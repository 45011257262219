<ng-container>

  <div class="import">
    <div style="padding-right:1rem;">
      <span class="dashboard-hdr" (click)="exportPDF()">
        <mat-icon svgIcon="download-arrow--s" class="import-icon" style="cursor:pointer;" id="import-icon">
          Export PDF
        </mat-icon>
        <span class="import-txt">Export PDF</span>
      </span>
    </div>

    <button id="refreshQuest" class="cvs-btn-primary" (click)="getQuestionaireData()">
      <mat-icon svgIcon="refresh--s"></mat-icon>
      Refresh Fetched Data
    </button>
  </div>
  <section  id="mapping-requirement" #mappingreq>
    <cvs-loader-overlay-container [isLoading]="showSpinner | async" loadingMessage="Loading">

    <form style="min-width: 44%;" *ngIf="form && options" [formGroup]="form">
      <!-- right container -->
      <div style="display: flex;">
        <div style="min-width: 44%;">
          <div class="row row-inner sub-heading" style="font-size: 1.2rem;margin-bottom: 1rem;">
            <span>Header Information</span>
          </div>

          <div class="row row-inner">
            <span>FTP ID</span>
            <div class="sm questionare-container">
              <input
                type="text"
                matInput class="questionaire"
                [formControl]="form.controls.ftpId"
                id="ftpId"
                readonly="true"
              >
            </div>

          </div>

          <div class="row row-inner">
            <span>File Schedule</span>
            <div class="sm questionare-container">
              <input
                type="text"
                matInput class="questionaire"
                [formControl]="form.controls.fileSchedule"
                id="fileSchedule"
                readonly="true"
              >
            </div>
          </div>

          <div class="row row-inner">
            <span>ISA12 - Interchange Control Version Number</span>
            <div>
              <mat-form-field appearance="outline" class="lg">
                <mat-select id="isa12" [formControl]="form.controls.isa12">
                  <mat-option *ngFor="let option of options.isa12"
                              [value]="option.id">{{ option.message }}</mat-option>
                </mat-select>
                <mat-error
                *ngIf="form.controls.isa12.errors?.required && showValidation"
                >Required Field</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row row-inner">
            <span>GS08 - Version Number</span>
            <div>
              <mat-form-field appearance="outline" class="lg">
                <mat-select id="gs08" [formControl]="form.controls.gs08">
                  <mat-option *ngFor="let option of options.gs08"
                              [value]="option.id">{{ option.message }}</mat-option>
                </mat-select>
                <mat-error
                *ngIf="form.controls.gs08.errors?.required && showValidation"
                >Required Field</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row row-inner">
            <span>ST03 - Implementation Convention Reference</span>
            <div>
              <mat-form-field appearance="outline" class="lg">
                <mat-select id="valueSentInST03" [formControl]="form.controls.valueSentInST03">
                  <mat-option *ngFor="let option of options.valueSentInST03"
                              [value]="option.id">{{ option.message }}</mat-option>
                </mat-select>
                <mat-error
                *ngIf="form.controls.valueSentInST03.errors?.required && showValidation"
                >Required Field</mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="row row-inner">
            <span>Value being sent in ST segment, ST03 field</span>
            <div>
              <mat-form-field appearance="outline" class="sm">
                <input matInput id="valueSentInST03" [formControl]="form.controls.valueSentInST03">

                <mat-error *ngIf="form.controls.valueSentInST03.errors?.required && showValidation">Required Field</mat-error>
              </mat-form-field>
            </div>
          </div> -->
          <div class="row row-inner">
            <span>999 response transaction required?</span>
            <div>
              <mat-form-field appearance="outline" class="lg">
                <mat-select id="responseTransaction999" [formControl]="form.controls.responseTransaction999">
                  <mat-option *ngFor="let option of options.responseTransaction999"
                              [value]="option">{{ option }}</mat-option>
                </mat-select>
                <mat-error
                *ngIf="form.controls.responseTransaction999.errors?.required && showValidation"
                >Required Field</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row row-inner">
            <span [ngClass]="(validationClass && validationClass.typeOf999Transaction)?
            'validation-dotted-border-text' : ''">Type of 999 Transaction </span>
            <div>
              <mat-form-field appearance="outline" class="lg-m"
                              [ngClass]="(validationClass && validationClass.typeOf999Transaction)?
                 'validation-dotted-border' : ''">
                <mat-select id="typeOf999Transaction" [formControl]="form.controls.typeOf999Transaction">
                  <mat-option *ngFor="let option of options.typeOf999Transaction"
                              [value]="option">{{ option }}</mat-option>
                </mat-select>
                <mat-error
                *ngIf="form.controls.typeOf999Transaction.errors?.required &&
                (!form.controls.typeOf999Transaction.disabled)
                && showValidation"
                >Required Field</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row row-inner" style="margin-bottom:.5rem">Number of files sent?</div>
          <div class="row row-inner" style="margin-bottom:.5rem">
            <!-- ag grid -->
            <ag-grid-angular
              class="ag-theme-alpine"
              #gridRequirement
              [stopEditingWhenCellsLoseFocus]="true"
              [frameworkComponents]="frameworkComponents"
              [suppressClickEdit]="form.disabled"
              [suppressRowClickSelection]="form.disabled"
              (gridReady)="onGridReady($event)"
            >
              <!-- (cellClicked)="onCellClicked($event)" -->
              <!-- [suppressClickEdit]="true" -->
              <!-- (row row-innerClicked)='onTablerow row-innerClicked($event)'
                  (dragStopped)="onDragStopped()"
                  (sortChanged)="onSortChange()"
                  (filterChanged)="onFilterChange( $event)" -->
            </ag-grid-angular>
          </div>
          <div class="row row-inner" style="margin-bottom:.5rem">
            <button id="register-user-btn" class="cvs-btn-text crud-button" (click)="addGridRow()"
                    [disabled]="form.disabled">
              <mat-icon svgIcon="plus--s"></mat-icon>
              Add Additional Value
            </button>
          </div>
        </div>
        <div class="mappingComments" style="margin-left: 1.5rem;">
          <!-- side container -->
          <div class="row sub-heading right-header"><span style="margin-left: 2rem; margin-top: .4rem;">Comments</span>
          </div>
          <div class="comment-container" id="comment-container">
            <textarea id="mappingInstructionsText"
            style="margin-left: 9px; padding-left: 3px;font-size: 20px; border: 0px;outline:none;max-width:98%;"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            appBulletCreater
            [(stringArray)]="mappingComments"
            *ngIf="addComments"
            [readonly]="!editMode"
            >
  </textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <span>More than ONE set of ST/SE segments?</span>
        <div>
          <mat-form-field appearance="outline" class="lg">
            <mat-select id="moreThanOneStSeSegments" [formControl]="form.controls.moreThanOneStSeSegments">
              <mat-option *ngFor="let option of options.moreThanOneStSeSegments"
                          [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error
            *ngIf="form.controls.moreThanOneStSeSegments.errors?.required &&
            (!form.controls.moreThanOneStSeSegments.disabled)
            && showValidation"
            >Required Field</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="sub-heading">ISA Segment</div>
      <div class="row">
        <span>ISA05</span>
        <div>
          <mat-form-field appearance="outline" class="lg">
            <mat-select id="isa05" [formControl]="form.controls.isa05">
              <mat-option *ngFor="let option of options.isa05" [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error
            *ngIf="form.controls.isa05.errors?.required &&
            (!form.controls.isa05.disabled)
            && showValidation"
            >Required Field</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <span>ISA06</span>
        <div>
          <mat-form-field appearance="outline" class="sm">
            <input matInput id="isa06" [formControl]="form.controls.isa06">
            <mat-error
            *ngIf="form.controls.isa06.errors?.required &&
            (!form.controls.isa06.disabled)
            && showValidation"
            >Required Field</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <span>ISA07</span>
        <div>
          <mat-form-field appearance="outline" class="lg">
            <mat-select id="isa07" [formControl]="form.controls.isa07">
              <mat-option *ngFor="let option of options.isa07" [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error
            *ngIf="form.controls.isa07.errors?.required &&
            (!form.controls.isa07.disabled)
            && showValidation"
            >Required Field</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <span>ISA08</span>
        <div>
          <mat-form-field appearance="outline" class="sm">
            <input matInput id="isa08" [formControl]="form.controls.isa08">
            <mat-error
            *ngIf="form.controls.isa08.errors?.required &&
            (!form.controls.isa08.disabled)
            && showValidation"
            >Required Field</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="sub-heading">GS Segment</div>
      <div class="row">
        <span>GS02</span>
        <div>
          <mat-form-field appearance="outline" class="sm">
            <input matInput id="gs02" [formControl]="form.controls.gs02">
            <mat-error
            *ngIf="form.controls.gs02.errors?.required &&
            (!form.controls.gs02.disabled)
            && showValidation"
            >Required Field</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <span>GS03</span>
        <div>
          <mat-form-field appearance="outline" class="sm">
            <input matInput id="gs03" [formControl]="form.controls.gs03">
            <mat-error
            *ngIf="form.controls.gs03.errors?.required &&
            (!form.controls.gs03.disabled)
            && showValidation"
            >Required Field</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="sub-heading">Header REF* 38 Segment</div>
      <div class="row">
        <span>Is this segment sent on EDI834 file?</span>
        <div>
          <mat-form-field appearance="outline" class="lg">
            <mat-select id="segmentSentOnEdi834" [formControl]="form.controls.segmentSentOnEdi834">
              <mat-option *ngFor="let option of options.segmentSentOnEdi834" [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error
            *ngIf="form.controls.segmentSentOnEdi834.errors?.required &&
            (!form.controls.segmentSentOnEdi834.disabled)
            && showValidation"
            >Required Field</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <span>What is the value?</span>
        <div>
          <mat-form-field appearance="outline" class="lg">
            <input matInput id="segmentSentOnEdi834Instructions" [formControl]="form.controls.segmentSentOnEdi834Instructions">
            <mat-error
            *ngIf="form.controls.segmentSentOnEdi834Instructions.errors?.required &&
            (!form.controls.segmentSentOnEdi834Instructions.disabled)
            && showValidation"
            >Required Field</mat-error>
          </mat-form-field>
        </div>
      </div>


      <div class="sub-heading">Loop 1000A Segment (P5)</div>
      <div class="row">
        <span>N102</span>
        <div>
          <mat-form-field appearance="outline" class="sm">
            <input matInput id="p5N102" [formControl]="form.controls.p5N102">
            <mat-error
            *ngIf="form.controls.p5N102.errors?.required &&
            (!form.controls.p5N102.disabled)
            && showValidation"
            >Required Field</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <span>N104</span>
        <div>
          <mat-form-field appearance="outline" class="sm">
            <input matInput id="p5N104" [formControl]="form.controls.p5N104">
            <mat-error
            *ngIf="form.controls.p5N104.errors?.required &&
            (!form.controls.p5N104.disabled)
            && showValidation"
            >Required Field</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="sub-heading">Loop 1000B Segment (IN)</div>
      <div class="row">
        <span>N102</span>
        <div>
          <mat-form-field appearance="outline" class="sm">
            <input matInput id="inN102" [formControl]="form.controls.inN102">
            <mat-error
            *ngIf="form.controls.inN102.errors?.required &&
            (!form.controls.inN102.disabled)
            && showValidation"
            >Required Field</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <span>N104</span>
        <div>
          <mat-form-field appearance="outline" class="sm">
            <input matInput id="inN104" [formControl]="form.controls.inN104">
            <mat-error
            *ngIf="form.controls.inN104.errors?.required &&
            (!form.controls.inN104.disabled)
            && showValidation"
            >Required Field</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="sub-heading">CAG Structure</div>
      <div class="row">
        <span>Carrier ID Sent?</span>
        <div>
          <mat-form-field appearance="outline" class="lg">
            <mat-select id="carrierIdSent" [formControl]="form.controls.carrierIdSent">
              <mat-option *ngFor="let option of options.carrierIdSent" [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error
            *ngIf="form.controls.carrierIdSent.errors?.required &&
            (!form.controls.carrierIdSent.disabled)
            && showValidation"
            >Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>

      <div class="row textarea-lg">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="carrierIdInstructions" [formControl]="form.controls.carrierIdInstructions"
            ></textarea>
            <mat-error
            *ngIf="form.controls.carrierIdInstructions.errors?.required &&
            (!form.controls.carrierIdInstructions.disabled)
            && showValidation"
            >Required Field</mat-error>
        </mat-form-field>
      </div>
      <div class="row-container">
        <div class="row">

          <span>Account ID Sent?</span>
          <div>
            <mat-form-field appearance="outline" class="lg">
              <mat-select id="accountIdSent" [formControl]="form.controls.accountIdSent">
                <mat-option *ngFor="let option of options.accountIdSent" [value]="option">{{ option }}</mat-option>
              </mat-select>
              <mat-error
            *ngIf="form.controls.accountIdSent.errors?.required &&
            (!form.controls.accountIdSent.disabled)
            && showValidation"
            >Required Field</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="container-tooltip">
          <mat-icon svgIcon="info-circle--s"
                    #tooltip="matTooltip" class="tooltip-icon"
                    matTooltip="Alight TBA should send in REF*DX and has a 10-byre limitation
            HCSC should send in REF*1L (pos 1-6)"
                    matTooltipClass="reqtooltip" matTooltipPosition="right"
                    matTooltipHideDelay="100">Changes Icon
          </mat-icon>
        </div>
      </div>
      <div class="row textarea-lg info">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
          <textarea matInput placeholder="Insert instructions here"
                    id="accountIdInstructions" [formControl]="form.controls.accountIdInstructions"
          ></textarea>
          <mat-error
            *ngIf="form.controls.accountIdInstructions.errors?.required &&
            (!form.controls.accountIdInstructions.disabled)
            && showValidation"
            >Required Field</mat-error>
        </mat-form-field>
      </div>
      <div class="row-container">
        <div class="row">

          <span>Group ID Sent?</span>
          <div>
            <mat-form-field appearance="outline" class="lg">
              <mat-select id="groupIdSent" [formControl]="form.controls.groupIdSent">
                <mat-option *ngFor="let option of options.groupIdSent" [value]="option">{{ option }}</mat-option>
              </mat-select>
              <mat-error
            *ngIf="form.controls.groupIdSent.errors?.required &&
            (!form.controls.groupIdSent.disabled)
            && showValidation"
            >Required Field</mat-error>
            </mat-form-field>
          </div>

        </div>
        <div class="container-tooltip">
          <mat-icon svgIcon="info-circle--s"
                    #tooltip="matTooltip"
                    class="tooltip-icon"
                    matTooltip="Alight TBA should send in REF*1L and has a 10-byte limitation
        HCSC should send in REF*1L (pos 7-10)"
                    matTooltipClass="reqtooltip" matTooltipPosition="right"
                    matTooltipHideDelay="100">Changes Icon
          </mat-icon>
        </div>
      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
          <textarea matInput placeholder="Insert instructions here"
                    id="groupIdInstructions" [formControl]="form.controls.groupIdInstructions"
          ></textarea>
          <mat-error
          *ngIf="form.controls.groupIdInstructions.errors?.required &&
          (!form.controls.groupIdInstructions.disabled)
          && showValidation"
          >Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="sub-heading">Person Code</div>
      <div class="row">

        <span class="readonly-text">Does Client use person code?</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.clientUsesPersonCode"
            id="clientUsesPersonCode"
            readonly="true"
          >
          <mat-error
          *ngIf="form.controls.clientUsesPersonCode.errors?.required &&
          (!form.controls.clientUsesPersonCode.disabled)
          && showValidation"
          >Required Field</mat-error>
        </div>


      </div>
      <div class="row">

        <span class="readonly-text">Is PC auto generated?</span>

        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.isPcAutoGenerated"
            id="isPcAutoGenerated"
            readonly="true"
          >
        </div>

      </div>
      <div class="row">

        <span class="readonly-text">Is PC appended to ID?</span>

        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.isPcAppendedToId"
            id="isPcAppendedToId"
            readonly="true"
          >
        </div>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="pcAppendedIdInstructions" [formControl]="form.controls.pcAppendedIdInstructions"
            ></textarea>
            <mat-error
          *ngIf="form.controls.pcAppendedIdInstructions.errors?.required &&
          (!form.controls.pcAppendedIdInstructions.disabled)
          && showValidation"
          >Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="sub-heading">Member Identification</div>

      <div class="row">

        <div>Member ID?</div>
      </div>
        <div class="row textarea-lg questionare-row-container">
          <mat-form-field  class="col-md-12" appearance="outline">
            <textarea matInput placeholder=""
            readonly="false"
                      id="memberId" [formControl]="form.controls.memberId"
            ></textarea>
            <mat-error
              *ngIf="form.controls.memberId.errors?.required &&
              (!form.controls.memberId.disabled)
              && showValidation"
              >Required Field</mat-error>
          </mat-form-field>
        <!-- <div>
          <mat-form-field appearance="outline" class="sm">
            <input matInput id="memberId" [formControl]="form.controls.memberId">
            <mat-error
          *ngIf="form.controls.memberId.errors?.required &&
          (!form.controls.memberId.disabled)
          && showValidation"
          >Required Field</mat-error>
          </mat-form-field>
        </div> -->

      </div>
      <div class="row">

        <span>Member ID sent for Dependents?</span>
        <div>
          <mat-form-field appearance="outline" class="lg-l">
            <mat-select id="memberIdForDependents" [formControl]="form.controls.memberIdForDependents">
              <mat-option *ngFor="let option of options.memberIdForDependents"
                          [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error
          *ngIf="form.controls.memberIdForDependents.errors?.required &&
          (!form.controls.memberIdForDependents.disabled)
          && showValidation"
          >Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row">

        <span class="readonly-text">Alternate ID Used?</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.alternateIdUsed"
            id="alternateIdUsed"
            readonly="true"
          >
        </div>

      </div>
      <div class="row">

        <span class="readonly-text">Alternate ID auto generated?</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.alternateIdAutoGenerated"
            id="alternateIdAutoGenerated"
            readonly="true"
          >
        </div>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="alternateIdGeneratedInstructions"
                      [formControl]="form.controls.alternateIdGeneratedInstructions"
            ></textarea>
            <mat-error
          *ngIf="form.controls.alternateIdGeneratedInstructions.errors?.required &&
          (!form.controls.alternateIdGeneratedInstructions.disabled)
          && showValidation"
          >Required Field</mat-error>
        </mat-form-field>
      </div>
      <div class="row">

        <span>Alternate ID for dependents?</span>
        <div>
          <mat-form-field appearance="outline" class="lg-l">
            <mat-select id="alternateIdForDependents" [formControl]="form.controls.alternateIdForDependents">
              <mat-option *ngFor="let option of options.alternateIdForDependents"
                          [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error
          *ngIf="form.controls.alternateIdForDependents.errors?.required &&
          (!form.controls.alternateIdForDependents.disabled)
          && showValidation"
          >Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <span class="readonly-text">3rd ID Used?</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.thirdIdUsed"
            id="thirdIdUsed"
            readonly="true"
          >
        </div>


      </div>
      <div class="row">
        <span class="readonly-text">3rd ID Type</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.q3rdIdFieldType"
            id="q3rdIdFieldType"
            readonly="true"
          >
        </div>


      </div>
      <div class="row">

        <span [ngClass]="(validationClass && validationClass.thirdIdForDependents)?
        'validation-dotted-border-text' : ''">3rd ID sent for dependants?</span>
        <div>
          <mat-form-field appearance="outline" class="sm"
            [ngClass]="(validationClass && validationClass.thirdIdForDependents)?
            'validation-dotted-border' : ''">
            <mat-select id="thirdIdForDependents" [formControl]="form.controls.thirdIdForDependents">
              <mat-option *ngFor="let option of options.thirdIdForDependents" [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error
          *ngIf="form.controls.thirdIdForDependents.errors?.required &&
          (!form.controls.thirdIdForDependents.disabled)
          && showValidation"
          >Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row-container">
        <div class="row">

          <span>Individual SS # sent?</span>
          <div>
            <mat-form-field appearance="outline" class="sm-s">
              <mat-select id="individualSsnSent" [formControl]="form.controls.individualSsnSent">
                <mat-option *ngFor="let option of options.individualSsnSent" [value]="option">{{ option }}</mat-option>
              </mat-select>
              <!-- <mat-error
          *ngIf="form.controls.individualSsnSent.errors?.required &&
          (!form.controls.individualSsnSent.disabled)
          && showValidation"
          >Required Field</mat-error> -->
            </mat-form-field>
          </div>

        </div>
        <div class="container-tooltip">
          <mat-icon svgIcon="info-circle--s"
                    #tooltip="matTooltip" class="tooltip-icon"
                    matTooltip="Workday has to be check for a value of “ZZ” first, then “34”"
                    matTooltipClass="reqtooltip" matTooltipPosition="right"
                    matTooltipHideDelay="100">Changes Icon
          </mat-icon>
        </div>
      </div>
      <div class="row-container container-tooltip">
        <div class="sub-heading">Address</div>
        <div class="">
          <mat-icon svgIcon="info-circle--s"
                    #tooltip="matTooltip"
                    [matTooltip]=toolTip.address class="tooltip-icon-wide"
                    matTooltipClass="reqtooltip" matTooltipPosition="right"
                    matTooltipHideDelay="100">Changes Icon
          </mat-icon>
        </div>
      </div>
      <div class="row">

        <span>Address sent for dependents?</span>
        <div>
          <mat-form-field appearance="outline" class="lg-l">
            <mat-select id="addressForDependents" [formControl]="form.controls.addressForDependents">
              <mat-option *ngFor="let option of options.addressForDependents" [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error
          *ngIf="form.controls.addressForDependents.errors?.required &&
          (!form.controls.addressForDependents.disabled)
          && showValidation"
          >Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row">

        <span>Is extended address required?</span>
        <div>
          <mat-form-field appearance="outline" class="lg-l">
            <mat-select id="extendedAddressRequired" [formControl]="form.controls.extendedAddressRequired">
              <mat-option *ngFor="let option of options.extendedAddressRequired"
                          [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error
          *ngIf="form.controls.extendedAddressRequired.errors?.required &&
          (!form.controls.extendedAddressRequired.disabled)
          && showValidation"
          >Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>

      <div class="row">

        <span>Is Language Sent?</span>
        <div>
          <mat-form-field appearance="outline" class="sm">
            <mat-select id="isLanguageSent"
                        [formControl]="form.controls.isLanguageSent">
              <mat-option *ngFor="let option of options.isLanguageSent"
                          [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.isLanguageSent.errors?.required &&
                            (!form.controls.isLanguageSent.disabled)
                            && showValidation">Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="isLanguageSentNotes"
                      [formControl]="form.controls.isLanguageSentNotes"></textarea>
          <mat-error *ngIf="form.controls.isLanguageSentNotes.errors?.required &&
                      (!form.controls.isLanguageSentNotes.disabled)
                      && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <span>Is Ethnicity Sent?</span>
        <div>
          <mat-form-field appearance="outline" class="sm">
            <mat-select id="isEthnicitySent"
                        [formControl]="form.controls.isEthnicitySent">
              <mat-option *ngFor="let option of options.isEthnicitySent"
                          [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.isEthnicitySent.errors?.required &&
                            (!form.controls.isEthnicitySent.disabled)
                            && showValidation">Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="isEthnicitySentNotes"
                      [formControl]="form.controls.isEthnicitySentNotes"></textarea>
          <mat-error *ngIf="form.controls.isEthnicitySentNotes.errors?.required &&
                      (!form.controls.isEthnicitySentNotes.disabled)
                      && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row-container container-tooltip">
        <div class="sub-heading" style="display: flex;"><span>Type 5</span>
          <div>
            <mat-icon svgIcon="info-circle--s"
                      #tooltip="matTooltip"
                      [matTooltip]=toolTip.type5 class="tooltip-icon-wide"
                      matTooltipClass="reqtooltip" matTooltipPosition="right"
                      matTooltipHideDelay="100">Changes Icon
            </mat-icon>
          </div>
        </div>

      </div>
      <div class="row">

        <span class="readonly-text">Will type 5 records be sent?</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.type5RecordsSent"
            id="type5RecordsSent"
            readonly="true"
          >
          <mat-error
          *ngIf="form.controls.type5RecordsSent.errors?.required &&
          (!form.controls.type5RecordsSent.disabled)
          && showValidation"
          >Required Field</mat-error>

        </div>

      </div>
      <div class="row">

        <span class="readonly-text">Type “E” Email?</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.typeEEmail"
            id="typeEEmail"
            readonly="true"
          >
          <mat-error
          *ngIf="form.controls.typeEEmail.errors?.required &&
          (!form.controls.typeEEmail.disabled)
          && showValidation"
          >Required Field</mat-error>
        </div>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      [ngClass]="(validationClass && validationClass.typeEEmailInstructions)?
                 'validation-border' : ''"
                      id="typeEEmailInstructions" [formControl]="form.controls.typeEEmailInstructions"
            ></textarea>
            <mat-error
            *ngIf="form.controls.typeEEmailInstructions.errors?.required &&
            (!form.controls.typeEEmailInstructions.disabled)
            && showValidation"
            >Required Field</mat-error>
        </mat-form-field>
      </div>
      <div class="row">

        <span class="readonly-text">Type P 'Privacy'?</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.typePPrivacy"
            id="typePPrivacy"
            readonly="true"
          >
          <mat-error
            *ngIf="form.controls.typePPrivacy.errors?.required &&
            (!form.controls.typePPrivacy.disabled)
            && showValidation"
            >Required Field</mat-error>
        </div>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      [ngClass]="(validationClass && validationClass.typePPrivacyInstructions)?
            'validation-border' : ''"
                      id="typePPrivacyInstructions" [formControl]="form.controls.typePPrivacyInstructions"
            ></textarea>
            <mat-error
            *ngIf="form.controls.typePPrivacyInstructions.errors?.required &&
            (!form.controls.typePPrivacyInstructions.disabled)
            && showValidation"
            >Required Field</mat-error>
        </mat-form-field>
      </div>

      <!-- <div class="row">

        <span>Type P 'Privacy'?</span>
          <div>
            <mat-form-field appearance="outline" class="sm">
                <input matInput id ="" [formControl]="form.controls.alphaCharacters">
              </mat-form-field>
          </div>

    </div> -->

      <!-- <div class="row textarea-lg ">
          <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
              <textarea matInput   placeholder="Insert instructions here"
              id ="" [formControl]="form.controls.alphaCharacters"
              ></textarea>
          </mat-form-field>
        </div> -->


      <div class="row">

        <span class="readonly-text">Type M 'Member Address'?</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.typeMMemberAddress"
            id="typeMMemberAddress"
            readonly="true"
          >
          <mat-error
            *ngIf="form.controls.typeMMemberAddress.errors?.required &&
            (!form.controls.typeMMemberAddress.disabled)
            && showValidation"
            >Required Field</mat-error>
        </div>

      </div>

      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="typeMMemberAddressInstructions"
                      [ngClass]="(validationClass && validationClass.typeMMemberAddressInstructions)?
            'validation-border' : ''"
                      [formControl]="form.controls.typeMMemberAddressInstructions"
            ></textarea>
            <mat-error
            *ngIf="form.controls.typeMMemberAddressInstructions.errors?.required &&
            (!form.controls.typeMMemberAddressInstructions.disabled)
            && showValidation"
            >Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <span class="readonly-text">Type A 'Member Ancillary'?</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.typeAMemberAncillary"
            id="typeAMemberAncillary"
            readonly="true"
          >
          <mat-error
            *ngIf="form.controls.typeAMemberAncillary.errors?.required &&
            (!form.controls.typeAMemberAncillary.disabled)
            && showValidation"
            >Required Field</mat-error>
        </div>

      </div>

      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="typeAMemberAncillaryInstructions"
                      [ngClass]="(validationClass && validationClass.typeAMemberAncillaryInstructions)?
            'validation-border' : ''"
                      [formControl]="form.controls.typeAMemberAncillaryInstructions"></textarea>
                      <mat-error
            *ngIf="form.controls.typeAMemberAncillaryInstructions.errors?.required &&
            (!form.controls.typeAMemberAncillaryInstructions.disabled)
            && showValidation"
            >Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row-container">
        <div class="row">

          <span>Multiple HD Loops sent?</span>
          <div>
            <mat-form-field appearance="outline" class="sm-s">
              <mat-select id="multipleHdLoopsSent" [formControl]="form.controls.multipleHdLoopsSent">
                <mat-option *ngFor="let option of options.multipleHdLoopsSent"
                            [value]="option">{{ option }}</mat-option>
              </mat-select>
              <mat-error
            *ngIf="form.controls.multipleHdLoopsSent.errors?.required &&
            (!form.controls.multipleHdLoopsSent.disabled)
            && showValidation"
            >Required Field</mat-error>
            </mat-form-field>
          </div>

        </div>
        <div class="container-tooltip">
          <mat-icon svgIcon="info-circle--s"
                    #tooltip="matTooltip"
                    [matTooltip]=toolTip.multipleLoop class="tooltip-icon"
                    matTooltipClass="reqtooltip" matTooltipPosition="right"
                    matTooltipHideDelay="100">Changes Icon
          </mat-icon>
        </div>
      </div>

      <div class="row">Value to be sent in Loop 2300, HD03</div>

      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
          <textarea matInput placeholder="Insert instructions here" id="valueSentInLoop2300Hd03"
            [formControl]="form.controls.valueSentInLoop2300Hd03">
                  </textarea>
          <mat-error *ngIf="form.controls.valueSentInLoop2300Hd03.errors?.required &&
                            (!form.controls.valueSentInLoop2300Hd03.disabled)
                            && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <span>HD05 Sent for dependents</span>
        <div>
          <mat-form-field appearance="outline" class="sm">
            <mat-select id="valueHD05SentDependents"
                        [formControl]="form.controls.valueHD05SentDependents">
              <mat-option *ngFor="let option of options.valueHD05SentDependents"
                          [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.valueHD05SentDependents.errors?.required &&
                            (!form.controls.valueHD05SentDependents.disabled)
                            && showValidation">Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>

      <div class="row">

        <span>Overrides value for "E5D" / “S5C”?</span>
        <div>
          <mat-form-field appearance="outline" class="sm-s">
            <mat-select id="overrideValuesE5DS5C"
                        [formControl]="form.controls.overrideValuesE5DS5C">
              <mat-option *ngFor="let option of options.overrideValuesE5DS5C" [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.overrideValuesE5DS5C.errors?.required &&
                            (!form.controls.overrideValuesE5DS5C.disabled)
                            && showValidation">Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row-container container-tooltip">
        <div class="sub-heading">Effective / Termination Date Coding</div>
        <div>
          <mat-icon svgIcon="info-circle--s"
                    #tooltip="matTooltip"
                    [matTooltip]=toolTip.terminationDateCoding class="tooltip-icon-wide"
                    matTooltipClass="reqtooltip" matTooltipPosition="right"
                    matTooltipHideDelay="100">Changes Icon
          </mat-icon>
        </div>
      </div>

      <div class="row">

        <span>Effective Date sent in Loop 2300, DTP01 = “348”?</span>
        <div>
          <mat-form-field appearance="outline" class="sm">
            <mat-select id="effectiveDateSentInLoop2300Dtp01348"
                        [formControl]="form.controls.effectiveDateSentInLoop2300Dtp01348">
              <mat-option *ngFor="let option of options.effectiveDateSentInLoop2300Dtp01348"
                          [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.effectiveDateSentInLoop2300Dtp01348.errors?.required &&
                            (!form.controls.effectiveDateSentInLoop2300Dtp01348.disabled)
                            && showValidation">Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="effectiveDateSentInLoop2300Dtp01348Instructions"
                      [formControl]="form.controls.effectiveDateSentInLoop2300Dtp01348Instructions"></textarea>
                      <mat-error *ngIf="form.controls.effectiveDateSentInLoop2300Dtp01348Instructions.errors?.required &&
                      (!form.controls.effectiveDateSentInLoop2300Dtp01348Instructions.disabled)
                      && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <span>Is effective date override needed?</span>
        <div>
          <mat-form-field appearance="outline" class="sm-s">
            <mat-select id="effectiveDateOverrideNeeded"
                        [formControl]="form.controls.effectiveDateOverrideNeeded">
              <mat-option *ngFor="let option of options.effectiveDateOverrideNeeded"
                          [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.effectiveDateOverrideNeeded.errors?.required &&
                      (!form.controls.effectiveDateOverrideNeeded.disabled)
                      && showValidation">Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="effectiveDateOverrideInstructions"
                      [ngClass]="(validationClass && validationClass.effectiveDateOverrideInstructions)?
            'validation-border' : ''"
                      [formControl]="form.controls.effectiveDateOverrideInstructions"></textarea>
                      <mat-error *ngIf="form.controls.effectiveDateOverrideInstructions.errors?.required &&
                      (!form.controls.effectiveDateOverrideInstructions.disabled)
                      && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <span>Termination Date sent in Loop 2300, DTP01 = “349”</span>
        <div>
          <mat-form-field appearance="outline" class="lg">
            <mat-select id="terminationDateSentInLoop2300Dtp01349"
                        [formControl]="form.controls.terminationDateSentInLoop2300Dtp01349">
              <mat-option *ngFor="let option of options.terminationDateSentInLoop2300Dtp01349"
                          [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.terminationDateSentInLoop2300Dtp01349.errors?.required &&
            (!form.controls.terminationDateSentInLoop2300Dtp01349.disabled)
            && showValidation">Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="terminationDateSentInLoop2300Dtp01349Instructions"
                      [formControl]="form.controls.terminationDateSentInLoop2300Dtp01349Instructions"></textarea>
                      <mat-error
                      *ngIf="form.controls.terminationDateSentInLoop2300Dtp01349Instructions.errors?.required &&
                      (!form.controls.terminationDateSentInLoop2300Dtp01349Instructions.disabled)
                      && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <span>Is Termination Date override needed?</span>
        <div>
          <mat-form-field appearance="outline" class="sm-s">
            <mat-select id="terminationDateOverrideNeeded"
                        [formControl]="form.controls.terminationDateOverrideNeeded">
              <mat-option *ngFor="let option of options.terminationDateOverrideNeeded"
                          [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.terminationDateOverrideNeeded.errors?.required &&
            (!form.controls.terminationDateOverrideNeeded.disabled)
            && showValidation">Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="terminationDateOverrideNeededInstructions"
                      [ngClass]="(validationClass && validationClass.terminationDateOverrideNeededInstructions)?
            'validation-border' : ''"
                      [formControl]="form.controls.terminationDateOverrideNeededInstructions"></textarea>
                      <mat-error *ngIf="form.controls.terminationDateOverrideNeededInstructions.errors?.required &&
                      (!form.controls.terminationDateOverrideNeededInstructions.disabled)
                      && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <span class="readonly-text">Is the Term Date a 'To' date?</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.isTermDateAToDate"
            id="isTermDateAToDate"
            readonly="true"
          >
          <mat-error *ngIf="form.controls.isTermDateAToDate.errors?.required &&
                      (!form.controls.isTermDateAToDate.disabled)
                      && showValidation">Required Field</mat-error>
        </div>

      </div>

      <div class="row">

        <span class="readonly-text">Care Facility required?</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.careFacilityRequired"
            id="careFacilityRequired"
            readonly="true"
          >
          <mat-error *ngIf="form.controls.careFacilityRequired.errors?.required &&
                      (!form.controls.careFacilityRequired.disabled)
                      && showValidation">Required Field</mat-error>
        </div>

      </div>

      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="careFacilityRequiredInstructions"
                      [ngClass]="(validationClass && validationClass.careFacilityRequiredInstructions)?
            'validation-border' : ''"
                      [formControl]="form.controls.careFacilityRequiredInstructions"></textarea>
                      <mat-error *ngIf="form.controls.careFacilityRequiredInstructions.errors?.required &&
                      (!form.controls.careFacilityRequiredInstructions.disabled)
                      && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <span class="readonly-text">Care Qualifier required?</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.careQualifierRequired"
            id="careQualifierRequired"
            readonly="true"
          >
          <mat-error *ngIf="form.controls.careQualifierRequired.errors?.required &&
          (!form.controls.careQualifierRequired.disabled)
          && showValidation">Required Field</mat-error>
        </div>

      </div>

      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="careQualifierRequiredInstructions"
                      [ngClass]="(validationClass && validationClass.careQualifierRequiredInstructions)?
            'validation-border' : ''"
                      [formControl]="form.controls.careQualifierRequiredInstructions"></textarea>
                      <mat-error *ngIf="form.controls.careQualifierRequiredInstructions.errors?.required &&
                      (!form.controls.careQualifierRequiredInstructions.disabled)
                      && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <span class="readonly-text">Healthcare Exchange / Grace Period</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.healthcareExchangeGracePeriod"
            id="healthcareExchangeGracePeriod"
            readonly="true"
          >
          <mat-error *ngIf="form.controls.healthcareExchangeGracePeriod.errors?.required &&
                      (!form.controls.healthcareExchangeGracePeriod.disabled)
                      && showValidation">Required Field</mat-error>
        </div>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="healthcareExchangeGracePeriodInstructions"
                      [ngClass]="(validationClass && validationClass.healthcareExchangeGracePeriodInstructions)?
            'validation-border' : ''"
                      [formControl]="form.controls.healthcareExchangeGracePeriodInstructions"></textarea>
                      <mat-error *ngIf="form.controls.healthcareExchangeGracePeriodInstructions.errors?.required &&
                      (!form.controls.healthcareExchangeGracePeriodInstructions.disabled)
                      && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <span>COB</span>
        <div>
          <mat-form-field appearance="outline" class="sm">
            <mat-select id="cob" [formControl]="form.controls.cob">
              <mat-option *ngFor="let option of options.cob" [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.cob.errors?.required &&
                      (!form.controls.cob.disabled)
                      && showValidation">Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="cobInstructions" [formControl]="form.controls.cobInstructions"
                      [ngClass]="(validationClass && validationClass.cobInstructions)?
            'validation-border' : ''"
            ></textarea>
            <mat-error *ngIf="form.controls.cobInstructions.errors?.required &&
                      (!form.controls.cobInstructions.disabled)
                      && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <span class="readonly-text">CDD</span>
        <div class="sm questionare-container">
          <input
            type="text"
            matInput class="questionaire"
            [formControl]="form.controls.cdd"
            id="cdd"
            readonly="true"
          >
          <mat-error *ngIf="form.controls.cdd.errors?.required &&
                      (!form.controls.cdd.disabled)
                      && showValidation">Required Field</mat-error>
        </div>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="cddInstructions" [formControl]="form.controls.cddInstructions"
                      [ngClass]="(validationClass && validationClass.cddInstructions)?
            'validation-border' : ''"></textarea>
            <mat-error *ngIf="form.controls.cddInstructions.errors?.required &&
                      (!form.controls.cddInstructions.disabled)
                      && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row-container">
        <div class="row">
          <span class="readonly-text">MED-B</span>
          <div class="sm questionare-container">
            <input
              type="text"
              matInput class="questionaire"
              [formControl]="form.controls.medB"
              id="medB"
              readonly="true"
            >
            <mat-error *ngIf="form.controls.medB.errors?.required &&
                      (!form.controls.medB.disabled)
                      && showValidation">Required Field</mat-error>
          </div>
        </div>

        <div class="container-tooltip">
          <mat-icon svgIcon="info-circle--s"
                    #tooltip="matTooltip"
                    [matTooltip]=toolTip.medB class="tooltip-icon"
                    matTooltipClass="reqtooltip" matTooltipPosition="right"
                    matTooltipHideDelay="100">Changes Icon
          </mat-icon>
        </div>
      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      [ngClass]="(validationClass && validationClass.medBInstructions)?
            'validation-border' : ''"
                      id="medBInstructions" [formControl]="form.controls.medBInstructions"></textarea>
                      <mat-error *ngIf="form.controls.medBInstructions.errors?.required &&
                      (!form.controls.medBInstructions.disabled)
                      && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row-container">
           <div class="row">
          <span class="readonly-text">RDS</span>
          <div class="sm questionare-container">
            <input
              type="text"
              matInput class="questionaire"
              [formControl]="form.controls.rds"
              id="rds"
              readonly="true"
            >
            <mat-error *ngIf="form.controls.rds.errors?.required &&
                      (!form.controls.rds.disabled)
                      && showValidation">Required Field</mat-error>
          </div>
        </div>

        <div class="container-tooltip">
          <mat-icon svgIcon="info-circle--s"
                    #tooltip="matTooltip"
                    [matTooltip]=toolTip.rds class="tooltip-icon"
                    matTooltipClass="reqtooltip" matTooltipPosition="right"
                    matTooltipHideDelay="100">Changes Icon
          </mat-icon>
        </div>
        </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      [ngClass]="(validationClass && validationClass.rdsInstructions)?
            'validation-border' : ''"
                      id="rdsInstructions" [formControl]="form.controls.rdsInstructions"></textarea>
                      <mat-error *ngIf="form.controls.rdsInstructions.errors?.required &&
                      (!form.controls.rdsInstructions.disabled)
                      && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <span>Transition Fill?</span>
        <div>
          <mat-form-field appearance="outline" class="sm">
            <mat-select id="transitionFill" [formControl]="form.controls.transitionFill">
              <mat-option *ngFor="let option of options.transitionFill" [value]="option">{{ option }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.transitionFill.errors?.required &&
            (!form.controls.transitionFill.disabled)
            && showValidation">Required Field</mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="transitionFillInstructions"
                      [ngClass]="(validationClass && validationClass.transitionFillInstructions)?
            'validation-border' : ''"
                      [formControl]="form.controls.transitionFillInstructions"></textarea>
                      <mat-error *ngIf="form.controls.transitionFillInstructions.errors?.required &&
            (!form.controls.transitionFillInstructions.disabled)
            && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <span>Other Instructions</span>

      </div>
      <div class="row textarea-lg ">
        <mat-form-field appearance="outline" class="col-md-12" appearance="outline">
            <textarea matInput placeholder="Insert instructions here"
                      id="otherInstructions"
                      [ngClass]="(validationClass && validationClass.otherInstructions)?
            'validation-border' : ''"
                      [formControl]="form.controls.otherInstructions"></textarea>
                      <mat-error *ngIf="form.controls.otherInstructions.errors?.required &&
            (!form.controls.otherInstructions.disabled)
            && showValidation">Required Field</mat-error>
        </mat-form-field>
      </div>
    </form>
  </cvs-loader-overlay-container>
  </section>
</ng-container>
