import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MappingRecTypeService} from '../../../../services/mapping-rec-type.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {InformationDialogComponent} from '../../common-components/information-dialog/information-dialog.component';

@Component({
  selector: 'app-add-logic-code-modal',
  templateUrl: './add-logic-code-modal.component.html',
  styleUrls: ['./add-logic-code-modal.component.scss']
})
export class AddLogicCodeModalComponent implements OnInit {
  mappingDocumentData;
  rowData;
  newLogicCodeSequence;

  @Output() addLogicCodeEvent = new EventEmitter<any>();

  logicCodeValue$: Subject<string> = new BehaviorSubject('');

  mappingForm = new UntypedFormGroup({
    mappingInstruction: new UntypedFormControl('', Validators.required),
    mappingDescription: new UntypedFormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<AddLogicCodeModalComponent>,
    private fb: UntypedFormBuilder,
    private mappingRecTypeService: MappingRecTypeService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.mappingDocumentData = this.data.mappingDocument;
    this.rowData = this.data.rowData;
    if (this.rowData) {
      this.mappingRecTypeService.getNewLogicCodeSequence(this.rowData.fieldNumber).subscribe(res => {
        this.newLogicCodeSequence = res.logicCode;
        this.logicCodeValue$.next(this.newLogicCodeSequence);
      });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  addLogicCode() {
    const {mappingInstruction, mappingDescription} = this.mappingForm.value;
    const newLogicCode = {
      logicCode: this.newLogicCodeSequence,
      mappingInstructions: mappingInstruction,
      mappingDescription,
      logicCodeStatus: 'Pending',
      // BELOW NEEDS DESIGN REVIEW. SET TO EMPTY STRING FOR NOW
      // mapType: this.mappingDocumentData.mappingType ? this.mappingDocumentData.mappingType : this.rowData.mapType,
      mapType: '',
      rxClaimField: this.rowData.fieldName,
    };

    this.mappingRecTypeService.addLogicCode(newLogicCode).subscribe((newLogicCodeResponse) => {
      if (newLogicCodeResponse.errorMessage !== null && newLogicCodeResponse.errorMessage.length > 0) {
        this.dialog.open(InformationDialogComponent, {
          height: '200px',
          width: '400px',
          data: {
            body: 'Something went wrong. please try again.',
            buttonText: 'Close'
          }
        });
        return;
      } else {
        this.dialogRef.close();
        this.addLogicCodeEvent.emit(newLogicCodeResponse.response);
      }
    });
  }
}
