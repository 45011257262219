import {  Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ClassificationCode } from '../../models/classificationCode';
import { ClassificationCodeService } from '../../services/classification-code.service';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { RowValueChangedEvent } from 'ag-grid-community';
import {  Subject} from 'rxjs';
import {columnDefs} from './classificationColumnSets'



@Component({
  selector: 'app-classification-code',
  templateUrl: './classification-code.component.html',
  styleUrls: ['./classification-code.component.scss']
})
export class ClassificationCodeComponent implements OnInit {

  newrecord = false;
  rowdata: any;
  editdisabled = true;
  showSpinner: Subject<boolean> = new Subject();
  btncliked:boolean;
  rowSelected: boolean = true;
  gridApi: any;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  constructor( private alertService: CVSAlertService, private classificationService: ClassificationCodeService ) { }

  @ViewChild('grid1', { static: false }) grid1: AgGridAngular;



  rowChanged: any;
  adddisabled = true;
  public editType: 'fullRow' = 'fullRow';
  deleteDisabled:boolean = true;


  ngOnInit() {
    this.getNewTableData();
  }
  private getNewTableData() {
    this.classificationService.getclassificationdata().subscribe(rowData => {
      if(rowData){
        this.showSpinner.next(true)
        this.grid1.api.setRowData(rowData);
        this.showSpinner.next(false)
        this.adddisabled = false
      }
    });
  }

  onGridReady(event){
    this.gridApi = event.api
    this.grid1.api.setColumnDefs(columnDefs)
  }
savedisabled() {
  this.editdisabled = true;
  this.adddisabled = false;
}


  onRowValueChanged(event: RowValueChangedEvent) {
  this.rowdata = event.data;
  let tested = 0
  const datalen = Object.keys(this.rowdata).length
   var regEx = /^[a-zA-Z0-9-_ ]+$/;
  Object.values(this.rowdata).forEach((val,index) => {
    if (regEx.test(<string>val)){
      tested++
    }else{
      tested--
    }
  })
 if (this.btncliked && tested === datalen ){
  this.btncliked = false;
  if (this.newrecord === false && event.data != null) {
    this.editdisabled = false;
    this.adddisabled = true;
    this.showSpinner.next(true);
    this.classificationService.updateclassificationCodeApi(this.rowdata)
      .subscribe((resp) => {
      if (resp.status === 'SUCCESS') {
       this.savedisabled();
       this.showMessage('Updated Successfully', 'Success');
       this.getNewTableData();
      } else {
        this.savedisabled();
        this.showMessage(resp.errorMessage, 'error');
        this.getNewTableData();
      }
      this.showSpinner.next(false);
      });
  } else if (this.newrecord === true && event.data != null) {
    this.editdisabled = true;
    this.adddisabled = true;
    this.showSpinner.next(true);
    this.classificationService.addclassification(this.rowdata).subscribe((resp) => {
      if (resp.status === 'SUCCESS') {
        this.savedisabled();
        this.newrecord = false;
        this.showMessage('Added Successfully', 'Success');
        this.getNewTableData();
      } else {
        this.savedisabled();
        this.newrecord = false;
        this.showMessage(resp.errorMessage, 'error');
        this.getNewTableData();
      }
      this.showSpinner.next(false);
  });
  } else {
    window.alert('Please select the row for edit first !');
  }
 }else if(!this.rowdata.classificationCode || !this.rowdata.fieldNumber || !this.rowdata.mapType
      || !this.rowdata.logicCode || !this.rowdata.recordType){
        this.editdisabled = true
  this.showMessage("No empty fields allowed", 'error');
 }
}

saveType(id) {
  switch (id) {
    case 0:
      this.btncliked = true;
      this.grid1.api.stopEditing();
      break;
    case 1:
      this.btncliked = true;
      this.newrecord = false;
      this.grid1.api.stopEditing();
      break;
  }
  }
  AddNew() {
    const rowdatA = {
      classificationCode: '',
      recordType: '',
      mapType: '',
      fieldNumber: '',
      logicCode: ''
    };
    this.grid1.api.applyTransaction({add: [rowdatA], addIndex: 0});
    this.adddisabled = true;
    this.newrecord = true;
  }
  save() {
    if (this.newrecord === false) {
      this.saveType(1);
    } else if (this.newrecord === true) {
      this.saveType(0);
    }
  }

  OnSelectionChange(event){
    this.editdisabled = true
    const selectedNodes : any = []
    this.gridApi.forEachNode(rowNode => {
      if (rowNode.selected) {
        selectedNodes.push(rowNode.data)
      }
    })
    if(selectedNodes.length > 0){
      this.deleteDisabled = false
    }else{
      this.deleteDisabled = true;
    }
  }

  OnCellClicked(event){
    this.editdisabled = false
    this.adddisabled = true
  }


  deleteClassification(){
    const selectedNodes : any = []
    this.gridApi.forEachNode(rowNode => {
      if (rowNode.selected && rowNode.data.id ) {
        selectedNodes.push(rowNode.data)
      }else if(rowNode.selected && !rowNode.data.id){
        this.gridApi.applyTransaction({ remove: [rowNode.data] })
      }
    })
    if(selectedNodes.length > 0){
      this.gridApi.applyTransaction({ remove: selectedNodes })
      const deleteId :any = []
      selectedNodes.forEach(row=>{
        deleteId.push(row?.id.toString())
      })
      this.classificationService.deleteClassificationCode(deleteId).subscribe(resp => {
        if (resp.status === "SUCCESS") {
          this.showMessage("Classification Code Deleted Successfully","Success" )
          this.getNewTableData()
          this.deleteDisabled = true
        } else {
          this.showMessage("ERROR !", 'error')
        }
        this.rowSelected = true;
      })
    }
     this.adddisabled = false
  }

  showMessage(message, type) {
    this.alertService.sendAlert({
      // headline: message ? message : 'Save successful !',
      headline: message,
      alertType: type === 'error' ? CVSAlertType.Error : CVSAlertType.Success,
      removedAfterMilliseconds: 3000,
      alertDocumentHolderName: '#Classification-code',
    });
  }

}
