import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from '../../environments/environment';
import {CVSHttpClient} from 'angular-component-library';
import {Contact} from '../models/Contact';
import {Copay} from '../models/Copay';

@Injectable({
  providedIn: 'root'
})
export class CopayService {

  isNew:boolean;
  cltId:any;
  isEdited:boolean;

  constructor(private http: CVSHttpClient) { }

  getCopay(id: number): Observable<any> {
    return this.http.get(`${environment.baseFiletrackerUrl}/benefitsfiletracker/${id}`);
  }

  getContact(id: number, field: string): Observable<any> {
    return this.http.get(`${environment.baseFiletrackerUrl}/benefitsfiletracker/contacts/${id}/${field}`);
  }

  getAll(): Observable<any> {
    return this.http.get(`${environment.baseFiletrackerUrl}/benefitsfiletracker/all`);
  }

  saveCopay(copay: Copay, type: string): Observable<any> {
    return this.http.post(`${environment.baseFiletrackerUrl}/benefitsfiletracker/save?type=${type}`, copay);
  }

  saveContact(contact: Contact, type: string): Observable<any> {
    return this.http.post(`${environment.baseFiletrackerUrl}/benefitsfiletracker/contact/save?type=${type}`, contact);
  }

  editContact(contact: Contact, field: string): Observable<any> {
    return this.http.post(`${environment.baseFiletrackerUrl}/benefitsfiletracker/contacts/update/${field}`, contact);
  }
  deleteContact(emailId: any): Observable<any> {
    return this.http.delete(`${environment.baseFiletrackerUrl}/filetracker/contacts/delete?emailId=` + emailId);
  }
  getNewCopayClientSetupId(): Observable<any> {
    return this.http.get(`${environment.baseFiletrackerUrl}/benefitsfiletracker/clientSetupId`);
  }
}
