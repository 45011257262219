import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { CVSAlertService } from 'angular-component-library';
import { FileLayoutService } from './file-layout/file-layout.service';

import { Store } from '@ngrx/store';
import { AppState, getPermissionsAdminObject } from '../reducers';
import {QuestionnaireSettingsService} from "../services/questionnaire-settings.service";
@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"],
})
export class AdminComponent implements OnInit{
  userId;
  scheduleEvnetsTabActive = false;
  selectedTab: any
  adminTabsPermission: any;
  adminObject$ = this.store.select(getPermissionsAdminObject)
  adminObject: any
  isQuestionnaireSettingSaveDisabled: boolean;
  currentTabIndex: any=0;
  previousTabIndex: any=0;

  constructor(
    private alertService: CVSAlertService,
    private flService: FileLayoutService,
    private questionnaireSettingService: QuestionnaireSettingsService,
    private store: Store<AppState>
  ) {

  }

  ngOnInit() {
    this.setPermissions()

    for(const [k,v] of Object.entries(this.adminTabsPermission)){
      if(v !== false){
        this.selectedTab = k;
        break;
      }
    }

    this.questionnaireSettingService.isQuestionnaireSettingSaveDisabled.subscribe(res=>{
      this.isQuestionnaireSettingSaveDisabled= res;
    });
  }
  setPermissions(){
    this.adminObject$.subscribe(res=>{

      this.adminObject = res
      this.adminTabsPermission =res.object
    })

  }

  // closeAllAdminModuleAlerts(event?) {
  //   return;
  //   // if(this.currentTabIndex === 3){
  //   //   this.showornotSettingsTab(event);
  //   // }
  //   const tabId = event?.index
  //   const tabsList = Object.keys(this.adminTabsPermission).filter(key=>this.adminTabsPermission[key]);
  //
  //   if (event && event.tab.textLabel === 'File Layout') {
  //     this.flService.updateTab();
  //   }
  //   // this.showornotSettingsTab(event)
  //   this.selectedTab = tabsList[tabId]
  //
  //   this.alertService.clearAlert("#master-data-alert");
  //   this.alertService.clearAlert("#register-user-alert");
  // }

  tabchanged(tab: any, index: any) {
    const tabsList = Object.keys(this.adminTabsPermission).filter(key=>this.adminTabsPermission[key]);

    if (tab.textLabel === 'File Layout') {
      this.flService.updateTab();
    }

    if (this.previousTabIndex === 3 && this.previousTabIndex !== index && this.isQuestionnaireSettingSaveDisabled) {
      if (confirm('Changes have NOT been saved! Click \'OK\' to exit WITHOUT saving.')) {
        if(tab.selectedIndex!== this.previousTabIndex){
          this.currentTabIndex= index;
          this.previousTabIndex= index;
          this.selectedTab = tabsList[index]
        }
      } else {
        tab.selectedIndex= this.previousTabIndex;
        this.selectedTab = tabsList[this.previousTabIndex]
      }
    } else {
      this.currentTabIndex= index;
      this.previousTabIndex= index;
      this.selectedTab = tabsList[index]
    }
    this.alertService.clearAlert("#master-data-alert");
    this.alertService.clearAlert("#register-user-alert");

    // if (this.isQuestionnaireSettingSaveDisabled) {
    //   if (!confirm('Changes have NOT been saved! Click \'OK\' to exit WITHOUT saving.')) {
    //   }
    //   else{
    //     this.currentTabIndex=3;
    //   }
    // }
  }

}
