import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {AlertType} from '../../../models/AlertType';

export interface ConfirmationDialogData {
  body: any;
  type: AlertType;
}

@Component({
  selector: 'app-confirmation-dialog-content',
  templateUrl: './confirmation-dialog-content.component.html',
  styleUrls: ['./confirmation-dialog-content.component.scss']
})
export class ConfirmationDialogContentComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
              public dialogRef: MatDialogRef<ConfirmationDialogData>
  ) {  }

  closeHandler() {
    this.dialogRef.close();
  }
}
