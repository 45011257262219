import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {
  LOAD_MASTER_DATA_SET,
  LOAD_MASTER_DATA_SET_SUCCESS,
  LoadMasterDataSetAction, SAVE_MASTER_DATA_SET, SAVE_MASTER_DATA_SET_SUCCESS
} from '../actions/data-management.action';
import {DataManagementService} from '../services/data-management.service';
import {MasterDataList} from '../models/MasterDataList';
import {Store} from '@ngrx/store';
import {AppState} from '../reducers';
import {CVSAlertService, CVSAlertType} from 'angular-component-library';
import {NoopAction} from '../actions/noop.action';

@Injectable()
export class DataManagementEffects {
  constructor(
    private actions$: Actions,
    private dataMgmtService: DataManagementService,
    private alertService: CVSAlertService,
    private store$: Store<AppState>) { }

  loadMasterDataSet$ = createEffect(() => this.actions$.pipe(
    ofType(LOAD_MASTER_DATA_SET),
    mergeMap((action: LoadMasterDataSetAction) => this.dataMgmtService.getMasterDataSet(action.dataSetName)
      .pipe(
        map(dataValues => ({ type: LOAD_MASTER_DATA_SET_SUCCESS, payload: dataValues})),
        catchError(error => {
          return of(new NoopAction());
        })
      ))
    )
  );

  saveMasterDataSet$ = createEffect(() => this.actions$.pipe(
    ofType(SAVE_MASTER_DATA_SET),
    withLatestFrom(this.store$.select(state => state.dataManagement)),
    mergeMap(([_, dataManagement]) => this.dataMgmtService.saveMasterDataSet(dataManagement.dataList)
      .pipe(
        map(mdlValue => ({ type: SAVE_MASTER_DATA_SET_SUCCESS, payload: mdlValue as MasterDataList })),
        tap(() => {
          this.alertService.sendAlert({
            headline: 'Your changes have been saved',
            alertType: CVSAlertType.Success,
            fadeClass: true,
            alertDocumentHolderName: '#master-data-alert'
          });
        }),
        catchError(() => {
          this.alertService.sendAlert({
            headline: 'Your changes have not been saved',
            alertType: CVSAlertType.Error,
            fadeClass: true,
            alertDocumentHolderName: '#master-data-alert'
          });
          return of(new NoopAction());
        })
      ))
    )
  );
}
