import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { of, Subscription } from 'rxjs';
import { RteSuspenseService } from 'src/app/services/rte-suspense.service';
import { UsersService } from 'src/app/services/users.service';
import { RteSuspenseFormService } from './rte-suspense-form.service';
import { SuspenseScheduleComponent } from './suspense-schedule/suspense-schedule.component';

@Component({
  selector: 'app-rte-suspense',
  templateUrl: './rte-suspense.component.html',
  styleUrls: ['./rte-suspense.component.scss']
})
export class RteSuspenseComponent implements OnInit {
  radioOptions = [{ display: "Member File", value: "file" },
  { display: "Member Daily", value: "daily" },
  { display: "Group", value: "group" }];
  criteriaList = ["Carrier", "Carrier List", "Carrier/Account", "Carrier/Account/Group", "Range"]
  form: UntypedFormGroup;
  criteriaDependedentFrom: UntypedFormGroup;
  subs: Subscription[] = [];
  getradioOptions = of(this.radioOptions);
  currentCriteria;
  showGrid: boolean;
  dialogConfig = new MatDialogConfig();
  formRadioVal: any;
  gridData: any = null;
  reportType = null;
  searchPayload: { reportType: string; criteria: any; carrier: any; account: any; group: any; carrierTo: string; accountTo: string; groupTo: string; };
  userId: any;
  userEmail: any;
  constructor(private formServ: RteSuspenseFormService,
    private dialog: MatDialog,
    private suspenseService: RteSuspenseService,
    private alertService: CVSAlertService,
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
    this.form = this.formServ.generateSuspenseForm();
    this.addFieldListner();
    this.usersService.user.subscribe(user => {
      this.userId = user.id;
      this.userEmail = user.email;
    })
  }


  addFieldListner() {
    this.subs.push(
      this.form.get('criteria').valueChanges.subscribe(resp => {
        if (resp) {
          this.currentCriteria = resp;
          this.criteriaDependedentFrom = this.formServ.generateCriteriaDependentForm(resp);
        }
      })
    )
  }

  constructSearchPayload() {
    const payload = {
      "reportType": this.radioOptions.find(itm => itm.value === this.form.get('radio').value).display,
      "criteria": this.form.get('criteria').value,
      "carrier": this.criteriaDependedentFrom.get('carrier')?.value ? this.criteriaDependedentFrom.get('carrier')?.value : "",
      "account": this.criteriaDependedentFrom.get('account')?.value ? this.criteriaDependedentFrom.get('account')?.value : "",
      "group": this.criteriaDependedentFrom.get('group')?.value ? this.criteriaDependedentFrom.get('group')?.value : "",
      "carrierTo": "",
      "accountTo": "",
      "groupTo": "",
    };

    if (this.form.get('criteria').value === "Carrier List") {
      payload.carrier = this.criteriaDependedentFrom.get('carrierList')?.value;
    }

    if (this.form.get('criteria').value === "Range") {
      payload.carrier = this.criteriaDependedentFrom.get('carrierFrom')?.value ? this.criteriaDependedentFrom.get('carrierFrom')?.value : "";
      payload.account = this.criteriaDependedentFrom.get('accountFrom')?.value ? this.criteriaDependedentFrom.get('accountFrom')?.value : "";
      payload.group = this.criteriaDependedentFrom.get('groupFrom')?.value ? this.criteriaDependedentFrom.get('groupFrom')?.value : "";
      payload.carrierTo = this.criteriaDependedentFrom.get('carrierEnd')?.value ? this.criteriaDependedentFrom.get('carrierEnd')?.value : "";
      payload.accountTo = this.criteriaDependedentFrom.get('accountEnd')?.value ? this.criteriaDependedentFrom.get('accountEnd')?.value : "";
      payload.groupTo = this.criteriaDependedentFrom.get('groupEnd')?.value ? this.criteriaDependedentFrom.get('groupEnd')?.value : "";
    }
    return payload
  }

  searchResult() {
    const payload = this.constructSearchPayload();
    this.showGrid = true;
    this.gridData = null;
    //TODO: confirm how to send carrierList
    this.suspenseService.getSuspenseRecords(payload).subscribe(res => {
      if (res.response && res.response.length > 0) {
        this.gridData = res.response;
        this.searchPayload = payload
        this.formRadioVal = this.radioOptions.find(itm => itm.value === this.form.get('radio').value).display
      } else if (res.errorMessage) {
        this.alertService.sendAlert({
          headline: res.errorMessage,
          alertType: CVSAlertType.Warning,
          removedAfterMilliseconds: 10000,
          alertDocumentHolderName: '#header-alert',
        })
        this.formRadioVal = this.radioOptions.find(itm => itm.value === this.form.get('radio').value).display
        this.gridData = [];
        this.showGrid = true;
      } else {
        this.formRadioVal = this.radioOptions.find(itm => itm.value === this.form.get('radio').value).display
        this.gridData = [];
        this.showGrid = true;
      }
    },
      err => {
        this.alertService.sendAlert({
          headline: 'Something went wrong. Please try again.',
          alertType: CVSAlertType.Error,
          removedAfterMilliseconds: 10000,
          alertDocumentHolderName: '#header-alert',
        })
        this.gridData = null;
        this.showGrid = false;
      });


  }

  callScheduleApi(data) {
    const searchObj = this.constructSearchPayload();
    const baseObj = {
      "userId": this.userId.toString(),
      "reportType": searchObj.reportType,
      "reportScheduledStatus": null,
      "reportScheduleDeleted": null,
      "criteriaType": searchObj.criteria,
      "carrierValue": searchObj.carrier !== '' ? searchObj.carrier : null,
      "accountValue": searchObj.account !== '' ? searchObj.account : null,
      "groupValue": searchObj.group !== '' ? searchObj.group : null,
      "carrierToValue": searchObj.carrierTo !== '' ? searchObj.carrierTo : null,
      "accountToValue": searchObj.accountTo !== '' ? searchObj.accountTo : null,
      "groupToValue": searchObj.groupTo !== '' ? searchObj.groupTo : null,
      "createdOn": new Date(),
      "updatedOn": null,
    }

    const payload = { ...data.formData, ...baseObj };
    this.suspenseService.createSchedule(payload).subscribe(res => {
      if (res.response && res.status === "SUCCESS") {
        this.alertService.sendAlert({
          headline: "Scheduled event successfully created",
          alertType: CVSAlertType.Success,
          removedAfterMilliseconds: 10000,
          alertDocumentHolderName: '#header-alert',
        })
      } else if (res.errorMessage) {
        this.alertService.sendAlert({
          headline: res.errorMessage,
          alertType: CVSAlertType.Error,
          removedAfterMilliseconds: 10000,
          alertDocumentHolderName: '#header-alert',
        })
      }
    }, err => {
      this.alertService.sendAlert({
        headline: 'Something went wrong. Please try again.',
        alertType: CVSAlertType.Error,
        removedAfterMilliseconds: 10000,
        alertDocumentHolderName: '#header-alert',
      })
    })
  }

  scheduleEvent() {
    this.dialogConfig.data = {
      id: 1,
      title: 'Schedule Occurence',
      type: 'add_record',
      email: this.userEmail
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    const dialogData = this.dialog.open(SuspenseScheduleComponent, this.dialogConfig);
    dialogData.componentInstance.scheduleData.subscribe(data => this.callScheduleApi(data))
  }

}
