import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {CVSHttpClient} from 'angular-component-library';
import {Contact} from '../models/Contact';
import {Spedm} from '../models/Spedm';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SpedmService {

  spedm: Spedm;
  contact: Contact;
  isNew: boolean;

  constructor(private http: CVSHttpClient) {  }

  getSpedm(id: number): Observable<any> {
    return this.http.get(`${environment.baseFiletrackerUrl}/spedmfiletracker/${id}`);
  }

  getContact(id: number, field: string): Observable<any> {
    return this.http.get(`${environment.baseFiletrackerUrl}/filetracker/contacts/${id}/${field}`);
  }

  getAll(): Observable<any> {
    return this.http.get(`${environment.baseFiletrackerUrl}/spedmfiletracker/all`);
  }

  saveSpedm(spedm: Spedm, type: string): Observable<any> {
    return this.http.post(`${environment.baseFiletrackerUrl}/spedmfiletracker/save?type=${type}`, spedm);
  }

  saveContact(contact: Contact, type: string): Observable<any> {
    return this.http.post(`${environment.baseFiletrackerUrl}/filetracker/contact/save?type=${type}`, contact);
  }

  editContact(contact: Contact, field: string): Observable<any> {
    return this.http.post(`${environment.baseFiletrackerUrl}/filetracker/contacts/update/${field}`, contact);
  }
  deleteContact(emailId: any): Observable<any> {
    return this.http.delete(`${environment.baseFiletrackerUrl}/filetracker/contacts/delete?emailId=` + emailId);
  }

  getNewSpedmClientSetupId(): Observable<any> {
    return this.http.get(`${environment.baseFiletrackerUrl}/spedmfiletracker/clientSetupId`);
  }

  getFileTrackerSpeedm(idno: number) {
    // return this.http.get(`${environment.baseFiletrackerUrl}/spedmfiletracker/filetrackerdetails/SPEDMEvents/${idno}`);
    return null;
  }
  deleteclientonboarding(payload:Array<string>){
    return this.http.post(`${environment.baseFiletrackerUrl}/spedmfiletracker/deleteclientonboarding`,payload);
    
  }
}
