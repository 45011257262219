<div class="popup">
  <mat-dialog-content class="dialog-header">
    <div class="icon-and-title">
      <mat-icon *ngIf="data.type === 'Warning'" svgIcon="error--s" id="warning-icon"></mat-icon>
      <mat-icon *ngIf="data.type === 'Additional Information'" svgIcon="info-circle--s" id="info-icon"></mat-icon>
      <h2>{{data.type}}</h2>
    </div>
    <button id="closeAction" class="cvs-icon-only-btn" mat-dialog-close (click)="closeHandler()" >
      <mat-icon svgIcon="close-btn--s"></mat-icon>
    </button>
  </mat-dialog-content>
  <mat-dialog-content id="bodyTextContent">
    <p [innerHTML]="data.body"></p>
  </mat-dialog-content>
</div>
