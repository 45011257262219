<mat-form-field style="width: 150px">
<!--  <mat-label>Choose an option</mat-label>-->
  <mat-select matInput [id]="params.name" [ngModel]="selectedOption" (ngModelChange)="selectValue($event)" >
      <ng-container>
        <mat-option  *ngFor="let val of dropdownValues"  [value]="val.value">
          <mat-checkbox style="pointer-events: none" [checked]="ischecked(val.value)" [name]="params.name"></mat-checkbox>
          <span style="margin-left: 5px">{{val.label}}</span>
        </mat-option>
      </ng-container>
  </mat-select>
</mat-form-field>

