import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CVSHttpClient } from 'angular-component-library';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {CVSAlertService, CVSAlertType} from 'angular-component-library';
@Injectable({
  providedIn: 'root'
})
export class FileLayoutApiService {

  baseURL = environment.baseCustomMappingUrl;
  constructor(private http: CVSHttpClient,
              private privateHTTP: HttpClient,
              private alertService: CVSAlertService) { }

  getRecordApi() {
    return this.http.get( `${this.baseURL}/filelayouts/getallrecordtypes`);
  }

  getFileLayoutData(record): Observable<any> {
    return this.http.get(`${this.baseURL}/filelayouts/` + record.recordType.toString() + '/' + record.recordName.toString());
  }

  addRercordApi(data) {
   return  this.http.post(`${this.baseURL}/filelayouts/addrecordtype`, data)
   .pipe(catchError((err) => {
    this.showMessage(err.error.message);
    return of(null);
  })
);
  }

  addFileLayoutRecord(field) {
    return this.http.post(`${this.baseURL}/filelayouts/addfieldrecords`, field)
    .pipe(catchError((err) => {
      this.showMessage(err.error.message);
      return of(null);
    })
  );
  }

  editFileLayoutDetail(updatedData) {
   const url = `${this.baseURL}/filelayouts/editfieldrecords`;
   return  this.http.put( url, updatedData).pipe(catchError((err) => {
    this.showMessage(err.error.message);
    return of(null); }));
  }

  deleteFileLayoutRecord(body) {
    return  this.http.post(`${this.baseURL}/filelayouts/deletefilelayout`, body)
    .pipe(catchError((err) => {
      this.showMessage(err.error.message);
      return of(null);
    })
  );
  }

  deleteRecord(record) {
    const url = `${this.baseURL}/filelayouts/deleterecordtype/${record.recordType}/${record.recordName}`;
    return this.http.delete(url)
    .pipe(catchError((err) => {
      this.showMessage(err.error.message);
      return of(null);
    })
  );
  }

  renameRecord(record, newRecord) {
    return this.http.put(`${this.baseURL}/filelayouts/editrecordtypedata/${record.recordType}/${record.recordName}`, newRecord)
    .pipe(catchError((err) => {
      this.showMessage(err.error.message);
      return of(null);
    })
  );
  }

  addAudit(details) {
    return this.http.post(`${this.baseURL}/filelayouts/addAudit`, details)
    .pipe(catchError((err) => {
      this.showMessage(err.error.message);
      return of(null);
    })
  );
  }

  getAuditHistory(record) {
   // getAuditHistory/File Header/999 Rec Type/M3-0006
   return this.http.get(`${this.baseURL}/filelayouts/getAuditHistory/${record.recordType}/${record.recordName}/${record.fieldNumber}`)
   .pipe(catchError((err) => {
    this.showMessage(err.error.message);
    return of(null);
  })
  );
  }

  showMessage(body) {
    this.alertService.sendAlert({
      headline: body,
      alertType: CVSAlertType.Error,
      removedAfterMilliseconds: 3000,
      alertDocumentHolderName: '#file-layout',
    });
  }

  getUser() {
    return this.http.get( `${environment.authorizationUrl}`);
  }
}
