import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AddLogicComponent } from 'src/app/admin/logic-code/add-logic/add-logic.component';
import { EprService } from 'src/app/services/epr.service';
import { UsersService } from 'src/app/services/users.service';
@Component({
  selector: 'app-cag-ref-batch-upload',
  templateUrl: './cagRefBatchUpload.component.html',
  styleUrls: ['./cagRefBatchUpload.component.scss']
})
export class CagRefBatchUploadComponent implements OnInit {
  form: UntypedFormGroup;
  requestTypeList: any[] = ["Insert", "Refresh", "Extract", "Update"]
  selectedFile: any = null;
  showSpinner: Subject<boolean> = new Subject();
  body: any = {};
  id: any;
  reader: FileReader = new FileReader();
  csv: string
  userFullName: string;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddLogicComponent>,
    private usersService: UsersService,
    @Inject(MAT_DIALOG_DATA) data,
    private eprService: EprService) {
    this.usersService.userId.subscribe(id => {
      this.id = id;
    })
    this.usersService.userFullName.subscribe(userFullName => {
      this.userFullName = userFullName;
    })
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      cagTableId: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      requestType: new UntypedFormControl('', [Validators.required]),
      fileData: new UntypedFormControl('', [Validators.required])
    });
  }

  onRequestTypeChange(val){
    if (val === 'Extract') {
      this.form.get('fileData').removeValidators(Validators.required);
      this.form.get('fileData').updateValueAndValidity();
    } else {
      this.form.get('fileData').setValidators([Validators.required]);
      this.form.get('fileData').updateValueAndValidity();
    }
  }


  public get requestTypeVal(): string {
    return this.form.get('requestType').value;
  }

  setRequestType(val) {
    if(val === 'Extract') {
      return 'Select'
    } 
    return val;
  }


  uploadFile(event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    let file = files[0];
    let acceptedType = ['text/plain', 'text/csv']
    if (acceptedType.includes(file.type)) {
      try {
        this.selectedFile = file
        this.reader.readAsText(file);
        this.reader.onload = (e) => {
        this.csv = this.reader.result as string;
        }
      } catch (e) {
        this.selectedFile = null;
        this.body = null;
      }
    } else {
      this.selectedFile = null;
      this.body = null;
    }
    this.form.get('fileData').patchValue(this.body, { emitEvent: false });

  }
  close() {
    this.dialogRef.close(null);
  }

  save() {
    if (this.form.valid) {
      this.showSpinner.next(true);
      const formValue = this.form.getRawValue();
          this.body = {
            clientId: formValue.cagTableId,
            requestType: this.setRequestType(formValue.requestType),
            inputFileName: this.selectedFile && this.selectedFile.name ? this.selectedFile.name : null,
            inputFile: this.csv ? this.csv : null,
            createdBy:  this.userFullName,
            ec: this.id.toString()
          }
      this.eprService.uploadCagRefBatch(this.body).pipe(catchError(error => {
        this.showSpinner.next(false);
        this.dialogRef.close(error);
        return of(error)
      })).subscribe(res => {
        if (res) {
          this.showSpinner.next(false);
          this.dialogRef.close(res);
        }
      }
      )
    } else {
      this.form.markAllAsTouched();
    }

  }
  uploadCancel() {
    this.selectedFile = null;
  }
}
