import {Action} from '@ngrx/store';
import {Copay} from '../models/Copay';
import {Contact} from '../models/Contact';

export const LOAD_ALL_COPAY = '[Copay] Load All Copay';
export const LOAD_ALL_COPAY_SUCCESS = '[Copay] Copay ALL Loaded Success';
export const LOAD_ALL_COPAY_FAILURE = '[Copay] Copay ALL Loaded Failure';
export const LOAD_COPAY = '[Copay] Load COPAY';
export const LOAD_COPAY_SUCCESS = '[Copay] Copay Loaded Success';
export const LOAD_COPAY_FAILURE = '[Copay] Copay Loaded Failure';

export const CLEAR_COPAY_DATA = '[Filetrakcer]  Clearing all Filetracker Data';
export const SAVE_COPAY = '[Filetracker] Save Filetracker';
export const UPDATE_COPAY = '[Filetracker] Update Filetracker';
export const SAVE_COPAY_SUCCESS = '[Filetracker] Save Filetracker Success';
export const SAVE_COPAY_SUCCESS_FAILURE = '[Filetracker] Filetracker Saved Failure';
export const LOAD_CONTACT = '[Contact] Contact Loaded';
export const LOAD_CONTACT_SUCCESS = '[Contact] Contact Loaded Success';
export const SAVE_CONTACT = '[Contact] Save Contact';
export const SAVE_CONTACT_SUCCESS = '[Contact] Save Contact Success';
export const SAVE_CONTACT_SUCCESS_FAILURE = '[Contact] Contact Saved Failure';
export const EDIT_COPAY = '[Filetracker] Edit Filetracker';
export const EDIT_COPAY_SUCCESS = '[Filetracker] Edit Filetracker Success';
export const EDIT_COPAY_SUCCESS_FAILURE = '[Filetracker] Filetracker Edit Failure';
export const DELETE_COPAY = '[Filetracker] Deleting Filetracker...';
export const DELETE_COPAY_SUCCESS = '[Filetracker] Filetracker deleted successfully';
export const FILTER_COPAY_BY_ID = '[Filetracker] Filter Filetrackers By Id';
export const UPDATE_CONTACT = '[Contact] Contact Updated';
export const UPDATE_CONTACT_SUCCESS = '[Contact] Contact Updated Successfully';
export const UPDATE_CONTACT_SUCCESS_FAILURE = '[Contact] Contact Updated Failure';
export const DELETE_CONTACT = '[EPRFEED] Deleting Cont...';
export const DELETE_CONTACT_SUCCESS = '[EPRFEED] EprFeed deleted successfully';
export const LOAD_DATA = 'LoadData';
export const EDIT_MODE = 'Edit';

export class LoadAllCopayAction implements Action {
  readonly type = LOAD_ALL_COPAY;
}

export class LoadAllCopaySuccessAction implements Action {
  readonly type = LOAD_ALL_COPAY_SUCCESS;
  constructor(public payload: Copay[]) { }
}

export class LoadAllCopayFailureAction implements Action {
  readonly type = LOAD_ALL_COPAY_FAILURE;
}
export class LoadCopayAction implements Action {
  readonly type = LOAD_COPAY;

  constructor(public payload: number) {
  }
}
export class ClearCopayAction implements Action {
  readonly type = CLEAR_COPAY_DATA;
}

export class LoadCopaySuccessAction implements Action {
  readonly type = LOAD_COPAY_SUCCESS;

  constructor(public payload: Copay) { }
}

export class LoadCopayFailureAction implements Action {
  readonly type = LOAD_COPAY_FAILURE;
}

export class SaveCopayAction implements Action {
  readonly type = SAVE_COPAY;

  constructor(public payload: Copay) { }
}
export class UpdateCopayAction implements Action {
  readonly type = UPDATE_COPAY;

  constructor(public payload: Copay) { }
}
export class UpdateContact implements Action {
  readonly type = UPDATE_CONTACT;

  constructor(public payload: any) { }
}

export class UpdateContactSuccessAction implements Action {
  readonly type = UPDATE_CONTACT_SUCCESS;

  constructor(public payload: Copay) { }
}

export class UpdateContactFailureAction implements Action {
  readonly type = UPDATE_CONTACT_SUCCESS_FAILURE;
}
export class SaveCopaySuccessAction implements Action {
  readonly type = SAVE_COPAY_SUCCESS;

  constructor(public payload: Copay) { }
}

export class SaveCopayFailureAction implements Action {
  readonly type = SAVE_COPAY_SUCCESS_FAILURE;
}
export class LoadContactAction implements Action {
  readonly type = LOAD_CONTACT;

  constructor(public payload: any) {
  }
}
export class LoadContactSuccessAction implements Action {
  readonly type = LOAD_CONTACT_SUCCESS;

  constructor(public payload: Contact) { }
}
export class SaveContactAction implements Action {
  readonly type = SAVE_CONTACT;

  constructor(public payload: any ) { }
}


export class SaveContactSuccessAction implements Action {
  readonly type = SAVE_CONTACT_SUCCESS;

  constructor(public payload: Contact) { }
}

export class SaveContactFailureAction implements Action {
  readonly type = SAVE_CONTACT_SUCCESS_FAILURE;
}

export class EditCopayAction implements Action {
  readonly type = EDIT_COPAY;

  constructor(public payload: any) { }
}

export class EditCopaySuccessAction implements Action {
  readonly type = EDIT_COPAY_SUCCESS;

  constructor(public payload: Copay) { }
}

export class EditCopayFailureAction implements Action {
  readonly type = EDIT_COPAY_SUCCESS_FAILURE;
}
export class DeleteCopayAction implements Action {
  readonly type = DELETE_COPAY;

  constructor(public payload: number) {
  }
}
export class DeleteCopaySuccessAction implements Action {
  readonly type = DELETE_COPAY_SUCCESS;
}
export class FilterCopayById implements Action {
  readonly type = FILTER_COPAY_BY_ID;

  constructor(public payload: any) {}
}
export class DeleteContactAction implements Action {
  readonly type = DELETE_CONTACT;

  constructor(public payload: number) {
  }
}
export class DeleteContactSuccessAction implements Action {
  readonly type = DELETE_CONTACT_SUCCESS;
}
export class LoadDataAction implements Action {
  readonly type = LOAD_DATA;

  constructor(public payload: any) {
  }
}
export class EditAction implements Action {
  readonly type = EDIT_MODE;

  constructor(public payload: boolean) {
  }
}


export type Actions
  = LoadCopayAction
  | LoadCopaySuccessAction
  | LoadCopayFailureAction
  | LoadAllCopayAction
  | LoadAllCopaySuccessAction
  | LoadAllCopayFailureAction
  | SaveCopayAction
  | UpdateCopayAction
  | SaveCopaySuccessAction
  | SaveCopayFailureAction
  | LoadContactAction
  | LoadContactSuccessAction
  | SaveContactAction
  | SaveContactSuccessAction
  | SaveContactFailureAction
  | EditCopayAction
  | EditCopaySuccessAction
  | EditCopayFailureAction
  | DeleteCopayAction
  | DeleteCopaySuccessAction
  | FilterCopayById
  | ClearCopayAction
  | UpdateContact
  | UpdateContactSuccessAction
  | UpdateContactFailureAction
  | DeleteContactAction
  | DeleteContactSuccessAction
  | LoadDataAction
  | EditAction;

