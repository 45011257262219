<header>
    <div class="container">
        <div class="row">
            <div class="col-4">
                <h1>RTE Metrics</h1>
            </div>
        </div>
    </div>
</header>


<form [formGroup]="rteForm" (submit)="onSubmit()">
    <div class="row">
        <mat-form-field id="carriers" appearance="outline">
            <mat-label>Carriers</mat-label>
            <textarea matInput type="text" formControlName="carriers" required></textarea>
        </mat-form-field>
    </div>
    <div class="row">
        <mat-error *ngIf="!rteForm.get('carriers').valid">
            Carriers can only be 4-9 characters, no special characters, only one space between carriers, and comma
            separated, can enter upto 10 values
        </mat-error>
    </div>

    <div class="row">
        <mat-form-field id="from-date">
            <mat-label>From Date</mat-label>
            <input matInput [matDatepicker]="datepickerFromDate" formControlName="fromDate" required>
            <mat-datepicker-toggle matSuffix [for]="datepickerFromDate"></mat-datepicker-toggle>
            <mat-datepicker #datepickerFromDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field id="to-date">
            <mat-label>To Date</mat-label>
            <input matInput [matDatepicker]="datepickerToDate" formControlName="toDate" required>
            <mat-datepicker-toggle matSuffix [for]="datepickerToDate"></mat-datepicker-toggle>
            <mat-datepicker #datepickerToDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field id="account" class="readonly">
            <input matInput type="text" formControlName="account" placeholder="Account">
        </mat-form-field>
    </div>

    <div class="row d-flex justify-content-end">
        <div class="col-sm-6">
            <mat-form-field id="member-id">
                <input matInput type="text" formControlName="memberId" placeholder="Member Id">
            </mat-form-field>
        </div>
        <div class="col-sm-6">
            <mat-form-field id="message">
                <input matInput type="text" formControlName="message" placeholder="Message">
            </mat-form-field>
        </div>

        <div class="col-sm-6">
            <mat-form-field id="group">
                <input matInput type="text" formControlName="group" placeholder="Group">
            </mat-form-field>
        </div>
    </div>

    <div class="row field-allign">
        <div>
            <mat-form-field>
                <mat-label>Master Segment List</mat-label>
                <mat-select formControlName="masterSegment" multiple>
                    <mat-option *ngFor="let datum of masterSegmentList" [value]="datum.value">
                        {{datum.displayName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Segment Name</mat-label>
                <mat-select formControlName="segmentName" multiple>
                    <mat-option *ngFor="let segment of segmentList" [value]="segment.displayName">
                        {{segment.displayName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Response</mat-label>
                <mat-select formControlName="response">
                    <mat-option *ngFor="let response of statusList" [value]="response.value">
                        {{response.displayName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="button-align">
            <div style="margin-right: 3em;">
                <button id="cancel-edit-btn" class="cvs-btn-primary" [disabled]="rteForm.invalid" type="submit">
                    <mat-icon>save</mat-icon>
                    Submit
                </button>
            </div>
            <a href="https://cvshealth.splunkcloud.com/" target="_blank"> <img src="../../../assets/Splunk_logo.png"
                    alt="Splunk logo" width="150px" height="40px"></a>
        </div>
    </div>
</form>
<div style="cursor:pointer;" (click)="exportView('tableOne')" class="export-button">
    <mat-icon
  id="tableTwoExportViewBtn"
   svgIcon="upload-arrow--s"   class="refresh-btn">
  Export View
</mat-icon>
<span class="export-txt"> Export </span>
</div>

<ag-grid-angular class="ag-theme-alpine" #gridRTEMetric [ngStyle]="{'height': 500 + 'px' }" id="gridRTEMetric"
    [context]="contextObj" [suppressScrollOnNewData]="true" [rowData]="dataRows" (gridReady)="onGridReady($event)">
</ag-grid-angular>
