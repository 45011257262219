import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AddNewParameterComponent } from './add-new-parameter/add-new-parameter.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ColDef } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { AddNewAttributesComponent } from './add-new-attributes/add-new-attributes.component';
import {ParamMapService} from '../../services/param-map.service';
import {CVSAlertService, CVSAlertType} from 'angular-component-library';

@Component({
  selector: 'app-parm-mapping',
  templateUrl: './parm-mapping.component.html',
  styleUrls: ['./parm-mapping.component.scss']
})
export class ParmMappingComponent implements OnInit, AfterViewInit {
  @ViewChild('grid1') grid1: AgGridAngular;
  dialogConfig = new MatDialogConfig();

  public rowData: any[];


  constructor( private dialog: MatDialog,
               private paramMapService: ParamMapService,
               private alertService: CVSAlertService) {
  }

  public columnDefs: ColDef[] = [
  {headerName: 'Parameter Type', field: 'parameterType', sortable: true, filter: true, resizable: true},
  {headerName: 'Parameter', field: 'parameter', sortable: true, filter: true, resizable: true},
  {headerName: 'File Type', field: 'fileType', sortable: true, filter: true, resizable: true},
  {headerName: 'Rule', field: 'rule', sortable: true, filter: true, resizable: true},
  {headerName: 'Description', field: 'description', sortable: true, filter: true, resizable: true},
  {headerName: 'Available Values', field: 'availableValues', sortable: true, filter: true, resizable: true},
  {headerName: 'Default Value', field: 'defaultValue', sortable: true, filter: true, resizable: true},
  {headerName: 'Level', field: 'paramLevel', sortable: true, filter: true, resizable: true},
  ];

  // public rowData: any[] | null = [
  //   { parametertype: 'A', parameter: 'cvs', filetype: 'alexa', rule: 'plx',
  // description: 'c1', availablevalues: 'kl', defaultvalues: 'dcw', level: 'dfv'},
  // ];

  ngOnInit() {
    this.getTableData();
  }
  ngAfterViewInit(): void {
    return;
    // this.paramMapService.getAll().subscribe(rowData => {
    //   this.rowData = rowData;
    // });
    // const paramMap$ = this.store.select(fromRoot.getAllParamMaps) as Observable<ParamMap[]>;
    // paramMap$.subscribe(data => {
    //   this.rowData = data;
    // });

    // this.paramMapService.getAll().subscribe((paramMapData) => {
    //   const tableData = [];
    //   paramMapData.forEach((res: any) => {
    //     const paramMapObject: any = {
    //       eprId: res.eprId,
    //       parameterType: res.parameterType,
    //       parameter: res.parameter,
    //       fileType: res.fileType,
    //       rule: res.rule,
    //       description: res.description,
    //       availablevalues: res.availablevalues,
    //       defaultvalue: res.defaultvalue,
    //       paramLevel: res.paramLevel
    //     };
    //     tableData.push(paramMapObject);
    //   });
    //   this.grid1.api.setRowData(tableData);
    // });

  }

  getTableData() {
    this.rowData = [];
    this.paramMapService.getAll().subscribe(rowData => {
      this.rowData = rowData;
    });
  }
  addNewParameter() {
    const dialogData = this.dialog.open(AddNewParameterComponent, this.dialogConfig);
    dialogData.afterClosed().subscribe(res => {
      this.getTableData();
      if (res) {
        const obj = {
          data: res
        };
        this.onRowClicked(obj);
      }
    });
  }
  // addNewAttributes(){
  //   const dialogData = this.dialog.open(AddNewAttributesComponent,{ backdropClass:'background_none',panelClass: 'addAttributeClass' });
  // }
  onRowClicked($event) {
    const dialogData = this.dialog.open(AddNewAttributesComponent,
      { backdropClass: 'background_none', panelClass: 'addAttributeClass' , data : $event.data});
    dialogData.afterClosed().subscribe(res => {
      let message = '';
      if ( res && res.issave) {
        message = 'New Parameter Saved';
      } else if ( res && res.isdelete) {
        message = 'Parameter Deleted Successfully';
      }
      if ( res && (res.issave || res.isdelete)) {
        this.alertService.sendAlert({
          headline: message,
          alertType: CVSAlertType.Success,
          removedAfterMilliseconds: 5000,
          alertDocumentHolderName: '#master-data-alert',
        });
        this.getTableData();
      }
    });
  }
  private setRowData( rowData) {
    this.grid1.api.setRowData(rowData);
  }
}
