<div class="modal-backdrop">
  <div class="modal">
    <div class="modal-header">
      <h5 class="modal-title">Detail Message Edit </h5>
      <button type="button" class="close-btn" aria-label="Close" (click)=closeModal($event)>X</button>
    </div>
    <div class="modal-body">
      <div class="search-box">
<!--          <input type="text" placeholder="Search Codes" [(ngModel)]="searchTerm"  class="search-input" (input)="searchCodes()">-->
<!--          <mat-icon style="margin-left: -35px;margin-top: 7px;"svgIcon="search&#45;&#45;s"></mat-icon>-->
        <mat-form-field style="margin-top: -25px; margin-bottom: -20px;" appearance="standard">
          <input matInput placeholder="Search" id = "search" (keyup)="filterData($event)" autocomplete="off">
          <mat-icon svgIcon="search--s"></mat-icon>
          <mat-hint>Search Content</mat-hint>
        </mat-form-field>

        <div id="clearAndAllBtns" style="display: flex;">
          <button class="cvs-btn-text-only" (click)="selectAll()">
            <mat-icon svgIcon="check--xs"></mat-icon>
            <span>Select All</span>
          </button>
          <button class="cvs-btn-text-only" [disabled]="!boxesChecked" (click)="clearSelection()">
            <mat-icon svgIcon="close--xs"></mat-icon>
            <span>Clear Selection</span>
          </button>

          
        </div>
      </div>
      <div><h3>Detail Messages</h3></div>
        <table class="table">
          <thead>
          <tr>
            <th>Include</th>
            <th>Type</th>
            <th>MBR or Both</th>
            <th>Description</th>
          </tr>
          </thead>
          <tbody style="height: 250px; overflow: auto">
            <tr *ngFor="let message of filteredMessages">
            <td style="width:20px;">
              <input type="checkbox" name="messageCode" [value]='message.message' (change)="onChange()">
            </td>
            <td> {{message.message}}</td>
            <td> {{message.reportMessageType}}</td>
            <td>{{message.messageDescription}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class= "btn-cancel cvs-btn-white"(click)="onCancel($event)">Cancel</button>
        <button type="button"  class="cvs-btn-primary" (click)="onSelect($event)">Update</button>
      </div>
    </div>
</div>
