import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import * as saveAs from 'file-saver';
import { CellRendererComponent } from '../admin/extract-epr-feed-gui/cell-renderer.component';
import { DownloadBtnCellRendererComponent } from '../common/DownloadBtnCellRenderer';
import { StatusPopoverComponent } from '../dashboard/status-popover/status-popover.component';
import { FiletrackerService } from '../services/filetracker.service';
import * as ColumnSets from '../threshold-dialog/thresholdColumnSets';



export interface DialogData {
  selected: string;
  data: any;
}

export interface ThresholdLogInput {
  meaud: string;
}

export interface AuditLogInput {
  memid: string;
  mecar: string;
  melib: string;
  mefile: string;
  membr: string;
}

export interface CommentInput {
  pli: string;
  carrier: string;
  fileName: string;
  memberName: string;
  library: string;
  commentedBy: string;
  commentedText: string;
}



@Component({
  selector: 'app-eligibility-dialog',
  templateUrl: './eligibility-dialog.component.html',
  styleUrls: ['./eligibility-dialog.component.scss']
})
export class EligibilityDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('eligibilityGrid') eligibilityGrid: AgGridAngular;
  contextObj: any;
  frameworkComponents: any;
  isEligibility = false;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private filetrackerService: FiletrackerService,
    private dialogRef: MatDialogRef<EligibilityDialogComponent>,

  ) {

    this.contextObj = {
      componentParent: this
    };
    this.frameworkComponents = {
R: CellRendererComponent,
customToolTip: StatusPopoverComponent,
downloadAction: DownloadBtnCellRendererComponent
};
  }

  ngOnInit() {
    if (this.data && this.data.body.screenName !== undefined) {
      if (this.data.body.screenName) {
      this.isEligibility = true;
      } else {
      this.isEligibility = false;
      }
    }
  }
  ngAfterViewInit(): void {
    this.eligibilityGrid.gridOptions.defaultColDef = {
      flex: 0,
      minWidth: 50,
      editable: true,
      resizable: false
    };
    if (this.data && this.data.body.screenName !== undefined) {
      if (this.data.body.screenName) {
        this.eligibilityGrid.api.setColumnDefs(ColumnSets.eligibilityCols);
      } else {
        this.eligibilityGrid.api.setColumnDefs(ColumnSets.SpeedmCols);
      }
    }

    this.eligibilityGrid.gridOptions.tooltipShowDelay = 0;
    const modalInputData = this.data.body && this.data.body.globalId;

    if (modalInputData) {
    this.createEligibilityData(modalInputData);
    }
  }
  createEligibilityData(modalInputData) {
    this.filetrackerService.getFileTrackerModalData(modalInputData).subscribe(data => {
      this.eligibilityGrid.api.setRowData(data);
    });
  }

  colorInGrid = (params) => {
    const currVal = params.data;
    if (Number(currVal.calculatedThreshold) > Number(currVal.definedThreshold)) {
      return {'background-color': '#fae6e6'};
    }
    return null;
  }
  closeDialog(): void {
    this.dialogRef.close(true);
  }
  exportDetails() {
    const date = new Date();
    const fileName = 'File_Tracking_Details_' + date.getTime() + '.csv';
    const fileData = new Blob([this.eligibilityGrid.api.getDataAsCsv()], {type: 'text/csv'});
    saveAs(fileData, fileName);
  }



}

