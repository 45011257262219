import {InputType} from '../../models/FormFieldConfig';
import {ChecklistTask} from '../../models/ChecklistTask';

export const discoveryTasksConfig = [
  {
    label: 'Eligibility Discovery Meeting',
    formControlName: 'discActDate'
  },
  {
    label: 'Initiate Custom Map',
    formControlName: 'bSRActDate'
  },
  {
    label: 'Document Requirements',
    formControlName: 'fileLayoutActDate'
  },
  {
    label: 'Setup Pre-Load Identifier',
    formControlName: 'pLIActDate'
  },
  {
    label: 'Setup Group PLI',
    formControlName: 'grpPLISetup'
  },
  {
    label: 'Request Library',
    formControlName: 'hEATActDate'
  },
  {
    label: 'Fill out COLA form',
    formControlName: 'cOLAActDate'
  },
  {
    label: 'Library Created',
    formControlName: 'libraryActDate'
  },
  {
    label: 'Submit NDM/FTP form for Reports',
    formControlName: 'fTPFormLoadRptsCmpltDte'
  },
  {
    label: 'Approval of File Format Req',
    formControlName: 'clntCstmReqSignOffDte'
  },

] as ChecklistTask[];

export const setupTasksConfig = [

  {
    label: 'Verify Client Code across Carriers',
    formControlName: 'clientCodeVerifyDate'
  },
  {
    label: 'Create Pre Pre-Load Profile',
    formControlName: 'prePreCpltDate'
  },
  {
    label: 'Create Pre-Load Profile',
    formControlName: 'preLoadActDate'
  },
  {
    label: 'Create Stage Profile',
    formControlName: 'stageActDate'
  },
  {
    label: 'Create Group Stage Profile',
    formControlName: 'grpStageCpltDate'
  },
  {
    label: 'Create Req. Mbr Field Detail',
    formControlName: 'requiredMemFieldsActDate'
  },
  {
    label: 'Create CDD Profile',
    formControlName: 'cDDCmpltDte'
  },
  {
    label: 'Add Carrier/s to "CDDEXT" Carrier List',
    formControlName: 'cDDCarLstCmpltDte'
  },
  {
    label: 'Create Crosswalk Profile',
    formControlName: 'xwalkProfileActDate'
  },
  {
    label: 'Setup Outq (incl. email, T4 & card)',
    formControlName: 'outQActDate'
  },
  {
    label: 'Create Card Profile',
    formControlName: 'iDCardShellActDate'
  },

  {
    label: 'Have EDI create RJS setup',
    formControlName: 'rJSCompDate'
  },
  {
    label: 'Create Incl/Excl List (for TMR)',
    formControlName: 'crtCAGListCmpltDate'
  },
  {
    label: 'File Tracker On-Boarding',
    formControlName: 'filTrkrOnBrdCmpltDte'
  },
  {
    label: 'Setup Fastlink (Member)',
    formControlName: 'fastlinkActDate'
  },
  {
    label: 'Setup Fastlink (Group)',
    formControlName: 'fastlinkGroupDate'
  },
  {
    label: 'Add to SHRTIDSRCH List',
    formControlName: 'sHRTIDSRCHActDate'
  },
  {
    label: 'Build one-time bridge file',
    formControlName: 'EZEConfigActDate'
  },
  {
    label: 'Review EPR and Submit to QC',
    formControlName: 'cMFlagReviewDate'
  },
  {
    label: 'Send Setup to Int Feed Team via SFDC',
    formControlName: 'sendToJimDate'
  },
  {
    label: 'EPR Sign-off Date',
    formControlName: 'cMSignOffDate',
    extraTaskField: {
      label: 'EPR Sign-off Status',
      controlName: 'cMStatus',
      inputType: InputType.DROPDOWN,
      options: ['', 'EC Validation', 'Pending (Client / Vendor)', 'No Sign Off', 'Signed Off']
    }
  },

] as ChecklistTask[];

export const testTasksConfig = [
  {
    label: 'Request Test File from Client',
    formControlName: 'reqTstFileCompDate'
  },
  {
    label: 'File Received',
    formControlName: 'tstFileRecCompDate'
  },
  {
    label: 'Test Client File in GIS',
    formControlName: 'gISTestCmpltDate'
  },
  {
    label: 'Test pre-pre',
    formControlName: 'prePreTstCpltDate'
  },
  {
    label: 'Visually Review File (Informatica)',
    formControlName: 'testVisualReviewCmpltDate'
  },
  {
    label: 'Test in ACE',
    formControlName: 'loadTstFileCompDate'
  },
  {
    label: 'Verify 4rx ID Configuration',
    formControlName: 'fourrxIDconfigverifydate'
  },
  {
    label: 'Client Testing Sign-Off',
    formControlName: 'clientTstSignOffCmpltDte'
  }
] as ChecklistTask[];

export const integratedFeedsTasksConfig = [
  {
    label: 'Verify Plan Design in QL (prior to BL)',
    formControlName: 'vrfyPlanDesignQL'
  },
  {
    label: 'Core ID Offset (prior to BL)',
    formControlName: 'iCACoreIDOffset'
  },
  {
    label: 'Core Alt Cardholder offset (prior to BL)',
    formControlName: 'iCACoreAltOffset'
  },
  {
    label: 'X-walk ID Field (prior to BL)',
    formControlName: 'iCAXwalkIDField'
  },
  {
    label: 'Eligibility Settings (prior to BL)',
    formControlName: 'iCAEligSettings'
  },
  {
    label: 'Matching Rule (prior to BL)',
    formControlName: 'iCAMatchRule'
  },
  {
    label: 'Network Settings (prior to BL)',
    formControlName: 'iCANetworkSettings'
  },
  {
    label: 'Primary ID Choice (prior to BL)',
    formControlName: 'iCAPrimIDChoice'
  },
  {
    label: 'Verify CAG Process Flags (prior to BL)',
    formControlName: 'vrfyCAGProcessFlags'
  },
] as ChecklistTask[];

export const baselineTasksConfig = [
  {
    label: 'Verify QL Flag set to Y (prior to BL)',
    formControlName: 'qLFlagCompDate'
  },
  {
    label: 'Verify EDI Flag set to Y (prior to BL)',
    formControlName: 'eDWFlgCompDate'
  },
  {
    label: 'Verify Plans attached to Groups (prior to BL)',
    formControlName: 'plansCompDate'
  },
  {
    label: 'Verify BPG Logic (prior to BL)',
    formControlName: 'bPGCompDate'
  },
  {
    label: 'Verify Xwalk Prefix Available',
    formControlName: 'vrfyXwlkAvCmpltDte'
  },
  {
    label: 'Perform ID Conversion',
    formControlName: 'iDCT4CasetoIFCmpltDte'
  },
  {
    label: 'Pull a Recon',
    formControlName: 'reconCmpltDate'
  },
  {
    label: 'Run a Compare against New File',
    formControlName: 'compareCmpltDate'
  },
  {
    label: 'Obtain Approval to Load',
    formControlName: 'obtApprv2LoadCmpltDt'
  },
  {
    label: 'Load Baseline',
    formControlName: 'baselineCompDate'
  },
  {
    label: 'Verify Load',
    formControlName: 'verifyBLLoadCompDate'
  },
  {
    label: 'Send 4rx Screenshots to Anna',
    formControlName: 'fourrxscreentoannacmpltdate'
  },
  {
    label: 'Confirm data sent to QL',
    formControlName: 'bL2QLCompDate'
  }

] as ChecklistTask[];

export const idCardsConfig = [
  {
    label: 'Request Manual Card File via EAR',
    formControlName: 'reqManCrdFileCmpltDte'
  },
  {
    label: 'QC Manual Card File',
    formControlName: 'qCManCrdFileCmpltDte'
  },
  {
    label: 'Manual Card File sent to Vendor',
    formControlName: 'manCrdFileSntVendorCmpltDte'
  },
  {
    label: 'QC Card Proof',
    formControlName: 'qCCardProofCmpltDate'
  },
  {
    label: 'Request Autorun Code via EAR',
    formControlName: 'reqAutoRunCdCmpltDt'
  }
] as ChecklistTask[];

export const goLiveConfig = [
  {
    label: 'Claims Verification',
    formControlName: 'claimsVerificationCompDate'
  },
  {
    label: 'Close out Project Tracker',
    formControlName: 'verifyLiveLoadCompDate'
  }
] as ChecklistTask[];
