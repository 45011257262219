import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {CVSHttpClient} from 'angular-component-library';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ProcessingFileDB } from '../models/PermissionsObject';

@Injectable({
  providedIn: 'root'
})
export class BalancesheetService {

  constructor(private http: CVSHttpClient) {  }

  getBalanceSheet(request: any, ssoeFlag= false,db=null): Observable<any> {
    let ssoeBaseUrl = environment.baseUrlSSOE;
    if(ssoeFlag && db){
    ssoeBaseUrl = this.getSSoeBaseUrl(db);
    }
    const baseURL = ssoeFlag ? ssoeBaseUrl: environment.rxClaimUrl;
    if(ssoeFlag){
      console.log("getBalanceSheet: - ", baseURL)
      return this.http.get(`${baseURL}/media/status?fastLinkMediaId=${request.meaud}&aggregate=true`);
    } else{
      return this.http.post(`${baseURL}/balanceSheet`, request);
    }
  }

  createBalanceSheetRequest(request: any, ssoeFlag=false, db=null): Observable<any> {
    let ssoeBaseUrl = environment.baseUrlSSOE;
    if(ssoeFlag && db){
    ssoeBaseUrl = this.getSSoeBaseUrl(db);
    }
    const baseURL = ssoeFlag ? ssoeBaseUrl: environment.baseUrl;
    return this.http.post(`${baseURL}/dialog/balanceSheet`, request);
  }

  getBalanceSheetSummaryReport(request: any, ssoeFlag=false, db=null): Observable<any> {
    let ssoeBaseUrl = environment.baseUrlSSOE;
    if(ssoeFlag && db){
    ssoeBaseUrl = this.getSSoeBaseUrl(db);
    }
    const baseURL = ssoeFlag ? ssoeBaseUrl: environment.rxClaimUrl;
    if(ssoeFlag){
      let url = new URL(baseURL);
      return this.http.downloadFile(`${url.origin}/reporting-engine/media/report/adhoc?fastLinkMediaId=${request.auditNumber}&pli=${request.pli}&reportType=SUMMARY`);
    }
    else{
      return this.http.post(`${baseURL}/balanceSheet/summary`, request);
    }

  }

  getBalanceSheetDetailsReport(request: any, ssoeFlag=false, db=null): Observable<any> {
    let ssoeBaseUrl = environment.baseUrlSSOE;
    if(ssoeFlag && db){
    ssoeBaseUrl = this.getSSoeBaseUrl(db);
    }
    const baseURL = ssoeFlag ? ssoeBaseUrl: environment.rxClaimUrl;
    if(ssoeFlag){
      let url = new URL(baseURL);
      return this.http.downloadFile(`${url.origin}/reporting-engine/media/report/adhoc?fastLinkMediaId=${request.auditNumber}&pli=${request.pli}&reportType=DETAIL`);
    }
    else{
      return this.http.post(`${baseURL}/balanceSheet/details`, request);
    }

  }

  getFilenameFromContentDisposition(contentDisposition: string) {
    if(!contentDisposition){
      return 'unknown'
    }
    const matches= /filename="(.+)"/.exec(contentDisposition);
    return matches && matches[1]? matches[1]: "unknown";
  }

  getSSoeBaseUrl(db: ProcessingFileDB): string {
    let ssoeBaseUrl;
    if(db === ProcessingFileDB.ADS){
      ssoeBaseUrl = environment.baseUrlSSOE_Ads
     }else if(db === ProcessingFileDB.PRD){
      ssoeBaseUrl = environment.baseUrlSSOE
     }else if(db === ProcessingFileDB.BETA){
      ssoeBaseUrl = environment.baseUrlSSOE_Beta
     }
     return ssoeBaseUrl;
  }
}
