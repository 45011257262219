import {Action} from '@ngrx/store';
import {ClearEprAction, CopyEprAction} from './epr.action';

export const CHECK_IF_CROSS_FORM_POPULATIONS_NEEDED = '[QUESTIONNAIRE] Check To see if any fields need to be populated on other forms';
export const UPDATE_QUESTIONNAIRE_FORM = '[QUESTIONNAIRE] Update Questionnaire Form';
export const LOAD_QUESTIONNAIRE_FORM = '[QUESTIONNAIRE] Load Questionnaire Form';
export const LOAD_QUESTIONNAIRE_FORM_SUCCESS = '[QUESTIONNAIRE] Load Questionnaire Form Success';
export const DELETE_ADDITIONAL_CONTACT = '[QUESTIONNAIRE] Delete Additional Contact Entry';
export const DELETE_PREVIOUS_EC = '[QUESTIONNAIRE] Delete Previous EC Entry';
export const DELETE_AUDIT_HISTORY = '[QUESTIONNAIRE] Delete Delete Audit History';
export const DELETE_PREFIX_ROW = '[QUESTIONNAIRE] Delete Prefix Row';

export class CheckIfCrossFormPopulationsNeededAction implements Action {
  readonly type = CHECK_IF_CROSS_FORM_POPULATIONS_NEEDED;

  constructor(public payload: any) { }
}

export class UpdateQuestionnaireFormAction implements Action {
  readonly type = UPDATE_QUESTIONNAIRE_FORM;

  constructor(public payload: any) {
  }
}

export class LoadQuestionnaireFormAction implements Action {
  readonly type = LOAD_QUESTIONNAIRE_FORM;
  readonly id: number;

  constructor(public payload: any) {

    this.id = 1;
  }
}

export class LoadQuestionnaireFormActionSuccess implements Action {
  readonly type = LOAD_QUESTIONNAIRE_FORM_SUCCESS;

  readonly id: number;

  constructor(public payload: any) {
    this.id = 1;
  }
}


export class DeleteAdditionalContactAction implements Action {
  readonly type = DELETE_ADDITIONAL_CONTACT;

  constructor(public payload: number) {
  }
}

export class DeletePrefixRow implements Action {
  readonly type = DELETE_PREFIX_ROW;

  constructor(public payload: number) {
  }
}

export class DeletePreviousEcAction implements Action {
  readonly type = DELETE_PREVIOUS_EC;

  constructor(public payload: number) {
  }
}

export class DeleteAuditHistoryAction implements Action {
  readonly type = DELETE_AUDIT_HISTORY;

  constructor(public payload: number) {
  }
}

export type Actions =
  UpdateQuestionnaireFormAction | LoadQuestionnaireFormAction | LoadQuestionnaireFormActionSuccess
  | CheckIfCrossFormPopulationsNeededAction | ClearEprAction | CopyEprAction;
