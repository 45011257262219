import { ITooltipParams } from "@ag-grid-community/core";
import { SegDropdownCellRendererComponent } from "./seg-dropdown-cell-renderer";

const toolTipValueGetter = (params: ITooltipParams) => ({
  value: params.value,
});

export const scheduledEventsGridCol = [
  {
    headerName: '',
    width: 50,
    lockPosition: true,
    field: 'action',
    cellRendererFramework: SegDropdownCellRendererComponent,
    resizable: true
  },
  {
    headerName: 'Report',
    editable: false,
    field: 'report',
    resizable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Status',
    editable: false,
    field: 'reportScheduledStatus',
    resizable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' },
    cellRenderer: params => {
      return params.value === 'A' ? 'Active' : 'Inactive'
    }
  },
  {
    headerName: 'Report Name',
    editable: false,
    field: 'reportName',
    resizable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Report Type',
    editable: false,
    field: 'reportType',
    resizable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Criteria',
    editable: false,
    field: 'criteriaType',
    resizable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Schedule Occurence',
    editable: false,
    field: 'createdOn',
    resizable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' },
    cellRenderer: params => {
      switch (params.data.scheduleRecord.occuranceType) {
        case 'Daily':
          return `${params.data.scheduleRecord.numOfDays ? params.data.scheduleRecord.numOfDays : ''}  ${params.data.scheduleRecord.dayFrequency} ${params.data.scheduleRecord.occuranceType} ${params.data.scheduleRecord.nextScheduleDate ? ': ' + params.data.scheduleRecord.nextScheduleDate : ''}`
        case 'Weekly':
          return `${params.data.scheduleRecord.weekFrequency} ${params.data.scheduleRecord.occuranceType} ${params.data.scheduleRecord.weekDay?.join(',')}${params.data.scheduleRecord.nextScheduleDate ? ': ' + params.data.scheduleRecord.nextScheduleDate : ''}`
        case 'Monthly':
          return `${params.data.scheduleRecord.monthlyFrequency} ${params.data.scheduleRecord.occuranceType} ${params.data.scheduleRecord.weekDayForMonth ? params.data.scheduleRecord.weekDayForMonth : 'Day ' + params.data.scheduleRecord.monthDate}${params.data.scheduleRecord.nextScheduleDate ? ': ' + params.data.scheduleRecord.nextScheduleDate : ''}`;
        case 'Yearly':
          return `${params.data.scheduleRecord.dayOfMonth} ${params.data.scheduleRecord.occuranceType} ${params.data.scheduleRecord.weekDayForMonth ? params.data.scheduleRecord.weekDayForMonth : 'Day ' + params.data.scheduleRecord.monthDate} ${params.data.scheduleRecord.frequencyOfYear}${params.data.scheduleRecord.nextScheduleDate ? ': ' + params.data.scheduleRecord.nextScheduleDate : ''}`;
      }
    }
  },
  {
    headerName: 'Last Occurence',
    editable: false,
    field: 'updatedOn',
    resizable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' },
    cellRenderer: params => {
      return new Date(params.value).toLocaleString()
    }
  },
  {
    headerName: 'Recipients',
    editable: false,
    field: 'recipients',
    resizable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' },
    tooltipComponent: 'toolTip',
    tooltipField: 'recipients'
  },
];

