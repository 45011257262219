import { Component, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as moment from "moment";

@Component({
  template: `<div class="backup-ec-container">
  <span>{{ this.displayValue }}</span>
  <app-out-of-office-icon-renderer
  *ngIf="oooExists"
  #tooltip
  class="icon backup-ec-icon"
  [fromDate]="oooFromDate"
  [toDate]="oooToDate"
  [isInactive]="isActive"
  [backupName]="backupName"
  [placement]="'bottom'">
</app-out-of-office-icon-renderer>
</div>`,
  styles: [
    `
  .backup-ec-container{
    display:flex;
    justify-content: space-between;
    width: 170px;
  }
  .backup-ec-icon {
    margin-left: 5px;
    font-size: 1.4em;
    vertical-align: middle;
    color: #F4642A;

    .mat-icon {
      width:16px;
      height:16px;
      overflow:visible;
    }
  }
  `,
  ],
})
export class BackupEcCellRendererComponent implements ICellRendererAngularComp {
  public displayValue!: string;
  public oooExists: boolean = false;
  public isActive: boolean = false;
  public oooFromDate: string = '';
  public oooToDate: string = '';
  public backupName: string = '';

  hasOOOExpiredWithTime(endTime, startTime) {
    let endDateTime = new Date(endTime)
    let startDateTime = new Date(startTime)
    let currentDateTime = moment();
    const isBetween = currentDateTime.isBetween(startDateTime, endDateTime)

    return isBetween
  }


  agInit(params: any): void {
    if (params.value) {
      if (params?.activeEc === 'false' || params?.activeEc === false) {
        this.isActive = false
        this.displayValue = params?.data[params.colDef.field];
        this.oooFromDate = params?.ecOOOFromDateTimestamp ? params?.ecOOOFromDateTimestamp : "";
        this.oooToDate = params?.ecOOOToDateTimestamp ? params?.ecOOOToDateTimestamp : "";
        this.oooExists = true
        this.backupName = params?.backupName ? params?.backupName : "";
      } else if(params?.primaryUserStatus === 'I' ){
        this.displayValue = params.primaryUser
        this.oooExists = true,
        this.isActive = true
        this.oooFromDate = params?.primaryUserOOOFromDateTimestamp ? params?.primaryUserOOOFromDateTimestamp : "";
        this.oooToDate = params?.primaryUserOOOToDateTimestamp ? params?.primaryUserOOOToDateTimestamp : "";
      }else if(params?.secondaryUserStatus === 'I' ){
        this.oooExists = true;
        this.isActive = true
        this.displayValue = params.secondaryUser
        this.oooFromDate = params?.secondaryUserOOOFromDateTimestamp ? params?.secondaryUserOOOFromDateTimestamp : "";
        this.oooToDate = params?.secondaryUserOOOToDateTimestamp ? params?.secondaryUserOOOToDateTimestamp : "";
      }
      else {
        this.displayValue = params?.data[params.colDef.field];
        this.oooFromDate = params?.ecOOOFromDateTimestamp ? params?.ecOOOFromDateTimestamp : "";
        this.oooToDate = params?.ecOOOToDateTimestamp ? params?.ecOOOToDateTimestamp : "";
        this.oooExists = this.hasOOOExpiredWithTime(params?.ecOOOToDateTimestamp, params?.ecOOOFromDateTimestamp)
        this.isActive = params?.activeEc;
        this.backupName = params?.backupName ? params?.backupName : "";
      }
    }
  }

  refresh(params: ICellRendererParams) {
    return false;
  }
}
