<div class="header">
  <mat-icon [ngClass]="{'header-icon-advance': data.icon === 'check-circle--s','header-icon-reset': data.icon === 'reorder-simple--s', 'header-icon-cancel': data.icon === 'close-circle-f--s'}" [svgIcon]="data.icon"></mat-icon>
<h1 mat-dialog-title class="header-text "><b>Confirm {{data.message | uppercase}}</b>
</h1>
</div>
<cvs-loader-overlay-container [isLoading]="confirmClicked" loadingMessage="">
<div mat-dialog-content>
    <textarea class="text-area-input" placeholder="Enter notes" [(ngModel)]="thresholdConfirmInput">
    </textarea>
</div>
</cvs-loader-overlay-container>
<div mat-dialog-actions>
  <p><b>Once confirmed action cannot be undone</b></p>

  <button mat-button class="epr-backbutton" (click)="dialogRef.close(); action='back'">Back</button>
  <button mat-button class="cvs-btn-primary" [disabled]="!thresholdConfirmInput"
    (click)="data.onConfirm(); closeDialogAfterConfirmation(); confirmClicked = true; action='confirm'">Confirm</button>
</div>