import { Injectable } from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {WebEprFeed} from '../models/WebEprFeed';

@Injectable({
  providedIn: 'root'
})
export class WebEprFeedService {

  constructor(private http: CVSHttpClient) {  }

  get(url: string): Observable<any>  {
    return this.http.get(`${environment.baseUrl}/${url}`);
  }


  getSpecificWebEprFeed(eprid: number): Observable<any> {
    return this.http.get(`${environment.baseUrl}/WebEprFeedData/epr/${eprid}`);
  }

  getAll(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/WebEprFeedData/all`);
  }

  getDataByPage(pageNumber: number): Observable<any> {
    return this.http.get(`${environment.baseUrl}/WebEprFeedData/pagination?pageNo=${pageNumber}`);
  }

  saveWebEprFeed(webEprFeed: WebEprFeed): Observable<any> {
    return this.http.post(`${environment.baseUrl}/WebEprFeedData/eprfeed/all/save`, webEprFeed);
  }

  saveWebEprFeedsList(webEprFeed: WebEprFeed[], type: string): Observable<any> {
    return this.http.post(`${environment.baseUrl}/WebEprFeedData/eprfeed/all/save` + '?type=' + type, webEprFeed);
  }
  editWebEprFeed(webEprFeed: any): Observable<any> {
    return this.http.put(`${environment.baseUrl}/WebEprFeedData/update`, webEprFeed);
  }}
