import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AgGridAngular } from 'ag-grid-angular';
import { Observable, Subscription , Subject, forkJoin} from 'rxjs';
import { map } from 'rxjs/operators';
import {AppState, getEprId, getEPRState, getUserId} from 'src/app/reducers';
import { MappingApiService } from 'src/app/services/mapping-api.service';
import { MappingFormService } from '../mapping-form.service';
import * as ColumnSets from '../mappingColumnSets';
import * as html2pdf from 'html2pdf.js';
import {InformationDialogComponent} from '../common-components/information-dialog/information-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { EprService } from 'src/app/services/epr.service';
import {MAPPING_STATUS} from '../mapping-documents.enum';
import {UsersService} from "../../../services/users.service";
import {DeleteBtnCellRendererComponent} from "../../../common/DeleteBtnCellRenderer";
import {CopayService} from "../../../services/copay.service";

@Component({
  selector: 'app-mapping-requirements',
  templateUrl: './mapping-requirements.component.html',
  styleUrls: ['./mapping-requirements.component.scss']
})
export class MappingRequirementsComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  options: any = null;
  @Input() isNewDoc: boolean;
  @Input() mappingDocument: any;
  @Output() showWarning = new EventEmitter();
  @Output() is834FormatOutput = new EventEmitter();
  eprId: any;
  userId: any;
  subsForFields: Subscription[] = [];
  subListner: Subscription[] = [];
  showValidation = false;
  rowData: any = [];
  editMode = false;
  private html2pdf: any = html2pdf();
  mappingguide: any = [];
  clientdetails: any = [];
  pliAndEcNames: any;
  showSpinner: Subject<boolean> = new Subject();
  toolTip: any;
  validationClass: any = {};
  is834Format: boolean;
  disableED5Field: boolean;

  mappingComments:any;
  pdfComments:any;
  forPdf: boolean = false;
  constructor(public api: MappingApiService,
              private eprService: EprService,
              public mapService: MappingFormService,
              private store: Store<AppState>,
              public dialog: MatDialog,
              private userService: UsersService,private copayService: CopayService) {
      this.subListner.push(
      this.store.select(getEprId).subscribe(id => {
        this.eprId = id;
        }),
        this.userService.userId.subscribe((id) => {
          this.userId = id
        })
       // this.store.select(getUserId).subscribe(id => {
       //    this.userId = id;
       //  })
      );
      this.toolTip = ColumnSets.tooltip;
    }

  @ViewChild('gridRequirement') grid: AgGridAngular;
  @ViewChild('mappingreq') mappingReq: any;
  frameworkComponents: any;
  maprequirementinstructions:string = "maprequirementinstructions";
  addComments:boolean = false;


  ngOnInit() {
    this.frameworkComponents = {
      deleteMappingFileSentAction: DeleteBtnCellRendererComponent
    };
    const {form, options} = this.mapService.generateMappingRequirementForm();
    this.form = form;
    this.form.disable({emitEvent: false});
    this.options = options;

    if (this.mappingDocument && !this.isNewDoc) {
      this.subListner.push(
      this.fetchMappingRequirement(this.mappingDocument).subscribe()
      );
    }
    this.subListner.push(
    this.addNewMappingListner(),
    this.addMappingDocumentListner(),
    this.addEditableListner(),
    this.addImportDocument(),
    this.addRequirementValidationListner(),
    this.addValidationListner(),
    this.addIs834FormatListner(),
    this.mapTypeValueChangeListener(),
    this.getDatamanagement(),
    this.addMapStatusListner()
    );
    this.formListner();

    if (this.isNewDoc) {
     this.enableForm();
      this.fetchNewRequirement();

    }
    this.getmappingGuideandEC();
    this.copayService.isEdited= !this.form.disabled;
  }
  addMapStatusListner(): Subscription {
  return this.mapService.mapStatusValueChange$.subscribe((resp:any)=>{
  if(resp.hasOwnProperty('mappingStatus')){
    if(resp.mappingStatus === MAPPING_STATUS.SUBMITTED && this.is834Format){
      this.updateFormValidatorsBasedOnFormat(true);
    }else{
      this.updateFormValidatorsBasedOnFormat(false);
    }
  }
   })
  }
  getDatamanagement(){
    const arr = [this.api.getDataManagementApi("isa12"),this.api.getDataManagementApi("gs08"),
    this.api.getDataManagementApi("st03")];
    return forkJoin(arr).subscribe(res =>{
      this.options.isa12= res[0].map(data=>{
        return {...data,id:data.id.toString()}
        });
      this.options.gs08 = res[1].map(data=>{
        return {...data,id:data.id.toString()}
        });;
      this.options.valueSentInST03 = res[2].map(data=>{
        return {...data,id:data.id.toString()}
        });;
      this.updateVariationField();
    })
  }
  onGridReady(param) {
    this.grid = param;
    this.grid.api.setColumnDefs(ColumnSets.requirements);
    this.grid.api.sizeColumnsToFit();
  }
  enableForm(){
    this.form.enable({emitEvent:false});
    this.copayService.isEdited= !this.form.disabled;
    this.form.patchValue(this.form.getRawValue());
    this.grid?.api?.redrawRows();
    if(this.disableED5Field ){
      let control = this.form.get('overrideValuesE5DS5C');
      control.disable();
    }
    if(this.showValidation ){
      this.form.markAllAsTouched()
    }
  }

  mapTypeValueChangeListener() {
     return this.mapService.mapTypeValueChange$.subscribe(resp => {
       let control = this.form.get('overrideValuesE5DS5C');
      if(resp.mapType && resp.mapType?.toLowerCase() === "alight tba"){
      control.enable();
      this.disableED5Field = false;
      }else{
        // "ED5" field should be DISABLED if Map type is NOT Alight
      control.disable();
      control.patchValue(null,{emitEvent:false});
      this.disableED5Field = true;
      }
      control.updateValueAndValidity();
      })
   }
  addIs834FormatListner() {
    return this.mapService.is834Format$.subscribe(resp => {
      if (resp) {
        this.is834Format = true;
        if (this.editMode || this.isNewDoc) {
         this.enableForm();
        }
      } else {
        this.is834Format = false;
        this.form.disable({ emitEvent: false });
      }
      this.is834FormatOutput.emit(!this.is834Format); // for disabling purpose.
    });
  }
  addRequirementValidationListner() {
    return this.mapService.addRequirementValidations$.subscribe(resp => {
     if (resp) {
      this.addRemoveValidators(true);
     } else {
      this.addRemoveValidators(false);
     }
    });
  }

  addImportDocument() {
    return this.mapService.importDocument$.subscribe(resp => {
      if (resp) {
        this.fetchMappingRequirement(resp, {case: 'import'}).subscribe(mappingFetched => {
         this.fetchNewRequirement({case: 'import'});
        });
      }
    });
  }

  addValidationListner() {
    return this.mapService.showValidations$.subscribe(resp => {
       if (resp) {
        this.showValidation = true;
        this.form.markAllAsTouched();
       } else {
        this.showValidation = false;
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.addRemoveValidators(false);
       }
      });
    }
  addEditableListner() {
    return this.mapService.editableMode$.subscribe(resp => {
       this.editMode = resp;
       if (resp && this.is834Format) { // i.e editable mode is true.
       this.enableForm();
      } else {
         this.form.disable({emitEvent: false});
         this.copayService.isEdited= !this.form.disabled;
         this.grid.api.redrawRows();
      }
    });
  }
  addMappingDocumentListner() { // for existing mapping guide population
    return this.mapService.mappingDocSelected$.subscribe(resp => {
      this.mappingDocument = resp;
      this.fetchMappingRequirement(this.mappingDocument).subscribe();
    });
  }
  fetchMappingRequirement(data, variation?): Observable<any> {
    this.addComments = false;
    return this.api.getMappingRequirementApi(data).pipe(map((resp: any) => {
      if (resp && resp.status === 'SUCCESS') {
        const dataFromResponse = resp.response;
        this.form.patchValue(dataFromResponse);
        this.store.select(getEPRState).subscribe(resp => {
          if (resp && resp.id && resp.questionnaireEntity !== undefined) {
            const questionnaireData: any = resp.questionnaireEntity;
            this.form.controls.ftpId.setValue(questionnaireData.ftpid);
            if(questionnaireData.fileFrequency != null){
              this.form.controls.fileSchedule.setValue(questionnaireData.fileFrequency);
            }
          }
        });
        this.updateVariationField();
        if (variation && variation.case === 'import') {
          dataFromResponse.mappingFileSentRecords.forEach(el => {
          el.seqNumber = null;
         });
        } else {
          this.form.disable({ emitEvent: false });
        }
        this.rowData = dataFromResponse.mappingFileSentRecords;
        this.setTableData();
        this.addGridRow(true);
        this.mapService.requirementFormData = dataFromResponse;
        if( dataFromResponse?.mappingReqInstructionsData !== null){
          this.mappingComments =  dataFromResponse.mappingReqInstructionsData;
          this.addComments = true;
        }else if( dataFromResponse?.mappingReqInstructionsData === null){
        this.api.getDataManagementMapInstrTemplate(this.maprequirementinstructions).subscribe(res=>{
          this.mappingComments = res.options
          this.addComments = true;
        })
        }
      } else {
        // handel error case
        this.showWarning.emit(resp.errorMessage);
      }
    }));
  }

  updateVariationField(){
    const value = this.form.getRawValue()
    if(value?.isa12 && this.options?.isa12?.length){
      const dataExists = this.options.isa12.find(data => data.id == value.isa12)
      if(!dataExists){
        this.form.patchValue({isa12:null})
      }
    }
    if(value?.gs08 && this.options?.gs08?.length){
      const dataExists = this.options.gs08.find(data => data.id == value.gs08)
      if(!dataExists){
        this.form.patchValue({gs08:null})
      }
    }
    if(value?.valueSentInST03 && this.options?.st03?.length){
      const dataExists = this.options.valueSentInST03.find(data => data.id == value.valueSentInST03)
      if(!dataExists){
        this.form.patchValue({valueSentInST03:null})
      }
    }
  }

  addNewMappingListner() {
    return this.mapService.newMapping$.subscribe(resp => {
      this.addRemoveValidators(false);
      this.fetchNewRequirement();
    });
  }
  getQuestionaireData(){
    this.fetchNewRequirement({case:'refresh'});
  }
  fetchNewRequirement(variation?) {
    this.addComments = false
    if(variation && variation.case == "refresh"){
      this.showSpinner.next(true);
    }
    this.api.getDataManagementMapInstrTemplate(this.maprequirementinstructions).subscribe(res=>{
      if(res){
        this.mappingComments = res.options
        this.addComments = true;
        this.editMode = true;
      }

    })
    this.api.getNewRequirementApi(this.eprId).subscribe(resp => {
      if (resp && resp.status === 'SUCCESS') {

        if(variation && variation.case === 'refresh'){
          const data = resp.response;
          this.form.patchValue(data);
          this.showSpinner.next(false);
          //refetch questionaire data
          return;
        }

        if (!(variation && variation.case === 'import')) {
          this.form.reset({emitEvent: false});
          const initialValues = {
          transitionFill: ' ',
          cob: ' ',
          terminationDateOverrideNeeded: ' ',
          multipleHdLoopsSent: ' ',
          };
          this.form.patchValue(initialValues);
          if (this.is834Format) {
           this.enableForm();
          } else {
            this.form.disable({emitEvent: false});
          }
          this.rowData = [];
        }
        const data = resp.response;
        //  we want our validations to re run again.
        this.form.patchValue(data);
        this.setTableData();
        this.addGridRow(true);
        this.mapService.requirementFormData = data;
      } else {
        // handel error
        this.showWarning.emit(resp.errorMessage);
      }
      this.store.select(getEPRState).subscribe(resp => {
        if (resp && resp.id && resp.questionnaireEntity !== undefined) {
          const questionnaireData: any = resp.questionnaireEntity;
          this.form.controls.ftpId.setValue(questionnaireData.ftpid);
          if(questionnaireData.fileFrequency != null){
            this.form.controls.fileSchedule.setValue(questionnaireData.fileFrequency);
          }
        }
      });
    });
  }

  addGridRow(initialize?) {
    if (initialize) {
      const rowData = [];
      this.grid.api.forEachNode(node => rowData.push(node.data));
      if (rowData.length) {
       return;
      }
    }
    this.grid.api.updateRowData({
      add: [{
        bgnValue: '',
        filePliCode: '',
        outputFile: '',
        fileType: '',
        seqNumber: null
      }]
    });
  }
 setTableData() {
    const rowData = this.rowData;
    if (this.grid) {
    this.grid.api.setRowData(rowData);
  }
}

formListner() {
  if (this.subsForFields.length) {
    this.subsForFields.forEach(sub => sub.unsubscribe());
  }
  this.subsForFields = [];
  this.subsForFields.push(


  this.form.get('responseTransaction999').valueChanges.subscribe(values => {
    // if (this.showValidation) {

      if (values !== 'Yes') {
        this.form.get('typeOf999Transaction').disable({emitEvent: false});
        this.form.get('typeOf999Transaction').patchValue(null, {emitEvent: false});
        this.validationClass['typeOf999Transaction'] = true;
      } else {
        this.form.get('typeOf999Transaction').enable({emitEvent: false});
        this.validationClass['typeOf999Transaction'] = false;
      }
    // }
  }),
  this.form.get('thirdIdUsed').valueChanges.subscribe(values => {
    // if (this.showValidation) {

      if (values === 'No') {
        this.form.get('thirdIdForDependents').disable({emitEvent: false});
        this.form.get('thirdIdForDependents').patchValue(null, {emitEvent: false});
        this.validationClass['thirdIdForDependents'] = true;
      } else {
        this.form.get('thirdIdForDependents').enable({emitEvent: false});
        this.validationClass['thirdIdForDependents'] = false;
      }
   // }
  }),

  // this.form.get('typeEEmail').valueChanges.subscribe(values => {
  //   // if (this.showValidation) {
  //   if (values !== 'Yes') {
  //     this.form.get('typeEEmailInstructions').enable({emitEvent: false});
  //     //this.form.get('typeEEmailInstructions').patchValue(null, {emitEvent: false});
  //     this.validationClass['typeEEmailInstructions'] = false;

  //   } else {
  //     this.form.get('typeEEmailInstructions').enable({emitEvent: false});
  //     this.validationClass['typeEEmailInstructions'] = false;

  //   }
  // // }
  // }),
  // this.form.get('typePPrivacy').valueChanges.subscribe(values => {
  //   // if (this.showValidation) {
  //   if (values !== 'Yes') {
  //     this.form.get('typePPrivacyInstructions').enable({emitEvent: false});
  //     //this.form.get('typePPrivacyInstructions').patchValue(null, {emitEvent: false});
  //     this.validationClass['typePPrivacyInstructions'] = false;

  //   } else {
  //     this.form.get('typePPrivacyInstructions').enable({emitEvent: false});
  //     this.validationClass['typePPrivacyInstructions'] = false;
  //   }
  // // }
  // }),
  // this.form.get('typeMMemberAddress').valueChanges.subscribe(values => {
  //   // if (this.showValidation) {
  //   if (values !== 'Yes') {
  //     this.form.get('typeMMemberAddressInstructions').enable({emitEvent: false});
  //     //this.form.get('typeMMemberAddressInstructions').patchValue(null, {emitEvent: false});
  //     this.validationClass['typeMMemberAddressInstructions'] = false;

  //   } else {
  //     this.form.get('typeMMemberAddressInstructions').enable({emitEvent: false});
  //     this.validationClass['typeMMemberAddressInstructions'] = false;
  //   }
  // // }
  // }),
  this.form.get('typeAMemberAncillary').valueChanges.subscribe(values => {
    // if (this.showValidation) {
    if (values !== 'Yes') {
      this.form.get('typeAMemberAncillaryInstructions').enable({emitEvent: false});
     // this.form.get('typeAMemberAncillaryInstructions').patchValue(null, {emitEvent: false});
      this.validationClass['typeAMemberAncillaryInstructions'] = false;

    } else {
      this.form.get('typeAMemberAncillaryInstructions').enable({emitEvent: false});
      this.validationClass['typeAMemberAncillaryInstructions'] = false;
    }
  // }
  }),

  this.form.get('effectiveDateOverrideNeeded').valueChanges.subscribe(values => {
    // if (this.showValidation) {
      if (values !== 'Yes') {
        this.form.get('effectiveDateOverrideInstructions').disable({emitEvent: false});
        this.form.get('effectiveDateOverrideInstructions').patchValue(null, {emitEvent: false});
        this.validationClass['effectiveDateOverrideInstructions'] = true;
      } else {
        this.form.get('effectiveDateOverrideInstructions').enable({emitEvent: false});
        this.validationClass['effectiveDateOverrideInstructions'] = false;
      }
   // }
  }),
  this.form.get('terminationDateOverrideNeeded').valueChanges.subscribe(values => {
    // if (this.showValidation) {
      if (values !== 'Yes') {
        this.form.get('terminationDateOverrideNeededInstructions').disable({emitEvent: false});
        this.form.get('terminationDateOverrideNeededInstructions').patchValue(null, {emitEvent: false});
        this.validationClass['terminationDateOverrideNeededInstructions'] = true;
      } else {
        this.form.get('terminationDateOverrideNeededInstructions').enable({emitEvent: false});
        this.validationClass['terminationDateOverrideNeededInstructions'] = false;
      }
   // }
  }),
  this.form.get('careFacilityRequired').valueChanges.subscribe(values => {
    // if (this.showValidation) {
      if (values !== 'Yes') {
        this.form.get('careFacilityRequiredInstructions').disable({emitEvent: false});
        this.form.get('careFacilityRequiredInstructions').patchValue(null, {emitEvent: false});
        this.validationClass['careFacilityRequiredInstructions'] = true;
      } else {
        this.form.get('careFacilityRequiredInstructions').enable({emitEvent: false});
        this.validationClass['careFacilityRequiredInstructions'] = false;
      }
   // }
  }),
  this.form.get('careQualifierRequired').valueChanges.subscribe(values => {
    // if (this.showValidation) {
      if (values !== 'Yes') {
        this.form.get('careQualifierRequiredInstructions').disable({emitEvent: false});
        this.form.get('careQualifierRequiredInstructions').patchValue(null, {emitEvent: false});
        this.validationClass['careQualifierRequiredInstructions'] = true;
      } else {
        this.form.get('careQualifierRequiredInstructions').enable({emitEvent: false});
        this.validationClass['careQualifierRequiredInstructions'] = false;
      }
   // }
  }),
  this.form.get('healthcareExchangeGracePeriod').valueChanges.subscribe(values => {
    // if (this.showValidation) {
      if (values !== 'Yes') {
        this.form.get('healthcareExchangeGracePeriodInstructions').disable({emitEvent: false});
        this.form.get('healthcareExchangeGracePeriodInstructions').patchValue(null, {emitEvent: false});
        this.validationClass['healthcareExchangeGracePeriodInstructions'] = true;
      } else {
        this.form.get('healthcareExchangeGracePeriodInstructions').enable({emitEvent: false});
        this.validationClass['healthcareExchangeGracePeriodInstructions'] = false;
      }
    // }
  }),
  this.form.get('cob').valueChanges.subscribe(values => {
    // if (this.showValidation) {
      if (values !== 'Yes') {
        this.form.get('cobInstructions').disable({emitEvent: false});
        this.form.get('cobInstructions').patchValue(null, {emitEvent: false});
        this.validationClass['cobInstructions'] = true;
      } else {
        this.form.get('cobInstructions').enable({emitEvent: false});
        this.validationClass['cobInstructions'] = false;
      }
   // }
  }),
  this.form.get('cdd').valueChanges.subscribe(values => {
    // if (this.showValidation) {
      if (values !== 'Yes') {
        this.form.get('cddInstructions').disable({emitEvent: false});
        this.form.get('cddInstructions').patchValue(null, {emitEvent: false});
        this.validationClass['cddInstructions'] = true;
      } else {
        this.form.get('cddInstructions').enable({emitEvent: false});
        this.validationClass['cddInstructions'] = false;
      }
   // }
  }),
  this.form.get('medB').valueChanges.subscribe(values => {
    // if (this.showValidation) {
      if (values !== 'Yes') {
        this.form.get('medBInstructions').disable({emitEvent: false});
        this.form.get('medBInstructions').patchValue(null, {emitEvent: false});
        this.validationClass['medBInstructions'] = true;
      } else {
        this.form.get('medBInstructions').enable({emitEvent: false});
        this.validationClass['medBInstructions'] = false;
      }
   // }
  }),
  this.form.get('rds').valueChanges.subscribe(values => {
    // if (this.showValidation) {
      if (values !== 'Yes') {
        this.form.get('rdsInstructions').disable({emitEvent: false});
        this.form.get('rdsInstructions').patchValue(null, {emitEvent: false});
        this.validationClass['rdsInstructions'] = true;
      } else {
        this.form.get('rdsInstructions').enable({emitEvent: false});
        this.validationClass['rdsInstructions'] = false;
      }
    // }
  }),
  this.form.get('transitionFill').valueChanges.subscribe(values => {
    // if (this.showValidation) {
      if (values !== 'Yes') {
        this.form.get('transitionFillInstructions').disable({emitEvent: false});
        this.validationClass.transitionFillInstructions = true;

      } else {
        this.form.get('transitionFillInstructions').enable({emitEvent: false});
        this.validationClass.transitionFillInstructions = false;
      }
    // }
  }),

  this.form.get('segmentSentOnEdi834').valueChanges.subscribe(values => {
    // if (this.showValidation) {
      if (values !== 'Yes') {
        this.form.get('segmentSentOnEdi834Instructions').disable({emitEvent: false});
        this.form.get('segmentSentOnEdi834Instructions').patchValue(null, {emitEvent: false});
        this.validationClass.segmentSentOnEdi834Instructions = true;
      } else {
        this.form.get('segmentSentOnEdi834Instructions').enable({emitEvent: false});
        this.validationClass.segmentSentOnEdi834Instructions = false;
      }
    // }
    }),
  );


 }
updateFormValidatorsBasedOnFormat(add){
  let requiredByFormat = [ "isa12", "gs08","valueSentInST03"];
  requiredByFormat.forEach(key=>{
    let control = this.form.get(key);
    if(add){
      control.setValidators([Validators.required]);
    }else{
      control.clearValidators();
    }
    // control.markAsTouched()
    control.updateValueAndValidity({emitEvent: false});
  })
}

 addRemoveValidators(add: boolean) {
  Object.keys(this.form.controls).forEach(key => {
    const control = this.form.get(key);
    if (add) {
      // keys for questionare new data on form prepopulation.
      if (
        key !== 'rds' &&
        key !== 'medB' &&
        key !== 'cdd' &&
        key !== 'healthcareExchangeGracePeriod' &&
        key !== 'careQualifierRequired' &&
        key !== 'careFacilityRequired' &&
        key !== 'isTermDateAToDate' &&
        key !== 'typeAMemberAncillary' &&
        key !== 'typeMMemberAddress' &&
        key !== 'typePPrivacy' &&
        key !== 'typeEEmail' &&
        key !== 'type5RecordsSent' &&
        key !== 'thirdIdUsed' &&
        key !== 'alternateIdAutoGenerated' &&
        key !== 'alternateIdUsed' &&
        key !== 'isPcAppendedToId' &&
        key !== 'isPcAutoGenerated' &&
        key !== 'clientUsesPersonCode' &&
        key !== 'questMultiCarrier' &&
        key !== 'type4RecordsSent' &&
        key !==  'overrideValuesE5DS5C' && key!=="isa12" && key!=="gs08" && key!=="valueSentInST03" &&
        key !== 'memberId'

      ) {
        control.setValidators([Validators.required]);
        control.markAsTouched();

      }

    } else {
      if(key!=="isa12" && key!=="gs08" && key!=="valueSentInST03") // as these 3 will be required based on file format being 834
      control.clearValidators();
    }
    control.updateValueAndValidity({emitEvent: false});
  });
  if (!add) {
    this.form.markAsPristine();
    // this.form.markAsUntouched();
  }

}

 ngOnDestroy() {
  const remove = (arrayData) => {
    if (arrayData.length) {
      arrayData.forEach(sub => {
        sub.unsubscribe();
      });
    }
  };
  remove(this.subListner);
  remove(this.subsForFields);
 }

 getmappingGuideandEC() {
  this.api.getMappingGuide(this.mappingDocument).subscribe(resp => {
    this.mappingguide = resp;
  });
  this.eprService.getAllPliAndEcNames().subscribe(res => {
    this.pliAndEcNames = res;
  });
}

formattedMappingComment(){
  this.addComments = false;
  this.pdfComments = this.mappingComments.join('@').toString()
   this.alertz = document.getElementById('comment-container');
var charArry = this.pdfComments.split('@');
charArry.forEach((liTxt,index) => {
  if(index===0){
    let li = document.createElement('li')
    li.style.cssText = 'width:500px'
    li.style.fontSize = '1rem';
    li.innerHTML = liTxt
    this.alertz.appendChild(li)

  }else{
    let li = document.createElement('li')
  li.style.cssText = 'width:500px'
  li.style.fontSize = '1rem';
  li.innerHTML = liTxt
  this.alertz.appendChild(li)
  }

})
}
alertz:any
  exportPDF() {
    this.formattedMappingComment()

    setTimeout(() => {
    if (this.isNewDoc || this.editMode) {
      this.dialog.open(InformationDialogComponent, {
        height: '200px',
        width: '400px',
        data: {
          body: 'Export operation not available while in edit/add mode. ' +
            'Click cancel to exit edit mode(changes will be lost) or save your changes',
          buttonText: 'Close'
        }
      });
      return;
    }
    const elem = this.mappingReq.nativeElement;
    // to change the comment location
    const elemCopy = elem.cloneNode(true) as HTMLElement;

    const pdfBody = document.body;
    const tbl  = document.createElement('table');


    const clientDetails = ['Client Name', 'Carrier(s)', 'PLI', 'Client Code', 'Project',
    'Project Go Live date', 'User Story', 'Format', 'Map Type', 'Assigned EC', 'Mapping BA', 'Map Notes/Comments'];
    const Fields = ['clientName', 'carrierNumber', 'pliCode', 'clientCode', 'project',
    'goLiveDate', 'userStory', 'mappingFormat', 'mappingType', 'mappingBA'];

    for (let i = 0; i <= 11; i++) {
    if (i === 9) {
      this.clientdetails.push(this.pliAndEcNames[this.mappingDocument.pliCode]);
    } else if (i === 10) {
      this.clientdetails.push(this.mappingDocument['mappingBA']);
    } else if (i === 11) {
      this.clientdetails.push(this.mappingguide.response.mappingComments);
    } else {
      this.clientdetails.push(this.mappingDocument[Fields[i]]);
    }
  }

    for (let i = 0; i <  2; i++) {
        const tr = tbl.insertRow();
        for (let j = 0; j < 12; j++) {
                const td = tr.insertCell();
                if (i === 1) {
                  td.appendChild(document.createTextNode(`${this.clientdetails[j]}` ));

                } else {
                  td.appendChild(document.createTextNode(`${clientDetails[j]}` ));
                  td.style.backgroundColor =  '#3385D6';
                }

                td.style.padding = '8px';

            }
    }
    pdfBody.appendChild(tbl);

    document.body.removeChild(tbl);
    try {
      const elemLength = elemCopy.querySelectorAll('mat-select').length;
      for (let i = 0; i < elemLength; i++) {
        const matElem = elemCopy.querySelector('mat-select'), parent = matElem.parentNode;
        const innerSpan = matElem.querySelector('.mat-select-value-text span') as HTMLElement;

        const newElem = document.createElement('span');
        newElem.style.color = 'rgba(0, 0, 0, 0.38)';
        newElem.style.fontSize = '1rem';

        if (innerSpan && innerSpan.innerText) {
          newElem.innerText = innerSpan.innerText;
          parent.replaceChild(newElem, matElem);
        } else {
          newElem.innerText = '';
          parent.replaceChild(newElem, matElem);
        }
      }
    } catch (e) {
    }

    const fileOptions = {
      margin: [10, 5, 10, 5],
      name: 'output.pdf',
      enableLinks: false,
      image: {type: 'jpeg'},
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      html2canvas: {dpi: 192},
      jsPDF: { orientation: 'landscape', format: 'letter' }
    };

    const fileName = `mapping-requirement-
          ${this.mappingDocument.documentID}-
          ${this.mappingDocument.clientCode}-
          ${this.mappingDocument.pliCode}.pdf`;

    const html2pdflocal: any = html2pdf();
    html2pdflocal.set(fileOptions).from(tbl).toPdf().get('pdf').then((pdf) => {
      pdf.addPage();
    }).from(elemCopy).toContainer().toCanvas().toPdf().save(fileName);
  }, 100);
  setTimeout(() => {
  this.addComments = false
  this.alertz.querySelectorAll('li').forEach(e => e.remove())
  this.alertz.value = this.mappingComments
   this.addComments = true
  }, 150);
  }
}

