import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {MasterDataList} from '../models/MasterDataList';
import {CVSHttpClient} from 'angular-component-library';


@Injectable({
  providedIn: 'root'
})
export class DataManagementService {
  reportTypes = new BehaviorSubject([]);
  reportMessageCodes = new BehaviorSubject([]);
  addRowdata= new BehaviorSubject([]);



  constructor(private http: CVSHttpClient) { }

  getMasterDataSet(dataSetName: string): Observable<MasterDataList> {
    const baseUrl = dataSetName === 'holidays' ? environment.baseFiletrackerUrl  : environment.baseUrl;
    const subUrl = dataSetName === 'holidays' ? 'filetracker' : 'dataManagement';
    return this.http.get(`${baseUrl}/${subUrl}/${dataSetName}`) as Observable<MasterDataList>;
  }

  saveMasterDataSet(masterDataList: MasterDataList): Observable<MasterDataList> {
    return this.http.post(`${environment.baseUrl}/dataManagement/save`, masterDataList) as Observable<MasterDataList>;
  }
  getMasterDataList() {
    return this.http.get( `${environment.baseUrl}/getDataManagement`);
  }
}
