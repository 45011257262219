import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import * as spedm from '../actions/spedm.action';
import {Router} from '@angular/router';
import {SpedmService} from '../services/spedm.service';
import {Spedm} from '../models/Spedm';
import {NoopAction} from '../actions/noop.action';
import {Contact} from '../models/Contact';
import {DeleteEprFeedAction} from '../actions/epr-feed.action';
import {DELETE_CONTACT, DeleteContactSuccessAction} from '../actions/spedm.action';
import {EprService} from "../services/epr.service";

@Injectable()
export class SpedmEffects {
  loadAllSpedm$ = createEffect(() => this.actions$.pipe(
    ofType(spedm.LOAD_ALL_SPEDM),
    mergeMap(() => this.spedmService.getAll()
      .pipe(
        map(spedmValue => ({ type: spedm.LOAD_ALL_SPEDM_SUCCESS, payload: spedmValue as Spedm[] })),
        catchError(() => of({type: spedm.LOAD_ALL_SPEDM_FAILURE}))
      ))
    )
  );
  loadSpedm$ = createEffect(() => this.actions$.pipe(
    ofType(spedm.LOAD_SPEDM),
    mergeMap((action) => this.spedmService.getSpedm(action['payload'])
      .pipe(
        map(spedmValues => ({ type: spedm.LOAD_SPEDM_SUCCESS, payload: spedmValues as Spedm })),
        tap(epr => {
          this.eprService.dashboardRowClicked.next(false);
          this.router.navigateByUrl('dashboard/onboardingspedm');
        }),
        catchError(error => {
          return of(new NoopAction());
        })
      ))
    )
  );
  saveSpedm$ = createEffect(() => this.actions$.pipe(
    ofType(spedm.SAVE_SPEDM),
    mergeMap(action => this.spedmService.saveSpedm(action['payload'], 'save')
      .pipe(
        map(Spedmvalue => ({ type: spedm.SAVE_SPEDM_SUCCESS, payload: Spedmvalue as Spedm })),
        catchError(() => of({type: spedm.SAVE_SPEDM_SUCCESS_FAILURE}))
      ))
    )
  );

  updateSpedm$ = createEffect(() => this.actions$.pipe(
    ofType(spedm.UPDATE_SPEDM),
    mergeMap(action => this.spedmService.saveSpedm(action['payload'], 'update')
      .pipe(
        map(Spedmvalue => ({ type: spedm.SAVE_SPEDM_SUCCESS, payload: Spedmvalue as Spedm })),
        catchError(() => of({type: spedm.SAVE_SPEDM_SUCCESS_FAILURE}))
      ))
    )
  );


  loadContact$ = createEffect(() => this.actions$.pipe(
    ofType(spedm.LOAD_CONTACT),
    mergeMap((action) =>
      this.spedmService.getContact(action['payload']['id'] ,
        action['payload']['alertType'])
      .pipe(
        map(contactvalues => ({ type: spedm.LOAD_CONTACT_SUCCESS, payload: contactvalues as Contact })),
        catchError(error => {
          return of(new NoopAction());
        })
      ))
    )
  );

  saveContact$ = createEffect(() => this.actions$.pipe(
    ofType(spedm.SAVE_CONTACT),
    mergeMap(action => this.spedmService.saveContact( action['payload'],  action['payload']['field'])
      .pipe(
        map(contact => ({ type: spedm.SAVE_CONTACT_SUCCESS, payload: contact as Contact})),
        catchError(() => of({type: spedm.SAVE_CONTACT_SUCCESS_FAILURE}))
      ))
    )
  );
  editContact$ = createEffect(() => this.actions$.pipe(
    ofType(spedm.UPDATE_CONTACT),
    mergeMap(action => this.spedmService.editContact(action['payload']['contact'], action['payload']['field'])
      .pipe(
        map(contactvalue => ({ type: spedm.UPDATE_CONTACT_SUCCESS, payload: contactvalue as Contact })),
        catchError(() => of({type: spedm.UPDATE_CONTACT_SUCCESS_FAILURE}))
      ))
    )
  );

  deleteContact$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteEprFeedAction>(DELETE_CONTACT),
    mergeMap(action => this.spedmService.deleteContact(action.payload)
      .pipe(
        map(() => new DeleteContactSuccessAction()),
        tap(() => this.router.navigateByUrl('')),
        catchError(error => {
          return of(new NoopAction());
        })
      )
    )
  ));
  constructor(
    private actions$: Actions,
    private spedmService: SpedmService,
    private eprService: EprService,
    private router: Router,
  ) { }

}
