import {Actions, LOAD_QUESTIONNAIRE_FORM_SUCCESS, UPDATE_QUESTIONNAIRE_FORM} from '../actions/questionnaire.action';
import {convertDateForDatePicker} from '../helpers/DateTimeHelpers';
import {CLEAR_EPR_DATA, COPY_EPR} from '../actions/epr.action';


export interface State {
  id: any;
  client: string;
  pli: string;
  golivedate: string ;
  qlclientcode: string;
  clientcode: string;
  restrictedaccessclient: string;
  carrier: string;
  eligibilityconsultant: string;
  eligType: string;
  quesMultiCarrier: string;
  qintegAccums: string;
  intAccmShrdID: string;
  cdhadjMethod: string;
  qmmpclient: string;
  medD: string;
  qclientMedicaid: string;
  medicaidIDPosition: string;
  subsidy: string;
  qmedB: string;
  qcommercial: string;
  qpopType: string[];
  cobused: string;
  pcobncobused: string;
  stcob: string;
  rejectBrokenLinks: string;
  cobcused: string;
  linkCobcToElig: string;
  crdhldrsOnlyQ: string;
  qsurvSpsMethod: string;
  standAloneDeps: string;
  memDepDifGrp: string;
  qphoneLines: string;
  earlyPhoneDte: string;
  qidcards: string;
  pgCriteria: string[];
  qpgtatlvl1: string;
  qpgpctgLvl1: string;
  qpgtatlvl2: string;
  qpgpctgLvl2: string;
  qpgperiod: string;
  qpginEffect: string;
  pgrptgInEffect: string;
  qexactMatchBPGUsed: string;
  memIDLength: string;
  memIDMaxLength: string;
  mbrIDTypeWPC: string;
  mbrIDTypeWOPC: string;
  uniqueIDsInFam: string;
  famIDMatchCoreMbrIDQ: string;
  personCodes: string;
  pcautoGen: string;
  autogenprgm: string;
  ignorePConFile: string;
  qappndPCtoXwalk: string;
  qpcautogenCarList: string;
  pcassignMethod: string;
  pconID: string;
  pclength: string;
  xwalkUsed: string;
  xwalkIDTypeWPC: string;
  xwalkIDTypeWOPC: string;
  xwalkIDLength: string;
  xwalkIDMaxLength: string;
  xwalkAutoGen: string;
  autoGenBaseLngth: string;
  xwalkAutogenPrefix: string;
  xwalkAGOverride: string;
  eobid: string;
  cetid: string;
  reportID: string;
  mabdtlID: string;
  cardID: string;
  mailOrderFormID: string;
  xwalkForFSA: string;
  q3rdIdFieldUsed: string;
  q3rdIdFieldType: string;
  q3rdIDinQL: string;
  consolidateProfileLogic: string;
  vendor: string;
  multFileFeeds: string;
  format: string;
  cstmFmtRsnCd: string;
  gisused: string;
  prePreLoad: string;
  discPrePreProgram: string;
  fileParamMapToo: string;
  fileTransmitionMethod: string;
  ftpid: string;
  qfileRouting: string;
  fileType: string;
  qhistMethod: string;
  moveHstFile: string;
  type5UsedQ: string;
  type5Validation: string;
  type5EmailUsed: string;
  type5MbrAddUsed: string;
  type5PrvcyUsed: string;
  privMoveLvlQ: string;
  batchOvrProtect: string;
  loadRptsDelMethodQ: string[];
  qloadRptNDMFTPProf1: string;
  fileFrequency: string;
  scheduleHolidays: string;
  scheduleVariance: string;
  vendorLookup: string;
  participantTermDates: string;
  termDateThru: string;
  depsOnFile: string;
  depImpTermQues: string;
  depImpTrmMthd: string;
  impliedTerms: string;
  impTermLevel: string;
  moveHistQues: string;
  moveAccumsQues: string;
  movePriorAuthsQues: string;
  impTrmInclTermRecs: string;
  impTrmsMoveALLClaims: string;
  tmr: string;
  tmrignFtrTrm: string;
  tmrinclFtrEff: string;
  tmrdate: string;
  bufferDays: string;
  tbaLookahead: string;
  tbaVarianceDays:string;
  tbaVarianceDaysCount: string;
  tmrrangeListType: string;
  incExcListName: string;
  grpInclExclList: string;
  manTMRProtect: string;
  compareProcess: string;
  compareRobotName: string;
  compareNDMScript: string;
  compareFrequency: string;
  questionnaireTermThreshold: string;
  termByAbsenceThshldPercent: string;
  flerrorThreshold: string;
  termThreshold: string;
  termThresholdInclMovesActCount: string;
  termThresholdExclMovesActCount: string;
  termThresholdAllActCount: string;
  termThreshExcMoves: string;
  termsVsAllActives: string;
  thrshldInclFtrTrms: string;
  famIDThrshld: string;
  matchLevel: string;
  newPopThrshldUOM: string;
  newPopThrshld: string;
  chngMbrIDUOM: string;
  chngMbrIDThrshld: string;
  chngLNThrshldUse: string;
  chngLNUOM: string;
  chngLNThrshld: string;
  chngFNThrshldUse: string;
  chngFNUOM: string;
  chngFNThrshld: string;
  chngDOBThrshldUse: string;
  chngDOBUOM: string;
  chngDOBThrshld: string;
  chngRelThrshldUse: string;
  chngRelUOM: string;
  chngRelThrshld: string;
  idcardThrshld: string;
  cardsForEntPopulation: string;
  idonCard: string;
  kitCrdBklt: string;
  ssnidcrdValQ: string;
  idcardOutque: string;
  field178: string;
  prntFamOnCard: string;
  genCardForMovedMbr: string;
  cardSupLevel: string;
  qcardTriggers: string[];
  multiBirth: string;
  grpOTForAutoCAG: string;
  groupFilesSent: string;
  grpSuspUsed: string;
  grpPplPgmUsed: string;
  grpPplProgram: string;
  grpSuspRetentionDays: string;
  qphysFiles: string;
  qdiagFiles: string;
  qlabFiles: string;
  bypassFileDependency: string;
  mbrSuspUsed: string;
  mbrSuspRetentionDays: string;
  cltPrdctUsed: string;
  cltRiderUsed: string;
  careNtwrkUsed: string;
  careFcltyUsed: string;
  careFcltyOnTheFly: string;
  careQualifierUsed: string;
  careQualifierOnTheFly: string;
  pcpsUsed: string;
  pcponTheFlyUsed: string;
  mbrLvlPlansUsedQ: string;
  qcddused: string;
  hcexchangeMethodology: string;
  gracePeriodHandling: string;
  openEnroll: string;

  fileSubmitterName: string;
  fileSubmitterEmail: string;
  fileSubmitterPhone: string;
  loadReportName: string;
  loadReportEmail: string;
  loadReportPhone: string;
  otherName: string;
  otherEmail: string;
  otherPhone: string;

  contactInfo: [];
  previousEaGroup: [];
  auditSection: [];

  qcltSendOEFile: string;
  qoewindow: string;
  qdataOnOEFile: string;
  qoefileExpDate: string;
  qoeidcards: string;
  qoecurrentTerms: string;
  qoecvstermDrops: string;
  qoedtaAfterOEFil: string;
  qoefileAfterOEDate: string;
  qoenewBnftYrFile: string;
  qoenewBnftYrFileDate: string;
  qoenewBnftYrFileTerms: string;
  qoecagstructrChg: string;
  qoetestFile: string;

  headerInfoNotes: string;
  clientBasicsNotes: string;
  lOBNotes: string;
  cOBNotes: string;
  dependantsNotes: string;
  phoneCardsNotes: string;
  pGNotes: string;
  iDConfigNotes: string;
  pcNotes: string;
  altIdNotes: string;
  fileParamNotes: string;
  fileFormatNotes: string;
  transmitNotes: string;
  fileTypeNotes: string;
  historyMethodNotes: string;
  loadReportNotes: string;
  fileFrequencyNotes: string;
  vendorNotesFeedback: string;
  termsNotes: string;
  impliedTermsNotes: string;
  termByAbsenceNotes: string;
  compareProcessNotes: string;
  thrshldNotes: string;
  cardQuestNotes: string;
  miscQuestClientNotes: string;
  groupFilesNotes: string;
  clientCodesNotes: string;
  qOENotes: string;
  questionariesLoadReportEntity: any;
  questionarieFilePrefixes:any;
  signOffStatus:string;

}

export const initialState: State = {
  id: null,
  client: '',
  pli: '',
  golivedate: '',
  qlclientcode: '',
  clientcode: '',
  restrictedaccessclient: '',
  carrier: '',
  eligibilityconsultant: '',
  eligType: '',
  quesMultiCarrier: '',
  qintegAccums: '',
  intAccmShrdID: '',
  cdhadjMethod: '',
  qmmpclient: '',
  medD: '',
  qclientMedicaid: '',
  medicaidIDPosition: '',
  subsidy: '',
  qmedB: '',
  qcommercial: '',
  qpopType: [],
  cobused: '',
  pcobncobused: '',
  stcob: '',
  rejectBrokenLinks: '',
  cobcused: '',
  linkCobcToElig: '',
  crdhldrsOnlyQ: '',
  qsurvSpsMethod: '',
  standAloneDeps: '',
  memDepDifGrp: '',
  qphoneLines: '',
  earlyPhoneDte: '',
  qidcards: '',
  pgCriteria: [],
  qpgtatlvl1: '',
  qpgpctgLvl1: '',
  qpgtatlvl2: '',
  qpgpctgLvl2: '',
  qpgperiod: '',
  qpginEffect: '',
  pgrptgInEffect: '',
  qexactMatchBPGUsed: '',
  memIDLength: '',
  memIDMaxLength: '',
  mbrIDTypeWPC: '',
  mbrIDTypeWOPC: '',
  uniqueIDsInFam: '',
  famIDMatchCoreMbrIDQ: '',
  personCodes: '',
  xwalkAutoGen: '',
  autogenprgm: '',
  ignorePConFile: '',
  qappndPCtoXwalk: '',
  qpcautogenCarList: '',
  pcassignMethod: '',
  pconID: '',
  pclength: '',
  xwalkUsed: '',
  xwalkIDTypeWPC: '',
  xwalkIDTypeWOPC: '',
  xwalkIDLength: '',
  xwalkIDMaxLength: '',
  pcautoGen: '',
  autoGenBaseLngth: '',
  xwalkAutogenPrefix: '',
  xwalkAGOverride: '',
  eobid: '',
  cetid: '',
  reportID: '',
  mabdtlID: '',
  cardID: '',
  mailOrderFormID: '',
  xwalkForFSA: '',
  q3rdIdFieldUsed: '',
  q3rdIdFieldType: '',
  q3rdIDinQL: '',
  consolidateProfileLogic: '',
  vendor: '',
  multFileFeeds: '',
  format: '',
  cstmFmtRsnCd: '',
  gisused: '',
  prePreLoad: '',
  discPrePreProgram: '',
  fileParamMapToo: '',
  fileTransmitionMethod: '',
  ftpid: '',
  qfileRouting: '',
  fileType: '',
  qhistMethod: '',
  moveHstFile: '',
  type5UsedQ: '',
  type5Validation: '',
  type5EmailUsed: '',
  type5MbrAddUsed: '',
  type5PrvcyUsed: '',
  privMoveLvlQ: '',
  batchOvrProtect: '',
  loadRptsDelMethodQ: [],
  qloadRptNDMFTPProf1: '',
  fileFrequency: '',
  scheduleHolidays: '',
  scheduleVariance: '',
  vendorLookup: '',
  participantTermDates: '',
  termDateThru: '',
  depsOnFile: '',
  depImpTermQues: '',
  depImpTrmMthd: '',
  impliedTerms: '',
  impTermLevel: '',
  moveHistQues: '',
  moveAccumsQues: '',
  movePriorAuthsQues: '',
  impTrmInclTermRecs: '',
  impTrmsMoveALLClaims: '',
  tmr: '',
  tmrignFtrTrm: '',
  tmrinclFtrEff: '',
  tmrdate: '',
  bufferDays: '',
  tbaLookahead: '',
  tbaVarianceDays: '',
  tbaVarianceDaysCount: '',
  tmrrangeListType: '',
  incExcListName: '',
  grpInclExclList: '',
  manTMRProtect: '',
  compareProcess: '',
  compareRobotName: '',
  compareNDMScript: '',
  compareFrequency: '',
  questionnaireTermThreshold: '',
  termByAbsenceThshldPercent: '',
  flerrorThreshold: '',
  termThreshold: '',
  termThresholdInclMovesActCount: '',
  termThresholdExclMovesActCount: '',
  termThresholdAllActCount: '',
  termThreshExcMoves: '',
  termsVsAllActives: '',
  thrshldInclFtrTrms: '',
  famIDThrshld: '',
  matchLevel: '',
  newPopThrshldUOM: '',
  newPopThrshld: '',
  chngMbrIDUOM: '',
  chngMbrIDThrshld: '',
  chngLNThrshldUse: '',
  chngLNUOM: '',
  chngLNThrshld: '',
  chngFNThrshldUse: '',
  chngFNUOM: '',
  chngFNThrshld: '',
  chngDOBThrshldUse: '',
  chngDOBUOM: '',
  chngDOBThrshld: '',
  chngRelThrshldUse: '',
  chngRelUOM: '',
  chngRelThrshld: '',
  idcardThrshld: '',
  cardsForEntPopulation: '',
  idonCard: '',
  kitCrdBklt: '',
  ssnidcrdValQ: '',
  idcardOutque: '',
  field178: '',
  prntFamOnCard: '',
  genCardForMovedMbr: '',
  cardSupLevel: '',
  qcardTriggers: [],
  multiBirth: '',
  grpOTForAutoCAG: '',
  groupFilesSent: '',
  grpPplPgmUsed: '',
  grpPplProgram: '',
  grpSuspUsed: '',
  grpSuspRetentionDays: '',
  qphysFiles: '',
  qdiagFiles: '',
  qlabFiles: '',
  bypassFileDependency: '',
  mbrSuspUsed: '',
  mbrSuspRetentionDays: '',
  cltPrdctUsed: '',
  cltRiderUsed: '',
  careNtwrkUsed: '',
  careFcltyUsed: '',
  careFcltyOnTheFly: '',
  careQualifierUsed: '',
  careQualifierOnTheFly: '',
  pcpsUsed: '',
  pcponTheFlyUsed: '',
  mbrLvlPlansUsedQ: '',
  qcddused: '',
  hcexchangeMethodology: '',
  gracePeriodHandling: '',
  openEnroll: '',

  fileSubmitterName: '',
  fileSubmitterEmail: '',
  fileSubmitterPhone: '',
  loadReportName: '',
  loadReportEmail: '',
  loadReportPhone: '',
  otherName: '',
  otherEmail: '',
  otherPhone: '',

  contactInfo: [],

  previousEaGroup: [],
  auditSection: [],

  qcltSendOEFile: '',
  qoewindow: '',
  qdataOnOEFile: '',
  qoefileExpDate: '',
  qoeidcards: '',
  qoecurrentTerms: '',
  qoecvstermDrops: '',
  qoedtaAfterOEFil: '',
  qoefileAfterOEDate: '',
  qoenewBnftYrFile: '',
  qoenewBnftYrFileDate: '',
  qoenewBnftYrFileTerms: '',
  qoecagstructrChg: '',
  qoetestFile: '',

  headerInfoNotes: '',
  clientBasicsNotes: '',
  lOBNotes: '',
  cOBNotes: '',
  dependantsNotes: '',
  phoneCardsNotes: '',
  pGNotes: '',
  iDConfigNotes: '',
  pcNotes: '',
  altIdNotes: '',
  fileParamNotes: '',
  fileFormatNotes: '',
  transmitNotes: '',
  fileTypeNotes: '',
  historyMethodNotes: '',
  loadReportNotes: '',
  fileFrequencyNotes: '',
  vendorNotesFeedback: '',
  termsNotes: '',
  impliedTermsNotes: '',
  termByAbsenceNotes: '',
  compareProcessNotes: '',
  thrshldNotes: '',
  cardQuestNotes: '',
  miscQuestClientNotes: '',
  groupFilesNotes: '',
  clientCodesNotes: '',
  qOENotes: '',
  questionariesLoadReportEntity: {
    id: null,
    notes: '',
    mbrLoadSftp: false,
    mbrLoadSftpFormat: 'fixedWidth',
    mbrLoadSftpDest: '',
    mbrLoadSftpDestName: '[PLI].td[filetype]mmddyyyy',
    mbrLoadResponse: false,
    mbrLoadResponseFormat: 'fixedWidth',
    mbrLoadResponseDest: '',
    mbrLoadResponseDestName: '[PLI].td[filetype]mmddyyyy',
    mbrLoadDelimiter: 'comma',
    mbrLoadBreak: false,
    mbrFirstType: "Carrier",
    mbrSecondType: "Type",
    mbrThirdType: "Reason",
    mbrFourthType: "Member ID",
    mbrIdType: 'member',
    mbrDetailMessages: [],
    mbrMaskedMemberId: false,
    mbrMaskedAlternateId: false,
    mbrMaskedDOB: false,
    mbrMaskedFname: false,
    reportFormat: [
      { reportField: 'Carrier', reportSpaceUsed: 9, reportActive: true },
      { reportField: 'Account', reportSpaceUsed: 15, reportActive: true },
      { reportField: 'Group', reportSpaceUsed: 15, reportActive: true },
      { reportField: 'Member ID', reportSpaceUsed: 15, reportActive: true },
      { reportField: 'Alternate ID', reportSpaceUsed: 15, reportActive: true },
      { reportField: 'First Name', reportSpaceUsed: 15, reportActive: true },
      { reportField: 'Date of Birth', reportSpaceUsed: 10, reportActive: true },
      { reportField: 'Effective Date', reportSpaceUsed: 10, reportActive: true },
      { reportField: 'Term Date', reportSpaceUsed: 10, reportActive: true },
      { reportField: 'Type', reportSpaceUsed: 3, reportActive: true },
      { reportField: 'Message', reportSpaceUsed: 40, reportActive: true }
    ]
  },
  questionarieFilePrefixes: [],
  signOffStatus:''
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case UPDATE_QUESTIONNAIRE_FORM:
      return action.payload;

    case LOAD_QUESTIONNAIRE_FORM_SUCCESS:
      return {
        ...action.payload,
        questionariesLoadReportEntity:setDefaultLRE(action.payload['questionariesLoadReportEntity']),
        qoefileExpDate: convertDateForDatePicker(action.payload['qoefileExpDate']),
        qoefileAfterOEDate: convertDateForDatePicker(action.payload['qoefileAfterOEDate']),
        qoenewBnftYrFileDate: convertDateForDatePicker(action.payload['qoenewBnftYrFileDate']),
        questionarieFilePrefixes: action.payload?.questionarieFilePrefixes? action.payload.questionarieFilePrefixes: []
      };

    case CLEAR_EPR_DATA:
      return initialState;

    case COPY_EPR:
      return removeIds(state)

    default:
      return state;
  }
}

export const getLineOfBusinessValues = ({qmmpclient, medD, qmedB, qclientMedicaid, subsidy, qcommercial}: State) => {
  const lineOfBusinessValues = [];

  if (qmmpclient === 'Yes') {
    lineOfBusinessValues.push('MMP');
  }

  if (medD === 'Yes') {
    lineOfBusinessValues.push('MED D');
  }

  if (qmedB === 'Yes') {
    lineOfBusinessValues.push('MED B');
  }

  if (qclientMedicaid === 'Yes') {
    lineOfBusinessValues.push('Medicaid');
  }

  if (subsidy === 'Yes') {
    lineOfBusinessValues.push('RDS');
  }

  if (qcommercial === 'Yes') {
    lineOfBusinessValues.push('Commercial');
  }

  return lineOfBusinessValues;
};

export const getPopulationValues = (state: State) => state.qpopType;
export const getFtpId = (state: State) => state.ftpid;
export const getMemberSuspenseRetentionDays = ({mbrSuspRetentionDays}: State) => mbrSuspRetentionDays;
export const getImpTermLevel = ({impTermLevel}: State) => impTermLevel;

export const getQuestionnaireTermThreshold = (state: State) => {
  if (state.termByAbsenceThshldPercent && state.termByAbsenceThshldPercent.length > 0) {
    return state.termByAbsenceThshldPercent.replace('%', '') + '%';
  } else {
    return state.questionnaireTermThreshold;
  }
};
export const getFlerrorThreshold = (state: State) => state.flerrorThreshold;
export const getTermThreshold = (state: State) => state.termThreshold;
export const getTermThresholdInclMovesCount = (state: State) => state.termThresholdInclMovesActCount;
export const getTermThresholdExclMovesCount = (state: State) => state.termThresholdExclMovesActCount;
export const getTermThresholdAllActCount = (state: State) => state.termThresholdAllActCount;
export const getTermThreshExcMoves = (state: State) => state.termThreshExcMoves;
export const getTermsVsAllActives = (state: State) => state.termsVsAllActives;
export const getFamIDThrshld = (state: State) => state.famIDThrshld;
export const getMatchLevel = (state: State) => state.matchLevel;
export const getNewPopThrshldUOM = (state: State) => state.newPopThrshldUOM;
export const getChngMbrIDUOM = (state: State) => state.chngMbrIDUOM;
export const getChngLNThrshldUse = (state: State) => state.chngLNThrshldUse;
export const getChngFNThrshldUse = (state: State) => state.chngFNThrshldUse;
export const getChngDOBThrshldUse = (state: State) => state.chngDOBThrshldUse;
export const getChngRelThrshldUse = (state: State) => state.chngRelThrshldUse;
export const getIdcardThrshld = (state: State) => state.idcardThrshld;
export const getChngLNUOM = (state: State) => state.chngLNUOM;
export const getChngFNUOM = (state: State) => state.chngFNUOM;
export const getChngDOBThrshld = (state: State) => state.chngDOBThrshld;
export const getChngRelThrshld = (state: State) => state.chngRelThrshld;
export const getNewPopThrshld = (state: State) => state.newPopThrshld;
export const getChngMbrIDThrshld = (state: State) => state.chngMbrIDThrshld;
export const getChngLNThrshld = (state: State) => state.chngLNThrshld;
export const getChngFNThrshld = (state: State) => state.chngFNThrshld;
export const getChngDOBUOM = (state: State) => state.chngDOBUOM;
export const getChngRelUOM = (state: State) => state.chngRelUOM;
export const getPrevousEAGroup = (state: State) => state.previousEaGroup;
export const getMemIDLength = (state: State) => state.memIDLength;
export const getMemIDMaxLength = (state: State) => state.memIDMaxLength;
export const getMbrIDTypeWPC = (state: State) => state.mbrIDTypeWPC?.substring(3);
export const getXwalkIDLength = (state: State) => state.xwalkIDLength;
export const getSignOffStatus = (state: State) => state.signOffStatus;

export const getXwalkIDMaxLength = (state: State) => state.xwalkIDMaxLength;
export const getXwalkIDTypeWPC = (state: State) => state.xwalkIDTypeWPC;

export const getPcLength = (state: State) => state.pclength;
export const getPcAssignmentMethod = (state: State) => state.pcassignMethod;
export const getCardID = ({cardID}: State) => cardID;
// export const getConsolidateProfileLogic = (state: State) => state.consolidateProfileLogic;
export const getVendor = (state: State) => state.vendor;
export const getVendorLookup = (state: State) => state.vendorLookup;
export const getFormat = (state: State) => state.format;
export const getGroupFilesSent = (state: State) => state.groupFilesSent;
export const getFileTransmitionMethod = (state: State) => state.fileTransmitionMethod;
export const getCompareProcess = (state: State) => state.compareProcess;
export const getCompareFrequency = (state: State) => state.compareFrequency;
export const getQphysFiles = (state: State) => state.qphysFiles;
export const getQdiagFiles = (state: State) => state.qdiagFiles;
export const getQlabFiles = (state: State) => state.qlabFiles;
export const getFileFrequencyNotes = (state: State) => {
  return parseQuillNotes(state.fileFrequencyNotes);
};


export const getTbaDate = ({tmrdate}: State) => tmrdate;
export const bufferDays = ({bufferDays}: State) => bufferDays;
export const getPersonCodes = ({personCodes}: State) => personCodes;

export const getLoadReportEntity =(state: State) =>state.questionariesLoadReportEntity;
export const getFilePrefixes =(state: State) =>state.questionarieFilePrefixes;


export const getDrivers = (state: State) => {
  return {
    autogenProgramValue: state.autogenprgm,
    willClientSendOEFile: state.qcltSendOEFile === 'Yes',
    isDataOnOEFileNewYearOnly: state.qdataOnOEFile === 'New Year Only',
    willPrintIDCards: state.qidcards === 'Yes',
    hasVendor: state.vendor === 'Yes',
    usesGroupSuspense: (state.grpSuspUsed === 'Yes') || (state.grpSuspUsed === 'Both'),
    memberSuspDays: (state.mbrSuspUsed === 'Yes - Daily') || (state.mbrSuspUsed === 'Yes - File'),
    usesCareFacility: state.careFcltyUsed === 'Yes',
    usesCareQualifier: state.careQualifierUsed === 'Yes',
    usesPCPs: state.pcpsUsed === 'Yes',
    usesHcExchange: state.hcexchangeMethodology === 'Yes',
    usesCardsPrintedMaterials: state.qidcards === 'Yes',
    personCodes: state.personCodes === 'Yes',
    pcAutoGenVsAdjMethod: state.pcautoGen === 'Yes' && state.cdhadjMethod === 'Type 7',
    usesIntegAccums: state.qintegAccums === 'Yes',
    usesPcAutoGen: state.pcautoGen === 'Yes',
    autogenProgramIncludesE: state.autogenprgm ? state.autogenprgm.includes('E') : false,
    pcAssignVspcAutogen: state.pcassignMethod === 'N/A' && state.pcautoGen === 'Yes',
    hasMultiFile: state.multFileFeeds === 'Yes',
    isPrePreLoadedUsed: state.prePreLoad === 'Yes',
    isFormatRxAndNotPreLoaded: (state.format ? state.format.includes('RX') : false) && state.prePreLoad !== 'Yes',
    xwalkUsedVsIdCards: state.xwalkUsed === 'Yes' && state.qidcards === 'Yes',
    isXwalkAutogenUsed: state.xwalkAutoGen === 'Yes',
    isXWalkUsed: state.xwalkUsed === 'Yes',
    formatRx51: state.format === 'RX 51' || state.fileParamMapToo === 'RX 51',
    thirdIdInQL: state.q3rdIdFieldUsed === 'Yes',
    bypassFileDependency: state.bypassFileDependency === 'Yes',
    isMmpUsed: state.qmmpclient === 'Yes',
    isMedDUsed: state.medD === 'Yes',
    isMedicaidUsed: state.qclientMedicaid === 'Yes',
    isSubsidyUsed: state.subsidy === 'Yes',
    hasStandAloneDeps: state.standAloneDeps === 'Yes',
    hasEarlyPhoneLines: state.qphoneLines !== 'No',
    pgCritIsNaOrAcc: (state.pgCriteria ? state.pgCriteria.includes('N/A') || state.pgCriteria.includes('Accuracy') : false),
    isCobUsed: state.cobused === 'Yes',
    isCobcUsed: state.cobcused === 'Yes',
    linkCobcToElig: state.linkCobcToElig === 'Yes',
    isStCobUsed: state.stcob === 'Yes',
    termDateThruUsed: state.termDateThru === 'Yes',
    depImpTermsUsed: state.depImpTermQues === 'Yes',
    impliedTermsUsed: state.impliedTerms,
    needsImpliedTermsLevel: state.impliedTerms === 'Yes' && (state.impTermLevel === '' || state.impTermLevel === undefined),
    checkListBsrComplete: state.prePreLoad !== 'Yes' && (state.format ? state.format.includes('RX') : false),
    checkListGroupFilesNotSent: state.groupFilesSent !== 'Yes',
    checkListGroupFilesSent: state.groupFilesSent === 'Yes',
    grpPplPgmUsed: state.grpPplPgmUsed === 'Yes',
    checkListTransmitWithoutSecureFtp: state.fileTransmitionMethod !== 'Secure FTP',
    checkListLoadReportsDelivered: state.loadRptsDelMethodQ ?
      (!state.loadRptsDelMethodQ.includes('NDM/FTP (fixed width)') &&
        !state.loadRptsDelMethodQ.includes('NDM/FTP (delimited)')) : true,
    isTmrUsed: state.tmr === 'Yes',
    isAccountListUsed: (state.tmrrangeListType ? (state.tmrrangeListType.includes('Account') ||
      (!state.tmrrangeListType.includes('Range') && state.tmrrangeListType.includes('Acct'))) : false),
    isGroupListUsed: (state.tmrrangeListType ? (state.tmrrangeListType.includes('Group') ||
      (!state.tmrrangeListType.includes('Range') && state.tmrrangeListType.includes('Grp'))) : false),
    isGroupListUsedWithOpts: (state.tmrrangeListType === 'N/A' ||
      (state.tmrrangeListType === 'Acct Range (incl)' || state.tmrrangeListType === 'Grp Range (incl)'
        || state.tmrrangeListType === 'Dynamic (incl)' || state.tmrrangeListType === '')),
    isQuesMultiCarrierUsed: state.quesMultiCarrier === 'Yes',
    formatNot834: state.format !== '834',
    fileFrequencyIsBypass: state.fileFrequency === 'Bypass',
    isCareFcltyOnTheFlyUsed: state.careFcltyOnTheFly === 'Yes',
    isCareQualifierOnTheFlyUsed: state.careQualifierOnTheFly === 'Yes',
    isCltPrdctUsed: state.cltPrdctUsed === 'Yes',
    isCltRiderUsed: state.cltRiderUsed === 'Yes',
    isCareNtwrkUsed: state.careNtwrkUsed === 'Yes',
    isMemIDMoreThan5: parseInt(state.memIDLength, 10) >= 5,

    // Testing validations
    moveHistoryFile: state.moveHstFile === 'Yes',
    isType5Used: state.type5UsedQ === 'Yes',
    type5Validation: state.type5Validation === 'Yes',
    consolidateProfileLogic: state.consolidateProfileLogic === 'Yes',

    isQMedBUsed: state.qmedB === 'Yes',
    isMbrLvlPlansUsed: state.mbrLvlPlansUsedQ === 'Yes',
    isQCDDUSed: state.qcddused ? state.qcddused.includes('Y') : false,
    participantTermDatesIsYes: state.participantTermDates === 'Yes',
    manTMRProtectIsYes: state.manTMRProtect === 'Yes',
    tmrignFtrTrmIsYes: state.tmrignFtrTrm === 'Yes',
    tmrinclFtrEffIsYes: state.tmrinclFtrEff === 'Yes',
    pcobncobusedIsYes: state.pcobncobused === 'Yes',
    batchOvrProtectIsYes: state.batchOvrProtect === 'Yes',
    crdhldrsOnlyQIsYes: state.crdhldrsOnlyQ === 'Yes',
    grpOTForAutoCAGIsYes: state.grpOTForAutoCAG === 'Yes',
    memDepDifGrpIsYes: state.memDepDifGrp === 'Yes',
    tmrrangeListTypeIsDynamic: state.tmrrangeListType ? state.tmrrangeListType.includes('Dynamic') : false,
    moving: state.moveHistQues === 'Yes' ||
      state.moveAccumsQues === 'Yes' ||
      state.movePriorAuthsQues === 'Yes',
    inclusionExlusionListUsed: state.tmrrangeListType ? state.tmrrangeListType !== '' && state.tmrrangeListType !== 'N/A' &&
      !state.tmrrangeListType.includes('Dynamic') && state.tmrrangeListType !== null : false,
    hasHistoryMethod: state.qhistMethod !== '' &&  state.qhistMethod !== null,
    tbaThresholdCount: state.questionnaireTermThreshold,
    tbaThresholdPercent: state.termByAbsenceThshldPercent,
  };
};

function parseQuillNotes(notes: string): string {
  if (!notes) {
    return '';
  }
  const staticInsertString = '"insert":"';
  const endCharsString = '"}';

  let insertIndex = notes.indexOf(staticInsertString, 0);
  if (insertIndex < 0) {
    return notes;
  }
  let remainingString = notes.substring(0, notes.length); // Create a deep copy

  let retString = '';

  while (insertIndex > -1) {
    const endIndex = remainingString.indexOf(endCharsString);
    const substring = remainingString.slice(insertIndex + staticInsertString.length, endIndex);
    retString += substring;
    remainingString = remainingString.substring(endIndex + endCharsString.length);
    insertIndex = remainingString.indexOf(staticInsertString); // Search for the next location
  }

  retString = retString.replace(/\\n/g, '\n');
  return retString.slice(0, retString.length - 1); // Removes the trailing \n that quill adds
}
function setDefaultLRE(reportEntity){
  if(!reportEntity){
    return initialState.questionariesLoadReportEntity;
  }
  if(reportEntity.mbrFirstType && reportEntity.mbrSecondType && reportEntity.mbrThirdType && reportEntity.mbrFourthType){
  }else{
    reportEntity.mbrFirstType = initialState.questionariesLoadReportEntity.mbrFirstType;
    reportEntity.mbrSecondType = initialState.questionariesLoadReportEntity.mbrSecondType;
    reportEntity.mbrThirdType = initialState.questionariesLoadReportEntity.mbrThirdType;
    reportEntity.mbrFourthType = (reportEntity.mbrIdType && reportEntity.mbrIdType ==='alternate')?
    'Alternate ID': initialState.questionariesLoadReportEntity.mbrFourthType;

  }
  if(reportEntity.reportFormat.filter((field=>{return field.reportField !== null})).length === 0){
    reportEntity.reportFormat = initialState.questionariesLoadReportEntity.reportFormat;
  }

  reportEntity.reportFormat = reportEntity.reportFormat.filter((field=>{return field.reportField !== null}))
  reportEntity.reportFormat.forEach(format=> format.sortOrder = reportEntity.reportFormat.indexOf(format))
  reportEntity.mbrDetailMessages = reportEntity.mbrDetailMessages.filter(msg =>{return msg.message !== null})
  return reportEntity;
}
function removeIds(state){
  state.id = null;
  state.questionariesLoadReportEntity.id = null;
  state.questionariesLoadReportEntity.mbrDetailMessages.forEach(msg => msg.id = null);
  state.questionariesLoadReportEntity.reportFormat.forEach(field => field.id = null);

  return state;
}
