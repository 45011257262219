import {Action} from '@ngrx/store';
import {Spedm} from '../models/Spedm';
import {Contact} from '../models/Contact';

export const LOAD_ALL_SPEDM = '[Spedm] Load All Spedm';
export const LOAD_ALL_SPEDM_SUCCESS = '[Spedm] Spedm ALL Loaded Success';
export const LOAD_ALL_SPEDM_FAILURE = '[Spedm] Spedm ALL Loaded Failure';
export const LOAD_SPEDM = '[Spedm] Load Spedm';
export const LOAD_SPEDM_SUCCESS = '[Spedm] Spedm Loaded Success';
export const LOAD_SPEDM_FAILURE = '[Spedm] Spedm Loaded Failure';

export const CLEAR_SPEDM_DATA = '[Filetrakcer]  Clearing all Filetracker Data';
export const SAVE_SPEDM = '[Filetracker] Save Filetracker';
export const UPDATE_SPEDM = '[Filetracker] Update Filetracker';
export const SAVE_SPEDM_SUCCESS = '[Filetracker] Save Filetracker Success';
export const SAVE_SPEDM_SUCCESS_FAILURE = '[Filetracker] Filetracker Saved Failure';
export const LOAD_CONTACT = '[Contact] Contact Loaded';
export const LOAD_CONTACT_SUCCESS = '[Contact] Contact Loaded Success';
export const SAVE_CONTACT = '[Contact] Save Contact';
export const SAVE_CONTACT_SUCCESS = '[Contact] Save Contact Success';
export const SAVE_CONTACT_SUCCESS_FAILURE = '[Contact] Contact Saved Failure';
export const EDIT_SPEDM = '[Filetracker] Edit Filetracker';
export const EDIT_SPEDM_SUCCESS = '[Filetracker] Edit Filetracker Success';
export const EDIT_SPEDM_SUCCESS_FAILURE = '[Filetracker] Filetracker Edit Failure';
export const DELETE_SPEDM = '[Filetracker] Deleting Filetracker...';
export const DELETE_SPEDM_SUCCESS = '[Filetracker] Filetracker deleted successfully';
export const FILTER_SPEDM_BY_ID = '[Filetracker] Filter Filetrackers By Id';
export const UPDATE_CONTACT = '[Contact] Contact Updated';
export const UPDATE_CONTACT_SUCCESS = '[Contact] Contact Updated Successfully';
export const UPDATE_CONTACT_SUCCESS_FAILURE = '[Contact] Contact Updated Failure';
export const DELETE_CONTACT = '[EPRFEED] Deleting Cont...';
export const DELETE_CONTACT_SUCCESS = '[EPRFEED] EprFeed deleted successfully';
export const LOAD_DATA = 'LoadData';
export const EDIT_MODE = 'Edit';

export class LoadAllSpedmAction implements Action {
  readonly type = LOAD_ALL_SPEDM;
}

export class LoadAllSpedmSuccessAction implements Action {
  readonly type = LOAD_ALL_SPEDM_SUCCESS;
  constructor(public payload: Spedm[]) { }
}

export class LoadAllSpedmFailureAction implements Action {
  readonly type = LOAD_ALL_SPEDM_FAILURE;
}
export class LoadSpedmAction implements Action {
  readonly type = LOAD_SPEDM;

  constructor(public payload: number) {
  }
}
export class ClearSpedmAction implements Action {
  readonly type = CLEAR_SPEDM_DATA;
}

export class LoadSpedmSuccessAction implements Action {
  readonly type = LOAD_SPEDM_SUCCESS;

  constructor(public payload: Spedm) { }
}

export class LoadSpedmFailureAction implements Action {
  readonly type = LOAD_SPEDM_FAILURE;
}

export class SaveSpedmAction implements Action {
  readonly type = SAVE_SPEDM;

  constructor(public payload: Spedm) { }
}
export class UpdateSpedmAction implements Action {
  readonly type = UPDATE_SPEDM;

  constructor(public payload: Spedm) { }
}
export class UpdateContact implements Action {
  readonly type = UPDATE_CONTACT;

  constructor(public payload: any) { }
}

export class UpdateContactSuccessAction implements Action {
  readonly type = UPDATE_CONTACT_SUCCESS;

  constructor(public payload: Spedm) { }
}

export class UpdateContactFailureAction implements Action {
  readonly type = UPDATE_CONTACT_SUCCESS_FAILURE;
}
export class SaveSpedmSuccessAction implements Action {
  readonly type = SAVE_SPEDM_SUCCESS;

  constructor(public payload: Spedm) { }
}

export class SaveSpedmFailureAction implements Action {
  readonly type = SAVE_SPEDM_SUCCESS_FAILURE;
}
export class LoadContactAction implements Action {
  readonly type = LOAD_CONTACT;

  constructor(public payload: any) {
  }
}
export class LoadContactSuccessAction implements Action {
  readonly type = LOAD_CONTACT_SUCCESS;

  constructor(public payload: Contact) { }
}
export class SaveContactAction implements Action {
  readonly type = SAVE_CONTACT;

  constructor(public payload: any ) { }
}


export class SaveContactSuccessAction implements Action {
  readonly type = SAVE_CONTACT_SUCCESS;

  constructor(public payload: Contact) { }
}

export class SaveContactFailureAction implements Action {
  readonly type = SAVE_CONTACT_SUCCESS_FAILURE;
}

export class EditSpedmAction implements Action {
  readonly type = EDIT_SPEDM;

  constructor(public payload: any) { }
}

export class EditSpedmSuccessAction implements Action {
  readonly type = EDIT_SPEDM_SUCCESS;

  constructor(public payload: Spedm) { }
}

export class EditSpedmFailureAction implements Action {
  readonly type = EDIT_SPEDM_SUCCESS_FAILURE;
}
export class DeleteSpedmAction implements Action {
  readonly type = DELETE_SPEDM;

  constructor(public payload: number) {
  }
}
export class DeleteSpedmSuccessAction implements Action {
  readonly type = DELETE_SPEDM_SUCCESS;
}
export class FilterSpedmById implements Action {
  readonly type = FILTER_SPEDM_BY_ID;

  constructor(public payload: any) {}
}
export class DeleteContactAction implements Action {
  readonly type = DELETE_CONTACT;

  constructor(public payload: number) {
  }
}
export class DeleteContactSuccessAction implements Action {
  readonly type = DELETE_CONTACT_SUCCESS;
}
export class LoadDataAction implements Action {
  readonly type = LOAD_DATA;

  constructor(public payload: any) {
  }
}
export class EditAction implements Action {
  readonly type = EDIT_MODE;

  constructor(public payload: boolean) {
  }
}


export type Actions
  = LoadSpedmAction
  | LoadSpedmSuccessAction
  | LoadSpedmFailureAction
  | LoadAllSpedmAction
  | LoadAllSpedmSuccessAction
  | LoadAllSpedmFailureAction
  | SaveSpedmAction
  | UpdateSpedmAction
  | SaveSpedmSuccessAction
  | SaveSpedmFailureAction
  | LoadContactAction
  | LoadContactSuccessAction
  | SaveContactAction
  | SaveContactSuccessAction
  | SaveContactFailureAction
  | EditSpedmAction
  | EditSpedmSuccessAction
  | EditSpedmFailureAction
  | DeleteSpedmAction
  | DeleteSpedmSuccessAction
  | FilterSpedmById
  | ClearSpedmAction
  | UpdateContact
  | UpdateContactSuccessAction
  | UpdateContactFailureAction
  | DeleteContactAction
  | DeleteContactSuccessAction
  | LoadDataAction
  | EditAction;

