import { DeleteBtnCellRendererComponent } from './../../../common/DeleteBtnCellRenderer';
import { AppState } from './../../../reducers/index';
import { Store } from '@ngrx/store';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { MessageSupressionService } from './../../../services/message-supression.service';
import { Component, OnInit, Output, ViewChild, EventEmitter, AfterViewInit, HostListener } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';


export interface MapTypeModel {
  id: any;
  message: string;
}
export const mapTypeGridColumns = [
  {
    headerName: 'Id', field: 'id', hide: true
  },
  {
    headerName: 'Map Type', field: 'message',
    editable: true,
    sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    heigth: 300,
    width: 440,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    cellEditorParams: {
      maxLength: 1000,
      rows: 10,
      cols: 50
    }
  },
  {
    headerName: 'Action',
    cellRenderer: 'deleteAction',
    editable: false,
    width: 90
  }
];


@Component({
  selector: 'app-map-type',
  templateUrl: './map-type.component.html',
  styleUrls: ['./map-type.component.scss']
})
export class MapTypeComponent implements OnInit, AfterViewInit {
@ViewChild('mapTypeGrid') mapTypeGrid: AgGridAngular;
  @Output() isSaveMapTypeCalled: EventEmitter<any> = new EventEmitter();

  dataRows = [];
  hlGridHeight = 350;
  contextObj: any;
  selectedStatus = '';
  frameworkComponents: any;
  changedRow: any;
  readonly: boolean;


constructor(private msgSupressService: MessageSupressionService, private alertService: CVSAlertService, private store: Store<AppState>) {
    this.frameworkComponents = {
      deleteAction: DeleteBtnCellRendererComponent,
      isEnableDeleteButton: true,
    };
    this.contextObj = {
      componentParent: this
    };
  }
  ngOnInit() {
    this.getAllRows();
    this.hlGridHeight = 350;
  }
ngAfterViewInit(): void {
    setTimeout(() => { this.addNewRow(); this.saveUnSavedRecords();
                       this.alertService.clearAlert('#map-alert');
    }, 500);
  }
  getAllRows() {
    this.msgSupressService.getAllMsgSupressRows(true).subscribe(res => {
      this.dataRows = res;
      this.dataRows.sort((a, b) => a.id - b.id);
    });
    this.changedRow = false;
  }
  saveUnSavedRecords() {

    this.msgSupressService.saveNewMapTypeRecords$.subscribe(resp => {
      const updatedRec = [];

      if (resp) {
        this.mapTypeGrid.api.forEachNode(node => {
          if (node.data.isUpdated || node.data.isNew) {
            
            delete node.data.isUpdated;
            delete node.data.isNew;
            updatedRec.push(node.data);
          }
        });
        if (updatedRec && updatedRec.length) {

          updatedRec[0]['type'] = 'mapType';
          this.msgSupressService.saveMsgSupressRow(updatedRec, true).subscribe(_ => {
            this.getAllRows();
            this.readonly = false;
            
            this.alertService.sendAlert({
              headline: 'Save successful!',
              alertType: CVSAlertType.Success,
              removedAfterMilliseconds: 5000,
              alertDocumentHolderName: '#map-alert',
            });
            // this.store.dispatch(new LoadMasterDataSetAction('holidays'));
          });
        }
      }
    });

  }
  addNewRow() {
    this.msgSupressService.onMapTypeMessage().subscribe(newRow => {
      if (newRow != null) {
        this.mapTypeGrid.api.updateRowData({
          addIndex: 0,
          add: newRow
        });
        this.mapTypeGrid.api.setFocusedCell(0, 'id');

        this.hlGridHeight = (this.mapTypeGrid.api.getModel().getRowCount() * 42) + 52;
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.readonly) {

      if (confirm('Changes have NOT been saved! Click \'OK\' to exit WITHOUT saving.')) {
        return true;
      } else {
        return false;
      }
    }
  }
  showAlert(alertMessage, alertType) {
    this.alertService.sendAlert({
      headline: alertMessage,
      alertType,
      removedAfterMilliseconds: 5000,
      alertDocumentHolderName: '#map-alert',
    });
  }
  onGridReady(params) {
    this.mapTypeGrid = params;
    this.hlGridHeight = this.dataRows.length * 51;
    this.mapTypeGrid.api.setColumnDefs(mapTypeGridColumns);
    this.mapTypeGrid.api.redrawRows();
    this.mapTypeGrid.gridOptions = { onCellValueChanged(data)  {
      this.UpdatedRow(data);
    }};
  }
  UpdatedRow(cell) {
    cell.data.isUpdated = true;
    this.readonly = true;
    }
  emitSave() {
    this.isSaveMapTypeCalled.emit();

  }
public deleteTableRecord(node, colId) {

    if (node.data.isNew) {
      this.readonly = false;
      this.mapTypeGrid.api.removeItems([node], false);
      this.hlGridHeight = (this.mapTypeGrid.api.getModel().getRowCount() * 42) + 52;
    } else {
      this.msgSupressService.deleteMsgSupressRow([{ ...node.data }], false).subscribe(resp => {
        this.mapTypeGrid.api.removeItems([node], true);
        this.hlGridHeight = (this.mapTypeGrid.api.getModel().getRowCount() * 42) + 52;
      });
    }
  }

}
