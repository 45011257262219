import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {
  AppState,
  getReadOnly,
  getSelectedProjectIndexFromTesting,
  getSelectedTestIteration,
  getTestIterationCount
} from '../reducers';
import {debounceTime, filter, take, withLatestFrom} from 'rxjs/operators';
import {combineLatest, Subscription} from 'rxjs';
import {FormType} from '../models/FormType';
import {UpdateHeaderFormAction} from '../actions/header.action';
import {CheckIfCrossFormPopulationsNeededAction} from '../actions/questionnaire.action';
import {UpdateEligibilityAgreementFormAction} from '../actions/eligibility-agreement.action';
import {CheckIfCrossFormPopulationsNeededActionChecklist} from '../actions/checklist.action';
import {UpdateTestingFormAction} from '../actions/testing.action';
import {UpdateProjectTrackerFormAction} from '../actions/project-tracker.action';

@Directive({
  selector: '[appConnectFormValues]'
})
export class ConnectFormValuesDirective implements OnInit, OnDestroy {
  @Input('appConnectFormValues') formType: string;
  formChange: Subscription;
  formDataSubscription: Subscription;

  debounce = 300;
  projectTrackerIndex = 0;
  pauseFormUpdates = false;


  constructor(private formGroupDirective: FormGroupDirective,
              private store: Store<AppState>) {}

  ngOnInit() {
    // Input binding (initializes form data based on state)
    const formType$ = this.store.select(state => state[this.formType]);
    const selectedProjectIndex$ = this.store.select(state => state.epr.selectedProjectIndex);
    const numProjects$ = this.store.select(state => state.projectTracker.projects.length);
    const readOnly$ = this.store.select(getReadOnly);

    const projectIndexFromTesting$ = this.store.select(getSelectedProjectIndexFromTesting);
    const testIterationIndex$ = this.store.select(getSelectedTestIteration);
    const numTestIterations$ = this.store.select(getTestIterationCount);

    if (this.formType === FormType.PROJECT_TRACKER) {
        this.formDataSubscription = combineLatest(selectedProjectIndex$, numProjects$).pipe(
          withLatestFrom(formType$),
        ).subscribe(([[selectedProjectIndex, _], projectTrackerStore]) => {
          this.projectTrackerIndex = selectedProjectIndex;
          projectTrackerStore.projects.sort((a,b) => b.id -a.id);
          if (projectTrackerStore.projects[this.projectTrackerIndex] !== undefined) {
          this.formGroupDirective.form.patchValue(projectTrackerStore.projects[this.projectTrackerIndex]);
          }
        });
    } else if (this.formType === FormType.CHECKLIST) {
      this.formDataSubscription = combineLatest(selectedProjectIndex$, numProjects$).pipe(
        withLatestFrom(formType$),
      ).subscribe(([[selectedProjectIndex, _], checklistStore]) => {
        this.projectTrackerIndex = selectedProjectIndex;
        if (checklistStore.checklists[this.projectTrackerIndex] !== undefined) {
        this.formGroupDirective.form.patchValue(checklistStore.checklists[this.projectTrackerIndex]);
        }
      });
    } else if (this.formType === FormType.TESTING) {
      this.formDataSubscription =
        combineLatest(testIterationIndex$, projectIndexFromTesting$, numProjects$, numTestIterations$).pipe(
          withLatestFrom(formType$, readOnly$, testIterationIndex$),
        ).subscribe(([[testIterationIndex, projectIndex], testingReducerState, readOnly]) => {
          this.pauseFormUpdates = true;
          if (testingReducerState.projectTests[projectIndex] !== undefined &&
            testingReducerState.projectTests[projectIndex][testIterationIndex] !== undefined) {
              const projectTestFormData = testingReducerState.projectTests[projectIndex][testIterationIndex];

              const customTestCriteria = this.formGroupDirective.form.controls['customTestCriteria'] as UntypedFormArray;
              customTestCriteria.clear();

              projectTestFormData.customTestCriteria.map(value => {
                customTestCriteria.push(new UntypedFormGroup({
                  cstmTC: new UntypedFormControl({value: value.cstmTC, disabled: readOnly}),
                  cstmTCStatus: new UntypedFormControl({value: value.cstmTCStatus, disabled: readOnly}),
                  cstmTCSvrty: new UntypedFormControl({value: value.cstmTCSvrty, disabled: readOnly}),
                  cstmTCNotes: new UntypedFormControl({value: value.cstmTCNotes, disabled: readOnly})
                }));
              });
              this.formGroupDirective.form.patchValue(projectTestFormData, {emitEvent: false});
              this.pauseFormUpdates = false;
            }

        });
    } else if (this.formType === FormType.ELIGIBILITY_AGREEMENT) {
        this.formDataSubscription = this.store.select(state => state[this.formType]).pipe(
          take(1),
          withLatestFrom(readOnly$)
        ).subscribe(([formValue, readOnly]) => {
          if(formValue){
            this.pauseFormUpdates = true;

            const changeLogData = formValue.changeLog;

            const changeLog = this.formGroupDirective.form.controls['changeLog'] as UntypedFormArray;

            changeLogData.forEach(changeLogRow => {

              changeLog.push(new UntypedFormGroup ({
                dateCreated: new UntypedFormControl({value: changeLogRow.dateCreated, disabled: readOnly}),
                change: new UntypedFormControl({value: changeLogRow.change, disabled: readOnly})
              }));
            });
            this.formGroupDirective.form.patchValue(formValue);
            this.pauseFormUpdates = false;
          }

        });
    } else if (this.formType === FormType.QUESTIONNAIRE) {
      this.formDataSubscription = this.store.select(state => state[this.formType]).pipe(
        take(1),
        withLatestFrom(readOnly$)
      ).subscribe(([formValue, readOnly]) => {
        if(formValue) {
          this.pauseFormUpdates = true;

          const prefixInfoData = formValue.questionarieFilePrefixes;
          const contactInfoData = formValue.contactInfo;
          const previousEaGroupData = formValue.previousEaGroup;
          const auditSectionData = formValue.auditSection;

          const prefixInfo = this.formGroupDirective.form.controls['questionarieFilePrefixes'] as UntypedFormArray;
          const contactInfo = this.formGroupDirective.form.controls['contactInfo'] as UntypedFormArray;
          const previousEaGroup = this.formGroupDirective.form.controls['previousEaGroup'] as UntypedFormArray;
          const auditSection = this.formGroupDirective.form.controls['auditSection'] as UntypedFormArray;

          contactInfoData.forEach((additionalContact,index) => {
            contactInfo.push(new UntypedFormGroup({
              contactRole: new UntypedFormControl({value: additionalContact.contactRole, disabled: readOnly}),
              contactName: new UntypedFormControl({value: additionalContact.contactName, disabled: readOnly}),
              contactEmail: new UntypedFormControl({value: additionalContact.contactEmail, disabled: readOnly}),
              contactPhone: new UntypedFormControl({value: additionalContact.contactPhone, disabled: readOnly}),
              contactEwfAccessType: new UntypedFormControl({value: (additionalContact?.contactEwfAccessType? additionalContact?.contactEwfAccessType : "None"), disabled: readOnly}),
              contactEwfAccessStatus : new UntypedFormControl({value: additionalContact?.contactEwfAccessStatus || "A", disabled: readOnly}),
              loadReport: new UntypedFormControl({value: (additionalContact?.loadReport? additionalContact?.loadReport : "None"), disabled: readOnly}),
              rowId: new UntypedFormControl({value: index, disabled: readOnly}),
              exist: new UntypedFormControl({value: (additionalContact?.id? true : false), disabled: readOnly}),
            }));
          });
          prefixInfoData?.forEach((prefixes,index) => {
            prefixInfo.push(new UntypedFormGroup({
              id: new UntypedFormControl({value: (prefixes?.id? prefixes.id: null), disabled: readOnly}),
              pli: new UntypedFormControl({value: prefixes.pli, disabled: readOnly}),
              fileType: new UntypedFormControl({value: prefixes.fileType, disabled: readOnly,}, [Validators.required]),
              description: new UntypedFormControl({value: prefixes.description, disabled: readOnly}),
              inboundFileName: new UntypedFormControl({value: (prefixes?.inboundFileName), disabled: readOnly}),
              prefix : new UntypedFormControl({value: prefixes?.prefix, disabled: readOnly}, [Validators.required]),
              bypassFileSchedule: new UntypedFormControl({value: prefixes?.bypassFileSchedule, disabled: readOnly}, [Validators.required]),
              bypassTba: new UntypedFormControl({value: prefixes?.bypassTba, disabled: readOnly}, [Validators.required]),
              bypassThreshold: new UntypedFormControl({value: (prefixes?.bypassThreshold), disabled: readOnly}, [Validators.required]),
              rowId: new UntypedFormControl({value: index, disabled: readOnly}),
            }));
          });


          previousEaGroupData.forEach(previousEa => {
            previousEaGroup.push(new UntypedFormGroup({
              previousEa: new UntypedFormControl({value: previousEa.previousEa, disabled: readOnly}),
              previousEADateSince: new UntypedFormControl({value: previousEa.previousEADateSince, disabled: readOnly}),
            }));
          });

          auditSectionData.forEach(audit => {
            auditSection.push(new UntypedFormGroup({
              ecaudited: new UntypedFormControl({value: audit.ecaudited, disabled: readOnly}),
              auditor: new UntypedFormControl({value: audit.auditor, disabled: readOnly}),
              auditDate: new UntypedFormControl({value: audit.auditDate, disabled: readOnly}),
            }));
          });

          this.formGroupDirective.form.patchValue(formValue);

          this.pauseFormUpdates = false;

        }
      });
    } else {
      this.formDataSubscription = this.store.select(state => state[this.formType]).pipe(take(1)).subscribe(formValue =>
        this.formGroupDirective.form.patchValue(formValue));
    }

    // Output binding (updates state to respond to form changes)
    this.formChange = this.formGroupDirective.form.valueChanges
      .pipe(
        filter(_ => !this.pauseFormUpdates),
        debounceTime(this.debounce)
      ).subscribe(() => {
        const value = this.formGroupDirective.form.getRawValue();

        switch (this.formType) {
          case FormType.PROJECT_TRACKER:
            this.store.dispatch(new UpdateProjectTrackerFormAction({
                value,
                selectedProjectIndex: this.projectTrackerIndex
              }
            ));
            break;
          case FormType.CHECKLIST:
            this.store.dispatch(new CheckIfCrossFormPopulationsNeededActionChecklist(value));
            break;
          case FormType.ELIGIBILITY_AGREEMENT:
            this.store.dispatch(new UpdateEligibilityAgreementFormAction(value));
            break;
          case FormType.HEADER:
            this.store.dispatch(new UpdateHeaderFormAction(value));
            break;
          case FormType.QUESTIONNAIRE:
            if(value?.bufferDays) {
              value.bufferDays = value?.bufferDays=="null" ? null: value?.bufferDays
            }
            this.store.dispatch(new CheckIfCrossFormPopulationsNeededAction(value));
            break;
          case FormType.TESTING:
            this.store.dispatch(new UpdateTestingFormAction(value));
            break;
            case FormType.BATCH_PROFILE_UPDATES:
              this.store.dispatch(new UpdateTestingFormAction(value));
              break;
        }
      });
  }

  ngOnDestroy(): void {
    this.formChange.unsubscribe();
    this.formDataSubscription.unsubscribe();
  }
}
