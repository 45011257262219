export const copayColumns = [
    {headerName: 'Client Receiver ID', field: 'clientReceiver', sortable: true, filter: true, resizable: true},
    {headerName: 'Input File Name', field: 'inputFileNamePattern', sortable: true, filter: true, resizable: true},
    {headerName: 'Output File Name', field: 'outputFileNamePattern', sortable: true, filter: true, resizable: true},
    {headerName: 'File Frequency', field: 'reportFrequency', sortable: true, filter: true, resizable: true},
    {headerName: 'File Frequency Value', field: 'reportFrequencyVal', sortable: true, filter: true, resizable: true},
    {headerName: 'Business Unit', field: 'systemTx', sortable: true, filter: true, resizable: true},
    {headerName: 'Active/InActive Status', field: 'activeorInactiveStatus', sortable: true, filter: true, resizable: true},
    {headerName: 'Contracted Delivery Time (CST)', field: 'contractedDeliveryTime', sortable: true, filter: true, resizable: true},
    {headerName: 'Late Files Warning Time (CST)', field: 'lateFileWarningTime', sortable: true, filter: true, resizable: true},
    {headerName: 'Processing time SLA For Output files', field: 'outputAlertProcTimeSla', sortable: true, filter: true, resizable: true},
    {headerName: 'Late file warning SLA For Output files', field: 'outputLateFileAlertTimeSla', sortable: true, filter: true, resizable: true},
    {headerName: 'Missed File SLA Alert For Input', field: 'missedFileSlaAlertForInput', sortable: true, filter: true, resizable: true},
    {headerName: 'Late File Warning Alert For Input', field: 'lateFileAlertForInput', sortable: true, filter: true, resizable: true},
   {headerName: 'Missed File SLA Alert For Output', field: 'missedFileSlaAlertForOutput', sortable: true, filter: true, resizable: true},
   {headerName: 'Late File Warning Alert For Output', field: 'lateFileAlertForOutput', sortable: true, filter: true, resizable: true},
       {
      headerName: 'Note', field: 'note', sortable: true, filter: true, resizable: true,
      tooltipField: 'note',
      tooltipComponentParams: {color: '#ececec'}
    },
  ];
