<cvs-alert-placeholder id="saveTestResults-alert">
</cvs-alert-placeholder>
<cvs-alert-placeholder id="test-results-alert">
</cvs-alert-placeholder>
<div class="mat-accord-body">
  <ag-grid-angular
    class="ag-theme-alpine"
    style="height: 300px"
    id="projectInfoGrid"
   (gridReady)="onGridReady($event)"
    #projectInfoGrid
    [rowSelection]="true"
    (rowClicked)='onRowClicked($event)'
    [overlayLoadingTemplate]="loadingTemplate"
  >
  </ag-grid-angular>
</div>
<div *ngIf="rowdata.length && projectId" id="testing-form" class="testing-wrapper">
  <div class="title-and-btn-container">
    <img src="assets/cvs-health-logo-old.png" class="logo-icon"/>
    <div class="cvs-email-wrapper">
    <div class="button-container">
     
      <button  class="pds-btn-ghost" *ngIf="!readOnly" (click)="discardChanges()">Discard Test Results Changes</button>
      <button  class="cvs-btn-primary" *ngIf="!readOnly" (click)="saveTestResults()">Save</button>
      <button  id="downloadPDF" class="cvs-btn-white cvs-btn-exportTest-pdf" *ngIf="readOnly" (click)="download()"><mat-icon svgIcon="file-pdf--s"></mat-icon>Export</button>
      <a class="cvs-btn-email" *ngIf="readOnly" [href]="emailBody">
        <span class="cvs-btn-email-text">Send Email</span></a>
      
      <button id="edit-btn" *ngIf="readOnly " [disabled]="!isEprReadOnly" class="cvs-btn-primary cvs-btn-editTest-btn" (click)="changeReadMode(false)">Edit Test Results</button>
    </div>
    <div class="epr-hint" *ngIf="!isEprReadOnly">To edit Test Results, save EPR changes</div>
   </div>
  </div>

  <h2>{{displayProjectForTesting}} </h2>

  <div class="iteration-actions">
    <mat-form-field>
      <label class="form-label">Select Iteration</label>
      <mat-select (selectionChange)="changeSelectedTestIteration($event)" [value]="currentTestIterIndex">
        <mat-option *ngFor="let _ of ' '.repeat(testingIterationCount).split(''), let iter = index" [value]="iter">
          Iteration {{ (testingIterationCount ) - iter }}

            <button
                    id="delete-iteration-btn"
                    class="delete-test-itr-btn"
                    (click)="openDeleteTestIterationModal($event, iter)"
                    [disabled]="readOnly || disableDeleteIteration(iter)">
              <mat-icon class='i-trash' svgIcon="trash--s"></mat-icon>
            </button>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button id="add-iteration-btn" class="cvs-btn-ghost cvs-btn-addTest-btn"
            (click)="addIteration()"
            [disabled]="readOnly"
    >
<!--      <mat-icon svgIcon="plus&#45;&#45;s"></mat-icon>-->
      Add Iteration
    </button>
  </div>

  <form
    [formGroup]="testingForm"
    class="testing-form testresultsIcon"
  >
    <ng-container id="test-results" *ngFor="let field of testResultsFormConfig">
      <ng-container [ngSwitch]="field.inputType">
        <mat-form-field *ngSwitchCase="inputType.TEXT" >
          <label class="form-label">{{field.label}}</label>
          <input
            type="text"
            matInput
            formControlName="{{field.controlName}}"
          >
        </mat-form-field>

        <ng-container *ngSwitchCase="inputType.DROPDOWN">
          <mat-form-field *ngSwitchCase="inputType.DROPDOWN" style="width: 150px">
          <label class="form-label">{{field.label}}</label>
          <mat-select formControlName="{{field.controlName}}">
            <mat-option *ngFor="let option of field.options" [value]="option">{{option}}</mat-option>
          </mat-select>
          </mat-form-field>
          <cvs-popover-button *ngIf="readOnly && field.hint"
                              iconName="info-circle--s"
                              iconOnly
                              hideX
                              [direction]="popoverDirection.RIGHT">
            <ng-container *ngTemplateOutlet="overallStatusHint"></ng-container>
          </cvs-popover-button>
        </ng-container>

        <mat-form-field *ngSwitchCase="inputType.CHIP" class="chips" appearance="none">
          <h3>{{field.label}}</h3>
          <p>Click to select the testing methods you used</p>
          <mat-chip-list formControlName="{{field.controlName}}" selectable multiple>
            <ng-container *ngIf="(readOnly); else notReadOnly">
              <mat-chip *ngFor="let method of testingMethods;"
                        [value]="method">
                {{method}}
              </mat-chip>
            </ng-container>
            <ng-template #notReadOnly>
              <mat-chip  *ngFor="let method of testingMethods;"
                         (click)="selectChip(method)"
                         [value]="method">
                {{method}}
              </mat-chip>
            </ng-template>
          </mat-chip-list>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="inputType.USERS_DROPDOWN">
          <label class="form-label">{{field.label}}</label>
          <mat-select formControlName="{{field.controlName}}">
            <mat-option *ngFor="let user of activeUsers$ | async"
                              [value]="user.id">{{user.firstName}} {{user.lastName}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="inputType.DATE" style="width: 150px">
          <label class="form-label">{{field.label}}</label>
          <input matInput [matDatepicker]="datepicker" formControlName="{{field.controlName}}">
          <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker></mat-datepicker>
        </mat-form-field>
      </ng-container>
    </ng-container>

    <h3>Testing Criteria</h3>

    <table id="test-criteria" class="testing-table">
      <colgroup>
        <col span="1" style="width: 20%">
        <col span="1" style="width: 5%">
        <col span="1" style="width: 5%">
        <col span="1" style="width: 70%">
      </colgroup>

      <tr>
        <th>Standard</th>
        <th class="testresultsIcon">
          <span>Status</span>
          <cvs-popover-button *ngIf="readOnly"
                              iconName="info-circle--s"
                              iconOnly
                              hideX
                              [direction]="popoverDirection.RIGHT">
            <ng-container *ngTemplateOutlet="statusHint"></ng-container>
          </cvs-popover-button>
        </th>
        <th class="testresultsIcon">
          <span>Severity</span>
          <cvs-popover-button *ngIf="readOnly"
                              iconName="info-circle--s"
                              iconOnly
                              hideX
                              [direction]="popoverDirection.RIGHT">
            <ng-container *ngTemplateOutlet="severityHint"></ng-container>
          </cvs-popover-button>
        </th>
        <th>
          Comments
        </th>
      </tr>

      <tr *ngFor="let testCriteria of testingCriteria">
        <td>
          {{testCriteria.label}}
        </td>
        <td>
          <mat-form-field
            appearance="none"
            class="test-criteria-input test-criteria-status"
            [ngClass]="{
              'criteria-status-grey': testingForm.get(testCriteria.baseControlName + 'Status').value === 'Not Tested' || testingForm.get(testCriteria.baseControlName + 'Status').value === 'Not Applicable',
              'criteria-status-orange': testingForm.get(testCriteria.baseControlName + 'Status').value === 'EC Discretion' || testingForm.get(testCriteria.baseControlName + 'Status').value === 'Insufficient Data',
              'criteria-status-green': testingForm.get(testCriteria.baseControlName + 'Status').value === 'Pass',
              'criteria-status-red': testingForm.get(testCriteria.baseControlName + 'Status').value === 'Fail'
            }"
          >
            <mat-select formControlName="{{testCriteria.baseControlName + 'Status'}}" >
              <mat-option class="status-option" *ngFor="let option of statusOptions" [value]="option">{{option}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field appearance="none" class="test-criteria-input test-criteria-severity">
            <mat-select formControlName="{{testCriteria.baseControlName + 'Svrty'}}">
              <mat-option *ngFor="let option of severityOptions" [value]="option">{{option}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td class="notes">
          <quill-editor
            formControlName="{{testCriteria.baseControlName + 'Notes'}}"
            placeholder="">
          </quill-editor>
        </td>
      </tr>
    </table>

    <table
      id="custom-test-criteria"
      style="margin-top: 15px"
      class="testing-table"
    >
      <colgroup>
        <col span="1" style="width: 20%">
        <col span="1" style="width: 5%">
        <col span="1" style="width: 5%">
        <col span="1" style="width: 70%">
      </colgroup>

      <tr>
        <th>Custom</th>
        <th>Status</th>
        <th>Severity</th>
        <th>Comments</th>
      </tr>
      <ng-container formArrayName="customTestCriteria">
        <tr *ngFor="let _ of customTestCriteriaFormArray?.controls; let i = index" [formGroupName]="i">
          <td>
            <mat-form-field appearance="none" class="test-criteria-input custom-criteria-input">
              <input
                formControlName="cstmTC"
                type="text"
                matInput
              >
            </mat-form-field>
          </td>
          <td>
            <mat-form-field
              appearance="none"
              class="test-criteria-input test-criteria-status"
              [ngClass]="{
              'criteria-status-grey': customTestCriteriaFormArray?.at(i).get('cstmTCStatus').value === 'Not Tested' || customTestCriteriaFormArray?.at(i).get('cstmTCStatus').value === 'Not Applicable',
              'criteria-status-orange': customTestCriteriaFormArray?.at(i).get('cstmTCStatus').value === 'EC Discretion' || customTestCriteriaFormArray?.at(i).get('cstmTCStatus').value === 'Insufficient Data',
              'criteria-status-green': customTestCriteriaFormArray?.at(i).get('cstmTCStatus').value === 'Pass',
              'criteria-status-red': customTestCriteriaFormArray?.at(i).get('cstmTCStatus').value === 'Fail'
              }"
            >
              <mat-select formControlName="cstmTCStatus">
                <mat-option *ngFor="let option of statusOptions" [value]="option">{{option}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="none" class="test-criteria-input test-criteria-severity">
              <mat-select formControlName="cstmTCSvrty">
                <mat-option *ngFor="let option of severityOptions" [value]="option">{{option}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td class="notes">
            <quill-editor
              formControlName="cstmTCNotes"
              placeholder="">
            </quill-editor>
          </td>
          <button [disabled]="readOnly" class="cvs-icon-only-btn cstm-test-crit-delete-btn" (click)="deleteCustomTestCriteria(i)">
            <mat-icon svgIcon="trash--s" style="position: absolute"></mat-icon>
          </button>
        </tr>
      </ng-container>
    </table>

    <button id="add-custom-criteria-btn" class="cvs-btn-secondary" (click)="addCustomCriterion()"
            [disabled]="readOnly">
<!--      <mat-icon svgIcon="plus&#45;&#45;s"></mat-icon>-->
      Add Custom Criteria
    </button>
  </form>

  <!-- HINTS -->
  <ng-template #overallStatusHint>
    <div class="popover-content">
      <p>Fail - One or more criteria have failed to the extent that the file received is not sufficient to pass testing phase and move to production</p>
      <p>Insufficient Data - One or more testing criteria did NOT have sufficient data to fully pass testing, but client and EC have agreed that criteria has either been addressed or will be addressed and perform sufficiently in production</p>
      <p>Pass - All testing criteria and scenarios have been performed and validated as expected</p>
    </div>
  </ng-template>

  <ng-template #statusHint>
    <div class="popover-content">
      <p>Not Applicable - This particular criteria does not apply to this file and is not to be tested</p>
      <p>Not Tested - This particular criteria, while applicable to this client / file, cannot be tested due to limiting circumstances (provide details in comments field)</p>
      <p>EC Discretion - This criteria has been reviewed by the testing team and while it doesn't fail high level validations, it is up to the Eligibility Consultant to determine if it is sufficient to pass based on client specific expectations</p>
      <p>Insufficient Data - There was not sufficient data to test this criteria completely, but client and EC have agreed that criteria has either been addressed or will be addressed and perform sufficiently in production</p>
      <p>Pass - This particular criteria / scenario has been performed and validated as expected</p>
      <p>Fail - This criteria has failed and will need to be corrected to move into production</p>
    </div>
  </ng-template>

  <ng-template #severityHint>
    <div class="popover-content">
      <p>LOW - Results were not completely as expected. Variances may cause undesired behavior but file and records will still load</p>
      <p>MEDIUM - Results are likely to cause offending records to reject and possible file stoppage due to threshold exceptions. If thresholds are not exceeded, non-offending records will load successfully</p>
      <p>HIGH - Failures are likely to cause file to fail to load and possibly go into MSGW</p>
    </div>
  </ng-template>
</div>
