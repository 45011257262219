
<div class="" [ngStyle]="{'min-width': '35rem'}">
    <h2 mat-dialog-title style="padding-bottom: 1rem; border-bottom :2px solid lightgray">{{providedInfo.title}}</h2>

    <ng-container>
            <ng-container [formGroup]="form">

            <mat-dialog-content class="" style="min-height:7rem ;">

                <div class="row col-md-12">

                    <mat-form-field class="col-md-4">
                        <input matInput placeholder="Logic Code"  formControlName="logicCode">
                          <mat-error *ngIf="form.controls['logicCode'].errors && form.controls['logicCode'].errors.required">This field is required *</mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-md-4 ml-6 custom-select">
                        <mat-label>Map Type</mat-label>
                        <mat-select  formControlName="mapType" >
                            <mat-option *ngFor="let mapType of mapTypeList" [value]="mapType.message" >{{ mapType.message }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.controls['mapType'].errors && form.controls['mapType'].errors.required">This field is required *</mat-error>
                      </mat-form-field>

                </div>
                <div class="row">
                   
                    <mat-form-field class="col-md-4">
 
                        
                          <input matInput  type="text"
                           id="search-rxclaimfield" 
                           formControlName="rxClaimField"
                            placeholder="RxClaim Field"
                          
                          [matAutocomplete]="auto">
                          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <!-- <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                              {{option}}
                            </mat-option> -->
                            <mat-option *ngFor="let rxClaimField of filteredRxClaimList" [value]="rxClaimField" >{{rxClaimField}}</mat-option>
                          </mat-autocomplete>
                          <mat-error *ngIf="form.controls['rxClaimField'] && form.controls['rxClaimField'].errors && form.controls['rxClaimField'].errors.required">This field is required *</mat-error>
                      </mat-form-field>
         
                    <mat-form-field class="col-md-4 ml-6">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="logicCodeStatus" >
                          <mat-option *ngFor="let status of statusList" [value]="status.displayName" >{{status.displayName}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.controls['logicCodeStatus'].errors && form.controls['logicCodeStatus'].errors.required">This field is required *</mat-error>
                      </mat-form-field>

                </div>
                <div class="row col-md-12">

                  <mat-form-field class="col-md-4">
                      <input matInput placeholder="Field Tag"  formControlName="fieldTag">
                        <mat-error *ngIf="form.controls['fieldTag'].errors && form.controls['fieldTag'].errors.required">This field is required *</mat-error>
                  </mat-form-field>

                 

              </div>
                <mat-form-field class="col-md-12" appearance="outline">
                    <textarea matInput formControlName="mappingInstructions"  placeholder="Mapping Instructions" style="min-height: 19rem;"
                       ></textarea>
                       <mat-error *ngIf="form.controls['mappingInstructions'].errors && form.controls['mappingInstructions'].errors.required">This field is required *</mat-error>
                </mat-form-field>

                <mat-form-field class="col-md-12" appearance="outline">
                    <textarea matInput formControlName="mappingDescription"  placeholder="Mapping Descriptions" style="min-height: 10rem;"
                       ></textarea>
                       <mat-error *ngIf="form.controls['mappingDescription'].errors && form.controls['mappingDescription'].errors.required">This field is required *</mat-error>
                </mat-form-field>


            </mat-dialog-content>
            <mat-dialog-actions class="row" style="justify-content: end;">
                <button class="mat-raised-button col-4 save-button"  (click)="close()">Close</button>
                <button class="mat-raised-button mat-primary col-4 save-button" *ngIf="providedInfo.type !== 'edit_logic'" (click)="save()">Create</button>
                <button class="mat-raised-button mat-primary col-4 save-button" *ngIf="providedInfo.type === 'edit_logic'"(click)="save()">Update</button>
            </mat-dialog-actions>
        </ng-container>
        </ng-container>



    </div>
