<div class="mr">
<br>
<!--<mat-grid-list cols="5">
    <mat-grid-tile></mat-grid-tile>
    <mat-grid-tile></mat-grid-tile>
    <mat-grid-tile></mat-grid-tile>
    <mat-grid-tile></mat-grid-tile>
    <mat-grid-tile>
    </mat-grid-tile>
  </mat-grid-list>-->

          <ag-grid-angular
           (rowClicked)='onRowClicked($event)'
            #myprcgrid class="ag-theme-alpine epr-feed-gui-grid"
            [rowData]="data"
            domLayout="autoHeight"
            (filterChanged)="onFilterChange($event)">
          </ag-grid-angular>

</div>
