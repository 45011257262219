import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { CVSHttpClient, CVSAlertService } from 'angular-component-library';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class MappingApiService {
  baseURL = environment.baseCustomMappingUrl;
  ewfbaseUrl = environment.baseUrl;
  // baseURL = 'baseCustomMappingUrl';
  myPbmRoles: any;

  constructor(private http: CVSHttpClient,
              private privateHTTP: HttpClient,
              private alertService: CVSAlertService,  private usersService: UsersService) { 
                this.myPbmRoles = this.usersService.myPbmRoles

                
              }

  addNewMapping() {
    return this.http.get(`${this.baseURL}/document/getMappingKey`);
  }
  getMappingDocument(id) {
    return this.http.get(`${this.baseURL}/document/getAllMappingDocuments?eprId=${id?.epr}`);
    // uncomment for dynamic mapping doc.
    // return this.http.get(`${this.baseURL}/document/getAllMappingDocuments?clientCode=CNHI&pliCode=CNHA`);
  }
  getProjectDetailApi(id) {
    return this.http.get(`${this.baseURL}/guide/getProjectDetails?eprID=${id}`);
  }
  getRecTypesApi() {
    return this.http.get(`${this.baseURL}/guide/getRecTypes`);
  }
  getMapTypesApi() {
    return this.http.get(`${this.baseURL}/guide/getMapTypes`);
  }
  getRoleApi(role) {
    for(let [key, value] of Object.entries(this.myPbmRoles)){
      if(value === role){
        role = key
      }
    } 
    return this.http.get(`${this.baseURL}/guide/getUsersByRole?role=${role}`);
  }
  getVendorFormatTechCon(id) {
    return this.http.get(`${this.baseURL}/guide/getVendorFormatTechCon?eprID=${id}`);
  }
  getMappingGuide(param) {
    // eslint-disable-next-line max-len
    return this.http.get(`${this.baseURL}/guide/getMappingGuide?documentId=${param?.documentID}`);
  }
  getDates(id) {
    return this.http.get(`${this.baseURL}/document/getDates?eprID=${id}`);
  }
  getTester(ids) {
    return this.http.get(`${this.baseURL}/guide/getMapIteratorUser?eprId=${ids?.eprId}&iterationNumber=${ids?.iterationNumber}`);
  }
  getLeader(userID) {
    return this.http.get(`${this.baseURL}/guide/getLeader?userId=${userID}`);
  }

  saveMappingGuideApi(body) {

    return this.http.post(`${this.baseURL}/document/saveMappingDocument`, body);
  }

  editMappingGuideApi(body) {
    return this.http.put(`${this.baseURL}/document/editMappingDocument`, body);
  }

  getNewRequirementApi(id) {
    return this.http.get(`${this.baseURL}/requirements/getQuestDataForReq?eprID=${id}`);
  }

  saveMappingRequirementApi(body) {
    return this.http.post(`${this.baseURL}/requirements/saveMappingRequirements`, body);
  }
  editMappingRequirementApi(body) {
    return this.http.put(`${this.baseURL}/requirements/editMappingRequirements`, body);
  }
  getMappingRequirementApi(id) {
    return this.http.get(
  `${this.baseURL}/requirements/getMappingRequirement?documentId=${id?.documentID}`)
    .pipe(catchError((err) => {
      return of(err);
    }));
  }
  saveMappingFileRequirementApi(body) {
    return this.http.post(`${this.baseURL}/requirements/saveAllMappingFileSent`, body);
  }
  editMappingFileRequirementApi(body) {
    return this.http.put(`${this.baseURL}/requirements/editAllMappingFileSent`, body);
  }
  getMappingFileRequirementApi(param) {
    return this.http.get(
  `${this.baseURL}/requirements/getMappingFileSent?documentId=${param?.documentID}`)
    .pipe(catchError((err) => {
      return of(err);
    }));
  }
  getImportDocs(fileFormat) {
    return this.http.get(`${this.baseURL}/document/getAllMapDocs?mappingFormat=${fileFormat}`);
  }

  saveToJSON(saveToJSONObj) {
    return this.http.post(`${this.baseURL}/si/addEnvelope`, saveToJSONObj);
  }
  getDataManagementApi(id) {
    return this.http.get(`${this.ewfbaseUrl}/mapping/get?type=${id}`);
  }

  getDataManagementMapInstrTemplate(datasetname) {
    return this.http.get(`${this.ewfbaseUrl}/dataManagement/${datasetname}`);
  }
}
