<div [ngClass]="currentUserId ? 'dashboard-body-container' : ''">
    <div id="right-panel">
      <div class="dashboard-heading">
        <div style="display: flex; align-items: center; height: 36px">
          <h1 id="right-pane-header">Filetracker</h1>
          <mat-form-field id="filter-text-box" style="margin-left: 16px">
            <input matInput type="text" placeholder="Search" (input)="filterTables($event.target.value)" />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
        <div style="display: flex !important; ">
  
        <div id="dashboard-dropdown" *ngIf="(isRegisteredUser$ | async) && dashboardRole" class="dashboard-dropdownalign matformfieldAlign" style="margin-right: 30px;">
          <mat-form-field>
            <mat-select  [(value)]="selectedDashboardOption" (selectionChange)="dashboardOption()">
              <mat-option *ngFor="let viewOption of dashboardOptions" [value]="viewOption">
                <b>{{viewOption}}</b>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <button *ngIf="(isRegisteredUser$ | async) && isAdmin" id="createNewFiletracker" class="cvs-btn-primary"
            (click)="createNewCopay()">
            <mat-icon svgIcon="folder--s"></mat-icon>
            New Entry
          </button>
        </div>
        </div>
      </div>
      <div class="d-flex" >
        <h2 style="justify-content: flex-start;">OnBoarding</h2>
        <div style="justify-content: flex-end;">
          <span *ngIf="isAdmin" style="display:inline-flex" class=dashboard-hdr >
            <span >
              <span style="cursor:pointer;" (click)="refreshMyProcessingFiles('tableOne')">
              <mat-icon svgIcon="refresh--s" class="refresh-btn" id="tableOneRefreshProcessorBtn" >
          Refresh
         </mat-icon>
          <span class="export-txt"> Refresh </span>
        </span>
         <span *ngIf="currentDateGrid1 "><span class="dashboard-text">Last on </span>{{currentDateGrid1 | date:' h:mm:ss'}}</span>
        </span>
        <span style="cursor:pointer;" (click)="exportView('tableOne')">
    <mat-icon
      id="tableOneExportViewBtn"
       svgIcon="upload-arrow--s"   class="refresh-btn">
      Export View
    </mat-icon>
    <span class="export-txt"> Export </span>
  </span>
  </span>
  </div>
      </div>
      <div id="dashboard-table-one" class="dashboard-table">
        <ag-grid-angular
          class="ag-theme-alpine"
          #grid1
          (rowClicked)='onRowClicked($event)'
          (dragStopped)="onDragStopped('tableOne')"
          (sortChanged)="onSortChange('tableOne')"
          (filterChanged)="onFilterChange('tableOne')"
        >
        </ag-grid-angular>
      </div>
    </div>
  </div>
  