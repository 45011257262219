import { Injectable } from '@angular/core';
import { UntypedFormBuilder , UntypedFormControl , UntypedFormGroup , UntypedFormArray, Validators } from '@angular/forms';
import { CVSHttpClient } from 'angular-component-library';
import { Observable, Subject } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class FileLayoutService {
  tabClicked$: Subject<any> = new Subject();


  constructor(private fb: UntypedFormBuilder) {
  }

  updateTab() {
    this.tabClicked$.next(true);
  }
   createRecordGroup(records): UntypedFormGroup {

     function addRecGroup(data) {

       const recordGroup = new UntypedFormGroup({
         record_name: new UntypedFormControl(data.name, []),
         record_description: new UntypedFormControl(data.detail, [])
       });
       return recordGroup;
     }

     const recordArray  = this.fb.group({records: new UntypedFormArray([])});
     records.forEach(rec => {
      if (Object.keys(rec).length) {
        (recordArray.get('records') as UntypedFormArray).push(addRecGroup(rec)); // get a Form array named record and add multiple record controls.
      }
    });
     return recordArray;
  }
   addFileLayoutGroup(data) {

    const fileGroup = new UntypedFormGroup({
      check: new UntypedFormControl(false , [] ),
      fieldNumber: new UntypedFormControl({value: data.fieldNumber ,  disabled: false} , [Validators.required]),
      fieldName: new UntypedFormControl({value: data.fieldName ,  disabled: false} , [Validators.required]),
      position: new UntypedFormControl({value : data.position ,  disabled: false } , [Validators.required]),
      length: new UntypedFormControl({value : data.length , disabled: false } , [Validators.required]),
      use: new UntypedFormControl({value : data.use ,  disabled: false } , []),
      type: new UntypedFormControl({value : data.type , disabled: false }, []),
      fieldInformation: new UntypedFormControl({value : data.fieldInformation , disabled: false } , []),
      internalNotes: new UntypedFormControl({value : data.internalNotes , disabled: false }, []),
      rejectLevel: new UntypedFormControl({value : data.rejectLevel ,  disabled: false } , []),
      actionValues: new UntypedFormControl({value : data.actionValues , disabled: false }, []),
      fieldValidation: new UntypedFormControl({value : data.fieldValidation?.map(t=> t.id) ,  disabled: false } , []),
      fieldValues: new UntypedFormControl({value: data.fieldValues ,  disabled: false} , []),
      validationDefault: new UntypedFormControl({value : data?.validationDefault , disabled: false }, []),
      rejectActions: new UntypedFormControl({value : data.rejectActions?.map(t=> t.id) ,  disabled: false } , []),
      rejectActionsMessage: new UntypedFormControl({value : data.rejectActions ,  disabled: false } , []),
      rejectActionsDefault: new UntypedFormControl({value : data?.rejectActionsDefault , disabled: false }, []),
      rejectActionValues: new UntypedFormControl({value: data.rejectActionValues ,  disabled: false} , []),
      fieldActions: new UntypedFormControl({value : data.fieldActions?.map(t=> t.id) ,  disabled: false } , []),
      fieldActionsDefault: new UntypedFormControl({value : data?.fieldActionsDefault , disabled: false }, []),
      profile:  new UntypedFormControl({value : data.profile ,  disabled: false } , []),
      memberDetails: new UntypedFormControl({value : data.memberDetails ,  disabled: false } , []),
      auditHistory: new UntypedFormControl({value : 'See History' ,  disabled: false } , []),
      sendToSI: new UntypedFormControl({value : data.sendToSI ,  disabled: false } , []),

    });

    return fileGroup;
  }
 

   createFileLayoutGroup(fileLayoutData:any): UntypedFormGroup {

  const groupArray  = this.fb.group({filelayout: new UntypedFormArray([])});

  fileLayoutData.forEach(data => {
    if (Object.keys(data).length) {
      if(data.rejectActions && data.rejectActions.length){
      data.rejectActions?.forEach(t=>{
        if(t.isdefaultOption){
          data.rejectActionsDefault= t.id;
        }
      });
      }
      if(data.fieldValidation && data.fieldValidation.length){
        data.fieldValidation?.forEach(t=>{
          if(t.isdefaultOption){
            data.validationDefault= t.id;
          }
        });
      }
      if(data.fieldActions && data.fieldActions.length){
        data.fieldActions?.forEach(t=>{
          if(t.isdefaultOption){
            data.fieldActionsDefault= t.id;
          }
        });
      }
        if(data.msgCodes && data.msgCodes.length){
          data.msgCodes?.forEach(t=>{
            if(t.default){
              data.msgCodesDefault= t.id;
            }
          });
      }
      (groupArray.get('filelayout') as UntypedFormArray).push(this.addFileLayoutGroup(data));
      // get a Form array named record and add multiple record controls.

    }
  });
  return groupArray;
   }

   createRecordSearch() {
    return new UntypedFormGroup({recordSearch: new UntypedFormControl()});
   }
   generateImportSearch() {
    const form = this.fb.group({
      search: new UntypedFormControl()
    });
    return form;
  }
}
