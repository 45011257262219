import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import * as webeprfeeds from '../actions/web-eprfeed.action';
import {CVSAlertService, CVSAlertType} from 'angular-component-library';
import {AuthService} from '../services/auth.service';
import {WebEprFeed} from '../models/WebEprFeed';
import {WebEprFeedService} from '../services/web-epr-feed.service';
import {NoopAction} from '../actions/noop.action';

@Injectable()
export class WebEprfeedEffects {
    loadWebEprFeed$ = createEffect(() => this.actions$.pipe(
    ofType(webeprfeeds.LOAD_WEBEPRFEED),
    mergeMap(() => this.webEprFeedService.getAll()
      .pipe(
        map(webEprFeedValue => ({ type: webeprfeeds.LOAD_WEBEPRFEED_SUCCESS, payload: webEprFeedValue as WebEprFeed[] })),
        catchError(() => of({type: webeprfeeds.LOAD_WEBEPRFEED_FAILURE}))
      ))
    )
  );


  saveWebEprFeed$ = createEffect(() => this.actions$.pipe(
    ofType(webeprfeeds.SAVE_WEBEPRFEED),
    mergeMap(action => this.webEprFeedService.saveWebEprFeed(action['payload'])
      .pipe(
        map(webEprFeedValue => {
          this.alertService.sendAlert({
            headline: 'Save successful!',
            alertType: CVSAlertType.Success,
            removedAfterMilliseconds: 5000,
            alertDocumentHolderName: '#settings-alert',
          });
          return ({ type: webeprfeeds.SAVE_WEBEPRFEED_SUCCESS, payload: webEprFeedValue as WebEprFeed });
        }),
        catchError(() => of({type: webeprfeeds.SAVE_WEBEPRFEED_FAILURE}))
      ))
    )
  );

  editWebEprFeed$ = createEffect(() => this.actions$.pipe(
    ofType(webeprfeeds.EDIT_WEBEPRFEED),
    mergeMap(action => this.webEprFeedService.editWebEprFeed(action['payload'])
      .pipe(
        map(webEprFeedValue => ({ type: webeprfeeds.EDIT_WEBEPRFEED_SUCCESS, payload: webEprFeedValue as WebEprFeed })),
        catchError(() => of({type: webeprfeeds.EDIT_WEBEPRFEED_FAILURE}))
      ))
    )
  );

  constructor(
    private actions$: Actions,
    private webEprFeedService: WebEprFeedService,
    private alertService: CVSAlertService,
    private authService: AuthService,
  ) { }
}
