import {Injectable} from '@angular/core';
import { Observable, of } from 'rxjs';
import {environment} from '../../environments/environment';
import { CVSHttpClient, CVSAlertService, CVSAlertType } from 'angular-component-library';
import {ClassificationCode} from '../models/classificationCode';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ClassificationCodeService {

  constructor(private http: CVSHttpClient, private alertService: CVSAlertService, private httpgen: HttpClient) { }

  classificationCode: ClassificationCode;
  


  baseURL = environment.baseCustomMappingUrl;

  getclassificationdata(): Observable<any> {
    return this.http.get(`${environment.baseCustomMappingUrl}/classificationcode/all`);
  }
  updateclassificationCodeApi(modifiedRows) {
    const url = `${this.baseURL}/classificationcode/updateClassificationCode`;
    return  this.http.put( url, modifiedRows)
    .pipe(catchError((err) => {
      this.showMessage(err.error.message);
      return of(null);
    })
  );
  }

  addclassification(newData) {
    const url = `${this.baseURL}/classificationcode/addClassificationCode`;
    return  this.http.post( url, newData)
    .pipe(catchError((err) => {
      this.showMessage(err.error.message);
      return of(null);
    })
  );
  }

  deleteClassificationCode(id:any) {
    const url = `${this.baseURL}/classificationcode/deleteClassificationCode`;
    return  this.http.put( url, id)
    .pipe(catchError((err) => {
      this.showMessage(err.error.message);
      return of(null);
    })
  );
  }


  showMessage(body) {
     this.alertService.sendAlert({
      headline: body,
      alertType: CVSAlertType.Error,
      removedAfterMilliseconds: 3000,
      alertDocumentHolderName: '#classification-code',
    });

  }
}

