<cvs-alert-placeholder id="logic-code"></cvs-alert-placeholder>
<div id="record">




    <div class=" mat-elevation-z8" [ngStyle]="{'width':'100%'}">

     
      <div class="row" style="padding: 8px 0 3px 0; justify-content: space-between; ">
        <div class="search-add-container">
          <span>
            <mat-form-field appearance="standard">
              <input matInput placeholder="Search" id = "search"[formControl]="searchForm.controls.search" >
              <mat-icon svgIcon="search--s"></mat-icon>
            </mat-form-field>
          </span>
       
      </div>
      <div style="display: flex;">
        <button class="cvs-btn-text crud-button"  (click)="resetFilters()">
          <mat-icon svgIcon="filter--s" style="cursor:pointer; color: #767676;" id="logicCodeCF">
            Clear Filters
          </mat-icon>
          <span class="clearFilter-txt" style="color: #767676;"> Clear Filters </span>
        </button>
          
        <button id="register-user-btn" class="cvs-btn-text crud-button"  (click)="addLogic()">
          <mat-icon svgIcon="plus--s"></mat-icon>
          Add
        </button>
        <button class="cvs-btn-text crud-button" style="/* padding: 8px 20px; */"  (click)="editLogic()">
          <mat-icon svgIcon="pencil--s"></mat-icon>
          <span>Edit</span>
        </button>
        <button class="cvs-btn-text crud-button" [disabled]="!isRowSelected" (click)="deleteLogicCode()">
          <mat-icon svgIcon="trash--s"></mat-icon>
          <span>Delete</span>
        </button>
      </div>
      </div>
      <cvs-loader-overlay-container [isLoading]="showSpinner | async" loadingMessage="Loading">
      <form [formGroup]="form" autocomplete="off" style="overflow: auto;"
        *ngIf="(logicArray) else noTable">
        <ng-container formArrayName="logicArray">
          <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" style=" max-height: 82vh;">
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row;  let i = dataIndex ; columns: displayedColumns;"></mat-row>

            <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

            <!-- Id Column -->
            <ng-container matColumnDef="check">
              <mat-header-cell  *matHeaderCellDef> </mat-header-cell>
              <mat-cell *matCellDef="let element" [formGroup]="element" >
                <mat-checkbox formControlName="check"
                (change)="onCheckBoxSelection($event,logicArray.controls.indexOf(element))" 
                ></mat-checkbox>
              </mat-cell>
            </ng-container>
            <!-- User Column -->
            <ng-container matColumnDef="logicCode" >
              <mat-header-cell  *matHeaderCellDef class="border-side"> 
                
                  <div mat-sort-header="logicCode" style="white-space: nowrap;">Logic Codes</div>
                    <mat-icon svgIcon="{{filedFilterlogicCode}}" style="cursor:pointer"  [matMenuTriggerFor]="menu"></mat-icon>
                    <!-- <mat-icon svgIcon="filter-f--s" style="cursor:pointer" *ngIf="filedFilter.logicCode" [matMenuTriggerFor]="menu"></mat-icon> -->
                 </mat-header-cell>

              <mat-cell *matCellDef="let element" [formGroup]="element">
                <mat-form-field *ngIf="!element.get('logicCode').disabled" floatLabel="never" appearance="none" class="col-internal-6">
                  <input  matInput formControlName="logicCode" />
                </mat-form-field>
                <div class="div-width" *ngIf="element.get('logicCode').disabled">
                  {{element.get('logicCode').value}}
                </div>
              </mat-cell>
              <mat-menu #menu>
                <div mat-menu-item appFilterItem [disableRipple]="true">
                  <mat-form-field>
                    <mat-select [panelClass]="'mat-elevation-z10'" placeholder='Conditions' (selectionChange)="conditionOption($event,'logicCode')">
                      <mat-option *ngFor="let  condition of conditionsList" [value]="condition.value">{{condition.label}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
      
                <div mat-menu-item appFilterItem [disableRipple]="true">
                  <mat-form-field>
                    <input matInput [formControl]="filterForm.controls.logicCodeFilter" placeholder="Value">
                  </mat-form-field>
                </div>
              </mat-menu>
            </ng-container>
            <ng-container matColumnDef="mapType" class="border-side">
              <mat-header-cell  *matHeaderCellDef >
                <div mat-sort-header="mapType" style="white-space: nowrap;"> Map Type </div>
                <mat-icon svgIcon="{{filedFiltermapType}}" style="cursor:pointer"  [matMenuTriggerFor]="menu"></mat-icon>
                
                <mat-menu #menu>
                  <div mat-menu-item appFilterItem [disableRipple]="true">
                    <mat-form-field>
                      <mat-select [panelClass]="'mat-elevation-z10'" placeholder='Conditions'  (selectionChange)="conditionOption($event,'mapType')">
                        <mat-option *ngFor="let  condition of conditionsList" [value]="condition.value">{{condition.label}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
        
                  <div mat-menu-item appFilterItem [disableRipple]="true">
                    <mat-form-field>
                      <input matInput [formControl]="filterForm.controls.mapTypeFilter" placeholder="Value">
                    </mat-form-field>
                  </div>
                </mat-menu>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" [formGroup]="element">
                <mat-form-field  *ngIf="!element.get('mapType').disabled" floatLabel="never" appearance="none" class="col-internal-6">
                  <input matInput formControlName="mapType" />
                </mat-form-field>
                <div class="div-width" *ngIf="element.get('mapType').disabled">
                  {{element.get('mapType').value}}
                </div>
              </mat-cell>
             
            </ng-container>

            <ng-container matColumnDef="rxClaimField" class="border-side">
              <mat-header-cell  *matHeaderCellDef >
                <div mat-sort-header="rxClaimField" style="white-space: nowrap;">RxClaim Field</div>
                <mat-icon svgIcon="{{filedFilterrxClaimField}}" style="cursor:pointer" [matMenuTriggerFor]="menu"></mat-icon>
                <mat-menu #menu>
                  <div mat-menu-item appFilterItem [disableRipple]="true">
                    <mat-form-field>
                      <mat-select [panelClass]="'mat-elevation-z10'" placeholder='Conditions'   (selectionChange)="conditionOption($event,'rxClaimField')">
                        <mat-option *ngFor="let  condition of conditionsList" [value]="condition.value">{{condition.label}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div mat-menu-item appFilterItem [disableRipple]="true">
                    <mat-form-field>
                      <input matInput [formControl]="filterForm.controls.rxClaimFieldFilter" placeholder="Value">
                    </mat-form-field>
                  </div>
                </mat-menu> </mat-header-cell>
              <mat-cell *matCellDef="let element" [formGroup]="element">
                <mat-form-field *ngIf="!element.get('rxClaimField').disabled" floatLabel="never" appearance="none" class="col-internal-4">
                  <input matInput formControlName="rxClaimField" />
                </mat-form-field>
                <div class="div-width" *ngIf="element.get('rxClaimField').disabled">
                  {{element.get('rxClaimField').value}}
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mappingInstructions" class="border-side">
              <mat-header-cell  *matHeaderCellDef >
                <div mat-sort-header="mappingInstructions" style="white-space: nowrap;">Mapping Instructions</div>
                <mat-icon svgIcon="{{filedFiltermappingInstructions}}" style="cursor:pointer"  [matMenuTriggerFor]="menu"></mat-icon>
                
                <mat-menu #menu>
                  <div mat-menu-item appFilterItem [disableRipple]="true">
                    <mat-form-field>
                      <mat-select [panelClass]="'mat-elevation-z10'" placeholder='Conditions'  (selectionChange)="conditionOption($event,'mappingInstructions')">
                        <mat-option *ngFor="let  condition of conditionsList" [value]="condition.value">{{condition.label}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div mat-menu-item appFilterItem [disableRipple]="true">
                    <mat-form-field>
                      <input matInput [formControl]="filterForm.controls.mapInstructionsFilter" placeholder="Value">
                    </mat-form-field>
                  </div>
                </mat-menu> </mat-header-cell>
              <mat-cell *matCellDef="let element" [formGroup]="element">
                <!-- <mat-form-field floatLabel="never" appearance="none" class="col-internal-20">

                  <textarea matInput style="" rows="2" class="text-areas"
                formControlName="mappingInstructions"></textarea>
                </mat-form-field> -->
                <div class="div-width-lg" *ngIf="element.get('mappingInstructions').disabled">
                  {{element.get('mappingInstructions').value}}
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mappingDescription" class="border-side">
              <mat-header-cell  *matHeaderCellDef >
                <div mat-sort-header="mappingDescription" style="white-space: nowrap;">Mapping Descriptions</div>
                <mat-icon svgIcon="{{filedFiltermappingDescription}}" style="cursor:pointer" [matMenuTriggerFor]="menu"></mat-icon>
                
                <mat-menu #menu>
                  <div mat-menu-item appFilterItem [disableRipple]="true">
                    <mat-form-field>
                      <mat-select [panelClass]="'mat-elevation-z10'" placeholder='Conditions'  (selectionChange)="conditionOption($event,'mappingDescription')">
                        <mat-option *ngFor="let  condition of conditionsList" [value]="condition.value">{{condition.label}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div mat-menu-item appFilterItem [disableRipple]="true">
                    <mat-form-field>
                      <input matInput [formControl]="filterForm.controls.mapDescriptionFilter" placeholder="Value">
                    </mat-form-field>
                  </div>
                </mat-menu> </mat-header-cell>
              <mat-cell *matCellDef="let element" [formGroup]="element">
                <!-- <mat-form-field floatLabel="never" appearance="none" class="col-internal-20">

                  <textarea matInput style="" rows="2" class="text-areas"
                formControlName="mappingDescription"></textarea>
                </mat-form-field> -->
                 <div class="div-width-lg" *ngIf="element.get('mappingDescription').disabled">
                  {{element.get('mappingDescription').value}}
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="logicCodeStatus" class="border-side">
              <mat-header-cell *matHeaderCellDef> 
                <div mat-sort-header="logicCodeStatus" style="white-space: nowrap;">Status</div>
                <mat-icon svgIcon="{{filedFilterlogicCodeStatus}}" style="cursor:pointer"  [matMenuTriggerFor]="menu"></mat-icon>
                
                <mat-menu #menu>
                  <div mat-menu-item appFilterItem [disableRipple]="true">
                    <mat-form-field>
                      <mat-select [panelClass]="'mat-elevation-z10'" placeholder='Conditions'  (selectionChange)="conditionOption($event,'logicCodeStatus')">
                        <mat-option *ngFor="let  condition of conditionsList" [value]="condition.value">{{condition.label}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div mat-menu-item appFilterItem [disableRipple]="true">
                    <mat-form-field>
                      <input matInput [formControl]="filterForm.controls.statusFilter" placeholder="Value">
                    </mat-form-field>
                  </div>
                </mat-menu> </mat-header-cell>
              <mat-cell *matCellDef="let element" [formGroup]="element">
                <mat-form-field *ngIf="!element.get('logicCodeStatus').disabled" floatLabel="never" appearance="none" class="col-internal-20">
                  <input matInput formControlName="logicCodeStatus" />
                </mat-form-field>
                <div *ngIf="element.get('logicCodeStatus').disabled">
                  {{element.get('logicCodeStatus').value}}
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="fieldTag">
              <mat-header-cell  *matHeaderCellDef class="border-side">
                <div mat-sort-header="fieldTag" style="white-space: nowrap;"> Field Tag</div>
                <mat-icon svgIcon="{{filedFilterfieldTag}}" style="cursor:pointer"  [matMenuTriggerFor]="menu"></mat-icon>
                
                <mat-menu #menu>
                  <div mat-menu-item appFilterItem [disableRipple]="true">
                    <mat-form-field>
                      <mat-select [panelClass]="'mat-elevation-z10'" placeholder='Conditions'  (selectionChange)="conditionOption($event,'fieldTag')">
                        <mat-option *ngFor="let  condition of conditionsList" [value]="condition.value">{{condition.label}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div mat-menu-item appFilterItem [disableRipple]="true">
                    <mat-form-field>
                      <input matInput [formControl]="filterForm.controls.fieldTagFilter" placeholder="Value">
                    </mat-form-field>
                  </div>
                </mat-menu> </mat-header-cell>
              <mat-cell *matCellDef="let element" [formGroup]="element">
                <!-- <mat-form-field floatLabel="never" appearance="none" class="col-internal-20">

                  <textarea matInput style="" rows="2" class="text-areas"
                formControlName="mappingDescription"></textarea>
                </mat-form-field> -->
                 <div class="div-width-lg" *ngIf="element.get('fieldTag').disabled">
                  {{element.get('fieldTag').value}}
                </div>
              </mat-cell>
            </ng-container>
          </mat-table>
        
        </ng-container>
      </form>
      <mat-paginator #paginator
      [pageSize]="100"
      [pageSizeOptions]="[100, 200, 300]" 
      >
      </mat-paginator>
      
      <ng-template #noTable>
        <p>No Records found for this particular record. </p>
      </ng-template>
    </cvs-loader-overlay-container>
    </div>

  </div>
