import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
@Injectable()
export class EnvironmentService {
    constructor(private http: HttpClient) { }
    applyOverrides(): Promise<any> {
        return this.http.get('/assets/config/environment.json')
            .toPromise()
            .then(json => {
                const environmentFromJson = (!!json['default']) ? json['default'] : json;
                Object.keys(environmentFromJson).forEach((key) => {
                    environment[key] = environmentFromJson[key];
                });
            });
    }
}
