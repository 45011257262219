import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LogicCodeService } from './logic-code.service';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AddLogicComponent } from './add-logic/add-logic.component';
import { LogicCodeApiService } from 'src/app/services/logic-code-api.service';
import { CVSAlertService , CVSAlertType} from 'angular-component-library';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import {Sort, MatSortModule} from '@angular/material/sort';

export const CONDITIONS_LIST = [
  { value: "contains", label: "Contains" },
  { value: "not-contains", label: "Not Contains" },
  { value: "equals", label: "Equals" },
  { value: "not-equals", label: "Not Equals" },
  { value: "startWith", label: "Starts With" },
  { value: "endWith", label: "Ends With" },
];

@Component({
  selector: 'app-logic-code',
  templateUrl: './logic-code.component.html',
  styleUrls: ['./logic-code.component.scss']
})


export class LogicCodeComponent implements OnInit , OnDestroy {
  form: UntypedFormGroup;
  public conditionsList = CONDITIONS_LIST;
  public searchCondition: any = {};
  displayedColumns = ['check', 'logicCode', 'mapType', 'rxClaimField', 'mappingInstructions', 'mappingDescription', 
  'logicCodeStatus', 'fieldTag'];

  dialogConfig = new MatDialogConfig();
  currentCheckboxIndex: any = null;
  rxClaimFieldsList: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>();
  subs: Subscription[] = [];
  completeResponse: any;
  searchForm: UntypedFormGroup;
  filterForm: UntypedFormGroup;
  showSpinner: Subject<boolean> = new Subject();
  isRowSelected: boolean=false;
  selectedRecordData: any;
  deleteBody = [];
  deleteRowIndex: any; 
  public filterValue: any = {};
  filtereddata = [];
  selectedCondition:any = {
    "logicCode": "contains",
    "mapType": "contains",
    "rxClaimField": "contains",
    "mappingInstructions": "contains",
    "mappingDescription": "contains",
    "logicCodeStatus": "contains",
    "fieldTag": "contains"
}

  filedFilterlogicCode:any= 'filter--s';
  filedFiltermapType:any= 'filter--s';
  filedFilterrxClaimField:any= 'filter--s';
  filedFiltermappingInstructions:any= 'filter--s';
  filedFiltermappingDescription:any= 'filter--s';
  filedFilterlogicCodeStatus:any= 'filter--s';
  filedFilterfieldTag:any= 'filter--s';
  colConditon: string = '';
  filteredLength: number = 0;
  globalFilter:any = ''

  constructor(private logicService: LogicCodeService,
              private dialog: MatDialog,
              private api: LogicCodeApiService,
              private alertService: CVSAlertService,
              private cdRef: ChangeDetectorRef,
    ) { }

  ngOnInit() {
    this.getRxClaimFields();
    this.loadData();
    this.updateField();
  }

  updateField() {
    this.logicService.updateLogicCode$.subscribe(resp => {
      if (resp) {
        this.getRxClaimFields();
      }
    });
  }
  getRxClaimFields() {
    return this.api.getRxclaimFieldsApi().subscribe(data => {
      if (data) {
        this.rxClaimFieldsList = data;
      }
    });
  }
  loadData() {
    this.api.getLogicCodeApi().subscribe(resp => {
      this.completeResponse = resp;
      this.createForm(resp);
    });
    this.addSearch();
    this.createFilterForm();
    this.filterValueChanges()
  }
  createForm(resp: any) {
    this.form =  this.logicService.createLogicCodeGroup(resp);
    this.dataSource.data = this.logicArray.controls;
    this.dataSource.paginator = this.paginator;
  }

 

  get logicArray(): UntypedFormArray {
    if (this.form) {
      return this.form.get('logicArray') as UntypedFormArray;
    } else {
      return null;
    }
  }

  setDialogConfig(id) {
    switch (id) {
      case 0:
        this.dialogConfig.data = {
          id: 1,
          title: 'Add Logic Code',
          type: 'add_logic'

        };
        break;
      case 1:
        this.dialogConfig.data = {
          id: 1,
          title: 'Edit Logic Code',
          type: 'edit_logic'
        };
        break;
      }
    this.dialogConfig.data.rxClaimFieldsList = this.rxClaimFieldsList;
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    }

    addLogic() {
      this.setDialogConfig(0);
      const dialogData = this.dialog.open(AddLogicComponent, this.dialogConfig);
      dialogData.afterClosed().subscribe(input => {
        if (input != null) {
          this.showSpinner.next(true);
          this.api.addLogicCodeApi(input).subscribe((resp) => {
            if (resp.status === 'SUCCESS') {
              this.showMessage({message: 'Logic code added !', type: CVSAlertType.Success});
              this.loadData();
              this.resetFilters();
            } else {
              this.showMessage({message: resp.errorMessage, type: CVSAlertType.Error});
            }
            this.showSpinner.next(false);
          });
        }
      });
    }

    onCheckBoxSelection(event, index) {
      if(event.checked === (true || 'true') ){
        this.isRowSelected = true;
        this.deleteRowIndex = index
      }else{
        this.isRowSelected = false;
        this.deleteBody = []
      }

      const logicFormArray: UntypedFormArray = this.logicArray;
      this.currentCheckboxIndex = index;

        // checked turn previous checked false .
      (logicFormArray.controls as Array<any>).forEach((group, groupIndex) => {
          if (group.value.check && index !== groupIndex) {
            group.patchValue({ check: false });
            // index != groupIndex =>
          } else if (group.value.check && event.checked) {
            this.currentCheckboxIndex = index;
          }
        });
    }

    editLogic() {
      const editGroup = (this.logicArray.controls as Array<any>).find((group) => {
        if (group.get('check').value) { return group; }
      });
      if (editGroup) {

      this.setDialogConfig(1);
      const rowData = editGroup.getRawValue();
      this.dialogConfig.data.editData = rowData;
      const dialogData = this.dialog.open(AddLogicComponent, this.dialogConfig);
      dialogData.afterClosed().subscribe(input => {
        if (input != null) {
         this.api.updateLogicCodeApi(input, rowData).subscribe((resp) => {
        if (resp.status === 'SUCCESS') {
          this.showMessage({message: 'Logic code updated !', type: CVSAlertType.Success});
          this.loadData();
          this.resetFilters();
        } else {
          this.showMessage({message: resp.errorMessage, type: CVSAlertType.Error});
        }

         });
        }

      });

    } else {
      window.alert('Please select the row for edit first !');
    }
    }
    showMessage(request) {
      this.alertService.sendAlert({
        headline: request ? request.message : 'Save successful!',
        alertType: request ? request.type : CVSAlertType.Success,
        removedAfterMilliseconds: 3000,
        alertDocumentHolderName: '#logic-code',
      });
    }
    addSearch() {
      this.searchForm =  this.logicService.generateImportSearch();
      this.subs.push(
        this.searchForm.get('search').valueChanges.pipe(
          // debounceTime(280),
           distinctUntilChanged(),
          switchMap(resp => this.filterData(resp)) ).subscribe()
      );
     }

     createFilterForm(){
      this.filterForm = this.logicService.generateFilterForm();
    }

    filterValueChanges(){

      this.filterForm.get('logicCodeFilter').valueChanges.subscribe(res=>{
        if(res !== null){
          this.colConditon = 'logicCode'
        let val  = res.trim(); // Remove whitespace
        val = val?.toLowerCase();
        if(val !== ''){
          this.filterValue['logicCode'] = val;
        } else if(val === ''){
          delete this.filterValue.logicCode
          this.filedFilterlogicCode = 'filter--s'
        }
        this.applyFilter(this.filterValue)
        }
        
      })
      this.filterForm.get('mapTypeFilter').valueChanges.subscribe(res=>{
        if(res!== null){
          this.colConditon = 'mapType'
          let val  = res.trim(); // Remove whitespace
          val = val?.toLowerCase();
          if(val !== ''){
            this.filterValue['mapType'] = val;
          } else if(val === ''){
            delete this.filterValue.mapType
            this.filedFiltermapType = 'filter--s'
          }
          this.applyFilter(this.filterValue)
        }
       
      })
      this.filterForm.get('rxClaimFieldFilter').valueChanges.subscribe(res=>{
        if(res!==null){
          this.colConditon = 'rxClaimField'
        let val  = res.trim(); // Remove whitespace
        val = val?.toLowerCase();
        if(val !== ''){
          this.filterValue['rxClaimField'] = val;
        } else if(val === ''){
          delete this.filterValue.rxClaimField
          this.filedFilterrxClaimField = 'filter--s'
        }
        this.applyFilter(this.filterValue)
        }
      })
      this.filterForm.get('mapInstructionsFilter').valueChanges.subscribe(res=>{
        if(res!==null){
          this.colConditon = 'mappingInstructions'
        let val  = res.trim(); // Remove whitespace
        val = val?.toLowerCase();
        if(val !== ''){
          this.filterValue['mappingInstructions'] = val;
        } else if(val === ''){
          delete this.filterValue.mappingInstructions
          this.filedFiltermappingInstructions = 'filter--s'
        }
        this.applyFilter(this.filterValue)
        }
      })
      this.filterForm.get('mapDescriptionFilter').valueChanges.subscribe(res=>{
        if(res!== null){
          this.colConditon = 'mappingDescription'
        let val  = res.trim(); // Remove whitespace
        val = val?.toLowerCase();
        if(val !== ''){
          this.filterValue['mappingDescription'] = val;
        } else if(val === ''){
          delete this.filterValue.mappingDescription
          this.filedFiltermappingDescription = 'filter--s'
        }
        this.applyFilter(this.filterValue)
        }
      })
      this.filterForm.get('statusFilter').valueChanges.subscribe(res=>{
        if(res!==null){
          this.colConditon ='logicCodeStatus'
        let val  = res.trim(); // Remove whitespace
        val = val?.toLowerCase();
        if(val !== ''){
          this.filterValue['logicCodeStatus'] = val;
        } else if(val === ''){
          delete this.filterValue.logicCodeStatus
          this.filedFilterlogicCodeStatus = 'filter--s'
        }
        this.applyFilter(this.filterValue)
        }
      })
      this.filterForm.get('fieldTagFilter').valueChanges.subscribe(res=>{
        if(res!==null){
          this.colConditon = 'fieldTag'
        let val  = res.trim(); // Remove whitespace
        val = val?.toLowerCase();
        if(val !== ''){
          this.filterValue['fieldTag'] = val;
        } else if(val === ''){
          delete this.filterValue.fieldTag
          this.filedFilterfieldTag = 'filter--s'
        } 
        this.applyFilter(this.filterValue)
        }
      })
     
    }
    applyFilter(filter){
      
      let data = this.completeResponse;
      this.showSpinner.next(true);
      function filterMatchingObjects(obj: any, filterVal: any,filterKey:any,
        selectedCondition:any): any {
        const filteredArray = [];
        obj.forEach(data => {
          const keys  = Object.keys(data);
          for (const key of keys) {
            if(filterKey === key  && selectedCondition[key] === 'contains'){
              if (data[key] && (data[key]?.toLowerCase().includes(filterVal?.trim().toLowerCase()))) {
                filteredArray.push(data);
                return;
              }
            }else if(filterKey === key  && selectedCondition[key] === 'not-contains'){
              if (data[key] && (!data[key]?.toLowerCase().includes(filterVal?.trim().toLowerCase()))) {
                filteredArray.push(data);
                return;
              }
            }else if(filterKey === key &&  selectedCondition[key] === 'equals'){
              if (data[key] && (data[key]?.toLowerCase() === filterVal?.trim().toLowerCase())) {
                filteredArray.push(data);
                return;
              }
            }
            else if(filterKey === key  && selectedCondition[key] === 'not-equals'){
              if (data[key] && (data[key]?.toLowerCase() !== filterVal?.trim().toLowerCase())) {
                filteredArray.push(data);
                return;
              }
            }
            else if(filterKey === key  && selectedCondition[key] === 'startWith'){
              if (data[key] && (data[key]?.toLowerCase().startsWith(filterVal))) {
                filteredArray.push(data);
                return;
              }
            }
            else if(filterKey === key &&  selectedCondition[key] === 'endWith'){
              if (data[key] && (data[key]?.toLowerCase().endsWith(filterVal))) {
                filteredArray.push(data);
                return;
              }
            }
          }
          });
        return filteredArray
     }

     
      if (Object.keys(filter).length < 1) {
       this.createForm(this.completeResponse)
       this.filtereddata = []
      } else {
        const filterEntries = Object.entries(filter)
        if(this.filteredLength <= filterEntries.length){
          this.filtereddata = this.filtereddata
        }
        else if(this.filteredLength > filterEntries.length ){
          this.filtereddata = data
        }
        if(filterEntries.length > 1){
          let count = 0
           const filterVal = Object.values(filter)
          const filterKey = Object.keys(filter)
          while (count < filterEntries.length){
            let filterdData = this.filtereddata
            this.filtereddata =   filterMatchingObjects(filterdData,filterVal[count],filterKey[count],this.selectedCondition)
            count++
          }
        }else if(filterEntries.length === 1){
          const filterVal = Object.values(filter)
          const filterKey = Object.keys(filter)
          this.filtereddata = filterMatchingObjects(data,filterVal[0],filterKey[0],this.selectedCondition)
        }
        this.filteredLength = filterEntries.length
        this.createForm(this.filtereddata);
      }
      setTimeout(() => {
        this.showSpinner.next(false);
  
      }, 500);
      Object.keys(filter).forEach(filer=>{
        if(filer === 'logicCode'){
          this.filedFilterlogicCode='filter-f--s';
        }else if(filer === 'mapType'){this.filedFiltermapType='filter-f--s';}
        else if(filer === 'rxClaimField'){this.filedFilterrxClaimField= 'filter-f--s';}
        else if(filer === 'mappingInstructions'){this.filedFiltermappingInstructions= 'filter-f--s';}
        else if(filer === 'mappingDescription'){this.filedFiltermappingDescription= 'filter-f--s';}
        else if(filer === 'logicCodeStatus'){this.filedFilterlogicCodeStatus= 'filter-f--s';}
        else if(filer === 'fieldTag'){this.filedFilterfieldTag='filter-f--s';}
        this.cdRef.detectChanges()
      })    
      return of(null);
    }
      filterData(resp): Observable<any> {
        this.showSpinner.next(true);
        if (!resp) {
         this.createForm(this.completeResponse);
        } else {
          const filteredArray = [];
          this.completeResponse.forEach(data => {
            const keys  = Object.keys(data);
            for (const key of keys) {
             if (data[key]  && (data[key]?.toLowerCase().includes(resp?.trim().toLowerCase()))) {
              filteredArray.push(data);
              return;
            }
            }
            });
            this.filtereddata = filteredArray;
            this.globalFilter = resp
          this.createForm(filteredArray);
        }
        setTimeout(() => {
          this.showSpinner.next(false);
    
        }, 500);
        return of(null);
      }

      sortData(sort: Sort) {
        let data:any = []
        if(this.filtereddata.length > 0){
           data = this.filtereddata
        }else{
           data = this.completeResponse
        }
        
        if (!sort.active || sort.direction === '') {
          this.createForm(data);
          return;
        }
        
        const sortedData = data.sort((a, b) => {
          const isAsc = sort.direction === 'asc';
          switch (sort.active) {
            case 'logicCode':
              return compare(a.logicCode, b.logicCode, isAsc);
            case 'mapType':
              return compare(a.mapType, b.mapType, isAsc);
            case 'rxClaimField':
              return compare(a.rxClaimField, b.rxClaimField, isAsc);
            case 'mappingInstructions':
              return compare(a.mappingInstructions, b.mappingInstructions, isAsc);
            case 'mappingDescription':
              return compare(a.mappingDescription, b.mappingDescription, isAsc);
              case 'logicCodeStatus':
                return compare(a.logicCodeStatus, b.logicCodeStatus, isAsc);
              case 'fieldTag':
                return compare(a.fieldTag, b.fieldTag, isAsc);
            default:
              return 0;
          }
        });
        this.createForm(sortedData);
        function compare(a: number | string, b: number | string, isAsc: boolean) {
          return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
        }
      }

      deleteLogicCode(){
        this.showSpinner.next(true);
        let data:any = []
        if(this.filtereddata.length > 0){
          data = this.filtereddata
       }else{
          data = this.completeResponse
       }
        this.deleteBody.push(data[this.deleteRowIndex].logicCode)

      this.api.deleteLogicCodeApi(this.deleteBody).subscribe((resp1: any) => {
        if (resp1.status === "SUCCESS") {
          data.splice(this.deleteRowIndex,1)
        this.api.getLogicCodeApi().subscribe(resp => {
          this.completeResponse = resp;
          if(this.globalFilter !== ''){
            this.filterData(this.globalFilter)
            this.showSpinner.next(false);
          }
        });
        }
        setTimeout(() => {
          this.createForm(data)
          this.showMessage({message: 'Logic Code(s) deleted !', type: CVSAlertType.Success});
          this.deleteBody = []
          this.isRowSelected = false
          this.showSpinner.next(false);
    
        }, 1000);
      }, err => {
        console.error(err);
      });
    }
     

    conditionOption(event,col){
        this.selectedCondition[col] = event.value
    }

    resetFilters(){
      this.filterValue = {}
      this.applyFilter(this.filterValue)
        this.filedFilterlogicCode='filter--s';
        this.filedFiltermapType='filter--s';
        this.filedFilterrxClaimField= 'filter--s';
        this.filedFiltermappingInstructions= 'filter--s';
        this.filedFiltermappingDescription= 'filter--s';
        this.filedFilterlogicCodeStatus= 'filter--s';
        this.filedFilterfieldTag='filter--s';
        this.filterForm.reset()
      this.createForm(this.completeResponse)
    }
     
      ngOnDestroy() {
        if (this.subs.length) {
          this.subs.forEach(sub => sub.unsubscribe());
        }
      }
  }


