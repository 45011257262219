import {EprFeed} from '../models/EprFeed';
import {
  Actions,
  DELETE_EPRFEED,
  EDIT_EPRFEED,
  EDIT_EPRFEED_SUCCESS,
  LOAD_EPRFEED,
  LOAD_EPRFEED_SUCCESS,
  SAVE_EPRFEED_SUCCESS,
} from '../actions/epr-feed.action';

export interface State {
  allEprFeeds: EprFeed[];
  selectedEprFeedId: number;
  selectedEprFeed: EprFeed;
}

export const initialState: State = {
  allEprFeeds: [],
  selectedEprFeedId: undefined,
  selectedEprFeed: {}
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    // case LOAD_EPRFEED:
    //   return {...state };

    case LOAD_EPRFEED_SUCCESS:
      const allEprFeeds = (Array.isArray(action.payload)) ? action.payload : [action.payload];
      return {...state, selectedEprFeed: action.payload as EprFeed, allEprFeeds: allEprFeeds as EprFeed[]};

    case SAVE_EPRFEED_SUCCESS:
      const newEprFeed = state.allEprFeeds.concat(action.payload);
      newEprFeed.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
      return {...state, allEprFeeds: newEprFeed};

    case EDIT_EPRFEED:
      return {...state, selectedEprFeedId: action.payload.id};


    case EDIT_EPRFEED_SUCCESS:
      let index;
      for (let i = 0; i < state.allEprFeeds.length; i++) {
          if ((action.payload.id === state.allEprFeeds[i].id)) {
            index = i;
          }
      }

      return {
        ...state,
        allEprFeeds: [
          ...state.allEprFeeds.slice(0, index),
          Object.assign({}, state.allEprFeeds[index], action.payload),
          ...state.allEprFeeds.slice(index + 1)
          ]
        };
    case DELETE_EPRFEED:
      return {
        ...state
      };


    default:
      return state;
  }
}
export const getEprFeeds = (state: State) => state.allEprFeeds;
export const getSelectedEprFeed = (state: State) => state.selectedEprFeed;
