
<div class="row" style="min-width:30rem ;font-size: 1rem;">
    <h2 mat-dialog-title style="padding-bottom: 1rem; border-bottom :2px solid lightgray">{{providedInfo.title}}</h2>
    
    <ng-container >
    <mat-dialog-content style="max-width:27rem"  class="">
    <p>
        Are you sure you want to exit without saving?
    </p>
    <p>Warning: All changes will be lost, this action cannot be undone.</p>
    </mat-dialog-content>
    <mat-dialog-actions class="row">
        <button class="mat-raised-button col-4"style="padding: 8px 20px;" (click)="close()">No</button>
        <button class="mat-raised-button mat-primary col-4"style="padding: 8px 20px;" (click)="save()">Yes</button>
    </mat-dialog-actions>
    
    </ng-container>
    </div>