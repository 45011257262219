const checkbox = (params) => `<input class="checkboxCustom" type='checkbox' ${params.value ? 'checked' : ''} /><label></label>`;

export const spedmDashboardColumns = [
  // {headerName: 'cltSetupID', field: 'cltSetupID', sortable: true, filter: true, resizable: true},
  {
    headerName: '', field: 'select',editable:false ,cellRenderer: (params) => checkbox(params),
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {headerName: 'Client Receiver', field: 'clientReceiver', sortable: true, filter: true, resizable: true},
  {headerName: 'Client Name', field: 'clientName', sortable: true, filter: true, resizable: true},
  {headerName: 'Drug Name', field: 'drugName', sortable: true, filter: true, resizable: true},
  {headerName: 'DRM Name', field: 'drmName', sortable: true, filter: true, resizable: true},
  {headerName: 'File Type', field: 'fileType', sortable: true, filter: true, resizable: true},
  {headerName: 'File Name Mask', field: 'filenameMask', sortable: true, filter: true, resizable: true},
  {headerName: 'Active/InActive Status', field: 'activeorInactiveStatus', sortable: true, filter: true, resizable: true},
  {headerName: 'Report Frequency', field: 'reportFrequency', sortable: true, filter: true, resizable: true},
  {headerName: 'Report Frequency Value', field: 'reportFrequencyVal', sortable: true, filter: true, resizable: true},
  {headerName: 'Contracted Delivery Time(ET)', field: 'contractedDeliveryTime', sortable: true, filter: true, resizable: true},
  {headerName: 'Late File Warning Time(ET)', field: 'lateFileWarningTime', sortable: true, filter: true, resizable: true},
 {headerName: 'Frequency of Alerts', field: 'alertFrequency', sortable: true, filter: true, resizable: true},
 {headerName: 'Late File Warning Alert', field: 'lateFileWarningAlert', sortable: true, filter: true, resizable: true},
  {headerName: 'Missed File SLA Alert', field: 'missedFileSLAAlert', sortable: true, filter: true, resizable: true},
 {
    headerName: 'Note', field: 'note', sortable: true, filter: true, resizable: true,
    tooltipField: 'note',
    tooltipComponentParams: {color: '#ececec'}
  },
];
export const filetrackerMCColumns = [
  // {headerName: 'File Tracker Details', field: 'pliCode', sortable: true, filter: true, resizable: true},
  {headerName: 'Global ID', field: 'globalId', sortable: true, filter: true, resizable: true},
  {headerName: 'Receiver ID', field: 'receiverId', sortable: true, filter: true, resizable: true},
  {headerName: 'File Route', field: 'fileRoute', sortable: true, filter: true, resizable: true},
  {headerName: 'Manufacturer', field: 'manufacturer', sortable: true, filter: true, resizable: true},
  {headerName: 'Drug name', field: 'drugName', sortable: true, filter: true, resizable: true},
  {headerName: 'File Type', field: 'fileType', sortable: true, filter: true, resizable: true},
  {headerName: 'File name pattern', field: 'fileNamePattern', sortable: true, filter: true, resizable: true},
  {headerName: 'Report Frequency', field: 'reportFrequency', sortable: true, filter: true, resizable: true},
  {headerName: 'Email Alt', field: 'emailAlt', sortable: true, filter: true, resizable: true},
  {headerName: 'CreationTime', field: 'creationTime', sortable: true, filter: true, resizable: true},
  {headerName: 'Completed', field: 'complete', sortable: true, filter: true, resizable: true},
];

export function createFiletrackerColumns(masterViewColumns: string[]) {
  const customMasterEprColumns = [];

  masterViewColumns.forEach(columnId => {
    customMasterEprColumns.push(spedmDashboardColumns.filter(column => column.field === columnId)[0]);
  });

  return customMasterEprColumns;
}

