import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  static show() {
    const body = document.getElementsByTagName('cvs-base')[0];
    if (body !== undefined) {
      body.setAttribute('class', 'busy-cursor');
    }
    // Ag grid requires explicit handling
    const grids = document.getElementsByTagName('ag-grid-angular');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < grids.length; i++) {
      grids[i].setAttribute('class', 'ag-theme-alpine busy-cursor');
    }
  }

  static hide() {
    const body = document.getElementsByTagName('cvs-base')[0];
    if (body !== undefined) {
      body.removeAttribute('class');
    }
    const grids = document.getElementsByTagName('ag-grid-angular');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < grids.length; i++) {
      grids[i].setAttribute('class', 'ag-theme-alpine'); // Reset it back to the theme
    }
  }
}
