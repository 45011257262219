import * as moment from "moment"

export const projectTrackerColumns = [
  {headerName: '', field: 'g4',  cellRenderer: 'editAction',   width: 50},
  {headerName: 'Project', sortable: true, filter: true, field: 'projectName', cellRenderer: params => {
      let formattedDate =  params.data?.prjStartDte ? moment(params.data?.prjStartDte).format('YYMMDD'): "";
      return "Project - "+formattedDate+" "+ (params.data?.projectType ?  params.data?.projectType : "" );
    },
  },
  {headerName: 'Project Type', field: 'projectType', sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'Project Phase', field: 'prjPhase',
  cellRenderer: params => {
    const projectPhase = params.data && params.data.prjPhase;
    const projectStatus = params.data && params.data.prjStatus;

    if (projectPhase) {
      let progressBarPercent;
      let progressBar;
      if (projectPhase === 'null' || projectPhase === null || projectPhase === undefined) {
        progressBar = '<div></div>';
        progressBarPercent = 0;
      } else if (projectPhase === 'not started' || projectPhase === 'Not Started') {
        progressBar = '<div>' + 'Not Started' + '</div>';
        progressBarPercent = 0;
      } else if (projectPhase === 'In Discovery') {
        progressBar = '<div>' + 'In Discovery' + '</div>';
        progressBarPercent = 10;
      } else if (projectPhase === 'In Setup') {
        progressBar = '<div>' + 'In Setup' + '</div>';
        progressBarPercent = 20;
      } else if (projectPhase === 'Waiting on BSR') {
        progressBar = '<div>' + 'Waiting on BSR' + '</div>';
        progressBarPercent = 30;
      } else if (projectPhase === 'Awaiting FTP Login ID') {
        progressBar = '<div>' + 'Awaiting FTP Login ID' + '</div>';
        progressBarPercent = 40;
      } else if (projectPhase === 'Ready for QC') {
        progressBar = '<div>' + 'Ready for QC' + '</div>';
        progressBarPercent = 50;
      } else if (projectPhase === 'Awaiting Test File') {
        progressBar = '<div>' + 'Awaiting Test File' + '</div>';
        progressBarPercent = 60;
      } else if (projectPhase === 'Testing') {
        progressBar = '<div>' + 'Testing' + '</div>';
        progressBarPercent = 70;
      } else if (projectPhase === 'Awaiting Baseline') {
        progressBar = '<div>' + 'Awaiting Baseline' + '</div>';
        progressBarPercent = 80;
      } else if (projectPhase === 'Loading Baseline') {
        progressBar = '<div>' + 'Loading Baseline' + '</div>';
        progressBarPercent = 85;
      } else if (projectPhase === 'Awaiting EPR Sign-Off') {
        progressBar = '<div>' + 'Awaiting EPR Sign-Off' + '</div>';
        progressBarPercent = 90;
      } else if (projectPhase === 'Ready for Go-Live') {
        progressBar = '<div>' + 'Ready for Go-Live' + '</div>';
        progressBarPercent = 95;
      } else if (projectPhase === 'On Hold') {
        progressBar = '<div>' + 'On Hold' + '</div>';
        progressBarPercent = 0;
      } else if (projectPhase === 'Completed') {
        progressBar = '<div>' + 'Completed' + '</div>';
        progressBarPercent = 0;
      } else if (projectPhase === 'Cancelled') {
        progressBar = '<div>' + 'Cancelled' + '</div>';
        progressBarPercent = 0;
      }

      let newProjectStatus;
      switch (projectStatus) {
        case 'Red':
        case 'red':
          newProjectStatus = "#cc0000";
          break;
        case 'Green':
        case 'green':
          newProjectStatus = "#61A515";
          break;
        case 'Yellow':
        case 'yellow':
          newProjectStatus = "#FFD216";
          break;
        case 'Black':
        case 'black':
          newProjectStatus = "#000000";
          break;

        default:
          newProjectStatus = "#000000";
          break;
      }
      progressBar = progressBar +
        '<div style="border-radius: 6px; width: 100%; margin-top: -6%; background-color: ' + newProjectStatus + '40;">' +
        '<div style="background-color: ' + projectStatus + '; ' +
        'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
        '</div>' +
        '</div>';
      return progressBar;
    } else {
      return params.value;
    }
  },
  sortable: true, filter: true, resizable: true, lockVisible: true},

  {headerName: 'Prj Go-Live Date', field: 'prjGoLiveDte', cellRenderer: (data) => {
    return data.value ? moment(data.value).format('YYYY-MM-DD'): "";
  },
  sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'Prj Status', field: 'prjStatus',
  cellRenderer: params => {  return params.data?.prjStatus },
  sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'Card Status', field: 'prjCardStatus', sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: "QC'd By", field: 'QCdBy', sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'QC Cmplt Date', field: 'prjQCDte', cellRenderer: (data) => {
    return data.value ? moment(data.value).utc().format('YYYY-MM-DD'): "";
  }, sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'Disc Due Date', field: 'newDiscDueDate', cellRenderer: (data) => {
    return data.value ? moment(data.value).format('YYYY-MM-DD'): "";
  },
   sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'Disc Cmplt Date', field: 'newDiscCmpltDate',
  cellRenderer: (data) => {
    return data.value ? moment(data.value).format('YYYY-MM-DD'): "";
  }, sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'Setup Due Date', field: 'newSetupDueDate', cellRenderer: (data) => {
    return data.value ? moment(data.value).format('YYYY-MM-DD'): "";
  }, sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'Setup Cmplt Date', field: 'newSetupCmpltDate', cellRenderer: (data) => {
    return data.value ? moment(data.value).format('YYYY-MM-DD'): "";
  },sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'Testing Due Date', field: 'newTstDueDate', cellRenderer: (data) => {
    return data.value ? moment(data.value).format('YYYY-MM-DD'): "";
  }, sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'Testing Cmplt Date', field: 'newTstCmpltDate', cellRenderer: (data) => {
    return data.value ? moment(data.value).format('YYYY-MM-DD'): "";
  }, sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'Baseline Due Date', field: 'newBLDueDate', cellRenderer: (data) => {
    return data.value ? moment(data.value).format('YYYY-MM-DD'): "";
  }, sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'Baseline Cmplt Date', field: 'newBLCmpltDate', cellRenderer: (data) => {
    return data.value ? moment(data.value).format('YYYY-MM-DD'): "";
  }, sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'ID Cards Due Date', field: 'newIDCrdsDueDate', cellRenderer: (data) => {
    return data.value ? moment(data.value).format('YYYY-MM-DD'): "";
  },sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'ID Cards Cmplt Date', field: 'newIDCrdsCmpltDate', cellRenderer: (data) => {
    return data.value ? moment(data.value).format('YYYY-MM-DD'): "";
  }, sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'Eligibility Type', field: 'eligibilityType', sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'FTP ID', field: 'ftpid', sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'Vendor', field: 'vendor', sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'PG Criteria', field: 'pgCriteria', sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'SSOE Migrated', field: 'ssoeMigrated', sortable: true, filter: true, resizable: true, lockVisible: true},
  {headerName: 'File Format', field: 'format', sortable: true, filter: true, resizable: true, lockVisible: true},

]

/*
export const projectTrackerColumns = [
    {headerName: 'EC', field: 'g4', sortable: true, filter: true, resizable: true, lockVisible: true,
    lockPosition: true, filterParams: {newRowsAction: 'keep'}},
    {headerName: 'Status', field: 'g5', sortable: true, filter: true, resizable: true,
      lockVisible: true, lockPosition: true, filterParams: {newRowsAction: 'keep'},
      tooltipField: 'tRecord.softText',
      cellRenderer: params => {
        const currentValue = params.data.progressBarDetails;
        if (currentValue && currentValue.outputText !== "Complete") {
          const progressBarPercent = (currentValue.rowSequence / currentValue.totalTranslations) * 100;
          const progressBar = '<div>' + currentValue.outputText + '</div>' +
            '<div style="border-radius: 6px; width: 100%; margin-top: -6%; background-color:' + currentValue.statusColour + '40;">' +
            '<div style="background-color: ' + currentValue.statusColour + '; ' +
            'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
            '</div>' +
            '</div>';
          return progressBar;
        } else if (currentValue && currentValue.outputText === "Complete") {
          const progressBarPercent = (currentValue.rowSequence / currentValue.totalTranslations) * 100;
          const progressBar = '<div>' + currentValue.outputText + '</div>' +
            '<div style="border: 0.1px solid #000000;border-radius: 6px; width: 100%; margin-top: -6%; background-color:' + currentValue.statusColour + '40;">' +
            '<div style="background-color: ' + currentValue.statusColour + '; ' +
            'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
            '</div>' +
            '</div>';
          return progressBar;
        } else {
          return params.value;
        }
      }
    },
    {headerName: 'Library', field: 'g13', sortable: true, filter: true, resizable: true,
      lockVisible: true, lockPosition: true, filterParams: {newRowsAction: 'keep'}},
    {headerName: 'File', field: 'g14', sortable: true, filter: true, resizable: true,
      lockVisible: true, lockPosition: true, filterParams: {newRowsAction: 'keep'}},
    {headerName: 'Member', field: 'g6', sortable: true, filter: true, resizable: true,
      lockVisible: true, lockPosition: true, filterParams: {newRowsAction: 'keep'}},
    {headerName: 'Business Date', field: 'g7', sortable: true, filter: true, resizable: true,
      lockVisible: true, lockPosition: true, filterParams: {newRowsAction: 'keep'}},
    {headerName: 'Record Count', field: 'g8', sortable: true, filter: true, resizable: true,
      lockVisible: true, lockPosition: true, filterParams: {newRowsAction: 'keep'}},
    {headerName: 'PG Criteria',   field: 'g11', sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
      filterParams: { newRowsAction: 'keep'}}
  ];
  */
