import {Inject, Injectable} from '@angular/core';
import {AuditlogService} from './auditlog.service';
import {BalancesheetService} from './balancesheet.service';
import {ThresholdService} from './threshold.service';
import {saveAs} from 'file-saver';
import {CVSAlertService} from 'angular-component-library';
import { of } from 'rxjs';
import { ProcessingFileDB } from '../models/PermissionsObject';

@Injectable({
  providedIn: 'root'
})
export class ThresholddialogreportService {

  summaryreportheaders: any = ['Carrier', 'PLI ID', 'Library', 'File Name', 'Member Name', 'File Type',
    'Header-Trailer Date', 'Header/Trailer', 'Member/Group', 'PRIVACY/ADDRESS/EMAIL', 'Move History',
    'Member/Group Rejects', 'Thresh Flg M/G Rejects', 'Prv/Add/email Rejects', 'Move Hist Rejects',
    'M/G Suspend', 'M/G UnSuspend', 'M/G Drp from Sus', 'History Suspend', 'History UnSuspend',
    'Hist Drop Frm Suspense', 'ST-Link Suspended', 'M/G Added', 'Retro Term', 'Current Term',
    'Future Term', 'Imp Trm due to Mbr Move', 'Imp Prv due to Mbr Move',
    'Dependent Imp Trm', 'Term by Absence', 'TBA Thrsh Flag', 'Batch Ovr Protected', 'Manual TBA Protected',
    'Demographic Changes', 'Demographic Changes Flag', 'Bad Address', 'Hist Move Frm File', 'His Mov Imp Trm Mbr Mov',
    'Alt ID Created', 'Account Created', 'Group Created', 'Physician ID created', 'Care Facility Created',
    'Care Qualifier Created', 'Miscellaneous', 'Opt M/G', 'Opt Priv Add', 'Opt Mov Hist', 'Status', 'Fastlink or Manual'];

  summaryreportmappings: any = ['carrier', 'pliId', 'library', 'fileName', 'memberName', 'fileType',
    'headerTrailerDate', 'headerTrailer', 'memberGroup', 'privacyAddressEmail', 'moveHistory',
    'memberGroupRejects', 'thresholdFlagMemberGroupRejects', 'privacyAddressEmailRejects', 'moveHistoryRejects',
    'memberGroupSuspend', 'memberGroupUnSuspend', 'memberGroupDropFromSuspend', 'historySuspend', 'historyUnSuspend',
    'historyDropFromSuspend', 'stLinkSuspend', 'memberGroupAdded', 'retroTerm', 'currentTerm', 'futureTerm',
    'impTrmDueToMbrMove', 'impPrvDueToMbrMove', 'dependentImpTrm', 'termByAbsence', 'tbaThresholdFlag',
    'batchOvrProtected', 'manualTBAProtected', 'demographicChanges', 'demographicChangesFlag', 'badAddress',
    'histMoveFrmFile', 'hisMovImpTrmMbrMov', 'altIdCreated', 'accountCreated', 'groupCreated', 'physicianIdCreated',
    'careFacilityCreated', 'careQualifierCreated', 'miscellaneous', 'optMemberGroup', 'optPrivAdd', 'optMovHist',
    'status', 'fastlinkOrManual'];

  detailreportheaders: any = ['Carrier', 'Sequence#', 'Account ID', 'Group ID', 'Plan', 'Member ID', 'Alternate ID',
    'First Name', 'Last Name', 'Date of Birth', 'Eff Date', 'Term Date',
    'Dynamic Field1', 'Dynamic Field2', 'Dynamic Field3', 'Dynamic Field4', 'Type', 'Msg Value', 'Message Code'];

  detailreportmappings: any = ['carrier', 'sequenceNumber', 'accountId', 'groupId', 'plan', 'memberId', 'alternateId',
    'firstName', 'lastName', 'dateOfBirth', 'effectiveDate', 'termDate',
    'dynamicField1', 'dynamicField2', 'dynamicField3', 'dynamicField4', 'type', 'messageValue', 'messageCode'];

  typereportheaders: any = ['Carrier', 'Account', 'Group', 'Member ID', 'Xwalk ID',
    'First Name', 'Last Name', 'Date of Birth', 'Term Date', 'Eff Date', 'Msg Type',
    'Msg Detail'];

  typereportmappings: any = ['carrier', 'account', 'group', 'memberId', 'xwalkId',
    'firstName', 'lastName', 'dateOfBirth', 'termDate', 'effectiveDate', 'msgType',
    'msgDetail'];
  typereportSsoeheaders :any = [
    "Record Type","Carrier Id","Account Id","Group Id","Member Id","Person Code","Relationship","Last Name","First Name",
    "Middle Initial","Sex Code","Date Of Birth","Multi Birth Code","Member Type","Language Code","DurKey","Social Security Number",
    "Address1","Address2","City","State","Zip1","Zip2","Zip3","Country","Phone Number","Family Flag",
    "Family Type","Family Id","Original From Date","Benefit Reset Date","Effective Date","Termination Date",
    "Brand","Generic","Copay3","Copay4","Client Product Code","Client Rider Code","Care From Date","Care Thru Date",
    "Care Network","Care Network PlanOvr","Care Network Plan From","Care Facility Id","Care Qualifier","Physician Id",
    "AltIns From Date","AltIns Thru Date","AltIns Indicator","AltIns Code","AltIns Id","New Card Flag","Fsa Indicator",
    "Grace Period Indicator","Grace Period Indicator Effective Date","Family Type FromDate","familyType ThruDate",
    "Disenrollment Reason Code","Date Of Death","Cdd TermDate","Cdd","MoopLimit","MoopEff","Medicare Participation EventDate",
    "Medicare PartD ThruDate","Ethnicity","Alternate Id","MbiHicn Railroad Board Number","Lics Premium Subsidy Level","Contract Id","Transition Period StartDate",
    "Esrd FromDate","Esrd ThruDate","Dialysis FromDate","Dialysis ThruDate","Transplant FromDate","Transplant ThruDate","Mbr Part B Flag FromDate","Mbr Part B Flag ThruDate",
    "Member Language Print Format Code","Mbr Hospice FromDate","MbrHospice ThruDate"
]
typereportSsoemappings :any = [
  "recordType","carrierId","accountId","groupId","memberId","personCode","relationship","lastName","firstName",
  "middleInitial","sexCode","dateOfBirth","multiBirthCode","memberType","languageCode","durKey","socialSecurityNumber",
  "address1","address2","city","state","zip1","zip2","zip3","country","phoneNumber","familyFlag",
  "familyType","familyId","originalFromDate","benefitResetDate","effectiveDate","terminationDate",
  "brand","generic","copay3","copay4","clientProductCode","clientRiderCode","careFromDate","careThruDate",
  "careNetwork","careNetworkPlanOvr","careNetworkPlanFrom","careFacilityId","careQualifier","physicianId",
  "altInsFromDate","altInsThruDate","altInsIndicator","altInsCode","altInsId","newCardFlag","fsaIndicator",
  "gracePeriodIndicator","gracePeriodIndicatorEffectiveDate","familyTypeFromDate","familyTypeThruDate",
  "disenrollmentReasonCode","dateOfDeath","cddTermDate","cdd","moopLimit","moopEff","medicareParticipationEventDate",
  "medicarePartDThruDate","ethnicity","alternateId","mbiHicnRailroadBoardNumber","licsPremiumSubsidyLevel","contractId","transitionPeriodStartDate",
  "esrdFromDate","esrdThruDate","dialysisFromDate","dialysisThruDate","transplantFromDate","transplantThruDate","mbrPartBFlagFromDate","mbrPartBFlagThruDate",
  "memberLanguagePrintFormatCode","mbrHospiceFromDate","mbrHospiceThruDate"
]

  constructor(private auditlogService: AuditlogService,
              private thresholdService: ThresholdService,
              private balanceSheetService: BalancesheetService) {
  }

  csvDownload(headers: any, mappings: any, data: any, fileName: any) {
    let csvContent: any;
    const separator = ',';
    if (!data || !data.length) {
      csvContent = headers.join(separator) + '\n';
    } else {
      csvContent = headers.join(separator) + '\n' +
        data.map((rowData: any) => {
          return mappings.map((name) => {
            return rowData[name];
          }).join(separator);
        }).join('\n');
    }
    this.exportFile(csvContent, fileName);
  }

  exportFile(data: any, fileName: any) {
    const fileData = new Blob([data], {type: 'text/csv'});
    saveAs(fileData, fileName);
  }

  exportAuditLogSummary(request: any,  ssoeFlag: boolean, db:ProcessingFileDB,alertService: CVSAlertService) {
    this.auditlogService.getAuditLogSummaryReport(request, ssoeFlag, db).subscribe(rowData => {
      if (rowData.message && rowData.message.length  > 0) {
        return rowData.message;
      }
      if(!ssoeFlag){
        const fileName = 'AuditLog_Summary_Report.csv';
        this.csvDownload(this.summaryreportheaders, this.summaryreportmappings, rowData.response, fileName);
      } else{
        const mediaId= request.auditNumber;
        const fileName = 'MEMBER_ELIGIBILITY_SUMMARY_REPORT_' + mediaId + '.rtf';
        saveAs(rowData, fileName);
      }
      alertService.clearAlert('#summary-alert');
    });
  }

  exportAuditLogDetails(request: any, ssoeFlag: boolean,db = null,alertService: CVSAlertService) {
    this.auditlogService.getAuditLogDetailsReport(request, ssoeFlag, db).subscribe(rowData => {
      if (rowData.message && rowData.message.length  > 0) {
        return rowData.message;
      }
      if(!ssoeFlag){
        const fileName = 'AuditLog_Details_Report.csv';
        this.csvDownload(this.detailreportheaders, this.detailreportmappings, rowData.response, fileName);
      }else{
        const mediaId= request.auditNumber;
        const fileName = 'MEMBER_ELIGIBILITY_DETAIL_REPORT_' + mediaId + '.rtf';
        saveAs(rowData, fileName);
      }
      alertService.clearAlert('#summary-alert');
    });
  }

  exportThresholdDetails(request: any, ssoeFlag: boolean, db = null, alertService: CVSAlertService) {
    this.thresholdService.getThresholdDetailsReport(request, ssoeFlag, db).subscribe(rowData => {
      if (rowData.message && rowData.message.length  > 0) {
        return rowData.message;
      }
      if(!ssoeFlag){
        const fileName = 'Threshold_Details_Report.csv';
        this.csvDownload(this.detailreportheaders, this.detailreportmappings, rowData.response, fileName);
      } else{
        const mediaId= request.auditNumber;
        const fileName = 'MEMBER_ELIGIBILITY_DETAIL_REPORT_' + mediaId + '.rtf';
        saveAs(rowData, fileName);
      }
      alertService.clearAlert('#summary-alert');
    });
  }
  exportThresholdType(request: any, ssoeFlag:boolean ,alertService: CVSAlertService) {

    if(ssoeFlag){
      this.thresholdService.getThresholdRowReport(request).subscribe(rowData=>{
        const fileName = 'Threshold_Type_Report.csv';
        if (rowData.message && rowData.message.length  > 0) {
          return rowData.message;
        }
        const csvData = rowData.response.map(data=>{return data.recordLayout})
        this.csvDownload(this.typereportSsoeheaders, this.typereportSsoemappings, csvData, fileName);
        alertService.clearAlert('#summary-alert');
      });
    }else{
      this.thresholdService.getThresholdTypeReport(request).subscribe(rowData => {
        const fileName = 'Threshold_Type_Report.csv';
        if (rowData.message && rowData.message.length  > 0) {
          return rowData.message;
        }
        this.csvDownload(this.typereportheaders, this.typereportmappings, rowData.response, fileName);
        alertService.clearAlert('#summary-alert');
      });
    }
   
  }
  

  exportThresholdSummary(request: any, ssoeFlag: boolean, db:ProcessingFileDB,alertService: CVSAlertService) {
    this.thresholdService.getThresholdSummaryReport(request, ssoeFlag, db).subscribe(rowData => {
      if (rowData.message && rowData.message.length  > 0) {
        return rowData.message;
      }
      if(!ssoeFlag){
        const fileName = 'Threshold_Summary_Report.csv';
        this.csvDownload(this.summaryreportheaders, this.summaryreportmappings, rowData.response, fileName);
      } else{
        const mediaId= request.auditNumber;
        const fileName = 'MEMBER_ELIGIBILITY_SUMMARY_REPORT_' + mediaId + '.rtf';
        saveAs(rowData, fileName);
      }
      alertService.clearAlert('#summary-alert');
    });
  }

  exportBalanceSheetDetails(request: any, ssoeFlag: boolean, db:ProcessingFileDB ,alertService: CVSAlertService) {
    this.balanceSheetService.getBalanceSheetDetailsReport(request, ssoeFlag, db).subscribe(rowData => {
      if (rowData.message && rowData.message.length  > 0) {
        return rowData.message;
      }
      if(!ssoeFlag){
        const fileName = 'BalanceSheet_Details_Report.csv';
        this.csvDownload(this.detailreportheaders, this.detailreportmappings, rowData.response, fileName);
      } else {
        const mediaId= request.auditNumber;
        const fileName = 'MEMBER_ELIGIBILITY_DETAIL_REPORT_' + mediaId + '.rtf';
        saveAs(rowData, fileName);
      }
      alertService.clearAlert('#summary-alert');
    });
  }

  exportBalanceSheetSummary(request: any, ssoeFlag: boolean, db:ProcessingFileDB ,alertService: CVSAlertService) {
    this.balanceSheetService.getBalanceSheetSummaryReport(request, ssoeFlag, db).subscribe(rowData => {
      if (rowData.message && rowData.message.length  > 0) {
        return rowData.message;
      }
      if(!ssoeFlag){
        const fileName = 'BalanceSheet_Summary_Report.csv';
        this.csvDownload(this.summaryreportheaders, this.summaryreportmappings, rowData.response, fileName);
    } else {
        const mediaId= request.auditNumber;
        const fileName = 'MEMBER_ELIGIBILITY_SUMMARY_REPORT_' + mediaId + '.rtf';
        saveAs(rowData, fileName);
    }
      alertService.clearAlert('#summary-alert');
    });
  }
}
