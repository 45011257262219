import { FormFieldConfig, InputType } from '../models/FormFieldConfig';
import { Validators } from '@angular/forms';
import { select } from '@ngrx/store';

export const spedmFiletrackerFormConfig: any = [
    [
        {
            label: 'Setup ID',
            controlName: 'cltSetupID'
        },
        {
            label: 'Client Receiver',
            controlName: 'clientReceiver',
            inputType: InputType.sinkInput1,
            controller: [Validators.required],
            errorMessage: 'Client Receiver is required',
            title: 'REQUIRED FIELD',
            required: true,
            index: 1
        },
        {
            label: 'Client Name',
            controlName: 'clientName',
            inputType: InputType.sinkInput2,
            controller: [Validators.required],
            errorMessage: 'Client Name is required',
            title: 'REQUIRED FIELD',
            required: true,
            index: 2
        },
        {
            label: 'Drug Name',
            controlName: 'drugName',
            inputType: InputType.sinkInput3,
            controller: [Validators.required],
            errorMessage: 'Client Receiver is required',
            title: 'REQUIRED FIELD',
            required: true,
            index: 3
        },
        {
            label: 'DRM Name',
            controlName: 'drmName',
            inputType: InputType.DRM_DROPDOWN,
            controller: [Validators.required],
            errorMessage: 'DRM Name is required',
            title: 'REQUIRED FIELD',
            required: true,
        },
        {
            label: 'File Type',
            controlName: 'fileType',
            inputType: InputType.DROPDOWN,
            controller: [Validators.required],
            errorMessage: 'File Type is required',
            title: 'REQUIRED FIELD',
            options: [
                { value: '852', name: '852' },
                { value: '867', name: '867'}
            ],
            required: true
        },
        {
            label: 'File Name Mask',
            controlName: 'filenameMask',
            inputType: InputType.sinkInput4,
            controller: [Validators.required],
            errorMessage: 'Client Receiver is required',
            title: 'REQUIRED FIELD',
            required: true,
            index: 4
        },
        {
            label: 'Active/InActive Status',
            controlName: 'activeorInactiveStatus',
            inputType: InputType.DROPDOWN,
            controller: [Validators.required],
            errorMessage: 'File Type is required',
            dropdownTitle: 'Primary time period (e.g.,Yes,No) agreed upon by client and CVS Health to receive, transfer' +
        ' and load each file into the system. REQUIRED FIELD',
            options: [
                { value: 'Y' , name: 'Yes'} ,
                { value: 'N', name: 'No'}
            ],
            required: true
        },
        {
            label: 'Report Frequency',
            controlName: 'reportFrequency',
            controlValName: 'reportFrequencyVal',
            inputType: InputType.DROPDOWN,
            controller: [Validators.required],
            errorMessage: 'File Type is required',
            title: 'REQUIRED FIELD',
            options: [
                { value: 'Daily M-F', name: 'Daily M-F' },
                { value: 'Daily', name: 'Daily 7 Days' },
                { value: 'Weekly', name: 'Weekly' },
                { value: 'Monthly', name: 'Monthly' }
            ],
            required: true,
            listing: 'repFrequency',
            listingoptionsweekly: [
                {value: 'SUNDAY', name: 'Sunday'},
                {value: 'MONDAY', name: 'Monday'},
                 {value: 'TUESDAY', name: 'Tuesday'},
                {value: 'WEDNESDAY', name: 'Wednesday'},
                 {value: 'THURSDAY', name: 'Thursday'},
                {value: 'FRIDAY', name: 'Friday'},
                 {value: 'SATURDAY', name: 'Saturday'}
            ],
          listingoptionsdaily7: [
            { value: 'Daily', name: 'Daily 7 Days' },
          ],
          listingoptionsdailyMtoF: [
            { value: 'DailyM-F', name: 'Daily M-F' },
          ],


          listingoptionsmonthly: [
                {value: '1', name: '1'},
                {value: '2', name: '2'},
                 {value: '3', name: '3'},
                {value: '4', name: '4'},
                 {value: '5', name: '5'},
                {value: '6', name: '6'},
                 {value: '7', name: '7'},
                {value: '8', name: '8'},
                 {value: '9', name: '9'},
                {value: '10', name: '10'},
                {value: '11', name: '11'},
                {value: '12', name: '12'},
                 {value: '13', name: '13'},
                {value: '14', name: '14'},
                 {value: '15', name: '15'},
                {value: '16', name: '16'},
                 {value: '17', name: '17'},
                {value: '18', name: '18'},
                 {value: '19', name: '19'},
                {value: '20', name: '20'},
                {value: '21', name: '21'},
                {value: '22', name: '22'},
                 {value: '23', name: '23'},
                {value: '24', name: '24'},
                 {value: '25', name: '25'},
                {value: '26', name: '26'},
                 {value: '27', name: '27'},
                {value: '28', name: '28'},
                 {value: '29', name: '29'},
                {value: '30', name: '30'},
                {value: '31', name: '31'},
            ],
        },
        {
            label: 'Contracted Delivery Time(ET)',
            controlName: 'contractedDeliveryTime',
            title: 'Eastern TimeZone',
            inputType: InputType.TIME,
        },
        {
            label: 'Late File Warning Time(ET)',
            controlName: 'lateFileWarningTime',
            title: 'Eastern TimeZone',
            inputType: InputType.TIME,

        },
        {
             label: 'Frequency of Alerts',
            controlName: 'alertFrequency',
            inputType: InputType.DROPDOWN,
            title: 'REQUIRED FIELD',
            options: [
                { value: '15 MIN', name: 'Every 15 minutes' },
                { value: '30 MIN', name: 'Every 30 minutes'},
                { value: '45 MIN', name: 'Every 45 minutes' },
                { value: '1 HR', name: 'Every 1 Hour'},
                { value: '2 HR', name: 'Every 2 Hour' },
                { value: '3 HR', name: 'Every 3 Hour'},
                { value: '4 HR', name: 'Every 4 Hour'},
                { value: '5 HR', name: 'Every 5 Hour' },
                { value: '6 HR', name: 'Every 6 Hour'},
            ],
        },
        {
            label: 'Late File Warning Alert',
            controlName: 'lateFileWarningAlert',
            inputType: InputType.DROPDOWN,
            title: 'REQUIRED FIELD',
            options: [
                { value: 'Y', name: 'Yes' },
                { value: 'N', name: 'No'}
            ],
            lateFiles: 'lateFilesCallIn',
            alertType: 'SPEDM Late file warning alert',
        },
        {
            label: 'Missed File SLA Alert',
            controlName: 'missedFileSLAAlert',
            inputType: InputType.DROPDOWN,
            title: 'REQUIRED FIELD',
            options: [
                { value: 'Y', name: 'Yes' },
                { value: 'N', name: 'No'}
            ],
            notes: 'missedFilesSlalIn',
            alertType: 'SPEDM Missing SLA files alert',
        },
        {
            label: 'Note',
            controlName: 'note',
            title: 'Special File Processing Notes - Intended for Internal user',
            inputType: InputType.TEXT_AREA
        },

        {

        }
    ]
] as FormFieldConfig[][];
