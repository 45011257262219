import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { AppState, getReadOnly } from '../../reducers';
import { FormType } from '../../models/FormType';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { InputType } from '../../models/FormFieldConfig';
import { DeleteChangeLogAction } from '../../actions/eligibility-agreement.action';
import * as html2pdf from 'html2pdf.js';
import { combineLatest, Subscription } from 'rxjs';
import { UsersService } from "../../services/users.service";
import { Permission } from "../../models/Authorization";
import { map, switchMap } from 'rxjs/operators';
import { EprService } from 'src/app/services/epr.service';
import { questionnaireFormConfig } from '../questionnaire/QuestionnaireConfig';
import { QuestionnaireSettingsService } from 'src/app/services/questionnaire-settings.service';

@Component({
  selector: 'app-eligibility-agreement',
  templateUrl: './eligibility-agreement.component.html',
  styleUrls: ['./eligibility-agreement.component.scss']
})

export class EligibilityAgreementComponent implements OnInit, OnChanges, OnDestroy {
  @Input() readOnly: boolean;
  @Input() closeIcon: boolean;
  FormType = FormType;
  InputType = InputType;
  html2pdf = html2pdf();
  showSpinner$ = false
  modal =false
  ea_Form;
  updatedChangeLog;
  fileNamingProdSubscription: Subscription;
  grpFileNamingProdSubscription: Subscription;
  fileNamingTestSubscription: Subscription;
  fileNamingCompareSubscription: Subscription;
  cEPRFamIDSubscription: Subscription;
  cEPRFAltIdCodeSubscription: Subscription;
  cEPRFPersonCodeSubscription: Subscription;
  cEPR3rdIDSubscription: Subscription;
  cEPRTrmDates4AllMbrsSubscription: Subscription;
  cEPRDepImpTrmsSubscription: Subscription;
  cEPRImpTrmsSubscription: Subscription;
  cEPRImpTrm2Subscription: Subscription;
  cEPRTMRSubscription: Subscription;
  cEPRTMR2Subscription: Subscription;
  cEPRTMR3Subscription: Subscription;
  cEPRTMR4Subscription: Subscription;
  cEPRTMRIgnFutureTrmSubscription: Subscription;
  cEPRTMRInclFtrEffSubscription: Subscription;
  cEPRHistMthdSubscription: Subscription;
  cEPRSrvvngSpsSubscription: Subscription;
  cEPRCOBSubscription: Subscription;
  cEPRGrpSuspSubscription: Subscription;
  cEPRSuspenseSubscription: Subscription;
  cEPRFamonCardsSubscription: Subscription;
  cERPBatchOvrSubscription: Subscription;
  cEPRCHOnlySubscription: Subscription;
  cEPRAutoCAGSubscription: Subscription;
  cEPRStandaloneDepsSubscription: Subscription;
  cEPRSplitFamSubscription: Subscription;
  changeLogSubscription: Subscription;
  // Eligibility Processing Requirements
  clientName$ = this.store.select(fromRoot.getClientName);
  carriers$ = this.store.select(fromRoot.getCarriers);
  pli$ = this.store.select(fromRoot.getPli);
  eligibilityConsultant$ = this.store.select(fromRoot.getEcName);
  signOffStatus$ = this.store.select(fromRoot.getSignOffStatus);
  lineOfBusinessValues$ = this.store.select(fromRoot.getLineOfBusinessValues);
  populationValues$ = this.store.select(fromRoot.getPopulationValues);
  changeLog$ = this.store.select(fromRoot.getChangeLog)
  // File Parameters Selectors
  // consolidateProfileLogic$ = this.store.select(fromRoot.getConsolidateProfileLogic);
  vendor$ = this.store.select(fromRoot.getVendor);
  vendorLookup$ = this.store.select(fromRoot.getVendorLookup);
  ftpId$ = this.store.select(fromRoot.getFtpId);
  fileTransmitionMethod$ = this.store.select(fromRoot.getFileTransmitionMethod);
  fileNamingProd$ = this.store.select(fromRoot.getFileNamingProd);
  format$ = this.store.select(fromRoot.getFormat);
  grpFileNamingProd$ = this.store.select(fromRoot.getGrpFileNamingProd);
  groupFilesSent$ = this.store.select(fromRoot.getGroupFilesSent);
  fileNamingTest$ = this.store.select(fromRoot.getFileNamingTest);
  fileNamingCompare$ = this.store.select(fromRoot.getFileNamingCompare);
  compareProcess$ = this.store.select(fromRoot.getCompareProcess);
  compareFrequency$ = this.store.select(fromRoot.getCompareFrequency);
  qphysFiles$ = this.store.select(fromRoot.getQphysFiles);
  qdiagFiles$ = this.store.select(fromRoot.getQdiagFiles);
  qlabFiles$ = this.store.select(fromRoot.getQlabFiles);
  eprId$ = this.store.select(fromRoot.getEprId);
  fileFrequencyNotes$ = this.store.select(fromRoot.getFileFrequencyNotes);

  // Member Identification Selectors
  memIDLength$ = this.store.select(fromRoot.getMemIDLength);
  memIDMaxLength$ = this.store.select(fromRoot.getMemIDMaxLength);
  mbrIDTypeWPC$ = this.store.select(fromRoot.getMbrIDTypeWPC);
  xwalkIDLength$ = this.store.select(fromRoot.getXwalkIDLength);
  xwalkIDMaxLength$ = this.store.select(fromRoot.getXwalkIDMaxLength);
  xwalkIDTypeWPC$ = this.store.select(fromRoot.getXwalkIDTypeWPC);
  cEPRFamID$ = this.store.select(fromRoot.getCEPRFamID);
  cEPRFAltIdCode$ = this.store.select(fromRoot.getCEPRFAltIdCode);
  cEPRFPersonCode$ = this.store.select(fromRoot.getCEPRFPersonCode);
  pcLength$ = this.store.select(fromRoot.getPcLength);
  pcAssignmentMethod$ = this.store.select(fromRoot.getPcAssignmentMethod);
  cEPR3rdID$ = this.store.select(fromRoot.getCEPR3rdID);
  cardID$ = this.store.select(fromRoot.getCardID);

  // Member Terms and Movements Selectors
  cEPRTrmDates4AllMbrs$ = this.store.select(fromRoot.getCEPRTrmDates4AllMbrs);
  cEPRDepImpTrms$ = this.store.select(fromRoot.getCEPRDepImpTrms);
  impTermLevel$ = this.store.select(fromRoot.getImpTermLevel);
  cEPRImpTrms$ = this.store.select(fromRoot.getCEPRImpTrms);
  cEPRImpTrm2$ = this.store.select(fromRoot.getCEPRImpTrm2);
  cEPRTMR$ = this.store.select(fromRoot.getCEPRTMR);
  tbaDate$ = this.store.select(fromRoot.getTbaDate);
  cEPRTMR2$ = this.store.select(fromRoot.getCEPRTMR2);
  cEPRTMR3$ = this.store.select(fromRoot.getCEPRTMR3);
  cEPRTMR4$ = this.store.select(fromRoot.getCEPRTMR4);
  cEPRTMRIgnFutureTrm$ = this.store.select(fromRoot.getCEPRTMRIgnFutureTrm);
  cEPRTMRInclFtrEff$ = this.store.select(fromRoot.getCEPRTMRInclFtrEff);
  cEPRHistMthd$ = this.store.select(fromRoot.getCEPRHistMthd);

  // Member Terms & Movements hidden drivers
  drivers$ = this.store.select(fromRoot.getDrivers);

  // Additional Consideration Selectors
  cEPRSrvvngSps$ = this.store.select(fromRoot.getCEPRSrvvngSps);
  cEPRCOB$ = this.store.select(fromRoot.getCEPRCOB);
  cEPRPCOBNCOBNote$ = this.store.select(fromRoot.getCEPRPCOBNCOBNote);
  cEPRGrpSusp$ = this.store.select(fromRoot.getCEPRGrpSusp);
  cEPRSuspense$ = this.store.select(fromRoot.getCEPRSuspense);
  memberSuspenseRetentionDays$ = this.store.select(fromRoot.getMemberSuspenseRetentionDays);
  cEPRFamonCards$ = this.store.select(fromRoot.getCEPRFamonCards);
  cERPBatchOvr$ = this.store.select(fromRoot.getCERPBatchOvr);
  cEPRCHOnly$ = this.store.select(fromRoot.getCEPRCHOnly);
  cEPRAutoCAG$ = this.store.select(fromRoot.getCEPRAutoCAG);
  cEPRStandaloneDeps$ = this.store.select(fromRoot.getCEPRStandaloneDeps);
  cEPRSplitFam$ = this.store.select(fromRoot.getCEPRSplitFam);

  // Threshold Selectors
  questionnaireTermThreshold$ = this.store.select(fromRoot.getQuestionnaireTermThreshold);
  flerrorThreshold$ = this.store.select(fromRoot.getFlerrorThreshold);
  termThreshold$ = this.store.select(fromRoot.getTermThreshold);
  termThresholdInclMovesCount$ = this.store.select(fromRoot.getTermThresholdInclMovesCount);
  termThresholdExclMovesCount$ = this.store.select(fromRoot.getTermThresholdExclMovesCount);
  termThresholdAllCount$ = this.store.select(fromRoot.getTermThresholdAllActCount);
  termThreshExcMoves$ = this.store.select(fromRoot.getTermThreshExcMoves);
  termsVsAllActives$ = this.store.select(fromRoot.getTermsVsAllActives);
  famIDThrshld$ = this.store.select(fromRoot.getFamIDThrshld);
  matchLevel$ = this.store.select(fromRoot.getMatchLevel);
  newPopThrshldUOM$ = this.store.select(fromRoot.getNewPopThrshldUOM);
  chngMbrIDUOM$ = this.store.select(fromRoot.getChngMbrIDUOM);
  chngLNThrshldUse$ = this.store.select(fromRoot.getChngLNThrshldUse);
  chngFNThrshldUse$ = this.store.select(fromRoot.getChngFNThrshldUse);
  chngDOBThrshldUse$ = this.store.select(fromRoot.getChngDOBThrshldUse);
  chngRelThrshldUse$ = this.store.select(fromRoot.getChngRelThrshldUse);
  idcardThrshld$ = this.store.select(fromRoot.getIdcardThrshld);
  chngLNUOM$ = this.store.select(fromRoot.getChngLNUOM);
  chngFNUOM$ = this.store.select(fromRoot.getChngFNUOM);
  chngDOBThrshld$ = this.store.select(fromRoot.getChngDOBThrshld);
  chngRelThrshld$ = this.store.select(fromRoot.getChngRelThrshld);
  newPopThrshld$ = this.store.select(fromRoot.getNewPopThrshld);
  chngMbrIDThrshld$ = this.store.select(fromRoot.getChngMbrIDThrshld);
  chngLNThrshld$ = this.store.select(fromRoot.getChngLNThrshld);
  chngFNThrshld$ = this.store.select(fromRoot.getChngFNThrshld);
  chngDOBUOM$ = this.store.select(fromRoot.getChngDOBUOM);
  chngRelUOM$ = this.store.select(fromRoot.getChngRelUOM);
  thresholdNotes$ = this.store.select(fromRoot.getThresholdNotes);
  questionnnaire$ = this.store.select(fromRoot.getQuestionnaireState)
  fileParamFields = [
    'fileNamingProd',
    'grpFileNamingProd',
    'fileNamingTest',
    'fileNamingCompare'
  ];
  memberIdentificationFields = [
    'cEPRFamID',
    'cEPRFAltIdCode',
    'cEPRFPersonCode',
    'cEPR3rdID'
  ];
  memberTermsAndMovementFields = [
    'cEPRTrmDates4AllMbrs',
    'cEPRDepImpTrms',
    'cEPRImpTrms',
    'cEPRImpTrm2',
    'cEPRTMR',
    'cEPRTMR2',
    'cEPRTMR3',
    'cEPRTMR4',
    'cEPRTMRIgnFutureTrm',
    'cEPRTMRInclFtrEff',
    'cEPRHistMthd'
  ];
  additionalConsiderationsFields = [
    'cEPRSrvvngSps',
    'cEPRCOB',
    'cEPRPCOBNCOBNote',
    'cEPRGrpSusp',
    'cEPRSuspense',
    'cERPBatchOvr',
    'cEPRCHOnly',
    'cEPRFamonCards',
    'cEPRAutoCAG',
    'cEPRStandaloneDeps',
    'cEPRSplitFam',
    'field174'
  ];

  thresholdFields = []
  eligAgreementForm = new UntypedFormGroup({});
  changeLogFormArray: UntypedFormArray = new UntypedFormArray([]);
  showEmailBtn:boolean = true;
  role: any;
  carrierArray: any = [];
  carrierArray2:any;
  isAddChange: boolean = true;
  clientName: string;
  pli: string;
  carriers: string;
  eprId:any;
  ssoeEnabled: any;
  carrierData:any = [];
  secondTable = false;
  alreadyCalled = false;
  questionnnaireFieldValues:any;
  constructor(private store: Store<AppState>, private usersService: UsersService,
     private eprService: EprService,private questionnaireSettingsService: QuestionnaireSettingsService) { }

  ngOnInit(): void {
    this.usersService.userRole.subscribe(role => {
      if (role) {
        this.role = role;
      }
    });

    combineLatest([this.clientName$, this.pli$, this.carriers$, this.drivers$, this.eprId$, this.questionnnaire$]).subscribe((data) => {
      this.clientName = data[0];
      this.pli = data[1];
      this.carriers = data[2];
      this.questionnnaireFieldValues = data[5]
      this.eprId = data[4]
      this.ssoeEnabled = data[3].consolidateProfileLogic
      if(this.carriers){
        if(this.carriers.includes(",")){
          this.carrierArray = this.carriers.split(",");
        }else{
          this.carrierArray = [this.carriers]
        }

      }
      //Makes sure EPR is fully loaded before calling for questions from store
      if(!data.includes('') && !data.includes(null) && data[5] !== null){
            if(!this.alreadyCalled){
              this.getThresholdQuestions()
            }
      }
          
        
    })
      
  

  
   
   
    this.eligAgreementForm.addControl('thresholdNotes', new UntypedFormControl('')); 
    this.eligAgreementForm.addControl('changeLog', this.changeLogFormArray);
    this.thresholdNotes$.subscribe(res=>{
      this.eligAgreementForm.controls['thresholdNotes'].patchValue(res)
    })
    this.changeLogSubscription = this.changeLog$.subscribe(result=>{
      this.updatedChangeLog = result;
      

    })
    this.fileParamFields.forEach(fileParamField => {
      this.eligAgreementForm.addControl(fileParamField, new UntypedFormControl(''));
    });
    this.memberIdentificationFields.forEach(memberIdentificationField => {
      this.eligAgreementForm.addControl(memberIdentificationField, new UntypedFormControl(''));
    });
    this.memberTermsAndMovementFields.forEach(memberTAMField => {
      this.eligAgreementForm.addControl(memberTAMField, new UntypedFormControl(''));
    });
    this.additionalConsiderationsFields.forEach(addtlConsideration => {
      this.eligAgreementForm.addControl(addtlConsideration, new UntypedFormControl(''));
    });
  

  
    

    if (this.readOnly) {
      this.eligAgreementForm.disable();
    } else if (!this.readOnly && (this.role === Permission.FILETRACKER || this.role === Permission.VISITOR)) {
      this.eligAgreementForm.disable();
    } else {
      this.isAddChange = false;
      this.eligAgreementForm.enable();
    }

    // Populate File Parameters
    this.fileNamingProdSubscription = this.fileNamingProd$.subscribe(result => {
      this.eligAgreementForm.controls['fileNamingProd'].patchValue(result);
    });
    this.grpFileNamingProdSubscription = this.grpFileNamingProd$.subscribe(result => {
      this.eligAgreementForm.controls['grpFileNamingProd'].patchValue(result);
    });
    this.fileNamingTestSubscription = this.fileNamingTest$.subscribe(result => {
      this.eligAgreementForm.controls['fileNamingTest'].patchValue(result);
    });
    this.fileNamingCompareSubscription = this.fileNamingCompare$.subscribe(result => {
      this.eligAgreementForm.controls['fileNamingCompare'].patchValue(result);
    });

    // Populate Member Identification
    this.cEPRFamIDSubscription = this.cEPRFamID$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRFamID'].patchValue(result);
    });
    this.cEPRFAltIdCodeSubscription = this.cEPRFAltIdCode$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRFAltIdCode'].patchValue(result);
    });
    this.cEPRFPersonCodeSubscription = this.cEPRFPersonCode$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRFPersonCode'].patchValue(result);
    });
    this.cEPR3rdIDSubscription = this.cEPR3rdID$.subscribe(result => {
      this.eligAgreementForm.controls['cEPR3rdID'].patchValue(result);
    });

    // Populate Member Terms and Movements
    this.cEPRTrmDates4AllMbrsSubscription = this.cEPRTrmDates4AllMbrs$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRTrmDates4AllMbrs'].patchValue(result);
    });
    this.cEPRDepImpTrmsSubscription = this.cEPRDepImpTrms$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRDepImpTrms'].patchValue(result);
    });
    this.cEPRImpTrmsSubscription = this.cEPRImpTrms$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRImpTrms'].patchValue(result);
    });
    this.cEPRImpTrm2Subscription = this.cEPRImpTrm2$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRImpTrm2'].patchValue(result);
    });
    this.cEPRTMRSubscription = this.cEPRTMR$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRTMR'].patchValue(result);
    });
    this.cEPRTMR2Subscription = this.cEPRTMR2$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRTMR2'].patchValue(result);
    });
    this.cEPRTMR3Subscription = this.cEPRTMR3$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRTMR3'].patchValue(result);
    });
    this.cEPRTMR4Subscription = this.cEPRTMR4$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRTMR4'].patchValue(result);
    });
    this.cEPRTMRIgnFutureTrmSubscription = this.cEPRTMRIgnFutureTrm$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRTMRIgnFutureTrm'].patchValue(result);
    });
    this.cEPRTMRInclFtrEffSubscription = this.cEPRTMRInclFtrEff$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRTMRInclFtrEff'].patchValue(result);
    });
    this.cEPRHistMthdSubscription = this.cEPRHistMthd$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRHistMthd'].patchValue(result);
    });

    // Populate Additional Considerations
    this.cEPRSrvvngSpsSubscription = this.cEPRSrvvngSps$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRSrvvngSps'].patchValue(result);
    });
    this.cEPRCOBSubscription = this.cEPRCOB$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRCOB'].patchValue(result);
    });
    this.cEPRGrpSuspSubscription = this.cEPRGrpSusp$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRGrpSusp'].patchValue(result);
    });
    this.cEPRSuspenseSubscription = this.cEPRSuspense$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRSuspense'].patchValue(result);
    });
    this.cEPRFamonCardsSubscription = this.cEPRFamonCards$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRFamonCards'].patchValue(result);
    });
    this.cERPBatchOvrSubscription = this.cERPBatchOvr$.subscribe(result => {
      this.eligAgreementForm.controls['cERPBatchOvr'].patchValue(result);
    });
    this.cEPRCHOnlySubscription = this.cEPRCHOnly$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRCHOnly'].patchValue(result);
    });
    this.cEPRAutoCAGSubscription = this.cEPRAutoCAG$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRAutoCAG'].patchValue(result);
    });
    this.cEPRStandaloneDepsSubscription = this.cEPRStandaloneDeps$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRStandaloneDeps'].patchValue(result);
    });
    this.cEPRSplitFamSubscription = this.cEPRSplitFam$.subscribe(result => {
      this.eligAgreementForm.controls['cEPRSplitFam'].patchValue(result);
    });
    
    
    
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.readOnly) {
      this.eligAgreementForm.disable();
      this.changeLogFormArray.patchValue(this.updatedChangeLog)
    } else if (!this.readOnly && (this.role === Permission.FILETRACKER || this.role === Permission.VISITOR)) {
      this.eligAgreementForm.disable();
    } else {
      this.isAddChange = false;
      this.eligAgreementForm.enable();
    }
    

  }
  getThresholdQuestions(){
     this.showSpinner$ = true;
     this.alreadyCalled = true;
     this.questionnaireSettingsService.getQuestionnaireSettingsRowData().subscribe(res=>{
       this.thresholdFields = res.filter(item=> item.section === "Threshold")
       let flattenConfig = questionnaireFormConfig.flat()
       this.thresholdFields.forEach(rec=>{
        flattenConfig.filter(field=> {
         if(field['id'] === rec.id){
           rec.value = this.questionnnaireFieldValues[field.controlName]
           rec = this.setNoShowParam(rec);
         }
       })
        })
        if(this.ssoeEnabled){
         this.getCarrierLevelData();
        }else{
          this.showSpinner$ = false
        }
      })
  }
  getCarrierLevelData(){
    //Threshold fields
    this.showSpinner$ = true;
    if(this.eprId){
      this.eprService.getCarrierLevel(this.eprId,this.pli).subscribe(resp=>{
    
        if(resp){
          if(resp.status === "SUCCESS"){
            this.carrierData = resp?.response?.questionnaireCarrierLevelList;
            this.carrierData = this.carrierData.filter(item=> item.questionSection === "Threshold" && item.answer)  
            
            this.setCarrierValues();
          
            
          }else{
            this.showSpinner$ = false;
            return
          }
        }
        
        
    })
    }
    
      
     
  }
  setCarrierValues(){
    //Threshold Fields
    //creates a property named after the carrier with the value for easier html access
    this.thresholdFields.forEach(field=>{
      let questionValues =  this.carrierData.filter(data=> data.questionId === field.id
        );

      let  carrierValues = questionValues.filter(value=>{ return value.pliOrCarrier !== this.pli})
      carrierValues.forEach(carrier=> field[carrier.pliOrCarrier.trim()] = carrier.answer)
      field = this.setNoShowParam(field)
       
    })
    //Checks for empty carrier columns and removes them
    this.carrierArray = this.carrierArray.filter(carrier => {
       let hasValue = false;
       this.thresholdFields.forEach(field =>{
        if(field[carrier.trim()]){
          hasValue = true;
          return
        }
      })
      return hasValue

    })
    //Wrap table if too many carriers *Really Rare
    if(this.carrierArray.length> 5){
      this.carrierArray2 = this.carrierArray.splice(5);
      this.carrierArray = this.carrierArray.splice(0,6);
    }

    this.showSpinner$ = false;
  }
  setNoShowParam(field){
    //Removes empty Threshold rows
    let updatedField
  if(field.inputType){
    return field
  }
   this.thresholdFields.forEach(field=>{
      field.noShow = false;
    if(field.value){
        updatedField = field;
        return
      }else{
        if(this.carrierArray.length > 0){
          for(let carrier of this.carrierArray){
            if(!!field[carrier.trim()]){ 
              updatedField = field;
              return
            }
          }  
        }
        field.noShow = true;
        updatedField = field;
        return
        
      }

   })
      
    
    
    return updatedField
  }

  getNewChangeGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      dateCreated: new UntypedFormControl('', Validators.required),
      change: new UntypedFormControl('', Validators.required)
    });
  }
 
 
  addChange(): void {
    const newChange = this.getNewChangeGroup();
    const changeLog = this.eligAgreementForm.get('changeLog') as UntypedFormArray;
    changeLog.push(newChange);
   
  }

  deleteChangeLogEntry(index: number) {
    const changelog = this.eligAgreementForm.get('changeLog') as UntypedFormArray;
    changelog.removeAt(index);
    this.store.dispatch(new DeleteChangeLogAction(index));
  }

  download(modal = false): void {
    let eaDocument;
    if(modal){
      eaDocument = this.ea_Form
    }else{
      eaDocument = document.getElementById('ea-form');
    }

    const eaDocumentCopy = eaDocument.cloneNode(true) as HTMLElement;
    const button = eaDocumentCopy.getElementsByClassName('cvs-btn-text-only')[0];
    const emailBtn = eaDocumentCopy.getElementsByClassName('cvs-btn-email')[0];
    const closeBtn = eaDocumentCopy.getElementsByClassName('cvs-btn-close')[0];
    button?.remove();
    emailBtn?.remove();
    if(this.closeIcon){
      closeBtn.remove();
    }


    const fileOptions = {
      margin: [10, 5, 10, 5],
      name: 'output.pdf',
      enableLinks: true,
      image: { type: 'jpeg' },
      pagebreak: { mode: 'css' },
      html2canvas: { dpi: 192 },
      jsPDF: { orientation: 'portrait', format: 'letter' }
    };

    this.html2pdf.from(eaDocumentCopy).set(fileOptions).save('EA.pdf');
    this.html2pdf = html2pdf(); // Set the variable to a fresh instance to alleviate caching issues within the library
  }

  public get emailBody(): string {
    const bodyText = `Attached you will find the Client Eligibility Processing Requirement document. This includes information regarding how CVS Health will handle your file transmission, including data such as member IDs, new members, terminations, thresholds and additional file processing information. This data was captured during our eligibility requirements gathering. %0A%0AIt’s important that you review this information to ensure CVS Health is meeting your requirements when processing your eligibility files. %0AIf you agree, please reply to this email with your approval. However, if you would like to discuss anything feel free to set up a conference call and we will review and make any changes needed. Additionally, as changes are made going forward, we will provide you with a new document for approval.`;
    const subject = `${this.clientName ? this.clientName?.trim() : ''}${this.pli ? ' | ' + this.pli?.trim() : ''}${this.carriers ? ' | ' + this.carriers?.trim() : ''} Eligibility Processing Requirements`;
    return `mailto:?subject=${subject}&cc=EligAdminApprovals&body=${bodyText}`;
  }

  ngOnDestroy(): void {
    this.thresholdFields = [];
    this.alreadyCalled = false;
    this.fileNamingProdSubscription.unsubscribe();
    this.grpFileNamingProdSubscription.unsubscribe();
    this.fileNamingTestSubscription.unsubscribe();
    this.fileNamingCompareSubscription.unsubscribe();
    this.cEPRFamIDSubscription.unsubscribe();
    this.cEPRFAltIdCodeSubscription.unsubscribe();
    this.cEPRFPersonCodeSubscription.unsubscribe();
    this.cEPR3rdIDSubscription.unsubscribe();
    this.cEPRTrmDates4AllMbrsSubscription.unsubscribe();
    this.cEPRDepImpTrmsSubscription.unsubscribe();
    this.cEPRImpTrmsSubscription.unsubscribe();
    this.cEPRImpTrm2Subscription.unsubscribe();
    this.cEPRTMRSubscription.unsubscribe();
    this.cEPRTMR2Subscription.unsubscribe();
    this.cEPRTMR3Subscription.unsubscribe();
    this.cEPRTMR4Subscription.unsubscribe();
    this.cEPRTMRIgnFutureTrmSubscription.unsubscribe();
    this.cEPRTMRInclFtrEffSubscription.unsubscribe();
    this.cEPRHistMthdSubscription.unsubscribe();
    this.cEPRSrvvngSpsSubscription.unsubscribe();
    this.cEPRCOBSubscription.unsubscribe();
    this.cEPRGrpSuspSubscription.unsubscribe();
    this.cEPRSuspenseSubscription.unsubscribe();
    this.cEPRFamonCardsSubscription.unsubscribe();
    this.cERPBatchOvrSubscription.unsubscribe();
    this.cEPRCHOnlySubscription.unsubscribe();
    this.cEPRAutoCAGSubscription.unsubscribe();
    this.cEPRStandaloneDepsSubscription.unsubscribe();
    this.cEPRSplitFamSubscription.unsubscribe();
  }
}
