import { state } from '@angular/animations';
import { id } from './../test-utils/QuestionnaireFieldHyperlinks';
import { Action } from '@ngrx/store';
import {addItemToArrayInArray, removeItemInArray} from '../helpers/NgRxHelpers';

import {
  LOAD_MSG_DATA_SET_SUCCESS,
  DELETE_MSG_DATA_SET,
  DELETE_MSG_DATA_SET_SUCCESS,
  SAVE_MSG_DATA_SET_SUCCESS,
  Actions
} from './../actions/msg-supress.action';

export interface State {
datalist: any;
}

export const initialState: State = {
  datalist: {
    id: null,
    message: null
  } as any

};

export function reducer(st = initialState, action: Actions): State {
  
  switch (action.type) {
    case LOAD_MSG_DATA_SET_SUCCESS:
    return {
      datalist: action.payload
    };

   
    case SAVE_MSG_DATA_SET_SUCCESS:
      return {
      datalist: action.payload
    };

    default:
      return st;
  }
}

export const getDataListOptions = (dataListstate: State) => dataListstate.datalist;
