<mat-select multiple [(ngModel)]="values" [disabled]="disabled">
  <mat-option *ngFor="let option of customOptions"
              [value]="option"
              (click)="selectOption()"
  >{{option}}</mat-option>
  <mat-option (click)="selectCustomOption(customOption.value)"
              [value]="customOption.value">
    <input #customOption
           (click)="$event.stopPropagation()"
           (keydown)="$event.stopPropagation()"
           type="text">
  </mat-option>
</mat-select>
