import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {DataManagementService} from "../../../services/data-management.service";
import {MessageSupressionService} from "../../../services/message-supression.service";

@Component({
  selector: 'app-message-code-popup',
  templateUrl: './message-code-popup.component.html',
  styleUrls: ['./message-code-popup.component.scss']
})
export class MessageCodePopupComponent implements OnInit {
  selectedMessage: any;
  messages:any=[];
  filteredMessages: any;

    constructor(private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) data , public dialogRef: MatDialogRef<MessageCodePopupComponent>,
                private msgSupressService: MessageSupressionService) {
    }
  ngOnInit() {
    this.msgSupressService.getReportingMessageCodes().subscribe(res => {
      if(res && res.length && res.length> 0) {
        this.messages = res;
        if (this.messages) {
          this.msgSupressService.getReportingMessageTypesRows().subscribe(res1 => {
            if (res1 && res1.length && res1.length > 0) {
              this.messages.forEach(message =>{
                const match= res1.find(t=> t.id === message.reportType);
                if(match){
                  message.reportType= match.message;
                }
              })
            }
          });
          this.filteredMessages = this.messages;
        }
      }
    });
    }



  onCancel(event:any) {
    this.dialogRef.close();
    event.stopPropagation();
  }
  closeModal(event:any) {
    this.dialogRef.close();
    event.stopPropagation();
  }
  onSelect(event:any) {
    this.dialogRef.close(this.selectedMessage);
    event.stopPropagation();
  }

  filterData(event: any) {
    let searchTerm = event.target.value?.toLowerCase();
    this.filteredMessages = this.messages;
    if (searchTerm != undefined) {
      //"message","reportType","messageDescription"
      this.filteredMessages= this.messages.filter((msg) =>{
        if(
        msg.message?.toLowerCase().includes(searchTerm) ||
        msg.reportType?.toLowerCase().includes(searchTerm )||
        msg.messageDescription?.toLowerCase().includes(searchTerm )
      ){
        return msg;
      }
      })

    }
  }
}
