
<div class="modalBox" style="max-width:25rem">
<h2 mat-dialog-title style="padding-bottom: 1rem;">{{providedInfo.title}}  
  <button class="xButton" (click)="close()"
  style="cursor:pointer;">
  <mat-icon class="cag-icon" svgIcon="close-btn--s"></mat-icon>
</button>
  </h2>

<ng-container [formGroup]="form">
<mat-dialog-content  class="">

    <ng-container *ngIf="providedInfo.type=== 'add_record'  || providedInfo.type === 'rename_record'" class="addRecord">
        <mat-form-field class="col-md-12">
          <label for="recordType">Name of Record Type*</label>
            <input matInput placeholder="" formControlName="recordType"
            appAlphaNumeric>
        </mat-form-field>
        <mat-form-field class="col-md-12">
          <label for="recordName">Secondary Label*</label>
            <input matInput placeholder="" formControlName="recordName"
            appAlphaNumeric>
        </mat-form-field>
        <div class="col-md-12" style="margin-bottom:10px">
          <label for="fileType">File Type*</label>
          <mat-radio-group aria-label="File Type*" formControlName="fileType">
            <mat-radio-button value="member" name="fileType" >Member</mat-radio-button>
            <mat-radio-button value="group" name="fileType" >Group</mat-radio-button>
          </mat-radio-group>
      </div>
      <div class="col-md-12">
        <label for="includeInSSOE">Include in SSOE JSON*</label>
        <mat-radio-group aria-label="Include in SSOE JSON*" formControlName="includeInSsoe">
          <mat-radio-button value="yes" name="includeInSsoe" >Yes</mat-radio-button>
          <mat-radio-button value="no" name="includeInSsoe" >No</mat-radio-button>
        </mat-radio-group>
    </div>
    </ng-container>


    <ng-container *ngIf="providedInfo.type=== 'add_file_layout'">

        <mat-form-field class="col-md-12">
            <input matInput placeholder="Field Number" formControlName="fieldNumber"
            >
            <mat-error *ngIf="!form.value.fieldNumber">This field is required *</mat-error>
        </mat-form-field>
        <mat-form-field class="col-md-12">
            <input matInput placeholder="Field Name" formControlName="fieldName"
            >
            <mat-error *ngIf="!form.value.fieldName">This field is required *</mat-error>
        </mat-form-field>
        <mat-form-field class="col-md-12">
            <input matInput placeholder="Position"  type="number" formControlName="position">
            <mat-error *ngIf="!form.value.position">This field is required *</mat-error>
        </mat-form-field>
        <mat-form-field class="col-md-12">
            <input matInput placeholder="Length" type="number" formControlName="length">
            <mat-error *ngIf="!form.value.length">This field is required *</mat-error>
        </mat-form-field>
        <mat-form-field class="col-md-12">
            <input matInput placeholder="Use" formControlName="use"
            >
        </mat-form-field>
        <mat-form-field class="col-md-12">
            <input matInput placeholder="Type" formControlName="type"
            >
        </mat-form-field>
        <mat-form-field class="col-md-12">
            <input matInput placeholder="Field Information" formControlName="fieldInformation">
        </mat-form-field>
        <mat-form-field class="col-md-12">
            <input matInput placeholder="Internal Notes" formControlName="internalNotes">
        </mat-form-field>
        <mat-form-field class="col-md-12">
            <input matInput placeholder="Reject Level" formControlName="rejectLevel">
        </mat-form-field>
        <mat-form-field class="col-md-12">
          <mat-select matInput placeholder="Field Action" formControlName="fieldActions" multiple>
            <mat-optgroup disabled class="mat-optgroup-header">
              <div class="label-container">
                  <span class="mat-optgroup-label">Field Actions</span>
                  <span class="mat-optgroup-label" style="margin-left: 25px;">Default</span>
              </div>
  
            </mat-optgroup>
            <mat-option *ngFor="let item of fieldActionsData" [value]="item" [disabled]="form.get('fieldActionsDefault').value === item.id">
              <div style="width: 150px;overflow: hidden;text-overflow: ellipsis;">{{item.message}}</div>
              <mat-radio-button style="padding:0px 30px;" [value]="item.id" [checked]="ischeckBoxChecked(item, 'fieldActionsDefault')" [disabled]="!isSelected(item, 'fieldActions')"
              (click)="HandleRadioEvent($event)" (change)="radioChange($event, 'fieldActionsDefault')"></mat-radio-button>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-md-12">
        <input matInput placeholder="Action Values" formControlName="actionValues">
        </mat-form-field>
        <mat-form-field class="col-md-12">
          <mat-select matInput placeholder="Validation" formControlName="fieldValidation" multiple>
            <mat-optgroup disabled class="mat-optgroup-header">
              <div class="label-container">
                  <span class="mat-optgroup-label">Validation</span>
                  <span class="mat-optgroup-label" style="margin-left: 25px;">Default</span>
              </div>
  
            </mat-optgroup>
            <mat-option *ngFor="let item of validationsData" [value]="item" [disabled]="form.get('validationDefault').value === item.id">
              <div style="width: 150px;overflow: hidden;text-overflow: ellipsis;">{{item.message}}</div>
              <mat-radio-button style="padding:0px 30px;" [value]="item.id" [checked]="ischeckBoxChecked(item, 'validationDefault')" [disabled]="!isSelected(item, 'fieldValidation')"
              (click)="HandleRadioEvent($event)" (change)="radioChange($event, 'validationDefault')"></mat-radio-button>
            </mat-option>
          </mat-select>
        </mat-form-field>
      <mat-form-field class="col-md-12">
        <input matInput placeholder="Validation Values" formControlName="fieldValues">
      </mat-form-field>
      

      <mat-form-field class="col-md-12">
        <mat-select matInput placeholder="Reject Actions" formControlName="rejectActions" multiple>
          <mat-optgroup disabled class="mat-optgroup-header">
            <div class="label-container">
                <span class="mat-optgroup-label">Reject Actions</span>
                <span class="mat-optgroup-label" style="margin-left: 25px;">Default</span>
            </div>

          </mat-optgroup>
          <mat-option *ngFor="let item of rejectActionsData" [value]="item" [disabled]="form.get('rejectActionsDefault').value === item.id">
            <div style="width: 150px;overflow: hidden;text-overflow: ellipsis;">{{item.message}}</div>
            <mat-radio-button style="padding:0px 30px;" [value]="item.id" [checked]="ischeckBoxChecked(item, 'rejectActionsDefault')" [disabled]="!isSelected(item, 'rejectActions')"
            (click)="HandleRadioEvent($event)"  (change)="radioChange($event, 'rejectActionsDefault')"></mat-radio-button>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-12">
        <input matInput placeholder="Reject Action Values" formControlName="rejectActionValues">
      </mat-form-field>
      <mat-form-field class="col-md-12">
            <input matInput placeholder="Profile" formControlName="profile">
        </mat-form-field>
        <mat-form-field class="col-md-12">
            <input matInput placeholder="Member Detail" formControlName="memberDetails">
        </mat-form-field>

    </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="row actionButtons">
    <button class="mat-raised-button col-4"style="padding: 8px 20px;" (click)="close()">Close</button>
    <button class="mat-raised-button mat-primary col-4"style="padding: 8px 20px;" (click)="save()">Save</button>
</mat-dialog-actions>

</ng-container>
</div>
