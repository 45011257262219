import {
  Actions,
  POPULATE_ALL_CHECKLIST_FIELDS_ENABLED_STATES,
  POPULATE_CHECKLIST_FIELDS_ENABLED_STATE,
  TOGGLE_FIELD_ENABLED_STATUS
} from '../actions/checklist-fields-enabled.action';
import {removeItemInArray, replaceItemsInArray} from '../helpers/NgRxHelpers';
import {ADD_PROJECT_TRACKER, CLEAR_EPR_DATA, DELETE_PROJECT_TRACKER, COPY_EPR, SELECT_PROJECT} from '../actions/epr.action';

export class ChecklistFieldsEnabledState {
  discActDate: boolean;
  bSRActDate: boolean;
  fileLayoutActDate: boolean;
  pLIActDate: boolean;
  grpPLISetup: boolean;
  hEATActDate: boolean;
  cOLAActDate: boolean;
  libraryActDate: boolean;
  fTPFormLoadRptsCmpltDte: boolean;
  clntCstmReqSignOffDte: boolean;
  sendToJimDate: boolean;
  clientCodeVerifyDate: boolean;
  prePreCpltDate: boolean;
  preLoadActDate: boolean;
  stageActDate: boolean;
  grpStageCpltDate: boolean;
  requiredMemFieldsActDate: boolean;
  cDDCmpltDte: boolean;
  cDDCarLstCmpltDte: boolean;
  xwalkProfileActDate: boolean;
  iDCardShellActDate: boolean;
  outQActDate: boolean;
  rJSCompDate: boolean;
  crtCAGListCmpltDate: boolean;
  filTrkrOnBrdCmpltDte: boolean;
  fastlinkActDate: boolean;
  fastlinkGroupDate: boolean;
  sHRTIDSRCHActDate: boolean;
  EZEConfigActDate: boolean;
  cMFlagReviewDate: boolean;
  cMSignOffDate: boolean;
  cMStatus: boolean;
  reqTstFileCompDate: boolean;
  tstFileRecCompDate: boolean;
  gISTestCmpltDate: boolean;
  prePreTstCpltDate: boolean;
  testVisualReviewCmpltDate: boolean;
  loadTstFileCompDate: boolean;
  fourrxIDconfigverifydate: boolean;
  clientTstSignOffCmpltDte: boolean;
  vrfyPlanDesignQL: boolean;
  iCACoreIDOffset: boolean;
  iCACoreAltOffset: boolean;
  iCAXwalkIDField: boolean;
  iCAEligSettings: boolean;
  iCAMatchRule: boolean;
  iCANetworkSettings: boolean;
  iCAPrimIDChoice: boolean;
  vrfyCAGProcessFlags: boolean;
  qLFlagCompDate: boolean;
  eDWFlgCompDate: boolean;
  plansCompDate: boolean;
  bPGCompDate: boolean;
  vrfyXwlkAvCmpltDte: boolean;
  iDCT4CasetoIFCmpltDte: boolean;
  reconCmpltDate: boolean;
  compareCmpltDate: boolean;
  obtApprv2LoadCmpltDt: boolean;
  baselineCompDate: boolean;
  verifyBLLoadCompDate: boolean;
  fourrxscreentoannacmpltdate: boolean;
  bL2QLCompDate: boolean;
  reqManCrdFileCmpltDte: boolean;
  qCManCrdFileCmpltDte: boolean;
  manCrdFileSntVendorCmpltDte: boolean;
  qCCardProofCmpltDate: boolean;
  reqAutoRunCdCmpltDt: boolean;
  claimsVerificationCompDate: boolean;
  verifyLiveLoadCompDate: boolean;
}

const defaultChecklistFieldsEnabledState: ChecklistFieldsEnabledState = {
  discActDate: true,
  bSRActDate: true,
  fileLayoutActDate: true,
  pLIActDate: true,
  grpPLISetup: true,
  hEATActDate: true,
  cOLAActDate: true,
  libraryActDate: true,
  fTPFormLoadRptsCmpltDte: true,
  clntCstmReqSignOffDte: true,
  sendToJimDate: true,
  clientCodeVerifyDate: true,
  prePreCpltDate: true,
  preLoadActDate: true,
  stageActDate: true,
  grpStageCpltDate: true,
  requiredMemFieldsActDate: true,
  cDDCmpltDte: true,
  cDDCarLstCmpltDte: true,
  xwalkProfileActDate: true,
  iDCardShellActDate: true,
  outQActDate: true,
  rJSCompDate: true,
  crtCAGListCmpltDate: true,
  filTrkrOnBrdCmpltDte: true,
  fastlinkActDate: true,
  fastlinkGroupDate: true,
  sHRTIDSRCHActDate: true,
  EZEConfigActDate: true,
  cMFlagReviewDate: true,
  cMSignOffDate: true,
  cMStatus: true,
  reqTstFileCompDate: true,
  tstFileRecCompDate: true,
  gISTestCmpltDate: true,
  prePreTstCpltDate: true,
  testVisualReviewCmpltDate: true,
  loadTstFileCompDate: true,
  fourrxIDconfigverifydate: true,
  clientTstSignOffCmpltDte: true,
  vrfyPlanDesignQL: true,
  iCACoreIDOffset: true,
  iCACoreAltOffset: true,
  iCAXwalkIDField: true,
  iCAEligSettings: true,
  iCAMatchRule: true,
  iCANetworkSettings: true,
  iCAPrimIDChoice: true,
  vrfyCAGProcessFlags: true,
  qLFlagCompDate: true,
  eDWFlgCompDate: true,
  plansCompDate: true,
  bPGCompDate: true,
  vrfyXwlkAvCmpltDte: true,
  iDCT4CasetoIFCmpltDte: true,
  reconCmpltDate: true,
  compareCmpltDate: true,
  obtApprv2LoadCmpltDt: true,
  baselineCompDate: true,
  verifyBLLoadCompDate: true,
  fourrxscreentoannacmpltdate: true,
  bL2QLCompDate: true,
  reqManCrdFileCmpltDte: true,
  qCManCrdFileCmpltDte: true,
  manCrdFileSntVendorCmpltDte: true,
  qCCardProofCmpltDate: true,
  reqAutoRunCdCmpltDt: true,
  claimsVerificationCompDate: true,
  verifyLiveLoadCompDate: true,
};

export interface State {
  selectedProjectIndex: number;
  checklistFieldEnabledStates: ChecklistFieldsEnabledState[];
}

export const initialState: State = {
  selectedProjectIndex: 0,
  checklistFieldEnabledStates: [defaultChecklistFieldsEnabledState]
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case TOGGLE_FIELD_ENABLED_STATUS:
      const enabledStatuses = state.checklistFieldEnabledStates[state.selectedProjectIndex];
      const updatedEnabledStatuses = {...enabledStatuses, [action.payload]: !enabledStatuses[action.payload]};

      return {
        ...state,
        checklistFieldEnabledStates: replaceItemsInArray(state.checklistFieldEnabledStates,
          state.selectedProjectIndex, updatedEnabledStatuses)
      };

    case POPULATE_CHECKLIST_FIELDS_ENABLED_STATE:
      return {
        ...state,
        checklistFieldEnabledStates: replaceItemsInArray(state.checklistFieldEnabledStates, state.selectedProjectIndex, action.payload)
      };

    case POPULATE_ALL_CHECKLIST_FIELDS_ENABLED_STATES:
      return {
        ...initialState,
        checklistFieldEnabledStates: action.payload
      };

    case ADD_PROJECT_TRACKER:
      return {
        selectedProjectIndex: 0,
        checklistFieldEnabledStates: [defaultChecklistFieldsEnabledState, ...state.checklistFieldEnabledStates],
      };

    case DELETE_PROJECT_TRACKER:
      return {
        selectedProjectIndex: 0,
        checklistFieldEnabledStates: removeItemInArray(state.checklistFieldEnabledStates, action.iteration)
      };

    case SELECT_PROJECT:
      return {...state,
        selectedProjectIndex: action.payload
      };

    case CLEAR_EPR_DATA:
      return initialState;

    case COPY_EPR:
      return initialState;

    default:
      return state;
  }
}

export const getChecklistFieldEnabledStatus = (state: State, controlName: string) => {
  return state.checklistFieldEnabledStates[state.selectedProjectIndex][controlName];
};
export const getAllChecklistFieldsEnabledStatus = (state: State) => {
  return state.checklistFieldEnabledStates[state.selectedProjectIndex];
};
