import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import * as filetracker from '../actions/filetracker.action';
import {CVSAlertService, CVSAlertType} from 'angular-component-library';
import {Router} from '@angular/router';
import {FiletrackerService} from '../services/filetracker.service';
import {Filetracker} from '../models/Filetracker';
import * as webeprfeeds from '../actions/web-eprfeed.action';
import {WebEprFeed} from '../models/WebEprFeed';
import {ResetProjectIndicesActions} from '../actions/epr.action';
import {NoopAction} from '../actions/noop.action';
import {Contact} from '../models/Contact';
import {id} from '../test-utils/QuestionnaireFieldHyperlinks';
import {DELETE_EPRFEED, DeleteEprFeedAction, DeleteEprFeedSuccessAction} from '../actions/epr-feed.action';
import {DELETE_CONTACT, DeleteContactSuccessAction} from '../actions/filetracker.action';
import {EprService} from "../services/epr.service";

@Injectable()
export class FileTrackerEffects {

  loadAllFiletracker$ = createEffect(() => this.actions$.pipe(
    ofType(filetracker.LOAD_ALL_FILETRACKER),
    mergeMap(() => this.filetrackerService.getAll(this.filetrackerService.userId,this.filetrackerService.role,false)
      .pipe(
        map(filetrackerValue => ({ type: filetracker.LOAD_ALL_FILETRACKER_SUCCESS, payload: filetrackerValue as Filetracker[] })),
        catchError(() => of({type: filetracker.LOAD_ALL_FILETRACKER_FAILURE}))
      ))
    )
  );
  loadEprFeed$ = createEffect(() => this.actions$.pipe(
    ofType(filetracker.LOAD_FILETRACKER),
    mergeMap((action) => this.filetrackerService.getFiletracker(action['payload'])
      .pipe(
        map(filetrackervalues => ({ type: filetracker.LOAD_FILETRACKER_SUCCESS, payload: filetrackervalues as Filetracker })),
        tap(epr => {
          this.eprService.dashboardRowClicked.next(false);
          this.router.navigateByUrl('filetracker');
        }),
        catchError(error => {
          return of(new NoopAction());
        })
      ))
    )
  );
  saveFiletracker$ = createEffect(() => this.actions$.pipe(
    ofType(filetracker.SAVE_FILETRACKER),
    mergeMap(action => this.filetrackerService.saveFiletracker(action['payload'], 'save')
      .pipe(
        map(filetrackervalue => ({ type: filetracker.SAVE_FILETRACKER_SUCCESS, payload: filetrackervalue as Filetracker })),
        catchError(() => of({type: filetracker.SAVE_FILETRACKER_SUCCESS_FAILURE}))
      ))
    )
  );

  updateFiletracker$ = createEffect(() => this.actions$.pipe(
    ofType(filetracker.UPDATE_FILETRACKER),
    mergeMap(action => this.filetrackerService.saveFiletracker(action['payload'], 'update')
      .pipe(
        map(filetrackervalue => ({ type: filetracker.SAVE_FILETRACKER_SUCCESS, payload: filetrackervalue as Filetracker })),
        catchError(() => of({type: filetracker.SAVE_FILETRACKER_SUCCESS_FAILURE}))
      ))
    )
  );


  loadContact$ = createEffect(() => this.actions$.pipe(
    ofType(filetracker.LOAD_CONTACT),
    mergeMap((action) =>
      this.filetrackerService.getContact(action['payload']['id'] ,
        action['payload']['alertType'])
      .pipe(
        map(contactvalues => ({ type: filetracker.LOAD_CONTACT_SUCCESS, payload: contactvalues as Contact })),
        catchError(error => {
          return of(new NoopAction());
        })
      ))
    )
  );

  saveContact$ = createEffect(() => this.actions$.pipe(
    ofType(filetracker.SAVE_CONTACT),
    mergeMap(action => this.filetrackerService.saveContact( action['payload'],  action['payload']['field'])
      .pipe(
        map(contact => ({ type: filetracker.SAVE_CONTACT_SUCCESS, payload: contact as Contact})),
        catchError(() => of({type: filetracker.SAVE_CONTACT_SUCCESS_FAILURE}))
      ))
    )
  );
  editContact$ = createEffect(() => this.actions$.pipe(
    ofType(filetracker.UPDATE_CONTACT),
    mergeMap(action => this.filetrackerService.editContact(action['payload']['contact'], action['payload']['field'])
      .pipe(
        map(contactvalue => ({ type: filetracker.UPDATE_CONTACT_SUCCESS, payload: contactvalue as Contact })),
        catchError(() => of({type: filetracker.UPDATE_CONTACT_SUCCESS_FAILURE}))
      ))
    )
  );

  deleteContact$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteEprFeedAction>(DELETE_CONTACT),
    mergeMap(action => this.filetrackerService.deleteContact(action.payload)
      .pipe(
        map(() => new DeleteContactSuccessAction()),
        tap(() => this.router.navigateByUrl('')),
        catchError(error => {
          return of(new NoopAction());
        })
      )
    )
  ));
  constructor(
    private actions$: Actions,
    private filetrackerService: FiletrackerService,
    private eprService: EprService,
    private router: Router,
  ) { }
}
