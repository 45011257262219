import {Component} from '@angular/core';
import {ICellEditorAngularComp, ICellRendererAngularComp} from 'ag-grid-angular';


@Component({
  selector: 'app-multi-select-cell',
  template: `
    <mat-select multiple [(value)]="selected" >
<mat-option *ngFor="let option of options" [value]="option">{{option}}</mat-option>
</mat-select>
  `,
})

export class PliCarrierMutiSelectCellRendererComponent implements ICellEditorAngularComp {
  options: any;
  selected = null;

  agInit(params: any): void {
    this.options = params?.context?.providedInfo?.options;
    this.selected = params?.value;
  }

  getValue = () => {
    return this.selected;
  };

  afterGuiAttached = () => {
    // if (!this.params.value) {
    //   return;
    // }
    // const [_, day, month, year] = this.params.value.match(
    //   /^(\d{2})\/(\d{2})\/(\d{4})$/
    // );
    // let selectedDate = new Date(year, month - 1, day);

    // this.selectedDate = selectedDate;
  };

 
}