import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HomePageComponent } from './home-page/home-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AppComponent } from './app.component';
import { MyMaterialModule } from './sub-modules/my-material.module';
import { MyACLModule } from './sub-modules/my-acl.module';
import { QuestionnaireComponent } from './epr/questionnaire/questionnaire.component';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EprComponent } from './epr/epr.component';
import { AdminComponent } from './admin/admin.component';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { UserManagementEffects } from './effects/user-management.effects';
import { EprFeedManagementEffects } from './effects/epr-feed.effects';
import { EffectsModule } from '@ngrx/effects';
import { UserInfoFormComponent } from './admin/edit-user/user-info-form.component';
import { DataManagementComponent } from './admin/data-management/data-management.component';
import { DataManagementEffects } from './effects/data-management.effects';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TestingComponent } from './epr/testing/testing.component';
import { ChecklistComponent } from './epr/checklist/checklist.component';
import { GetMdlPipe } from './pipes/get-mdl.pipe';
import { GetUsersPipe } from './pipes/get-users.pipe';
import { EligibilityAgreementComponent } from './epr/eligibility-agreement/eligibility-agreement.component';
import { ConnectFormValuesDirective } from './directives/connect-form-values.directive';
import { ProjectTrackerComponent } from './epr/project-tracker/project-tracker.component';
import { EprEffects } from './effects/epr.effects';
import { DashboardComponent } from './dashboard/dashboard.component';
import { QuestionnaireEffects } from './effects/questionnaire.effects';
import { HeaderComponent } from './epr/header/header.component';
import { ConfirmationDialogContentComponent } from './epr/questionnaire/confirmation-dialog-content/confirmation-dialog-content.component';
import { AddProjectDialogContentComponent } from './epr/project-tracker/add-project-dialog-content/add-project-dialog-content';
import { ChecklistEffects } from './effects/checklist.effects';
import { MatChipsModule } from '@angular/material/chips';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EwfHttpInterceptor } from './EwfHttpInterceptor';
import { AgGridModule } from 'ag-grid-angular';
import {
  AuthCodePKCEService,
  CVSConfirmationDialogModule,
  CVSHttpClient,
  CVSLoaderModule,
  TooltipDirective,
  TooltipModule
} from 'angular-component-library';
import { ReverseArrayPipe } from './helpers/reverseArrayPipe';
import { EprDeactivateGuard } from './guards/EprDeactivateGuard';
import { GetUsersDisplayStringsPipe } from './pipes/get-users-display-strings.pipe';
import { QuillModule } from 'ngx-quill';
import { FreeformMultiselectComponent } from './form-elements/freeform-multiselect/freeform-multiselect.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { DataExtractComponent } from './admin/data-extract/data-extract.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AdminGuard } from './guards/AdminGuard';
import { CookieService } from 'ngx-cookie-service';
import { SettingsComponent } from './admin/settings/settings.component';
import { ConfirmationDialogComponent } from './epr/confirmation-dialogue/confirmation-dialog.component';
import { ChecklistFieldsEnabledEffects } from './effects/checklist-fields-enabled.effects';
import { GetChecklistFieldEnabledValuePipe } from './pipes/get-checklist-field-enabled-value.pipe';
import { ChecklistSettingsComponent } from './admin/settings/checklist-settings/checklist-settings.component';
import { QuestionnaireSettingsComponent } from './admin/settings/questionnaire-settings/questionnaire-settings.component';
import { LabelLinkComponent } from './form-elements/label-link/label-link.component';
import { NumericCellEditorComponent } from './admin/settings/questionnaire-settings/numeric-cell-editor/numeric-cell-editor.component';
import { ThreasholdDialogComponent } from './threshold-dialog/threshold-dialog.component';
import {
  AuthenticationService,
  CodeAuthenticationService,
} from 'angular-component-library';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ExtractEprFeedGuiComponent } from './admin/extract-epr-feed-gui/extract-epr-feed-gui.component';
import { CellRendererComponent } from './admin/extract-epr-feed-gui/cell-renderer.component';
import { WebEprFeedService } from './services/web-epr-feed.service';
import { CustomTooltipComponent } from './admin/extract-epr-feed-gui/custom-tooltip.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RXClaimConfirmationDialogComponent } from './epr/rxclaim-confirmation-dialog/rxclaim-confirmation-dialog.component';
import { FiletrackerDashboardComponent } from './filetracker-dashboard/filetracker-dashboard.component';
import { FiletrackerComponent } from './filetracker/filetracker.component';
import { FileTrackerEffects } from './effects/file-tracker.effects';
import { FiletrackerContactDialogComponent } from './filetracker/filetracker-contact-dialog/filetracker-contact-dialog.component';
import { DeleteBtnCellRendererComponent } from './common/DeleteBtnCellRenderer';
import { EPRFiletrackerComponent } from './epr/epr-file-tracker/epr-file-tracker.component';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MyProcessingFilesComponent } from './epr/my-processing-files/my-processing-files.component';
import { ThresholdConfirmDialogComponent } from './threshold-dialog/threshold-confirm-dialog/threshold-confirm-dialog.component';
import { TranslationsDataGridComponent } from './admin/data-management/translations-data-grid/translations-data-grid.component';
import { ProfileConfirmationDialogComponent } from './epr/profile-confirmation-dialogue/profile-confirmation-dialog.component';
// eslint-disable-next-line max-len
import { RxprofileConfirmationDialogComponent } from './epr/profile-confirmation-dialogue/rxprofile-confirmation-dialogue/rxprofile-confirmation-dialog.component';
import { StatusPopoverComponent } from './dashboard/status-popover/status-popover.component';
import { ThresholdsDataGridComponent } from './admin/data-management/thresholds-data-grid/thresholds-data-grid.component';
import { MsgSupressDataGridComponent } from './admin/data-management/msg-supress-data-grid/msg-supress-data-grid.component';
import { FileLayoutComponent } from './admin/file-layout/file-layout.component';
import { FileLayoutDialogComponent } from './admin/file-layout/file-layout-dialog/file-layout-dialog.component';
import { SubmitConfirmationComponent } from './admin/file-layout/submit-confirmation/submit-confirmation.component';
import { DeleteRecordConfirmationComponent } from './admin/file-layout/delete-record-confirmation/delete-record-confirmation.component';
import { AuditHistoryComponent } from './admin/file-layout/audit-history/audit-history.component';
import { LogicCodeComponent } from './admin/logic-code/logic-code.component';
import { AddLogicComponent } from './admin/logic-code/add-logic/add-logic.component';
import { MapTypeComponent } from './admin/data-management/map-type/map-type.component';
import { AlphaNumericDirective } from './directives/alpha-numeric.directive';
import { DownloadBtnCellRendererComponent } from './common/DownloadBtnCellRenderer';
import { AdminDeactivateGuard } from './guards/AdminDeactivateGuard';
import { MappingDocumentsComponent } from './epr/mapping-documents/mapping-documents.component';
import { MappingGuideComponent } from './epr/mapping-documents/mapping-guide/mapping-guide.component';
import { MappingRequirementsComponent } from './epr/mapping-documents/mapping-requirements/mapping-requirements.component';
import { MappingRecordComponent } from './epr/mapping-documents/mapping-record/mapping-record.component';
import { MappingConfirmationComponent } from './epr/mapping-documents/mapping-confirmation/mapping-confirmation.component';
import { InfoDialogComponent } from './epr/info-dialogue/info-dialog.component';
import { AdminAlertTypeComponent } from './admin/data-management/admin-alert-type/admin-alert-type.component';
import { EditComponent } from './admin/data-management/admin-alert-type/edit/edit.component';
import { LogicCodeModalComponent } from './epr/mapping-documents/mapping-record/logic-code-modal/logic-code-modal.component';
import { ParmMappingComponent } from './admin/parm-mapping/parm-mapping.component';
import { AddNewParameterComponent } from './admin/parm-mapping/add-new-parameter/add-new-parameter.component';
import { AddNewAttributesComponent } from './admin/parm-mapping/add-new-attributes/add-new-attributes.component';
// eslint-disable-next-line max-len
import { SpedmDashboardComponent } from './spedm-dashboard/spedm-dashboard.component';
import { SpedmOnboardingComponent } from './spedm-onboarding/spedm-onboarding.component';
import { SpedmContactDialogComponent } from './spedm-onboarding/spedm-contact-dialog/spedm-contact-dialog.component';
import { SpedmEffects } from './effects/spedm-tracker.effects';
import {
  LogicCodeSelectionModalComponent
} from './epr/mapping-documents/mapping-record/logic-code-selection-modal/logic-code-selection-modal.component';
import { RteMetricsComponent } from './rte/rte-metrics/rte-metrics.component';
import { AddLogicCodeModalComponent } from './epr/mapping-documents/mapping-record/add-logic-code-modal/add-logic-code-modal.component';
import { ParamMapEffects } from './effects/param-map.effects';
import { InformationDialogComponent } from './epr/mapping-documents/common-components/information-dialog/information-dialog.component';
import { MappingImportGuideComponent } from './epr/mapping-documents/mapping-guide/mapping-import-guide/mapping-import-guide.component';
import { EligibilityDialogComponent } from './eligibility-dialog/eligibility-dialog.component';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WebEprfeedEffects } from './effects/web-eprfeed-effects';
import { ClassificationCodeComponent } from "./admin/classification-code/classification-code.component";
import { MatSidenavModule } from '@angular/material/sidenav';
import { OrderByPipe } from './common/orderby.pipe';
import { CagRefBatchUploadComponent } from './dashboard/cagRefBatchUpload/cagRefBatchUpload.component';
import { HistoryComponent } from "./epr/history/history.component";
import { RteComponent } from "./rte/rte.component";
import { RteEprHistoryComponent } from "./rte/rte-epr-history/rte-epr-history.component";
import { VersionControlDataGridComponent } from './admin/data-management/version-control-data-grid/version-control-data-grid.component';
import { OutOfOfficeIconRendererComponent } from './common/OutOfOfficeIconRenderer';
import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { BackupEcCellRendererComponent } from './common/BackupEcCellRenderer';
import { RteSuspenseComponent } from './rte/rte-suspense/rte-suspense.component';
import { SuspenseGridComponent } from './rte/rte-suspense/suspense-grid/suspense-grid.component';
import { SuspenseScheduleComponent } from './rte/rte-suspense/suspense-schedule/suspense-schedule.component';
import { ScheduledEventsGridComponent } from './rte/rte-suspense/scheduled-events-grid/scheduled-events-grid.component';
import { SegDropdownCellRendererComponent } from './rte/rte-suspense/scheduled-events-grid/seg-dropdown-cell-renderer';
import {EprActivateGuard} from "./guards/EprActivateGuard";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {ToolTipComponent} from "./dashboard/tool-tip/tool-tip.component";
import { RecipientTooltipComponent } from './common/TooltipComponent';
import { CopayAccumDashboardComponent } from './copay-accum-dashboard/copay-accum-dashboard.component';
import { CopayAccumOnboardingComponent } from './copay-accum-onboarding/copay-accum-onboarding.component';
import { CopayContactDialogComponent } from './copay-accum-onboarding/copay-contact-dialog/copay-contact-dialog.component';
import {EnvironmentService} from "./common/environment-service/environment.service";
import { FilterItemDirective } from "./admin/logic-code/filter-item.directive";
import { BulletCreaterDirective } from './directives/bullet-creater.directive';
import {ValidationComponent} from "./admin/data-management/validations/validation.component";
import { RejectActionComponent } from './admin/data-management/reject-action/reject-action.component';
import { FieldActionsComponent } from './admin/data-management/field-actions/field-actions.component';
import { EditBtnCellRendererComponent } from './common/EditBtnCellRenderer';
import { AddEditItemPopupComponent } from './admin/data-management/add-edit-item-popup/add-edit-item-popup.component';
import { ReportingMessageTypesComponent } from './admin/data-management/reporting-message-types/reporting-message-types.component';
import { ReportingMessageCodesComponent } from "./admin/data-management/reporting-message-codes/reporting-message-codes.component";
import { AddEditReportingItemPopupComponent } from "./admin/data-management/add-edit-reporting-item-popup/add-edit-reporting-item-popup.component";
import { FormsModule } from '@angular/forms';
import {
  DropdownRendererComponent
} from "./admin/settings/questionnaire-settings/dropdown-renderer/dropdown-renderer.component";
import { EligibilityBtnCellRendererComponent } from './common/EligibilityBtnCellRender';
import { MessageCodePopupComponent } from './admin/file-layout/message-code-popup/message-code-popup.component';
import { GridInputFormRendererComponent } from './common/GirdInputFormControl';
import { GridSelectFormRendererComponent } from './common/GridSelectFormControl';
import { EpruseraccessmodelComponent } from './epr/questionnaire/epruseraccessmodel/epruseraccessmodel.component';
import { UserSettingMfeLibraryModule } from '@ewf-mfe/user-setting-mfe-library-v1';
import { EwfMbrGrpInqMfeLibraryModule } from '@ewf-mfe/ewf-mbr-grp-inq-mfe-library';
import {OnlyNumberDirective} from "./directives/only-number.directive";
import { MatTableExporterModule } from 'mat-table-exporter';
import { PliCarrierModelComponent } from './epr/questionnaire/pli-carrier-model/pli-carrier-model.component';
import { PliCarrierDeleteCellRendererComponent } from './epr/questionnaire/pli-carrier-model/renderer/PliCarrierDeleteCellRenderer';
import {DetailMessageModalComponent} from "./epr/questionnaire/detailMessagesModal/detailMessageModal.component";
import { GridSelectReportFormRendererComponent } from './common/GridSelectReportFormRenderer';
import { DeleteBtnLoadFormatCellRendererComponent } from './common/DeleteBtnLoadFormatCellRenderer';
import { AddReportFormatFieldComponent } from './epr/questionnaire/addReportFormatModal/addReportFormatField.component';
import { MatInputModule } from '@angular/material/input';
import { PliCarrierDateCellRendererComponent } from './epr/questionnaire/pli-carrier-model/renderer/PliCarrierDateCellRenderer';
import { PliCarrierMutiSelectCellRendererComponent } from './epr/questionnaire/pli-carrier-model/renderer/PliCarrierMultiSelectCellRenderer';
import { ProjectConfirmationDialogComponent } from './epr/project-tracker/project-confirmation-dialogue/project-confirmation-dialog.component';
import { DeleteMenuCellRendererComponent } from './common/DeleteMenuCellRenderer.component';



@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    PageNotFoundComponent,
    QuestionnaireComponent,
    EprComponent,
    AdminComponent,
    UserInfoFormComponent,
    UserManagementComponent,
    DataManagementComponent,
    TestingComponent,
    ChecklistComponent,
    GetMdlPipe,
    GetUsersPipe,
    ReverseArrayPipe,
    RecipientTooltipComponent,
    ConnectFormValuesDirective,
    EligibilityAgreementComponent,
    ProjectTrackerComponent,
    HeaderComponent,
    ProjectTrackerComponent,
    DashboardComponent,
    ConfirmationDialogContentComponent,
    InfoDialogComponent,
    AddProjectDialogContentComponent,
    GetUsersDisplayStringsPipe,
    FreeformMultiselectComponent,
    DataExtractComponent,
    SettingsComponent,
    ConfirmationDialogComponent,
    ThresholdConfirmDialogComponent,
    GetChecklistFieldEnabledValuePipe,
    ChecklistSettingsComponent,
    QuestionnaireSettingsComponent,
    LabelLinkComponent,
    NumericCellEditorComponent,
    ExtractEprFeedGuiComponent,
    CellRendererComponent,
    DropdownRendererComponent,
    CustomTooltipComponent,
    RXClaimConfirmationDialogComponent,
    FiletrackerDashboardComponent,
    FiletrackerComponent,
    SpedmOnboardingComponent,
    SpedmContactDialogComponent,
    SpedmDashboardComponent,
    FiletrackerContactDialogComponent,
    EditBtnCellRendererComponent,
    DeleteBtnCellRendererComponent,
    EPRFiletrackerComponent,
    ThreasholdDialogComponent,
    MyProcessingFilesComponent,
    ThresholdConfirmDialogComponent,
    TranslationsDataGridComponent,
    SpedmContactDialogComponent,
    ProfileConfirmationDialogComponent,
    RxprofileConfirmationDialogComponent,
    StatusPopoverComponent,
    ThresholdsDataGridComponent,
    MsgSupressDataGridComponent,
    FileLayoutComponent,
    FileLayoutDialogComponent,
    SubmitConfirmationComponent,
    DeleteRecordConfirmationComponent,
    AuditHistoryComponent,
    LogicCodeComponent,
    AddLogicComponent,
    MapTypeComponent,
    AlphaNumericDirective,
    DownloadBtnCellRendererComponent,
    MappingDocumentsComponent,
    MappingGuideComponent,
    MappingRequirementsComponent,
    MappingRecordComponent,
    MappingConfirmationComponent,
    AdminAlertTypeComponent,
    EditComponent,
    LogicCodeModalComponent,
    ParmMappingComponent,
    AddNewParameterComponent,
    AddNewAttributesComponent,
    LogicCodeSelectionModalComponent,
    LogicCodeModalComponent,
    SpedmDashboardComponent,
    SpedmOnboardingComponent,
    SpedmContactDialogComponent,
    AddLogicCodeModalComponent,
    InformationDialogComponent,
    MappingImportGuideComponent,
    RteMetricsComponent,
    AddLogicCodeModalComponent,
    EligibilityDialogComponent,
    ClassificationCodeComponent,
    OrderByPipe,
    HistoryComponent,
    RteComponent,
    RteMetricsComponent,
    RteEprHistoryComponent,
    VersionControlDataGridComponent,
    UserSettingsComponent,
    OutOfOfficeIconRendererComponent,
    BackupEcCellRendererComponent,
    SegDropdownCellRendererComponent,
    CustomTooltipComponent,
    CagRefBatchUploadComponent,
    RteSuspenseComponent,
    SuspenseGridComponent,
    ScheduledEventsGridComponent,
    SuspenseScheduleComponent,
    ToolTipComponent,
    CopayAccumDashboardComponent,
    CopayAccumOnboardingComponent,
    CopayContactDialogComponent,
    FilterItemDirective,
    BulletCreaterDirective,
    ValidationComponent,
    RejectActionComponent,
    FieldActionsComponent,
    ReportingMessageTypesComponent,
    AddEditItemPopupComponent,
    ReportingMessageCodesComponent,
    AddEditReportingItemPopupComponent,
    EligibilityBtnCellRendererComponent,
    MessageCodePopupComponent,
    GridInputFormRendererComponent,
    GridSelectFormRendererComponent,
    EpruseraccessmodelComponent,
    OnlyNumberDirective,
    PliCarrierModelComponent,
    PliCarrierDeleteCellRendererComponent,
    PliCarrierDateCellRendererComponent,
    PliCarrierMutiSelectCellRendererComponent,
    DetailMessageModalComponent,
    GridSelectReportFormRendererComponent,
    DeleteBtnLoadFormatCellRendererComponent,
    AddReportFormatFieldComponent,
    ProjectConfirmationDialogComponent,
    DeleteMenuCellRendererComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MyMaterialModule,
    MyACLModule,
    // PdsMatOptionModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatGridListModule,
    MatDialogModule,
    MatSnackBarModule,
    CVSLoaderModule,
    AppRoutingModule,
    MatSidenavModule,
    CVSConfirmationDialogModule,
    AgGridModule.withComponents([NumericCellEditorComponent,
      CellRendererComponent, CustomTooltipComponent,
      EligibilityDialogComponent, EditBtnCellRendererComponent,
      DeleteBtnCellRendererComponent, ThreasholdDialogComponent,
      DashboardComponent, DownloadBtnCellRendererComponent, RteEprHistoryComponent,
      DropdownRendererComponent, EligibilityBtnCellRendererComponent,
      GridInputFormRendererComponent,GridSelectFormRendererComponent,
      PliCarrierDeleteCellRendererComponent,PliCarrierDateCellRendererComponent,
      PliCarrierMutiSelectCellRendererComponent]),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([
      WebEprfeedEffects,
      UserManagementEffects,
      EprFeedManagementEffects,
      DataManagementEffects,
      QuestionnaireEffects,
      ChecklistEffects,
      EprEffects,
      ChecklistFieldsEnabledEffects,
      FileTrackerEffects,
      SpedmEffects,
      ParamMapEffects,
    ]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    DragDropModule,
    MatChipsModule,
    QuillModule.forRoot({
      format: 'json',
      theme: 'bubble',
      bounds:"self",
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ direction: 'rtl' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ['clean'],
          ['link']
        ]
      }
    }),
    MatProgressSpinnerModule,
    NgxMaterialTimepickerModule,
    TooltipModule,
    UserSettingMfeLibraryModule,
    EwfMbrGrpInqMfeLibraryModule,
    MatTableExporterModule
  ],
  providers: [
    MatDialog,
    {
      provide: MatMenuModule,
      useValue: {},
    },
		{
      provide: MatDialogRef,
      useValue: {}
    },
    {provide:MAT_DIALOG_DATA,useValue:{}},
    { provide: AuthenticationService, useClass: AuthCodePKCEService },
    CVSHttpClient,
    { provide: HTTP_INTERCEPTORS, useClass: EwfHttpInterceptor, multi: true },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    EprDeactivateGuard,
    EprActivateGuard,
    AdminDeactivateGuard,
    AdminGuard,
    CookieService,
    WebEprFeedService,
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      }
    },
    EnvironmentService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: EnvironmentService) => () => ds.applyOverrides(),
      deps: [EnvironmentService],
      multi: true
    },
    {provide:"ENVIRONMENT",useValue:environment}
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
