import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {CVSHttpClient} from 'angular-component-library';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private http: CVSHttpClient) { }

  getHistory(eprId: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/eprHistory/${eprId}`);
  }
}
