<div class="export">
  <span class = dashboard-hdr (click)="exportPDF()">
     <mat-icon svgIcon="download-arrow--s" style="cursor:pointer;"  id="export-icon">
       Export PDF
   </mat-icon>
   <span class="export-txt"> Export PDF</span>
</span>
</div>
<div class="table">
<mat-tab-group animationDuration="0ms" disableRipple color="accent"
[selectedIndex] = "currentTabIndex"
(selectedTabChange)="onTabChange($event)">
  <mat-tab *ngFor="let tab of allTabs" [label]="tab.displayName">
    <mat-table [dataSource]="dataSource" class="pds-table">
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; let i = index; columns: displayedColumns;">
      </mat-row>

      <ng-container matColumnDef="fieldNumber">
        <mat-header-cell *matHeaderCellDef> Field Number</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.fieldNumber}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="fieldName">
        <mat-header-cell *matHeaderCellDef>Field Name</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.fieldName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="position">
        <mat-header-cell *matHeaderCellDef> Position</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="cell-content-position">
            {{element.fieldPosition}}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="length">
        <mat-header-cell *matHeaderCellDef> Length</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="cell-content-length">
            {{element.fieldLength}}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="use">
        <mat-header-cell *matHeaderCellDef> Use</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.fieldUse}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.fieldType}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fieldInfo" *ngIf="isRX51Format">
        <mat-header-cell *matHeaderCellDef> Field Information</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.fieldInfo}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="mappingInstructions" *ngIf="!isRX51Format">
        <mat-header-cell *matHeaderCellDef> Logic Code Mapping Instructions</mat-header-cell>
        <mat-cell *matCellDef="let element">

           <textarea matInput
           [readonly]="!editMode"
           [(ngModel)]="element.mappingInstructions"
           cdkTextareaAutosize
           cdkAutosizeMinRows="1"
           cdkAutosizeMaxRows="8"
             placeholder="Mapping instructions for selected logic code"
           ></textarea>

          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="logicCode">
        <mat-header-cell *matHeaderCellDef> Logic Code</mat-header-cell>
        <mat-cell *matCellDef="let element; let index=index" [ngClass]="isValidRecord(element) && !element.logicCode &&
         (validationEnable && is834Format) ? 'logic-code-error-field' : ''">
          <a *ngIf="element.logicCode"  (click)="openLogicCodeModal(element.logicCode, element, index)">{{element.logicCode}}</a>
          <mat-icon svgIcon="search--s"
          [ngClass]="!element.logicCode && (!is834Format) ? 'search-disabled' : ''"
          *ngIf="(!element.logicCode || element.logicCode === null || element.logicCode === '') &&
          ( validationEnable? (!isValidRecord(element)) : true)"
           (click)="openLogicCodeModal(element.logicCode, element, index)"></mat-icon>
          <!-- This is for search icon -->
          <mat-icon id="error-icon" svgIcon="error--s" *ngIf="isValidRecord(element) && (validationEnable && is834Format) && !element.logicCode" [ngClass]="!element.logicCode && validationEnable ? 'logic-code-error-field' : ''" (click)="openLogicCodeModal(element.logicCode, element, index)"></mat-icon>
          <!-- This is for error icon -->

        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="comments">
        <mat-header-cell class="comment-header" *matHeaderCellDef>
          <p style="margin-top: 10px; margin-bottom: 0px;">Comments</p>
          <p style="margin-bottom: 0px;">*Max 4000 characters</p>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-form-field *ngIf="(this.editMode || this.isNewDocForModal); else commentRender" floatLabel="never"
            appearance="none" class="col-internal-6">
            <!-- <input matInput [(ngModel)]="element.comments"  /> -->
            <textarea matInput maxlength="4000" placeholder="comments" [(ngModel)]="element.comments"
              cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"></textarea>
          </mat-form-field>
          <ng-template #commentRender>{{element.comments || ''}}</ng-template>
        </mat-cell>
      </ng-container>

    </mat-table>
  </mat-tab>
</mat-tab-group>
</div>
