import {ActionReducer, ActionReducerMap, createSelector, MetaReducer} from '@ngrx/store';
import {environment} from '../../environments/environment';
import * as fromEPR from './epr.reducer';
import * as fromUserManagement from './user-management.reducer';
import * as fromWebEprFeed from './web-eprfeed.reducer';

import * as fromEprFeed from './epr-feed.reducer';
import * as fromFiletrakcer from './filetracker.reducer';
import * as fromSpedm from './spedm.reducer';
import * as fromParamMap from './param.reducer';
import * as fromCopay from './copay.reducer'
import * as fromUserPBM from './userPermissions.reducer';

import * as fromUser from './user.reducer';
import * as fromDataManagement from './data-management.reducer';
import * as fromHeader from './header.reducer';
import * as fromChecklist from './checklist.reducer';
import * as fromChecklistFieldsEnabled from './checklist-fields-enabled.reducer';
import * as fromQuestionnaire from './questionnaire.reducer';
import * as fromTesting from './testing.reducer';
import * as fromEligibilityAgreement from './eligibility-agreement.reducer';
import * as fromProjectTracker from './project-tracker.reducer';
import * as fromMessageSupress from './msg-supression.reducer';
import { UserPermission } from "../models/User_PBM";
import * as fromUserPermssion from "./userPermissions.reducer"
import {localStorageSync} from 'ngrx-store-localstorage';
import {calculateStatus} from '../helpers/CalculateStatusHelper';

export interface AppState {
  epr: fromEPR.State;
  userManagement: fromUserManagement.State;
  eprFeed: fromEprFeed.State;
  user: fromUser.State;
  webEprFeed: fromWebEprFeed.State;
  filetracker: fromFiletrakcer.State;
  spedm: fromSpedm.State;
  paramMap: fromParamMap.State;
  dataManagement: fromDataManagement.State;
  header: fromHeader.State;
  checklist: fromChecklist.State;
  checklistFieldsEnabled: fromChecklistFieldsEnabled.State;
  questionnaire: fromQuestionnaire.State;
  testing: fromTesting.State;
  eligibilityAgreement: fromEligibilityAgreement.State;
  projectTracker: fromProjectTracker.State;
  msgSupression: fromMessageSupress.State;
  copay: fromCopay.State;
  userPbm: fromUserPBM.State;
}

export const reducers: ActionReducerMap<AppState> = {
  epr: fromEPR.reducer,
  userManagement: fromUserManagement.reducer,
  eprFeed: fromEprFeed.reducer,
  user: fromUser.reducer,
  webEprFeed: fromWebEprFeed.reducer,
  filetracker: fromFiletrakcer.reducer,
  spedm: fromSpedm.reducer,
  paramMap: fromParamMap.reducer,
  dataManagement: fromDataManagement.reducer,
  header: fromHeader.reducer,
  checklist: fromChecklist.reducer,
  checklistFieldsEnabled: fromChecklistFieldsEnabled.reducer,
  questionnaire: fromQuestionnaire.reducer,
  testing: fromTesting.reducer,
  eligibilityAgreement: fromEligibilityAgreement.reducer,
  projectTracker: fromProjectTracker.reducer,
  msgSupression: fromMessageSupress.reducer,
  copay: fromCopay.reducer,
  userPbm: fromUserPermssion.reducer,
};
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: ['epr', 'user'], rehydrate: true})(reducer);
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [localStorageSyncReducer] : [localStorageSyncReducer];

export const getEPRState = (state: AppState) => state.epr;
export const getReadOnly = createSelector(getEPRState, fromEPR.getReadOnly);
export const getEprId = createSelector(getEPRState, fromEPR.getId);
export const getQuestionareID = createSelector(getEPRState,fromEPR.getQuestionareID);
export const getEditorId = createSelector(getEPRState, fromEPR.getEditorId);
export const getFirstLoad = createSelector(getEPRState, fromEPR.getFirstLoad);
export const getSelectedProjectIndex = createSelector(getEPRState, fromEPR.getSelectedProjectIndex);
export const getCurrentEditorId = createSelector(getEPRState, fromEPR.getCurrentEditorId);
export const getPreviousData = createSelector(getEPRState, fromEPR.getPreviousData);
export const getchangedQuestionnaireRecord = createSelector(getEPRState, fromEPR.getchangedQuestionnaireRecord);
export const getProjectTestingEditState = createSelector(getEPRState, fromEPR.getProjectTestingEditState);




// User Management Selectors
export const getUserManagementState = (state: AppState) => state.userManagement;
export const getUsers = createSelector(getUserManagementState, fromUserManagement.getUsers);
export const getActiveUsers = createSelector(getUserManagementState, fromUserManagement.getActiveUsers);
export const getActiveUsersByRole = createSelector(getUserManagementState, fromUserManagement.getActiveUsersByRole);
export const getDisplayedUsers = createSelector(getUserManagementState, fromUserManagement.getDisplayedUsers);
export const getRoles = createSelector(getUserManagementState, fromUserManagement.getRoles);
export const getUserSettingData = createSelector(getUserManagementState, fromUserManagement.getUserSettingList);

export const getUserState = (state: AppState) => state.user;
export const getRole = createSelector(getUserState, fromUser.getRole);
export const getIsRegisteredUser = createSelector(getUserState, fromUser.getIsRegisteredUser);
export const getRdcs = createSelector(getUserState, fromUser.getRdcs);
export const getUserId = createSelector(getUserState, fromUser.getUserId);
// User PBM
export const getUserPbmState = (state: AppState) => state.userPbm;
export const getUserPbm = createSelector(getUserPbmState, fromUserPBM.getUserPBM);
export const getUserPbmId = createSelector(getUserPbmState, fromUserPBM.getUserId);
export const getUserPermissions = createSelector(getUserPbmState, fromUserPBM.getPermissions);


export const getPermissionsAdminObject =createSelector(getUserPbmState, fromUserPBM.getPermissionsAdminObject)
export const getPermissionsDashboardObject =createSelector(getUserPbmState, fromUserPBM.getPermissionsDashboardObject)
export const getPermissionsEprObject =createSelector(getUserPbmState, fromUserPBM.getPermissionsEprObject)
export const getPermissionsProcessingFilesObject =createSelector(getUserPbmState, fromUserPBM.getPermissionsProcessingFilesObject)
export const getPermissionsFiletrackerObject=createSelector(getUserPbmState, fromUserPBM.getPermissionsFiletrackerObject)
export const getUserNamePbm =createSelector(getUserPbmState,fromUserPBM.getUserNamePBM)
export const getDataManagementState = (state: AppState) => state.dataManagement;
export const getDataListOptions = createSelector(getDataManagementState, fromDataManagement.getDataListOptions);
export const getMsgSupressionState = (state: AppState) => state.msgSupression;
export const getMsgDataListOptions = createSelector(getMsgSupressionState, fromMessageSupress.getDataListOptions);



export const getCurrentEditorName = createSelector(getCurrentEditorId, getUsers, (ecId, users) => {
  const selectedUser = ecId ? users.find(user => user.id === ecId) : null;
  return selectedUser ? selectedUser.firstName + ' ' + selectedUser.lastName : '';
});

// EprFeed Selectors
export const  getEprFeed = (state: AppState) => state.eprFeed;
export const getEprFeeds = createSelector(getEprFeed, fromEprFeed.getEprFeeds);
// export const getSelectedEprFeedID = (state: AppState) => state.eprFeed.selectedEprFeedId;
export const getSelectedEprFeed = createSelector(getEprFeed, fromEprFeed.getSelectedEprFeed);

// WebEprFeed Selectors
export const getWebEprFeedState = (state: AppState) => state.webEprFeed;
export const getWebEprFeeds = createSelector(getWebEprFeedState, fromWebEprFeed.getWebEprFeeds);
export const getWebEprFeedByEpr = createSelector(getWebEprFeedState, fromWebEprFeed.getWebEprFeedByEpr);

// Filetracker Selectors
export const getFiletrackerState = (state: AppState) => state.filetracker;
export const getAllFiletracker = createSelector(getFiletrackerState, fromFiletrakcer.getAllFiletrackers);
export const getFiletracker = createSelector(getFiletrackerState, fromFiletrakcer.getSelectedFiletracker);
export const getContact = createSelector(getFiletrackerState, fromFiletrakcer.getContact);
export const getEditMode = createSelector(getFiletrackerState, fromFiletrakcer.getEditMode);

// Spedm Selectors
export const getSpedmState = (state: AppState) => state.spedm;
export const getAllSpedm = createSelector(getSpedmState, fromSpedm.getspedm);
export const getSpedm = createSelector(getSpedmState, fromSpedm.getSelectedSpedm);

// ParamMap Selectors
export const getParamMapState = (state: AppState) => state.paramMap;
export const getAllParamMaps = createSelector(getParamMapState, fromParamMap.getAllParamMap);
export const getParamMap = createSelector(getParamMapState, fromParamMap.getSelectedParamMap);


// Header Selectors
export const getHeaderState = (state: AppState) => state.header;
export const getClientName = createSelector(getHeaderState, fromHeader.getClientName);
export const getCarriers = createSelector(getHeaderState, fromHeader.getCarriers);
export const getPli = createSelector(getHeaderState, fromHeader.getPli);
export const getEC = createSelector(getHeaderState, fromHeader.getEC);
export const getBackupEc = createSelector(getHeaderState, fromHeader.getBackupec);
export const getBackupDefaultEc = createSelector(getHeaderState, fromHeader.getBackupecDefault);

export const getEcName = createSelector(getEC, getUsers, (ecId, users) => {
  const selectedUser = ecId ? users.find(user => user.id === ecId) : null;
  return selectedUser ? selectedUser.firstName + ' ' + selectedUser.lastName : '';
});

// Project Tracker Selectors
export const getProjectTrackerState = (state: AppState) => state.projectTracker;
export const getProjectTrackerDisplayNames = createSelector(getProjectTrackerState, fromProjectTracker.getProjectTrackersDisplayNames);
export const getNumberOfProjectTrackers = createSelector(getProjectTrackerState, fromProjectTracker.getNumberOfProjectTrackers);
export const getCurrentGoLiveDate = createSelector(getProjectTrackerState, fromProjectTracker.getCurrentGoLiveDate);
export const getProjectPhase = createSelector(getProjectTrackerState, getSelectedProjectIndex, fromProjectTracker.getPhase);
export const getProjectType = createSelector(getProjectTrackerState, getSelectedProjectIndex, fromProjectTracker.getType);
export const getProjectIterations = createSelector(getProjectTrackerState, fromProjectTracker.getProjectIterations);

// Checklist Selectors
export const getChecklistState = (state: AppState) => state.checklist;
export const getCheckListDrivers = createSelector(getChecklistState, fromChecklist.getCheckListDrivers);
export const getStatusDates = createSelector(getChecklistState, getSelectedProjectIndex, fromChecklist.getStatusDates);

export const getProjectStatus = createSelector(getProjectPhase, getStatusDates, (phase, dates) => {
  return calculateStatus(phase, dates);
});

// Checklist Field Enabled Selectors
export const getChecklistFieldsEnabledState = (state: AppState) => state.checklistFieldsEnabled;
export const getChecklistFieldEnabledStatus = createSelector(getChecklistFieldsEnabledState,
  fromChecklistFieldsEnabled.getChecklistFieldEnabledStatus);
export const getAllChecklistFieldsEnabledStatus = createSelector(getChecklistFieldsEnabledState,
  fromChecklistFieldsEnabled.getAllChecklistFieldsEnabledStatus);

// Testing Selectors
export const getTestingState = (state: AppState) => state.testing;
export const getSelectedProjectIndexFromTesting = createSelector(getTestingState, fromTesting.getSelectedProjectIndex);
export const getSelectedTestIteration = createSelector(getTestingState, fromTesting.getSelectedTestIteration);
export const getCustomTestCriteria = createSelector(getTestingState, fromTesting.getCustomTestCriteria);
export const getCurrentProjectTestIterations = createSelector(getTestingState, fromTesting.getCurrentProjectTestIterations);
export const getTestIterationCount = createSelector(getTestingState, fromTesting.getTestIterationCount);
export const getProjectName = createSelector(getSelectedProjectIndexFromTesting, getProjectTrackerDisplayNames,
  (index, names) => names[index]);

// Questionnaire Selectors
export const getQuestionnaireState = (state: AppState) => state.questionnaire;
export const getDrivers: any = createSelector(getQuestionnaireState, fromQuestionnaire.getDrivers);
export const getLineOfBusinessValues = createSelector(getQuestionnaireState, fromQuestionnaire.getLineOfBusinessValues);
export const getPopulationValues = createSelector(getQuestionnaireState, fromQuestionnaire.getPopulationValues);
export const getFtpId = createSelector(getQuestionnaireState, fromQuestionnaire.getFtpId);
export const getMemberSuspenseRetentionDays = createSelector(getQuestionnaireState, fromQuestionnaire.getMemberSuspenseRetentionDays);
export const getImpTermLevel = createSelector(getQuestionnaireState, fromQuestionnaire.getImpTermLevel);
export const getTbaDate = createSelector(getQuestionnaireState, fromQuestionnaire.getTbaDate);
export const getPcLength = createSelector(getQuestionnaireState, fromQuestionnaire.getPcLength);
export const getCardID = createSelector(getQuestionnaireState, fromQuestionnaire.getCardID);
export const getPcAssignmentMethod = createSelector(getQuestionnaireState, fromQuestionnaire.getPcAssignmentMethod);

export const getQuestionnaireTermThreshold = createSelector(getQuestionnaireState, fromQuestionnaire.getQuestionnaireTermThreshold);
export const getFlerrorThreshold = createSelector(getQuestionnaireState, fromQuestionnaire.getFlerrorThreshold);
export const getTermThreshold = createSelector(getQuestionnaireState, fromQuestionnaire.getTermThreshold);
export const getTermThresholdInclMovesCount = createSelector(getQuestionnaireState, fromQuestionnaire.getTermThresholdInclMovesCount);
export const getTermThresholdExclMovesCount = createSelector(getQuestionnaireState, fromQuestionnaire.getTermThresholdExclMovesCount);
export const getTermThresholdAllActCount = createSelector(getQuestionnaireState, fromQuestionnaire.getTermThresholdAllActCount);
export const getTermThreshExcMoves = createSelector(getQuestionnaireState, fromQuestionnaire.getTermThreshExcMoves);
export const getTermsVsAllActives = createSelector(getQuestionnaireState, fromQuestionnaire.getTermsVsAllActives);
export const getFamIDThrshld = createSelector(getQuestionnaireState, fromQuestionnaire.getFamIDThrshld);
export const getMatchLevel = createSelector(getQuestionnaireState, fromQuestionnaire.getMatchLevel);
export const getNewPopThrshldUOM = createSelector(getQuestionnaireState, fromQuestionnaire.getNewPopThrshldUOM);
export const getChngMbrIDUOM = createSelector(getQuestionnaireState, fromQuestionnaire.getChngMbrIDUOM);
export const getChngLNThrshldUse = createSelector(getQuestionnaireState, fromQuestionnaire.getChngLNThrshldUse);
export const getChngFNThrshldUse = createSelector(getQuestionnaireState, fromQuestionnaire.getChngFNThrshldUse);
export const getChngDOBThrshldUse = createSelector(getQuestionnaireState, fromQuestionnaire.getChngDOBThrshldUse);
export const getChngRelThrshldUse = createSelector(getQuestionnaireState, fromQuestionnaire.getChngRelThrshldUse);
export const getIdcardThrshld = createSelector(getQuestionnaireState, fromQuestionnaire.getIdcardThrshld);
export const getChngLNUOM = createSelector(getQuestionnaireState, fromQuestionnaire.getChngLNUOM);
export const getChngFNUOM = createSelector(getQuestionnaireState, fromQuestionnaire.getChngFNUOM);
export const getChngDOBThrshld = createSelector(getQuestionnaireState, fromQuestionnaire.getChngDOBThrshld);
export const getChngRelThrshld = createSelector(getQuestionnaireState, fromQuestionnaire.getChngRelThrshld);
export const getNewPopThrshld = createSelector(getQuestionnaireState, fromQuestionnaire.getNewPopThrshld);
export const getChngMbrIDThrshld = createSelector(getQuestionnaireState, fromQuestionnaire.getChngMbrIDThrshld);
export const getChngLNThrshld = createSelector(getQuestionnaireState, fromQuestionnaire.getChngLNThrshld);
export const getChngFNThrshld = createSelector(getQuestionnaireState, fromQuestionnaire.getChngFNThrshld);
export const getChngDOBUOM = createSelector(getQuestionnaireState, fromQuestionnaire.getChngDOBUOM);
export const getChngRelUOM = createSelector(getQuestionnaireState, fromQuestionnaire.getChngRelUOM);
export const getPersonCodes = createSelector(getQuestionnaireState, fromQuestionnaire.getPersonCodes);
export const getPrevousEAGroup = createSelector(getQuestionnaireState, fromQuestionnaire.getPrevousEAGroup);
export const getMemIDLength = createSelector(getQuestionnaireState, fromQuestionnaire.getMemIDLength);
export const getMemIDMaxLength = createSelector(getQuestionnaireState, fromQuestionnaire.getMemIDMaxLength);
export const getMbrIDTypeWPC = createSelector(getQuestionnaireState, fromQuestionnaire.getMbrIDTypeWPC);
export const getSignOffStatus = createSelector(getQuestionnaireState, fromQuestionnaire.getSignOffStatus);

export const getXwalkIDLength = createSelector(getQuestionnaireState, fromQuestionnaire.getXwalkIDLength);
export const getXwalkIDMaxLength = createSelector(getQuestionnaireState, fromQuestionnaire.getXwalkIDMaxLength);
export const getXwalkIDTypeWPC = createSelector(getQuestionnaireState, fromQuestionnaire.getXwalkIDTypeWPC);

// export const getConsolidateProfileLogic = createSelector(getQuestionnaireState, fromQuestionnaire.getConsolidateProfileLogic);
export const getVendor = createSelector(getQuestionnaireState, fromQuestionnaire.getVendor);
export const getVendorLookup = createSelector(getQuestionnaireState, fromQuestionnaire.getVendorLookup);
export const getFileTransmitionMethod = createSelector(getQuestionnaireState, fromQuestionnaire.getFileTransmitionMethod);
export const getFormat = createSelector(getQuestionnaireState, fromQuestionnaire.getFormat);
export const getGroupFilesSent = createSelector(getQuestionnaireState, fromQuestionnaire.getGroupFilesSent);
export const getCompareProcess = createSelector(getQuestionnaireState, fromQuestionnaire.getCompareProcess);
export const getCompareFrequency = createSelector(getQuestionnaireState, fromQuestionnaire.getCompareFrequency);
export const getQphysFiles = createSelector(getQuestionnaireState, fromQuestionnaire.getQphysFiles);
export const getQdiagFiles = createSelector(getQuestionnaireState, fromQuestionnaire.getQdiagFiles);
export const getQlabFiles = createSelector(getQuestionnaireState, fromQuestionnaire.getQlabFiles);
export const getFileFrequencyNotes = createSelector(getQuestionnaireState, fromQuestionnaire.getFileFrequencyNotes);

export const getLoadReportEntity = createSelector(getQuestionnaireState, fromQuestionnaire.getLoadReportEntity)
export const getFilePrefixes = createSelector(getQuestionnaireState, fromQuestionnaire.getFilePrefixes)



// Eligibility Agreement Selectors
export const getEligibilityAgreement = (state: AppState) => state.eligibilityAgreement;
export const getChangeLog = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getChangeLog);
export const getCEPRTMR = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRTMR);
export const getGrpFileNamingProd = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getGrpFileNamingProd);
export const getCEPRSuspense = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRSuspense);
export const getCEPRFamonCards = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRFamonCards);
export const getCEPRSrvvngSps = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRSrvvngSps);
export const getCEPRFamID = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRFamID);
export const getCEPRFPersonCode = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRFPersonCode);
export const getCEPRCOB = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRCOB);
export const getCEPRHistMthd = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRHistMthd);
export const getCEPRFAltIdCode = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRFAltIdCode);
export const getCEPRTrmDates4AllMbrs = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRTrmDates4AllMbrs);
export const getCEPRDepImpTrms = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRDepImpTrms);
export const getCEPRImpTrms = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRImpTrms);
export const getFileNamingCompare = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getFileNamingCompare);
export const getCEPRImpTrm2 = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRImpTrm2);
export const getCEPRPCOBNCOBNote = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRPCOBNCOBNote);
export const getCEPR3rdID = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPR3rdID);
export const getFileNamingProd = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getFileNamingProd);
export const getFileNamingTest = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getFileNamingTest);
export const getCEPRGrpSusp = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRGrpSusp);
export const getCERPBatchOvr = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCERPBatchOvr);
export const getCEPRCHOnly = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRCHOnly);
export const getCEPRAutoCAG = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRAutoCAG);
export const getCEPRStandaloneDeps = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRStandaloneDeps);
export const getCEPRSplitFam = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRSplitFam);
export const getCEPRTMR2 = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRTMR2);
export const getCEPRTMR3 = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRTMR3);
export const getCEPRTMR4 = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRTMR4);
export const getCEPRTMRIgnFutureTrm = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRTMRIgnFutureTrm);
export const getCEPRTMRInclFtrEff = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getCEPRTMRInclFtrEff);
export const getThresholdNotes = createSelector(getEligibilityAgreement, fromEligibilityAgreement.getThresholdNotes);

// Copay Selectors
export const getCopayState = (state: AppState) => state.copay;
export const getAllCopay = createSelector(getCopayState, fromCopay.getcopay);
export const getCopay = createSelector(getCopayState, fromCopay.getSelectedCopay);
