import {Component, OnInit, ViewChild} from '@angular/core';
import {filetrackerFormConfig} from './FiletrackerFormConfig';
import {FormType} from '../models/FormType';
import {InputType} from '../models/FormFieldConfig';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import * as fromRoot from '../reducers';
import {getFiletracker, getIsRegisteredUser, getRole,
  getPermissionsFiletrackerObject} from '../reducers';
import {Store} from '@ngrx/store';
import {CVSAlertService} from 'angular-component-library';
import {Actions} from '@ngrx/effects';
import {FiletrackerService} from '../services/filetracker.service';
import {AlertType} from '../models/AlertType';
import { MatDialog } from '@angular/material/dialog';
import {FiletrackerContactDialogComponent} from './filetracker-contact-dialog/filetracker-contact-dialog.component';
import {Permission} from '../models/Authorization';
import {Observable} from 'rxjs';
import {EditAction} from '../actions/filetracker.action';
import {UsersService} from "../services/users.service";
import { FiletrackerDashboardComponent } from '../filetracker-dashboard/filetracker-dashboard.component';

@Component({
  selector: 'app-filetracker',
  templateUrl: './filetracker.component.html',
  styleUrls: ['./filetracker.component.scss']
})
export class FiletrackerComponent implements OnInit {
  primaryUser: boolean = false;
  secondaryUser: boolean = false;
  priUserName: any;
  secUserName: any;
  priOooFromDate:any;
  priOooToDate:any;
  priOooFromTime:any;
  priOooToTime:any;
  isPriUserInactive:boolean;
  secOooFromDate:any;
  secOooToDate:any;
  secOooFromTime:any;
  secOooToTime:any;
  isSecUserInactive:boolean;
  filetrackerdata: any ;

  constructor(private store: Store<fromRoot.AppState>, private alertService: CVSAlertService,
              private actions$: Actions, private filetrackerService: FiletrackerService,
              private usersService: UsersService,
              public dialog: MatDialog) {
              }

  formType = FormType;
  inputType = InputType;
  filetrackerFormConfig = filetrackerFormConfig;
  hyperlinks = [];
  form: UntypedFormGroup = new UntypedFormGroup({});
  errors = {};
  isEdited = false;
  isRegisteredUser$ = this.store.select(getIsRegisteredUser) as Observable<boolean>;
  isAdmin = false;
  permissionObject$ = this.store.select(getPermissionsFiletrackerObject) as Observable<Object>;
  permissionObject:any;

  ngOnInit() {
      this.filetrackerFormConfig = [...filetrackerFormConfig];
      this.setupForm();
      if (!this.filetrackerService.isNew) {
        const cltSetupId = this.filetrackerService.cltSetupId
        this.filetrackerService.getFiletracker(cltSetupId).subscribe(response => {
          if (response ) {
            this.form.patchValue(response);
            this.filetrackerdata = this.filetrackerService.fileTrackerRowData;
      if(this.filetrackerdata?.primaryUserName){
        this.primaryUser = true;
        this.priUserName = this.filetrackerdata["primaryUserName"]
      if(this.filetrackerdata?.primaryUserStatus === 'I'){
        this.isPriUserInactive = true
      }
      if(this.filetrackerdata?.primaryUserOOOFromDateTimestamp){
        this.priOooFromDate=this.filetrackerdata["primaryUserOOOFromDateTimestamp"].substring(0,11)
        this.priOooFromTime=this.filetrackerdata["primaryUserOOOFromDateTimestamp"].substring(11)
        this.priOooToDate=this.filetrackerdata["primaryUserOOOToDateTimestamp"].substring(0,11)
        this.priOooToTime=this.filetrackerdata["primaryUserOOOToDateTimestamp"].substring(11)
      }

    }
      if(this.filetrackerdata?.secondaryUserName){
      this.secondaryUser = true
      this.secUserName = this.filetrackerdata["secondaryUserName"]
      if(this.filetrackerdata.secondaryUserStatus === 'I'){
        this.isSecUserInactive = true
      }
      if(this.filetrackerdata?.secondaryUserOOOFromDateTimestamp){
        this.secOooFromDate=this.filetrackerdata["secondaryUserOOOFromDateTimestamp"].substring(0,11)
        this.secOooFromTime=this.filetrackerdata["secondaryUserOOOFromDateTimestamp"].substring(11)
        this.secOooToDate=this.filetrackerdata["secondaryUserOOOToDateTimestamp"].substring(0,11)
        this.secOooToTime=this.filetrackerdata["secondaryUserOOOToDateTimestamp"].substring(11)
      }
    }
          }
        });
      } else if (this.filetrackerService.isNew) {
        this.filetrackerService.getFiletracker(0).subscribe(response => {
          if (response ) {
            this.form.patchValue(response);
          }
        });
      } else {
        this.isEdited = true;
        this.formEnable();
      }

      // this.store.select(getRole).subscribe(role => {
    this.usersService.userRole.subscribe(role =>{
      if ([Permission.ADMIN, Permission.MANAGER,
           Permission.EC, Permission.PROCESSOR,
           Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
           Permission.INTFEED,  Permission.FILETRACKER].includes(role)) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    });
    this.permissionObject$.subscribe(res=>{
      this.permissionObject = res
    })
  }

  getHyperlink(controlName: string, hyperlinks) {
    let hyperLinkValue = null;
    if (Array.isArray(hyperlinks) && hyperlinks[0] && hyperlinks[0][controlName]) {
      hyperLinkValue =  hyperlinks[0][controlName];
    }
    return hyperLinkValue;

  }
  setupForm() {
    // Base form
    this.filetrackerFormConfig.map(section => {
      section.map(formField => {
        if (formField.notes) {
          this.form.addControl(formField.notes, new UntypedFormControl(''));
        }
        if (formField.inputType !== InputType.HEADER) {
          if (formField['required']) {
            this.form.addControl(formField.controlName, new UntypedFormControl('', formField['controller']));
          } else {
            this.form.addControl(formField.controlName, new UntypedFormControl(''));
          }

        }
      });
    });
    this.form.addControl('notProcCallIn', new UntypedFormControl(''));
    this.form.addControl('missedPgLoadReportCallIn', new UntypedFormControl(''));
    this.form.addControl('procErrorCallIn', new UntypedFormControl(''));
    this.form.addControl('missedFilesCallIn', new UntypedFormControl(''));
    this.form.addControl('multipleExpectedFilesCallIn', new UntypedFormControl(''));
    this.formDisabled();
  }

  startEdit() {
    this.isEdited = true;
    this.formEnable();
  }
  formDisabled() {
    this.filetrackerFormConfig.map(section => {
      section.map(formField => {
        if (formField.inputType !== InputType.HEADER) {
          this.form.get(formField.controlName).disable();
        }
      });
    });
    this.form.get('notProcCallIn').disable();
    this.form.get('missedPgLoadReportCallIn').disable();
    this.form.get('procErrorCallIn').disable();
    this.form.get('missedFilesCallIn').disable();
    this.form.get('multipleExpectedFilesCallIn').disable();
  }
  formEnable() {
    this.filetrackerFormConfig.map(section => {
      section.map(formField => {
        if (formField.inputType !== InputType.HEADER) {
          this.form.get(formField.controlName).enable();
        }
      });
    });
    if (this.form.controls['callAlertReceiverGroup'].value === 'Anthem') {
      this.form.get('notProcCallIn').enable();
      this.form.get('missedPgLoadReportCallIn').enable();
      this.form.get('procErrorCallIn').enable();
      this.form.get('missedFilesCallIn').enable();
      this.form.get('multipleExpectedFilesCallIn').enable();
    } else {
      this.form.get('notProcCallIn').disable();
      this.form.get('missedPgLoadReportCallIn').disable();
      this.form.get('procErrorCallIn').disable();
      this.form.get('missedFilesCallIn').disable();
      this.form.get('multipleExpectedFilesCallIn').disable();
    }
  }
  saveFileTracker() {
    if (!this.isValidate()) {
      return;
    }

    if ( this.filetrackerService.isNew ) {
    // this.store.dispatch(new SaveFiletrackerAction(this.form.getRawValue()));
      // this.store.dispatch(new SaveFiletrackerAction(this.form.getRawValue(), 'save'));
      //
      this.filetrackerService.saveFiletracker(this.form.getRawValue(), 'save').subscribe(response => {
        this.isEdited = false;
        this.filetrackerService.isNew = false;
        this.formDisabled();
      });
    } else {
      // this.store.dispatch(new UpdateFiletrackerAction(this.form.getRawValue()));
      this.filetrackerService.saveFiletracker(this.form.getRawValue(), 'update').subscribe(response => {
        this.isEdited = false;
        this.formDisabled();
      });
    }

  }

  private isExracted(value: any) {
    const pattern = new RegExp('^[0-9]*$');
    const result = pattern.test(value);
    if ( result ) {
      return true;
    }
    return false;
  }

  isValidate() {
    this.errors = {};

    if (this.form.controls['pgContract'].value !== null) {
      const pgContractValue = this.form.controls['pgContract'].value;
      if (!this.isExracted(this.form.controls['pgContract'].value)) {
        this.errors['pgContract'] = {message: 'A numeric PG Contract is required if entered'};
      }
      if (pgContractValue.length > 9) {
        this.errors['pgContract'] = {message: 'Maximum PG Contract length is 9 digits'};
      }
    }

    if ( !this.form.controls['fileRoute'].value.toString().startsWith(this.form.controls['system'].value.toString())) {
       this.errors['fileRoute'] = {message: 'The File Route and System do not match'};
    }

    const pgPercentageLv1 = this.form.getRawValue().pgPercentageLv1;
    const pgTurnAroundTimeLvl1 = this.form.getRawValue().pgTurnAroundTimeLvl1;
    const pgPercentageLv2 = this.form.getRawValue().pgPercentageLv2;
    const pgTurnAroundTimeLvl2 = this.form.getRawValue().pgTurnAroundTimeLvl2;

    if ( (pgTurnAroundTimeLvl2 === null || pgTurnAroundTimeLvl2 === ''  || pgTurnAroundTimeLvl2 === 'PT0H') &&
      pgPercentageLv2 !== null && pgPercentageLv2 !== '' && pgPercentageLv2 !== '0.00') {
      this.errors['pgTurnAroundTimeLvl2'] = {message: 'PG Turn-Around Time LVL2 needs to be entered in order to select any value from PG' +
          ' Percentage LVL2'};
    }

    if (pgTurnAroundTimeLvl2 !== null  && pgTurnAroundTimeLvl2 !== 'PT0H'
      && pgTurnAroundTimeLvl2 !== '' &&  (pgPercentageLv2 === null  || pgPercentageLv2 === '' || pgPercentageLv2 === '0.00')) {
      this.errors['pgPercentageLv2'] = {message: 'PG Percentage LVL2 is required if Turn-Around Time LVL2 has been entered' +
          ' Percentage LVL2'};
    }

    if ( pgTurnAroundTimeLvl1 !== null && pgTurnAroundTimeLvl2 !== null ) {
      const lvl1TATVal = pgTurnAroundTimeLvl1.substring(2, pgTurnAroundTimeLvl1.length - 1 );
      const lvl2TATVal = pgTurnAroundTimeLvl2.substring(2, pgTurnAroundTimeLvl2.length - 1 );
      // Compare the 2 entered PG Turn-Around Time values
      // eslint-disable-next-line radix
      if ( parseInt(lvl1TATVal) > parseInt(lvl2TATVal) && parseInt(lvl2TATVal) !== 0) {
        this.errors['pgTurnAroundTimeLvl2'] = {message: 'PG Turn-Around Time LVL2 should be greater/equal to PG Turn-Around Time LVL1' };
      }
    }

    if ( pgPercentageLv1 !== null && pgPercentageLv2 !== null ) {
      // eslint-disable-next-line radix
      const lvl1PercentageVal = parseInt(pgPercentageLv1.trim() );
      // eslint-disable-next-line radix
      const lvl2PercentageVal = parseInt(pgPercentageLv2.trim() );
      // Compare the 2 entered PG Turn-Around Time values
      // eslint-disable-next-line radix
      // lvl2PercentageVal !== 0 && isNaN(lvl2PercentageVal)
      if (lvl2PercentageVal > 0 && lvl2PercentageVal !== null ) {
           if ( lvl1PercentageVal > lvl2PercentageVal  && ! isNaN(lvl2PercentageVal)) {
             this.errors['pgPercentageLv2'] = {message: 'PG Percentage LVL2 should be greater/equal to PG Percentage LVL1' };
        }
      }

    }

    if ( this.form.getRawValue().notProcessedAlert === 'E' || this.form.getRawValue().notProcessedAlert === 'M' ||
      this.form.getRawValue().notProcessedAlert === 'B') {
      if ( this.form.controls['notProcessedfirstAlert'].value === null || this.form.controls['notProcessedfirstAlert'].value === '') {
        this.errors['notProcessedAlert'] = {message: 'By selecting \'E\', \'M\' or \'B\', user is requesting to be notified when files' +
            ' are not processed successfully. User must select a percentage below to indicate when first alert will be triggered' +
            ' for notification' };
      }
    }

    if (this.form.controls['notProcessedfirstAlert'].value !== null &&
        this.form.controls['notProcessedfirstAlert'].value !== '' &&
        this.form.controls['notProcessedfirstAlert'].value > 0) {
      if (this.form.getRawValue().notProcessedAlert === 'N') {
        this.errors['notProcessedfirstAlert'] = {
          message: 'By selecting a percentage, you are requesting the "Not Processed Alert" to be ' +
            'activated. Select a valid option above to activate alert'
        };
      }
    }

    if ( this.form.getRawValue().pgLoadReportingInEffect === 'Y' &&
      (this.form.controls['pgLoadReportingTurnAroundTime'].value === null ||
        this.form.controls['pgLoadReportingTurnAroundTime'].value === '' )) {
        this.errors['pgLoadReportingTurnAroundTime'] = {message: 'This client feed has a Performance Guarantee tied to Load Reporting. ' +
            'A Turn-Around-Time is required'};
    }

    if ( this.form.getRawValue().pgLoadReportingAlert === 'E' || this.form.getRawValue().pgLoadReportingAlert === 'M' ||
      this.form.getRawValue().pgLoadReportingAlert === 'B') {
      if ( this.form.controls['pgLoadReportingfirstAlert'].value === null ||
           this.form.controls['pgLoadReportingfirstAlert'].value === '' ) {
        // @ts-ignore
        this.errors['pgLoadReportingfirstAlert'] = {message: 'By selecting \'E\', \'M\' or \'B\', user is requesting to be notified ' +
            'when load reports are late being delivered to destination. User must select a percentage below to indicate when first ' +
            'alert will be triggered for notification' };
      }
      if ( !this.form.controls['fileRoute'].value.toString().startsWith(this.form.controls['system'].value.toString())) {
        this.errors['fileRoute'] = {message: 'The File Route and System do not match'};
      }
    }

    if ( this.form.getRawValue().pgLoadReportingAlert === 'N' &&
      ( this.form.getRawValue().pgLoadReportingfirstAlert !== null &&  this.form.getRawValue().pgLoadReportingfirstAlert > 0 ) ) {
      this.errors['pgLoadReportingfirstAlert'] = {message: 'By selecting a percentage, you are requesting the "PG Load Reporting Alert" ' +
          'to be activated. Select a valid option above to activate alert'};
    }

    if ((this.form.getRawValue().pgLoadReportingAlert === 'E' ||
      this.form.getRawValue().pgLoadReportingAlert === 'M' ||
      this.form.getRawValue().pgLoadReportingAlert === 'B') &&
      (this.form.getRawValue().pgLoadReportingfirstAlert === 0 ||
        this.form.getRawValue().pgLoadReportingfirstAlert === '')) {
      this.errors['pgLoadReportingfirstAlert'] = {
        message: 'By selecting "E", "M" or "B", user is requesting to be notified ' +
          'when load reports are late being delivered to destination.' +
          ' User must select a percentage below to indicate when first alert will be triggered for notification'
      };
    }

    if ( this.form.getRawValue().multipleExpectedFilesAlert === 'E' || this.form.getRawValue().multipleExpectedFilesAlert === 'M' ||
      this.form.getRawValue().multipleExpectedFilesAlert === 'B') {
      if ( this.form.getRawValue().missingFilesAlert !== 'N') {
        // @ts-ignore
        this.errors['multipleExpectedFilesAlert'] = {message: 'Missing Files Alert and Multiple Expected Files Alert cannot be activated' +
            ' together. Please set the appropriate alert to \'N\'' };
      }
    }


    if ( this.form.getRawValue().missingFilesAlert === 'E' || this.form.getRawValue().missingFilesAlert === 'M' ||
      this.form.getRawValue().missingFilesAlert === 'B') {
      if ( this.form.getRawValue().multipleExpectedFilesAlert !== 'N') {
        // @ts-ignore
        this.errors['multipleExpectedFilesAlert'] = {message: 'Missing Files Alert and Multiple Expected Files Alert cannot be activated' +
            ' together. Please set the appropriate alert to \'N\'' };
      }
    }
    if ( this.form.getRawValue().callAlertReceiverGroup !== null &&
       this.form.getRawValue().callAlertReceiverGroup !== '' ) {
      if ( this.form.getRawValue().notProcCallIn === false && this.form.getRawValue().missedPgLoadReportCallIn === false &&
          this.form.getRawValue().procErrorCallIn === false ) {
        this.errors['callAlertReceiverGroup'] = {
          message: 'Selecting "Call Alert Receiver Group" requires user to subscribe to at least ' +
            'one of the eligible alerts using the check boxes below.'
        };
      }
    }

    const keys = Object.keys(this.errors);
    if (keys.length === 0) {
      return true;
    }
    return false;
  }

  addContacts(alertfield) {
        const defaultmessage = 'ErrorMessage:';
        this.store.dispatch(new EditAction(this.isEdited));
        this.dialog.open(FiletrackerContactDialogComponent, {
          data: {
            setupId: this.form.controls['cltSetupID'].value,
            alertField: alertfield,
            type: AlertType.STATUS,
            isEdited: this.isEdited
          }
        });

  }
  noAction() {
    return;
  }
  private isExtracted(value: any) {
    const pattern = new RegExp('^[0-9]*$');
    const result = pattern.test(value);
    if (result) {
      return true;
    }
    return false;
  }

  dropValue(controlName: string) {
    if (controlName === 'callAlertReceiverGroup') {
      if (this.form.controls[controlName].value === 'Anthem') {
        this.form.get('notProcCallIn').enable();
        this.form.get('missedPgLoadReportCallIn').enable();
        this.form.get('procErrorCallIn').enable();
      } else {
        this.form.get('notProcCallIn').disable();
        this.form.get('missedPgLoadReportCallIn').disable();
        this.form.get('procErrorCallIn').disable();
        this.form.get('notProcCallIn').setValue(false);
        this.form.get('missedPgLoadReportCallIn').setValue(false);
        this.form.get('procErrorCallIn').setValue(false);
      }
    }
  }
}
