import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { groupCol, memberDailyCol, memberFileCol } from './suspense-ColumnSets';
import { GridOptions } from "ag-grid-community";
import { RteSuspenseService } from 'src/app/services/rte-suspense.service';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
@Component({
  selector: 'app-suspense-grid',
  templateUrl: './suspense-grid.component.html',
  styleUrls: ['./suspense-grid.component.scss']
})
export class SuspenseGridComponent implements  OnChanges {
  @Input() radioType: string;
  @Input() gridData
  @Input() searchPayload;
  private gridApi;
  columnDefs
  gridOptions = {
    rowClass: 'cvs-ag-grid-row',
    rowSelection: 'multiple',
    suppressRowClickSelection: true
  } as GridOptions;
  selectedColDefinition: (any)[];
  //{ headerName: string; headerCheckboxSelection: boolean; checkboxSelection: boolean; width: number; cellClass: (params: any) => string; editable?: undefined; field?: undefined; sortable?: undefined; filter?: undefined; lockVisible?: undefined; lockPosition?: undefined; filterParams?: undefined; } | { headerName: string; editable: boolean; field: string; sortable: boolean; filter: boolean; lockVisible: boolean; lockPosition: boolean; filterParams: { newRowsAction: string; }; headerCheckboxSelection?: undefined; checkboxSelection?: undefined; width?: undefined; cellClass?: undefined; }
  gridRowData: { carrier: string; account: string; group: string; groupName: string; effectiveDate: string; termDate: string; planCode: string; planEffectiveDate: string; pli: string; addDate: string; dropDate: string; accountSuspenseFlag: string; planSuspenseFlag: string; }[];
  isRowSelected: boolean;
  selectedRowsArr: any;
  constructor(private suspenseService: RteSuspenseService, private alertService: CVSAlertService) { }



  ngOnChanges() {
    this.setGridColnData();
  }

  setGridColnData() {
    if (this.radioType === "Group") {
      this.selectedColDefinition = groupCol
    } else if (this.radioType === "Member File") {
      this.selectedColDefinition = memberFileCol
    } else {
      this.selectedColDefinition = memberDailyCol
    }

    this.columnDefs = this.selectedColDefinition;
    if (this.gridData === null || undefined) {
      this.gridApi?.showLoadingOverlay();
    } else {
      this.gridApi?.hideOverlay();
      this.gridRowData = this.gridData;
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  onSelectionChanged(event) {
    const selectedRows = this.gridApi.getSelectedRows();
    this.selectedRowsArr = selectedRows;
    this.selectedRowsArr = this.selectedRowsArr.map(item => {
      return {
        ...item,
        reportType: this.radioType
      }
    })
    if (selectedRows.length > 0) {
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
  }

  deleteRecords() {
    this.gridApi?.showLoadingOverlay();
    if (this.selectedRowsArr && this.selectedRowsArr.length > 0) {
      this.suspenseService.deleteSuspenseRecords(this.selectedRowsArr).subscribe(res => {
        this.isRowSelected = false;
        this.alertService.sendAlert({
          headline: res.response[0],
          alertType: CVSAlertType.Success,
          removedAfterMilliseconds: 10000,
          alertDocumentHolderName: '#header-alert',
        })
        this.suspenseService.getSuspenseRecords(this.searchPayload).subscribe(res => {
          if(res.response && res.response.length > 0) {
            this.gridRowData = res.response;
          } else if(res.errorMessage){
            this.alertService.sendAlert({
              headline: res.errorMessage,
              alertType: CVSAlertType.Error,
              removedAfterMilliseconds: 10000,
              alertDocumentHolderName: '#header-alert',
            })
            this.gridData = [];
          } else {
            this.gridData = [];
          }
        },
        err => {
          this.alertService.sendAlert({
            headline: 'Something went wrong. Please try again.',
            alertType: CVSAlertType.Error,
            removedAfterMilliseconds: 10000,
            alertDocumentHolderName: '#header-alert',
          })
          this.gridData = null;
        });
      }, err => {
        this.alertService.sendAlert({
          headline: 'Something went wrong. Please try again.',
          alertType: CVSAlertType.Error,
          removedAfterMilliseconds: 10000,
          alertDocumentHolderName: '#header-alert',
        })
        this.gridData = null;
        this.gridApi?.hideOverlay();
      })
    }
  }

  exportFile() {
    const keys = this.columnDefs.map(rec => rec.field);
    var params = {
      columnKeys: keys.filter(key => !!key)
    };
    this.gridApi.exportDataAsCsv(params);
  }
}
