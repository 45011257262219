import {Injectable} from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {questionnaireFormConfig} from '../epr/questionnaire/QuestionnaireConfig';
import {InputType} from '../models/FormFieldConfig';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireSettingsService {

  public isQuestionnaireSettingSaveDisabled= new BehaviorSubject(false);


  private static generateRow(controlName, metadata, field) {
    const dataToUpdate = { controlName, label: field.label, hyperlink: '', consolidateProfileLogic: ''};
    for (let index = 0; index < metadata.length; index++) {
                switch (index) {
                  case 0:
                    dataToUpdate.hyperlink = metadata[index][controlName];
                    break;
                  case 1:
                    dataToUpdate.consolidateProfileLogic = metadata[index][controlName];
                    break;

                }
            }
    return dataToUpdate;
  }
  private static metadataToRowData(metadata) {

    const allRowData = [];

    questionnaireFormConfig.map(section => {
      section.map(field => {
        let dataToUpdate;
        if (field.inputType === InputType.HEADER) {
          allRowData.push([]);
        } else {
          const rowData = allRowData[allRowData.length - 1];
          if (field.inputType === InputType.HORIZONTAL_FIELD_SET) {
            const controlName = field.subFormGroup[0].controlName; // uses the first controlName in subFormGroup to track hyperlink
            dataToUpdate = this.generateRow(controlName, metadata, field);
            rowData.push(dataToUpdate);
          } else if (field.inputType !== InputType.SUB_HEADER) {
            const controlName = field.controlName;
            dataToUpdate = this.generateRow(controlName, metadata, field);
            rowData.push(dataToUpdate);
            /*
            rowData.push({ controlName, label: field.label,
              hyperlink: metadata.length !== 0 ? metadata[0][controlName] : '' ,
              preload: metadata.length !== 0 ? metadata[1][controlName] : '' ,
              stage: metadata.length !== 0 ? metadata[2][controlName] : '' ,
              intRcd: metadata.length !== 0 ? metadata[3][controlName] : '' ,
              adtlPrms: metadata.length !== 0 ? metadata[4][controlName] : '' ,
              crosswalk: metadata.length !== 0 ? metadata[5][controlName] : '' ,
              mbrrqflds: metadata.length !== 0 ? metadata[6][controlName] : '' , });*/
          }
        }
      });
    });

    return allRowData;
  }

  private static rowDataToMetadata(rowData, id) {
  const allRowData: Array<any> = [];
  for (const val of id) {
    let metadata = {id: val};
    rowData.forEach((grid, gridIndex) => {
      grid.forEach((row, rowIndex) => {
        /*to push only changes made in hyperlink column has to save in the db as first row*/
            if (val === 0) {
          const controlName = rowData[gridIndex][rowIndex].controlName;
          const hyperlink = rowData[gridIndex][rowIndex].hyperlink;
          metadata = {
            ...metadata,
            [controlName]: hyperlink
          };
        } else if (val === 1) {
          const controlName = rowData[gridIndex][rowIndex].controlName;
          const consolidateProfileLogic = rowData[gridIndex][rowIndex].consolidateProfileLogic;
          metadata = {
            ...metadata,
            [controlName]: consolidateProfileLogic
          };
        }
      });
    });
    allRowData.push(metadata);
  }
  return allRowData;
  }

  constructor(private http: CVSHttpClient) {}

  getQuestionnaireHyperlinks(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/settings/questionnaire/hyperlinks/all`);
  }
  //
  // getQuestionnaireSettingsRowData(): Observable<any> {
  //   return this.http.get(`${environment.baseUrl}/settings/questionnaire/hyperlinks/all`).pipe(
  //     map(metadata => QuestionnaireSettingsService.metadataToRowData(metadata))
  //   );
  // }

  // saveQuestionnaireHyperlinks(rowData, id: number[]): Observable<any> {
  //   return this.http.post(`${environment.baseUrl}/settings/questionnaire/hyperlinks/saveall`,
  //     QuestionnaireSettingsService.rowDataToMetadata(rowData, id));
  // }
  saveQuestionnaireHyperlinks(rowData): Observable<any> {
    return this.http.post(`${environment.baseUrl}/admin/settings/saveQuestionnaire`,rowData);
      // QuestionnaireSettingsService.rowDataToMetadata(rowData, id));
  }

  getQuestionnaireSettingsRowData() {
     return this.http.get(`${environment.baseUrl}/admin/settings/questionnaire`);

  }
}
