
import {Contact} from '../models/Contact';
import {Spedm} from '../models/Spedm';
import {
  Actions,
  LOAD_ALL_PARAM_MAP,
  LOAD_ALL_PARAM_MAP_SUCCESS, LOAD_PARAM_MAP, LOAD_PARAM_MAP_SUCCESS, SAVE_PARAM_MAP_SUCCESS
} from '../actions/paramMap.action';
import {ParamMap} from '../models/ParamMapping';
import {LOAD_ALL_FILETRACKER_SUCCESS, LOAD_DATA, LOAD_FILETRACKER_SUCCESS} from '../actions/filetracker.action';
import {Filetracker} from '../models/Filetracker';

export interface State {
  allParamMap: ParamMap[];
  selectedParamMapId: number;
  selectedParamMap: ParamMap;
  ParamMap: any[];
  isEdit: boolean;
}

export const initialState: State = {
  allParamMap: [],
  selectedParamMapId: undefined,
  selectedParamMap: {},
  ParamMap: [],
  isEdit: false
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case LOAD_ALL_PARAM_MAP_SUCCESS:
      return {...state, allParamMap: action.payload as ParamMap[]};

    case LOAD_PARAM_MAP:
      return {...state };

    case LOAD_DATA:
      state.allParamMap = action.payload as any;
      return {...state };

    case LOAD_PARAM_MAP_SUCCESS:
      const allFiletrackers = (Array.isArray(action.payload)) ? action.payload : [action.payload];
      return {...state, selectedParamMap: action.payload as Filetracker, allParamMap: allFiletrackers as ParamMap[]};

    case SAVE_PARAM_MAP_SUCCESS:
      const newParamMap = state.allParamMap.concat(action.payload);
      newParamMap.sort((a, b) => {
        // if (a.id < b.id) {
        //   return -1;
        // }
        // if (a.id > b.id) {
        //   return 1;
        // }
        return 0;
      });
      return {...state, allParamMap: newParamMap};


    default:
      return state;
  }
}
export const getAllParamMap = (state: State) => state.allParamMap;
export const getSelectedParamMap = (state: State) => state.selectedParamMap;


