import {Action} from '@ngrx/store';
import {LOAD_QUESTIONNAIRE_FORM} from './questionnaire.action';
import {AddProjectTrackerFormAction, ClearEprAction, CopyEprAction, DeleteProjectTrackerFormAction} from './epr.action';
import {ProjectType} from '../models/ProjectTypeEnum';

export const UPDATE_PROJECT_TRACKER = '[PROJECT_TRACKER] Update Project Tracker Form';
export const LOAD_PROJECT_TRACKER_SUCCESS = '[PROJECT_TRACKER] Load Project Tracker Form Success';
export const PROJECT_TYPE_CHANGED = '[PROJECT_TRACKER] Project Type Changed';

export class UpdateProjectTrackerFormAction implements Action {
  readonly type = UPDATE_PROJECT_TRACKER;

  constructor(public payload: any) {  }
}

export class LoadProjectTrackerAction implements Action {
  readonly type = LOAD_QUESTIONNAIRE_FORM;
  readonly id: number;

  constructor(public payload: any) {
    this.id = 1;
  }
}

export class LoadProjectTrackerActionSuccess implements Action {
  readonly type = LOAD_PROJECT_TRACKER_SUCCESS;

  readonly id: number;

  constructor(public payload: any) {
    this.id = 1;
  }
}

export class ProjectTypeChangeAction implements Action {
  readonly type = PROJECT_TYPE_CHANGED;

  constructor(public payload: ProjectType) {  }
}

export type Actions =
  AddProjectTrackerFormAction
  | DeleteProjectTrackerFormAction
  | UpdateProjectTrackerFormAction
  | LoadProjectTrackerAction
  | LoadProjectTrackerActionSuccess
  | ClearEprAction
  | CopyEprAction;
