import {Action, createAction, props} from '@ngrx/store';
import { Permission } from '../models/Authorization';
import { PermissionsObject } from '../models/PermissionsObject';
import { User } from '../models/User';

import { User_PBM } from '../models/User_PBM';

export const SET_LOGGED_IN_USERPBM = '[USER] Set Logged In User';
export const SET_PERMISSIONS_OBJECT = '[PERMISSIONS OBJECT] Set Permissions Object'
export class SetLoggedInUserPBMAction implements Action {
  readonly type = SET_LOGGED_IN_USERPBM;
  readonly user: User_PBM;
  readonly role:Permission;
  readonly legacyUser: User;
  readonly permissionObject: PermissionsObject
}

export const setLoggedInUserPBMAction = createAction(
  SET_LOGGED_IN_USERPBM,
  props<{user: User_PBM,role:Permission,legacyUser:User}>()
  
);
export class SetPermissionsObjectAction implements Action {
  readonly type = SET_PERMISSIONS_OBJECT;
  constructor(public permissionObject: PermissionsObject) { 
    
  }


}
  



export type Actions = SetLoggedInUserPBMAction | SetPermissionsObjectAction