import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {CVSHttpClient} from 'angular-component-library';
import {Contact} from '../models/Contact';
import {Spedm} from '../models/Spedm';
import {ParamMap} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ParamMapService {

  paramMap: ParamMap;
  isNew: boolean;

  constructor(private http: CVSHttpClient) {  }

  getAll(): Observable<any> {
    return this.http.get(`${environment.baseProfileManagementUrl}/allParamMapping`);
  }

  getParamMapByFileType(fileType: string): Observable<any> {
    return this.http.get(`${environment.baseProfileManagementUrl}/paramMapping/${fileType}`);
  }
  getParamMapById(id: number): Observable<any> {
    return this.http.get(`${environment.baseProfileManagementUrl}/paramMapping/${id}`);
  }

  saveParamMap(paramMap: ParamMap): Observable<any> {
    return this.http.post(`${environment.baseProfileManagementUrl}/saveParamMapping`, paramMap);
  }

  deleteParamMap(Id: number): Observable<any> {
    return this.http.delete(`${environment.baseProfileManagementUrl}/deleteParamMapping/${Id}`);
  }

  getAddParams(request: any, type: any): Observable<any> {
    return this.http.post(`${environment.rxClaimUrl}/dataExtract/additionalParameters?type=${type}`, request);
  }
}
