import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {EprFeedService} from '../services/eprfeed.service';
import * as eprfeed from '../actions/epr-feed.action';
import {EprFeed} from '../models/EprFeed';
import {CVSAlertService, CVSAlertType} from 'angular-component-library';
import {NoopAction} from '../actions/noop.action';
import {DELETE_EPRFEED, DeleteEprFeedAction, DeleteEprFeedSuccessAction} from '../actions/epr-feed.action';
import {Router} from '@angular/router';
import * as webeprfeeds from '../actions/web-eprfeed.action';
import {WebEprFeed} from '../models/WebEprFeed';

@Injectable()
export class EprFeedManagementEffects {
  loadEprFeed$ = createEffect(() => this.actions$.pipe(
    ofType(eprfeed.LOAD_EPRFEED),
    mergeMap((action) => this.eprFeedService.getEprFeeds(action['payload'])
      .pipe(
        map(eprFeedvalues => ({ type: eprfeed.LOAD_EPRFEED_SUCCESS, payload: eprFeedvalues as EprFeed })),
        catchError(() => of({type: eprfeed.LOAD_EPRFEED_FAILURE}))
      ))
    )
  );
  saveEprFeed$ = createEffect(() => this.actions$.pipe(
    ofType(eprfeed.SAVE_EPRFEED),
    mergeMap(action => this.eprFeedService.saveEprFeed(action['payload'])
      .pipe(
        map(eprfeedvalue => ({ type: eprfeed.SAVE_EPRFEED_SUCCESS, payload: eprfeedvalue as EprFeed })),
        catchError(() => of({type: eprfeed.SAVE_EPRFEED_SUCCESS_FAILURE}))
      ))
    )
  );
  editEprFeed$ = createEffect(() => this.actions$.pipe(
    ofType(eprfeed.EDIT_EPRFEED),
    mergeMap(action => this.eprFeedService.editEprFeed(action['payload']['eprFeed'], action['payload']['id'])
      .pipe(
        map(eprfeedvalue => ({ type: eprfeed.EDIT_EPRFEED_SUCCESS, payload: eprfeedvalue as EprFeed })),
        catchError(() => of({type: eprfeed.EDIT_EPRFEED_SUCCESS_FAILURE}))
      ))
    )
  );
  deleteEprFeed$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteEprFeedAction>(DELETE_EPRFEED),
    mergeMap(action => this.eprFeedService.deleteEpr(action.payload)
      .pipe(
        map(() => new DeleteEprFeedSuccessAction()),
        tap(() => this.router.navigateByUrl('')),
        catchError(error => {
          return of(new NoopAction());
        })
      )
    )
  ));
  constructor(
    private actions$: Actions,
    private eprFeedService: EprFeedService,
    private alertService: CVSAlertService,
    private router: Router,
  ) { }
}
