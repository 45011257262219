export const eprUserAccessModelColumns=[
    {headerName: 'First Name', field: 'firstName', sortable: true, filter: true, resizable: true,},
    {headerName: 'Last Name', field: 'lastName', sortable: true, filter: true, resizable: true},
    {headerName: 'Email', field: 'emailAddress', sortable: true, filter: true, resizable: true},
    {headerName: 'Phone', field: 'phone', sortable: true, filter: true, resizable: true},
    {headerName: 'Status', field: 'status', sortable: true, filter: true, resizable: true},
    {headerName: 'Super Client ID', field: 'superClientId', sortable: true, filter: true, resizable: true},
    {headerName: 'Client Code', field: 'clientCode', sortable: true, filter: true, resizable: true},
    {headerName: 'Carrier', field: 'carrier', sortable: true, filter: true, resizable: true},
];
