import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-mapping-confirmation',
  templateUrl: './mapping-confirmation.component.html',
  styleUrls: ['./mapping-confirmation.component.scss']
})
export class MappingConfirmationComponent implements OnInit {
  form: UntypedFormGroup;
  description: string;
  providedInfo: any;
  constructor(
      private fb: UntypedFormBuilder,
      private dialogRef: MatDialogRef<MappingConfirmationComponent>,
      @Inject(MAT_DIALOG_DATA) data ,

      ) {
      this.description = data.description;
      this.providedInfo = data;
  }

  ngOnInit() {
    if (this.providedInfo.type === 'cancel_edit') {

    }
  }

  save() {
      this.dialogRef.close(1);
  }

  close() {
      this.dialogRef.close(null);
  }


}
