<form [formGroup]="mappingForm" (ngSubmit)="addLogicCode()" >
  <span>
  <mat-icon class="close-btn" svgIcon="close-btn--s" (click)="closeModal()"></mat-icon>
</span>
  <h4 class="header-text">Add Logic Code</h4>
  <div class="modal-body-container" >
    <div>
      <p>Logic Code</p>
      <p>{{ logicCodeValue$ | async}}</p>
    </div>
    <textarea class="mapping-instructions" formControlName="mappingInstruction" name="mapping-instructions" placeholder="Enter Mapping Instructions here..."></textarea>
    <textarea class="mapping-description" formControlName="mappingDescription" name="mapping-description" placeholder="Enter Mapping Description here..."></textarea>
  </div>
  <div class="btn-container">
    <span class="save-logic-code">
  <button id="save-logic-code" [disabled]="!mappingForm.valid" type="submit" class="cvs-btn-primary" >
    <mat-icon svgIcon="folder--s"></mat-icon>
    Add Logic Code
  </button>
</span>
    <span class="cancel-btn">
  <button id="cancel-btn" (click)="closeModal()" class="cvs-btn-white" type="button">
    <mat-icon svgIcon="folder--s"></mat-icon>
    Cancel
  </button>
</span>
  </div>
</form>
