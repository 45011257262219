import { Component, ViewEncapsulation } from '@angular/core';
import { ITooltipAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-custom-tooltip',
  template: ` <div class="custom-tooltip">
    <p>
      <span>{{ value }}</span>
    </p>
  </div>`,
  styles: [
    `
      :host {
        position: absolute;
        width: 150px;
        height: 70px;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip p {
        margin: 5px;
        white-space: nowrap;
      }

      .custom-tooltip p:first-of-type {
        font-weight: bold;
      }
    `,
  ],
})
export class CustomTooltipComponent implements ITooltipAngularComp {
  value: any;

  constructor() {
  }
  agInit(params): void {
    if (params && params.value) {
      const val = params.value.split(',');
      val.forEach(element => {
        this.value = this.value + '<div>' + element + '</div>';
      });
    }
    //  this.value = params.value;
  }
}
