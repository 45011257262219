import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ChecklistSettingsComponent} from './checklist-settings/checklist-settings.component';
import {QuestionnaireSettingsComponent} from './questionnaire-settings/questionnaire-settings.component';
import {QuestionnaireSettingsService} from "../../services/questionnaire-settings.service";
import {CVSAlertService, CVSAlertType} from "angular-component-library";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit{
  @ViewChild(ChecklistSettingsComponent) checklistSettingsComponent;
  @ViewChild(QuestionnaireSettingsComponent) questionnaireSettingsComponent;
  saveDisabled = false;

  showingChecklistSettings = true;
  showingQuestionnaireSettings = false;
  isQuestionnaireSettingSaveDisabled: boolean;

  constructor(private questionnaireSettingService: QuestionnaireSettingsService,
              private alertService: CVSAlertService){

  }
  showChecklistSettings() {
    if(!this.isQuestionnaireSettingSaveDisabled){
      if (confirm('Changes have NOT been saved! Click \'OK\' to exit WITHOUT saving.')) {
        this.showingChecklistSettings = true;
        this.showingQuestionnaireSettings = false;
      } else {
        this.showingChecklistSettings = false;
        this.showingQuestionnaireSettings = true;
      }
    } else {
      this.showingChecklistSettings = true;
      this.showingQuestionnaireSettings = false;
    }
  }

  showQuestionnaireSettings() {
    this.isQuestionnaireSettingSaveDisabled= true;
    this.showingQuestionnaireSettings = true;
    this.showingChecklistSettings = false;
  }
  ngOnInit(){
    if(this.showingQuestionnaireSettings){
      this.isQuestionnaireSettingSaveDisabled= true;
    }
    this.questionnaireSettingService.isQuestionnaireSettingSaveDisabled.subscribe(res=>{
      this.isQuestionnaireSettingSaveDisabled= !res;
    })
  }
  save(): void {
    if (this.showingChecklistSettings) {
      this.checklistSettingsComponent.save();
    } else {
      this.questionnaireSettingsComponent.save();
    }
  }

  isSaveDisabled() {
    if (this.showingChecklistSettings) {
      return false;
    } else if (this.questionnaireSettingsComponent) {
      return !this.questionnaireSettingsComponent.isQuestionaireSettingFormValid();
    }

    return false;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewChecked() {
    setTimeout(() => {
      this.saveDisabled = this.isSaveDisabled();
    });

  }

}
