import {AfterViewInit, Component, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {TranslationsService} from '../../../services/translations.service';
import {CVSAlertService, CVSAlertType} from 'angular-component-library';

@Component({
  selector: 'app-translations-data-grid',
  templateUrl: './translations-data-grid.component.html',
  styleUrls: ['./translations-data-grid.component.scss']
})
export class TranslationsDataGridComponent implements OnInit, AfterViewInit {

  readonly translationsDataColumns = [
    {field: 'Sequence', class: 'table-cell-min'},
    {field: 'Input', class: 'table-cell'},
    {field: 'Output', class: 'table-cell'},
    {field: 'Soft Text', class: 'table-cell'},
    {field: 'Advance', class: 'table-cell-min'},
    {field: 'Reset To Preload', class: 'table-cell'},
    {field: 'Cancel', class: 'table-cell-min'},
    {field: 'Details', class: 'table-cell-min'},
    {field: 'Summary', class: 'table-cell-min'},
    {field: 'Status', class: 'table-cell-min'},
    {field: 'Source', class:'table-cell-min'}
  ];

  dataRows = [];
  rowsTobeDeleted = [];
  rowsTobeUpdated = [];
  sequenceColumnIDs = [1];
  payloadRequest = [];
  statusValues = [
    'Processing',
    'Pending',
    'Error',
    'Completed'
  ];
  selectedStatus = '';
  sourceValues = ['RxClaim','SSOE']
  translationAddMessage: any;
  translationEditMessage: any;
  translationDeleteMessage: any;
  changedRow: any;
  translationIds = []

  constructor(private translationService: TranslationsService, private alertService: CVSAlertService) { }

  ngOnInit() {
    this.getAllTranslations();
  }

  ngAfterViewInit(): void {
    const sourceCell: HTMLElement = document.querySelector('.theader[title="Source"]');
    sourceCell.setAttribute('style', 'border-right: 1px solid grey');
  }

  getAllTranslations() {
    this.translationService.getTranslations().subscribe(res => {
      this.dataRows = res;
      this.payloadRequest = [];
      this.dataRows.sort((a, b) => a.translationId - b.translationId);
      this.updateSequenceIDs();
    });
    this.changedRow = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dataRows, event.previousIndex, event.currentIndex);
    this.dataRows.forEach((row,index)=>{
      row.translationId = this.translationIds[index]
    })
    this.addTranslations(this.dataRows, 'Update Successful');
    this.updateSequenceIDs();
  }
  
  updateSequenceIDs() {
    this.translationIds = []
    this.sequenceColumnIDs = Array.from({length: this.dataRows.length}, (_, i) => i + 1);

    this.dataRows.forEach((row,index)=>{
      this.translationIds.push(row.translationId)
    })
  }

  addNewTableRow() {
    this.dataRows.push({});
    this.payloadRequest.push({});
    this.sequenceColumnIDs.push(this.dataRows.length);
    this.changedRow = true;
  }

  selectedRows(event) {
    if (event.checked) {
      const rowId = event.source.value;
      this.rowsTobeDeleted.push(rowId);
    } else {
      this.rowsTobeDeleted.splice(this.rowsTobeDeleted.indexOf(event.source.value), 1);
      this.sequenceColumnIDs = Array.from({length: this.dataRows.length}, (_, i) => i + 1);
    }
  }

  deleteTableRow() {
    const dataRowsIndex = Array.from({length: this.dataRows.length}, (_, i) => i);
    const updatedDataRowsIndexes = dataRowsIndex.filter((el) => {
      return this.rowsTobeDeleted.indexOf(el) === -1;
    });
    const newDataRows = [];

    this.rowsTobeDeleted.forEach(el => {
      this.translationService.deleteTranslations([this.dataRows[el]]).subscribe(res => { console.log(res); });
      // this.dataRows.splice(this.dataRows[el], 1);
    });

    this.rowsTobeDeleted.forEach( el => {
      this.payloadRequest.pop();
    });

    updatedDataRowsIndexes.forEach(el => {
      if (this.dataRows[el]) {
        newDataRows.push(this.dataRows[el]);
      }
    });

    this.translationService.getTranslations().subscribe(rows => {
      this.dataRows = newDataRows;
      this.changedRow = false;
    });

    this.rowsTobeDeleted = [];
    this.updateSequenceIDs();
    this.showAlert('Delete Successful', CVSAlertType.Success);
  }

  saveTranslations() {
    this.generatePayload();
  }

  updateChangeEvent(event, dataRow, sequenceId) {
    if ((dataRow.translationId) && (this.rowsTobeUpdated.indexOf(sequenceId) === -1)) {
      this.changedRow = true;
      this.rowsTobeUpdated.push(sequenceId);
    }
  }

  generatePayload() {
  //  Getting all the translations row ids
    const payload = [];
    for (let row = 0; row < this.sequenceColumnIDs.length; row++) {
        if (this.getValueByDomID('#translation-id-' + row) === '0') {
          payload.push(
            this.commonPayload(row, false)
          );
        }
      }
    this.addTranslations(payload, 'Save Successful');

    if (this.rowsTobeUpdated.length > 0) {
      this.updateTranslations();
    }
  }

  commonPayload(row, isTranslationId) {
    const payload = {
      input: this.getValueByDomID('#options-to-add-input-' + row).trim(),
      output: this.getValueByDomID('#options-to-add-output-' + row).trim(),
      softText: this.getValueByDomID('#options-to-add-soft-text-' + row).trim(),
      isAdvanced: this.getCheckedValuesByRow('#isAdvance-' + row),
      isResetToPreload: this.getCheckedValuesByRow('#isResetToPreload-' + row),
      isCancelled: this.getCheckedValuesByRow('#isCancelled-' + row),
      isDetailed: this.getCheckedValuesByRow('#isDetail-' + row),
      isSummary: this.getCheckedValuesByRow('#isSummary-' + row),
      status: this.getValueByDomID('#translation-status-' + row),
      source: this.getValueByDomID('#translation-source-' + row),
    };
    if (isTranslationId) {
      payload['translationId'] = this.dataRows[row].translationId;
    }
    this.changedRow = true;
    return payload;
  }

  addTranslations(payload, alertMessage) {
    this.translationService.addTranslations(payload).subscribe(response => {
      this.getAllTranslations();
      this.showAlert(alertMessage, CVSAlertType.Success);
    });
  }

  updateTranslations() {
    const payload = [];
    this.rowsTobeUpdated.forEach(rowId => {
      payload.push(
        this.commonPayload(rowId, true)
      );
    });
    this.addTranslations(payload, 'Update Successful');
    this.rowsTobeUpdated = [];
  }

  getValueByDomID(rowId) {
    let inputVal = document.querySelector(rowId);
    if (inputVal && Number(inputVal.value) !== 0) {
      inputVal = inputVal.value;
    } else if (inputVal && Number(inputVal.value) === 0) {
      inputVal = inputVal.value;
    } else {
      inputVal = ' ';
    }

    if (!inputVal && this.statusValues.indexOf(document.querySelector(rowId).innerText.replace('\t', '')) > -1) {
      inputVal = document.querySelector(rowId).innerText.replace('\t', '');
    }

    if (!inputVal && this.sourceValues.indexOf(document.querySelector(rowId).innerText.replace('\t', '')) > -1) {
      inputVal = document.querySelector(rowId).innerText.replace('\t', '');
    }

    return inputVal;
  }

  getCheckedValuesByRow(checkBoxID) {
    const checkedCheckboxClass = 'mat-checkbox-checked';
    return document.querySelector(checkBoxID).classList.contains(checkedCheckboxClass) ? 'Y' : 'N';
  }

  showAlert(alertMessage, alertType) {
    this.alertService.sendAlert({
      headline: alertMessage,
      alertType,
      removedAfterMilliseconds: 5000,
      alertDocumentHolderName: '#translations-alert',
    });
  }
}
