import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { UsersService } from "../services/users.service";
import { BehaviorSubject, Observable, Subject, Subscription, of } from "rxjs";
import * as moment from "moment";
import * as ColumnSets from './userSettingColumns';
import { CVSAlertService, CVSAlertType } from "angular-component-library";
import { switchMap, tap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import * as fromRoot from '../reducers';
import { EditUserAction, LoadUsersSettingAction } from "../actions/user-management.action";
import { User } from "../models/User";
import { AgGridAngular } from "ag-grid-angular";
import { LoadEprAction } from "../actions/epr.action";

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})

export class UserSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('usersettinggrid', { static: false }) usersettinggrid: AgGridAngular;

  userSettingsForm = new FormGroup({
    email: new FormControl(''),
    backup: new FormControl(''),
    leader: new FormControl(''),
    allDayEvent: new FormControl(false),
    startDate: new FormControl(''),
    startTime: new FormControl({ value: '', disabled: false }),
    endDate: new FormControl(''),
    endTime: new FormControl({ value: '', disabled: false }),
  })
  scheduleEvnetsTabActive = false;
  userSettingColumns = ColumnSets.userSettingColumns;
  usersPliList = new BehaviorSubject([]);
  
  constructor(private userService: UsersService, private alertService: CVSAlertService,
    private store: Store<fromRoot.AppState>) {
      this.subs.push(this.userService.user.subscribe((user=>{
        if(user){
          this.store.dispatch(new LoadUsersSettingAction(user.id));
          this.loggedLdapId = user.ldapId;
        }
      }))
    );

    }

  user;
  startDate: string;
  endDate: string;
  startTime: any;
  endTime: any;
  allUsers: string[] = [];
  endDateError = false;
  startDateError = false;
  endTimeError = false;
  startTimeError = false;


  activeUsers$ :Observable<any> = this.store.select(fromRoot.getActiveUsers) as Observable<User[]>;
  leaderMaterialUsers$ :Observable<any> = this.store.select(fromRoot.getActiveUsersByRole,
        ['ELG_ELIGIBILITY_RELATIONSHIP_MANAGER','ELG_MANAGER', "ELG_LEADERSHIP"]) as Observable<User[]>;
  currentUserFname$ = new BehaviorSubject(null);
  currentUserLname$ = new BehaviorSubject(null);
  currentUserRole$ = new BehaviorSubject(null);
  currentUserEmployerId$ = new BehaviorSubject(null);
  currentUserEmail$ = new BehaviorSubject(null);
  currentUserId$ = new BehaviorSubject(null);
  showSpinner$: Subject<boolean> = new BehaviorSubject(true);
  loggedLdapId : string;
  subs:Subscription[] = [];
  @ViewChild('checkbox') checkbox;

  ngOnInit() {
   this.subs.push(
     this.store
       .select(fromRoot.getUserSettingData)
       .pipe(
         switchMap((allUsers: any) => {
           if (allUsers && allUsers.length) {
             this.showSpinner$.next(false);
             this.allUsers = allUsers;
             const currentUser = allUsers.find(
               (data) => data.ldapId == this.loggedLdapId
             );

             this.currentUserFname$.next(currentUser.firstName);
             this.currentUserLname$.next(currentUser.lastName);
             this.currentUserRole$.next(currentUser.authorization.permissionRole);
             this.currentUserEmail$.next(currentUser.email);
             this.currentUserEmployerId$.next(currentUser.ldapId);
             this.currentUserId$.next(currentUser.id);
             this.user = currentUser;
             this.startDate = currentUser?.outofofficeFromDate;
             this.endDate = currentUser?.outofofficeToDate;
             this.startTime = this.getTimeIn24HourFormat(
               currentUser?.outofofficeFromTime
             );
             this.endTime = this.getTimeIn24HourFormat(
               currentUser?.outofofficeToTime
             );
             this.userSettingsForm.patchValue({
               email: currentUser.outofofficeTextEmail,
               allDayEvent: currentUser.outofofficeAllDayEvent,
               startDate: currentUser.outofofficeFromDate,
               startTime: this.getTimeIn24HourFormat(
                 currentUser.outofofficeFromTime
               ),
               endDate: currentUser.outofofficeToDate,
               endTime: this.getTimeIn24HourFormat(
                 currentUser.outofofficeToTime
               ),
               backup: currentUser ? currentUser.backUpUser : null,
               leader: currentUser ? currentUser.leader : null,
             });

             // this.userSettingsForm.patchValue({
             //   backup: currentUser ? currentUser.backUpUser : null,
             //   leader: currentUser ? currentUser.leader : null,
             //   // backup: allUsers.find(aUser => aUser.id === this.user?.backUpUser?.id),
             //   // leader: allUsers.find(aUser => aUser.id === this.user?.leader?.id),
             // })
             if (this.userSettingsForm.get("allDayEvent").value) {
               this.userSettingsForm
                 .get("startTime")
                 .disable({ emitEvent: false });
               this.userSettingsForm
                 .get("endTime")
                 .disable({ emitEvent: false });
             }
           }
           return of(allUsers);
         })
       )
       .subscribe()
   );

    this.currentUserId$.subscribe(res => {
      if(res){
      this.getUserInfoByUserId(res);
      }
  });
  }

  public allDayEventChecked(event: any) {
    if (event.checked) {
      this.startTime = "00:00";
      this.endTime = "00:00";
    }

    if (event.checked) {
      this.userSettingsForm.get('startTime').disable({ emitEvent: false })
      this.userSettingsForm.get('endTime').disable({ emitEvent: false })
    } else {
      this.userSettingsForm.get('startTime').enable({ emitEvent: false })
      this.userSettingsForm.get('endTime').enable({ emitEvent: false })
    }
    this.userSettingsForm.patchValue({
      startTime: "00:00",
      endTime: "00:00"
    })
  }

  onDateChange(event, dateType) {
    let currentEvent = this.getDate(event)
    if (dateType === 'startDate') {
      this.startDate = currentEvent;
    } else {
      this.endDate = currentEvent;
    }
    this.compareDates(this.startDate, this.endDate);
    this.updateTime({
      target: {
        value: this.startTime
      }
    }, true)
  }

  getDate($event: any) {
    if ($event.value) {
      const date = JSON.stringify($event.value);
      const dt = date.substring(1, 11);
      return dt;
    }
    return null;
  }

   setColumnType() {
    this.usersettinggrid?.api?.setColumnDefs(this.userSettingColumns);
  }

  getUserInfoByUserId(userId:any) {
    this.userService.getUserInfoByUserId(userId).subscribe(res => {
      if(res){
        this.usersPliList = res;
        this.getNewTableData();
      }
    });
  }

   getNewTableData() {
    let sortOrder;
     this.setColumnType();
      sortOrder = [];
      const tableData = [];
      this.usersPliList.forEach((res: any) => {
        const usersPliObject: any = {
          select: false,
          clientName: res.clientName,
          pli:res.pli,
          carrier: res.carrier,
          ec: res.ecName,
          eprId: res.eprId,
          selfService: res.selfService,
          loadReports: res.loadReports,
        };
        tableData.push(usersPliObject);
      });
      this.usersettinggrid?.api?.setRowData(tableData);
  }
   filterTables(query: any) {
    this.usersettinggrid?.api?.setQuickFilter(query);
   }
  
   onRowClicked($event){
    this.store?.dispatch(new LoadEprAction(($event?.data?.eprId),'To Open EPR Link'));
  }


  private compareDates(d1, d2) {
    let date1 = new Date(d1).getTime();
    let date2 = new Date(d2).getTime();

    if (date1 > date2) {
      this.userSettingsForm.get('startDate').setErrors({ 'invalid': true })
      this.alertService.sendAlert({
        headline: 'Start Date cannot be greater than End Date.',
        alertType: CVSAlertType.Error,
        alertDocumentHolderName: '#dateValidator',
      });
    } else if (date1 > date2) {
      this.userSettingsForm.get('endDate').setErrors({ 'invalid': true })
      this.alertService.sendAlert({
        headline: 'End Date cannot be less than Start Date.',
        alertType: CVSAlertType.Error,
        alertDocumentHolderName: '#dateValidator',
      });
    } else {
      this.userSettingsForm.get('startDate').setErrors(null)
      this.userSettingsForm.get('endDate').setErrors(null)
      this.alertService.clearAlert('#dateValidator');
    }
  };

  updateTime(event, timeType) {
    const updatedEvent = event?.target?.value;
    if (timeType) {
      this.startTime = updatedEvent;
    } else {
      this.endTime = updatedEvent;
    }
    if (this.startDate === this.endDate) {
      if (this.endTime < this.startTime) {
        this.userSettingsForm.get('startTime').setErrors({ 'invalid': true })
        this.alertService.sendAlert({
          headline: 'End Time cannot be less than Start Time.',
          alertType: CVSAlertType.Error,
          alertDocumentHolderName: '#dateValidator',
        });
      } else if (this.startTime > this.endTime) {
        this.userSettingsForm.get('endTime').setErrors({ 'invalid': true })
        this.alertService.sendAlert({
          headline: 'Start Time cannot be greater than End Time.',
          alertType: CVSAlertType.Error,
          alertDocumentHolderName: '#dateValidator',
        });
      } else {
        this.userSettingsForm.get('startTime').setErrors(null)
        this.userSettingsForm.get('endTime').setErrors(null)
        this.alertService.clearAlert('#dateValidator');
      }
    }
  }

  convertTimeTo12HoursFormat(time: string) {
    if (time) {
      const [hours, minutes] = time?.split(':')
      const date = new Date(`${this.endDate} ${hours}:${minutes}:00`)
      const convertedHours = date.getHours() % 12 || 12;
      let convertedHoursString = convertedHours.toString()
      if (convertedHours < 10) {
        convertedHoursString = `0${convertedHoursString}`
      }
      const convertedMinutes = date.getMinutes();
      const ampm = date.getHours() < 12 ? "AM" : "PM";
      const formattedTime = `${convertedHoursString}:${convertedMinutes.toString().padStart(2, "0")} ${ampm}`

      return formattedTime;
    }
  }

  getTimeIn24HourFormat(str) {
    if (str) {
      const time = str?.split(" ")[0];
      const AMPM = str?.split(" ")[1]?.toUpperCase();
      if (AMPM === "AM") {
        if (Number(time.split(":")[0]) > 11) {
          return `0${Number(time.split(":")[0]) - 12}:${time.split(":")[1]}`;
        }
      } else {
        if(time.split(":")[0] === '12') {
          return `${Number(time.split(":")[0])}:${time.split(":")[1]}`;
        } else {
          return `${Number(time.split(":")[0]) + 12}:${time.split(":")[1]}`;

        }
      }
      return time;
    }
    return str;
  }


  public get userId(): string {
    return this.user?.id;
  }

  get checkOooExpired() {
    if (this.endTime && this.startTime) {
      const [hours, minutes] = this.endTime.split(':')
      const [sHours, sMinutes] = this.startTime.split(':')
      const endTime = new Date(`${this.endDate} ${hours}:${minutes}:00`)
      const startTime = new Date(`${this.startDate} ${sHours}:${sMinutes}:00`)
      const currentDate = moment();
      const isBetween = currentDate.isBetween(startTime, endTime);
      return !isBetween
    }
    return true
  }
  compareUsers(user1: User, user2: User): boolean {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }
  submitForm() {
    const userSettings = {
      id: this.user?.id,
      ldapId: this.user?.ldapId,
      firstName: this.user?.firstName,
      lastName: this.user?.lastName,
      authorization: this.user?.authorization,
      rdcs: this.user?.rdcs,
      active: this.user?.active,
      leader: this.userSettingsForm?.get('leader').value,
      email: this.user?.email,
      backUpUser: this.userSettingsForm?.get('backup').value,
      outofofficeAllDayEvent: this.userSettingsForm?.get('allDayEvent').value,
      outofofficeTextEmail: this.userSettingsForm?.get('email').value,
      outofofficeFromDate: this.userSettingsForm?.get('startDate').value?
      moment(this.userSettingsForm?.get('startDate').value).format('YYYY-MM-DD') : null,
      outofofficeToDate: this.userSettingsForm?.get('endDate').value ?
      moment(this.userSettingsForm?.get('endDate').value).format('YYYY-MM-DD') : null,
      outofofficeFromTime: this.userSettingsForm?.get('allDayEvent').value ? '12:00 AM' : this.convertTimeTo12HoursFormat(this.userSettingsForm.get('startTime').value),
      outofofficeToTime: this.userSettingsForm?.get('allDayEvent').value ? '12:00 AM' : this.convertTimeTo12HoursFormat(this.userSettingsForm.get('endTime').value)
    }


    this.store.dispatch(new EditUserAction(userSettings));

  }

  tabChange(event){
    if (event && (event.index === 1)) {
      this.scheduleEvnetsTabActive = true;
    } else {
      this.scheduleEvnetsTabActive = false;
    }
  }
  ngOnDestroy(): void {
    this.subs.forEach(sub=>sub.unsubscribe());
  }
}
