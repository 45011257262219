import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {Permission} from '../models/Authorization';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { UsersService } from '../services/users.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private store: Store<fromRoot.AppState>, private router: Router,
    private userService:UsersService) { }

  canActivate(): Observable<boolean> {
    return this.checkIfAdmin();
  }

  private checkIfAdmin(): Observable<boolean> {
    return this.userService.userRole.pipe(
      map(role => {
        console.log(role)
        if ([Permission.ADMIN, Permission.MANAGER,
          Permission.VISITOR, Permission.EC,
          Permission.PROCESSOR , Permission.LEADERSHIP, Permission.ERM,
           Permission.TESTER_AUDITOR, Permission.INTFEED,
          Permission.SI_MAPPER,Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER].includes(role)) {
          return true;
      } else if ([Permission.SPEDM].includes(role)) {
          this.router.navigateByUrl('/dashboard/spedm');
          return true;
        }
        else if ([Permission.FT_BENEFIT].includes(role)) {
          this.router.navigateByUrl('/dashboard/Benefit');
          return true;
        }else if ([Permission.FILETRACKER].includes(role)) {
          this.router.navigateByUrl('home/dashboard/filetracker');
          return true;
        }
      })
    );
  }
}
