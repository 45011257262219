export function replaceItemsInArray(originalArray, index, newItem): any {
  return [
    ...originalArray.slice(0, index),
    Object.assign({}, originalArray[index], newItem),
    ...originalArray.slice(index + 1)
  ];
}

export function removeItemInArray(originalArray, index): any {
  return [
    ...originalArray.slice(0, index),
    ...originalArray.slice(index + 1)
  ];
}

export function replaceItemsInArrayOfArrays(originalArrayOfArrays, outerIndex, innerIndex, newItem): any {
  return [
    ...originalArrayOfArrays.slice(0, outerIndex),
    [...originalArrayOfArrays[outerIndex].slice(0, innerIndex),
      Object.assign({}, originalArrayOfArrays[outerIndex][innerIndex], newItem),
      ...originalArrayOfArrays[outerIndex].slice(innerIndex + 1)
    ],
    ...originalArrayOfArrays.slice(outerIndex + 1)
  ];
}

export function addItemToArrayInArray(originalArray, index, newItem): any {
  return [
    ...originalArray.slice(0, index),
    [newItem, ...originalArray[index]],
    ...originalArray.slice(index + 1)
  ];
}
