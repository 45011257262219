import {Injectable} from '@angular/core';
import {User} from '../models/User';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {CVSHttpClient} from 'angular-component-library';

@Injectable({
  providedIn: 'root'
})
export class GlobalSubjectService {
  userId = new BehaviorSubject(null);

  constructor(private http: CVSHttpClient) {  }


}
