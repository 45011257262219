import { Injectable } from '@angular/core';
import { CVSHttpClient } from 'angular-component-library';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RteService {

  constructor(private http: CVSHttpClient) { }

  getRTEMetrics(payload: any) {
    return this.http.post(`${environment.rxClaimUrl}/rteMetrics`, payload);
  }
}

