import {
  Actions,
  EDIT_WEBEPRFEED,
  EDIT_WEBEPRFEED_SUCCESS,
  LOAD_WEBEPRFEED_SUCCESS,
  SAVE_WEBEPRFEED_SUCCESS,
} from '../actions/web-eprfeed.action';
import {WebEprFeed} from '../models/WebEprFeed';

export interface State {
  allWebEprFeeds: WebEprFeed[];
  selectedWebEprFeedId: number;
}

export const initialState: State = {
  allWebEprFeeds: [],
  selectedWebEprFeedId: undefined
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case LOAD_WEBEPRFEED_SUCCESS:
      return {...state, allWebEprFeeds: action.payload as WebEprFeed[]};

    case SAVE_WEBEPRFEED_SUCCESS:
      const newUsers = action.payload;
      newUsers.sort((a, b) => {
        if (a.eprId < b.eprId) {
          return -1;
        }
        if (a.eprId > b.eprId) {
          return 1;
        }
        return 0;
      });
      return {...state, allWebEprFeeds: newUsers};

    case EDIT_WEBEPRFEED:
      return {...state, selectedWebEprFeedId: action.payload.eprId};

    case EDIT_WEBEPRFEED_SUCCESS:
      let index;
      for (let i = 0; i < state.allWebEprFeeds.length; i++) {
          if ((action.payload.eprId === state.allWebEprFeeds[i].eprId)) {
            index = i;
          }
      }

      return {
        ...state,
        allWebEprFeeds: [
          ...state.allWebEprFeeds.slice(0, index),
          Object.assign({}, state.allWebEprFeeds[index], action.payload),
          ...state.allWebEprFeeds.slice(index + 1)
          ],
        };


    default:
      return state;
  }
}

export const getWebEprFeeds = (state: State) => state.allWebEprFeeds;
export const getWebEprFeedByEpr = (state: State) => {
  return state.allWebEprFeeds.filter(webeprfeed => webeprfeed.eprId);
};
