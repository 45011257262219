import { AppState } from './../../../reducers/index';
import { Store } from '@ngrx/store';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { MessageSupressionService } from './../../../services/message-supression.service';
import {Component, OnInit, Output, ViewChild, EventEmitter, AfterViewInit, HostListener, Input} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { EditBtnCellRendererComponent } from 'src/app/common/EditBtnCellRenderer';
import {DataManagementService} from "../../../services/data-management.service";

export const reportingMessageCodeGridColumns = [
  {
    headerName: 'Id', field: 'id', hide: true
  },
  {
    headerName: 'Code', field: 'message',
    editable: true,
    sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    heigth: 300, width: 150,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    cellRenderer: (params) => {
      return `<mat-icon class="mat-icon md-16 material-icons small" style="height:12px; font-size: small" role="img" aria-hidden="true">
      drag_indicator</mat-icon>${params.data?.message}`;
    },
    cellEditorParams: { maxLength: 1000, rows: 10,cols: 50 }
  },
  {
    headerName: 'Type', field: 'reportMessageCodesTypeValue',
    editable: false,
    sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    heigth: 300, width: 150,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    cellEditorParams: { maxLength: 1000, rows: 10, cols: 50 },
    unSortIcon: true
  },
  {
    headerName: 'Message', field: 'messageDescription',
    editable: false,
    sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    heigth: 300, width: 750,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    cellEditorParams: { maxLength: 1000, rows: 10, cols: 50 },
    unSortIcon: true
  },
  {
    headerName: '',
    field:"action",
    cellRenderer: 'editAction',
    editable: true,
    width: 90,
  }
];

class reportMessageModel {
  data:any;
  type: string;
}

@Component({
  selector: 'app-reporting-message-codes',
  templateUrl: './reporting-message-codes.component.html',
  styleUrls: ['./reporting-message-codes.component.scss']
})
export class ReportingMessageCodesComponent implements OnInit, AfterViewInit {
  @ViewChild('reportingMessageCodesGrid') reportingMessageCodesGrid: AgGridAngular;
  @Output() isReportingMessageCodes: EventEmitter<any> = new EventEmitter();
  @Output() isReportingMessageCodesRowData: EventEmitter<{}> = new EventEmitter();


  dataRows = [];
  hlGridHeight = 350;
  contextObj: any;
  selectedStatus = '';
  frameworkComponents: any;
  changedRow: any;
  readonly: boolean;
  isRowDragged: boolean;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  isItemDeleted: boolean = false;
  reportingTypes:any = [];
  filteredData: any= [];
  constructor(private msgSupressService: MessageSupressionService,private alertService: CVSAlertService, private store: Store<AppState>,private dataManagementService: DataManagementService) {
    this.contextObj = {
      componentParent: this
    };
    this.frameworkComponents = {
      editAction: EditBtnCellRendererComponent,
      currentPage: "reportingMessageCodes",
    };
  }

  ngOnInit(): void {
    this.getAllRows();
    this.hlGridHeight = 350;
    this.dataManagementService.reportTypes.subscribe(data=>{
      this.reportingTypes = data;
    })
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.addNewRow();
      this.saveUnSavedRecords();
      this.alertService.clearAlert('#reportingMessageCodes-alert');
    }, 500);
  }
  getAllRows() {
    this.msgSupressService.getReportingMessageCodes().subscribe(res => {
      if(res && res.length && res.length> 0) {
        this.dataManagementService.reportMessageCodes.next(res.map(c=> c.message));
        res.map(t=> {
          let type = this.reportingTypes.find(a=> a.id === t.reportType);
          t.reportMessageCodesTypeValue = type?.message;
        });
        this.dataRows = res;
         this.dataManagementService.addRowdata.next(this.dataRows);
      }
    });
    this.changedRow = false;
  }

  sortChange(event) {
    const sortOrder = this.reportingMessageCodesGrid.api.getSortModel();
    this.reportingMessageCodesGrid.api.setSortModel(sortOrder);
  }

  onGridReady(params) {
    this.reportingMessageCodesGrid = params;
    this.hlGridHeight = this.dataRows.length * 51;
    this.reportingMessageCodesGrid.api.setColumnDefs(reportingMessageCodeGridColumns);
    this.reportingMessageCodesGrid.api.redrawRows();
    this.reportingMessageCodesGrid.api.sizeColumnsToFit();
    this.reportingMessageCodesGrid.gridOptions = {
      editType: "Enable",
      onCellValueChanged(data)  { this.UpdatedRow(data) }
    };
  }

  onRowDragEnd(event) {
    this.isRowDragged = true;
    this.emitSave();
  }

  addNewRow() {
    this.msgSupressService.onReportMessageCodesMessage().subscribe(newRow => {
    const nextItemIndex = this.reportingMessageCodesGrid?.api?.getDisplayedRowCount();
      if (newRow != null) {
        this.reportingMessageCodesGrid.api.updateRowData({
          addIndex: nextItemIndex,
          add: newRow
        });
        this.reportingMessageCodesGrid.api.setFocusedCell(0, 'id');
        if(this.reportingMessageCodesGrid.api.getModel()) {
          this.hlGridHeight = (this.reportingMessageCodesGrid.api.getModel().getRowCount() * 42) + 52;
        }
      }
    });
  }
  saveUnSavedRecords() {
    this.msgSupressService.saveNewReportingMessageCodes$.subscribe(resp => {
      const updatedRec = [];
      let nodeData: any = {}
      if (resp) {
        let isAddorEditNode =  this.reportingMessageCodesGrid.api.getRenderedNodes()?.some(node=> (node.data.isNew || node.data.isUpdated));
        this.reportingMessageCodesGrid.api.forEachNode(
          node => {
          if(node.data?.isNew || node.data?.isUpdated) {
            delete node.data?.isUpdated;
            delete node.data?.isNew
            delete node.data?.action;
            nodeData = node.data;
            nodeData.type= 'reportingMessageCodes';
            nodeData.sortOrder = nodeData.sortOrder ? nodeData.sortOrder: node.rowIndex;
            updatedRec.push(nodeData);
          }
          if(this.isRowDragged || this.isItemDeleted) {
            nodeData = node.data;
            nodeData.sortOrder = node.rowIndex;
            updatedRec.push(nodeData);
          }
        });

        if (updatedRec && updatedRec.length) {
          if(isAddorEditNode) {
            this.msgSupressService.saveMsgSupressRow(updatedRec, true).subscribe(_ => {
              this.getAllRows();
              this.readonly = false;
              this.showAlert('Save successful!', CVSAlertType.Success)
            });
          }
           if(this.isRowDragged || this.isItemDeleted) {
            this.msgSupressService.saveSortedRecord(updatedRec, true).subscribe(_ => {
              this.getAllRows();
              this.isRowDragged = false;
              this.readonly = false;
              this.showAlert(this.isItemDeleted? 'Delete successful!': 'Save successful!', CVSAlertType.Success)
              this.isItemDeleted = false;
            });
          }
        }
      }
    });
  }

  private showAlert(alertMessage, alertType) {
    this.alertService.sendAlert({
      headline: alertMessage,
      alertType: alertType,
      removedAfterMilliseconds: 5000,
      alertDocumentHolderName: '#reportingMessageCodes-alert',
    });
  }

  UpdatedRow(cell) {
    cell.data.isUpdated = true;
    this.readonly = true;
  }

  emitSave() {
    this.isReportingMessageCodes.emit();
  }

  public updateRecord(node, record) {
    const currentRecordId = node?.data?.id;
    this.dataRows.map((item)=>  {
      if(item.id === currentRecordId) {
        node.data.isUpdated = true;
        item.message = record[0].message;
        item.reportType=record[0].reportType;
        item.messageDescription = record[0].messageDescription
        return
      }
    });
    this.msgSupressService.saveNewReportingMessageCodes$.next(true);
    this.reportingMessageCodesGrid.api.setRowData(this.dataRows);
  }

  public deleteTableRecord(node, colId) {
    if (node.data.isNew) {
      this.readonly = false;
      this.reportingMessageCodesGrid.api.removeItems([node], false);
      this.hlGridHeight = (this.reportingMessageCodesGrid.api.getModel().getRowCount() * 42) + 52;
    } else {
      this.msgSupressService.deleteFieldActionsRow([{ ...node.data }], false).subscribe(resp => {
        this.reportingMessageCodesGrid.api.removeItems([node], true);
        this.isItemDeleted = true;
        this.msgSupressService.saveNewFieldActionsRow$.next(true);
        this.msgSupressService.saveNewReportingMessageCodes$.next(true);
        this.hlGridHeight = (this.reportingMessageCodesGrid.api.getModel().getRowCount() * 42) + 52;
      });
    }
  }

  onFilterChange(event: any) {
    this.filteredData = [];
    event.api.forEachNodeAfterFilter((node) =>{
      this.filteredData.push(node.data);
    })
    this.dataManagementService.addRowdata.next(this.filteredData)
  }
}

