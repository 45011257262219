const checkbox = (params) => `<input class="checkboxCustom" type='checkbox' ${params.value ? 'checked' : ''} /><label></label>`;
  export const columnDefs = [
    {
      headerName: '', field: 'classificationCheck',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
    },
    { headerName: 'Classification Code', field: 'classificationCode', sortable: true,
     filter: true, resizable: true, editable: true, width: 300 },
    { headerName: 'Rec Type', field: 'recordType', sortable: true, filter: true,
     resizable: true, editable: true, width: 300 },
    { headerName: 'Map Type', field: 'mapType', sortable: true, filter: true, resizable: true, editable: true, width: 300 },
    { headerName: 'Field Number', field: 'fieldNumber', sortable: true, filter: true, resizable: true, editable: true , width: 280},
    { headerName: 'Logic Code', field: 'logicCode', sortable: true, filter: true, resizable: true, editable: true, width: 290}
  ];