<cvs-alert-placeholder id="settings-alert"></cvs-alert-placeholder>

<h1>Settings</h1>

<div class="horizontal-container">
  <div class="tabs">
    <span class="tab"
          [ngClass]="{ 'selected': showingChecklistSettings }"
          (click)="showChecklistSettings()">
      Checklist
    </span>

    <span class="tab"
          [ngClass]="{ 'selected': showingQuestionnaireSettings }"
          (click)="showQuestionnaireSettings()">
      Questionnaire
    </span>
  </div>

  <button
    class="cvs-btn-primary"
    id="save-btn"
    (click)="save()" [disabled]="showingChecklistSettings? saveDisabled: isQuestionnaireSettingSaveDisabled">
    <mat-icon>save</mat-icon>
    Save
  </button>
</div>

<app-checklist-metadata *ngIf="showingChecklistSettings"></app-checklist-metadata>
<app-questionnaire-metadata *ngIf="showingQuestionnaireSettings"></app-questionnaire-metadata>
