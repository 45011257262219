import { Directive, HostListener, ElementRef, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appBulletCreater]'
})
export class BulletCreaterDirective implements OnInit{
  @Input() stringArray: string[];
  @Output() stringArrayChange = new EventEmitter();
  @HostListener("keydown.enter", ['$event']) onEnter(event: KeyboardEvent) {
    // this.rawValue = this.rawValue += '\n• ';
    event.preventDefault();

    const textarea = this.elementRef.nativeElement as HTMLTextAreaElement;

    if(textarea){
      const cursorPosition = textarea.selectionStart;
      this.rawValue = this.rawValue.substring(0, cursorPosition) + '\n• ' + this.rawValue.substring(cursorPosition);
      textarea.setSelectionRange(cursorPosition + 3, cursorPosition + 3)
    }
  }
  @HostListener("change", ['$event']) change(event) {
    this.stringArrayChange.emit(this.rawValue.split("\n• "));
  }

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    let temp: string = '';
    this.stringArray.forEach((item ,index)=> {
      if(index===0){
        if (temp)
        temp += "\r\n";
      temp += item + '\r\n';
      }else{
        if (temp)
        temp += "\r\n";
      temp += '• ' + item;
      }
    });

    this.rawValue = temp;
  }

  get rawValue(): string {
    return this.elementRef.nativeElement.value;
  }
  set rawValue(value: string) {
    this.elementRef.nativeElement.value = value;
  }

}
