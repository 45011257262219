import { MenuState, SideNavConfig } from 'angular-component-library';
export class Sidenav {
  sideNavConfig: SideNavConfig;
  menuState: MenuState;
  
  allSideNavItem: any = {
    home:{
      icon: 'home--s',
      name: 'My Dashboard',
      routerLink: 'home',
    },
    filetracker:{
      icon: 'map-pin--s',
      name: 'Filetracker',
      routerLink: 'dashboard/filetracker',
    },

    rm:{
      icon: 'clipboard--s',
      name: 'R&M',
      routerLink: 'rte',
    },
    admin:{
      icon: 'gear--s',
      name: 'Admin',
      routerLink: 'admin',

    },
    userSettings:{
      icon: 'profile--s',
      name: 'User Settings',
      routerLink: 'userSettings',
    },
    
    pbm:{
        name: 'myPBM',
        icon: 'app--s',
        routerLink: 'myPBM',
    },
    memberGroupInquiry:{
      icon: 'user-group--m',
      name: 'Member Inquiry',
      routerLink: 'inquiry-lib',
    }
  }

  getSideNavConfig = (user: any) => {
    const allowedSideNavItems:any[] = Array.from({length: 7}, () => false)
      user.permissions.forEach(permission=>{
        switch (permission.permissionCode){
          case 'ELG_HOME_ICON':
            allowedSideNavItems[0] = this.allSideNavItem.home
            break;
          case 'ELG_FILE_TRACKER_ICON':
            allowedSideNavItems[1] = this.allSideNavItem.filetracker
            break;
          case "ELG_REPORTING_AND_METRICS_ICON":
            allowedSideNavItems[2] = this.allSideNavItem.rm
            break;
          case 'ELG_ADMIN_ICON':
            allowedSideNavItems[3] = this.allSideNavItem.admin
            break;
          case "ELG_USER_SETTINGS_ICON":
            allowedSideNavItems[4] = this.allSideNavItem.userSettings
            break;
          case "ELG_MBR_GRP_INQUIRY_ICON":
              allowedSideNavItems[5] = this.allSideNavItem.memberGroupInquiry
              break; 
          case "ELG_MYPBM_ICON":
              allowedSideNavItems[6] = this.allSideNavItem.pbm
              break;
            
        }
    })

    return {
      sideNavItems: allowedSideNavItems.filter(icon=>icon !== false),
      iconMenu: true,
      menuState: MenuState.Closed
    } as SideNavConfig;
  }

}






