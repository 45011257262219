<ag-grid-angular
  class="ag-theme-alpine" id="discovery-grid"
  #discoveryTasksGrid
  (cellClicked)="onCellClicked($event, DISCOVERY_INDEX)"
  (cellEditingStopped)="onCellEditingStopped($event)"
  (gridReady)="onGridReady($event)"
>
</ag-grid-angular>

<ag-grid-angular
  class="ag-theme-alpine" id="setup-grid"
  #setupTasksGrid
  (cellClicked)="onCellClicked($event, SETUP_INDEX)"
  (cellEditingStopped)="onCellEditingStopped($event)"
  (gridReady)="onGridReady($event)"
>
</ag-grid-angular>

<ag-grid-angular
  class="ag-theme-alpine" id="test-grid"
  #testTasksGrid
  (cellClicked)="onCellClicked($event, TEST_INDEX)"
  (cellEditingStopped)="onCellEditingStopped($event)"
  (gridReady)="onGridReady($event)"
>
</ag-grid-angular>

<ag-grid-angular
  class="ag-theme-alpine" id="i-feeds-grid"
  #iFeedsTasksGrid
  (cellClicked)="onCellClicked($event, IFEEDS_INDEX)"
  (cellEditingStopped)="onCellEditingStopped($event)"
  (gridReady)="onGridReady($event)"
>
</ag-grid-angular>

<ag-grid-angular
  class="ag-theme-alpine" id="baseline-grid"
  #baselineTasksGrid
  (cellClicked)="onCellClicked($event, BASELINE_INDEX)"
  (cellEditingStopped)="onCellEditingStopped($event)"
  (gridReady)="onGridReady($event)"
>
</ag-grid-angular>

<ag-grid-angular
  class="ag-theme-alpine" id="id-cards-grid"
  #idCardsGrid
  (cellClicked)="onCellClicked($event, IDCARDS_INDEX)"
  (cellEditingStopped)="onCellEditingStopped($event)"
  (gridReady)="onGridReady($event)"
>
</ag-grid-angular>

<ag-grid-angular
  class="ag-theme-alpine" id="go-live-grid"
  #goLiveGrid
  (cellClicked)="onCellClicked($event, GOLIVE_INDEX)"
  (cellEditingStopped)="onCellEditingStopped($event)"
  (gridReady)="onGridReady($event)"
>
</ag-grid-angular>
