import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';

@Injectable()
export class EprActivateGuard implements CanActivate {
  private router : Router;

  constructor(private store: Store<fromRoot.AppState>, router: Router) {
    this.router = router;
  }

  public canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot,
                     routerStateSnapshot: RouterStateSnapshot): boolean {
    if(this.isPageRefresh()){

      this.router.navigateByUrl('home');
      return false;
    }
    return true;
  }

  private isPageRefresh() : boolean {
    return (!this.router.navigated);
  }
}
