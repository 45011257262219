import { compose } from "@ngrx/store";
import { PliCarrierDeleteCellRendererComponent } from "./renderer/PliCarrierDeleteCellRenderer";
import { InputType } from "src/app/models/FormFieldConfig";
import { Component } from "@ag-grid-community/core";
import { PliCarrierDateCellRendererComponent } from "./renderer/PliCarrierDateCellRenderer";
import { Input } from "@angular/core";

export const defaultColumns=[
    {headerName: 'PLI/Carrier', field: 'pliCarrier', sortable: true, filter: true, resizable: true,
        editable:(params)=>{return isEditable(params)},
        cellEditorSelector: (params)=>{
            if(!params?.data?.type){
                const options = params?.context?.providedInfo?.questionareDetail?.carrier;
                if(options){
                    return {
                        component: 'agSelectCellEditor',
                        params: { values: options }
                    }
                }
            }
           
        },
        cellStyle:mandatoryCellRule
    },
    {
        headerValueGetter:(params)=>{
        return params?.context?.providedInfo.label || null
        
    } ,
    flex:2,
    cellStyle:mandatoryCellRule,
    valueFormatter:(params)=>{
        const info = params?.context?.providedInfo;
        
        if( info?.inputType===InputType.DATE){
           if(params?.value){
               return  new Date(params?.value).toLocaleDateString("en-US",{ timeZone: 'UTC' })
           }
    }
},
    cellEditorSelector: (params)=>{
        const options = params?.context?.providedInfo?.options;
        const info = params?.context?.providedInfo;
        if(info?.inputType === InputType.DROPDOWN){
            return {
                component: 'agSelectCellEditor',
                params: { values: options }
            }
        }else if(info?.inputType === InputType.DATE){
            return {
                component : 'myDateEditor'
            }
        }else if(info?.inputType === InputType.MULTI_SELECT || info?.inputType === InputType.FREEFORM_MULTISELECT){
            return {
                component: 'myMultiSelectEditor',
                params:{values:options}
            }
        }else if(options){
            return {
                component: 'agSelectCellEditor',
                params: { values: options }
            }
        }
        else{
            return {
                component: 'agTextCellEditor' 
            }
        }
    },
    editable:(params)=>{ return isEditable(params)},
    field: '', sortable: true, filter: true, resizable: true},

    {headerName: '', field: 'delete',flex:1 ,sortable: true, filter: true, resizable: true,
    cellRendererFramework:PliCarrierDeleteCellRendererComponent  
    },
];

function mandatoryCellRule(params) {
    if (!params.data?.type &&
      (params.context && params.context.saveTriggered) &&
      ((!params.value)))
      return { 'border-color': 'red' }
    else
      return { 'border-color': 'transparent' }
  }

export const minMaxColumns=[
    {headerName: 'PLI/Carrier', field: 'pliCarrier', sortable: true, filter: true, resizable: true,
        editable:(params)=>{return isEditable(params)},
        cellEditorSelector: (params)=>{
            if(!params?.data?.type){
                const options = params?.context?.providedInfo?.questionareDetail?.carrier;
                if(options){
                    return {
                        component: 'agSelectCellEditor',
                        params: { values: options }
                    }
                }
            }
           
        },
        cellStyle:mandatoryCellRule
    },
    {headerName: 'Min', field: 'min',flex:1, sortable: true, filter: true, resizable: true,
       
        cellEditorSelector: (params)=>{
            let options = [];
            const info = params?.context?.providedInfo;
            
            if(info?.inputType===InputType.HORIZONTAL_FIELD_SET){
                const cellControlName = params?.colDef.field;
                options = info?.subFormGroup.find(group=>group.controlName===cellControlName)?.options;
            }else{
                options = info?.options;
            }
            if(options){
                return {
                    component: 'agSelectCellEditor',
                    params: { values: options }
                }
            }else if(info?.inputType === InputType.DATE){
                return {
                    component:PliCarrierDateCellRendererComponent
                }
            }
            
            else{
                return {
                    component: 'agTextCellEditor' 
                }
            }
        },
        editable:(params)=>{return isEditable(params)},
    },
    {headerName: 'Max', field: 'max',flex:1, sortable: true, filter: true, resizable: true,
        cellEditorSelector: (params)=>{
            let options = [];
            const info = params?.context?.providedInfo;
            
            if(info?.inputType===InputType.HORIZONTAL_FIELD_SET){
                const cellControlName = params?.colDef.field;
                options = info?.subFormGroup.find(group=>group.controlName===cellControlName)?.options;
            }else{
                options = info?.options;
            }
            if(options){
                return {
                    component: 'agSelectCellEditor',
                    params: { values: options }
                }
            }else{
                return {
                    component: 'agTextCellEditor' 
                }
            }
        },
        editable:(params)=>{return isEditable(params)},
    },
    {headerName: '', field: 'delete',flex:1, sortable: true, filter: true, resizable: true,
        cellRendererFramework:PliCarrierDeleteCellRendererComponent  
    },
];

function isEditable(params) {
   
    if(params?.context?.providedInfo?.ssoeData){
        return false;
    }

    if(params?.context?.providedInfo?.questionareDetail?.readOnly){ //readOnly mode
        return false;
    }else{
        if(params?.data?.type === "default"){
            return false; //first row will be disabled
        }else{
            return true;
        }
    }
}