<script src="threshold-dialog.component.ts"></script>
<div class="popup threshold-dialog">
  <div style="display: flex; justify-content: flex-end">
    <button (click)="closeDialog()" align="end" style="background: transparent;
    position: absolute;
    border: none;cursor:pointer;">X</button>
  </div>

  <div class="container" style="font-size: 18px;width: 80%;height: 18px;padding: 20px 20px 20px 0px;">
    <div class="row">
      <div class="col-10">
        <b headerC id="file-details"> File Details | {{clientName}} | {{memberName}} | {{carrier}}</b>
      </div>
    </div>
  </div>
  <br>
  <div class="container">
    <div class="row">
      <div class="col-7">
        <label style="padding-right: 0.5em" id="pli"><b style="font-size: 14px;width: 28px;height: 14px;">PLI:
          </b>{{pli}}</label>
        <label style="padding-right: 0.5em" id="status"> <b style="font-size: 14px;width: 28px;height: 14px;">Status:
          </b>{{status}} - {{(data.body && data.body.tRecord) ? data.body.tRecord.output.trim() : ''}}</label>
        <label style="padding-right: 0.5em" id="library"> <b style="font-size: 14px;width: 28px;height: 14px;">Library:
          </b>{{library}}</label>
        <label> <b style="font-size: 14px;width: 28px;height: 14px;" id="ec">EC:</b> {{ec}}</label>
        <i class="material-icons" matTooltip="{{backUser}}" style="font-size:17px;cursor:pointer;">info_outline</i>
      </div>
    </div>
  </div>
  <mat-dialog-content id="bodyTextContent">
    <mat-tab-group (selectedTabChange)="cleanupTransaction($event)">
      <mat-tab label="Audit Log">
        <br>
        <div class="container">

          <div class="toolbar">
            <div class="toolbar-item-left">
              <mat-icon style="width: 17px;height: 17px;cursor: pointer;" svgIcon="refresh--s"
                (click)="refreshAuditLog()"></mat-icon>
              <label style=" font-weight:bold;"> <b class="button-label">Refresh</b></label>
              <label style=" font-weight:bold;padding-left:6px;"> <b style="font-size: 12px;font-weight:normal;">Last on
                  {{auditLogLast | date:'mediumTime'}}</b></label>
            </div>
            <div class="toolbar-item-middle">
              <mat-button-toggle-group name="mode" style="border-radius: 6px;height: 24px;font-size: 14px;"
                [value]="selectedTabVal">
                <mat-button-toggle value="Extended" (click)="createAuditLogData('Extended')">Abbreviated</mat-button-toggle>
                <mat-button-toggle value="Default" (click)="createAuditLogData('Default')">Extended</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="toolbar-item-right" *ngIf="currentUserRole !=='PROCESSOR'">
              <div *ngIf="(permissionObject.advanceFile && permissionObject.pbmRole)">
              <span *ngIf="(!isAdvanced || disableButtons)" class="disabled-custom-pointer">
                <mat-icon style="width: 17px;height: 17px;cursor: default; color: grey" svgIcon="check-circle--s">
                </mat-icon>
                <label style="padding-right: 1em; font-weight:bold;"><b class="button-label">Advance</b></label>
              </span>
              <span *ngIf="isAdvanced && !disableButtons" class="custom-pointer"
                (click)="openConfirmationDialog('ADVANCE_FILE', 'check-circle--s', 'Audit logs')">
                <mat-icon style="width: 17px;height: 17px;cursor: pointer;" svgIcon="check-circle--s">
                </mat-icon>
                <label style="padding-right: 1em; font-weight:bold;"><b class="button-label">Advance</b></label>
              </span>
            </div>
            <div *ngIf="(permissionObject.resetFile && permissionObject.pbmRole)">
              <span *ngIf="(!isResetToPreload || disableButtons) && !ssoeFlag" class="disabled-custom-pointer">
                <mat-icon style="width: 17px;height: 17px;cursor: default; color: grey" svgIcon="reorder-simple--s">
                </mat-icon>
                <label style="padding-right: 1em;font-weight:bold;"> <b class="button-label">Reset to
                    Preload</b></label>
              </span>
              <span *ngIf="isResetToPreload && !disableButtons && !ssoeFlag" class="custom-pointer"
                (click)="openConfirmationDialog('RESET_FILE', 'reorder-simple--s', 'Audit logs')">
                <mat-icon style="width: 17px;height: 17px;cursor: pointer;" svgIcon="reorder-simple--s"></mat-icon>
                <label style="padding-right: 1em;font-weight:bold;"> <b class="button-label">Reset to
                    Preload</b></label>
              </span>
              </div>
              <div *ngIf="(permissionObject.cancelFile && permissionObject.pbmRole)">
              <span *ngIf="!isCancelled || disableButtons" class="disabled-custom-pointer">
                <i class="material-icons" style="cursor:default; color: grey">cancel</i>
                <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Cancel</b></label>
              </span>
              <span *ngIf="isCancelled && !disableButtons" class="custom-pointer"
                (click)="openConfirmationDialog('CANCEL_FILE', 'close-circle-f--s', 'Audit logs')">
                <i class="material-icons" style="cursor:pointer;">cancel</i>
                <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Cancel</b></label>
              </span>
              </div>
              <div *ngIf="(permissionObject.detailsDownlaod && permissionObject.pbmRole)">
              <span *ngIf="!isDetails || disableButtons" class="disabled-custom-pointer">
                <mat-icon style="width: 17px;height: 17px;cursor: default; color: grey" svgIcon="download-arrow--s">
                </mat-icon>
                <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Details</b></label>
              </span>
              <span *ngIf="isDetails && !disableButtons" class="custom-pointer" (click)="exportAuditLogDetails()">
                <mat-icon svgIcon="download-arrow--s" style="width: 17px;height: 17px;cursor: pointer;"></mat-icon>
                <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Download Details</b></label>
              </span>
              </div>
              <div *ngIf="(permissionObject.summaryDownload && permissionObject.pbmRole)">
              <span *ngIf="!isSummay || disableButtons" class="disabled-custom-pointer">
                <mat-icon style="width: 17px;height: 17px;cursor: default; color: grey;" svgIcon="download-arrow--s">
                </mat-icon>
                <label style="font-weight:bold;"> <b class="button-label">Summary</b></label>
              </span>
              <span *ngIf="isSummay && !disableButtons" class="custom-pointer" (click)="exportAuditLogSummary()">
                <mat-icon svgIcon="download-arrow--s" style="width: 17px;height: 17px;cursor: pointer;"></mat-icon>
                <label style="font-weight:bold;"> <b class="button-label">Download Summary</b></label>
              </span>
              </div>

            </div>
            <div class="toolbar-item-right" *ngIf="currentUserRole ==='PROCESSOR'">
              <div *ngIf="(permissionObject.advanceFile && permissionObject.pbmRole)">
              <span class="custom-pointer"
                (click)="openConfirmationDialog('ADVANCE_FILE', 'check-circle--s', 'Audit logs')">
                <mat-icon style="width: 17px;height: 17px;cursor: pointer;" svgIcon="check-circle--s">
                </mat-icon>
                <label style="padding-right: 1em; font-weight:bold;"><b class="button-label">Advance</b></label>
              </span>
            </div>
              <div *ngIf="((permissionObject.resetFile && permissionObject.pbmRole)) && !ssoeFlag">
              <span class="custom-pointer"
                (click)="openConfirmationDialog('RESET_FILE', 'reorder-simple--s', 'Audit logs')">
                <mat-icon style="width: 17px;height: 17px;cursor: pointer;" svgIcon="reorder-simple--s"></mat-icon>
                <label style="padding-right: 1em;font-weight:bold;"> <b class="button-label">Reset to
                    Preload</b></label>
              </span>
            </div>
              <div *ngIf="(permissionObject.cancelFile && permissionObject.pbmRole) ">
              <span class="custom-pointer"
                (click)="openConfirmationDialog('CANCEL_FILE', 'close-circle-f--s', 'Audit logs')">
                <i class="material-icons" style="cursor:pointer;">cancel</i>
                <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Cancel</b></label>
              </span>
            </div>
              <div *ngIf="(permissionObject.detailsDownlaod && permissionObject.pbmRole) ">
              <span class="custom-pointer" (click)="exportAuditLogDetails()">
                <mat-icon svgIcon="download-arrow--s" style="width: 17px;height: 17px;cursor: pointer;"></mat-icon>
                <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Download Details</b></label>
              </span>
            </div>
              <div *ngIf="(permissionObject.summaryDownload && permissionObject.pbmRole) ">
              <span class="custom-pointer" (click)="exportAuditLogSummary()">
                <mat-icon svgIcon="download-arrow--s" style="width: 17px;height: 17px;cursor: pointer;"></mat-icon>
                <label style="font-weight:bold;"> <b class="button-label">Download Summary</b></label>
              </span>

            </div>
            </div>
          </div>
          <br>
          <cvs-alert-placeholder id="summary-alert"></cvs-alert-placeholder>

        </div>
        <div class="container-grid">
          <br>
          <ag-grid-angular  rowHeight="26" headerHeight="26" domLayout='autoHeight' #auditGrid [columnDefs]="auditlogColumns"
            class="ag-theme-alpine epr-feed-gui-grid auditlog">
          </ag-grid-angular>

        </div>
        <div class="container" *ngIf="(permissionObject.addComment && permissionObject.pbmRole) ">
          <div class="row">
            <div style="display:inline-flex; padding-top: 4px;">
              <textarea
                class="form-control mr-3 comment-textarea" placeholder="Add Comments" maxlength="200"
                [(ngModel)]="commentedText"></textarea>
              <button class="add-comments-button" id="addComments"
                *ngIf="commentedText.length > 0" (click)="addMainComment()" [disabled]="!commentedText">
                <mat-icon style="width: 17px;height: 17px;" svgIcon="plus--s"></mat-icon>
                Add Comment
              </button>
            </div>
          </div>
        </div>

      </mat-tab>
      <mat-tab label="Threshold">
        <br>
        <div class="container">

          <div class="toolbar">
            <div class="toolbar-item-left">
              <mat-icon style="width: 17px;height: 17px;cursor: pointer;" svgIcon="refresh--s"
                (click)="refreshThreashold()"></mat-icon>
              <label style=" font-weight:bold;"> <b class="button-label">Refresh</b></label>
              <label style=" font-weight:bold;padding-left:6px;"> <b style="font-size: 12px;font-weight:normal;">Last on
                  {{thresholdLast | date:'mediumTime'}}</b></label>
            </div>
            <div class="toolbar-item-right" *ngIf="currentUserRole !=='PROCESSOR'">
              <div *ngIf="(permissionObject.advanceFile && permissionObject.pbmRole) ">
                <span *ngIf="(!isAdvanced || disableButtons)" class="disabled-custom-pointer">
                    <mat-icon style="width: 17px;height: 17px;cursor: default; color: grey" svgIcon="check-circle--s"></mat-icon>
                    <label style="padding-right: 1em; font-weight:bold;"><b class="button-label">Advance</b></label>
                </span>
                <span *ngIf="isAdvanced && !disableButtons" class="custom-pointer"
                      (click)="openConfirmationDialog('ADVANCE_FILE', 'check-circle--s', 'Threshold')">
                  <mat-icon style="width: 17px;height: 17px;cursor: pointer;" svgIcon="check-circle--s">
                  </mat-icon>
                   <label style="padding-right: 1em; font-weight:bold;"><b class="button-label">Advance</b></label>
                </span>
              </div>
              <div *ngIf="((permissionObject.resetFile && permissionObject.pbmRole) ) && !ssoeFlag">
                <span *ngIf="(!isResetToPreload || disableButtons) && !ssoeFlag" class="disabled-custom-pointer">
                <mat-icon style="width: 17px;height: 17px;cursor: default; color: grey" svgIcon="reorder-simple--s">
                </mat-icon>
                <label style="padding-right: 1em;font-weight:bold;"> <b class="button-label">Reset to
                    Preload</b></label>
                </span>
                <span *ngIf="isResetToPreload && !disableButtons && !ssoeFlag" class="custom-pointer"
                      (click)="openConfirmationDialog('RESET_FILE', 'reorder-simple--s', 'Threshold')">
                <mat-icon style="width: 17px;height: 17px;cursor: pointer;" svgIcon="reorder-simple--s"></mat-icon>
                <label style="padding-right: 1em;font-weight:bold;"> <b class="button-label">Reset to
                    Preload</b></label>
              </span>
            </div>
              <div *ngIf="(permissionObject.cancelFile && permissionObject.pbmRole) ">
                        <span *ngIf="!isCancelled || disableButtons" class="disabled-custom-pointer">
                                 <i class="material-icons" style="cursor:default; color: grey">cancel</i>
                                <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Cancel</b></label>
                        </span>
                        <span *ngIf="isCancelled && !disableButtons" class="custom-pointer"
                              (click)="openConfirmationDialog('CANCEL_FILE', 'close-circle-f--s', 'Threshold')">
                          <i class="material-icons" style="cursor:pointer;">cancel</i>
                          <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Cancel</b></label>
                        </span>
              </div>
              <div *ngIf="(permissionObject.detailsDownlaod && permissionObject.pbmRole) ">
                <span *ngIf="!isDetails || disableButtons" class="disabled-custom-pointer">
                  <mat-icon style="width: 17px;height: 17px;cursor: default; color: grey" svgIcon="download-arrow--s">
                  </mat-icon>
                  <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Details</b></label>
                </span>
                <span *ngIf="isDetails && !disableButtons" class="custom-pointer" (click)="exportThresholdDetails()">
                  <mat-icon svgIcon="download-arrow--s" style="width: 17px;height: 17px;cursor: pointer;"></mat-icon>
                  <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Download Details</b></label>
                </span>
            </div>
              <div *ngIf="(permissionObject.summaryDownload && permissionObject.pbmRole) ">
                <span *ngIf="!isSummay || disableButtons" class="disabled-custom-pointer">
                  <mat-icon style="width: 17px;height: 17px;cursor: default; color: grey;" svgIcon="download-arrow--s"></mat-icon>
                  <label style="font-weight:bold;"> <b class="button-label">Summary</b></label>
                </span>
                <span *ngIf="isSummay && !disableButtons" class="custom-pointer" (click)="exportThresholdSummary()">
                <mat-icon svgIcon="download-arrow--s" style="width: 17px;height: 17px;cursor: pointer;"></mat-icon>
                <label style="font-weight:bold;"> <b class="button-label">Download Summary</b></label>
                </span>
            </div>
            </div>
            <div class="toolbar-item-right" *ngIf="currentUserRole ==='PROCESSOR'">
              <div *ngIf="(permissionObject.advanceFile && permissionObject.pbmRole) ">
              <span class="custom-pointer"
                    (click)="openConfirmationDialog('ADVANCE_FILE', 'check-circle--s', 'Threshold')">
                <mat-icon style="width: 17px;height: 17px;cursor: pointer;" svgIcon="check-circle--s">
                </mat-icon>
                <label style="padding-right: 1em; font-weight:bold;"><b class="button-label">Advance</b></label>
              </span>
              </div>
              <div *ngIf="((permissionObject.resetFile && permissionObject.pbmRole) ) && !ssoeFlag">
              <span class="custom-pointer"
                    (click)="openConfirmationDialog('RESET_FILE', 'reorder-simple--s', 'Threshold')">
                <mat-icon style="width: 17px;height: 17px;cursor: pointer;" svgIcon="reorder-simple--s"></mat-icon>
                <label style="padding-right: 1em;font-weight:bold;"> <b class="button-label">Reset to
                    Preload</b></label>
              </span>
              </div>
              <div *ngIf="(permissionObject.cancelFile && permissionObject.pbmRole) ">
              <span class="custom-pointer"
                    (click)="openConfirmationDialog('CANCEL_FILE', 'close-circle-f--s', 'Threshold')">
                <i class="material-icons" style="cursor:pointer;">cancel</i>
                <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Cancel</b></label>
              </span>
              </div>
              <div *ngIf="(permissionObject.detailsDownlaod && permissionObject.pbmRole) ">
              <span class="custom-pointer" (click)="exportThresholdDetails()">
                <mat-icon svgIcon="download-arrow--s" style="width: 17px;height: 17px;cursor: pointer;"></mat-icon>
                <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Download Details</b></label>
              </span>
              </div>
              <div *ngIf="(permissionObject.summaryDownload && permissionObject.pbmRole) ">
              <span class="custom-pointer" (click)="exportThresholdSummary()">
                <mat-icon svgIcon="download-arrow--s" style="width: 17px;height: 17px;cursor: pointer;"></mat-icon>
                <label style="font-weight:bold;"> <b class="button-label">Download Summary</b></label>
              </span>

              </div>
            </div>
          </div>
          <br>
          <cvs-alert-placeholder id="summary-alert"></cvs-alert-placeholder>
        </div>

        <div class="container-grid">
          <br>
          <ag-grid-angular rowHeight="34" domLayout='autoHeight' [getRowStyle]="colorInGrid" [context]="contextObj"
            [frameworkComponents]="frameworkComponents" #thresholdGrid
            class="ag-theme-alpine epr-feed-gui-grid threshold">
          </ag-grid-angular>

        </div>
      </mat-tab>
      <mat-tab label="Balance Sheet">
        <br>
        <div class="container">

          <div class="toolbar">
            <div class="toolbar-item-left">
              <mat-icon style="width: 17px;height: 17px;cursor: pointer;" svgIcon="refresh--s"
                (click)="refreshBalSheet()"></mat-icon>
              <label style=" font-weight:bold;"> <b class="button-label">Refresh</b></label>
              <label style=" font-weight:bold;padding-left:6px;"> <b style="font-size: 12px;font-weight:normal;">Last on
                  {{balanceSheetLast | date:'mediumTime'}}</b></label>
            </div>
            <div style="gap:16px;" class="toolbar-item-right" *ngIf="currentUserRole !=='PROCESSOR'">
              <div style="display: flex;align-items: center;gap: 8px" *ngIf="(permissionObject.advanceFile && permissionObject.pbmRole) ">
                <span *ngIf="(!isAdvanced || disableButtons)" class="disabled-custom-pointer">
                <mat-icon style="width: 17px;height: 17px;cursor: default; color: grey" svgIcon="check-circle--s">
                </mat-icon>
                <label style="padding-right: 1em; font-weight:bold;"><b class="button-label">Advance</b></label>
              </span>
                <span *ngIf="isAdvanced && !disableButtons" class="custom-pointer"
                      (click)="openConfirmationDialog('ADVANCE_FILE', 'check-circle--s', 'Balance Sheet')">
                <mat-icon style="width: 17px;height: 17px;cursor: pointer;" svgIcon="check-circle--s">
                </mat-icon>
                <label style="padding-right: 1em; font-weight:bold;"><b class="button-label">Advance</b></label>
              </span>
            </div>
<!--              <div style="display: flex;align-items: center;gap: 8px" *ngIf="((permissionObject.resetFile && permissionObject.pbmRole) )">-->
<!--              <mat-icon style="width: 17px;height: 17px;cursor: pointer; color: grey;"-->
<!--                *ngIf="!isResetToPreload || disableButtons" svgIcon="reorder-simple&#45;&#45;s"></mat-icon>-->
<!--              <mat-icon style="width: 17px;height: 17px;cursor: pointer;" *ngIf="isResetToPreload && !disableButtons"-->
<!--                svgIcon="reorder-simple&#45;&#45;s"-->
<!--                (click)="openConfirmationDialog('RESET_FILE', 'reorder-simple&#45;&#45;s', 'Balance Sheet')"></mat-icon>-->
<!--              <label *ngIf="!ssoeFlag" style="padding-right: 1em;font-weight:bold;"> <b class="button-label">Reset to Preload</b></label>-->
<!--            </div>-->
              <div style="display: flex;align-items: center;gap: 8px" *ngIf="(permissionObject.cancelFile && permissionObject.pbmRole) ">

                  <span *ngIf="!isCancelled || disableButtons" class="disabled-custom-pointer">
                  <i class="material-icons" style="cursor:default; color: grey">cancel</i>
                  <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Cancel</b></label>
                  </span>
                <span *ngIf="isCancelled && !disableButtons" class="custom-pointer"
                      (click)="openConfirmationDialog('CANCEL_FILE', 'close-circle-f--s', 'Balance Sheet')">
                 <i class="material-icons" style="cursor:pointer;">cancel</i>
                <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Cancel</b></label>
                </span>

            </div>
              <div style="display: flex;align-items: center;gap: 8px"  *ngIf="(permissionObject.detailsDownlaod && permissionObject.pbmRole) ">
                <span *ngIf="!isDetails || disableButtons" class="disabled-custom-pointer">
                <mat-icon style="width: 17px;height: 17px;cursor: default; color: grey" svgIcon="download-arrow--s">
                </mat-icon>
                <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Details</b></label>
              </span>
                <span *ngIf="isDetails && !disableButtons" class="custom-pointer" (click)="exportBalanceSheetDetails()">
                <mat-icon svgIcon="download-arrow--s" style="width: 17px;height: 17px;cursor: pointer;"></mat-icon>
                <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Download Details</b></label>
              </span>
            </div>
              <div style="display: flex;align-items: center;gap: 8px" *ngIf="(permissionObject.summaryDownload && permissionObject.pbmRole) ">
                <span *ngIf="!isSummay || disableButtons" class="disabled-custom-pointer">
                  <mat-icon style="width: 17px;height: 17px;cursor: default; color: grey;" svgIcon="download-arrow--s">
                  </mat-icon>
                  <label style="font-weight:bold;"> <b class="button-label">Summary</b></label>
                </span>
                <span *ngIf="isSummay && !disableButtons" class="custom-pointer" (click)="exportBalanceSheetSummary()">
                  <mat-icon svgIcon="download-arrow--s" style="width: 17px;height: 17px;cursor: pointer;"></mat-icon>
                  <label style="font-weight:bold;"> <b class="button-label">Download Summary</b></label>
                </span>
            </div>
            </div>
            <div style="gap:16px;" class="toolbar-item-right" *ngIf="currentUserRole ==='PROCESSOR'">
              <div *ngIf="(permissionObject.advanceFile && permissionObject.pbmRole) ">
              <span class="custom-pointer"
                    (click)="openConfirmationDialog('ADVANCE_FILE', 'check-circle--s', 'Balance Sheet')">
                <mat-icon style="width: 17px;height: 17px;cursor: pointer;" svgIcon="check-circle--s">
                </mat-icon>
                <label style="padding-right: 1em; font-weight:bold;"><b class="button-label">Advance</b></label>
              </span>
              </div>
              <div *ngIf="((permissionObject.resetFile && permissionObject.pbmRole) ) && !ssoeFlag">
              <span class="custom-pointer"
                    (click)="openConfirmationDialog('RESET_FILE', 'reorder-simple--s', 'Balance Sheet')">
                <mat-icon style="width: 17px;height: 17px;cursor: pointer;" svgIcon="reorder-simple--s"></mat-icon>
                <label style="padding-right: 1em;font-weight:bold;"> <b class="button-label">Reset to
                    Preload</b></label>
              </span>
              </div>
              <div *ngIf="(permissionObject.cancelFile && permissionObject.pbmRole) ">
              <span class="custom-pointer"
                    (click)="openConfirmationDialog('CANCEL_FILE', 'close-circle-f--s', 'Balance Sheet')">
                <i class="material-icons" style="cursor:pointer;">cancel</i>
                <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Cancel</b></label>
              </span>
              </div>
              <div *ngIf="(permissionObject.detailsDownlaod && permissionObject.pbmRole) ">
              <span class="custom-pointer" (click)="exportBalanceSheetDetails()">
                <mat-icon svgIcon="download-arrow--s" style="width: 17px;height: 17px;cursor: pointer;"></mat-icon>
                <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Download Details</b></label>
              </span>
              </div>
              <div *ngIf="(permissionObject.summaryDownload && permissionObject.pbmRole) ">
              <span class="custom-pointer" (click)="exportBalanceSheetSummary()">
                <mat-icon svgIcon="download-arrow--s" style="width: 17px;height: 17px;cursor: pointer;"></mat-icon>
                <label style="font-weight:bold;"> <b class="button-label">Download Summary</b></label>
              </span>

              </div>
            </div>
          </div>
          <br>
          <cvs-alert-placeholder id="summary-alert"></cvs-alert-placeholder>

        </div>
        <div class="container-grid" [ngClass]="showscollbar ? 'app-table-wrapper': ''">
          <ag-grid-angular *ngIf="!ssoeFlag" domLayout='autoHeight' rowHeight="26" #balanceSheetGrid class="ag-theme-alpine epr-feed-gui-grid balanceSheet"
            style="overflow-y: auto; margin-bottom:85px">
          </ag-grid-angular>
          <ng-container *ngIf="ssoeFlag">
            <section class="" tabindex="0">

                <table mat-table [dataSource]="dataSource" class="balance-sheet-tbl">
                  <ng-container *ngFor="let disCol of (displayedColumns); let colIndex = index" [sticky]="isSticky(stickyColumns, disCol)" matColumnDef="{{disCol}}">
                    <th mat-header-cell *matHeaderCellDef>{{disCol}}</th>
                    <td mat-cell *matCellDef="let element; let row = index">
                      <ng-container *ngIf="(colIndex===0 || colIndex===1 ); else other_content">
                        <span *ngIf="colIndex===0" [ngClass]="(element.position && colIndex===0 )? 'carrierTxt-position'+element.position: ''">
                          {{ element?.["Carrier"]  }}
                        </span>
                        <span *ngIf="colIndex===1">
                          {{ element?.["Total"] }}
                        </span>
                      </ng-container>
                      <ng-template #other_content>{{ element?.[disCol]?.[element.field] }}</ng-template>
                    </td>
                  </ng-container>
                  <tr mat-header-row class="header-row" *matHeaderRowDef="(displayedColumns); sticky: isSticky(stickyHeaders, 'header-1')"></tr>
                  <tr mat-row class="content-row" *matRowDef="let row; columns: (displayedColumns)"></tr>
                </table>
            </section>
          </ng-container>
        </div>
      </mat-tab>

    </mat-tab-group>
  </mat-dialog-content>
  <div id="matButtons">
    <button class="cvs-btn-ghost" id="cancelContact1"
            (click)="closeDialog()">
      Close
    </button>
    <!-- <div align="middle" class="mat-card mat-card-class" *ngIf="commentedText.length === 0 && auditComplete && !ssoeFlag"
         [style]="currentTabIndex === 2 ? {marginTop: '5em'}: {marginTop: '2em'}">
      <i class="material-icons" style="font-size:17px;cursor:pointer;padding-top:1px">info_outline</i>{{auditComplete}}
    </div>
    <div align="middle" class="mat-card mat-card-ssoeclass" *ngIf="ssoeFlag && requestMessage"
    [style]="currentTabIndex === 2 ? {marginTop: '5em'}: {marginTop: '2em'}">
    <span><i class="material-icons" style="font-size:17px;cursor:pointer;padding-top:1px" >info_outline</i>{{ssoerequestMessage}}</span>
 
</div> -->
    <a class="cvs-btn-primary" *ngIf="(permissionObject.sendEmail && permissionObject.pbmRole) "
       [href]="emailBody">Send Email</a>
  </div>

</div>
