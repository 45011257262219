<div class="" [ngStyle]="{'width': '25rem'}">
  <button id="closeAction" class="cvs-icon-btn" style="background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;float:right;" mat-dialog-close  (click)="closeHandler()">
    <mat-icon svgIcon="close-btn--s"></mat-icon>
  </button>
    <h2 mat-dialog-title style="font-size: 18px;font-weight:bold;padding-top:20px">"{{data.parameter}}" Attributes</h2>
    <ng-container>
        <ng-container [formGroup]="form">
            <mat-dialog-content class="">
                <div class="row" style="margin-top:5px">
                    <mat-form-field  [floatLabel]="'never'" class=" textarea col-md-12 custom-select w-100">
                      <textarea matInput placeholder="Parameter and rule description" formControlName="description" class="text-ara-sty message">{{data.description}}</textarea>
                    </mat-form-field>
                    <mat-label style="font-size: 12px; font-weight: 400;padding-top:1px;padding-left:18px;">Description Text</mat-label>
                    <mat-form-field class="col-md-12 custom-select w-100">
                      <mat-label>Level</mat-label>
                      <mat-select formControlName="paramLevel">
                          <mat-option [value]="paramLevel" *ngFor="let paramLevel of paramLevelList">{{paramLevel}}</mat-option>
                      </mat-select>
                  </mat-form-field>
                    <!-- <mat-form-field class="col-md-12 w-100">
                        <input matInput placeholder="Level" formControlName="paramLevel">
                    </mat-form-field> -->
                    <mat-form-field class="textarea col-md-12 custom-select w-100">
                        <textarea matInput  formControlName="availableValues" class="text-ara-sty h-36"></textarea>

                    </mat-form-field>
                    <mat-label style="font-size: 12px; font-weight: 400;padding-top:1px;padding-left:18px;">Available Values</mat-label>
                    <mat-form-field class="col-md-12 w-100">
                        <input matInput placeholder="Default" formControlName="defaultValue">
                    </mat-form-field>
                    <h2 mat-dialog-title style="margin-bottom: 2%;font-weight:bold" class="sub-heading">Rules</h2>
                    <mat-form-field class="col-md-12 custom-select w-100 ">
                        <mat-label >Rules</mat-label>
                        <mat-select  formControlName="rule" (selectionChange)="ruleSelect()">
                            <mat-option *ngFor="let rule of rulesList" [value]="rule.value">{{
                                rule.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                  <div [ngStyle]="{'display': showRulesOptions && selectedRule === 'Reference' ? 'block' : 'none'}">
                    <mat-form-field class="col-md-12 custom-select w-100">
                      <mat-label>Source</mat-label>
                      <mat-select formControlName="referenceSource">
                        <mat-option *ngFor="let lookup of lookupFields" [value]="lookup.value">{{
                          lookup.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <ag-grid-angular
                          class="ag-theme-alpine epr-feed-gui-grid" style="height: 200px !important;"
                          #referenceTable
                          (cellValueChanged)="referenceTableRecUpdated($event)"
                          [context]="contextObj"
                          [frameworkComponents]="frameworkComponents"
                          [stopEditingWhenCellsLoseFocus]="true"
                          [stopEditingWhenGridLosesFocus]="true"
                    ></ag-grid-angular>
                    <div style="cursor: pointer; margin-top: 10px" (click)="add()">+
                      <span style="color: #CC0502">Add Value</span></div>
                  </div>

                  <div *ngIf="showRulesOptions && selectedRule === 'Imply'">
                    <mat-form-field class="col-md-12 w-100">
                      <input matInput placeholder="Value" formControlName="imply">
                    </mat-form-field>
                  </div>

                  <div *ngIf="showRulesOptions && selectedRule === 'Concat'">
                    <mat-form-field class="col-md-12 w-100">
                      <input matInput placeholder="Concat Source 1" [ngModel]="defaultConcatSource1Val" formControlName="concatSource1">
                    </mat-form-field>
                    <mat-form-field class="col-md-12 w-100">
<!--                      <input matInput placeholder="Concat Source 2" formControlName="concatSource2">-->
                      <mat-select formControlName="concatSource2">
                        <mat-option *ngFor="let lookup of lookupFields" [value]="lookup.value">{{
                          lookup.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-12 w-100">
                      <input matInput placeholder="Concat Source 3" [ngModel]="defaultConcatSource3Val" formControlName="concatSource3">
                    </mat-form-field>
                  </div>



                  <div *ngIf="showRulesOptions && selectedRule === 'Source'">
                    <mat-form-field class="col-md-12 custom-select w-100">
                      <mat-label>Lookup</mat-label>
                      <mat-select formControlName="source">
                        <mat-option *ngFor="let lookup of lookupFields" [value]="lookup.value">{{
                          lookup.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="showRulesOptions && selectedRule === 'Substring'">
                    <mat-form-field class="col-md-12 custom-select w-100">
                      <mat-label>Source</mat-label>
                      <mat-select formControlName="subStringSource">
                        <mat-option *ngFor="let lookup of lookupFields" [value]="lookup.value">{{
                          lookup.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-12 w-100">
                      <input matInput placeholder="Start Position" formControlName="subStringStart">
                    </mat-form-field>
                    <mat-form-field class="col-md-12 w-100">
                      <input matInput placeholder="Length" formControlName="subStringLength">
                    </mat-form-field>
                  </div>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions class="row mat-bottom-btns jst-btw">
                <button class="mat-raised-button col-4 delete-button " (click)="close()">Delete</button>
                <button [ngClass]="isFormValid() ? 'save-button' : 'savedisabled-button'" class="mat-raised-button mat-primary col-4"  (click)="save()">Save</button>
            </mat-dialog-actions>
        </ng-container>
    </ng-container>
</div>
