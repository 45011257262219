import {Action} from '@ngrx/store';
import {ClearEprAction, CopyEprAction} from './epr.action';

export const UPDATE_ELIGIBILITY_AGREEMENT_FORM = '[ELIGIBILITY AGREEMENT] Update Eligibility Agreement Form';
export const LOAD_ELIGIBILITY_AGREEMENT_FORM = '[ELIGIBILITY AGREEMENT] Load Eligibility Agreement Form';
export const DELETE_CHANGE_LOG = '[ELIGIBILITY AGREEMENT] Delete Change Log Entry';

export class UpdateEligibilityAgreementFormAction implements Action {
  readonly type = UPDATE_ELIGIBILITY_AGREEMENT_FORM;

  constructor(public payload: any) { }
}

export class LoadEligibilityAgreementFormAction implements Action {
  readonly type = LOAD_ELIGIBILITY_AGREEMENT_FORM;

  constructor(public payload: any) {
  }
}

export class DeleteChangeLogAction implements Action {
  readonly type = DELETE_CHANGE_LOG;

  constructor(public payload: number) {
  }
}

export type Actions = UpdateEligibilityAgreementFormAction | ClearEprAction | LoadEligibilityAgreementFormAction | CopyEprAction;
