import { Injectable } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray, AbstractControl } from '@angular/forms';
import { isNullOrUndefined } from 'angular-component-library';
import { CRITERIA } from './rte-suspense.enum';
@Injectable({
  providedIn: 'root'
})
export class RteSuspenseFormService {

  constructor(private formBuilder: FormBuilder) { }
  generateSuspenseForm(): any {
    const form = new UntypedFormGroup({
      radio: new UntypedFormControl(null, Validators.required),
      criteria: new UntypedFormControl(null, Validators.required),
    });
    return form;
  }

  validation(control: AbstractControl) {
    let valid = null;
    const value = control.value;
    let carrierArray = new Array();
    if (value) {
      if (/^[^`~!@#$%\^&*()_+={}|[\]\\:';"<>?./]*$/.test(control.value)) {
        if (value.indexOf(',') !== -1) {
          carrierArray = value.split(',');
          if (carrierArray.length >= 151) {
            return valid = { required: true };
          }
          let i = 0;
          for (const carrier of carrierArray) {
            const space = carrier.split(' ').length - 1;
            if (!(carrier.replace(/\s/g, '').length >= 4 && carrier.replace(/\s/g, '').length <= 9)) {
              valid = { required: true };
            }
            i++;
          }
        } else {
          const space = value.split(' ').length - 1;
          if (!(value.replace(/\s/g, '').length >= 4 && value.replace(/\s/g, '').length <= 9)) {
            valid = { required: true };
          }
          if (space >= 1) {
            valid = { required: true };
          }
        }
      } else {
        valid = { required: true };
      }
      return valid;
    }
  }

  generateCriteriaDependentForm(criteria) {
    let form;
    switch (criteria) {
      case CRITERIA.CARRIER: {
        form = new UntypedFormGroup({
          carrier: new UntypedFormControl(null, [Validators.required, Validators.maxLength(9)])
        })
      }
        break;
      case CRITERIA.CARRIERLIST: {
        form = new UntypedFormGroup({
          carrierList: new UntypedFormControl(null, [Validators.required, this.validation])
        })
      } break;
      case CRITERIA.CARRIERACCOUNT: {
        form = new UntypedFormGroup({
          carrier: new UntypedFormControl(null, [Validators.required, Validators.maxLength(9)]),
          account: new UntypedFormControl(null, [Validators.required, Validators.maxLength(15)])
        })
      } break;
      case CRITERIA.CARRIERACCGROUP: {
        form = new UntypedFormGroup({
          carrier: new UntypedFormControl(null, [Validators.required, Validators.maxLength(9)]),
          account: new UntypedFormControl(null, [Validators.required, Validators.maxLength(15)]),
          group: new UntypedFormControl(null, [Validators.required, Validators.maxLength(15)])
        })
      } break;
      case CRITERIA.RANGE: {
        form = new UntypedFormGroup({
          carrierFrom: new UntypedFormControl(null, [Validators.required, Validators.maxLength(9)]),
          carrierEnd: new UntypedFormControl(null, [Validators.required, Validators.maxLength(9)]),
          accountFrom: new UntypedFormControl(null, [Validators.required, Validators.maxLength(15)]),
          accountEnd: new UntypedFormControl(null, [Validators.required, Validators.maxLength(15)]),
          groupFrom: new UntypedFormControl(null, [Validators.required, Validators.maxLength(15)]),
          groupEnd: new UntypedFormControl(null, [Validators.required, Validators.maxLength(15)])
        })
      } break;
    }
    return form;
  }
  generateScheduleForm() {
    const form = new UntypedFormGroup({
      occurence: new UntypedFormControl(null, Validators.required),
      time: new UntypedFormControl(null, Validators.required),
      reportName: new UntypedFormControl(null, Validators.required),
      recipient: new UntypedFormControl(null, Validators.required),
    });
    return form;
  }

  createRecipient(email: string) {
    return new UntypedFormGroup({
      recipientName: new UntypedFormControl({ value: email, disabled: email ? true : false }, [Validators.required, Validators.email]),
    })
  }

  getFormattedTime(time) {
    if (!isNullOrUndefined(time) && time.toString().length === 1) {
      return time === 0 ? "00:00" : `0${time}:00`;
    }
    return time ? `${time}:00` : null;
  }

  generateOccurenceDepenedentScheduleForm(occurence, { email, rowData }) {
    let form;
    const timeFormatted = this.getFormattedTime(rowData?.scheduleRecord?.scheduleTime)
    switch (occurence) {
      case OPTIONS.occurence[0]: {
        form = new UntypedFormGroup({
          on: new UntypedFormControl(rowData?.scheduleRecord?.dayFrequency, Validators.required),
          day: new UntypedFormControl(rowData?.scheduleRecord?.numOfDays, Validators.required),
          time: new UntypedFormControl(timeFormatted, Validators.required),
          reportName: new UntypedFormControl(rowData?.reportName, Validators.required),
          recipient: new UntypedFormArray([this.createRecipient(email)])
        })
      }
        break;
      case OPTIONS.occurence[1]: {
        form = new UntypedFormGroup({
          weeks: new UntypedFormControl(rowData?.scheduleRecord?.weekFrequency, Validators.required),
          on: new UntypedFormControl(rowData?.scheduleRecord?.weekDay, Validators.required),
          time: new UntypedFormControl(timeFormatted, Validators.required),
          reportName: new UntypedFormControl(rowData?.reportName, Validators.required),
          recipient: new UntypedFormArray([this.createRecipient(email)])
        })
      } break;
      case OPTIONS.occurence[2]: {
        form = new UntypedFormGroup({
          on: new UntypedFormControl(rowData?.scheduleRecord?.dayOfMonth, Validators.required),
          day: new UntypedFormControl(rowData?.scheduleRecord?.monthDate ? rowData?.scheduleRecord?.monthDate : rowData?.scheduleRecord?.weekDayForMonth, Validators.required),
          months: new UntypedFormControl(rowData?.scheduleRecord?.monthlyFrequency, Validators.required),
          time: new UntypedFormControl(timeFormatted, Validators.required),
          reportName: new UntypedFormControl(rowData?.reportName, Validators.required),
          recipient: new UntypedFormArray([this.createRecipient(email)])
        })
      } break;
      case OPTIONS.occurence[3]: {
        form = new UntypedFormGroup({
          on: new UntypedFormControl(rowData?.scheduleRecord?.dayOfMonth, Validators.required),
          day: new UntypedFormControl(rowData?.scheduleRecord?.monthDate ? rowData?.scheduleRecord?.monthDate : rowData?.scheduleRecord?.weekDayForMonth, Validators.required),
          months: new UntypedFormControl(rowData?.scheduleRecord?.frequencyOfYear, Validators.required),
          time: new UntypedFormControl(timeFormatted, Validators.required),
          reportName: new UntypedFormControl(rowData?.reportName, Validators.required),
          recipient: new UntypedFormArray([this.createRecipient(email)])
        })
      } break;
    }
    return form;

  }
}

export const OPTIONS = {
  occurence: ["Daily", "Weekly", "Monthly", "Yearly"]
}
