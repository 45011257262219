import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AgGridAngular } from "ag-grid-angular";
import { defaultColumns, minMaxColumns } from "./pli-carrier-Columns";
import { InputType } from "src/app/models/FormFieldConfig";
import { EprService } from "src/app/services/epr.service";
import { catchError, switchMap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { PliCarrierDateCellRendererComponent } from "./renderer/PliCarrierDateCellRenderer";
import { PliCarrierMutiSelectCellRendererComponent } from "./renderer/PliCarrierMultiSelectCellRenderer";

@Component({
  selector: "app-pli-carrier-model",
  templateUrl: "./pli-carrier-model.component.html",
  styleUrls: ["./pli-carrier-model.component.scss"],
})
export class PliCarrierModelComponent implements OnInit {
  @ViewChild("pliClientgrid", { static: false }) grid: AgGridAngular;
  rowData: any[];
  orignalRows: any[] = [];
  readOnly: boolean;
  isEprReadOnly: any;
  isSubGroup: boolean = false;
  frameworkComponents: any;
  constructor(
    private dialogRef: MatDialogRef<PliCarrierModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eprService: EprService
  ) {}

  ngOnInit(): void {
    this.frameworkComponents = {
      myDateEditor: PliCarrierDateCellRendererComponent,
      myMultiSelectEditor: PliCarrierMutiSelectCellRendererComponent
    };
    this.readOnly = true;
    this.isEprReadOnly = this.data.questionareDetail?.readOnly;
    if (this.data.inputType === InputType.HORIZONTAL_FIELD_SET) {
      this.isSubGroup = true;
    }
  }

  onGridReady(event) {
    
    
    this.grid.gridOptions.context = {
      providedInfo: this.data,
      saveTriggered: false,
      parentRef: this,
      stopEditingWhenGridLosesFocus: true,
      ssoeData: this.data?.ssoeData,
    };
    let col: any;
    if (this.isSubGroup) {
      col = minMaxColumns;
    } else {
      col = defaultColumns;

      col[1] = {
        ...col[1],
        field: this.data.controlName,
      };
    }
    if (this.data.ssoeData || this.isEprReadOnly) {
      col = col.filter((columns) => columns.field !== "delete");
    }
   
    this.grid.api.setColumnDefs(col);
    this.setDefaultRows();
  }
  setDefaultRows() {
    const questionare = this.data.questionareDetail;
    let rows = [];
    if (this.data?.ssoeData) {
      // populate profile section data
      rows = this.populateSsoeData();
    } else {
      // populate ewf data

      if (this.data?.carrierList.length) {
        rows = this.getpreSavedRows();
      } else {
        if (this.isSubGroup) {
          rows.push({
            pliCarrier: questionare.pli,
            min: this.data.controlValue.min,
            max: this.data.controlValue.max,
            type: "default",
          });
          
        } else {
          rows.push({
            pliCarrier: questionare.pli,
            [this.data.controlName]: this.data.controlValue,
            type: "default",
          });
          
        }
        this.readOnly = false;
      }
    }
    this.rowData = rows;
    this.orignalRows = new Array(...rows);
    this.grid.api.setRowData(this.rowData);
  }
  populateSsoeData() {
    let rows = [];
    const ssoeData = this.data.ssoeData;
    const pliRow = ssoeData.find((data) => data.carrierId === "Default");
    const carrierRow = ssoeData.filter((data) => data.carrierId !== "Default");
    if (this.isSubGroup) {
      rows.push({
        pliCarrier: this.data.questionareDetail.pli,
        min: pliRow.value.min,
        max: pliRow.value.max,
        type: "default",
      });
      carrierRow.forEach((r) => {
        rows.push({
          pliCarrier: r.carrierId,
          min: r.value.min,
          max: r.value.max,
        });
      });
    } else {
      rows.push({
        pliCarrier: this.data.questionareDetail.pli,
        [this.data.controlName]: pliRow.value,
        type: "default",
      });
      carrierRow.forEach((r) => {
        rows.push({
          pliCarrier: r.carrierId,
          [this.data.controlName]: r.value,
        });
      });
    }
    return rows;
  }
  getpreSavedRows(): any[] {
    const rows = [];
    let existingCarriers = this.data?.carrierList;

    let pliRow = existingCarriers.find(
      (data) => data?.pliOrCarrierIndicator === "P"
    );
    if (this.isSubGroup) {
      if (
        pliRow.answer.min !== this.data.controlValue.min ||
        pliRow.answer.max !== this.data.controlValue.max
      ) {
        this.readOnly = false;
        pliRow.answer = this.data.controlValue;
        //pli value saved (1st row data) not being same as of current control value .
      }
    } else {
      if(this.data.inputType === InputType.MULTI_SELECT || this.data.inputType === InputType.FREEFORM_MULTISELECT){
        if(pliRow.answer?.length === this.data.controlValue?.length){
        
          pliRow.answer.forEach((value,index)=>{

          if(!this.data.controlValue[index] || this.data.controlValue[index] !== value){
            this.readOnly = false;
            pliRow.answer = this.data.controlValue;
          }
          })
         
       
        }else{
          this.readOnly = false;
          pliRow.answer = this.data.controlValue;
        }
      }else{

        if (pliRow.answer !== this.data.controlValue) {
          this.readOnly = false;
          pliRow.answer = this.data.controlValue;
          //pli value saved (1st row data) not being same as of current control value .
        }
        
     }
    }

    if (pliRow.pliOrCarrier !== this.data.questionareDetail?.pli) {
      // pli was updated.
      this.readOnly = false;
      pliRow.pliOrCarrier = this.data.questionareDetail?.pli;
    }

    let carrierRow = existingCarriers
      .filter((data) => data?.pliOrCarrierIndicator !== "P")
      .map((row) => {
        if (this.data.questionareDetail?.carrier &&
          !(this.data.questionareDetail?.carrier as Array<any>).includes(
            row.pliOrCarrier
          )
        ) {
          row.pliOrCarrier = null;
          this.readOnly = false;
        }
        return row;
      });

    if (this.isSubGroup) {
      rows.push({
        pliCarrier: pliRow.pliOrCarrier,
        min: pliRow.answer.min,
        max: pliRow.answer.max,
        type: "default",
        id: pliRow.id,
      });
      carrierRow.forEach((r) => {
        rows.push({
          pliCarrier: r.pliOrCarrier,
          min: r.answer.min,
          max: r.answer.max,
          id: r.id,
        });
      });
    } else {
      rows.push({
        pliCarrier: pliRow.pliOrCarrier,
        [this.data.controlName]: pliRow.answer,
        type: "default",
        id: pliRow.id,
      });
      carrierRow.forEach((r) => {
        rows.push({
          pliCarrier: r.pliOrCarrier,
          [this.data.controlName]: r.answer,
          id: r.id,
        });
      });
    }
    return rows;
  }

  addRow() {
    const existingRows = this.getCurrentRows();
    let newRow: any;
    if (this.isSubGroup) {
      newRow = { pliCarrier: null, min: null, max: null };
    }
    newRow = { pliCarrier: null, [this.data.controlName]: null };
    this.rowData = [...existingRows, newRow];
    this.grid.api.setRowData(this.rowData);
    this.verifyChanges();
  }
  getCurrentRows() {
    const existingRows = [];
    this.grid.api.forEachNode((row) => existingRows.push(row.data));
    return existingRows;
  }

  verifyChanges() {
    if (!this.data?.carrierList.length) {
      this.readOnly = false;
      return;
      //new case
    }
    const existingRows = this.getCurrentRows();
    const orignalRows = this.getpreSavedRows();

    existingRows.forEach((eRow, i) => {
      if (!eRow.id) {
        this.readOnly = false;
        return;
        // add new case
      }
      const oRow = orignalRows[i];
      Object.entries(eRow).forEach(([key, value]) => {
        if (value !== oRow[key]) {
          this.readOnly = false;
          return;
        }
      });
    });
  }
  onRowClick(event) {}
  save() {
    this.grid.api.stopEditing(false); 
    this.grid.gridOptions.context.saveTriggered = true;
    this.grid.api.refreshCells({ force: true });
    
    let updatedRows = [];
    const questionnaire = this.data.questionareDetail;
    this.grid.api.forEachNode((rowNode) => {
      updatedRows.push(rowNode?.data);
    });
   
    let allowSave = true;
    updatedRows = updatedRows.map((row) => {
      if (
        !row?.type &&
        !(this.isSubGroup
          ? row.pliCarrier && row.min && row.max
          : row.pliCarrier && row[this.data.controlName])
      ) {
        allowSave = false;
      }
      return {
        id: row?.id || null,
        pli: questionnaire?.pli,
        eprId: questionnaire?.eprId,
        questionnaireId: questionnaire?.questionnaireId,
        questionId: this.data?.id,
        pliOrCarrier: row.pliCarrier,
        answer: this.isSubGroup
          ? { min: row.min, max: row.max }
          : row[this.data.controlName],
        pliOrCarrierIndicator: row?.type ? "P" : "C",
        sendToSsoe: this.data?.sendToSsoe,
        questionName: this.data?.label,
        questionTechnicalName: this.data.technicalName,
        questionSection: this.data?.section,
        carrierLevel: this.data.carrierLevel ? "Yes" : "No"
      };
    });
    if (!allowSave) {
      return;
    }
    const deletePayload = this.data.deleteRows;
    if (deletePayload.length) {
      const deleteApis = [];
      deletePayload.forEach((id) => {
        deleteApis.push(
          this.eprService.deleteCarrierLevel(id).pipe(
            catchError((e) => {
              return of(e);
            })
          )
        );
      });
      forkJoin(deleteApis)
        .pipe(
          switchMap((resp) => {
            return this.eprService.saveCarrierLevel(updatedRows);
          })
        )
        .subscribe((res) => {
          this.dialogRef.close(true);
        });
    } else {
      this.eprService.saveCarrierLevel(updatedRows).subscribe((res) => {
        this.dialogRef.close(true);
      });
    }
  }
  close() {
    this.dialogRef.close(false);
  }
}
