import { FormFieldConfig, InputType } from '../models/FormFieldConfig';
import { Validators } from '@angular/forms';
import { select } from '@ngrx/store';

export const CopayOnboardingFormConfig: any = [
    [
        {
            label: 'Setup ID',
            controlName: 'cltSetupID'
        },
        {
            label: 'Client Receiver ID',
            controlName: 'clientReceiverID',
            inputType: InputType.sinkInput1,
            controller: [Validators.required],
            errorMessage: 'Client Receiver is required',
            title: 'REQUIRED FIELD',
            required: true,
            index: 1
        },
        {
            label: 'Business Unit',
            controlName: 'businessUnit',
            inputType: InputType.sinkInput2,
            controller: [Validators.required],
            errorMessage: 'Client Receiver is required',
            title: 'REQUIRED FIELD',
            required: true,
            index: 1
        },
        {
            label: 'Input File Name',
            controlName: 'InputFileName',
            inputType: InputType.sinkInput3,
            controller: [Validators.required],
            errorMessage: 'Input File Name is required',
            title: 'REQUIRED FIELD',
            required: true,
            index: 2
        },
        {
            label: 'Output File Name',
            controlName: 'OutputFileName',
            inputType: InputType.sinkInput4,
            controller: [Validators.required],
            errorMessage: 'Output File Name is required',
            title: 'REQUIRED FIELD',
            required: true,
            index: 3
        },
        {
            label: 'File Frequency',
            controlName: 'fileFrequency',
            controlValName: 'fileFrequencyVal',
            inputType: InputType.DROPDOWN,
            controller: [Validators.required],
            errorMessage: 'File Frequency is required',
            title: 'REQUIRED FIELD',
            options: [
                { value: 'Daily M-F', name: 'Daily M-F' },
                { value: 'Daily', name: 'Daily 7 Days' },
                { value: 'Weekly', name: 'Weekly' },
                { value: 'Monthly', name: 'Monthly' }
            ],
            required: true,
            listing: 'repFrequency',
            listingoptionsweekly: [
                {value: 'SUNDAY', name: 'Sunday'},
                {value: 'MONDAY', name: 'Monday'},
                 {value: 'TUESDAY', name: 'Tuesday'},
                {value: 'WEDNESDAY', name: 'Wednesday'},
                 {value: 'THURSDAY', name: 'Thursday'},
                {value: 'FRIDAY', name: 'Friday'},
                 {value: 'SATURDAY', name: 'Saturday'}
            ],
            listingoptionsdailyMtoF: [
                { value: 'DailyM-F', name: 'Daily M-F' },
              ],
          listingoptionsdaily7: [
            { value: 'Daily', name: 'Daily 7 Days' },
          ],
          listingoptionsmonthly: [
                {value: '1', name: '1'},
                {value: '2', name: '2'},
                 {value: '3', name: '3'},
                {value: '4', name: '4'},
                 {value: '5', name: '5'},
                {value: '6', name: '6'},
                 {value: '7', name: '7'},
                {value: '8', name: '8'},
                 {value: '9', name: '9'},
                {value: '10', name: '10'},
                {value: '11', name: '11'},
                {value: '12', name: '12'},
                 {value: '13', name: '13'},
                {value: '14', name: '14'},
                 {value: '15', name: '15'},
                {value: '16', name: '16'},
                 {value: '17', name: '17'},
                {value: '18', name: '18'},
                 {value: '19', name: '19'},
                {value: '20', name: '20'},
                {value: '21', name: '21'},
                {value: '22', name: '22'},
                 {value: '23', name: '23'},
                {value: '24', name: '24'},
                 {value: '25', name: '25'},
                {value: '26', name: '26'},
                 {value: '27', name: '27'},
                {value: '28', name: '28'},
                 {value: '29', name: '29'},
                {value: '30', name: '30'},
                {value: '31', name: '31'},
            ],
        },
        {
            label: 'Contracted Delivery Time For Input (CST)',
            controlName: 'contractedDeliveryTime',
            title: 'Central TimeZone',
            inputType: InputType.TIME,
            controller: [Validators.required],
            required: true,
        },
        {
            label: 'Late File Warning Time For Input (CST)',
            controlName: 'lateFileWarningTime',
            title: 'Central TimeZone',
            inputType: InputType.TIME,
            controller: [Validators.required],
            required: true,

        },
        {
            label: 'Processing Time SLA For Output files',
            controlName: 'processingTime',
            inputType: InputType.sinkInput5,
            controller: [Validators.required],
            errorMessage: 'Processing Time SLA is required',
            title: 'REQUIRED FIELD',
            required: true,
            index: 4
        },
        {
            label: 'Late file warning SLA For Output files',
            controlName: 'lateFileWarningAlertOutputFiles',
            inputType: InputType.sinkInput6,
            controller: [Validators.required],
            errorMessage: 'Late file warning Alert for Output files is required',
            title: 'REQUIRED FIELD',
            required: true,
            index: 5
        },
        {
            label: 'Missed File SLA Alert For Input',
            controlName: 'missedFileSLAAlertInput',
            inputType: InputType.DROPDOWN,
            title: 'REQUIRED FIELD',
            options: [
                { value: 'Y', name: 'Yes' },
                { value: 'N', name: 'No'}
            ],
            Inputnotes: 'missedFilesSlalIn',
            alertType: 'COPAY Missing SLA files alert Input',
        },
        {
            label: 'Late File Warning Alert For Input',
            controlName: 'lateFileWarningAlertInput',
            inputType: InputType.DROPDOWN,
            title: 'REQUIRED FIELD',
            options: [
                { value: 'Y', name: 'Yes' },
                { value: 'N', name: 'No'}
            ],
            InputlateFiles: 'lateFilesCallIn',
            alertType: 'COPAY Late file warning alert Input',
        },
        {
            label: 'Missed File SLA Alert For Output',
            controlName: 'missedFileSLAAlertOutput',
            inputType: InputType.DROPDOWN,
            title: 'REQUIRED FIELD',
            options: [
                { value: 'Y', name: 'Yes' },
                { value: 'N', name: 'No'}
            ],
            Outputnotes: 'missedFilesSlalOp',
            alertType: 'COPAY Missing SLA files alert Output for detail',
        },
        {
            label: 'Late File Warning Alert For Output',
            controlName: 'lateFileWarningAlertOutput',
            inputType: InputType.DROPDOWN,
            title: 'REQUIRED FIELD',
            options: [
                { value: 'Y', name: 'Yes' },
                { value: 'N', name: 'No'}
            ],
            OutputlateFiles: 'lateFilesCallOp',
            alertType: 'COPAY Late file warning alert Output for detail',
        },
        {
            label: 'Active/InActive Status',
            controlName: 'activeorInactiveStatus',
            inputType: InputType.DROPDOWN,
            controller: [Validators.required],
            errorMessage: 'File Type is required',
            dropdownTitle: 'REQUIRED FIELD',
            options: [
                { value: 'Y' , name: 'Yes'} ,
                { value: 'N', name: 'No'}
            ],
            required: true
        },
        {
            label: 'Note',
            controlName: 'note',
            title: 'Special File Processing Notes - Intended for Internal user',
            inputType: InputType.TEXT_AREA
        }
    ]
] as FormFieldConfig[][];
