import {Action, createAction, props} from '@ngrx/store';
import {ClearEprAction, CopyEprAction} from './epr.action';

export const UPDATE_HEADER_FORM = '[HEADER] Update Header Form';
export const LOAD_HEADER_FORM = '[HEADER] Load Header Form';
export const LOAD_HEADER_FORM_SUCCESS = '[HEADER] Load Header Form Success';

export class UpdateHeaderFormAction implements Action {
  readonly type = UPDATE_HEADER_FORM;

  constructor(public payload: any) {
  }
}

export class LoadHeaderFormAction implements Action {
  readonly type = LOAD_HEADER_FORM;
  readonly id: number;

  constructor(public payload: any) {
    this.id = 1;
  }
}

export class LoadHeaderSuccessAction implements Action {
  readonly type = LOAD_HEADER_FORM_SUCCESS;

  constructor(public payload: any) { }
}

export const loadHeaderFormAction = createAction(
  LOAD_HEADER_FORM,
  props<{ id: number }>()
  )
;

export type Actions = UpdateHeaderFormAction
  | LoadHeaderFormAction
  | LoadHeaderSuccessAction
  | ClearEprAction
  | CopyEprAction;
