import {Action} from '@ngrx/store';
import {
  AddProjectTrackerFormAction,
  ClearEprAction,
  DeleteProjectTrackerFormAction,
  ResetProjectIndicesActions,
  SelectProjectAction,
  CopyEprAction
} from './epr.action';

export const UPDATE_TESTING = '[TESTING] Update Testing Form';
export const ADD_TEST_ITERATION = '[TESTING] Add Test Iteration Form';
export const DELETE_TEST_ITERATION = '[TESTING] DELETE Test Iteration Form';
export const DELETE_TEST_ITERATION_SUCCESS = '[TESTING] DELETE Test Iteration Form Success';
export const CHANGE_SELECTED_TEST_ITERATION = '[TESTING] Selecting new test iteration form...';
export const UPDATE_CUSTOM_TEST_CRITERIA = '[TESTING] Update Custom Test Criteria Form';
export const LOAD_TESTING_SUCCESS = '[TESTING] Load Testing Form Success';
export const DELETE_CUSTOM_TEST_CRITERIA = '[TESTING] Delete Custom Test Criteria';
export const UPDATE_BATCH_PROFILE = '[TESTING] Update Batch Update Form';

export class AddTestIterationAction implements Action {
  readonly type = ADD_TEST_ITERATION;
}
export class DeleteTestIterationAction implements Action {
  readonly type = DELETE_TEST_ITERATION;

  constructor(public id: number, public iteration: number) { }
}

export class ChangeSelectedTestIterationAction implements Action {
  readonly type = CHANGE_SELECTED_TEST_ITERATION;

  constructor(public payload: number) { }
}

export class UpdateTestingFormAction implements Action {
  readonly type = UPDATE_TESTING;

  constructor(public payload: any) { }
}


export class BatchProfileUpdateFormAction implements Action {
  readonly type = UPDATE_BATCH_PROFILE;

  constructor(public payload: any) { }
}

export class LoadTestingFormAction implements Action {
  readonly type = LOAD_TESTING_SUCCESS;

  constructor(public payload: any) { }
}

export class UpdateCustomTestCriteriaFormAction implements Action {
  readonly type = UPDATE_CUSTOM_TEST_CRITERIA;

  constructor(public payload: any) { }
}

export class DeleteCustomTestCriteriaAction implements Action {
  readonly type = DELETE_CUSTOM_TEST_CRITERIA;

  constructor(public payload: any) { }
}

export class DeleteTestIterationSuccessAction implements Action {
  readonly type = DELETE_TEST_ITERATION_SUCCESS;
}

export type Actions = UpdateCustomTestCriteriaFormAction
  | UpdateTestingFormAction
  | AddTestIterationAction
  | ChangeSelectedTestIterationAction
  | LoadTestingFormAction
  | ClearEprAction
  | AddProjectTrackerFormAction
  | DeleteProjectTrackerFormAction
  | SelectProjectAction
  | ResetProjectIndicesActions
  | DeleteTestIterationAction
  | DeleteTestIterationSuccessAction
  | CopyEprAction;
