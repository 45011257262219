<cvs-alert-placeholder id="file-layout"></cvs-alert-placeholder>
<div class="row mb-10">
  <h1>File Tracker Profile</h1>
  <div>
    <button id="edit-btn" class="cvs-btn-primary"   *ngIf="!isEdited && (isRegisteredUser$ | async) && isAdmin"(click)="startEdit()">
    <mat-icon svgIcon="pencil--s"></mat-icon>
    Edit
  </button>
    <button id="cancel-edit-btn" class="cvs-btn-primary" (click)="saveSpedm()" *ngIf="isEdited" [disabled]="form.invalid || isErrorMessage">
    <mat-icon>save</mat-icon>
    Save
    </button>
  </div>
  <div>
    <button id="cancel-btn" style="margin-left: 25px;" (click)="cancelSpedm()"  class="cvs-btn-primary">
    <mat-icon>cancel</mat-icon>
    Cancel
    </button>
  </div>
</div>
<form [formGroup]="form">
  <div class="grid">
    <div *ngFor="let section of spedmFiletrackerFormConfig; let i = index">
      <div class="inputs">
        <div *ngFor="let field of section; let k = index" [id]="k">
          <div [ngSwitch]="field.inputType" class="input-field">

            <mat-grid-list cols="3" rowHeight="28px" *ngSwitchCase="inputType.sinkInput1" class="text input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile>
                <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" matTooltip="REQUIRED FIELD" matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" labelText="Client Receiver">
                </app-label-link>
                <span style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile class="auto_width" [ngStyle]="{'width': width1 + 'px'}">
                <input type="text" #elasticInput1 [ngStyle]="{'minWidth': width1 + 'px'}" maxlength="120" (input)="resize(1)" [id]="'input_' + k" class="clientReceiver" formControlName="clientReceiver">
                <span #hiddenText1 style="visibility: hidden; white-space: pre;">{{elasticInput1.value}}</span>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="3" rowHeight="28px" *ngSwitchCase="inputType.sinkInput2" class="text input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile>
                <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" matTooltip="REQUIRED FIELD" matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" labelText="Client Name">
                </app-label-link>
                <span style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile class="auto_width" [ngStyle]="{'width': width2 + 'px'}">
                <input type="text" #elasticInput2 [ngStyle]="{'minWidth': width2 + 'px'}" maxlength="200" (input)="resize(2)" [id]="'input_' + k" class="clientName" formControlName="clientName">
                <span #hiddenText2 style="visibility: hidden; white-space: pre;">{{elasticInput2.value}}</span>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="3" rowHeight="28px" *ngSwitchCase="inputType.sinkInput3" class="text input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile>
                <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" matTooltip="REQUIRED FIELD" matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" labelText="Drug Name">
                </app-label-link>
                <span style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile class="auto_width" [ngStyle]="{'width': width3 + 'px'}">
                <input type="text" #elasticInput3 [ngStyle]="{'minWidth': width3 + 'px'}" maxlength="20" (input)="resize(3)" [id]="'input_' + k" class="drugName" formControlName="drugName">
                <span #hiddenText3 style="visibility: hidden; white-space: pre;">{{elasticInput3.value}}</span>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="3" rowHeight="28px" *ngSwitchCase="inputType.DRM_DROPDOWN" class="row dropdown input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile>
                <app-label-link  [hyperlink]="getHyperlink(field.controlName, hyperlinks)"  matTooltip="REQUIRED FIELD" matTooltipClass="tooltip-bg-color"
                                 matTooltipHideDelay="20" [labelText]="field.label">
                </app-label-link>
                <span style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile>
                <select formControlName="{{field.controlName}}"  (ngModelChange)="showoptions($event, field.controlName,field)">
                  <option *ngFor="let user of usersList" [value]=" user.lastName+','+user.firstName">{{user.lastName}} {{user.firstName}}</option>
                </select>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="3" rowHeight="28px" *ngSwitchCase="inputType.sinkInput4" class="text input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile>
                <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" matTooltip="REQUIRED FIELD" matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" labelText="File Name Mask">
                </app-label-link>
                <span style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile class="auto_width" [ngStyle]="{'width': width4 + 'px'}">
                <input type="text" #elasticInput4 [ngStyle]="{'minWidth': width4 + 'px'}" maxlength="240" (input)="resize(4)" [id]="'input_' + k" class="filenameMask" formControlName="filenameMask">
                <span #hiddenText4 style="visibility: hidden; white-space: pre;">{{elasticInput4.value}}</span>
              </mat-grid-tile>
            </mat-grid-list>


              <!-- <mat-grid-tile>
                <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" [matTooltip]="field.title" matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" [labelText]="field.label">
                </app-label-link>
                <span *ngIf="field.required" style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile class="auto_width" [ngStyle]="{'width.px': width }">
                <input type="text" #elasticInput [ngStyle]="{'minWidth': width + 'px'}" (input)="resize(field.index)" [id]="'input_' + k" [class]="field.controlName" formControlName="{{field.controlName}}">
                <span #hiddenText1 *ngIf="field.index == '1'" style="visibility: hidden; white-space: pre;">{{elasticInput.value}}</span>
                <span #hiddenText2 *ngIf="field.index == '2'" style="visibility: hidden; white-space: pre;">{{elasticInput.value}}</span>
                <span #hiddenText3 *ngIf="field.index == '3'" style="visibility: hidden; white-space: pre;">{{elasticInput.value}}</span>
                <span #hiddenText4 *ngIf="field.index == '4'" style="visibility: hidden; white-space: pre;">{{elasticInput.value}}</span>
              </mat-grid-tile> -->

            <!-- onkeypress="this.style.minWidth = ((this.value.length + 1) * 7) + 'px';" -->


            <mat-grid-list cols="3" rowHeight="125px" *ngSwitchCase="inputType.TEXT_AREA" class="row text-area" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile>
                <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" [matTooltip]="field.title" matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" [labelText]="field.label"></app-label-link>
                <span *ngIf="field.required" style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile class="text-area-wt">
                <textarea type="text" [class]="field.controlName" maxlength="1000"  formControlName="{{field.controlName}}"></textarea>
              </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="3" rowHeight="28px" *ngSwitchCase="inputType.DROPDOWN" class="row dropdown input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile>
                <app-label-link [matTooltip]="field.dropdownTitle" matTooltipClass="tooltip-bg-color" matTooltipHideDelay="20" [hyperlink]="getHyperlink(field.controlName, hyperlinks)"
                  [labelText]="field.label"></app-label-link>
                <span *ngIf="field.required" style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile>
                <select formControlName="{{field.controlName}}" (ngModelChange)="showoptions($event, field.controlName,field)">
                <option *ngFor="let option of field.options" [value]="option.value">{{option.name}}</option>
              </select>
              </mat-grid-tile>
              <mat-grid-tile class="subDiv">
                <button *ngIf="(field.lateFiles && isLateFileViewConatct) && !latefileShowAddConatct " (click)="addContacts(field.alertType)" [id]="field.notes + '_' + k"
                        class=" {{field.controlName}}-notes">
                <span *ngIf="!isEdited">View Contact</span>
                <span *ngIf="isEdited">Edit Contact</span>
                </button>
                <button *ngIf="(field.lateFiles && latefileShowAddConatct) && !isLateFileViewConatct " (click)="addContacts(field.alertType)" [id]="field.notes + '_' + k"
                        class=" {{field.controlName}}-notes">
                Add Contact
                </button>

                <button *ngIf="(field.notes && ismissedFileViewConatct) && !missedfileShowAddConatct" (click)="addContacts(field.alertType)" [id]="field.notes + '_' + k"
                  class=" {{field.controlName}}-notes">
                  <span *ngIf="!isEdited">View Contact</span>
                  <span *ngIf="isEdited">Edit Contact</span>
                </button>
                <button *ngIf="(field.notes && missedfileShowAddConatct) && !ismissedFileViewConatct" (click)="addContacts(field.alertType)" [id]="field.notes + '_' + k"
                  class=" {{field.controlName}}-notes">
                  Add Contact
                </button>
                <span *ngIf="field.listing">
                  <select
                    formControlName="{{field.controlValName}}" *ngIf="showDailyMtoF">
                <option *ngFor="let option of field.listingoptionsdailyMtoF" [value]="option.value" selected>{{option.name}}</option>
              </select>
                  <select
                     formControlName="{{field.controlValName}}" *ngIf="showDaily7Days" >
                <option *ngFor="let option of field.listingoptionsdaily7" [value]="option.value" selected>{{option.name}}</option>
              </select>
                  <select
                  formControlName="{{field.controlValName}}" *ngIf="showWeekly" [ngStyle]="{'border':(isWeeklySelected)? '2px solid #FF0000FF':''}">
                <option *ngFor="let option of field.listingoptionsweekly" [value]="option.value">{{option.name}}</option>
              </select>
              <select
                  formControlName="{{field.controlValName}}" *ngIf="showMonthly" [ngStyle]="{'border':(isMonthlySelected)? '2px solid #FF0000FF':''}">
                <option *ngFor="let option of field.listingoptionsmonthly" [value]="option.value">{{option.name}}</option>
              </select>
                </span>
              </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="3" rowHeight="28px" *ngSwitchCase="inputType.TIME" class="row dropdown input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile>
                <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" [matTooltip]="field.title" matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" [labelText]="field.label">
                </app-label-link>
                <span *ngIf="field.required" style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile>
                <input type="time" [id]="'input_' + k" [class]="field.controlName" formControlName="{{field.controlName}}">
              </mat-grid-tile>
              <div style="margin-left: 500px">
                <span  *ngIf="errors[field.controlName]?.message"  style="color: #CC0502;height:300px; width:300px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">{{errors[field.controlName].message}}</span>
              </div>
            </mat-grid-list>


            <div *ngSwitchCase="inputType.MDL_DROPDOWN" class="dropdown input" [ngClass]="field.heading ? 'heading' : ''">
              <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" [labelText]="field.label"></app-label-link>
              <select formControlName="{{field.controlName}}">
                <option *ngFor="let option of (field.mdlName | async)?.options"
                        [value]="option">{{option}}</option>
              </select>
            </div>

            <div *ngSwitchCase="inputType.FREEFORM_MULTISELECT" class="auto-complete multi-select input" [ngClass]="field.heading ? 'heading' : ''">
              <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" [labelText]="field.label"></app-label-link>

              <app-freeform-multiselect [formControlName]="field.controlName" [defaultOptions]="field.options"></app-freeform-multiselect>
            </div>

            <div *ngSwitchCase="inputType.MULTI_SELECT" class="multi-select input" [ngClass]="field.heading ? 'heading' : ''">
              <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" [labelText]="field.label"></app-label-link>

              <mat-select multiple formControlName="{{field.controlName}}">
                <mat-option *ngFor="let option of field.options" [value]="option">{{option}}</mat-option>
              </mat-select>
            </div>

            <div *ngSwitchCase="inputType.HORIZONTAL_FIELD_SET" class="horizontal-field-set" [ngClass]="field.heading ? 'heading' : ''">
              <app-label-link [hyperlink]="getHyperlink(field.subFormGroup[0].controlName, hyperlinks)" [labelText]="field.label"></app-label-link>
              <div class="input-fields">
                <div *ngFor="let subForm of field.subFormGroup">
                  <ng-container [ngSwitch]="subForm.inputType">
                    <div *ngSwitchCase="inputType.TEXT" class="text input">
                      <input type="text" matInput formControlName="{{subForm.controlName}}">
                    </div>

                    <div *ngSwitchCase="inputType.DROPDOWN" class="dropdown input">
                      <select formControlName="{{subForm.controlName}}">
                        <option *ngFor="let option of subForm.options" [value]="option">{{option}}</option>
                      </select>
                    </div>

                  </ng-container>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</form>
