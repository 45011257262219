import {Action} from '@ngrx/store';
import {MasterDataList} from '../models/MasterDataList';

export const UPDATE_MASTER_DATA_SET = '[Data Mgmt] Update Master Data Set';

export const LOAD_MASTER_DATA_SET = '[Data Mgmt] Load Master Data Set';
export const LOAD_MASTER_DATA_SET_SUCCESS = '[Data Mgmt] Master Data Set Loaded Success';

export const SAVE_MASTER_DATA_SET = '[Data Mgmt] Save Master Data Set';
export const SAVE_MASTER_DATA_SET_SUCCESS = '[Data Mgmt] Master Data Set Saved Success';

export class UpdateMasterDataSetAction implements Action {
  readonly type = UPDATE_MASTER_DATA_SET;

  constructor(public payload: string[]) { }
}

export class LoadMasterDataSetAction implements Action {
  readonly type = LOAD_MASTER_DATA_SET;

  constructor(public dataSetName: string) { }
}

export class LoadMasterDataSetSuccessAction implements Action {
  readonly type = LOAD_MASTER_DATA_SET_SUCCESS;

  constructor(public payload: MasterDataList) { }
}

export class SaveMasterDataSetAction implements Action {
  readonly type = SAVE_MASTER_DATA_SET;
}

export class SaveMasterDataSetSuccessAction implements Action {
  readonly type = SAVE_MASTER_DATA_SET_SUCCESS;

  constructor(public payload: MasterDataList) { }
}

export type Actions
  = LoadMasterDataSetAction | LoadMasterDataSetSuccessAction | SaveMasterDataSetAction |
  SaveMasterDataSetSuccessAction | UpdateMasterDataSetAction;
