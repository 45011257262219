import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {UsersService} from '../services/users.service';
import * as users from '../actions/user-management.action';
import {User} from '../models/User';
import {CVSAlertService, CVSAlertType} from 'angular-component-library';
import {NoopAction} from '../actions/noop.action';
import {AuthService} from '../services/auth.service';
import {Authorization} from '../models/Authorization';

@Injectable()
export class UserManagementEffects {
  loadUsers$ = createEffect(() => this.actions$.pipe(
    ofType(users.LOAD_USERS),
    mergeMap(() => this.usersService.getAll()
      .pipe(
        map(usersValue => ({ type: users.LOAD_USERS_SUCCESS, payload: usersValue as User[] })),
        catchError(() => of({type: users.LOAD_USERS_FAILURE}))
      ))
    )
  );

  loadUsersSetting$ = createEffect(() => this.actions$.pipe(
    ofType(users.LOAD_USER_SETTING),
    mergeMap((action) => {
      return this.usersService.getAllUserSettingInfo(action['payload'])
      .pipe(
        map(usersValue => ({ type: users.LOAD_USERS_SETTING_SUCCESS, payload: usersValue as User[] })),
        catchError(() => of({type: users.LOAD_USERS_SETTING_FAILURE}))
      )
    }
      )
    )
  );
  saveUser$ = createEffect(() => this.actions$.pipe(
    ofType(users.SAVE_USER),
    mergeMap(action => this.usersService.saveUser(action['payload'])
      .pipe(
        map(usersValue => ({ type: users.SAVE_USER_SUCCESS, payload: usersValue as User })),
        tap(() => {
          this.alertService.sendAlert({
            headline: 'Your changes have been saved',
            alertType: CVSAlertType.Success,
            fadeClass: true,
            alertDocumentHolderName: '#register-user-alert'
          });
        }),
        catchError(() => {
          this.alertService.sendAlert({
            headline: 'Your changes have not been saved',
            alertType: CVSAlertType.Error,
            fadeClass: true,
            alertDocumentHolderName: '#register-user-alert'
          });
          return of(new NoopAction());
        })
      ))
    )
  );

  editUser$ = createEffect(() => this.actions$.pipe(
    ofType(users.EDIT_USER),
    mergeMap(action => this.usersService.editUser(action['payload'])
      .pipe(
        map(usersValue => ({ type: users.EDIT_USER_SUCCESS, payload: usersValue as User })),
        tap(() => {
          this.alertService.sendAlert({
            headline: 'Your changes have been saved',
            alertType: CVSAlertType.Success,
            fadeClass: true,
            alertDocumentHolderName: '#register-user-alert'
          });
        }),
        catchError(() => {
          this.alertService.sendAlert({
            headline: 'Your changes have not been saved',
            alertType: CVSAlertType.Error,
            fadeClass: true,
            alertDocumentHolderName: '#register-user-alert'
          });
          return of(new NoopAction());
        })
      ))
    )
  );

  loadRoles$ = createEffect(() => this.actions$.pipe(
    ofType(users.LOAD_ROLES),
    mergeMap(() => this.authService.getAll()
      .pipe(
        map(roles => ({ type: users.LOAD_ROLES_SUCCESS, payload: roles as Authorization[] })),
        catchError(() => of(new NoopAction()))
      ))
    )
  );

  constructor(
    private actions$: Actions,
    private usersService: UsersService,
    private alertService: CVSAlertService,
    private authService: AuthService,
  ) { }
}
