import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from './../../environments/environment';
import { CVSHttpClient } from 'angular-component-library';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThresholdTranslationsService {
  addNewThresholdRow$ = new BehaviorSubject(null);
  saveNewRecords$ = new BehaviorSubject(false);


  constructor(private http: CVSHttpClient) { }

  getAllThresholdTranslations(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/threshold-translation/get`);
  }
  saveThresholdTranslationRow(request: any): Observable<any> {

    return this.http.post(`${environment.baseUrl}/threshold-translation/save`, request);
  }
  deleteThresholdTranslationRow(request: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/threshold-translation/delete`, request);
  }
  onMessage(): Observable<any> {
    return this.addNewThresholdRow$.asObservable();
  }
  addNewThresholdRow(newRow: any) {
    this.addNewThresholdRow$.next(newRow);
  }

}
