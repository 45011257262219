import {Component} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";

@Component({
    selector: 'app-gird-input-form-cell',
    template: `
        <div *ngIf="formGroup" [formGroup]="formGroup">
            <mat-form-field  [ngClass]="formGroup.get(key)?.hasError('required') ? 'invalid':''" style="width: 100%" appearance="none">
                <input matInput  [formControlName]="key" [id]="rowId" >
            </mat-form-field>
        </div>
    `
})
export class GridInputFormRendererComponent {
    formGroup: FormGroup;
    key;
    value;
    columnName: string;
     rowId: number;

    agInit(params: any) {
        this.columnName = params.colDef.headerName;
        this.key = params.colDef.field;
       // params.context.createKey(params.columnApi, params.column);
        this.value = params.data[this.key];
        this.rowId = params.data.rowId;
        //params.node.id; //work as form array index
        this.formGroup = params.context.formGroupArray[this.rowId];
        //this.formGroup.get(this.key).patchValue(this.value);
    }

    refresh(params: any): boolean {
        // this.formGroup = params.context.formGroupArray[this.rowId];
        // this.formGroup.get(this.key).patchValue(this.value);

        // this could also be done in GridComponent.createFormControls, but the cell component could be doing something with
        // the value, so it feels more natural that the control value be set here
        
        
        
        return true;
    }
}
