import {Action} from '@ngrx/store';
import {Filetracker} from '../models/Filetracker';
import {Contact} from '../models/Contact';

export const LOAD_ALL_FILETRACKER = '[Filetracker] Load All Filetracker';
export const LOAD_ALL_FILETRACKER_SUCCESS = '[Filetracker] Filetracker ALL Loaded Success';
export const LOAD_ALL_FILETRACKER_FAILURE = '[Filetracker] Filetracker ALL Loaded Failure';
export const LOAD_FILETRACKER = '[Filetracker] Load Filetracker';
export const LOAD_FILETRACKER_SUCCESS = '[Filetracker] Filetracker Loaded Success';
export const LOAD_FILETRACKER_FAILURE = '[Filetracker] Filetracker Loaded Failure';
export const CLEAR_FILETRACKER_DATA = '[Filetrakcer]  Clearing all Filetracker Data';
export const SAVE_FILETRACKER = '[Filetracker] Save Filetracker';
export const UPDATE_FILETRACKER = '[Filetracker] Update Filetracker';
export const SAVE_FILETRACKER_SUCCESS = '[Filetracker] Save Filetracker Success';
export const SAVE_FILETRACKER_SUCCESS_FAILURE = '[Filetracker] Filetracker Saved Failure';
export const LOAD_CONTACT = '[Contact] Contact Loaded';
export const LOAD_CONTACT_SUCCESS = '[Contact] Contact Loaded Success';
export const SAVE_CONTACT = '[Contact] Save Contact';
export const SAVE_CONTACT_SUCCESS = '[Contact] Save Contact Success';
export const SAVE_CONTACT_SUCCESS_FAILURE = '[Contact] Contact Saved Failure';
export const EDIT_FILETRACKER = '[Filetracker] Edit Filetracker';
export const EDIT_FILETRACKER_SUCCESS = '[Filetracker] Edit Filetracker Success';
export const EDIT_FILETRACKER_SUCCESS_FAILURE = '[Filetracker] Filetracker Edit Failure';
export const DELETE_FILETRACKER = '[Filetracker] Deleting Filetracker...';
export const DELETE_FILETRACKER_SUCCESS = '[Filetracker] Filetracker deleted successfully';
export const FILTER_FILETRACKER_BY_ID = '[Filetracker] Filter Filetrackers By Id';
export const UPDATE_CONTACT = '[Contact] Contact Updated';
export const UPDATE_CONTACT_SUCCESS = '[Contact] Contact Updated Successfully';
export const UPDATE_CONTACT_SUCCESS_FAILURE = '[Contact] Contact Updated Failure';
export const DELETE_CONTACT = '[EPRFEED] Deleting Cont...';
export const DELETE_CONTACT_SUCCESS = '[EPRFEED] EprFeed deleted successfully';
export const LOAD_DATA = 'LoadData';
export const EDIT_MODE = 'Edit';

export class LoadAllFiletrackerAction implements Action {
  readonly type = LOAD_ALL_FILETRACKER;
}

export class LoadAllFiletrackerSuccessAction implements Action {
  readonly type = LOAD_ALL_FILETRACKER_SUCCESS;
  constructor(public payload: Filetracker[]) { }
}

export class LoadAllFiletrackerFailureAction implements Action {
  readonly type = LOAD_ALL_FILETRACKER_FAILURE;
}
export class LoadFiletrackerAction implements Action {
  readonly type = LOAD_FILETRACKER;

  constructor(public payload: number) {
  }
}
export class ClearFiletrakcerAction implements Action {
  readonly type = CLEAR_FILETRACKER_DATA;
}

export class LoadFiletrackerSuccessAction implements Action {
  readonly type = LOAD_FILETRACKER_SUCCESS;

  constructor(public payload: Filetracker) { }
}

export class LoadFiletrackerFailureAction implements Action {
  readonly type = LOAD_FILETRACKER_FAILURE;
}

export class SaveFiletrackerAction implements Action {
  readonly type = SAVE_FILETRACKER;

  constructor(public payload: Filetracker) { }
}
export class UpdateFiletrackerAction implements Action {
  readonly type = UPDATE_FILETRACKER;

  constructor(public payload: Filetracker) { }
}
export class UpdateContact implements Action {
  readonly type = UPDATE_CONTACT;

  constructor(public payload: any) { }
}

export class UpdateContactSuccessAction implements Action {
  readonly type = UPDATE_CONTACT_SUCCESS;

  constructor(public payload: Filetracker) { }
}

export class UpdateContactFailureAction implements Action {
  readonly type = UPDATE_CONTACT_SUCCESS_FAILURE;
}
export class SaveFiletrackerSuccessAction implements Action {
  readonly type = SAVE_FILETRACKER_SUCCESS;

  constructor(public payload: Filetracker) { }
}

export class SaveFiletrackerFailureAction implements Action {
  readonly type = SAVE_FILETRACKER_SUCCESS_FAILURE;
}
export class LoadContactAction implements Action {
  readonly type = LOAD_CONTACT;

  constructor(public payload: any) {
  }
}
export class LoadContactSuccessAction implements Action {
  readonly type = LOAD_CONTACT_SUCCESS;

  constructor(public payload: Contact) { }
}
export class SaveContactAction implements Action {
  readonly type = SAVE_CONTACT;

  constructor(public payload: any ) { }
}


export class SaveContactSuccessAction implements Action {
  readonly type = SAVE_CONTACT_SUCCESS;

  constructor(public payload: Contact) { }
}

export class SaveContactFailureAction implements Action {
  readonly type = SAVE_CONTACT_SUCCESS_FAILURE;
}

export class EditFiletrackerAction implements Action {
  readonly type = EDIT_FILETRACKER;

  constructor(public payload: any) { }
}

export class EditFiletrackerSuccessAction implements Action {
  readonly type = EDIT_FILETRACKER_SUCCESS;

  constructor(public payload: Filetracker) { }
}

export class EditFiletrackerFailureAction implements Action {
  readonly type = EDIT_FILETRACKER_SUCCESS_FAILURE;
}
export class DeleteFiletrackerAction implements Action {
  readonly type = DELETE_FILETRACKER;

  constructor(public payload: number) {
  }
}
export class DeleteFiletrackerSuccessAction implements Action {
  readonly type = DELETE_FILETRACKER_SUCCESS;
}
export class FilterFiletrackerById implements Action {
  readonly type = FILTER_FILETRACKER_BY_ID;

  constructor(public payload: any) {}
}
export class DeleteContactAction implements Action {
  readonly type = DELETE_CONTACT;

  constructor(public payload: number) {
  }
}
export class DeleteContactSuccessAction implements Action {
  readonly type = DELETE_CONTACT_SUCCESS;
}
export class LoadDataAction implements Action {
  readonly type = LOAD_DATA;

  constructor(public payload: any) {
  }
}
export class EditAction implements Action {
  readonly type = EDIT_MODE;

  constructor(public payload: boolean) {
  }
}


export type Actions
  = LoadFiletrackerAction
  | LoadFiletrackerSuccessAction
  | LoadFiletrackerFailureAction
  | LoadAllFiletrackerAction
  | LoadAllFiletrackerSuccessAction
  | LoadAllFiletrackerFailureAction
  | SaveFiletrackerAction
  | UpdateFiletrackerAction
  | SaveFiletrackerSuccessAction
  | SaveFiletrackerFailureAction
  | LoadContactAction
  | LoadContactSuccessAction
  | SaveContactAction
  | SaveContactSuccessAction
  | SaveContactFailureAction
  | EditFiletrackerAction
  | EditFiletrackerSuccessAction
  | EditFiletrackerFailureAction
  | DeleteFiletrackerAction
  | DeleteFiletrackerSuccessAction
  | FilterFiletrackerById
  | ClearFiletrakcerAction
  | UpdateContact
  | UpdateContactSuccessAction
  | UpdateContactFailureAction
  | DeleteContactAction
  | DeleteContactSuccessAction
  | LoadDataAction
  | EditAction;

