
<cvs-alert-placeholder id="linkuser-alert">
</cvs-alert-placeholder>
<button mat-icon-button style="float: right; margin:5px;" (click)="close()">
    <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
    <header>
        <h1>EPR User Access</h1>
    </header>
</mat-dialog-content>

<div class="search-add-container">
    <span>
        <mat-form-field appearance="standard">
            <input matInput placeholder="Search" [(ngModel)]="searchText" (ngModelChange)="onFilterBoxChanged()">
            <mat-icon svgIcon="search--s"></mat-icon>
        </mat-form-field>
    </span>
</div>

<div class="grid">
    <ag-grid-angular class="ag-theme-alpine pds-ag-grid" #epruseraccessmodelgrid (gridReady)="onGridReady($event)"
    [rowSelection]="rowSelection"  (rowClicked)="onRowClick($event)"
    [overlayLoadingTemplate]="loadingTemplate"
    [overlayNoRowsTemplate]="noRowsTemplate">
    </ag-grid-angular>
</div>
<mat-dialog-actions align="end">
    <button mat-raised-button color="warn" matDialogClose>cancel</button>
    <button mat-raised-button color="Primary" (click)="link()">Link</button>
</mat-dialog-actions>