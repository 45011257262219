import { Component, Input } from "@angular/core";

@Component({
  selector: "app-out-of-office-icon-renderer",
  template:
    `<mat-icon *ngIf="isInactive && fromDate && toDate" svgIcon="user-external--s" [cvsTooltip]="cvsToolTip" 
               [placement]="placement" [showArrow]="true"></mat-icon>
    <mat-icon *ngIf="!isInactive" class="inactive-user"  svgIcon="user-remove--s"></mat-icon>`,
  styles: [
    `
    mat-icon{
      color: #F4642A
    }
    mat-icon.inactive-user{
      color: #cc0000;
      fill: #cc0000;
    }
      .tooltip-icon {
        display: inline-block;
        position: relative;
        cursor: pointer;

        pointer-events:auto

      }
      .tooltip {
        position: absolute;
        max-width: 200px;
      background-color: #000;
        color: #fff;
        padding: 10px;
        border-radius: 5px;
        z-index: 1;
      }
    `,
  ],
})
export class OutOfOfficeIconRendererComponent {
  @Input() fromDate: string;
  @Input() backupName?: string;
  @Input() toDate: string;
  @Input() fromTime?: string;
  @Input() toTime?: string;
  @Input() placement: string;
  @Input() isInactive?: boolean = false;

  get cvsToolTip() {
    return `Out of Office: ${this.fromDate}${this.fromTime ? ', ' + this.fromTime : ''} to ${this.toDate}${this.toTime ? ', ' + this.toTime : ''}. ${this.backupName ? 'Backup User: ' + this.backupName : ''}.`
  }
}
