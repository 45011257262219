import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Permission} from "../models/Authorization";
import {UsersService} from "../services/users.service";
import {Store} from "@ngrx/store";
import * as fromRoot from "../reducers";
import {
  BaseComponent,
  CVSAuthConfig,
  CVSBaseConfig,
  MenuState,
  SideNavConfig,
  SideNavService
} from "angular-component-library";
import {Subscription} from "rxjs";
import {User} from "../models/User";
import {setLoggedInUserAction} from "../actions/user.action";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: []
})
export class HomePageComponent {
  constructor() { }
}
