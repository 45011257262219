<cvs-alert-placeholder id="header-alert"></cvs-alert-placeholder>

<header>
  <div class="container">
    <cvs-alert-placeholder id="consolidate-profile"></cvs-alert-placeholder>
    <div class="row">
      <div class="col-12">

        <h1 style="justify-content: flex-start">EPR Information</h1>
        <div style="display:flex;">
          <p class="header-button" style="justify-content: flex-end;">
            <ng-s *ngIf="!isEditProfiles" style="display: flex;">
            <button mat-button [matMenuTriggerFor]="menu" id="menu-edit-btn" 
            [disabled]="isProjectTestingEditable"
            *ngIf="consildatedFlag && (readOnly$ | async) && isRegisteredUser$ && showEditButton && ( eprObject.buttons.EDIT_EPR)"
                    class="cvs-mat-button" style="margin-right: 20px">
              <!--      <mat-icon svgIcon="pencil&#45;&#45;s"></mat-icon>-->
                    <mat-icon svgIcon="angle-down--s"></mat-icon>
              Edit
            </button>

            <mat-menu #menu="matMenu" [overlapTrigger]="false">
              <button mat-menu-item (click)="editQuestionnaire()">
                <span>Edit Questionaire</span>
              </button>
              <button mat-menu-item (click)="editProfiles()">
                <span>Edit Profiles</span>
              </button>
             </mat-menu>


            <button id="edit-btn" *ngIf="!consildatedFlag && (readOnly$ | async) && isRegisteredUser$ && showEditButton && (eprObject.buttons.EDIT_EPR)"
                    class="cvs-btn-primary" (click)="startEdit()" [disabled]="isProjectTestingEditable">
              <!--      <mat-icon svgIcon="pencil&#45;&#45;s"></mat-icon>-->
              Edit
            </button>

            <button id="cancel-edit-btn"  [disabled]="isSaveEnable()" *ngIf="(readOnly$ | async) === false" class="cvs-btn-primary"
                    (click)="saveEpr()">
              <!--      <mat-icon>save</mat-icon>-->
              Save
            </button>
            <button id="delete-epr-btn" *ngIf="(eprObject.buttons.DELETE_EPR  && showEditButton)   || ((((role) === Permission.ADMIN || (role) === Permission.ERM ||
            (role) === Permission.LEADERSHIP ||  (role) === Permission.MANAGER))  && showEditButton)  " class="cvs-btn-primary"
                    (click)="openDeleteEprModal()" [disabled]="readOnly$ | async">
              <!--      <mat-icon>delete</mat-icon>-->
              Delete
            </button>

            <button id="copy-epr-btn" *ngIf="allowedTopCopy(role)" class="cvs-btn-ghost" (click)="copyEpr()"
                    [disabled]="((readOnly$ | async) === false) || isProjectTestingEditable">
              <!--      <mat-icon>content_copy</mat-icon>-->
              Copy
            </button>
            <button id="discard-epr-btn" style="width: 140px" class="cvs-btn-ghost" *ngIf="(readOnly$ | async) === false" (click)="discardChanges()">
              Discard Changes
            </button>
            </ng-s>
            <button id="exit-profile-btn" *ngIf="isEditProfiles"
                    class="cvs-btn-primary" (click)="exitProfileEdit()">
              Edit Profile Exit
            </button>
          </p>
          <div id="locked-by" *ngIf="(currentEditorName$ | async)
                                && isRegisteredUser$
                                && lockButton">
            <mat-icon svgIcon="lock&#45;&#45;s"></mat-icon>
            <span>Locked by {{ currentEditorName$ | async}}</span>
          </div>
        </div>

      </div>
    </div>
  </div>

  <form [formGroup]="headerForm" [appConnectFormValues]="formType">
    <div class="row">
      <mat-form-field id="client-name" class="readonly">
        <input matInput type="text" formControlName="clientName" placeholder="Client Name">
        <mat-error *ngIf="!headerForm.get('clientName').valid">
          Client Name is required to save an EPR
        </mat-error>
      </mat-form-field>

      <mat-form-field id="pli">
        <div [ngStyle]="{'display': 'flex', 'margin-top':!isPliValid? '-10px': '0px'}">
          <input matInput type="text" formControlName="pli" placeholder="PLI">
          <span *ngIf="headerForm.get('pli').value !== '' && !isPliValid">
            <mat-icon matTooltip="Prior go live, PLI must be unique, begin with an Alpha character, and be between 3-4 bytes in length"
                      svgIcon="warning-circle--s" style="cursor:pointer; color: orange; width: 20px">
            </mat-icon>
          </span>
        </div>
        <mat-error *ngIf="!headerForm.get('pli').valid">
          PLI  is required to save an EPR
        </mat-error>
      </mat-form-field>

      <mat-form-field id="go-live-date">
        <mat-label>Go-Live Date</mat-label>
        <input matInput [matDatepicker]="datepicker" formControlName="goLiveDate" >
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field id="ql-client-code">
        <input matInput type="text" formControlName="qlClientCode1" placeholder="QL Client Code">
      </mat-form-field>

      <mat-form-field id="client-code">
        <input matInput type="text" formControlName="clientCode1" placeholder="Client Code">
      </mat-form-field>



      <mat-form-field id="rdc">
        <mat-label>Restricted Access Client</mat-label>
        <mat-select formControlName="rdc">
          <mat-option (click)="removeRestricedAccessClient()"></mat-option>
          <mat-option *ngFor="let option of ('restrictedDataClients' | getMdl | async)?.options" [value]="option">
            {{option}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field id="eligibilityConsultant">
        <mat-label style="pointer-events:auto;" class="tooltip-icon" >Assigned EC
          <app-out-of-office-icon-renderer
            *ngIf="oooExistsEC"
            class="icon backup-ec-icon"
            [fromDate]="oooFromDate"
            [toDate]="oooToDate"
            [fromTime]="oooFromTime"
            [toTime]="oooToTime"
            [isInactive]="isUserInactive"
            [placement]="'bottom'">
          </app-out-of-office-icon-renderer>
        </mat-label>
        <mat-select formControlName="eligibilityConsultant" class="mat-select-dropdown" (selectionChange)="onEcChange($event.value)">
          <mat-option *ngFor="let user of usersList" [value]="user.id">{{user.firstName}} {{user.lastName}}</mat-option>
        </mat-select>
        <mat-error *ngIf="!headerForm.get('eligibilityConsultant').valid">
          EC must be assigned to save an EPR
        </mat-error>
      </mat-form-field>

      <div id="obec" class="override-checkbox">
        <mat-checkbox formControlName="obec" (change)="toggleBEC($event)">Override Backup EC</mat-checkbox>
      </div>

    <div formGroupName="backupec">
      <mat-form-field id="id">
        <mat-label style="pointer-events:auto;" class="tooltip-icon" >Backup EC
          <app-out-of-office-icon-renderer
            *ngIf="oooExists"
            class="icon backup-ec-icon"
            [fromDate]="oooFromDateBec"
            [toDate]="oooToDateBec"
            [fromTime]="oooFromTimeBec"
            [toTime]="oooToTimeBec"
            [isInactive]="isUserInactiveBeC"
            [placement]="'bottom'">
          </app-out-of-office-icon-renderer>
        </mat-label>
        <mat-select formControlName="id" class="mat-select-dropdown" >
          <mat-option *ngFor="let user of usersList" [value]="user.id">{{user.firstName}} {{user.lastName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

      <div style="padding-top: 4px" *ngIf="consildatedFlag">
        <mat-form-field id="hold-date">
          <mat-label>Hold Date</mat-label>
          <input matInput [matDatepicker]="holddatepicker" formControlName="holdDate" (ngModelChange)="checkHoldDate()">
          <mat-datepicker-toggle matSuffix [for]="holddatepicker"></mat-datepicker-toggle>
          <mat-datepicker #holddatepicker></mat-datepicker>
        </mat-form-field>
        <mat-basic-chip [ngClass]=" isOnhold ? 'pds-status-chip--error': 'pds-status-chip--success'">{{isOnhold ? 'ON HOLD': 'ACTIVE'}}</mat-basic-chip>
      </div>

    </div>

    <div class="row">
      <mat-form-field  id="carriers" appearance="outline">
        <mat-label>Carriers</mat-label>
        <textarea matInput
        (input)= "resize()" cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        type="text" formControlName="carrierNumber"></textarea>
      </mat-form-field>
    </div>
    <div class="row">

      <mat-error *ngIf="(!headerForm.get('carrierNumber').valid && (readOnly$ | async) === false)">
        Carriers can only be 4-9 characters, no special characters, only one space between carriers, and comma separated
      </mat-error>
    </div>
  </form>
</header>
