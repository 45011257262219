import { Injectable } from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private http: CVSHttpClient) {  }

  addComment(request: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/comments/add`, request);
  }

  getComment(request: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/comments/get`, request);
  }
}
