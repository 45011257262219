/* eslint-disable max-len */
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AgGridAngular} from "ag-grid-angular";
import {CellRendererComponent} from "../../admin/extract-epr-feed-gui/cell-renderer.component";
import {StatusPopoverComponent} from "../../dashboard/status-popover/status-popover.component";
import {rteEprHistoryColumns} from "./rte-epr-historyColumnSets";
import * as ColumnSets from './rte-epr-historyColumnSets';
import {Router} from "@angular/router";
import {RteService} from "../../services/rte.service";
import {UntypedFormBuilder,UntypedFormGroup,Validators} from "@angular/forms";
import {RteEprHistoryService} from "../../services/rte-epr-history.service";
import {saveAs} from "file-saver";
import {DatePipe} from "@angular/common";
import {DateUtilService} from "../../services/dateUtil.service";
import * as moment from "moment";
import { UsersService } from 'src/app/services/users.service';
import {Permission} from '../../models/Authorization';
import * as fromRoot from '../../reducers';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {User} from '../../models/User';
import {
  EditUserAction,
  FilterUsersByRoleAction,
  LoadRolesAction,
  LoadUsersAction,
  SaveUserAction
} from '../../actions/user-management.action';

@Component({
  selector: 'app-rte-epr-history',
  templateUrl: './rte-epr-history.component.html',
  styleUrls: ['./rte-epr-history.component.scss']
})
export class RteEprHistoryComponent implements OnInit {
  @ViewChild('gridEPRMetric') gridEPRMetric: AgGridAngular;
  eprHistoryForm: UntypedFormGroup;
  data: any =[];
  startDate: any;
   endDate: any;
   sectionList:any[] = ["Header","Checklist","Questionnaire","Project","Test Results"]
  dataRows: any[] = [];
  usersList: any = [];
  gridData: any[] = [];
  moreDataErrorGrid: string;
  displayedUsers$ = this.store.select(fromRoot.getDisplayedUsers) as Observable<User[]>;

  constructor(private store: Store<fromRoot.AppState>,private router: Router, private rteEprHistoryService: RteEprHistoryService,
              private dateUtilService: DateUtilService, private fb: UntypedFormBuilder,
              private usersService: UsersService) {
  }

  ngOnInit(){
    this.setupForm();
    this.store.dispatch(new LoadUsersAction());
  }
  private setupForm() {
    this.eprHistoryForm = this.fb.group({
      clientName: null,
      carrier: null,
      pliCode: null,
      assignedEC: null,
      user: null,
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      section: null,
      subSection: null,
      field: null,
    });
  }

  onRowClicked($event: any) {
  }

  onGridReady(params) {
    this.gridEPRMetric = params;
    this.gridEPRMetric.api.setColumnDefs(ColumnSets.rteEprHistoryColumns);
    this.gridEPRMetric.api.redrawRows();
  }

  filterTables(query: any) {
    this.gridEPRMetric.api.setQuickFilter(query);
    var Count = this.gridEPRMetric.api.getDisplayedRowCount();
    if (Count >= 5000){
      this.moreDataErrorGrid = 'More data to display, use filters to narrow down the results'
    }else if(Count < 5000 ){
      this.moreDataErrorGrid = ''
    }
  }


  exportView(event, title) {
    const fileName = title + '.csv';
    const fileData = new Blob([event.api.getDataAsCsv()], {type: 'text/csv'});
    saveAs(fileData, fileName);

  }

  onSubmit(){
   this.getRTEHistory()
  }

  getRTEHistory() {
    this.dataRows = [];
    let userName = [];
    let ecName = [];
    let sectionSelected = [];

    const rteHistoryFormObj = Object.assign({}, this.eprHistoryForm.value);

    if( rteHistoryFormObj.assignedEC !== null ){
      rteHistoryFormObj.assignedEC.forEach(res=>
        ecName.push(res.firstName +" " + res.lastName))
    }

    if( rteHistoryFormObj.user !== null ){
        rteHistoryFormObj.user.forEach(res=>
          userName.push(res.lastName + ", " + res.firstName ))
    }
    if(rteHistoryFormObj.section !== null){
      rteHistoryFormObj.section.forEach(res =>
        sectionSelected.push(res))
    }

    rteHistoryFormObj.clientName = rteHistoryFormObj.clientName
    rteHistoryFormObj.carrier = rteHistoryFormObj.carrier
    rteHistoryFormObj.startDate = this.dateFormat(rteHistoryFormObj.startDate)
    rteHistoryFormObj.endDate = this.dateFormat(rteHistoryFormObj.endDate)
    rteHistoryFormObj.pliCode = rteHistoryFormObj.pliCode;
    rteHistoryFormObj.assignedEC = ecName;
    rteHistoryFormObj.user = userName;
    rteHistoryFormObj.section = sectionSelected;
    rteHistoryFormObj.subSection = rteHistoryFormObj.subSection;
    this.rteEprHistoryService.getEprHistoryByDates(rteHistoryFormObj).subscribe(res => {
      this.dataRows = res;
      this.gridEPRMetric.api.setRowData(this.dataRows);
      if(res.length >= 5000){
     this.moreDataErrorGrid = 'More data to display, use filters to narrow down the results'
      }else{
        this.moreDataErrorGrid=''
      }
    });

  }

  get dataLoaded(): boolean {
    if (this.dataRows.length > 0) {
      return false;
    }
    return true
  }

  dateFormat(dateStr: Date) {
    const date = new Date(dateStr);
    const yyyy = date.getFullYear().toString();
    const MM = this.pad2(date.getMonth() + 1, 2);
    const dd = this.pad2(date.getDate(), 2);
    const hh = this.pad2(date.getHours(), 2);
    const mm = this.pad2(date.getMinutes(), 2);
    const ss = this.pad2(date.getSeconds(), 2);
    const ms = this.pad2(date.getMilliseconds(), 6);

    return yyyy +'-'+ MM +'-'+ dd +' '+ hh +':'+ mm +':'+ ss;
  }

  pad2(num, length) {
    let str = '' + num;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }
}

