<cvs-alert-placeholder id="master-data-alert"></cvs-alert-placeholder>
<!--<div [ngClass]="currentDropdownSelection && (currentDropdownSelection=='fieldActions' || currentDropdownSelection =='validations' ||  currentDropdownSelection =='rejectActions')? 'dm-outerClass' : ''">
-->
<div [ngClass]="showInlineItems() ? 'dm-outerClass': ''">
  <mat-form-field *ngIf="!showInlineItems()">
  <mat-label for="currentDropdownSelection">Select Master Data List</mat-label>
  <mat-select [(value)]="currentDropdownSelection" id="currentDropdownSelection" (selectionChange)="onSelectionChange($event)">
    <mat-option *ngFor="let datum of masterDataList" [value]="datum.value">
      {{datum.displayName}}
    </mat-option>
  </mat-select>
</mat-form-field>


<div *ngIf="this.currentlyDisplayingOptions" class="" [ngClass]="showInlineItems() ? 'dm-outergrid' : 'dropdown-options'" >
  <div *ngIf="this.currentDropdownSelection !== 'translations'">
    <div class="selected-options" *ngIf="selectedDataListOptions.length > 0 && showDelete">
      <span id="num-selected">{{this.selectedDataListOptions.length}} Selected</span>
      <button id="delete-btn"
              class="cvs-btn-text-only"
              (click)="deleteOptions()"
      ><mat-icon svgIcon="trash--s"></mat-icon>Delete</button>
    </div>

    <div id="save-and-add" style="width: 700px">
      <mat-form-field *ngIf="showInlineItems()">
        <mat-label for="currentDropdownSelection">Select Master Data List</mat-label>
        <mat-select [(value)]="currentDropdownSelection" id="currentDropdownSelection" (selectionChange)="onSelectionChange($event)">
          <mat-option *ngFor="let datum of masterDataList" [value]="datum.value">
            {{datum.displayName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button *ngIf="showInlineItems()" style="cursor:pointer;" (click)="exportView(currentDropdownSelection)" class="add-export-btn">
        <mat-icon svgIcon="file-csv--s"  id="dataExportBtn" >
                            Export View
        </mat-icon>
        <span class="export-txt"> Export </span>
      </button>

      <button id="add-btn"  [ngClass]="showInlineItems() ? 'add-record-btn' : 'cvs-btn-text-only'"
              (click)="addButton()"
              *ngIf="currentDropdownSelection && currentDropdownSelection !== 'mappingInstructions'"
      ><span style="gap:10px; font-weight: 800" class="icon-text"><mat-icon svgIcon="plus--s"></mat-icon>Add Record</span></button>

      <button
        class="cvs-btn-primary"
        id="dataSaveBtn"
        (click)="saveMasterDataList()" [disabled]="isSaveDisabled">Save</button>
    </div>
  </div>

  <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
    <div *ngIf="this.addOptionDialog"
         id="new-option"
         class="example-box">
      <div>
        <mat-icon id="new-option-checkbox" svgIcon="drag-drop-dot--s"></mat-icon>
      </div>
      <div>
        <mat-checkbox [disabled]="true"></mat-checkbox>
      </div>
      <textarea id="options-to-add"
                style="margin-left: 9px; padding-left: 3px; border: solid 1px #02a192"
                #addOptionSpan
                matInput
                [(ngModel)]="optionsToAdd"
                cdkTextareaAutosize
                cdkAutosizeMinRows="1">
      </textarea>
    </div>
    <div mt={2} *ngIf="currentDropdownSelection === 'holidays'">
      <ag-grid-angular class="ag-theme-alpine"
                       [ngStyle]="{'height': hlGridHeight + 'px' }"
                       id="holidayGrid"
                       (cellValueChanged)="holidayRecUpdated($event)"
                       [context]="contextObj"
                       [frameworkComponents]="frameworkComponents"
                       [stopEditingWhenCellsLoseFocus]="true"
                       [stopEditingWhenGridLosesFocus]="true"
                       (gridReady)="onGridReady($event)"
      [rowData]="holidaysData">

      </ag-grid-angular>
    </div>

    <div *ngIf="currentDropdownSelection === 'mappingInstructions'"
    id="new-option"
    class="example-box">
      <textarea id="mappingInstructionsText"
           style="margin-left: 9px;font-size: 16px; padding-left: 3px; border: solid 1px #02a192"
           matInput
           cdkTextareaAutosize
           cdkAutosizeMinRows="1"
           appBulletCreater
           [(stringArray)]="mappingComments"
           (input)="editComments()"
           *ngIf="commentsReady"
           >
 </textarea>

</div>

    <!-- Translations Grid -->
    <app-translations-data-grid *ngIf="currentDropdownSelection === 'translations'"></app-translations-data-grid>

   <!-- thresholds Grid -->
    <app-thresholds-data-grid (isSaveCalled)="savedInfo()" *ngIf="currentDropdownSelection === 'threshold'"></app-thresholds-data-grid>

   <!-- Admin Alert Type Grid-->
   <app-admin-alert-type (isSaveCalled)="savedInfo()" *ngIf="currentDropdownSelection === 'adminAlertType'"></app-admin-alert-type>

  <!--Message Supress Grid -->
    <app-msg-supress-data-grid  *ngIf="currentDropdownSelection === 'msgSupression'" (isSaveMsgCalled)="savedInfo()" ></app-msg-supress-data-grid>
  <!--Map Type Grid -->
    <app-map-type  *ngIf="currentDropdownSelection === 'mapType'" (isSaveMapTypeCalled)="savedInfo()" ></app-map-type>
     <!--Reject Action -->
     <app-reject-action *ngIf="currentDropdownSelection === 'rejectActions'" (isRejectActionCalled)="savedInfo()"></app-reject-action>
     <app-validation  *ngIf="currentDropdownSelection === 'validations'" (isSaveValidationCalled)="savedInfo()" ></app-validation>
    <!--Field Action -->
    <app-field-actions  *ngIf="currentDropdownSelection === 'fieldActions'" (isFieldActionsCalled)="savedInfo()" ></app-field-actions>
    <app-reporting-message-types *ngIf="currentDropdownSelection === 'reportingMessageTypes'" (isSaveReportingMessageTypesCalled)="savedInfo()" ></app-reporting-message-types>
    <app-reporting-message-codes  *ngIf="currentDropdownSelection === 'reportingMessageCodes'" (isReportingMessageCodes)="savedInfo()" ></app-reporting-message-codes>

    <!--Version Typr -->

  <app-version-control-data-grid [type]="currentDropdownSelection" *ngIf="currentDropdownSelection === 'isa12' || currentDropdownSelection === 'gs08' || currentDropdownSelection === 'st03'" (isSaveMapTypeCalled)="savedInfo()" >
  </app-version-control-data-grid>
    <!-- <ng-template #commonGrid>
    </ng-template> -->
    <div *ngIf="currentDropdownSelection !== 'translations' && currentDropdownSelection !== 'threshold' &&
    currentDropdownSelection !== 'mapType' &&
    currentDropdownSelection !== 'reportingMessageTypes' && currentDropdownSelection!== 'adminAlertType'
    &&  currentDropdownSelection !== 'reportingMessageCodes' &&
    currentDropdownSelection !== 'validations' && currentDropdownSelection !== 'rejectActions' && currentDropdownSelection !== 'fieldActions' &&
    currentDropdownSelection !== 'msgSupression' && currentDropdownSelection !== 'holidays'
    && currentDropdownSelection !== 'isa12'&& currentDropdownSelection !== 'gs08'
    && currentDropdownSelection !== 'st03' && currentDropdownSelection !== 'mappingInstructions'">
      <div class="example-box" *ngFor="let datum of dataListOptions; let indexOfDatum = index" cdkDrag>
        <mat-icon svgIcon="drag-drop-dot--s"></mat-icon>
        <mat-checkbox (change)="updateSelectedList(indexOfDatum)"></mat-checkbox>
        <span>{{datum}}</span>
      </div>
    </div>

  </div>

</div>
</div>
