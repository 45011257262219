<div class="history-body">
<ng-container>
  <div style="display:flex;justify-content: space-between;">
    <div class="history-doc-header">History</div>
  </div>
    <mat-form-field id="filter-text-box" style="margin-left: 16px">
      <input matInput type="text" placeholder="Search" (keyup)="filterTables($event.target.value)"/>
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
</ng-container>
<table mat-table
       [dataSource]="dataSource" multiTemplateDataRows
       class="mat-elevation-z8" >
       <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon>
          {{expandedElement === element ? 'expand_less' : 'expand_more'}}
        </mat-icon>
        </td>
      </ng-container>
  <ng-container matColumnDef="User">
    <th mat-header-cell *matHeaderCellDef>  User </th>
    <td mat-cell *matCellDef="let element"> {{element.eprAuditUser}} </td>
  </ng-container>
  <ng-container matColumnDef="TimeStamp">
    <th mat-header-cell *matHeaderCellDef> Time Stamp </th>
    <td mat-cell *matCellDef="let element"> {{element.timeStamp}} </td>
  </ng-container>
  <ng-container matColumnDef="TotalChanges">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let element"><span  class="totalCell">{{element.totalchanges}}</span></td>
  </ng-container>
    <ng-container  matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
      <div class="element-detail"
           [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'" >
           <ag-grid-angular
           style="min-height: 100% !important ; min-width:100%; padding-top:2rem;padding-bottom: 2rem;"
           class="ag-theme-alpine"
           [rowData]="subgriddata"
           [columnDefs]="columnDefs"
           (gridReady)="onGridReady($event)"
           (firstDataRendered)="onFirstDataRendered($event)">
       </ag-grid-angular>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
      class="element-row" (click)="onRowClicked(element)"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"  class="detail-row"></tr>
</table>
</div>
