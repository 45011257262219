import {Actions, LOAD_ELIGIBILITY_AGREEMENT_FORM, UPDATE_ELIGIBILITY_AGREEMENT_FORM} from '../actions/eligibility-agreement.action';
import {CLEAR_EPR_DATA, COPY_EPR} from '../actions/epr.action';

interface Change {
  dateCreated: string;
  change: string;
}

export interface State {
  changeLog: Change[];

  // File Parameters
  fileNamingProd: string;
  grpFileNamingProd: string;
  fileNamingTest: string;
  fileNamingCompare: string;

  // Member Identification
  cEPRFamID: string;
  cEPRFAltIdCode: string;
  cEPRFPersonCode: string;
  cEPR3rdID: string;

  // Member Terms and Movement
  cEPRTrmDates4AllMbrs: string;
  cEPRDepImpTrms: string;
  cEPRImpTrms: string;
  cEPRImpTrm2: string;
  cEPRTMR: string;
  cEPRTMR2: string;
  cEPRTMR3: string;
  cEPRTMR4: string;
  cEPRTMRIgnFutureTrm: string;
  cEPRTMRInclFtrEff: string;
  cEPRHistMthd: string;

  // Additional Considerations
  cEPRSrvvngSps: string;
  cEPRCOB: string;
  cEPRPCOBNCOBNote: string;
  cEPRGrpSusp: string;
  cEPRSuspense: string;

  // fields that are shown under misc items based on form logic
  cERPBatchOvr: string;
  cEPRCHOnly: string;
  cEPRFamonCards: string;
  cEPRAutoCAG: string;
  cEPRStandaloneDeps: string;
  cEPRSplitFam: string;
  field174: string;

  thresholdNotes: string;
}

export const initialState: State = {
  changeLog: [],

  fileNamingProd: '',
  grpFileNamingProd: '',
  fileNamingTest: '',
  fileNamingCompare: '',

  cEPRFamID: '',
  cEPRFAltIdCode: '',
  cEPRFPersonCode: '',
  cEPR3rdID: '',
  cEPRTrmDates4AllMbrs: '',
  cEPRDepImpTrms: '',
  cEPRImpTrms: '',
  cEPRImpTrm2: '',
  cEPRTMR: '',
  cEPRTMR2: '',
  cEPRTMR3: '',
  cEPRTMR4: '',
  cEPRTMRIgnFutureTrm: '',
  cEPRTMRInclFtrEff: '',
  cEPRHistMthd: '',
  cEPRSrvvngSps: 'Contact your Eligibility Consultant to discuss how surviving spouse situations are to be handled.',
  cEPRCOB: 'Not Applicable',
  cEPRPCOBNCOBNote: '',
  cEPRGrpSusp: '',
  cEPRSuspense: 'Member Suspense is not used.',
  cERPBatchOvr: '',
  cEPRCHOnly: '',
  cEPRFamonCards: '',
  cEPRAutoCAG: '',
  cEPRStandaloneDeps: '',
  cEPRSplitFam: '',
  field174: '',
  thresholdNotes: ''
};

export function reducer(state = initialState, action: Actions): State {
  
  switch (action.type) {
    case UPDATE_ELIGIBILITY_AGREEMENT_FORM:
      
      
      return {...changeSortOrder(state), ...changeSortOrder(action.payload)};

    case LOAD_ELIGIBILITY_AGREEMENT_FORM:
      return changeSortOrder(action.payload);

    case CLEAR_EPR_DATA:
      return initialState;

    case COPY_EPR:
      return {
        ...changeSortOrder(state),
        thresholdNotes: '',
        field174: '',
      };

    default:
      return changeSortOrder(state);
  }
}

export const getCEPRTMR = (state: State) => state?.cEPRTMR;
export const getGrpFileNamingProd = (state: State) => state?.grpFileNamingProd;
export const getCEPRSuspense = (state: State) => state?.cEPRSuspense;
export const getCEPRFamonCards = (state: State) => state?.cEPRFamonCards;
export const getCEPRSrvvngSps = (state: State) => state?.cEPRSrvvngSps;
export const getCEPRFamID = (state: State) => state?.cEPRFamID;

export const getCEPRFAltIdCode = (state: State) => state?.cEPRFAltIdCode;
export const getCEPRFPersonCode = (state: State) => state?.cEPRFPersonCode;
export const getCEPRCOB = (state: State) => state?.cEPRCOB;
export const getCEPRHistMthd = (state: State) => state?.cEPRHistMthd;
export const getCEPRTrmDates4AllMbrs = (state: State) => state?.cEPRTrmDates4AllMbrs;
export const getCEPRDepImpTrms = (state: State) => state?.cEPRDepImpTrms;
export const getCEPRImpTrms = (state: State) => state?.cEPRImpTrms;
export const getFileNamingCompare = (state: State) => state?.fileNamingCompare;
export const getCEPRImpTrm2 = (state: State) => state?.cEPRImpTrm2;
export const getCEPRPCOBNCOBNote = (state: State) => state?.cEPRPCOBNCOBNote;
export const getCEPR3rdID = (state: State) => state?.cEPR3rdID;
export const getFileNamingProd = (state: State) => state?.fileNamingProd;
export const getFileNamingTest = (state: State) => state?.fileNamingTest;
export const getCEPRGrpSusp = (state: State) => state?.cEPRGrpSusp;
export const getCERPBatchOvr = (state: State) => state?.cERPBatchOvr;
export const getCEPRCHOnly = (state: State) => state?.cEPRCHOnly;
export const getCEPRAutoCAG = (state: State) => state?.cEPRAutoCAG;
export const getCEPRStandaloneDeps = (state: State) => state?.cEPRStandaloneDeps;
export const getCEPRSplitFam = (state: State) => state?.cEPRSplitFam;
export const getCEPRTMR2 = (state: State) => state?.cEPRTMR2;
export const getCEPRTMR3 = (state: State) => state?.cEPRTMR3;
export const getCEPRTMR4 = (state: State) => state?.cEPRTMR4;
export const getCEPRTMRIgnFutureTrm = (state: State) => state?.cEPRTMRIgnFutureTrm;
export const getCEPRTMRInclFtrEff = (state: State) => state?.cEPRTMRInclFtrEff;
export const getChangeLog = (state: State) => state?.changeLog
export const getThresholdNotes = (state: State)=> state?.thresholdNotes;
function changeSortOrder(info: any): any {
  if(info?.changeLog){
    if(info.changeLog.length > 1){

      info.changeLog = info.changeLog.sort((log1,log2)=>{
        return log1.dateCreated > log2.dateCreated ? 1 : log1.dateCreated < log2.dateCreated ? -1 : 0;
      })
    }
   
  }
  return info
}

