import {Pipe, PipeTransform} from '@angular/core';
import {UsersService} from '../services/users.service';
import {Permission} from '../models/Authorization';
import {User} from '../models/User';
import {Observable} from 'rxjs';

@Pipe({
  name: 'getUsers'
})
export class GetUsersPipe implements PipeTransform {
  constructor(private usersService: UsersService) {}

  transform(permissions: Permission[]): Observable<User[]> {
    return this.usersService.getUsersByRole(permissions);
  }
}
