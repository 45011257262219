/* eslint-disable max-len */
import {Component, OnInit} from '@angular/core';
import {UsersService} from "../services/users.service";


@Component({
  selector: 'app-rte',
  templateUrl: './rte.component.html',
  styleUrls: ['./rte.component.scss']
})
export class RteComponent implements OnInit {
  constructor(private usersService: UsersService) {
  }
  selectedTab: any;
  navLinks: any = [];

  ngOnInit(): void {
    this.checkPermissions()
    if(this.navLinks.length > 0) {
      this.selectedTab = this.navLinks[0].action;
    }
  }
  checkPermissions(){
    // [false, false, false]
    const allowedNavLinks:any[] = Array.from({length: 3}, () => false)

    let defaultNavLinks: any = {
    rte:{
      label: 'RTE Metrics', action
    :
      'metrics'
    }
  ,
    suspense:{
      label: 'Suspense', action
    :
      'suspense'
    }
  ,
    history:{
      label: 'EPR History', action
    :
      'history'
    }
  };
   
      if(this.usersService.permissionCodes.includes("ELG_RTE_METRICS")){
        allowedNavLinks[0] = defaultNavLinks.rte
      }
      if(this.usersService.permissionCodes.includes("ELG_SUSPENSE_REPORT")){
        allowedNavLinks[1] = defaultNavLinks.suspense
      }
      if(this.usersService.permissionCodes.includes("ELG_EPR_AUDIT_REPORT")){
        allowedNavLinks[2] = defaultNavLinks.history

      }
    //this method maintains original order of tabs
    this.navLinks = allowedNavLinks.filter(tab=>tab !== false)
}
  display(value){
    this.selectedTab = value;
  }

}

