export const lookupFields: any = [
  {name: 'Client Name', value: 'Client Name'},
  {name: 'Carrier', value: 'Carrier'},
  {name: 'EC', value: 'EC'},
  {name: 'PLI', value: 'PLI'},
  {name: 'Backup EC', value: 'Backup EC'},
  {name: 'Eligibilty Type', value: 'Eligibilty Type'},
  {name: 'Multiple Carriers', value: 'Multiple Carriers'},
  {name: 'Are Integrated Accumulations Used', value: 'Are Integrated Accumulations Used'},
  {name: 'What is the shared ID', value: 'What is the shared ID'},
  {name: 'Adjustment Method', value: 'Adjustment Method'},
  {name: 'Medicare Medicaid Program (MMP)', value: 'Medicare Medicaid Program (MMP)'},
  {name: 'Med D', value: 'Med D'},
  {name: 'Medicaid', value: 'Medicaid'},
  {name: 'Where will client send Medicaid ID', value: 'Where will client send Medicaid ID'},
  {name: 'SubsidyRDS', value: 'SubsidyRDS'},
  {name: 'Med B', value: 'Med B'},
  {name: 'Commercial', value: 'Commercial'},
  {name: 'Population Type', value: 'Population Type'},
  {name: 'Will Coordination of Benefits be used', value: 'Will Coordination of Benefits be used'},
  {name: 'PCOB/NCOB', value: 'PCOB/NCOB'},
  {name: 'STCOB', value: 'STCOB'},
  {name: 'COBC', value: 'COBC'},
  {name: 'Is population made up of Cardholders ONLY', value: 'Is population made up of Cardholders ONLY'},
  {name: 'How are Surviving Spouses handled', value: 'How are Surviving Spouses handled'},
  {name: 'Are there stand alone deps (orphans)', value: 'Are there stand alone deps (orphans)'},
  {name: 'Mbrs/Deps in different CAG(split families)', value: 'Mbrs/Deps in different CAG(split families)'},
  {name: 'Early phone lines or web', value: 'Early phone lines or web'},
  {name: 'Does CVS Health print Cards/kits', value: 'Does CVS Health print Cards/kits'},
  {name: 'Performance Grarantee', value: 'Performance Grarantee'},
  {name: 'Is Exact Match BPG logic used', value: 'Is Exact Match BPG logic used'},
  {name: 'Full Member ID Length', value: 'Full Member ID Length'},
  {name: 'Member ID type (with PC)', value: 'Member ID type (with PC)'},
  {name: 'Member ID type (w/o PC)', value: 'Member ID type (w/o PC)'},
  {name: 'Are ID unique within Fam other than PC', value: 'Are ID unique within Fam other than PC'},
  {name: 'Is Fam ID equal to core of Mbr ID', value: 'Is Fam ID equal to core of Mbr ID'},
  {name: 'Does client use Person Codes', value: 'Does client use Person Codes'},
  {name: 'Do we auto-gen', value: 'Do we auto-gen'},
  {name: 'Autogen Program', value: 'Autogen Program'},
  {name: 'Ignore PC on file', value: 'Ignore PC on file'},
  {name: 'Append PC to Alt ID', value: 'Append PC to Alt ID'},
  {name: 'Carrier list for PC autogen', value: 'Carrier list for PC autogen'},
  {name: 'PC Assignment Method', value: 'PC Assignment Method'},
  {name: 'Is PC appended to ID', value: 'Is PC appended to ID'},
  {name: 'PC Length', value: 'PC Length'},
  {name: 'Does Client use Alternate ID', value: 'Does Client use Alternate ID'},
  {name: 'Alternate ID type (with PC)', value: 'Alternate ID type (with PC)'},
  {name: 'Alternate ID type (w/o PC)', value: 'Alternate ID type (w/o PC)'},
  {name: 'Do we autogen', value: 'Do we autogen'},
  {name: 'Autogen base length', value: 'Autogen base length'},
  {name: 'Autogen Prefix', value: 'Autogen Prefix'},
  {name: 'Override with Inbound', value: 'Override with Inbound'},
  {name: 'What ID goes on EOB', value: 'What ID goes on EOB'},
  {name: 'What ID goes on CET', value: 'What ID goes on CET'},
  {name: 'What ID goes on Reports', value: 'What ID goes on Reports'},
  {name: 'What ID goes on MAB dtl', value: 'What ID goes on MAB dtl'},
  {name: 'What ID goes on Cards', value: 'What ID goes on Cards'},
  {name: 'What ID goes on Mail Order Forms', value: 'What ID goes on Mail Order Forms'},
  {name: 'Send X-Walk for FSA', value: 'Send X-Walk for FSA'},
  {name: 'Alt ID Full Length', value: 'Alt ID Full Length'},
  {name: 'Will the 3rd ID Field be used', value: 'Will the 3rd ID Field be used'},
  {name: 'Does this ID need to be in QL', value: 'Does this ID need to be in QL'},
  {name: 'SSOE Migration', value: 'Consolidated Profile Logic'},
  {name: 'Vendor', value: 'Vendor'},
  {name: 'Is there more then one file feed for client', value: 'Is there more then one file feed for client'},
  {name: 'What file format will be sent', value: 'What file format will be sent'},
  {name: 'Reason for non-standard format', value: 'Reason for non-standard format'},
  {name: 'Will B2B be used', value: 'Will B2B be used'},
  {name: 'Will Pre-Preload be used', value: 'Will Pre-Preload be used'},
  {name: 'Pre-Preload Program', value: 'Pre-Preload Program'},
  {name: 'Mapping to format', value: 'Mapping to format'},
  {name: 'How will client file be transmitted', value: 'How will client file be transmitted'},
  {name: 'FTP ID', value: 'FTP ID'},
  {name: 'File Routing', value: 'File Routing'},
  {name: 'File Type', value: 'File Type'},
  {name: 'History Method', value: 'History Method'},
  {name: 'Will Type 4 be sent by client', value: 'Will Type 4 be sent by client'},
  {name: 'Will Type 5 (privacy/email) be sent by client', value: 'Will Type 5 (privacy/email) be sent by client'},
  {name: 'Type E-Email', value: 'Type E-Email'},
  {name: 'Type M-Member Address', value: 'Type M-Member Address'},
  {name: 'Type P-Privacy', value: 'Type P-Privacy'},
  {name: 'Type 5 Validation', value: 'type5Validation'},
  {name: 'Type 5 Retention Days', value: 'type5RetentionDays'},
  {name: 'Privacy Moves Level', value: 'Privacy Moves Level'},
  {name: 'Is Batch Override Protection Used', value: 'Is Batch Override Protection Used'},
  {name: 'How are load reports to be delivered', value: 'How are load reports to be delivered'},
  {name: 'Report NDM/FTP Profile', value: 'Report NDM/FTP Profile'},
  {name: 'File Frequency', value: 'File Frequency'},
  {name: 'Exclude Holidays from Schedule', value: 'Exclude Holidays from Schedule'},
  {name: 'Is there a variance', value: 'Is there a variance'},
  {name: 'Who is the Vendor', value: 'Who is the Vendor'},
  {name: 'Will client send term dates for all mbrs', value: 'Will client send term dates for all mbrs'},
  {name: 'Is the term date a to date', value: 'Is the term date a to date'},
  {name: 'Will dependent terms be sent on file', value: 'Will dependent terms be sent on file'},
  {name: 'Will dependent implied terms be used', value: 'Will dependent implied terms be used'},
  {name: 'Dependent Implied Terms Method', value: 'Dependent Implied Terms Method'},
  {name: 'Will Implied terms be used', value: 'Will Implied terms be used'},
  {name: 'At what level', value: 'At what level'},
  {name: 'Move History', value: 'Move History'},
  {name: 'Move Accums', value: 'Move Accums'},
  {name: 'Move Prior Auths', value: 'Move Prior Auths'},
  {name: 'Include Termed Records', value: 'Include Termed Records'},
  {name: 'Move claims prior to effective date', value: 'Move claims prior to effective date'},
  {name: 'Will Term by Absence be used', value: 'Will Term by Absence be used'},
  {name: 'Ignore Future Terms (< open ended)', value: 'Ignore Future Terms (< open ended)'},
  {name: 'Include Future Effectives', value: 'Include Future Effectives'},
  {name: 'TBA Date', value: 'TBA Date'},
  {name: 'Range or List used', value: 'Range or List used'},
  {name: 'Account List', value: 'Account List'},
  {name: 'Group List', value: 'Group List'},
  {name: 'Is manual entry TBA protection used', value: 'Is manual entry TBA protection used'},
  {name: 'Compare Process', value: 'Compare Process'},
  {name: 'ROBOT Name', value: 'ROBOT Name'},
  {name: 'Email/NDM Script', value: 'Email/NDM Script'},
  {name: 'Compare Frequency', value: 'Compare Frequency'},
  {name: 'Term by Absence Threshold (count)', value: 'Term by Absence Threshold (count)'},
  {name: 'Term by Absence Threshold (percentage)', value: 'Term by Absence Threshold (percentage)'},
  {name: 'Error Threshold (percentage)', value: 'Error Threshold (percentage)'},
  {name: 'Term Threshold (Incl Moves)', value: 'Term Threshold (Incl Moves)'},
  {name: 'Term Threshold Incl Moves Count', value: 'termThresholdInclMovesActCount'},
  {name: 'Term Threshold Excl Moves Count', value: 'termThresholdExclMovesActCount'},
  {name: 'Term Threshold All Act Count', value: 'termThresholdAllActCount'},
  {name: 'Term Threshold (Excl Moves)', value: 'Term Threshold (Excl Moves)'},
  {name: 'Term Threshold (Against All Actives)', value: 'Term Threshold (Against All Actives)'},
  {name: 'Include Future Terms (< open ended)', value: 'Include Future Terms (< open ended)'},
  {name: 'Inactivate Count Threshold', value: 'Inactivate Count Threshold'},
  {name: 'Inactivate Pct Threshold', value: 'Inactivate Pct Threshold'},
  {name: 'MLX Chg Count Threshold', value: 'MLX Chg Count Threshold'},
  {name: 'MLX Chg Pct Threshold', value: 'MLX Chg Pct Threshold'},
  {name: 'Term Move Count Threshold', value: 'Term Move Count Threshold'},
  {name: 'Term Move Pct Threshold', value: 'Term Move Pct Threshold'},
  {name: 'Family ID Threshold', value: 'Family ID Threshold'},
  {name: 'Demographic Match Level', value: 'Demographic Match Level'},
  {name: 'New Population', value: 'New Population'},
  {name: 'Change in Member ID', value: 'Change in Member ID'},
  {name: 'Last Name Change', value: 'Last Name Change'},
  {name: 'First Name Change', value: 'First Name Change'},
  {name: 'DOB Change', value: 'DOB Change'},
  {name: 'Relationship Change', value: 'Relationship Change'},
  {name: 'ID Card Threshold', value: 'ID Card Threshold'},
  {name: 'Do we print cards for entire population', value: 'Do we print cards for entire population'},
  {name: 'What ID goes on the card (regardless of who prints)', value: 'What ID goes on the card (regardless of who prints)'},
  {name: 'What are we printing?', value: 'What are we printing?'},
  {name: 'SSN Validation Used?', value: 'SSN Validation Used?'},
  {name: 'ID Card Report Output Que', value: 'ID Card Report Output Que'},
  {name: 'Who do they want printed on the card?', value: 'Who do they want printed on the card?'},
  {name: 'Regardless of CAG?', value: 'Regardless of CAG?'},
  {name: 'Do we generate cards for moved members?', value: 'Do we generate cards for moved members?'},
  {name: 'Suppression Level?', value: 'Suppression Level?'},
  {name: 'What should trigger a card?', value: 'What should trigger a card?'},
  {name: 'Multi-Birth Indicator', value: 'Multi-Birth Indicator'},
  {name: 'Is Auto-CAG used?', value: 'Is Auto-CAG used?'},
  {name: 'Sort Input File?', value: 'Sort Input File'},
  {name: 'Will there be Group Files?', value: 'Will there be Group Files?'},
  {name: 'Will Group Suspense be used?', value: 'Will Group Suspense be used?'},
  {name: 'Retention Days?', value: 'Retention Days?'},
  {name: 'Prescriber/Physician Files?', value: 'Prescriber/Physician Files?'},
  {name: 'Diagnosis Files?', value: 'Diagnosis Files?'},
  {name: 'Lab Files?', value: 'Lab Files?'},
  {name: 'Bypass File Dependency?', value: 'Bypass File Dependency?'},
  {name: 'Will Member Suspense be used?', value: 'Will Member Suspense be used?'},
  {name: 'Retention Days?', value: 'Retention Days?'},
  {name: 'Is Client Product Code Used?', value: 'Is Client Product Code Used?'},
  {name: 'Is Client Rider Code Used?', value: 'Is Client Rider Code Used?'},
  {name: 'Is Care Network Used?', value: 'Is Care Network Used?'},
  {name: 'Is Care Facility Used?', value: 'Is Care Facility Used?'},
  {name: 'Do we create on the fly?', value: 'Do we create on the fly?'},
  {name: 'Is Care Qualifier Used?', value: 'Is Care Qualifier Used?'},
  {name: 'Do we create on the fly?', value: 'Do we create on the fly?'},
  {name: 'Will Physician ID (PCP) be used?', value: 'Will Physician ID (PCP) be used?'},
  {name: 'Do we create on the fly?', value: 'Do we create on the fly?'},
  {name: 'Will Member Level Plans be used?', value: 'Will Member Level Plans be used?'},
  {name: 'Will Client Defined Data (CDD) be used?', value: 'Will Client Defined Data (CDD) be used?'},
  {name: 'Is this a Healthcare Exchange Client?', value: 'Is this a Healthcare Exchange Client?'},
  {name: 'How will Grace Period be managed?', value: 'How will Grace Period be managed?'},
  {name: 'When is New Benefit Plan Year?', value: 'When is New Benefit Plan Year?'},
  {name: 'Will client be sending an OE file?', value: 'Will client be sending an OE file?'},
  {name: 'When is Open Enrollment Window?', value: 'When is Open Enrollment Window?'},
  {name: 'What data will be on OE File?', value: 'What data will be on OE File?'},
  {name: 'When will this file be sent?', value: 'When will this file be sent?'},
  {name: 'Will ID cards need to be reissued?', value: 'Will ID cards need to be reissued?'},
  {name: 'Will explicit terms be sent for current year?', value: 'Will explicit terms be sent for current year?'},
  {name: 'Does CVSH have to term dropped Mbrs?', value: 'Does CVSH have to term dropped Mbrs?'},
  {name: 'What data will come after OE file?', value: 'What data will come after OE file?'},
  {name: 'When will this file be sent?', value: 'When will this file be sent?'},
  {name: 'What will be on 1st file after new bnft yr?', value: 'What will be on 1st file after new bnft yr?'},
  {name: 'When will this file be sent?', value: 'When will this file be sent?'},
  {name: 'Does CVSH have to term dropped Mbrs?', value: 'Does CVSH have to term dropped Mbrs?'},
  {name: 'Will there be CAG structure changes?', value: 'Will there be CAG structure changes?'},
  {name: 'Will a test file be required if no changes?', value: 'Will a test file be required if no changes?'},

];
