<div class="modal-backdrop">
  <div class="modal">
    <div class="modal-header">
      <h5 class="modal-title">Select Message Code</h5>
      <button type="button" class="close-btn" aria-label="Close" (click)=closeModal($event)>X</button>
    </div>
    <div class="modal-body">
      <div class="search-box">
<!--          <input type="text" placeholder="Search Codes" [(ngModel)]="searchTerm"  class="search-input" (input)="searchCodes()">-->
<!--          <mat-icon style="margin-left: -35px;margin-top: 7px;"svgIcon="search&#45;&#45;s"></mat-icon>-->
        <mat-form-field style="margin-top: -25px; margin-bottom: -20px;" appearance="standard">
          <input matInput placeholder="Search" id = "search" (keyup)="filterData($event)" autocomplete="off">
          <mat-icon svgIcon="search--s"></mat-icon>
        </mat-form-field>
      </div>

        <table class="table">
          <thead>
          <tr>
            <th></th>
            <th>Code</th>
            <th>Type</th>
            <th>Message</th>
          </tr>
          </thead>
          <tbody style="height: 250px; overflow: auto">
            <tr *ngFor="let message of filteredMessages">
            <td style="width:20px;">
              <input type="radio" name="messageCode" [(ngModel)]="selectedMessage" [value]="message">
            </td>
            <td> {{message.message}}</td>
            <td> {{message.reportType}}</td>
            <td>{{message.messageDescription}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class= "btn-cancel"(click)="onCancel($event)">Cancel</button>
        <button type="button"  class="cvs-btn-primary" (click)="onSelect($event)">Select Code</button>
      </div>
    </div>
</div>
