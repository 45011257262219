import { MessageSupressionModel } from './../models/MsgSupressList';
import {Action} from '@ngrx/store';


export const LOAD_MSG_DATA_SET = '[Msg Supress] Load Msg Supress Data Set';
export const LOAD_MSG_DATA_SET_SUCCESS = '[Msg Supress] Msg Supress Data Set Loaded Success';

export const SAVE_MSG_DATA_SET = '[Msg Supress] Save Msg Supress Data Set';
export const SAVE_MSG_DATA_SET_SUCCESS = '[Msg Supress] Msg Supress Data Set Saved Success';

export const DELETE_MSG_DATA_SET = '[Msg Supress] Delete Msg ';
export const DELETE_MSG_DATA_SET_SUCCESS = '[Msg Supress] Delete Msg Success';



export class LoadMsgSupressSetAction implements Action {
  readonly type = LOAD_MSG_DATA_SET;

  constructor(public payload: any ) { }
}

export class LoadMsgSupressSuccessAction implements Action {
  readonly type = LOAD_MSG_DATA_SET_SUCCESS;

  constructor(public payload: any) { }
}

export class SaveMsgSupressAction implements Action {
  readonly type = SAVE_MSG_DATA_SET;
}

export class SaveMsgSupressSuccessAction implements Action {
  readonly type = SAVE_MSG_DATA_SET_SUCCESS;

  constructor(public payload: any) { }
}

export class DeleteMsgSupressAction implements Action {
  readonly type = DELETE_MSG_DATA_SET;

  constructor(public payload: any) {
  }
}

export class DeleteEprSuccessAction implements Action {
  readonly type = DELETE_MSG_DATA_SET_SUCCESS;
}

export type Actions
  =  LoadMsgSupressSetAction |  LoadMsgSupressSuccessAction |
SaveMsgSupressAction| SaveMsgSupressSuccessAction;
