import {Action} from '@ngrx/store';
import {ParamMap} from '../models/ParamMapping';

export const LOAD_ALL_PARAM_MAP = '[PARAMMAP] Load All PARAMMAP';
export const LOAD_ALL_PARAM_MAP_SUCCESS = '[PARAMMAP] PARAMMAP ALL Loaded Success';
export const LOAD_ALL_PARAM_MAP_FAILURE = '[PARAMMAP] PARAMMAP ALL Loaded Failure';

export const LOAD_PARAM_MAP = '[PARAMMAP] Load PARAMMAP';
export const LOAD_PARAM_MAP_SUCCESS = '[PARAMMAP] PARAMMAP Loaded Success';
export const LOAD_PARAM_MAP_FAILURE = '[PARAM_MAP] PARAMMAP Loaded Failure';

export const CLEAR_PARAM_MAP_DATA = '[Filetrakcer]  Clearing all Filetracker Data';
export const SAVE_PARAM_MAP = '[Filetracker] Save Filetracker';
export const UPDATE_PARAM_MAP = '[Filetracker] Update Filetracker';
export const SAVE_PARAM_MAP_SUCCESS = '[Filetracker] Save Filetracker Success';
export const SAVE_PARAM_MAP_FAILURE = '[Filetracker] Filetracker Saved Failure';
export const EDIT_PARAM_MAP = '[Filetracker] Edit Filetracker';
export const EDIT_PARAM_MAP_SUCCESS = '[Filetracker] Edit Filetracker Success';
export const EDIT_PARAM_MAP_SUCCESS_FAILURE = '[Filetracker] Filetracker Edit Failure';
export const DELETE_PARAM_MAP = '[Filetracker] Deleting Filetracker...';
export const DELETE_PARAM_MAP_SUCCESS = '[Filetracker] Filetracker deleted successfully';
export const FILTER_PARAM_MAP_BY_ID = '[Filetracker] Filter Filetrackers By Id';
export const LOAD_DATA = 'LoadData';
export const EDIT_MODE = 'Edit';

export class LoadAllParamMapAction implements Action {
  readonly type = LOAD_ALL_PARAM_MAP;
}

export class LoadAllParamMapSuccessAction implements Action {
  readonly type = LOAD_ALL_PARAM_MAP_SUCCESS;
  constructor(public payload: ParamMap[]) { }
}

export class LoadAllParamMapFailureAction implements Action {
  readonly type = LOAD_ALL_PARAM_MAP_FAILURE;
}
export class LoadParamMapAction implements Action {
  readonly type = LOAD_PARAM_MAP;

}
export class ClearParamMapAction implements Action {
  readonly type = CLEAR_PARAM_MAP_DATA;
}

export class LoadParamMapSuccessAction implements Action {
  readonly type = LOAD_PARAM_MAP_SUCCESS;

  constructor(public payload: ParamMap) { }
}

export class LoadParamMapFailureAction implements Action {
  readonly type = LOAD_PARAM_MAP_FAILURE;
}

export class SaveParamMapAction implements Action {
  readonly type = SAVE_PARAM_MAP;

  constructor(public payload: ParamMap) { }
}
export class UpdateParamMapAction implements Action {
  readonly type = UPDATE_PARAM_MAP;

  constructor(public payload: ParamMap) { }
}

export class SaveParamMapSuccessAction implements Action {
  readonly type = SAVE_PARAM_MAP_SUCCESS;

  constructor(public payload: ParamMap) { }
}

export class SaveParamMapFailureAction implements Action {
  readonly type = SAVE_PARAM_MAP_FAILURE;
}


export class EditParamMapAction implements Action {
  readonly type = EDIT_PARAM_MAP;

  constructor(public payload: any) { }
}

export class EditParamMapSuccessAction implements Action {
  readonly type = EDIT_PARAM_MAP_SUCCESS;

  constructor(public payload: ParamMap) { }
}

export class EditParamMapFailureAction implements Action {
  readonly type = EDIT_PARAM_MAP_SUCCESS_FAILURE;
}
export class DeleteParamMapAction implements Action {
  readonly type = DELETE_PARAM_MAP;

  constructor(public payload: number) {
  }
}
export class DeleteParamMapSuccessAction implements Action {
  readonly type = DELETE_PARAM_MAP_SUCCESS;
}
export class FilterParamMapById implements Action {
  readonly type = FILTER_PARAM_MAP_BY_ID;

  constructor(public payload: any) {}
}

export class LoadDataAction implements Action {
  readonly type = LOAD_DATA;

  constructor(public payload: any) {
  }
}
export class EditAction implements Action {
  readonly type = EDIT_MODE;

  constructor(public payload: boolean) {
  }
}


export type Actions
  = LoadParamMapAction
  | LoadParamMapSuccessAction
  | LoadParamMapFailureAction
  | LoadAllParamMapAction
  | LoadAllParamMapSuccessAction
  | LoadAllParamMapFailureAction
  | SaveParamMapAction
  | UpdateParamMapAction
  | SaveParamMapSuccessAction
  | SaveParamMapFailureAction
  | EditParamMapAction
  | EditParamMapSuccessAction
  | EditParamMapFailureAction
  | DeleteParamMapAction
  | DeleteParamMapSuccessAction
  | FilterParamMapById
  | ClearParamMapAction
  | LoadDataAction
  | EditAction;

