import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import { MatIcon } from '@angular/material/icon';


@Component({
  selector: 'app-download-btn-cell-renderer',
  template: `
  <button mat-icon-button *ngIf= "signOffStatus && havePermission" (click)="btnClickedHandler('view')">
  <span id='view'>View</span>
  </button>

              `,
              styleUrls:['../dashboard/dashboard.component.scss']
  })
export class EligibilityBtnCellRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public currentPage: string ="";
  public signOffStatus:boolean
  public havePermission:boolean
  constructor() {}
  agInit(params: any): void {

    this.params = params;
    this.signOffStatus = params.data.eligAgreementSignOffStatus === 'Signed Off'? true:false

    this.havePermission =  params.context.permissions.includes('ELG_ELIGIBILITY_AGREEMENT_ONLY')

  }

  checkDisabled(){
    return true;
  }
  btnClickedHandler(button: any) {

      this.params.context.componentParent.onCellClicked( this.params, "EPR's", false)


  }
  refresh(params: any): boolean {
    return false;
  }
}
