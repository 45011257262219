<cvs-alert-placeholder id="epr-alert"></cvs-alert-placeholder>
<cvs-alert-placeholder id="apiloading-alert"></cvs-alert-placeholder>


<app-header (saveTestResults)="saveTestResults()" id="head"></app-header>

<!-- <div class="epr-form-layout-container"> -->
  <!-- <div class="column-1">
    <app-project-tracker></app-project-tracker>
    <app-checklist></app-checklist>
  </div> -->
<div>
    <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)" id="epr-forms" class="column-2" >
      
        <mat-tab *ngIf="showAllTabsAtEpr('Questionnaire')" label="Questionnaire" >
          <app-questionnaire [isEprForm]="isEprForm"></app-questionnaire>
        </mat-tab>
     

      <mat-tab label="Projects"  >
        <app-project-tracker *ngIf="selectedTab === 'Projects'" [eprId]="eprId"></app-project-tracker> 
      </mat-tab>

      <mat-tab  *ngIf="showAllTabsAtEpr('Test Results')" label="Test Results"  >
        <app-testing #testResultsRef *ngIf="selectedTab === 'Test Results'"></app-testing>
      </mat-tab>

      <mat-tab *ngIf="showAllTabsAtEpr('Eligibility Agreement')" label="Eligibility Agreement"  >
        <app-eligibility-agreement *ngIf="selectedTab === 'Eligibility Agreement'" [readOnly]="readOnly$ | async"></app-eligibility-agreement>
      </mat-tab>

      <mat-tab  *ngIf="showAllTabsAtEpr('File Tracker')" label="File Tracker"  >
        <app-eprfiletracker *ngIf="selectedTab === 'File Tracker'" [readOnly]="readOnly$ | async" ></app-eprfiletracker>
      </mat-tab>

      <mat-tab  *ngIf="showAllTabsAtEpr('Processing Files')" label="Processing Files"  >
        <app-myprocessingfiles *ngIf="selectedTab === 'Processing Files'"></app-myprocessingfiles>
      </mat-tab>
      <mat-tab *ngIf="showMappingTab" label="Mapping"  >
        <app-mapping-documents *ngIf="selectedTab === 'Mapping'"></app-mapping-documents>
      </mat-tab>
      <mat-tab  *ngIf="showHistoryTab" label="History"  >
        <app-history *ngIf="selectedTab === 'History'" ></app-history>
      </mat-tab>
    </mat-tab-group>



</div>

<button id="jump-to-top-btn" (click)="scrollToTop()">
  <mat-icon svgIcon="angle-up--s"></mat-icon>
</button>
