import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MappingRecTypeService } from '../../../services/mapping-rec-type.service';
import { MappingFormService } from '../mapping-form.service';
import { combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LogicCodeModalComponent } from './logic-code-modal/logic-code-modal.component';
import { catchError, switchMap, tap } from 'rxjs/operators';
import {
  LogicCodeSelectionModalComponent
} from './logic-code-selection-modal/logic-code-selection-modal.component';
import jsPDF from 'jspdf';

import autoTable from 'jspdf-autotable';
import { InformationDialogComponent } from '../common-components/information-dialog/information-dialog.component';
import { MappingApiService } from 'src/app/services/mapping-api.service';
import { EprService } from 'src/app/services/epr.service';

@Component({
  selector: 'app-mapping-record',
  templateUrl: './mapping-record.component.html',
  styleUrls: ['./mapping-record.component.scss']
})
export class MappingRecordComponent implements OnInit, OnDestroy {
  @Output() updateEmptyRecordTypeLogicCodeCount = new EventEmitter<any>();
  recordTypeData = [];
  allTabs = [];
  recTypeToSave: any = { recTypes: [] };
  currentTabIndex: any;
  dataSource;
  selectedMapType;
  editMode = false;
  validationEnable = false;
  isNewDocFoRectype = false;
  isNewDocForModal = false;
  rectypeDataCache: any = {};

  displayedColumns;

  @Input() mappingDocument;
  @Input() isNewDoc;

  unSavedChanges = false;
  private emptyLogicCodeArr: any = [];
  private eleRef: any;
  private subsArr: any = [];
  subsListner: Subscription[] = [];
  is834Format = false;
  mappingguide: any = [];
  clientdetails: any = [];
  pliAndEcNames: any;
  pdfColumns: string[];
  isRX51Format: boolean = false;

  constructor(public api: MappingApiService, private eprService: EprService,
    private recTypesService: MappingRecTypeService, private formServ: MappingFormService,
    element: ElementRef,
    public dialog: MatDialog) {
    this.eleRef = element;
  }

  ngOnInit() {
    this.mappingDocSelectedListener();
    this.newMappingListener();
    this.mappingGuideValueChangeListener();
    this.editableModeListener();
    this.mapTypeValueChangeListener();
    this.subsListner.push(
      this.addImportDocument(),
      this.addValidationListner(),
      this.addIs834FormatListner()
    );
    if (this.mappingDocument) {
      if (this.isNewDoc) {
        this.setRecTypeData({ recTypes: null });
        this.isNewDocForModal = true;
        this.editMode = true;
      } else {
        this.mappingDocSelected();
      }
    }
    this.getmappingGuideandEC();
  }
  addIs834FormatListner() {
    return this.formServ.is834Format$.subscribe(resp => {
      if (resp) {
        this.is834Format = true;
        // this.displayedColumns = ['fieldNumber', 'fieldName', 'position', 'length', 'use',
        //   'type', 'mappingInstructions', 'logicCode', 'comments'];
        // this.pdfColumns = ['Record Type', 'Field Number', 'Field Name', 'Position', 'Length', 'Use',
        //   'Type', 'Mapping Instructions', 'Logic Code', 'Comments'];
      } else {
        this.is834Format = false;
        // this.displayedColumns = ['fieldNumber', 'fieldName', 'position', 'length', 'use',
        //   'type', 'fieldInfo', 'logicCode', 'comments'];
        // this.pdfColumns = ['Record Type', 'Field Number', 'Field Name', 'Position', 'Length', 'Use',
        //   'Type', 'Field Info', 'Logic Code', 'Comments'];
      }
    });
  }
  addValidationListner() {
    return this.formServ.showValidations$.subscribe(resp => {
      if (!resp) {
        this.validationEnable = false;
      }
    });
  }
  addImportDocument() {
    return this.formServ.importDocument$.subscribe(resp => {
      if (resp) {

        // {param.clientCode}&pliCode=${param.pliCode}&documentId=${param.documentID}
        // this.fetchMappingGuide(resp,{case:'import'});
        this.mappingDocSelected({ case: 'import', import: resp });
      }
    });
  }

  mappingDocSelectedListener() {
    this.subsListner.push(
      this.formServ.mappingDocSelected$.subscribe(res => {
        this.mappingDocument = res;
        this.mappingDocSelected();
      })
    );
  }

  mappingDocSelected(variation?) {
    this.editMode = false;
    this.subsArr.forEach(sub => sub && sub.unsubscribe());
    this.dataSource = null;
    this.recordTypeData = [];
    this.recTypeToSave.recTypes = [];
    this.rectypeDataCache = {};
    this.isNewDocFoRectype = false;
    let documentID = this.mappingDocument.documentID;
    let clientCode = this.mappingDocument.clientCode;
    let pliCode = this.mappingDocument.pliCode;
    this.selectedMapType = { mapType: this.mappingDocument.mappingType };
    if (variation && variation.case === 'import') {
      documentID = variation.import.documentID;
      clientCode = variation.import.clientCode;
      pliCode = variation.import.pliCode;
    }
    this.subsListner.push(
      this.recTypesService.getExistingRecTypes(documentID

      )
        .subscribe(allRecords => {
          const rectypes = allRecords.flatMap(itm => itm).map(itm => itm.displayName);
          const uniqueRectypes = [...new Set(rectypes)].map(recs => ({ displayName: recs }));
          uniqueRectypes.forEach((rectype: any, index) => {
            this.rectypeDataCache[rectype.displayName] = allRecords[index];
          });
          this.mapSetRecTypeData(allRecords, { recTypes: uniqueRectypes });
          // its used when value change gets triggered to compare existing value with new ones.
          this.recTypeToSave = { recTypes: uniqueRectypes };
        },
          error => {
            this.dataSource = null;
            this.setNewTabs(null);
          })
    );
    this.getmappingGuideandEC();
  }

  newMappingListener() {
    this.subsListner.push(
      this.formServ.newMapping$.subscribe(res => {
        this.mappingDocument = res;
        this.isNewDoc = true;
        this.rectypeDataCache = {};
        this.recordTypeData = [];
        this.recTypeToSave.recTypes = [];
        this.isNewDocFoRectype = true;
        this.isNewDocForModal = true;
        this.dataSource = null;
        this.subsArr.forEach(sub => sub && sub.unsubscribe());
        this.setNewTabs(null);
      }));
  }

  mappingGuideValueChangeListener() {
    this.subsListner.push(
      this.formServ.mappinGuideValueChange$.pipe(switchMap(selectedRecType => {

        if (this.isNewDoc || this.isNewDocFoRectype || this.editMode) {
          const existingRecType = this.recTypeToSave.recTypes || [];
          const recievedRecType = selectedRecType.recTypes || [];
          const removableRecTypes = [];
          const addedRecTypes = [];
          if (recievedRecType.length === 0) { // if all are removed
            removableRecTypes.push(...existingRecType);
          } else {

            recievedRecType.forEach(recievedData => {
              if (recievedData && recievedData.displayName) {
                if (existingRecType.length > 0 &&
                  !existingRecType.find(existingData => existingData.displayName === recievedData.displayName)) {
                  addedRecTypes.push(recievedData);
                }
              }
            });

          }
          if (existingRecType.length === 0) {
            addedRecTypes.push(...recievedRecType);
          } else {

            existingRecType.forEach(existingData => {
              if (recievedRecType.length > 0 &&
                !recievedRecType.find(recievedData => recievedData.displayName === existingData.displayName)) {
                removableRecTypes.push(existingData);
              }
            });

          }
          if (removableRecTypes.length) {
            // this will remove data from cache, tabs name and tab data.
            removableRecTypes.forEach(deleteRecord => {
              delete this.rectypeDataCache[deleteRecord.displayName];
              this.recTypeToSave.recTypes.splice(this.recTypeToSave.recTypes
                .findIndex(data => data.displayName === deleteRecord.displayName), 1);

            });
            if (recievedRecType.length) {
              this.dataSource = this.rectypeDataCache[recievedRecType[0].displayName];

            } else {
              this.dataSource = null; // when none record are selected. (in case of unselect)
            }


          }
          if (addedRecTypes.length) {
            this.setRecTypeData(selectedRecType, { addedRecTypes });
          } else {
            this.updateEmptyArray();
          }

          return of(selectedRecType);
        }
      })).subscribe(selectedRecord => this.setNewTabs(selectedRecord))
    );
  }

  mapTypeValueChangeListener() {
    this.subsListner.push(
      this.formServ.mapTypeValueChange$.subscribe(mapTye => {
        this.selectedMapType = mapTye;
      })
    );
  }

  editableModeListener() {
    this.subsListner.push(
      this.formServ.editableMode$.subscribe(res => {
        console.log(res)
        this.editMode = res;
        if (!res) {
          this.validationEnable = false;
        }
      })
    );
  }

  onTabChange(event) {
    if (this.rectypeDataCache && event.index >= 0) {
      const recId = event.tab.textLabel;
      this.currentTabIndex = event.index;
      this.dataSource = this.rectypeDataCache[recId];
    }
  }

  setNewTabs(selectedRecType) {
    if (selectedRecType !== null) {
      this.allTabs = [];
      const selectedRecTypesTabs = [];
      selectedRecTypesTabs.push(selectedRecType.recTypes);
      this.allTabs = selectedRecTypesTabs[0];
      this.currentTabIndex = 0;
      this.recTypeToSave = selectedRecType;
    } else {
      this.allTabs = [];
      this.currentTabIndex = null;
      this.recTypeToSave.recTYpes = [];
      this.updateEmptyRecordTypeLogicCodeCount.emit(0);
    }
  }

  setRecTypeData(selectedRecType, operationalCase?) {
    // this.recTypeToSave = selectedRecType;
    // this.recordTypeData = [];
    if (selectedRecType.recTypes && selectedRecType.recTypes.length > 0) {
      if (operationalCase.addedRecTypes) {
        const latestAddition = operationalCase.addedRecTypes;
        const mapSetRecSubscription = forkJoin(latestAddition.map(recType => this.recTypesService.getRecType(recType.displayName))).pipe(
          switchMap((allRecords: any) => {
            allRecords = allRecords.map((records: Array<any>, index) => {
              const selectedData = latestAddition[index];
              return records.map(rec => {
                return {
                  ...rec,
                  recType: selectedData.recordType,
                  recName: selectedData.recordName,
                  displayName: selectedData.displayName
                };
              });
            });
            latestAddition.forEach((rectype: any, index) => {
              this.rectypeDataCache[rectype.displayName] = allRecords[index];
            });

            this.mapSetRecTypeData(allRecords, selectedRecType, true);
            return of(allRecords);

          })).subscribe(() => {
            // this.isNewDoc = false;
          });
        this.subsArr.push(mapSetRecSubscription);
      }
    } else {
      this.setNewTabs(null);
    }
  }

  mapSetRecTypeData(allRecords, selectedRecType, isNewRecTypeSelected = false) {
    if (selectedRecType?.recTypes.length === 0) {
      this.dataSource = null;
      this.setNewTabs(null);
      this.recTypeToSave.recType = [];
    } else {
      this.recTypeToSave = selectedRecType;
      this.dataSource = this.rectypeDataCache[selectedRecType.recTypes[0].displayName];
      this.setNewTabs(selectedRecType);
    }
    this.updateEmptyArray();
    setTimeout(() => {
      const elem: any = this.eleRef.nativeElement.querySelectorAll('.mat-tab-labels .mat-tab-label');
      if (elem.length > 0) {
        elem[0].click();
      }
    }, 100);
  }
  updateEmptyArray() {
    this.emptyLogicCodeArr = [];
    const errorRecord = [];
    const recKeys = Object.keys(this.rectypeDataCache);
    if (recKeys.length) {
      recKeys.forEach((recName: any) => {
        const filtered = this.rectypeDataCache[recName].filter(subItm =>
          subItm.fieldUse === 'R' && subItm.sendToSI === 'Yes' &&
          !subItm.logicCode);
        errorRecord.push(...filtered);
      });
      if (errorRecord.length > 0) {
        this.updateErrorCount(errorRecord.length);
        this.emptyLogicCodeArr = [...errorRecord];
      } else {
        this.updateErrorCount(0);
        this.emptyLogicCodeArr = [];
      }

    } else {
      this.updateErrorCount(0);
    }
  }
  isValidRecord(item) {
    if (item.fieldUse === 'R' && item.sendToSI === 'Yes') {
      return true;
    } else {
      return false;
    }
  }
  updateErrorCount(count: number) {
    if (this.is834Format) {
      this.updateEmptyRecordTypeLogicCodeCount.emit(count);
    }
  }

  saveRecData(mappingGuideValues): Observable<any> {
    const populatedRecTypes = [];
    this.recTypeToSave.recTypes.forEach(recInfo => {

      this.rectypeDataCache[recInfo.displayName].map(recTypeArr => {
        const populatedRecType = {
          ...recTypeArr,
          documentId: this.mappingDocument.documentID,
          pliCode: this.mappingDocument.pliCode,
          clientCode: this.mappingDocument.clientCode,
          mappingStatus: mappingGuideValues.mappingStatus,
          mappingFormat: mappingGuideValues.mappingFormat,
          mapType: this.selectedMapType.mapType,
          logicCodeStatus: recTypeArr.logicCodeStatus || null,
          logicCode: recTypeArr.logicCode || null
        };
        populatedRecTypes.push(populatedRecType);
        return populatedRecType;
        // return acc.concat(populatedRecType);
      });
      // populatedRecTypes.push(...this.rectypeDataCache[recInfo.displayName]);
    });
    return this.recTypesService.saveRecTypes(this.mappingDocument.documentID, this.mappingDocument.pliCode,
      this.mappingDocument.clientCode, populatedRecTypes);
  }

  openLogicCodeModal(logicCode, rowData = null, index = null) {
    if (!this.editMode && !this.isNewDocForModal && logicCode) {
      this.recTypesService.searchLogicCode(logicCode).subscribe(logicCodeDetails => {
        const logicCodedialogRef = this.dialog.open(LogicCodeModalComponent, {
          width: '787px',
          height: '811px',
          data: {
            logicCode,
            editMode: this.editMode,
            mappingInstructions: logicCodeDetails.mappingInstructions,
            mappingDesc: logicCodeDetails.mappingDescription,
            selectedMapType: this.selectedMapType
          },
        });
      });
    }

    if (this.is834Format && (this.editMode || this.isNewDocForModal)) {
      const logicCodeSelectionDialogRef = this.dialog.open(LogicCodeSelectionModalComponent, {
        autoFocus: false,
        restoreFocus: false,
        data: {
          selectedLogicCode: logicCode,
          rowIndex: index,
          rowData,
          mappingDocument: this.mappingDocument,
          selectedMapType: this.selectedMapType
        }
      });
      logicCodeSelectionDialogRef.componentInstance.selectLogicCodeEvent.subscribe((data) => {
        this.unSavedChanges = true;
        const currentRecInfo = this.recTypeToSave.recTypes[this.currentTabIndex].displayName;
        this.rectypeDataCache[currentRecInfo][data.rowIndex].logicCode = data.logicCode.logicCode;
        this.rectypeDataCache[currentRecInfo][data.rowIndex].logicCodeStatus = data.logicCode.logicCodeStatus;
        this.rectypeDataCache[currentRecInfo][data.rowIndex].mappingInstructions = data.logicCode.mappingInstructions;
        // this.dataSource = this.rectypeDataCache[currentRecInfo];
        this.updateEmptyArray();
      });
    }
  }

  addEmptyValuesToArr(elem: any) {
    const filtered = this.emptyLogicCodeArr.filter(itm => JSON.stringify(itm) === JSON.stringify(elem));
    if (!(filtered.length > 0)) {
      this.emptyLogicCodeArr.push(elem);
      this.updateErrorCount(this.emptyLogicCodeArr.length);
    }
  }

  removeEmptyValuesFromArr(elem: any) {
    this.emptyLogicCodeArr.some(item => {
      if (JSON.stringify(item) === JSON.stringify(elem)) {
        this.emptyLogicCodeArr = this.emptyLogicCodeArr.filter(itm => JSON.stringify(itm) !== JSON.stringify(elem));
        this.updateErrorCount(this.emptyLogicCodeArr.length);
      }
    });
  }

  getmappingGuideandEC() {
    this.api.getMappingGuide(this.mappingDocument).subscribe(resp => {
      this.mappingguide = resp;
      let isRX51Format = this.mappingguide.response?.mappingFormat
      if (this.mappingguide && isRX51Format === 'RX 51') {
        this.isRX51Format = true;
        this.displayedColumns = ['fieldNumber', 'fieldName', 'position', 'length', 'use',
          'type', 'fieldInfo', 'logicCode', 'comments'];
        this.pdfColumns = ['Record Type', 'Field Number', 'Field Name', 'Position', 'Length', 'Use',
          'Type', 'Field Info', 'Logic Code', 'Comments'];
      } else {
        this.isRX51Format = false;
        this.displayedColumns = ['fieldNumber', 'fieldName', 'position', 'length', 'use',
          'type', 'mappingInstructions', 'logicCode', 'comments'];
        this.pdfColumns = ['Record Type', 'Field Number', 'Field Name', 'Position', 'Length', 'Use',
          'Type', 'Mapping Instructions', 'Logic Code', 'Comments'];
      }
    });
    this.eprService.getAllPliAndEcNames().subscribe(res => {
      this.pliAndEcNames = res;
    });
  }

  exportPDF() {

    if (this.isNewDoc || this.editMode) {
      this.dialog.open(InformationDialogComponent, {
        height: '200px',
        width: '400px',
        data: {
          body: 'Export operation not available while in edit/add mode. ' +
            'Click cancel to exit edit mode(changes will be lost) or save your changes',
          buttonText: 'Close'
        }
      });
      return;
    }
    const doc = new jsPDF('landscape');

    const columns = [this.pdfColumns];
    let rectypes: any;
    this.recTypesService.getExistingRecTypes(
      this.mappingDocument.documentID)
      .subscribe(allRecords => {
        if (this.isRX51Format === false) {
          rectypes = allRecords.flatMap(itm => itm).map(itm => (
            [itm.displayName,
            itm.fieldNumber,
            itm.fieldName,
            itm.fieldPosition,
            itm.fieldLength,
            itm.fieldUse,
            itm.fieldType,
            itm.mappingInstructions,
            itm.logicCode,
            itm.comments]));
        } else if (this.isRX51Format === true) {
          rectypes = allRecords.flatMap(itm => itm).map(itm => (
            [itm.displayName,
            itm.fieldNumber,
            itm.fieldName,
            itm.fieldPosition,
            itm.fieldLength,
            itm.fieldUse,
            itm.fieldType,
            itm.fieldInfo,
            itm.logicCode,
            itm.comments]));
        }
        const typeCount = rectypes.length;
        const recCount = allRecords.length;
        let rec = [];
        let rectypeS = [];
        var dummyRec = new Array(10).fill('');
        for (let i = 0; i < recCount; i++) {
          rec.push([])
        }
        let addedcount = 0;
        rec[addedcount].push(rectypes[0]);
        rectypeS.push(rectypes[0]);
        for (let i = 1; i < typeCount; i++) {
          if (rectypes[i][0] === rectypes[i - 1][0]) {
            rec[addedcount].push(rectypes[i]);
            rectypeS.push(rectypes[i]);
          } else {
            rectypeS.push(dummyRec, dummyRec)
            addedcount++;
            rec[addedcount].push(rectypes[i])
            rectypeS.push(rectypes[i])
          }
        }

        const clientDetails = [['Client Name', 'Carrier(s)', 'PLI', 'Client Code', 'Project',
          'Project Go Live date', 'User Story', 'Format', 'Map Type', 'Assigned EC', 'Mapping BA', 'Map Notes/Comments']];
        const Fields = ['clientName', 'carrierNumber', 'pliCode', 'clientCode', 'project',
          'goLiveDate', 'userStory', 'mappingFormat', 'mappingType', 'mappingBA'];
        const ClientDetails = [this.clientdetails];

        for (let i = 0; i <= 11; i++) {
          if (i === 9) {
            this.clientdetails.push(this.pliAndEcNames[this.mappingDocument.pliCode]);
          } else if (i === 10) {
            this.clientdetails.push(this.mappingDocument['mappingBA']);
          } else if (i === 11) {
            this.clientdetails.push(this.mappingguide.response.mappingComments);
          } else {
            this.clientdetails.push(this.mappingDocument[Fields[i]]);
          }
        }

        autoTable(doc, {
          head: clientDetails,
          body: ClientDetails,
        });
        doc.addPage();
        autoTable(doc, {
          head: columns,
          body: rectypeS,
          rowPageBreak: 'avoid'
        });

        const fileName = `record-type-
          ${this.mappingDocument.documentID}-
          ${this.mappingDocument.clientCode}-
          ${this.mappingDocument.pliCode}.pdf`;
        doc.save(fileName);
      });
  }
  ngOnDestroy(): void {
    function remove(arrayData) {
      if (arrayData.length) {
        arrayData.forEach(sub => {
          sub.unsubscribe();
        });
      }
    }
    remove(this.subsListner);
    remove(this.subsArr);
  }
}
