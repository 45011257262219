import {Pipe, PipeTransform} from '@angular/core';
import {DataManagementService} from '../services/data-management.service';
import {Observable} from 'rxjs';
import {MasterDataList} from '../models/MasterDataList';
import {tap} from 'rxjs/operators';

@Pipe({
  name: 'getMdl'
})
export class GetMdlPipe implements PipeTransform {
  constructor(private dataManagementService: DataManagementService) {}

  transform(listName: string): Observable<MasterDataList> {
    return this.dataManagementService.getMasterDataSet(listName)
      .pipe(
        tap(masterDataList => {
          const dataListOptions = [];
          if (masterDataList && masterDataList.options) {
            masterDataList.options.forEach(option => dataListOptions.push(option.replace(/\\,/g, ',')));
            masterDataList.options = dataListOptions;
          }

        })
      );
  }
}
