import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { RteSuspenseService } from 'src/app/services/rte-suspense.service';
import { UsersService } from 'src/app/services/users.service';
import { SuspenseScheduleComponent } from '../suspense-schedule/suspense-schedule.component';

@Component({
  template: `
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="row options">
    <mat-icon svgIcon="more-horz-f--s"></mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onEditSchedule()">
      <span>Edit Schedule</span>
    </button>
    <button mat-menu-item (click)="onDeleteReport()">
      <span>Delete Report</span>
    </button>
    <button mat-menu-item (click)="onSetAsInactive()">
      <span *ngIf="rowData.reportScheduledStatus === 'A'">Set as Inactive</span>
      <span *ngIf="rowData.reportScheduledStatus !== 'A'">Set as Active</span>
    </button>
  </mat-menu>
  `
})
export class SegDropdownCellRendererComponent implements ICellRendererAngularComp, OnInit {
  public displayValue!: string;
  dialogConfig = new MatDialogConfig();
  rowData: any;
  userEmail: any;
  userId: any;
  gridParams: ICellRendererParams;

  constructor(private dialog: MatDialog,
    private suspenseService: RteSuspenseService,
    private alertService: CVSAlertService,
    private usersService: UsersService) { }

  ngOnInit(): void {
    this.usersService.user.subscribe(user => {
      this.userId = user?.id;
      this.userEmail = user?.email;
    })
  }

  agInit(params: ICellRendererParams): void {
    this.rowData = params?.data;
    this.gridParams = params;
  }

  onEditSchedule() {
    this.dialogConfig.data = {
      id: 1,
      title: 'Schedule Occurence',
      type: 'add_record',
      email: this.userEmail,
      rowData: this.rowData
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    const dialogData = this.dialog.open(SuspenseScheduleComponent, this.dialogConfig);
    dialogData.componentInstance.scheduleData.subscribe(data => this.callScheduleApi(data))
  }

  callScheduleApi(data) {
    const searchObj: any = {}; // this.constructSearchPayload();
    const baseObj = {
      "userId": this.userId.toString(),
      "reportType": this.rowData.reportType ? this.rowData.reportType : searchObj.reportType,
      "reportScheduledStatus": this.rowData.reportScheduledStatus ? this.rowData.reportScheduledStatus : null,
      "reportScheduleDeleted": this.rowData.reportScheduleDeleted ? this.rowData.reportScheduleDeleted : null,
      "criteriaType": this.rowData.criteriaType ? this.rowData.criteriaType : searchObj.criteria,
      "carrierValue": this.rowData.carrierValue ? this.rowData.carrierValue : searchObj.carrier !== '' ? searchObj.carrier : null,
      "accountValue": this.rowData.accountValue ? this.rowData.accountValue : searchObj.account !== '' ? searchObj.account : null,
      "groupValue": this.rowData.groupValue ? this.rowData.groupValue : searchObj.group !== '' ? searchObj.group : null,
      "carrierToValue": this.rowData.carrierToValue ? this.rowData.carrierToValue : searchObj.carrierTo !== '' ? searchObj.carrierTo : null,
      "accountToValue": this.rowData.accountToValue ? this.rowData.accountToValue : searchObj.accountTo !== '' ? searchObj.accountTo : null,
      "groupToValue": this.rowData.groupToValue ? this.rowData.groupToValue : searchObj.groupTo !== '' ? searchObj.groupTo : null,
      "createdOn": this.rowData.createdOn ? this.rowData.createdOn : new Date(),
      "updatedOn": this.rowData.updatedOn ? new Date() : null,
    }

    const payload = { ...baseObj, ...data.formData };
    this.suspenseService.createSchedule(payload).subscribe(res => {
      if (res.response && res.status === "SUCCESS") {
        this.alertService.sendAlert({
          headline: 'Event edit successful',
          alertType: CVSAlertType.Success,
          removedAfterMilliseconds: 10000,
          alertDocumentHolderName: '#header-alert',
        })
        this.gridParams.node.updateData(res.response);
        this.gridParams.api.redrawRows();
      } else if (res.errorMessage) {
        this.alertService.sendAlert({
          headline: res.errorMessage,
          alertType: CVSAlertType.Error,
          removedAfterMilliseconds: 10000,
          alertDocumentHolderName: '#header-alert',
        })
      }
    }, err => {
      this.alertService.sendAlert({
        headline: 'Something went wrong. Please try again.',
        alertType: CVSAlertType.Error,
        removedAfterMilliseconds: 10000,
        alertDocumentHolderName: '#header-alert',
      })
    })
  }

  onDeleteReport() {
    this.suspenseService.deleteScheduleEvent(this.rowData.scheduleId).subscribe(res => {
      if (res.status === "SUCCESS") {
        this.alertService.sendAlert({
          headline: "Event deleted successfully",
          alertType: CVSAlertType.Success,
          removedAfterMilliseconds: 10000,
          alertDocumentHolderName: '#header-alert',
        })
        this.gridParams.api.applyTransaction({ remove: [this.rowData] });
      } else if (res.errorMessage) {
        this.alertService.sendAlert({
          headline: res.errorMessage,
          alertType: CVSAlertType.Error,
          removedAfterMilliseconds: 10000,
          alertDocumentHolderName: '#header-alert',
        })
      }
    }, err => {
      this.alertService.sendAlert({
        headline: 'Something went wrong. Please try again.',
        alertType: CVSAlertType.Error,
        removedAfterMilliseconds: 10000,
        alertDocumentHolderName: '#header-alert',
      })
    })
  }

  onSetAsInactive() {
    const status = this.rowData.reportScheduledStatus === "A" ? "I" : "A";

    this.suspenseService.setInactiveScheduleEvent(this.rowData.scheduleId, status).subscribe(res => {
      if (res.status === "SUCCESS") {
        this.alertService.sendAlert({
          headline: "Status updated",
          alertType: CVSAlertType.Success,
          removedAfterMilliseconds: 10000,
          alertDocumentHolderName: '#header-alert',
        })
        this.gridParams.node.updateData({ ...this.rowData, reportScheduledStatus: status });
        this.gridParams.api.redrawRows();
      } else if (res.errorMessage) {
        this.alertService.sendAlert({
          headline: res.errorMessage,
          alertType: CVSAlertType.Error,
          removedAfterMilliseconds: 10000,
          alertDocumentHolderName: '#header-alert',
        })
      }
    }, err => {
      this.alertService.sendAlert({
        headline: 'Something went wrong. Please try again.',
        alertType: CVSAlertType.Error,
        removedAfterMilliseconds: 10000,
        alertDocumentHolderName: '#header-alert',
      })
    })

  }

  refresh(params: ICellRendererParams) {
    return false;
  }
}