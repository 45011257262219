<script src="eligibility-dialog.component.ts"></script>
<div class="popup threshold-dialog">
  <div style="display: flex; justify-content: flex-end">
    <button (click)="closeDialog()" align="end"
    style="background: transparent;
    position: absolute;
    border: none;cursor:pointer;">X</button>
  </div>

  <div class="container" style = "font-size: 18px;height: 18px;padding: 20px;">
    <div class="row">
      <div class="col-10">
         <b *ngIf="isEligibility" headerC id="eligibility-details"> File Tracking Details </b>
         <b *ngIf="!isEligibility" headerC id="eligibility-details"> File Tracking Details </b>
         <span style="float: right;">

           <mat-icon style="width: 17px;height: 17px;cursor: pointer; color: grey;padding-right: 5px; margin-top:-2px ;" svgIcon="download-arrow--s" (click)="exportDetails()"></mat-icon>
           <label style="font-weight:bold;padding-right: 1em;"> <b class="button-label">Details</b></label>
         </span>
      </div>
    </div>
  </div>
  <br>
  <mat-dialog-content id="bodyTextContent">


    <br>
    <ag-grid-angular
     rowHeight="34"
     headerHeight="26"
     [getRowStyle]="colorInGrid"
     [context]="contextObj"
     [frameworkComponents]="frameworkComponents"
      #eligibilityGrid class="ag-theme-alpine epr-feed-gui-grid threshold"
      style="height: 460px  !important; margin-bottom:85px; ">
    </ag-grid-angular>


  </mat-dialog-content>
  <div>
    <div  class="mat-card">
    <button class="cvs-btn-primary" id="cancelContact" style="position: absolute;right: 0;bottom: 0;margin-right: 1em;margin-bottom: 2em;cursor: pointer;z-index: 500;" (click)="closeDialog()">
      Done
    </button>
  </div>
</div>
