import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import * as ColumnSets from './eprUserAccessModelColumns';
import { BehaviorSubject } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';

@Component({
  selector: "app-epruseraccessmodel",
  templateUrl: "./epruseraccessmodel.component.html",
  styleUrls: ["./epruseraccessmodel.component.scss"],
})
export class EpruseraccessmodelComponent implements OnInit {
  @ViewChild("epruseraccessmodelgrid", { static: false })
  epruseraccessmodelgrid: AgGridAngular;
  eprUserAccessModelColumns = ColumnSets.eprUserAccessModelColumns;
  gridApiActive:any;
  searchText: any
  contactEwfAccessType:any
  rowId:any
   public rowSelection: "single" | "multiple" = "single";
   public rowSelectedData: any;
   eprUsersList = new BehaviorSubject([]);

  noRowsTemplate;
  loadingTemplate;

  constructor(private userService: UsersService, private alertService: CVSAlertService,
    private dialogRef: MatDialogRef<EpruseraccessmodelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;
    this.noRowsTemplate = `"<span">no rows to show</span>"`;
  }

  ngOnInit() {
    this.searchText = this.data.contactEmail;
    this.contactEwfAccessType = this.data.contactEwfAccessType;
    this.rowId = this.data.rowId;
    this.userService.getEprUsers().subscribe((res) => {
      if (res) {
        this.eprUsersList = res;
        this.getNewTableData();
        this.onFilterBoxChanged();
      }
    });
  }

  setColumnType() {
    this.epruseraccessmodelgrid?.api?.setColumnDefs(
      this.eprUserAccessModelColumns
    );
  }

  getNewTableData() {
    let sortOrder;
    this.setColumnType();
    sortOrder = [];
    const tableData = [];
    this.eprUsersList.forEach((res: any) => {
      const eprUsersObject: any = {
        select: true,
        firstName: res.firstName,
        lastName: res.lastName,
        emailAddress: res.emailAddress,
        phone: res.phone,
        status: res.status,
        superClientId: res.superClientId,
        superClientAccess: res.superClientAccess,
        clientCode: res.clientCode,
        carrier: res.carrier,
        clientAccess: res.clientAccess,
      };
      tableData.push(eprUsersObject);
    });
    this.epruseraccessmodelgrid?.api?.setRowData(tableData);
  }

  filterTables(query: any) {
    this.epruseraccessmodelgrid.api.setQuickFilter(query);
  }

  onGridReady(params) {
    this.gridApiActive = params.api;
    const tableData = [];
    this.eprUsersList.forEach((res: any) => {
      const eprUsersObject: any = {
        select: true,
        firstName: res.firstName,
        lastName: res.lastName,
        emailAddress: res.emailAddress,
        phone: res.phone,
        status: res.status,
        superClientId: res.superClientId,
        superClientAccess: res.superClientAccess,
        clientCode: res.clientCode,
        carrier: res.carrier,
        clientAccess: res.clientAccess,
      };
      tableData.push(eprUsersObject);
    });
    this.epruseraccessmodelgrid?.api?.setRowData(tableData);
  }

  onFilterBoxChanged() {
    this.gridApiActive?.setQuickFilter(this.searchText);
  }

  onRowClick($event) {
    this.rowSelectedData = this.epruseraccessmodelgrid.api.getSelectedRows()[0];
}
  link(){
    let rowSelectedFinalData=this.rowSelectedData;
    if(rowSelectedFinalData !=null && rowSelectedFinalData != undefined){
    let linkData={
      contactEwfAccessType:this.contactEwfAccessType,
      rowSelectedData: rowSelectedFinalData,
      rowId:this.rowId
    }
    this.dialogRef.close({linkData,linked:true,selectionHistory:this.data.selectionHistory,rowId:this.rowId});
  }


  else if(rowSelectedFinalData == null || rowSelectedFinalData == undefined ){
      this.alertService.sendAlert({
      headline: 'Please select one of the user to update contact information',
      alertType: CVSAlertType.Warning,
      fadeClass: true,
      alertDocumentHolderName: '#linkuser-alert'
    });
  }
  }

  close() {
    this.dialogRef.close({linked:false,selectionHistory:this.data.selectionHistory,rowId:this.rowId});
  }
}
