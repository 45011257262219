export class Contact {
  setupId?: any;
  alertCategoryId?: any;
  contactId?: any;
  emailId?: any;
  alertTypeCd?: any;
  alertTypeDesc?: any;
  cmnctTypeCd?: any;
  cmnctTypeDesc?: any;
  statusCd?: any;
  statusDesc?: any;
}
