import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'app-tooltipcomponent',
  template: ` <div class="custom-tooltip" style="background-color: #f8f8f8;">
    <div *ngFor="let item of data">
      <p>{{ item }}</p>
    </div>
  </div>`,
  styles: [
    `
      :host {
        position: absolute;
        width: auto;
        height: 70px;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip {
        padding:5px;
        border: 1px solid grey;
      }

      .custom-tooltip div {
        margin: 5px;
        white-space: nowrap;
        padding:5px;
      }
    `,
  ],
})
export class RecipientTooltipComponent implements ITooltipAngularComp {
  private params!: ITooltipParams;
  public data!: any;

  agInit(params: ITooltipParams): void {
    this.params = params;
    this.data = params?.api?.getDisplayedRowAtIndex(params?.rowIndex).data?.recipients?.split(',');
  }
}
