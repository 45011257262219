import {Component, OnDestroy, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import * as fromRoot from '../reducers';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {getEditMode} from '../reducers/filetracker.reducer';
import { CopayService } from '../services/copay.service';
import {MappingFormService} from "../epr/mapping-documents/mapping-form.service";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MessageSupressionService } from '../services/message-supression.service';
import { AddEditItemPopupComponent } from '../admin/data-management/add-edit-item-popup/add-edit-item-popup.component';
import { AddEditReportingItemPopupComponent } from "../admin/data-management/add-edit-reporting-item-popup/add-edit-reporting-item-popup.component";

@Component({
  selector: 'app-edit-btn-cell-renderer',
  styles: ['button { color: #767676 !important; }'],
  template: ` <ng-container *ngIf="currentPage==='projectTracker'; else editDelete" >
                  <button *ngIf="!isProjectDeletable" mat-button [matMenuTriggerFor]="menu">
                    <mat-icon class="example-icon" aria-hidden="false" aria-label="expand menu">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="btnClicked($event)">Delete Project</button>
                  </mat-menu>
                </ng-container>
                <ng-template #editDelete>
                  <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon class="example-icon" aria-hidden="false" aria-label="expand menu">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="btnClickedHandler($event, 'Edit')">Edit</button>
                    <button mat-menu-item (click)="btnClickedHandler($event, 'Delete')">Delete</button>
                  </mat-menu>
                </ng-template>`,
  })
export class EditBtnCellRendererComponent implements ICellRendererAngularComp{
  public params: any;
  public currentPage: string;
  dialogConfig:any = new MatDialogConfig();
  readOnlySubscription: Subscription;
  readOnly$ = this.store.select(fromRoot.getReadOnly) as Observable<boolean>;
  isProjectDeletable: boolean;
  constructor(private store: Store<fromRoot.AppState>, private msgSupressService: MessageSupressionService, public dialog: MatDialog, private mappingFormService: MappingFormService) {

  }
  agInit(params: any): void {
    this.params = params;
    this.currentPage = this.params?.context?.componentParent?.frameworkComponents?.currentPage;
    this.readOnlySubscription = this.readOnly$.subscribe(eprReadOnly => {
      this.isProjectDeletable = !eprReadOnly;
        });
    this.isProjectDeletable = false;
  }
  checkDisabled(){
    return true;
  }
  btnClicked($event) {
    this.params.context.componentParent.deleteTableRecord(this.params.node, this.params.column.columnId);
  }
  btnClickedHandler($event, mode) {
    this.dialogConfig.data = {};
    this.dialogConfig.data.actionType = { type: this.currentPage, mode: mode, title: mode + this.currentPage}
    this.dialogConfig.data.id = this.params.data.id;
    this.dialogConfig.data.message = this.params.data.message;
     this.dialogConfig.data.reportMessageType = this.params.data.reportMessageType;
    this.dialogConfig.data.messageDescription = this.params.data.messageDescription;
    if(this.currentPage === 'reportingMessageCodes'){
      this.dialogConfig.data.code = this.params.data.message;
      this.dialogConfig.data.type = this.params.data.reportType;
      this.dialogConfig.data.description = this.params.data.messageDescription;
    }
    this.dialogConfig.height = (mode=="Delete") ? '250px':'550px';
    this.dialogConfig.width= (mode=="Delete") ? '350px':'360px';;
    const component:any = this.currentPage === 'reportingMessageCodes' ? AddEditReportingItemPopupComponent : AddEditItemPopupComponent;
    const dialogRef = this.dialog.open(component, this.dialogConfig)

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.length >0 ) {
        this.params.context.componentParent.updateRecord(this.params.node, result);
      }
      if(result && result?.mode == 'Delete') {
        this.params.context.componentParent.deleteTableRecord(this.params.node, this.params.column.columnId);
      }

    });
  }
  refresh(params: any): boolean {
    return false;
  }


}
