export enum FormType {
  HEADER = 'header',
  CHECKLIST = 'checklist',
  TESTING = 'testing',
  QUESTIONNAIRE = 'questionnaire',
  PROJECT_TRACKER = 'projectTracker',
  ELIGIBILITY_AGREEMENT = 'eligibilityAgreement',
  BATCH_PROFILE_UPDATES = 'batchProfileUpdates',
  FILETRACKER= 'filetracker'
}
