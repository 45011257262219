import { Component, OnInit,ViewChild,AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { CopayService } from '../services/copay.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {getIsRegisteredUser, getRole} from '../reducers';
import {AgGridAngular} from 'ag-grid-angular';
import {Observable} from 'rxjs';
import {Permission} from '../models/Authorization';
import {saveAs} from 'file-saver';
import * as ColumnSets from './copayColumnSets';
import {copayColumns} from './copayColumnSets';
import {ClearCopayAction, LoadAllCopayAction, LoadCopayAction} from '../actions/copay.action';
import {Copay} from '../models/Copay';

import {CookieService} from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import {EligibilityDialogComponent} from '../eligibility-dialog/eligibility-dialog.component';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {UsersService} from "../services/users.service";

@Component({
  selector: 'app-copay-accum-dashboard',
  templateUrl: './copay-accum-dashboard.component.html',
  styleUrls: ['./copay-accum-dashboard.component.scss']
})
export class CopayAccumDashboardComponent implements OnInit, AfterViewInit {
  @ViewChild('grid1') grid1: AgGridAngular;


  // isRegisteredUser$ = this.store.select(getIsRegisteredUser) as Observable<boolean>;
  isAdmin = false;
  // copay$ = this.store.select(fromRoot.getAllCopay) as Observable<Copay[]>;
  currentUserId: number;
  currentDateGrid2: Date;
  currentDateGrid1: Date;
  islatest = true;
  copay$ = this.store.select(fromRoot.getAllCopay) as Observable<Copay[]>;
  isRegisteredUser$ = this.store.select(getIsRegisteredUser) as Observable<boolean>;

  

  constructor(private store: Store<fromRoot.AppState>,private copayService:CopayService, private router: Router,
    private usersService: UsersService,
    public dialog: MatDialog,
    private cookieService: CookieService) { }

    dashboardOptions = ['Benefit','Eligibility','Spedm'];

  selectedDashboardOption: string = this.dashboardOptions[0];

  dashboardRole = true;

  ngOnInit(): void {
    this.store.dispatch(new LoadAllCopayAction());
      this.usersService.userRole.subscribe(role =>{
        if (role === Permission.FT_BENEFIT) {
          this.dashboardOptions = ['Benefit'];
          this.dashboardRole = false;
          this.isAdmin = true
        }else if([ Permission.FILETRACKER].includes(role)){
          this.dashboardOptions = ['Eligibility','Spedm','Benefit'];
          this.dashboardRole = true;
        }
        if ([Permission.ADMIN, Permission.MANAGER,
        Permission.EC, Permission.PROCESSOR,
        Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
        Permission.INTFEED, Permission.FILETRACKER].includes(role)) {
          this.isAdmin = true;
        }
      });
  }

ngAfterViewInit(): void {
  this.getNewTableData('tableOne', 'tableOne');
}

createNewCopay() {
   this.router.navigateByUrl('dashboard/onboardingcopay');
   this.copayService.isNew = true;
   this.store.dispatch(new ClearCopayAction());
   this.store.dispatch(new LoadCopayAction(0));
}

refreshMyProcessingFiles(tableId: string) {
  if (tableId === 'tableTwo') {
    this.currentDateGrid2 = new Date();
  }
  if (tableId === 'tableOne') {
    this.currentDateGrid1 = new Date();
  }
}

onRowClicked($event: any) {
  this.copayService.isNew = false;
  this.copayService.cltId = $event.data.cltSetupID
  this.store.dispatch(new LoadCopayAction($event.data.cltSetupID));
  this.router.navigateByUrl('dashboard/onboardingcopay');
}

onDragStopped(tableId: string) {
}

onSortChange(tableId: string) {
}

onFilterChange(tableId: string) {

}


filterTables(query: any) {
  this.grid1.api.setQuickFilter(query);
}

resetFilters(tableId: string) {
  this.cookieService.set('ewf-table-one-column-state', JSON.stringify(copayColumns.map(column => column.field)));
  this.cookieService.set('ewf-table-one-sort-model', JSON.stringify([{colId: '1', sort: 'asc'}, {colId: '2', sort: 'asc'}]));
  this.setColumnType();
}

private setColumnType() {
  this.grid1.api.setColumnDefs(ColumnSets.copayColumns);
}

private getNewTableData(dataSetToFetch: string, tableId: string) {
  let sortOrder;
  this.setColumnType();

  sortOrder = [];
  this.setSortOrder(tableId, sortOrder);
  this.copayService.getAll().subscribe((copayData) => {
    const tableData = [];
    if(copayData.response !== null){
      copayData.response.forEach((res: any) => {
        const copayObject: any = {
          cltSetupID: res.cltSetupID,
          clientReceiver : res.clientReceiver,
          systemTx:res.systemTx,
          inputFileNamePattern: res.inputFileNamePattern,
          outputFileNamePattern: res.outputFileNamePattern,
          reportFrequency: res.benefitsAlertConfigModel? res.benefitsAlertConfigModel.reportFrequency:'',
          reportFrequencyVal:res.benefitsAlertConfigModel? res.benefitsAlertConfigModel.reportFrequencyVal:'',
          contractedDeliveryTime: res.benefitsAlertConfigModel? res.benefitsAlertConfigModel.contractedDeliveryTime:'',
          lateFileWarningTime: res.benefitsAlertConfigModel? res.benefitsAlertConfigModel.lateFileWarningTime:'',
          outputAlertProcTimeSla: res.benefitsAlertConfigModel? res.benefitsAlertConfigModel.outputAlertProcTimeSla:'',
          outputLateFileAlertTimeSla: res.benefitsAlertConfigModel? res.benefitsAlertConfigModel.outputLateFileAlertTimeSla:'',
          missedFileSlaAlertForInput: res.benefitsAlertConfigModel? res.benefitsAlertConfigModel.missedFileSlaAlertForInput:'',
          lateFileAlertForInput: res.benefitsAlertConfigModel? res.benefitsAlertConfigModel.lateFileAlertForInput:'',
          missedFileSlaAlertForOutput: res.benefitsAlertConfigModel? res.benefitsAlertConfigModel.missedFileSlaAlertForOutput:'',
          lateFileAlertForOutput: res.benefitsAlertConfigModel? res.benefitsAlertConfigModel.lateFileAlertForOutput:'',
      activeorInactiveStatus: res.activeorInactiveStatus,
      note: res.note};
  
        tableData.push(copayObject);
      });
    }
    
    this.grid1.api.setRowData(tableData);

  });

}


private setRowData(tableId: string, rowData) {
  this.grid1.api.setRowData(rowData);
}

private setSortOrder(tableId: string, sortOrder) {
  this.grid1.api.setSortModel(sortOrder);
}


exportView(tableId) {
  const date = new Date();
  const fileName = 'Filetracker_' + date.getTime() + '.csv';
  if (tableId === 'tableOne') {
    const fileData = new Blob([this.grid1.api.getDataAsCsv()], {type: 'text/csv'});
    saveAs(fileData, fileName);
  }
}
dashboardOption() {
  if (this.selectedDashboardOption === 'Eligibility') {
    this.router.navigateByUrl('dashboard/filetracker');
  } else if (this.selectedDashboardOption === 'Spedm') {
    this.router.navigateByUrl('dashboard/spedm');
  } else if (this.selectedDashboardOption === 'Benefit') {
    this.router.navigateByUrl('dashboard/Benefit');
}
}

onChange($event: MatSlideToggleChange) {
  this.islatest = $event.checked;
  this.getNewTableData('tableOne', 'tableOne');
}
}
