import { Injectable } from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {

  constructor(private http: CVSHttpClient, private httpClient: HttpClient) { }

  addTranslations(request: any) {
    return this.http.post(`${environment.baseUrl}/savetranslation`, request);
  }

  getTranslations(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/gettranslation`);
  }

  deleteTranslations(request: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/translation/delete`, request);
  }
}
