export enum AdminTabList {
    USERS = "Users",
    DATA = "Data",
    PARM_MAPPING = "Parm Mapping",
    SETTINGS = "Settings",
    FILE_LAYOUT = "File Layout",
    LOGIC_CODES = "Logic Codes",
    CLASSIFICATION_CODES = "Classification Code",
    SCHEDULED_EVENTS = "Scheduled Events",
}

export enum AdminTabPermissions {
    USERS = "ELG_USER_MANAGEMENT",
    DATA = "ELG_ADMIN_DATA",
    PARM_MAPPING = "ELG_PARM_MAPPING",
    SETTINGS = "ELG_SETTINGS",
    FILE_LAYOUT = "ELG_FILE_LAYOUT",
    LOGIC_CODES = "ELG_LOGIC_CODES",
    CLASSIFICATION_CODES = "ELG_CLASSIFICATION_CODES",
    SCHEDULED_EVENTS = "ELG_SCHEDULED_EVENTS", 
}