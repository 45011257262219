import {Actions, SET_LOGGED_IN_USERPBM} from '../actions/userPbm.action';
import {Permission} from '../models/Authorization';

export interface State {
  id: number;
  role: Permission;
  rdcs: string[];
}

export const initialState: State = {
  id: null,
  role: undefined,
  rdcs: []
};

export function reducer(state = initialState, action: Actions): State {
  if (action.type === SET_LOGGED_IN_USERPBM) {
    let filteredRole = action.role;
    
    return {...state, role: filteredRole, rdcs: action.legacyUser.rdcs, id: action.legacyUser.id};
  } else {
    return state;
  }
}

export const getRole = (state: State) => state.role;
export const getRdcs = (state: State) => state.rdcs;
export const getUserId = (state: State) => state.id;
export const getIsRegisteredUser = (state: State): boolean => state.role !== Permission.VISITOR;

