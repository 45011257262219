import {Injectable} from '@angular/core';
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class DateUtilService {

  convertToCst(res) {
    res = res.map((row) => {
      let timeStamp = row.timeStamp;
      timeStamp = new Date(timeStamp);
      const date = new Date(timeStamp);
      const datePipe = new DatePipe('en-Us')
      let cstDate = datePipe.transform(date, 'MM/dd/yyyy h:mm a');
      return {...row, timeStamp: cstDate}
    });
    return res;
  }

}


