import { ToolTipComponent } from "src/app/dashboard/tool-tip/tool-tip.component";
export const historyColumns =[
  {headerName: 'Section', field: 'section', sortable: true, filter: true, resizable: true,
  lockVisible: true, lockPosition: true, filterParams: {newRowsAction: 'keep'}},
{headerName: 'Sub Selection',   field: 'subSelection', sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
  filterParams: { newRowsAction: 'keep'}},
  {headerName: 'Field', field: 'eprHistoryField', sortable: true, filter: true, resizable: true,
  lockVisible: true, lockPosition: true, filterParams: {newRowsAction: 'keep'}},
{headerName: 'Previous Value',   field: 'previousValue', sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
tooltipField: 'newValue',
tooltipComponentParams: { color: '#ececec' }},
  {headerName: 'New value', field: 'newValue', sortable: true, filter: true, resizable: true,
  lockVisible: true, tooltipField: 'newValue',
  tooltipComponentParams: { color: '#ececec' }},

]
export interface ICallRecord {
  eprAuditUser: string;
  timeStamp: string;
  totalchanges:number;
}

export interface IAccount {
  section: string;
  subSelection: string;
  eprHistoryField: string;
  previousValue: string;
  newValue: string;
}

