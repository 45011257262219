
import {Contact} from '../models/Contact';
import {Spedm} from '../models/Spedm';
import {
  Actions,
  CLEAR_SPEDM_DATA, DELETE_SPEDM, EDIT_MODE,
  EDIT_SPEDM,
  LOAD_ALL_SPEDM,
  LOAD_ALL_SPEDM_SUCCESS, LOAD_CONTACT_SUCCESS,
  LOAD_DATA,
  LOAD_SPEDM,
  LOAD_SPEDM_SUCCESS, SAVE_CONTACT_SUCCESS, SAVE_SPEDM_SUCCESS
} from '../actions/spedm.action';

export interface State {
  spedm: Spedm[];
  selectedSpedmId: number;
  selectedSpedm: Spedm;
  contact: Contact[];
  Spedm: any[];
  isEdit: boolean;
}

export const initialState: State = {
  spedm: [],
  selectedSpedmId: undefined,
  selectedSpedm: {},
  contact: [],
  Spedm: [],
  isEdit: false
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case LOAD_ALL_SPEDM_SUCCESS:
      return {...state, spedm: action.payload as Spedm[]};

    case LOAD_SPEDM:
      return {...state };

    case LOAD_DATA:
      state.spedm = action.payload as any;
      return {...state };

    case LOAD_SPEDM_SUCCESS:
      const spedm = (Array.isArray(action.payload)) ? action.payload : [action.payload];
      return {...state, selectedSpedm: action.payload as Spedm, spedm: spedm as Spedm[]};

    case SAVE_SPEDM_SUCCESS:
      const newSpedm = state.spedm.concat(action.payload);
      newSpedm.sort((a, b) => {
        // if (a.id < b.id) {
        //   return -1;
        // }
        // if (a.id > b.id) {
        //   return 1;
        // }
        return 0;
      });
      return {...state, spedm: newSpedm};

    case LOAD_CONTACT_SUCCESS:
      const contacts = (Array.isArray(action.payload)) ? action.payload : [action.payload];
      return {...state,  contact: contacts as Contact[]};


    case SAVE_CONTACT_SUCCESS:
      const newContact = state.contact.concat(action.payload);
      newContact.sort((a, b) => {
        // if (a.id < b.id) {
        //   return -1;
        // }
        // if (a.id > b.id) {
        //   return 1;
        // }
        return 0;
      });
      return {...state, contact: newContact};

    case EDIT_SPEDM:
      return {...state, selectedSpedmId: action.payload.id};

    case CLEAR_SPEDM_DATA:
      return initialState;

    case DELETE_SPEDM:
      return {
        ...state
      };
    case EDIT_MODE:
      state.isEdit = action.payload as any;
      return {...state };

    default:
      return state;
  }
}
export const getspedm = (state: State) => state.spedm;
export const getSelectedSpedm = (state: State) => state.selectedSpedm;
export const getContact = (state: State) => state.contact;
export const getEditMode = (state: State) => state.isEdit;

