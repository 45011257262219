import {Injectable} from '@angular/core';
import {EprFeed} from '../models/EprFeed';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {CVSHttpClient} from 'angular-component-library';

@Injectable({
  providedIn: 'root'
})
export class EprFeedService {

  eprfeed: EprFeed;

  constructor(private http: CVSHttpClient) {  }

  getEprFeeds(id: number): Observable<any> {
    return this.http.get(`${environment.baseUrl}/getEprFeedByEpr?eprId=${id}`);
  }

  getAll(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/eprfeed`);
  }

  saveEprFeed(eprfeed: EprFeed): Observable<any> {
    return this.http.post(`${environment.baseUrl}/eprfeed/save`, eprfeed);
  }

  editEprFeed(eprfeed: EprFeed, id: number): Observable<any> {
    return this.http.post(`${environment.baseUrl}/eprfeed/epr/${id}/save?type=SaveRxClaim`, eprfeed);
  }
  deleteEpr(eprId: number): Observable<any> {
    return this.http.delete(`${environment.baseUrl}/eprfeed/epr/${eprId}/delete`);
  }

  saveEprFeedWithRecordType(eprfeed: EprFeed, id: number, type: string): Observable<any> {
    return this.http.post(`${environment.baseUrl}/eprfeed/epr/${id}/type/save` + '?type=' + type, eprfeed);
  }

}
