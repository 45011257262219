import {Actions, LOAD_CHECKLIST_FORM_SUCCESS, UPDATE_CHECKLIST_FORM} from '../actions/checklist.action';

import {convertDateForDatePicker} from '../helpers/DateTimeHelpers';
import {ADD_PROJECT_TRACKER, CLEAR_EPR_DATA, COPY_EPR, DELETE_PROJECT_TRACKER,
  RESET_PROJECT_INDICES, SELECT_PROJECT} from '../actions/epr.action';
import {removeItemInArray, replaceItemsInArray} from '../helpers/NgRxHelpers';
import {StatusDates} from '../helpers/CalculateStatusHelper';

export class ChecklistStateType {
  newDiscDueDate: string;
  newDiscCmpltDate: string;

  newSetupDueDate: string;
  newSetupCmpltDate: string;

  newTstExptDate: string;
  newTstDueDate: string;
  newTstCmpltDate: string;

  newBLExptDate: string;
  newBLDueDate: string;
  newBLCmpltDate: string;

  newIDCrdsDueDate: string;
  newIDCrdsCmpltDate: string;

  goLiveDueDate: string;
  goLiveCmpltDate: string;

  discActDate: string;
  bSRActDate: string;
  fileLayoutActDate: string;
  pLIActDate: string;
  grpPLISetup: string;
  hEATActDate: string;
  cOLAActDate: string;
  libraryActDate: string;
  fTPFormLoadRptsCmpltDte: string;
  clntCstmReqSignOffDte: string;
  sendToJimDate: string;
  clientCodeVerifyDate: string;
  prePreCpltDate: string;
  preLoadActDate: string;
  stageActDate: string;
  grpStageCpltDate: string;
  requiredMemFieldsActDate: string;
  cDDCmpltDte: string;
  cDDCarLstCmpltDte: string;
  xwalkProfileActDate: string;
  iDCardShellActDate: string;
  outQActDate: string;
  rJSCompDate: string;
  crtCAGListCmpltDate: string;
  filTrkrOnBrdCmpltDte: string;
  fastlinkActDate: string;
  fastlinkGroupDate: string;
  sHRTIDSRCHActDate: string;
  EZEConfigActDate: string;
  cMFlagReviewDate: string;
  cMSignOffDate: string;
  cMStatus: string;
  reqTstFileCompDate: string;
  tstFileRecCompDate: string;
  gISTestCmpltDate: string;
  prePreTstCpltDate: string;
  testVisualReviewCmpltDate: string;
  loadTstFileCompDate: string;
  fourrxIDconfigverifydate: string;
  clientTstSignOffCmpltDte: string;
  vrfyPlanDesignQL: string;
  iCACoreIDOffset: string;
  iCACoreAltOffset: string;
  iCAXwalkIDField: string;
  iCAEligSettings: string;
  iCAMatchRule: string;
  iCANetworkSettings: string;
  iCAPrimIDChoice: string;
  vrfyCAGProcessFlags: string;
  qLFlagCompDate: string;
  eDWFlgCompDate: string;
  plansCompDate: string;
  bPGCompDate: string;
  vrfyXwlkAvCmpltDte: string;
  iDCT4CasetoIFCmpltDte: string;
  reconCmpltDate: string;
  compareCmpltDate: string;
  obtApprv2LoadCmpltDt: string;
  baselineCompDate: string;
  verifyBLLoadCompDate: string;
  fourrxscreentoannacmpltdate: string;
  bL2QLCompDate: string;
  reqManCrdFileCmpltDte: string;
  qCManCrdFileCmpltDte: string;
  manCrdFileSntVendorCmpltDte: string;
  qCCardProofCmpltDate: string;
  reqAutoRunCdCmpltDt: string;
  claimsVerificationCompDate: string;
  verifyLiveLoadCompDate: string;


  // NOTES
  discoveryNotes: string;
  setupPhaseNotes: string;
  testNote: string;
  integFeedMigNotes: string;
  baselineNote: string;
  iDCardTaskNotes: string;
  goLiveNote: string;

}

export interface State {
  selectedProjectIndex: number;
  checklists: ChecklistStateType[];
}

export const initialState: State = {
  selectedProjectIndex: 0,
  checklists: [{

    newDiscDueDate: '',
    newDiscCmpltDate: '',

    newSetupDueDate: '',
    newSetupCmpltDate: '',

    newTstExptDate: '',
    newTstDueDate: '',
    newTstCmpltDate: '',

    newBLExptDate: '',
    newBLDueDate: '',
    newBLCmpltDate: '',

    newIDCrdsDueDate: '',
    newIDCrdsCmpltDate: '',

    goLiveDueDate: '',
    goLiveCmpltDate: '',

    // Discovery
    discActDate: '',
    bSRActDate: '',
    fileLayoutActDate: '',
    pLIActDate: '',
    grpPLISetup: '',
    hEATActDate: '',
    cOLAActDate: '',
    libraryActDate: '',
    fTPFormLoadRptsCmpltDte: '',
    clntCstmReqSignOffDte: '',

    // Setup
    sendToJimDate: '',
    clientCodeVerifyDate: '',
    prePreCpltDate: '',
    preLoadActDate: '',
    stageActDate: '',
    grpStageCpltDate: '',
    requiredMemFieldsActDate: '',
    cDDCmpltDte: '',
    cDDCarLstCmpltDte: '',
    xwalkProfileActDate: '',
    iDCardShellActDate: '',
    outQActDate: '',
    rJSCompDate: '',
    crtCAGListCmpltDate: '',
    filTrkrOnBrdCmpltDte: '',
    fastlinkActDate: '',
    fastlinkGroupDate: '',
    sHRTIDSRCHActDate: '',
    EZEConfigActDate: '',
    cMFlagReviewDate: '',
    cMSignOffDate: '',
    cMStatus: '',

    // Testing Phase
    reqTstFileCompDate: '',
    tstFileRecCompDate: '',
    gISTestCmpltDate: '',
    prePreTstCpltDate: '',
    testVisualReviewCmpltDate: '',
    loadTstFileCompDate: '',
    fourrxIDconfigverifydate: '',
    clientTstSignOffCmpltDte: '',

    // Baseline Phase
    vrfyPlanDesignQL: '',
    iCACoreIDOffset: '',
    iCACoreAltOffset: '',
    iCAXwalkIDField: '',
    iCAEligSettings: '',
    iCAMatchRule: '',
    iCANetworkSettings: '',
    iCAPrimIDChoice: '',
    vrfyCAGProcessFlags: '',
    qLFlagCompDate: '',
    eDWFlgCompDate: '',
    plansCompDate: '',
    bPGCompDate: '',
    vrfyXwlkAvCmpltDte: '',
    iDCT4CasetoIFCmpltDte: '',
    reconCmpltDate: '',
    compareCmpltDate: '',
    obtApprv2LoadCmpltDt: '',
    baselineCompDate: '',
    verifyBLLoadCompDate: '',
    fourrxscreentoannacmpltdate: '',
    bL2QLCompDate: '',

    // ID Cards Phase
    reqManCrdFileCmpltDte: '',
    qCManCrdFileCmpltDte: '',
    manCrdFileSntVendorCmpltDte: '',
    qCCardProofCmpltDate: '',
    reqAutoRunCdCmpltDt: '',

    // Go Live Phase
    claimsVerificationCompDate: '',
    verifyLiveLoadCompDate: '',

    discoveryNotes: '',
    setupPhaseNotes: '',
    testNote: '',
    integFeedMigNotes: '',
    baselineNote: '',
    iDCardTaskNotes: '',
    goLiveNote: ''
  }]
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case LOAD_CHECKLIST_FORM_SUCCESS:
      return {
        ...state,
        checklists: action.payload.map(checklistFromBackend => convertChecklistDatesForDatePicker(checklistFromBackend))
      };

    case RESET_PROJECT_INDICES:
      return {
        ...state,
        selectedProjectIndex: 0
      };

    case ADD_PROJECT_TRACKER:
      return {
        ...state,
        selectedProjectIndex: 0,
        checklists: [initialState.checklists[0], ...state.checklists]
      };

    case DELETE_PROJECT_TRACKER:
      return {
        ...state,
        selectedProjectIndex: 0,
        checklists: removeItemInArray(state.checklists, action.iteration)
      };

    case UPDATE_CHECKLIST_FORM:
      return {
        ...state,
        checklists:
          replaceItemsInArray(
            state.checklists,
            action.payload.selectedProjectIndex,
            action.payload.formData
          )
      };

    case SELECT_PROJECT:
      return {
        ...state,
        selectedProjectIndex: action.payload
      };

    case CLEAR_EPR_DATA:
      return initialState;

    case COPY_EPR:
      return initialState;

    default:
      return state;
  }
}

export const getCheckListDrivers = (state: State) => {
  if (state.checklists.length === 0 || state.checklists[state.selectedProjectIndex] === undefined
     || state.checklists[state.selectedProjectIndex].libraryActDate === undefined) {
    setTimeout(() => {
      return;
    }, 1000);
  }
  if (state.checklists.length === 0 || state.checklists[state.selectedProjectIndex] === undefined
    || state.checklists[state.selectedProjectIndex].libraryActDate === undefined) {
    return;
}
  return {
    libraryCmpltDate: state.checklists[state.selectedProjectIndex].libraryActDate === ''
      || state.checklists[state.selectedProjectIndex].libraryActDate === null
      || state.checklists[state.selectedProjectIndex].libraryActDate === undefined
  };
};


const convertChecklistDatesForDatePicker = (payload) => {
  return {
    ...payload,

    // Checklist section header dates
    newDiscDueDate: convertDateForDatePicker(payload['newDiscDueDate']),
    newDiscCmpltDate: convertDateForDatePicker(payload['newDiscCmpltDate']),

    newSetupDueDate: convertDateForDatePicker(payload['newSetupDueDate']),
    newSetupCmpltDate: convertDateForDatePicker(payload['newSetupCmpltDate']),

    newTstExptDate: convertDateForDatePicker(payload['newTstExptDate']),
    newTstDueDate: convertDateForDatePicker(payload['newTstDueDate']),
    newTstCmpltDate: convertDateForDatePicker(payload['newTstCmpltDate']),

    newBLExptDate: convertDateForDatePicker(payload['newBLExptDate']),
    newBLDueDate: convertDateForDatePicker(payload['newBLDueDate']),
    newBLCmpltDate: convertDateForDatePicker(payload['newBLCmpltDate']),

    newIDCrdsDueDate: convertDateForDatePicker(payload['newIDCrdsDueDate']),
    newIDCrdsCmpltDate: convertDateForDatePicker(payload['newIDCrdsCmpltDate']),

    goLiveDueDate: convertDateForDatePicker(payload['goLiveDueDate']),
    goLiveCmpltDate: convertDateForDatePicker(payload['goLiveCmpltDate']),

    // Discovery Phase
    discActDate: convertDateForDatePicker(payload['discActDate']),
    bSRActDate: convertDateForDatePicker(payload['bSRActDate']),
    fileLayoutActDate: convertDateForDatePicker(payload['fileLayoutActDate']),
    pLIActDate: convertDateForDatePicker(payload['pLIActDate']),
    grpPLISetup: convertDateForDatePicker(payload['grpPLISetup']),
    hEATActDate: convertDateForDatePicker(payload['hEATActDate']),
    cOLAActDate: convertDateForDatePicker(payload['cOLAActDate']),
    libraryActDate: convertDateForDatePicker(payload['libraryActDate']),
    fTPFormLoadRptsCmpltDte: convertDateForDatePicker(payload['fTPFormLoadRptsCmpltDte']),
    clntCstmReqSignOffDte: convertDateForDatePicker(payload['clntCstmReqSignOffDte']),

    // Setup
    sendToJimDate: convertDateForDatePicker(payload['sendToJimDate']),
    clientCodeVerifyDate: convertDateForDatePicker(payload['clientCodeVerifyDate']),
    prePreCpltDate: convertDateForDatePicker(payload['prePreCpltDate']),
    preLoadActDate: convertDateForDatePicker(payload['preLoadActDate']),
    stageActDate: convertDateForDatePicker(payload['stageActDate']),
    grpStageCpltDate: convertDateForDatePicker(payload['grpStageCpltDate']),
    requiredMemFieldsActDate: convertDateForDatePicker(payload['requiredMemFieldsActDate']),
    cDDCmpltDte: convertDateForDatePicker(payload['cDDCmpltDte']),
    cDDCarLstCmpltDte: convertDateForDatePicker(payload['cDDCarLstCmpltDte']),
    xwalkProfileActDate: convertDateForDatePicker(payload['xwalkProfileActDate']),
    iDCardShellActDate: convertDateForDatePicker(payload['iDCardShellActDate']),
    outQActDate: convertDateForDatePicker(payload['outQActDate']),
    rJSCompDate: convertDateForDatePicker(payload['rJSCompDate']),
    crtCAGListCmpltDate: convertDateForDatePicker(payload['crtCAGListCmpltDate']),
    filTrkrOnBrdCmpltDte: convertDateForDatePicker(payload['filTrkrOnBrdCmpltDte']),
    fastlinkActDate: convertDateForDatePicker(payload['fastlinkActDate']),
    fastlinkGroupDate: convertDateForDatePicker(payload['fastlinkGroupDate']),
    sHRTIDSRCHActDate: convertDateForDatePicker(payload['sHRTIDSRCHActDate']),
    EZEConfigActDate: convertDateForDatePicker(payload['EZEConfigActDate']),
    cMFlagReviewDate: convertDateForDatePicker(payload['cMFlagReviewDate']),
    cMSignOffDate: convertDateForDatePicker(payload['cMSignOffDate']),

    // Testing Phase
    reqTstFileCompDate: convertDateForDatePicker(payload['reqTstFileCompDate']),
    tstFileRecCompDate: convertDateForDatePicker(payload['tstFileRecCompDate']),
    gISTestCmpltDate: convertDateForDatePicker(payload['gISTestCmpltDate']),
    prePreTstCpltDate: convertDateForDatePicker(payload['prePreTstCpltDate']),
    testVisualReviewCmpltDate: convertDateForDatePicker(payload['testVisualReviewCmpltDate']),
    loadTstFileCompDate: convertDateForDatePicker(payload['loadTstFileCompDate']),
    fourrxIDconfigverifydate: convertDateForDatePicker(payload['fourrxIDconfigverifydate']),
    clientTstSignOffCmpltDte: convertDateForDatePicker(payload['clientTstSignOffCmpltDte']),

    // Baseline phase
    vrfyPlanDesignQL: convertDateForDatePicker(payload['vrfyPlanDesignQL']),
    iCACoreIDOffset: convertDateForDatePicker(payload['iCACoreIDOffset']),
    iCACoreAltOffset: convertDateForDatePicker(payload['iCACoreAltOffset']),
    iCAXwalkIDField: convertDateForDatePicker(payload['iCAXwalkIDField']),
    iCAEligSettings: convertDateForDatePicker(payload['iCAEligSettings']),
    iCAMatchRule: convertDateForDatePicker(payload['iCAMatchRule']),
    iCANetworkSettings: convertDateForDatePicker(payload['iCANetworkSettings']),
    iCAPrimIDChoice: convertDateForDatePicker(payload['iCAPrimIDChoice']),
    vrfyCAGProcessFlags: convertDateForDatePicker(payload['vrfyCAGProcessFlags']),
    qLFlagCompDate: convertDateForDatePicker(payload['qLFlagCompDate']),
    eDWFlgCompDate: convertDateForDatePicker(payload['eDWFlgCompDate']),
    plansCompDate: convertDateForDatePicker(payload['plansCompDate']),
    bPGCompDate: convertDateForDatePicker(payload['bPGCompDate']),
    vrfyXwlkAvCmpltDte: convertDateForDatePicker(payload['vrfyXwlkAvCmpltDte']),
    iDCT4CasetoIFCmpltDte: convertDateForDatePicker(payload['iDCT4CasetoIFCmpltDte']),
    reconCmpltDate: convertDateForDatePicker(payload['reconCmpltDate']),
    compareCmpltDate: convertDateForDatePicker(payload['compareCmpltDate']),
    obtApprv2LoadCmpltDt: convertDateForDatePicker(payload['obtApprv2LoadCmpltDt']),
    baselineCompDate: convertDateForDatePicker(payload['baselineCompDate']),
    verifyBLLoadCompDate: convertDateForDatePicker(payload['verifyBLLoadCompDate']),
    fourrxscreentoannacmpltdate: convertDateForDatePicker(payload['fourrxscreentoannacmpltdate']),
    bL2QLCompDate: convertDateForDatePicker(payload['bL2QLCompDate']),

    // ID Cards Phase
    reqManCrdFileCmpltDte: convertDateForDatePicker(payload['reqManCrdFileCmpltDte']),
    qCManCrdFileCmpltDte: convertDateForDatePicker(payload['qCManCrdFileCmpltDte']),
    manCrdFileSntVendorCmpltDte: convertDateForDatePicker(payload['manCrdFileSntVendorCmpltDte']),
    qCCardProofCmpltDate: convertDateForDatePicker(payload['qCCardProofCmpltDate']),
    reqAutoRunCdCmpltDt: convertDateForDatePicker(payload['reqAutoRunCdCmpltDt']),

    // Go Live Phase
    claimsVerificationCompDate: convertDateForDatePicker(payload['claimsVerificationCompDate']),
    verifyLiveLoadCompDate: convertDateForDatePicker(payload['verifyLiveLoadCompDate'])
  };
};

export const getSignOffStatus = (state: State) => state !== undefined &&
state.checklists[state.selectedProjectIndex] !== undefined  && state.checklists[state.selectedProjectIndex].cMStatus;
export const getStatusDates = (state: State) => {
  if (state.checklists.length !== 0 && state.checklists[state.selectedProjectIndex] !== undefined
    && state.checklists[state.selectedProjectIndex].newDiscDueDate !== undefined
    && state.checklists[state.selectedProjectIndex].newDiscCmpltDate !== undefined
    && state.checklists[state.selectedProjectIndex].newSetupDueDate !== undefined
    && state.checklists[state.selectedProjectIndex].newSetupCmpltDate !== undefined
    && state.checklists[state.selectedProjectIndex].newTstDueDate !== undefined
    && state.checklists[state.selectedProjectIndex].newTstCmpltDate !== undefined
    && state.checklists[state.selectedProjectIndex].newBLDueDate !== undefined
    && state.checklists[state.selectedProjectIndex].newBLCmpltDate !== undefined
    && state.checklists[state.selectedProjectIndex].newIDCrdsDueDate !== undefined
    && state.checklists[state.selectedProjectIndex].newIDCrdsCmpltDate !== undefined
    ) {
  return {
    discoveryDueDate: state.checklists[state.selectedProjectIndex].newDiscDueDate,
    discoveryCompleteDate: state.checklists[state.selectedProjectIndex].newDiscCmpltDate,

    setupDueDate: state.checklists[state.selectedProjectIndex].newSetupDueDate,
    setupCompleteDate: state.checklists[state.selectedProjectIndex].newSetupCmpltDate,

    testingDueDate: state.checklists[state.selectedProjectIndex].newTstDueDate,
    testingCompleteDate: state.checklists[state.selectedProjectIndex].newTstCmpltDate,

    baselineDueDate: state.checklists[state.selectedProjectIndex].newBLDueDate,
    baselineCompleteDate: state.checklists[state.selectedProjectIndex].newBLCmpltDate,

    idCardsDueDate: state.checklists[state.selectedProjectIndex].newIDCrdsDueDate,
    idCardsCompleteDate: state.checklists[state.selectedProjectIndex].newIDCrdsCmpltDate,
  } as StatusDates;
}
};
