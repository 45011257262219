import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';


@Component({
  selector: 'app-plicarrier-delete-cell-renderer',
  styles: ['button { color: #767676 !important; }'],
  template: `
  <button mat-icon-button (click)="deleteRow()" [disabled]="readonly" *ngIf=!isDefault>
  <mat-icon svgIcon="trash--s"></mat-icon>
    </button>

  `,
})
export class PliCarrierDeleteCellRendererComponent implements ICellRendererAngularComp {
  public params: any;
  readonly: boolean;
  isDefault:boolean = false;
  constructor() {
  }
  agInit(params: any): void {
    this.params = params;
    if(this.params?.data?.type==='default'){
      this.isDefault = true;
    }
    this.readonly = this.params?.context?.providedInfo?.questionareDetail?.readOnly;
  }

  
  refresh(params: any): boolean {
    return false;
  }
  deleteRow() {
    this.params.api.getRenderedNodes().forEach(res =>{
      if(res.rowIndex === this.params.node.rowIndex) {
        if(res.data.id)
        this.params.context.providedInfo.deleteRows.push(res.data.id);
        this.params.context.parentRef.readOnly = false;
      }
    } );
  
    
    let gridRowData = this.params.api.getRenderedNodes().filter(res => res.rowIndex !== this.params.node.rowIndex);
    let data = gridRowData.map(res => res.data);
    this.params.api.setRowData(data);
  }
}
