<div style="display: flex; align-items: center; height: 36px; margin-top: 50px; justify-content: space-between">
  <div style="display: flex;flex-direction: column">
    <h1 id="right-pane-header">EPR History</h1>
  </div>
</div>

<form [formGroup]="eprHistoryForm" (submit)="onSubmit()">
  <div class="row" style="margin-top: 25px;">
    <mat-form-field id="Client-Name">
      <input matInput type="text" formControlName="clientName" placeholder="Client Name">
  </mat-form-field>
  <mat-form-field id="Carrier">
    <input matInput type="text" formControlName="carrier" placeholder="Carrier">
</mat-form-field>
<mat-form-field id="PLI">
  <input matInput type="text" formControlName="pliCode" placeholder="PLI">
</mat-form-field>
  </div>
  <!-- <div class="dateAdjust"> -->
    <div class="row">
      <mat-form-field id="from-date" style="width:180px">
        <mat-label>From Date</mat-label>
        <input matInput [matDatepicker]="datepickerFromDate" formControlName="startDate" required>
        <mat-datepicker-toggle matSuffix [for]="datepickerFromDate"></mat-datepicker-toggle>
        <mat-datepicker #datepickerFromDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field id="to-date" style="width:180px">
        <mat-label>To Date</mat-label>
        <input matInput [matDatepicker]="datepickerToDate" formControlName="endDate" required>
        <mat-datepicker-toggle matSuffix [for]="datepickerToDate"></mat-datepicker-toggle>
        <mat-datepicker #datepickerToDate></mat-datepicker>
    </mat-form-field>
      <mat-form-field class="rte-assignedEC">
        <mat-label>Assigned EC</mat-label>
        <mat-select  formControlName="assignedEC" multiple >
          <mat-option *ngFor="let user of (displayedUsers$ | async)" [value]="user">{{user.firstName}} {{user.lastName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
     
      <mat-form-field>
        <mat-label>User</mat-label>
        <mat-select formControlName="user" multiple>
          <mat-option *ngFor="let user of (displayedUsers$ | async)" [value]="user">{{user.firstName}} {{user.lastName}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Section</mat-label>
        <mat-select formControlName="section" multiple>
            <mat-option *ngFor="let section of sectionList" [value]="section">
              {{section}}
            </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

   <div class="row">
    <mat-form-field id="SubSection">
      <input matInput type="text" formControlName="subSection" placeholder="Sub Section">
  </mat-form-field>
  <mat-form-field id="Field">
    <input matInput type="text" formControlName="field" placeholder="Field">
</mat-form-field>
  <div class="button-align">
    <div>
        <button id="cancel-edit-btn" class="cvs-btn-primary" [disabled]="eprHistoryForm.invalid" type="submit">
            <mat-icon>save</mat-icon>
            Submit
        </button>
    </div>
  </div>
   </div>
  <!-- </div> -->
</form>

<!-- <div class="search" style="display: flex;">
  <div style="display: flex;flex-direction: column">
    <mat-form-field id="filter-text-box">
      <input matInput type="text" placeholder="Search History" (input)="filterTables($event.target.value)"/>
      <mat-icon matSuffix style="float: right">search History</mat-icon>
    </mat-form-field>
  </div> -->
 
  <div class="exportbuttonhistory" style="display: flex;justify-content: space-between;">
    <div class="search">
      <mat-form-field id="filter-text-box" style="justify-content: flex-start;">
        <input matInput type="text" placeholder="Search History" [disabled]="dataLoaded" (input)="filterTables($event.target.value)"/>
        <mat-icon matSuffix style="float: right">search History</mat-icon>
      </mat-form-field>
    </div>
  <div class="Export" style="padding-top: 20px;cursor:pointer;" (click)="exportView(gridEPRMetric.gridOptions, 'eprhistory')">
    <mat-icon svgIcon="upload-arrow--s"  id="eprHistoryExportBtn">
      Export History
    </mat-icon>
    <span class="export-txt" > Export </span>
  </div>
    
  </div>
<!-- </div> -->
<div>
  <span *ngIf="moreDataErrorGrid"
                        class="more-data-error">{{moreDataErrorGrid}}
                      </span>
  <ag-grid-angular  class="ag-theme-alpine" #gridEPRMetric [ngStyle]="{'height': 500 + 'px', 'margin-top': 10 + 'px' }"
  id="gridEPRMetric" [suppressScrollONewData]="true" [rowData]="dataRows" (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>



