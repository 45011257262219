export enum ProjectType {
  NEW_IMPLEMENTATION = 'New Implementation',
  ID_CONVERSION = 'ID Conversion',
  ADD_CHANGE_ALT_ID = 'Add/Change Alt ID',
  NEW_CAG_STRUCTURE = 'New CAG Structure',
  CDH_ADD = 'CDH Add',
  EXCHANGE_ADD = 'Exchange Add',
  FORMAT_CHANGE = 'Format Change',
  C2C = 'C2C',
  MAP_ENHANCEMENT_IT = 'Map Enhancement (IT)',
  MAP_ENHANCEMENT_SECURITY = 'Map Enhancement (Security)',
  RECARD_ONLY = 'Recard Only',
  VENDOR_CHANGE = 'Vendor Change',
  MOOP = 'MOOP',
  MOOP_PLUS_OTHER = 'MOOP Plus Other',
  SSI_NEW_IMP = 'SSI New Imp',
  OE_PROJECT = 'OE Project',
  ONE_TIME_LOAD = 'One-Time Load',
  OTHER_FILE = 'Other File',
  TERM_PROJECT = 'Term Project',
  MISC_PROJECT = 'Misc Project'
}

export const projectTypeChecklistMetadata = {
  EZEConfigActDate: false,
  bL2QLCompDate: false,
bPGCompDate: false,
bSRActDate: false,
baselineCompDate: false,
cDDCarLstCmpltDte: false,
cDDCmpltDte: false,
cMFlagReviewDate: false,
cMSignOffDate: false,
cOLAActDate: false,
claimsVerificationCompDate: false,
clientCodeVerifyDate: false,
clientTstSignOffCmpltDte: false,
clntCstmReqSignOffDte: false,
compareCmpltDate: false,
crtCAGListCmpltDate: false,
discActDate: false,
eDWFlgCompDate: false,
fTPFormLoadRptsCmpltDte: false,
fastlinkActDate: false,
fastlinkGroupDate: false,
filTrkrOnBrdCmpltDte: false,
fileLayoutActDate: false,
fourrxIDconfigverifydate: false,
fourrxscreentoannacmpltdate: false,
gISTestCmpltDate: false,
grpPLISetup: false,
grpStageCpltDate: false,
hEATActDate: false,
iCACoreAltOffset: false,
iCACoreIDOffset: false,
iCAEligSettings: false,
iCAMatchRule: false,
iCANetworkSettings: false,
iCAPrimIDChoice: false,
iCAXwalkIDField: false,
iDCT4CasetoIFCmpltDte: false,
iDCardShellActDate: false,
id: null,
libraryActDate: false,
loadTstFileCompDate: false,
manCrdFileSntVendorCmpltDte: false,
obtApprv2LoadCmpltDt: false,
outQActDate: false,
pLIActDate: false,
plansCompDate: false,
preLoadActDate: false,
prePreCpltDate: false,
prePreTstCpltDate: false,
qCCardProofCmpltDate: false,
qCManCrdFileCmpltDte: false,
qLFlagCompDate: false,
rJSCompDate: false,
reconCmpltDate: false,
reqAutoRunCdCmpltDt: false,
reqManCrdFileCmpltDte: false,
reqTstFileCompDate: false,
requiredMemFieldsActDate: false,
sHRTIDSRCHActDate: false,
sendToJimDate: false,
stageActDate: false,
testVisualReviewCmpltDate:false,
tstFileRecCompDate: false,
verifyBLLoadCompDate: false,
verifyLiveLoadCompDate: false,
vrfyCAGProcessFlags: false,
vrfyPlanDesignQL: false,
vrfyXwlkAvCmpltDte: false,
xwalkProfileActDate: false
}
