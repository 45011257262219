import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from './../../environments/environment';
import { CVSHttpClient } from 'angular-component-library';

@Injectable({
  providedIn: 'root'
})
export class AdminAlertTypeService {
  addNewAdminAlertRow$ = new BehaviorSubject(null);
  saveNewAdminRecords$ = new BehaviorSubject(false);

  constructor(private http: CVSHttpClient) { }

  getAllAdminAlertTypes(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/alertTypes/get`);
  }

  onMessage(): Observable<any> {
    return this.addNewAdminAlertRow$.asObservable();
  }

  saveAdminAlertTypeRow(request: any): Observable<any> {

    return this.http.post(`${environment.baseUrl}/alertTypes/save`, request);
  }

  deleteAdminAlertTypeRow(request: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/alertTypes/delete`, request);
  }

  addNewAlertTypeRow(newRow: any) {
    this.addNewAdminAlertRow$.next(newRow);
  }
}
