import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import * as fromRoot from '../reducers';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {getEditMode} from '../reducers/filetracker.reducer';

@Component({
  selector: 'app-download-btn-cell-renderer',
  template: `
    <button mat-icon-button (click)="btnClickedHandler()" *ngIf="toShow">
      <mat-icon svgIcon="download-arrow--s" style="width: 17px;height: 17px;"></mat-icon>
    </button>

  `,
})
export class DownloadBtnCellRendererComponent implements ICellRendererAngularComp {
  public params: any;
  toShow = false;

  constructor() {
  }
  agInit(params: any): void {
    this.params = params;
    this.toShow = this.params.data.download;
  }

  btnClickedHandler() {
    this.params.context.componentParent.downloadExcel(this.params.node, this.params.column.columnId);

    }

  refresh(params: any): boolean {
    return false;
  }



}
