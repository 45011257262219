import {Filetracker} from '../models/Filetracker';
import {
  Actions,
  CLEAR_FILETRACKER_DATA,
  DELETE_FILETRACKER,
  EDIT_FILETRACKER,
  LOAD_ALL_FILETRACKER_SUCCESS,
  LOAD_CONTACT_SUCCESS,
  LOAD_DATA,
  LOAD_FILETRACKER,
  LOAD_FILETRACKER_SUCCESS,
  SAVE_CONTACT_SUCCESS,
  SAVE_FILETRACKER_SUCCESS,
  EDIT_MODE
} from '../actions/filetracker.action';
import {Contact} from '../models/Contact';

export interface State {
  allFiletrackers: Filetracker[];
  selectedFiletrackerId: number;
  selectedFiletracker: Filetracker;
  contact: Contact[];
  fileTracker: any[];
  isEdit: boolean;
}

export const initialState: State = {
  allFiletrackers: [],
  selectedFiletrackerId: undefined,
  selectedFiletracker: {},
  contact: [],
  fileTracker: [],
  isEdit: false
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case LOAD_ALL_FILETRACKER_SUCCESS:
      return {...state, allFiletrackers: action.payload as Filetracker[]};

    case LOAD_FILETRACKER:
      return {...state };

    case LOAD_DATA:
      state.fileTracker = action.payload as any;
      return {...state };

    case LOAD_FILETRACKER_SUCCESS:
      const allFiletrackers = (Array.isArray(action.payload)) ? action.payload : [action.payload];
      return {...state, selectedFiletracker: action.payload as Filetracker, allFiletrackers: allFiletrackers as Filetracker[]};

    case SAVE_FILETRACKER_SUCCESS:
      const newFiletracker = state.allFiletrackers.concat(action.payload);
      newFiletracker.sort((a, b) => {
        // if (a.id < b.id) {
        //   return -1;
        // }
        // if (a.id > b.id) {
        //   return 1;
        // }
        return 0;
      });
      return {...state, allFiletrackers: newFiletracker};

    case LOAD_CONTACT_SUCCESS:
      const contacts = (Array.isArray(action.payload)) ? action.payload : [action.payload];
      return {...state,  contact: contacts as Contact[]};


    case SAVE_CONTACT_SUCCESS:
      const newContact = state.contact.concat(action.payload);
      newContact.sort((a, b) => {
        // if (a.id < b.id) {
        //   return -1;
        // }
        // if (a.id > b.id) {
        //   return 1;
        // }
        return 0;
      });
      return {...state, contact: newContact};

    case EDIT_FILETRACKER:
      return {...state, selectedFiletrackerId: action.payload.id};

    case CLEAR_FILETRACKER_DATA:
      return initialState;

    case DELETE_FILETRACKER:
      return {
        ...state
      };
    case EDIT_MODE:
      state.isEdit = action.payload as any;
      return {...state };

    default:
      return state;
  }
}
export const getAllFiletrackers = (state: State) => state && state.allFiletrackers;
export const getSelectedFiletracker = (state: State) => state &&  state.selectedFiletracker;
export const getContact = (state: State) => state && state.contact;
export const getEditMode = (state: State) => state && state.isEdit;

