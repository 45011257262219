import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormArray, UntypedFormArray, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileLayoutDialogComponent } from 'src/app/admin/file-layout/file-layout-dialog/file-layout-dialog.component';
import { RteSuspenseFormService } from '../rte-suspense-form.service';
import { OPTIONS } from '../rte-suspense-form.service';
@Component({
  selector: 'app-suspense-schedule',
  templateUrl: './suspense-schedule.component.html',
  styleUrls: ['./suspense-schedule.component.scss']
})
export class SuspenseScheduleComponent implements OnInit {
  @Output() scheduleData = new EventEmitter<any>();
  providedInfo: any;
  occurenceForm: any;
  options: any;
  currentOccurence: any = "Daily";
  daysList: any[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  dailyOnOptionList = ["Every Day(s)", "Every Weekday"];
  weeklyOnOptionList = ["1 Week", "2 Weeks", "3 Weeks", "4 Weeks"];
  monthlynYearlyOnOptionList = ["Every", "First", "Second", "Third", "Fourth", "Last"];
  weeklyDayOptionList = ["Day",
    "Weekday",
    "Weekend Day",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"];
  yearlyOfOptionList = ["January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"];
  userEmail: string;
  weeklySelectedDayOptions: any[] = [];
  constructor(private dialogRef: MatDialogRef<FileLayoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private formService: RteSuspenseFormService
  ) {
    this.providedInfo = data;
  }

  public get formOnValue(): string {
    return this.occurenceForm?.get('on')?.value;
  }

  ngOnInit(): void {
    this.userEmail = this.providedInfo.userEmail;
    this.loadData();
  }

  isCheckMark(option) {
    return this.providedInfo.rowData?.scheduleRecord?.weekDay?.includes(option)
  }

  loadData() {
    this.options = OPTIONS;
    if (this.providedInfo.rowData?.scheduleRecord?.occuranceType) {
      this.currentOccurence = this.providedInfo.rowData?.scheduleRecord?.occuranceType;
    }
    this.occurenceForm = this.formService.generateOccurenceDepenedentScheduleForm(this.currentOccurence, this.providedInfo);
    setTimeout(() => {
      if (this.providedInfo?.rowData?.recipients) {
        const mappedRecipent = this.providedInfo.rowData?.recipients?.split(',').map(itm => ({ recipientName: itm }));
        for (let index = 0; index < mappedRecipent.length - 1; index++) {
          this.occurenceForm.get('recipient').push(this.formService.createRecipient(null))
        }
        this.occurenceForm.get('recipient').patchValue(mappedRecipent)
      }
      if (this.providedInfo.rowData?.scheduleRecord?.weekDay?.length > 0  ) {
        this.weeklySelectedDayOptions = this.providedInfo.rowData?.scheduleRecord?.weekDay;
        this.occurenceForm.get('on').patchValue(true);
      } else {
        if(this.providedInfo.rowData?.scheduleRecord?.occuranceType === "Weekly"){
          this.occurenceForm.get('on').patchValue(null);
        }
       
      }
    }, 250);
  }

  onCheckboxTick(val, day) {
    if (val) {
      this.weeklySelectedDayOptions.push(day)
    } else {
      this.weeklySelectedDayOptions = this.weeklySelectedDayOptions.filter(itm => itm !== day);
    }
    if (this.weeklySelectedDayOptions?.length > 0 ) {
      this.occurenceForm.get('on').patchValue(true);
    } else {
      this.occurenceForm.get('on').patchValue(null);
    }
  }

  onOccurenceChange(event) {
    const value = event?.value;
    this.currentOccurence = value;
    if (value) {
      this.occurenceForm = this.formService.generateOccurenceDepenedentScheduleForm(value, this.providedInfo);
    }
  }

  public get recipients(): UntypedFormArray {
    return this.occurenceForm.get('recipient') as UntypedFormArray;
  }

  addRecipient() {
    this.occurenceForm.get('recipient').push(this.formService.createRecipient(null))
  }

  deleteRecipient(index) {
    this.recipients.removeAt(index)
  }

  close() {
    this.dialogRef.close();
  }

  dailyOnValueChange(e) {
    const control = this.occurenceForm.get('day');

    if (this.formOnValue !== 'Every Weekday') {
      control.setValidators([Validators.required]);
    } else {
      control.clearValidators();
    }
    control.updateValueAndValidity({ emitEvent: false });
  }

  getScheduleObj() {
    let form: any = {};

    switch (this.currentOccurence) {
      case OPTIONS.occurence[0]: {
        form = {
          dayFrequency: this.occurenceForm.value?.on,
          numOfDays: this.occurenceForm.value?.day ? this.occurenceForm.value?.day : null,
          scheduleTime: this.occurenceForm.value?.time,
        }
      }
        break;
      case OPTIONS.occurence[1]: {
        form = {
          weekFrequency: this.occurenceForm.value?.weeks,
          weekDay: this.weeklySelectedDayOptions,
          scheduleTime: this.occurenceForm.value?.time,
        }
      } break;
      case OPTIONS.occurence[2]: {
        form = {
          dayOfMonth: this.occurenceForm.value?.on,
          monthDate: this.occurenceForm.value?.on === "Every" ? this.occurenceForm.value?.day : null,
          weekDayForMonth: this.occurenceForm.value?.on !== "Every" ? this.occurenceForm.value?.day : null,
          monthlyFrequency: this.occurenceForm.value?.months,
          scheduleTime: this.occurenceForm.value?.time,
        }
      } break;
      case OPTIONS.occurence[3]: {
        form = {
          dayOfMonth: this.occurenceForm.value?.on,
          monthDate: this.occurenceForm.value?.on === "Every" ? this.occurenceForm.value?.day : null,
          weekDayForMonth: this.occurenceForm.value?.on !== "Every" ? this.occurenceForm.value?.day : null,
          frequencyOfYear: this.occurenceForm.value?.months,
          scheduleTime: this.occurenceForm.value?.time,
        }
      } break;
    }

    const dummyObj = {
      "occuranceType": this.currentOccurence,
      "scheduleId": this.providedInfo?.rowData?.scheduleRecord?.scheduleId ? this.providedInfo?.rowData?.scheduleRecord?.scheduleId : null,
      "id": this.providedInfo?.rowData?.scheduleRecord?.id ? this.providedInfo?.rowData?.scheduleRecord?.id : null,
      "dayFrequency": null,
      "numOfDays": null,
      "weekFrequency": null,
      "weekDay": null,
      "dayOfMonth": null,
      "monthDate": null,
      "weekDayForMonth": null,
      "scheduleTime": null,
      "nextScheduleDate": null,
      "monthlyFrequency": null,
      "frequencyOfYear": null
    }

    form.scheduleTime = form.scheduleTime?.split(':')[0]

    return { ...dummyObj, ...form };
  }

  save() {
    let formValue: any = {
      "scheduleId": this.providedInfo?.rowData?.scheduleId ? this.providedInfo?.rowData?.scheduleId : null,
      "report": "Suspense",
      "reportName": this.occurenceForm.value.reportName,
      "recipients": this.occurenceForm.get('recipient').controls?.map(itm => itm?.value?.recipientName).join(','),
      "accountValue": this.providedInfo?.rowData?.accountValue,
      "groupValue": this.providedInfo?.rowData?.groupValue,
      "carrierToValue": this.providedInfo?.rowData?.carrierToValue,
      "accountToValue": this.providedInfo?.rowData?.accountToValue,
      "groupToValue": this.providedInfo?.rowData?.groupToValue,
    };
    formValue.scheduleRecord = this.getScheduleObj();
    this.scheduleData.emit({
      formData: formValue
    })
    this.dialogRef.close();
  }
}
