import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {CVSHttpClient} from 'angular-component-library';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MappingRecTypeService {

  baseURL = environment.baseCustomMappingUrl;
  constructor(private http: CVSHttpClient) { }

  getExistingRecTypes(documentId) {
    return this.http.get( `${this.baseURL}/mapping/recTypes/getRecTypesMap/${documentId}`);
  }

  getRecType(recType) {
    const [rec, group] = recType.split('-');
    if (rec && group) {
      return this.http.get( `${this.baseURL}/mapping/recTypes/getRecTypes/${rec}/${group}`);
    }
    return this.http.get( `${this.baseURL}/mapping/recTypes/getRecTypes/${rec}`);
  }

  saveRecTypes(documentID, pliCode, clientCode, recordTypeData) {
    return this.http.post( `${this.baseURL}/mapping/recTypes/saveRecTypesMapAll`, recordTypeData);
  }

  searchLogicCode(logicCode) {
    return this.http.get(`${this.baseURL}/mapping/recTypes/getLogicCode/${logicCode}`);
  }

  getAllLogicCodes(fieldName) {
    const fieldNameObj = {
      rxClaimField: fieldName
    };
    return this.http.post(`${this.baseURL}/mapping/recTypes/getLogicCodesAll`, fieldNameObj);
  }

  addLogicCode(newLogicCode) {
    return this.http.post(`${this.baseURL}/mapping/recTypes/addLogicCode`, newLogicCode);
  }

  getNewLogicCodeSequence(fieldNumber) {
    return this.http.get(`${this.baseURL}/mapping/recTypes/getNewLogicCode/${fieldNumber}`);
  }

}
