import {Component, OnInit, ViewChild} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

interface ToolTipParams extends ICellRendererParams {
  lineBreak?: boolean;
  toolTipArray?: string[];
  toolTip?: string;
}


@Component({
  selector: 'app-tool-tip',
  templateUrl: './tool-tip.component.html',
  // styleUrls: ['./tool-tip.component.css']
})
export class ToolTipComponent implements ICellRendererAngularComp {

  @ViewChild('tooltip') tooltip : any;
  public data: any;
  public toolTip: string;

  disableTooltip: boolean = false;

  constructor() { }

  agInit(params: any): void {
    this.toolTip = params.value;
  }

  refresh(params: any): boolean {
    return true;
  }

  show(tooltip: any) {
    this.disableTooltip = false;
    setTimeout(()=>{
      tooltip.show()
    });
  }
}
