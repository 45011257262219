import { Injectable } from '@angular/core';
import { CVSHttpClient } from 'angular-component-library';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RteSuspenseService {

  constructor(private http: CVSHttpClient) { }

  getSuspenseRecords(payload: any) {
    return this.http.post(`${environment.suspenseUrl}/suspense/getsuspenserecords`, payload);
  }


  getScheduleEventsRecords(userId: any, isAdmin :boolean) {
    if(isAdmin){
      return this.http.get(`${environment.suspenseUrl}/suspense/getUserSchedulesForAdmin`);
    }
    return this.http.get(`${environment.suspenseUrl}/suspense/getCriteriaByUserId/${userId}`);
  }
    
  deleteSuspenseRecords(payload: any) {
    return this.http.put(`${environment.suspenseUrl}/suspense/delete`, payload);
  }

  createSchedule(payload: any) {
    return this.http.post(`${environment.suspenseUrl}/suspense/addschedule`, payload);
  }

  deleteScheduleEvent(scheduleId: any) {
    return this.http.delete(`${environment.suspenseUrl}/suspense/deleteSchedule?scheduleId=${scheduleId}`);
  }

  setInactiveScheduleEvent(scheduleId: any, status: any) {
    return this.http.get(`${environment.suspenseUrl}/suspense/updateReportScheduleStatus?scheduleId=${scheduleId}&status=${status}`);
  }

}
