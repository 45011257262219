import {Actions, LOAD_PROJECT_TRACKER_SUCCESS, UPDATE_PROJECT_TRACKER} from '../actions/project-tracker.action';
import {convertDateForDatePicker} from '../helpers/DateTimeHelpers';
import {ADD_PROJECT_TRACKER, CLEAR_EPR_DATA, COPY_EPR, DELETE_PROJECT_TRACKER} from '../actions/epr.action';
import {removeItemInArray, replaceItemsInArray} from '../helpers/NgRxHelpers';

export class ProjectTrackerStateType {
  prjStartDte: string;
  projectType: string;
  prjPhase: string;
  prjQCDte: string;
  prjQcBy: number;
  prjGoLiveDte: string;
  prjctSFDCCase: string;
  prjCardStatus: string;
  prjNotes: string;
  internalProjectType : boolean
}

export interface State {
  projects: ProjectTrackerStateType[];
  projectsToDelete: number[];
}

export const initialState: State = {
  projects: [
    {
      prjStartDte: '',
      projectType: '',
      prjPhase: '',
      prjQCDte: '',
      prjQcBy: null,
      prjGoLiveDte: '',
      prjctSFDCCase: '',
      prjCardStatus: '',
      prjNotes: '',
      internalProjectType: false
    }
  ],
  projectsToDelete: []
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {

    case ADD_PROJECT_TRACKER:
      return {
        ...state,
        projects: [action.payload.newProjectTracker, ...state.projects]
      };

    case DELETE_PROJECT_TRACKER:
      if (action.id) {
        return {
          ...state,
          projects: removeItemInArray(state.projects, action.iteration),
          projectsToDelete: [...state.projectsToDelete, action.id]
        };
      } else {
        return {
          ...state,
          projects: removeItemInArray(state.projects, action.iteration)
        };
      }

    case UPDATE_PROJECT_TRACKER:
      return {
        ...state,
        projects: replaceItemsInArray(state.projects, action.payload.selectedProjectIndex, action.payload.value)
      };

    case LOAD_PROJECT_TRACKER_SUCCESS:
      return {
        projects: action.payload.map(projectFromBackend => {
          return {
            ...projectFromBackend,
            checklistEntity: undefined,
            testingEntity: undefined,
            prjStartDte: convertDateForDatePicker(projectFromBackend.prjStartDte),
            prjQCDte: convertDateForDatePicker(projectFromBackend.prjQCDte),
            prjGoLiveDte: convertDateForDatePicker(projectFromBackend.prjGoLiveDte)
          };
        }),
        projectsToDelete: []
      };

    case CLEAR_EPR_DATA:
      return initialState;

    case COPY_EPR:
      return initialState;

    default:
      return state;
  }
}

export const getProjectTrackersDisplayNames = (state: State) => {
  // @ts-ignore
  return state.projects.map(project => new Date(project.prjStartDte).yymmdd() + ' ' + project.projectType);
};

export const getNumberOfProjectTrackers = (state: State) => {
  return state.projects.length;
};

export const getCurrentGoLiveDate = (state: State, index: number) => {
  if (index < state.projects.length) {
    return state.projects[index].prjGoLiveDte;
  } else {
    return null;
  }
};

export const getPhase = (state: State, index: number) => {
  if (index < state.projects.length) {
    return state.projects[index].prjPhase;
  } else {
    return null;
  }
};

export const getType = (state: State, index: number) => {
  if (index < state.projects.length) {
    return state.projects[index].projectType;
  } else {
    return null;
  }
};

export const getProjectIterations = state => state.projects;
