import {FormFieldConfig, InputType} from '../../models/FormFieldConfig';
import {Permission} from '../../models/Authorization';

export const testResultsFormConfig = [
  {
    label: 'Date',
    controlName: 'testIterationDate',
    inputType: InputType.DATE
  },
  {
    label: 'File Type',
    controlName: 'testFileType',
    inputType: InputType.DROPDOWN,
    options: ['', 'Member', 'Group', 'ID Card', 'Other']
  },
  {
    label: 'File Name',
    controlName: 'testIterationFileName',
    inputType: InputType.TEXT
  },
  {
    label: 'File Volume',
    controlName: 'testFileVolume',
    inputType: InputType.TEXT
  },
  {
    label: 'Tester',
    controlName: 'tester',
    inputType: InputType.USERS_DROPDOWN,
    permissions: [Permission.EC, Permission.TESTER_AUDITOR, Permission.ADMIN]
  }, {
    label: 'Testing Case # (SFDC Case #)',
    controlName: 'iterationTstCaseSFDC',
    inputType: InputType.TEXT
  }, {
    label: 'Overall Status',
    controlName: 'testIterationStatus',
    inputType: InputType.DROPDOWN,
    options: ['', 'Fail', 'Insufficient Data', 'Pass'],
    hint: 'hintText'
  }, {
    label: 'Client/Vendor Approval',
    controlName: 'tstCltAprvl',
    inputType: InputType.DROPDOWN,
    options: ['', 'Yes', 'No'],
  }, {
    label: 'Methodology',
    controlName: 'testIterationMethodology',
    inputType: InputType.CHIP,
    options: ['None', 'Visual', 'Informatica', 'ACE', 'PROD', 'Other']
  }
] as FormFieldConfig[];

export const testCriteria = [
  { // dropdown, dropdown, and textbox
    label: 'Header / Trailer Record',
    baseControlName: 'tCHeaderTrailer'
  },
  { // dropdown, dropdown, and textbox
    label: 'End of Record',
    baseControlName: 'tCEndofRecord'
  },
  { // dropdown, dropdown, and textbox
    label: 'CAG',
    baseControlName: 'tCCAG'
  },
  { // dropdown, dropdown, and textbox
    label: 'ID / PC\'s',
    baseControlName: 'tCIDPC'
  },
  { // dropdown, dropdown, and textbox
    label: 'Family Type',
    baseControlName: 'tCFamType'
  },
  { // dropdown, dropdown, and textbox
    label: 'Dates',
    baseControlName: 'tCDates'
  },
  { // dropdown, dropdown, and textbox
    label: 'Numeric Field',
    baseControlName: 'tCNumericField'
  },
  { // dropdown, dropdown, and textbox
    label: 'Special Character / Lower Case',
    baseControlName: 'tCSpecialCharLwrCase'
  },
  { // dropdown, dropdown, and textbox
    label: 'Address',
    baseControlName: 'tCAddr'
  },
  { // dropdown, dropdown, and textbox
    label: 'Demographics',
    baseControlName: 'tCDemographic'
  },
  { // dropdown, dropdown, and textbox
    label: 'Language Code',
    baseControlName: 'tCLanguage'
  },
  { // dropdown, dropdown, and textbox
    label: 'Other Eligibility',
    baseControlName: 'tCOtherElig'
  },
  { // dropdown, dropdown, and textbox
    label: 'Type 4 Records',
    baseControlName: 'tCType4'
  },
  { // dropdown, dropdown, and textbox
    label: 'Type 5 Records',
    baseControlName: 'tCType5'
  },
  { // dropdown, dropdown, and textbox
    label: 'COB/Alt Ins/STCOB',
    baseControlName: 'tCCOB'
  },
  { // dropdown, dropdown, and textbox
    label: 'Exchange',
    baseControlName: 'tCExchange'
  },
  { // dropdown, dropdown, and textbox
    label: 'Shared ID',
    baseControlName: 'tCIntegAccums'
  },
  { // dropdown, dropdown, and textbox
    label: 'Med-B/RDS',
    baseControlName: 'tCMedBRDS'
  },
  { // dropdown, dropdown, and textbox
    label: 'Med-D',
    baseControlName: 'tCMedD'
  },
  { // dropdown, dropdown, and textbox
    label: 'Member Level Plan',
    baseControlName: 'tCMbrLvlPlan'
  }
] as TestCriteriaRow[];

export interface TestCriteriaRow {
  label: string;
  baseControlName: string;
}
