
<ng-container *ngIf="!isDelete">
    <h2>{{ title }}</h2>
    <p class="msg">Input the following to {{ dataValues?.actionType?.mode}} line item</p>
    <form [formGroup]="addEditItem">
    <mat-dialog-content class="mat-typography">
       <mat-form-field>
        <mat-label>Code</mat-label>
        <input matInput formControlName="code" (keyup)="checkCode($event)">
        <mat-error *ngIf="addEditItem?.controls['code'].errors?.required">Code is required</mat-error>
      </mat-form-field>
      <span style="display: flex; align-items: center; margin-bottom: 20px;margin-top: -30px;" *ngIf="codeError">
        <mat-icon style="color: red;" svgIcon="error-f--s"></mat-icon>
        <mat-hint class="mat-error" style="color: rgb(204, 0, 0)" *ngIf="codeError">This code is already in use.Pick a unique code</mat-hint>
      </span>
      <mat-form-field>
        <mat-label>Type</mat-label>
        <mat-select formControlName="reportType">
          <mat-option *ngFor="let option of reportingMessageCodesTypes" [value]="option">
            {{option.message}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <textarea matInput (keyup)="findcharacterCount($event)" formControlName="description"></textarea>
      </mat-form-field>
      <span [ngClass]="characterCount < 61 ? 'character-count-gray': 'character-count-red'"> Character Count: <span>{{ characterCount }}</span></span>    </mat-dialog-content>
    <mat-dialog-actions class="cvs-theme" align="end">
      <button mat-button class="cvs-btn-primary"  [disabled]="codeError || addEditItem.invalid" (click)="save()" cdkFocusInitial>Save</button>
      <button mat-button mat-dialog-close class="btn-cancel">Cancel</button>
    </mat-dialog-actions>
    </form>
   </ng-container>

  <ng-container *ngIf="isDelete">
    <h2>Delete Row</h2>
    <mat-dialog-content class="mat-typography">
       <p class="msg">Are you sure you want to delete this row ? </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close class="btn-cancel">Cancel</button>
      <button mat-button (click)="deleteRecord()" class="cvs-btn-primary" cdkFocusInitial>Delete</button>
    </mat-dialog-actions>
   </ng-container>
