<div class="" [ngStyle]="{'width': '25rem'}">
    <h2 mat-dialog-title style="padding-bottom: 1rem; border-bottom :2px solid lightgray">Add New Parameter</h2>
    <ng-container>
        <ng-container [formGroup]="form">
            <mat-dialog-content class="">
                <div class="row">
                    <mat-form-field class="col-md-12 custom-select w-100">
                        <mat-label>Parameter Type</mat-label>
                        <mat-select formControlName="paramType">
                            <mat-option [value]="paramType" *ngFor="let paramType of paramTypeList">{{
                                paramType}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-12 w-100">
                        <input matInput placeholder="Parameter" formControlName="parameter">
                    </mat-form-field>
                    <mat-form-field class="col-md-12 ml-6 custom-select w-100">
                        <mat-label>File Type</mat-label>
                        <mat-select formControlName="fileType">
                            <mat-option [value]="fileType" *ngFor="let fileType of fileTypeList">{{
                                fileType}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions class="row mat-bottom-btns">
                <button class="mat-raised-button col-4 save-button can-btn" (click)="close()">Cancel</button>
                <button class="mat-raised-button mat-primary col-4 save-button sav-btn" [disabled]= "form.invalid" (click)="save($event)">Add New Parameter</button>
            </mat-dialog-actions>
        </ng-container>
    </ng-container>
</div>
