import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AgGridAngular} from 'ag-grid-angular';
import {
  sharedColumnDefs,
} from './ChecklistSettingsColumnDefs';
import {
  baselineTasksConfig,
  discoveryTasksConfig, goLiveConfig, idCardsConfig,
  integratedFeedsTasksConfig,
  setupTasksConfig,
  testTasksConfig
} from '../../../epr/checklist/ChecklistTasksConfig';
import {Subscription} from 'rxjs';
import {ChecklistSettingsService} from '../../../services/checklist-settings.service';
import {CVSAlertService, CVSAlertType} from 'angular-component-library';
import { CellEditingStoppedEvent, GridReadyEvent } from '@ag-grid-community/core';

@Component({
  selector: 'app-checklist-metadata',
  templateUrl: './checklist-settings.component.html',
  styleUrls: ['./checklist-settings.component.scss']
})
export class ChecklistSettingsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('discoveryTasksGrid') discoveryTasksGrid: AgGridAngular;
  @ViewChild('setupTasksGrid') setupTasksGrid: AgGridAngular;
  @ViewChild('testTasksGrid') testTasksGrid: AgGridAngular;
  @ViewChild('iFeedsTasksGrid') iFeedsTasksGrid: AgGridAngular;
  @ViewChild('baselineTasksGrid') baselineTasksGrid: AgGridAngular;
  @ViewChild('idCardsGrid') idCardsGrid: AgGridAngular;
  @ViewChild('goLiveGrid') goLiveGrid: AgGridAngular;

  grids: AgGridAngular[] = [];

  DISCOVERY_INDEX = 0;
  SETUP_INDEX = 1;
  TEST_INDEX = 2;
  IFEEDS_INDEX = 3;
  BASELINE_INDEX = 4;
  IDCARDS_INDEX = 5;
  GOLIVE_INDEX = 6;


  sections = [
    discoveryTasksConfig, setupTasksConfig,  testTasksConfig, integratedFeedsTasksConfig,
    baselineTasksConfig, idCardsConfig, goLiveConfig
  ];

  rawData;
  rowData = [];
  columns = [];
  columnDefs = [];
  finalCol = []

  getSubscription: Subscription;
  saveSubscription: Subscription;
  gridApi;
  hyperlinkEdit: boolean;

  private static initializeGrid(grid: AgGridAngular, columnDefs: any[], rowData) {
    grid.api.setColumnDefs(columnDefs);
    grid.api.setRowData(rowData);
  }

  constructor(private checklistMetadataService: ChecklistSettingsService, private alertService: CVSAlertService) {}

  ngAfterViewInit(): void {
    this.grids.push(this.discoveryTasksGrid, this.setupTasksGrid, this.testTasksGrid, this.iFeedsTasksGrid,
      this.baselineTasksGrid, this.idCardsGrid, this.goLiveGrid);

    this.getSubscription = this.checklistMetadataService.getChecklistMasterMetadata().subscribe(rowData => {
      var sizes = [10,21,8,9,13,5,2]
      const dataArray = []

      this.rawData = rowData;
      const checkbox = (params) => `<input class="checkbox" type='checkbox' ${params.value ? 'checked' : ''} /><label></label>`;
      
      Object.keys(rowData).forEach(key => {
        const projType = {}
        const cols = []
        const childObject = rowData[key];
        
        projType['label']=childObject.label;
        projType['hyperlink'] = childObject.hyperlink
        childObject?.checklistProjectTypeMetadata?.map(obj =>{
          if(obj.projectTypeStatus === 'A'){(
          projType[obj.projectType] = obj.labelEnabled
          )}})
        Object.keys(projType).forEach(header =>{
          if(header === 'label'){
            if(childObject.id <= 10){
              cols.push({headerName: 'Discovery', field: 'label', pinned: 'left'})
            } else if(childObject.id > 10 && childObject.id <=31 ){
              cols.push({headerName: 'Setup', field: 'label', pinned: 'left'})
            } else if(childObject.id > 31 && childObject.id <=39){
              cols.push({headerName: 'Testing', field: 'label', pinned: 'left'})
            }else if(childObject.id > 39 && childObject.id <=48){
              cols.push({headerName: 'Integrated Feeds', field: 'label', pinned: 'left'})
            }else if(childObject.id > 48 && childObject.id <=61){
              cols.push({headerName: 'Baseline', field: 'label', pinned: 'left'})
            }else if(childObject.id > 61 && childObject.id <=66){
              cols.push({headerName: 'ID CARDS', field: 'label', pinned: 'left'})
            }else if(childObject.id > 66 && childObject.id <=68){
              cols.push({headerName: 'GO-LIVE', field: 'label', pinned: 'left'})
            }
           
          } else if(header === 'hyperlink'){
            cols.push(sharedColumnDefs.find(field=> field.headerName === 'Hyperlink'))
          } else{
            const emptyColumn = {headerName:header, field: header, cellRenderer: params => checkbox(params)} 
            cols.push(emptyColumn)
          }
        })
       this.columns.push(cols)
        
        dataArray.push(projType)
      })
      let start =1;
      for (const size of sizes){
        const subCol = this.columns.slice(start,start+size)
        const subArray = dataArray.slice(start,start+size);
        this.rowData.push(subArray);
        this.columnDefs.push(subCol)
        start += size;
      }
      
      this.columnDefs.forEach(colArray =>{
        const flatarray = colArray.flatMap(col=>col)
        const colSet = new Set();
        const uniqueObjects = []
        flatarray.forEach(obj =>{
          const key = obj.headerName;
          if(!colSet[key]){
            uniqueObjects.push(obj)
            colSet[key] = true
          }
        })
        this.finalCol.push(uniqueObjects)
      })
      for (let i = 0; i < this.grids.length; i++) {
        
        ChecklistSettingsComponent.initializeGrid(this.grids[i], this.finalCol[i], this.rowData[i]);
    }
    });
  }

  onCellClicked(event: any, sectionIndex: number) {
    const projectType = event.colDef.field;
    const labelName = event.data.label;
    if (projectType !== 'label' && projectType !== 'hyperlink') {
      const rowIndex = event.rowIndex;
      this.hyperlinkEdit = false;
      this.rowData[sectionIndex][rowIndex][projectType] = !this.rowData[sectionIndex][rowIndex][projectType];
      this.grids[sectionIndex].api.refreshCells();
      this.rowData.forEach((section, sectionIndex) => {
        section.forEach((fieldMetadata, fieldIndex) => {
          const controlName = this.sections[sectionIndex][fieldIndex]?.formControlName;
          Object.keys(this.rawData).some(key =>{
            if( controlName === key && this.rawData[key].label === labelName ){
             let checkListArray = this.rawData[key].checklistProjectTypeMetadata;
             let projectItem = checkListArray.find(i => i.projectType === projectType)
             projectItem.labelEnabled = !projectItem.labelEnabled;
            }
          }
            );
        });
      });      
    }else if(projectType === 'hyperlink'){
      this.hyperlinkEdit = true
    }
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    const labelName = event.data.label;
    Object.keys(this.rawData).some(key =>{
      if( this.rawData[key].label === labelName && this.hyperlinkEdit ){
      this.rawData[key].hyperlink = (event && (event?.newValue?.trim().length >0))? event.newValue.trim(): null
      }
    }
      );
  }

  onGridReady(params: GridReadyEvent) {
     this.gridApi = params.api
  }
  

  save() {
    this.gridApi.stopEditing();
    this.saveSubscription = this.checklistMetadataService.saveChecklistMasterMetadata(this.rawData).subscribe(() => {
      this.alertService.sendAlert({
        headline: 'Save successful!',
        alertType: CVSAlertType.Success,
        removedAfterMilliseconds: 5000,
        alertDocumentHolderName: '#settings-alert',
      });
    });
  }

  ngOnDestroy(): void {
    this.getSubscription.unsubscribe();
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
  }
}
