import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {CVSHttpClient} from 'angular-component-library';
import {Filetracker} from '../models/Filetracker';
import {Contact} from '../models/Contact';
import { Permission } from '../models/Authorization';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class FiletrackerService {

  filetracker: Filetracker;
  contact: Contact;
  isNew: boolean;
  fileTrackerRowData:any
  userId: any;
  role: any;
  cltSetupId:number;
  myPbmRoles: any;

  constructor(private http: CVSHttpClient, private usersService: UsersService) {  
    this.myPbmRoles = this.usersService.myPbmRoles
  }

  getFiletracker(id: number): Observable<any> {
    return this.http.get(`${environment.baseFiletrackerUrl}/filetracker/${id}`);
  }

  getContact(id: number, field: string): Observable<any> {
    return this.http.get(`${environment.baseFiletrackerUrl}/filetracker/contacts/${id}/${field}`);
  }

  getAll(userId: any, role: any, backup:any): Observable<any> {
    
    for(let [key, value] of Object.entries(this.myPbmRoles)){
      if(value === role){
        role = key
      }
    } 
    return this.http.get(`${environment.baseFiletrackerUrl}/filetracker/user/${userId}/${role}/${backup}`);
  }

  saveFiletracker(filetracker: Filetracker, type: string): Observable<any> {
    return this.http.post(`${environment.baseFiletrackerUrl}/filetracker/save?type=${type}`, filetracker);
  }

  saveContact(contact: Contact, type: string): Observable<any> {
    return this.http.post(`${environment.baseFiletrackerUrl}/filetracker/contact/save?type=${type}`, contact);
  }

  editContact(contact: Contact, field: string): Observable<any> {
    return this.http.post(`${environment.baseFiletrackerUrl}/filetracker/contacts/update/${field}`, contact);
  }
  deleteContact(emailId: any): Observable<any> {
    return this.http.delete(`${environment.baseFiletrackerUrl}/filetracker/contacts/delete?emailId=` + emailId);
  }
  deleteHoliday(holidayType: any): Observable<any> {
    return this.http.delete(`${environment.baseFiletrackerUrl}/filetracker/holidays/` + holidayType);
  }
  saveHolidays(contact: any): Observable<any> {
    return this.http.post(`${environment.baseFiletrackerUrl}/filetracker/saveHolidays`, contact);
  }
  getFileTrackerEligibility(idno: number) {
     return this.http.get(`${environment.baseFiletrackerUrl}/spedmfiletracker/filetrackerdetails/Eligibility/${idno}`);
    //return null;
  }
  getFileTrackerEligibilityPli(idno: number, pli: String) {
    return this.http.get(`${environment.baseFiletrackerUrl}/spedmfiletracker/filetrackerdetailspli/Eligibility/${idno}/${pli}`);
  }
  getFileTrackerModalData(modalInputData) {
    return this.http.get(`${environment.baseFiletrackerUrl}/spedmfiletracker/filetrackerdetails/events?glbid=${modalInputData}`);
  }

  getFiletrackerByPli(pli: any): Observable<any> {
    return this.http.get(`${environment.baseFiletrackerUrl}/filetracker/pli?pli=${pli}`);

  }
}
