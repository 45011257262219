import {Action} from '@ngrx/store';
import {EprFeed} from '../models/EprFeed';


export const LOAD_EPRFEED = '[EPRFEED] Load EprFeed';
export const LOAD_EPRFEED_SUCCESS = '[EPRFEED] EprFeed Loaded Success';
export const LOAD_EPRFEED_FAILURE = '[EPRFEED] EprFeed Loaded Failure';
export const SAVE_EPRFEED = '[EPRFEED] Save EprFeed';
export const SAVE_EPRFEED_SUCCESS = '[EPRFEED] Save EprFeed Success';
export const SAVE_EPRFEED_SUCCESS_FAILURE = '[EPRFEED] EprFeed Saved Failure';
export const EDIT_EPRFEED = '[EPRFEED] Edit EprFeed';
export const EDIT_EPRFEED_SUCCESS = '[EPRFEED] Edit EprFeed Success';
export const EDIT_EPRFEED_SUCCESS_FAILURE = '[EPRFEED] EprFeed Edit Failure';
export const DELETE_EPRFEED = '[EPRFEED] Deleting EprFeed...';
export const DELETE_EPRFEED_SUCCESS = '[EPRFEED] EprFeed deleted successfully';
export const FILTER_EPRFEEDS_BY_ID = '[EPRFEED] Filter EprFeeds By Id';

export class LoadEprFeedAction implements Action {
  readonly type = LOAD_EPRFEED;

  constructor(public payload: number) {
  }
}

export class LoadEprFeedSuccessAction implements Action {
  readonly type = LOAD_EPRFEED_SUCCESS;

  constructor(public payload: EprFeed) { }
}

export class LoadEprFeedFailureAction implements Action {
  readonly type = LOAD_EPRFEED_FAILURE;
}

export class SaveEprFeedAction implements Action {
  readonly type = SAVE_EPRFEED;

  constructor(public payload: EprFeed) { }
}

export class SaveEprFeedSuccessAction implements Action {
  readonly type = SAVE_EPRFEED_SUCCESS;

  constructor(public payload: EprFeed) { }
}

export class SaveEprFeedFailureAction implements Action {
  readonly type = SAVE_EPRFEED_SUCCESS_FAILURE;
}

export class EditEprFeedAction implements Action {
  readonly type = EDIT_EPRFEED;

  constructor(public payload: any) { }
}

export class EditEprFeedSuccessAction implements Action {
  readonly type = EDIT_EPRFEED_SUCCESS;

  constructor(public payload: EprFeed) { }
}

export class EditEprFeedFailureAction implements Action {
  readonly type = EDIT_EPRFEED_SUCCESS_FAILURE;
}
export class DeleteEprFeedAction implements Action {
  readonly type = DELETE_EPRFEED;

  constructor(public payload: number) {
  }
}
export class DeleteEprFeedSuccessAction implements Action {
  readonly type = DELETE_EPRFEED_SUCCESS;
}
export class FilterEprFeedById implements Action {
  readonly type = FILTER_EPRFEEDS_BY_ID;

  constructor(public payload: any) {}
}

export type Actions
  = LoadEprFeedAction
  | LoadEprFeedSuccessAction
  | LoadEprFeedFailureAction
  | SaveEprFeedAction
  | SaveEprFeedSuccessAction
  | SaveEprFeedFailureAction
  | EditEprFeedAction
  | EditEprFeedSuccessAction
  | EditEprFeedFailureAction
  | DeleteEprFeedAction
  | DeleteEprFeedSuccessAction
  | FilterEprFeedById;


