import * as moment from 'moment';

export const convertDateForDatePicker = (javaDateString) => {
  if (!javaDateString) { return ''; }

  return moment.utc(javaDateString).format();
};

// @ts-ignore
Date.prototype.yymmdd = function() {
  const yy = this.getUTCFullYear().toString().slice(-2); // Get 2-char year
  const mm = (this.getUTCMonth() + 1).toString(); // getMonth() is zero-based
  const dd = this.getUTCDate().toString();

  return [yy, mm.length === 2 ? '' : '0', mm, dd.length === 2 ? '' : '0', dd].join('');
};
