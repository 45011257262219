import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, merge, Observable, of, Subject, zip } from 'rxjs';
import { environment } from '../../environments/environment';
import { CVSHttpClient } from 'angular-component-library';
import { catchError, map } from 'rxjs/operators';
import { calculateActiveStatus, calculateStatus } from '../helpers/CalculateStatusHelper';
import { ChangedQuestionnaireRecord } from '../models/ChangedQuestionnaireRecord';
import { HttpClient } from '@angular/common/http';
import { UsersService } from "./users.service";
import { Permission } from "../models/Authorization";
import { ProcessingFileDB } from '../models/PermissionsObject';

@Injectable({
  providedIn: 'root'
})
export class EprService {
  fullyLoaded = new BehaviorSubject(false);
  pliValue = new BehaviorSubject('');
  dashboardRowClicked = new BehaviorSubject(false);
  usersPliList = new BehaviorSubject([]);
  reloadPrj = new BehaviorSubject(false);
  reloadTest = new BehaviorSubject(false)
  mdlist: any = [];
  consolidateFlag: any = new Subject();
  ssoeEnabled: boolean;
  initialEprData: any = new Subject();
  finalEprCommonModel: any;
  usersList: any = [];
  username: '';
  isNewCopy:boolean;
  private questionnaireObj: any;
  enableSaveButton: boolean = true;
  currentTab = "Questionnaire";
  resetQuestionnaire = new BehaviorSubject(false)
  carrierLevelPayloadList : any = [];

  isEditProfileFlag: any = new BehaviorSubject(false);
  triggerGetCarrierPliApi$: any = new Subject();
  includeBackup: boolean;
  triggerProjectTestingConfirmation$ :any = new BehaviorSubject(null);
  constructor(private http: CVSHttpClient, private httpgen: HttpClient, private userService: UsersService) { }

  getEprById(id: number, view: string): Observable<any> {
    const modifiedView= view?.replace('/', '');
    return this.http.get(`${environment.baseUrl}/epr/` + id + "/" + modifiedView);
  }
  getTestSSOEData() {
    return this.http.get(`${environment.baseUrlSSOE}/client/profile/byPli?pli=ace1&env=test`);
  }
  getProdSSOEData() {
    return this.http.get(`${environment.baseUrlSSOE}/client/profile/byPli?pli=ace1&env=prod`);
  }
  sendSSOEData(payload, env) {
    return this.http.post(`${environment.baseUrl}/questionnaire/carrierlevel/sendToSsoe?env=${env}`,payload);
  }
  getEnableSaveButton() {
    return this.enableSaveButton;
  }
  setEnableSaveButton(value) {
    this.enableSaveButton = value;
  }
  getLoadReportSSOEDataProd(pli) {
    return this.http.get(`${environment.baseUrlSSOE}/client/profile/byPli?pli=${pli}`);
  }
  getLoadReportSSOEDataTest(pli){
    return this.http.get(`${environment.baseUrlSSOE_Beta}/client/profile/byPli?pli=${pli}`);
  }
  sendLoadReportContactsSSOEData(payload, pli, type, env){
    let url;
    url = env === 'test' ? environment.baseUrlSSOE_Beta : environment.baseUrlSSOE
    return this.http.post(`${url}/client/loadReport/save?pli=${pli}&type=${type}`, payload);

  }
  // EPR views
  getProjectTabData(eprId){
    //let epr = 24;
    let dataList = this.http.get(`${environment.baseUrl}/dashboard/projects/testingProjectsByEprId?eprId=${eprId}`).pipe(
      map(oneOneActiveProjectRecordsFromBackend =>
        this.mapFunctionToCalculateActiveStatusOneoneProjects(oneOneActiveProjectRecordsFromBackend)
      )
    );
    return dataList;
   // return this.http.get(`${environment.baseUrl}/dashboard/projects/testingProjectsByEprId?eprId=${eprId}`);
  }

  getProjectDetail(projectId){
    return this.http.get(`${environment.baseUrl}/epr/project/${projectId}`);
  }
  saveProjectDetail(payload){
    return this.http.post(`${environment.baseUrl}/epr/project/save`,payload);
  }
  getAllEprs(userId: string, backup: boolean): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/eprs?userId=${userId}&&backup=${backup}`);
  }

  getOEEprs(userId: string, backup: boolean): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/eprs/oe?userId=${userId}&&backup=${backup}`);
  }
  // Project views
  getAllActiveProjects(userId: string, backup: boolean): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/projects?userId=${userId}&&backup=${backup}`).pipe(
      map(allActiveProjectRecordsFromBackend =>
        this.mapFunctionToCalculateActiveStatus(allActiveProjectRecordsFromBackend))
    );
  }


  getOneOneProjects(userId: string, backup: boolean): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/projects/oneone?userId=${userId}&&backup=${backup}`).pipe(
      map(oneOneActiveProjectRecordsFromBackend =>
        this.mapFunctionToCalculateActiveStatusOneoneProjects(oneOneActiveProjectRecordsFromBackend))
    );
  }


  getMyUserId() {
    return this.http.get(`${environment.baseUrl}/dashboard/projects/users/userId`);
  }

  getMyUser(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/projects/userentity`);
  }
  // for processing files
  getMyProcessingFilesByFilter(filterObj: any, userId: string, backup: boolean, db: string , thresholdSsoe?): Observable<any> {
    // stop extra api call with thresholdssoe flag that represent row isSSoe from dashboard to threshold component
    if(!environment.fetchssoeProcessingFiles){
        return  this.http.post(`${environment.baseUrl}/dashboard/projects/processingFiles?userId=${userId}&&backup=${backup}&&db=${db}`, filterObj);
    } else {

            let processingFileRxClaim =  this.http.post(`${environment.baseUrl}/dashboard/projects/processingFiles?userId=${userId}&&backup=${backup}&&db=${db}`, filterObj);
            let processingFileSSOE =  this.http.post(`${environment.baseUrl}/dashboard/projects/ssoe/processingFiles?userId=${userId}&&backup=${backup}&&db=${db}`, filterObj);
            let result: any ={};
            if(db === ProcessingFileDB.BETA || thresholdSsoe === true){
              processingFileRxClaim = of({response:[]});
            }
            if(thresholdSsoe === false){
              processingFileSSOE =  of({response:[]});
            }
            const combinedProcessingFiles = zip(processingFileRxClaim.pipe(catchError((err)=> of({ errorResp: err }))),
            processingFileSSOE.pipe(catchError((err)=> of({ errorResp: err })))).pipe(
              map(x => {
                  if(x[0].errorResp || x[1].errorResp) {
                    result.response = (x[0].errorResp &&  x[1].errorResp) ? []: (x[0].errorResp ? x[1].response : x[0].response);
                  //  result.errorResp = x[0].errorResp ?  x[0].errorResp : x[1].errorResp ? x[1].errorResp: of({})
                    result.errorResp = (x[0].errorResp &&  x[1].errorResp)? 'Both SSOE and RxClaim': x[0].errorResp ?  "RxClaim" : x[1].errorResp ? "SSOE": of({})

                  } else {
                    result.response = x[0].response.concat(x[1].response);
                  }
                  return result;
              }));
            return combinedProcessingFiles;
      }

  }


  getAllCurrentFilesByFilter(filterObj: any, userId: string, backup: boolean, db:ProcessingFileDB = ProcessingFileDB.ADS): Observable<any> {
    if(!environment.fetchssoeProcessingFiles || !this.ssoeEnabled){
      return  this.http.post(`${environment.baseUrl}/dashboard/projects/allCurrentFiles?processingFilesWithinEprFlag=${true}`, filterObj).pipe(catchError((err)=> of({ errorResp: err })),map(x=>{
        let result: any ={};
        if(x.errorResp){
          result.errorResp = x.errorResp;
          result.response = [];
        }else{
          result.response = x;
        }
        return result;
      }))
    } else{
      const eprPFRxClaim =  this.http.post(`${environment.baseUrl}/dashboard/projects/allCurrentFiles?processingFilesWithinEprFlag=${true}`, filterObj);
      const keyValues = Object.keys(filterObj).map(key => {
        let obj = {}
        if(key==="g1"){
          obj["g3"] = filterObj["g1"];
        } else{
          obj[key] = filterObj[key];
        }
        return obj
      });
      let newObjUpdated = Object.assign({}, ...keyValues);
      const eprPFSSOE =  this.http.post(`${environment.baseUrl}/dashboard/projects/ssoe/processingFiles?userId=${userId}&&backup=${backup}&&db=${db}&&processingFilesWithinEprFlag=${true}`, newObjUpdated);
      let result: any ={};
      const combinedProcessingFiles = zip(eprPFRxClaim.pipe(catchError((err)=> of({ errorResp: err }))),
      eprPFSSOE.pipe(catchError((err)=> of({ errorResp: err })))).pipe(
        map(x => {
            if(x[0].errorResp || x[1].errorResp) {
              result.response = (x[0].errorResp &&  x[1].errorResp) ? []: (x[0].errorResp ? x[1].response : x[0]);
              result.errorResp = (x[0].errorResp &&  x[1].errorResp)? 'Both SSOE and RxClaim': x[0].errorResp ?  "RxClaim" : x[1].errorResp ? "SSOE": of({})
            } else {
              result.response = x[0].concat(x[1].response);
            }
            return result;
        }));
      return combinedProcessingFiles;
    }
  }


  getDashboard(userId: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/items?userId=${userId}`);
  }

  saveDashboard(items: any[]): Observable<any> {
    return this.http.post(`${environment.baseUrl}/dashboard/items/saveAll`, items);
  }


  // Misc views

  getTestingProjects(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/projects/testingProjects`).pipe(
      map(data => {
        data.response = data.response.map(activeProject => {
          const relevantProjectStatusDates = {
            discoveryDueDate: activeProject.tNewDiscDueDate,
            discoveryCompleteDate: activeProject.tNewDiscCmpltDate,
            setupDueDate: activeProject.tNewSetupDueDate,
            setupCompleteDate: activeProject.tNewSetupCmpltDate,
            testingDueDate: activeProject.tNewTstDueDate,
            testingCompleteDate: activeProject.tNewTstCmpltDate,
            baselineDueDate: activeProject.tNewBLDueDate,
            baselineCompleteDate: activeProject.tNewBLCmpltDate,
            idCardsDueDate: activeProject.tNewIDCrdsDueDate,
            idCardsCompleteDate: activeProject.tNewIDCrdsCmpltDate
          };

          return { ...activeProject, tPrjStatus: calculateActiveStatus(relevantProjectStatusDates) };
        });
        return data;
      }
      )
    );
  }

  getTestingRecordsByProjectId(projId): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/projects/testingProjectsById?id=${projId}`);
  }


  getTestingResultsByProjectId(id): Observable<any> {
    return this.http.get(`${environment.baseUrl}/epr/testResults/${id}`);
  }

  deleteTestingRecords(items: any[]): Observable<any> {
    return this.http.post(`${environment.baseUrl}/dashboard/projects/deletetesting`, items);
  }

  getTestingProjectListByEprId(eprId): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/projects/testingProjectsByEprId?eprId=${eprId}`);
  }
  getTestingProjectsByEprId(eprId): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/projects/testingProjectsByEprId?eprId=${eprId}`).pipe(
      map(data => {
          data.response = data?.response?.map(activeProject => {
            const relevantProjectStatusDates = {
              discoveryDueDate: activeProject.tNewDiscDueDate,
              discoveryCompleteDate: activeProject.tNewDiscCmpltDate,
              setupDueDate: activeProject.tNewSetupDueDate,
              setupCompleteDate: activeProject.tNewSetupCmpltDate,
              testingDueDate: activeProject.tNewTstDueDate,
              testingCompleteDate: activeProject.tNewTstCmpltDate,
              baselineDueDate: activeProject.tNewBLDueDate,
              baselineCompleteDate: activeProject.tNewBLCmpltDate,
              idCardsDueDate: activeProject.tNewIDCrdsDueDate,
              idCardsCompleteDate: activeProject.tNewIDCrdsCmpltDate
            };

            return { ...activeProject, tPrjStatus: calculateActiveStatus(relevantProjectStatusDates) };
          });
          return data;
        }
      )
    );
  }



  getQcProjects(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/projects/qcProjects`).pipe(
      map(data => {
        data.response = data.response.map(activeProject => {
          const relevantProjectStatusDates = {
            discoveryDueDate: activeProject.qcNewDiscDueDate,
            discoveryCompleteDate: activeProject.qcNewDiscCmpltDate,
            setupDueDate: activeProject.qcNewSetupDueDate,
            setupCompleteDate: activeProject.qcNewSetupCmpltDate,
            testingDueDate: activeProject.qcNewTstDueDate,
            testingCompleteDate: activeProject.qcNewTstCmpltDate,
            baselineDueDate: activeProject.qcNewBLDueDate,
            baselineCompleteDate: activeProject.qcNewBLCmpltDate,
            idCardsDueDate: activeProject.qcNewIDCrdsDueDate,
            idCardsCompleteDate: activeProject.qcNewIDCrdsCmpltDate
          };

          return { ...activeProject, qcPrjStatus: calculateActiveStatus(relevantProjectStatusDates) };
        });
        return data;
      }
      )
    );
  }

  getIFeedsProjects(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/projects/iFeedsProjects`).pipe(
      map(data => {
        data.response = data.response.map(activeProject => {
          const relevantProjectStatusDates = {
            discoveryDueDate: activeProject.iNewDiscDueDate,
            discoveryCompleteDate: activeProject.iNewDiscCmpltDate,
            setupDueDate: activeProject.iNewSetupDueDate,
            setupCompleteDate: activeProject.iNewSetupCmpltDate,
            testingDueDate: activeProject.iNewTstDueDate,
            testingCompleteDate: activeProject.iNewTstCmpltDate,
            baselineDueDate: activeProject.iNewBLDueDate,
            baselineCompleteDate: activeProject.iNewBLCmpltDate,
            idCardsDueDate: activeProject.iNewIDCrdsDueDate,
            idCardsCompleteDate: activeProject.iNewIDCrdsCmpltDate
          };

          return { ...activeProject, iPrjStatus: calculateActiveStatus(relevantProjectStatusDates) };
        });
        return data;
      }
      )
    );
  }

  getMasterViewEprs(masterViewColumns: string[]): Observable<any> {
    return this.http.post(`${environment.baseUrl}/dashboard/eprs/masterView`, masterViewColumns).pipe(
      map(eprRecords => {
        return eprRecords.map(epr => {
          const relevantProjectStatusDates = {
            discoveryDueDate: epr.mvNewDiscDueDate,
            discoveryCompleteDate: epr.mvNewDiscCmpltDate,
            setupDueDate: epr.mvNewSetupDueDate,
            setupCompleteDate: epr.mvNewSetupCmpltDate,
            testingDueDate: epr.mvNewTstDueDate,
            testingCompleteDate: epr.mvNewTstCmpltDate,
            baselineDueDate: epr.mvNewBLDueDate,
            baselineCompleteDate: epr.mvNewBLCmpltDate,
            idCardsDueDate: epr.mvNewIDCrdsDueDate,
            idCardsCompleteDate: epr.mvNewIDCrdsCmpltDate,
          };
          return { ...epr, mvPrjStatus: calculateActiveStatus(relevantProjectStatusDates) };
        });
      }
      )
    );
  }

  updateEprEditor(id: number, userId: number, isRequestingToEdit: boolean): Observable<any> {
    if (!id) { // When editing a new EPR, ensure we don't send null or undefined to the API
      id = -1;
    }
    return this.http.post(`${environment.baseUrl}/epr/updateEditor`,
      {
        eprId: id,
        editorId: userId,
        isRequestingToEdit
      });
  }

  saveChangedQuestionnaireData(questionnaireRecord: any, eprId: number): Observable<any> {
    if (this.questionnaireObj !== JSON.stringify(questionnaireRecord.questionnairedata)) {
      this.questionnaireObj = JSON.stringify(questionnaireRecord.questionnairedata);
      return this.http.post(`${environment.baseUrl}/SaveEprDataByEpr?eprId=${eprId}`, questionnaireRecord);
    }
    return of({});
  }
  deleteEpr(eprId: number): Observable<any> {
    return this.http.delete(`${environment.baseUrl}/epr/delete/${eprId}`);
  }

  deleteTestIteration(ids: number[]): Observable<any> {
    return this.http.post(`${environment.baseUrl}/epr/delete/testIterations`, ids);
  }

  deleteProjectIteration(ids: number[]): Observable<any> {
    return this.http.post(`${environment.baseUrl}/epr/delete/projectIterations`, ids);
  }


  rebuildAndSaveEpr(header, questionnaire, eligibilityAgreement, eprMetadata): Observable<any> {
    const rebuiltEpr = {
      // We implicitly set the editorId to null since we just saved and are exiting edit mode
      id: eprMetadata.id,
      headerEntity: { ...header, id: eprMetadata.headerId },
     /*
      projectTrackerEntities: this.rebuildProjectTrackerEntities(projectTracker.projects, checklist,
        checklistFieldsEnabled, testing, eprMetadata),
     */
        questionnaireEntity: {
        ...questionnaire,
        id: eprMetadata.questionnaireMetadata.id,
        contactInfo: this.zipUpIdsOnExistingRecords(questionnaire.contactInfo, eprMetadata.questionnaireMetadata.contactInfoIds)
        //this.udpateContactInfo(questionnaire.contactInfo, eprMetadata.questionnaireMetadata.contactInfoIds)
        ,

        // eslint-disable-next-line max-len
        previousEaGroup: this.zipUpIdsOnExistingRecords(questionnaire.previousEaGroup, eprMetadata.questionnaireMetadata.previousEaGroupIds),
        auditSection: this.zipUpIdsOnExistingRecords(questionnaire.auditSection, eprMetadata.questionnaireMetadata.auditSectionIds),
      },
      eligibilityAgreementEntity: {
        ...eligibilityAgreement,
        id: eprMetadata.eligibilityAgreementMetadata.id,
        changeLog: this.zipUpIdsOnExistingRecords(eligibilityAgreement?.changeLog, eprMetadata.eligibilityAgreementMetadata.changeLogIds)
      }
    };

    rebuiltEpr.questionnaireEntity.contactInfo

    if (rebuiltEpr.id === null) {
      this.initialEpr(rebuiltEpr);
    }
    let commonFieldsHistoryobj = this.getCommonFiledsHistoryObj(rebuiltEpr.id, rebuiltEpr.headerEntity, new Date(), 'Update');
    let eprCommonModel = {
      eprEntity: this.checkCopyEpr(rebuiltEpr),
      eprHistoryEntity: commonFieldsHistoryobj
    };
    this.finalEprCommonModel= eprCommonModel;
    return this.http.post(`${environment.baseUrl}/saveEpr`, {eprEntity:eprCommonModel.eprEntity});

  }
  udpateContactInfo(contactInfo: any, contactInfoIds: any) {

   contactInfo =  this.zipUpIdsOnExistingRecords(contactInfo, contactInfoIds);
   contactInfo = contactInfo.map(contact=>{
     if(contact.contactEwfAccessType ==="Remove User"){
       contact.contactEwfAccessStatus = "I"
      }else{
        contact.contactEwfAccessStatus = "A"
      }
      return contact;
    })

    return contactInfo;
  }
  checkCopyEpr(epr){
    if(epr?.id === null){
      if(epr?.eligibilityAgreementEntity?.id === null && epr?.eligibilityAgreementEntity.changeLog?.length > 0  &&
         epr?.eligibilityAgreementEntity?.changeLog[0]?.id !== null){
        for(let i=0;i < epr.eligibilityAgreementEntity.changeLog.length ; i++){
          epr.eligibilityAgreementEntity.changeLog[i].id =  null;
        }
      }
    }
    return epr;
  }
  initialEpr(rebuiltEpr: any) {
    this.initialEprData.next(rebuiltEpr);
  }
  getCommonFiledsHistoryObj(eprid, headerData: any, historyDate: Date, epraction) {
    return {
      "eprId": eprid,
      "clientName": headerData.clientName,
      "carrier": headerData.carrierNumber,
      "pli": headerData.pli,
      "assignedEc": this.getAssignedEc(headerData.eligibilityConsultant),
      "eprAuditUser": this.username,
      "timeStamp": historyDate,
      "eprAction": epraction,
      "previousData": '',
      "currentData": ''
    };
  }

  getAssignedEc(id: any) {
    let assingedEc = '';
    const filteredUsers = this.usersList.filter(user => user.id === id);
    assingedEc = filteredUsers.length >= 1 ? filteredUsers[0].firstName + ' ' + filteredUsers[0].lastName : '';
    return assingedEc;
  }
  private zipUpIdsOnExistingRecords(iterableItems, existingIds) {
    return iterableItems?.map((item, index) => {
      if (typeof existingIds[index] !== 'undefined') {
        return {
          ...item,
          id: existingIds[index]
        };
      }

      return item;
    });
  }

  private rebuildProjectTrackerEntities(projectTrackerEntities: any, checklist: any,
    checklistFieldsEnabled: any, testing: any, eprMetadata) {
    return projectTrackerEntities.map((projectTracker, i) => {
      return {
        ...projectTracker,
        id: eprMetadata.projectTrackerIds[i],
        checklistEntity: {
          ...checklist.checklists[i], id: eprMetadata.checklistIds[i],
          checklistFieldsEnabled: checklistFieldsEnabled.checklistFieldEnabledStates[i]
        },
        testingEntity: testing.projectTests[i].map((testIteration, j) => {
          return {
            ...testIteration,
            id: eprMetadata.testingMetadata[i][j].id,
            customTestCriteria: testing.projectTests[i][j].customTestCriteria.map((customCriterion, k) => {

              // eslint-disable-next-line max-len
              if (eprMetadata.testingMetadata[i][j].customCriteriaIds && typeof eprMetadata.testingMetadata[i][j].customCriteriaIds[k] !== 'undefined') {
                return { ...customCriterion, id: eprMetadata.testingMetadata[i][j].customCriteriaIds[k] };
              }
              return customCriterion;
            })
          };
        })
      };
    });
  }

  private mapFunctionToCalculateActiveStatus(projectRecords) {
    if (projectRecords && projectRecords.response !== null) {
      projectRecords.response = projectRecords.response.map(activeProject => {
        let relevantProjectStatusDates: any;
        relevantProjectStatusDates = {
          discoveryDueDate: activeProject.aNewDiscDueDate,
          discoveryCompleteDate: activeProject.aNewDiscCmpltDate,
          setupDueDate: activeProject.aNewSetupDueDate,
          setupCompleteDate: activeProject.aNewSetupCmpltDate,
          testingDueDate: activeProject.aNewTstDueDate,
          testingCompleteDate: activeProject.aNewTstCmpltDate,
          baselineDueDate: activeProject.aNewBLDueDate,
          baselineCompleteDate: activeProject.aNewBLCmpltDate,
          idCardsDueDate: activeProject.aNewIDCrdsDueDate,
          idCardsCompleteDate: activeProject.aNewIDCrdsCmpltDate
        };
        activeProject.prjStatus = calculateStatus(activeProject.a6, relevantProjectStatusDates);
        return activeProject;
      });
    }
    return projectRecords;
  }

  private mapFunctionToCalculateActiveStatusOneoneProjects(projectRecords) {
    let relevantProjectStatusDates: any;
    if (projectRecords && projectRecords.response !== null) {
      projectRecords.response = projectRecords.response.map(activeProject => {
        relevantProjectStatusDates = {
          discoveryDueDate: activeProject.newDiscDueDate,
          discoveryCompleteDate: activeProject.newDiscCmpltDate,
          setupDueDate: activeProject.newSetupDueDate,
          setupCompleteDate: activeProject.newSetupCmpltDate,
          testingDueDate: activeProject.newTstDueDate,
          testingCompleteDate: activeProject.newTstCmpltDate,
          baselineDueDate: activeProject.newBLDueDate,
          baselineCompleteDate: activeProject.newBLCmpltDate,
          idCardsDueDate: activeProject.newIDCrdsDueDate,
          idCardsCompleteDate: activeProject.newIDCrdsCmpltDate
        };
        return { ...activeProject, prjStatus: calculateActiveStatus(relevantProjectStatusDates) };
      });

    }
    return projectRecords;
  }

  fetchAllEprs(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/epr/all`);
  }

  getAllPliAndEcNames(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/projects/pliAndEcNames`);
  }

  getFileMappingData(userId: any, backup : boolean): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/filemapping?userId=${userId}&&backup=${backup}`);
  }
  getCagRefManualData(filter?): Observable<any> {
    if (filter) {
      return this.http.post(`${environment.cagRefUrl}/cagref/dashboard/manual/filter`, filter);
    } else {
      return this.http.get(`${environment.cagRefUrl}/cagref/dashboard/manual`);
    }
  }
  updateCagRefManual(body) {
    return this.http.post(`${environment.cagRefUrl}/cagref/save?type=update`, body);
  }
  addCagRefManual(body) {
    return this.http.post(`${environment.cagRefUrl}/cagref/save?type=save`, body);
  }

  deleteCagRefManual(body) {
    return this.http.put(`${environment.cagRefUrl}/cagref/delete`, body);
  }

  getCagRefBatchData(userID): Observable<any> {
    return this.http.get(`${environment.cagRefUrl}/cagref/dashboard/batch?userId=${userID}`);
  }

  downloadCagRefOutputFile(id: any) {
    return this.http.get(`${environment.cagRefUrl}/cagref/downloadoutput/${id}`);
  }
  uploadCagRefBatch(body: any) {
    return this.http.post(`${environment.cagRefUrl}/cagref/upload`, body)
  }
  saveEprHistory(payload: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/saveEprHistory`, payload);
  }

  saveTestResults(payload: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/dashboard/projects/savetesting`, payload);
  }
  saveTestResultsBySeprateApi(payload: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/epr/save/testResults`, payload);
  }
  saveCarrierLevel(payload:any):Observable<any>{
    return this.http.post(`${environment.baseUrl}/questionnaire/carrierlevel/save`,payload);
  }
  deleteCarrierLevel(payload:any):Observable<any>{
    return this.http.delete(`${environment.baseUrl}/questionnaire/carrierlevel/deleteById?id=${payload}`);
  }
  getCarrierLevel(eprId:any,pli:any):Observable<any>{
    return this.http.get(`${environment.baseUrl}/questionnaire/carrierlevel/byEpr?eprId=${eprId}&pli=${pli}`);
  }

  getReportFields():Observable<any>{
    return this.http.get(`${environment.baseCustomMappingUrl}/filelayouts/getfieldname/3/Member%205.1%20Com`);
  }
  triggerGetCarrierPliApi(trigger){
   this.triggerGetCarrierPliApi$.next(trigger);
  }
  triggerProjectTrestingConfirmation(trigger){
    this.triggerProjectTestingConfirmation$.next(trigger);
  }

}




