
export const memberFileCol = [
  {
    headerName: ' ',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    width: 120,
    lockPosition: true,
    // floatingFilterComponentParams: {suppressFilterButton: true},
    cellClass: (params) => {
      return 'pds-ag-grid__checkbox-column'
    }
  },
  {
    headerName: 'Carrier',
    editable: false,
    field: 'carrier',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },

  {
    headerName: 'Account',
    editable: false,
    field: 'account',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Group',
    editable: false,
    field: 'group',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Member ID',
    editable: false,
    field: 'memberID',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Alternate ID',
    editable: false,
    field: 'altID',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Last Name',
    editable: false,
    field: 'memberLN',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'First Name',
    editable: false,
    field: 'memberFN',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Date of Birth',
    editable: false,
    field: 'dateOfBirth_parsed',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Effective Date',
    editable: false,
    field: 'effectiveDate_parsed',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Term Date',
    editable: false,
    field: 'termDate_parsed',

    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Ovr Plan',
    editable: false,
    field: 'ovrPlan',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Ovr Plan Effective Date',
    editable: false,
    field: 'ovrPlanEffectiveDate_parsed',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Add Date',
    editable: false,
    field: 'addDate_parsed',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Drop Date',
    editable: false,
    field: 'dropDate_parsed',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Account Suspense Flag',
    editable: false,
    field: 'accountSuspenseFlag',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Group Suspense Flag',
    editable: false,
    field: 'groupSuspenseFlag',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Ovr Plan Suspense Flag',
    editable: false,
    field: 'ovrPlanSuspenseFlag',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  }
];

export const memberDailyCol = [
{
  headerName: ' ',
  headerCheckboxSelection: true,
  checkboxSelection: true,
  lockPosition: true,
  width: 120,
  // floatingFilterComponentParams: {suppressFilterButton: true},
  cellClass: (params) => {
    return 'pds-ag-grid__checkbox-column'
  }
},
{
  headerName: 'Carrier',
  editable: false,
  field: 'carrier',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Account',
  editable: false,
  field: 'account',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Group',
  editable: false,
  field: 'group',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Member ID',
  editable: false,
  field: 'memberID',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Alternate ID',
  editable: false,
  field: 'altID',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Last Name',
  editable: false,
  field: 'memberLN',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'First Name',
  editable: false,
  field: 'memberFN',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Date of Birth',
  editable: false,
  field: 'dateOfBirth_parsed',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Effective Date',
  editable: false,
  field: 'effectiveDate_parsed',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Term Date',
  editable: false,
  field: 'termDate_parsed',

  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Ovr Plan',
  editable: false,
  field: 'ovrPlan',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Ovr Plan Effective Date',
  editable: false,
  field: 'ovrPlanEffectiveDate_parsed',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Account Suspense Date',
  editable: false,
  field: 'accountSuspenseDate_parsed',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'PLI',
  editable: false,
  field: 'pli',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Add Date',
  editable: false,
  field: 'addDate_parsed',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Drop Date',
  editable: false,
  field: 'dropDate_parsed',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Account Suspense Flag',
  editable: false,
  field: 'accountSuspenseFlag',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Group Suspense Flag',
  editable: false,
  field: 'groupSuspenseFlag',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
},
{
  headerName: 'Ovr Plan Suspense Flag',
  editable: false,
  field: 'ovrPlanSuspenseFlag',
  sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
}
];

export const groupCol = [
  {
    headerName: ' ',
    headerCheckboxSelection: true,
    checkboxSelection: true,

    width: 120,
    // floatingFilterComponentParams: {suppressFilterButton: true},
    cellClass: (params) => {
      return 'pds-ag-grid__checkbox-column'
    }
  },
  {
    headerName: 'Carrier',
    editable: false,
    field: 'carrier',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },

  {
    headerName: 'Account',
    editable: false,
    field: 'account',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Group',
    editable: false,
    field: 'group',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Group Name',
    editable: false,
    field: 'groupName',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Effective Date',
    editable: false,
    field: 'effectiveDate_parsed',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Term Date',
    editable: false,
    field: 'termDate_parsed',

    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Plan Code',
    editable: false,
    field: 'planCode',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Plan Effective Date',
    editable: false,
    field: 'planEffectiveDate_parsed',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'PLI',
    editable: false,
    field: 'pli',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Add Date',
    editable: false,
    field: 'addDate_parsed',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Drop Date',
    editable: false,
    field: 'dropDate_parsed',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Account Suspense Flag',
    editable: false,
    field: 'accountSuspenseFlag',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Plan Suspense Flag',
    editable: false,
    field: 'planSuspenseFlag',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  }
];