import {Action} from '@ngrx/store';
import {AddProjectTrackerFormAction, ClearEprAction,
  CopyEprAction, DeleteProjectTrackerFormAction, SelectProjectAction} from './epr.action';
import {ProjectTypeChangeAction} from './project-tracker.action';

export const TOGGLE_FIELD_ENABLED_STATUS = '[Checklist Fields Enabled] Toggle Field Enabled Status';
export const POPULATE_CHECKLIST_FIELDS_ENABLED_STATE = '[Checklist Fields Enabled] Populate Single Fields Enabled State';
export const POPULATE_ALL_CHECKLIST_FIELDS_ENABLED_STATES = '[Checklist Fields Enabled] Populate All Fields Enabled States';

export class ToggleFieldEnabledStatusAction implements Action {
  readonly type = TOGGLE_FIELD_ENABLED_STATUS;

  constructor(public payload: string) { }
}

export class PopulateChecklistFieldsEnabledStateAction implements Action {
  readonly type = POPULATE_CHECKLIST_FIELDS_ENABLED_STATE;

  constructor(public payload: any) { }
}

export class PopulateAllChecklistFieldsEnabledStatesAction implements Action {
  readonly type = POPULATE_ALL_CHECKLIST_FIELDS_ENABLED_STATES;

  constructor(public payload: any[]) { }
}

export type Actions = ToggleFieldEnabledStatusAction
  | PopulateChecklistFieldsEnabledStateAction
  | PopulateAllChecklistFieldsEnabledStatesAction
  | AddProjectTrackerFormAction
  | DeleteProjectTrackerFormAction
  | ClearEprAction
  | ProjectTypeChangeAction
  | SelectProjectAction
  | CopyEprAction;

