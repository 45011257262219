import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState, getProjectType} from '../reducers';
import {ChecklistSettingsService} from '../services/checklist-settings.service';
import {ADD_PROJECT_TRACKER} from '../actions/epr.action';
import {catchError, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {ProjectType} from '../models/ProjectTypeEnum';
import {NoopAction} from '../actions/noop.action';
import {of} from 'rxjs';
import {PROJECT_TYPE_CHANGED, ProjectTypeChangeAction} from '../actions/project-tracker.action';
import {PopulateChecklistFieldsEnabledStateAction} from '../actions/checklist-fields-enabled.action';

@Injectable()
export class ChecklistFieldsEnabledEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private checklistMetadataService: ChecklistSettingsService
  ) {  }

  loadChecklistFieldsEnabledStateForNewChecklist$ = createEffect(() => this.actions$.pipe(
    ofType(ADD_PROJECT_TRACKER),
    withLatestFrom(this.store$.select(getProjectType)),
    mergeMap(([action, projectType]) => this.checklistMetadataService.getChecklistFieldsEnabledByProjectType(projectType as ProjectType)
      .pipe(
        map(result => new PopulateChecklistFieldsEnabledStateAction(result)),
        catchError(error => {
          console.error('=============>Error when loading in default checklist fields enabled statuses: ', error);
          return of(new NoopAction());
        })
      )
    )
  ));

  loadChecklistFieldsEnabledStateForProjectTypeChange$ = createEffect(() => this.actions$.pipe(
    ofType<ProjectTypeChangeAction>(PROJECT_TYPE_CHANGED),
    mergeMap(action => this.checklistMetadataService.getChecklistFieldsEnabledByProjectType(action.payload)
      .pipe(
        map(result => new PopulateChecklistFieldsEnabledStateAction(result)),
        catchError(error => {
          console.error('=============>Error when loading in default checklist fields enabled statuses: ', error);
          return of(new NoopAction());
        })
      )
    )
  ));
}
