import {Action} from '@ngrx/store';
import {
  AddProjectTrackerFormAction,
  ClearEprAction,
  CopyEprAction,
  DeleteProjectTrackerFormAction,
  LoadEprSuccessAction,
  ResetProjectIndicesActions,
  SelectProjectAction
} from './epr.action';

// eslint-disable-next-line max-len
export const CHECK_IF_CROSS_FORM_POPULATIONS_NEEDED_CHECKLIST = '[CHECKLIST] Check To see if any fields need to be populated on other forms';
export const UPDATE_CHECKLIST_FORM = '[CHECKLIST] Update Checklist Form';
export const LOAD_CHECKLIST_FORM_SUCCESS = '[CHECKLIST] Load Checklist Form Success';

export class CheckIfCrossFormPopulationsNeededActionChecklist implements Action {
  readonly type = CHECK_IF_CROSS_FORM_POPULATIONS_NEEDED_CHECKLIST;

  constructor(public payload: any) { }
}

export class UpdateChecklistFormAction implements Action {
  readonly type = UPDATE_CHECKLIST_FORM;

  constructor(public payload: any) { }
}

export class LoadChecklistFormAction implements Action {
  readonly type = LOAD_CHECKLIST_FORM_SUCCESS;

  constructor(public payload: any) { }
}

export type Actions = UpdateChecklistFormAction
  | LoadChecklistFormAction
  | ClearEprAction
  | LoadEprSuccessAction
  | AddProjectTrackerFormAction
  | DeleteProjectTrackerFormAction
  | CheckIfCrossFormPopulationsNeededActionChecklist
  | SelectProjectAction
  | ResetProjectIndicesActions
  | CopyEprAction;
