import {NgModule} from '@angular/core';
import {
  CVSAlertModule,
  CVSAutocompleteSelectModule,
  CVSBaseModule,
  CVSPageNotFoundModule,
  CVSPopoverButtonModule,
  CVSToggleSwitchModule
} from 'angular-component-library';

// All Angular-Component-Library Modules to be used in your project should be added through this file
@NgModule({
    imports: [CVSBaseModule, CVSPageNotFoundModule, CVSAlertModule, CVSPopoverButtonModule, CVSToggleSwitchModule,CVSAutocompleteSelectModule],
    exports: [CVSBaseModule, CVSPageNotFoundModule, CVSAlertModule, CVSPopoverButtonModule, CVSToggleSwitchModule,CVSAutocompleteSelectModule],
})
export class MyACLModule { }
