/* eslint-disable */
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, debounceTime, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import * as header from '../actions/header.action';
import {LOAD_QUESTIONNAIRE_FORM_SUCCESS} from '../actions/questionnaire.action';
import {LOAD_PROJECT_TRACKER_SUCCESS} from '../actions/project-tracker.action';
import {EprService} from '../services/epr.service';
import {NoopAction} from '../actions/noop.action';
import {Router} from '@angular/router';
import {
  DELETE_EPR,
  DeleteEprAction,
  DeleteEprSuccessAction,
  LOAD_EPR,
  LOAD_EPR_SUCCESS,
  LoadEprAction,
  ResetProjectIndicesActions,
  SAVE_EPR,
  START_EDIT,
  StartEditAction,
  StartEditFailureAction,
  StartEditSuccessAction,
  STOP_EDIT,
  StopEditAction,
  PREVIOUS_DATA, CHANGED_QUESTIONNARIE_RECORD, CHANGED_QUESTIONNARIE_RECORD_SUCCESS, CHANGED_QUESTIONNARIE_RECORD_FAILURE
} from '../actions/epr.action';
import {
  LOAD_TESTING_SUCCESS
} from '../actions/testing.action';
import {LOAD_CHECKLIST_FORM_SUCCESS} from '../actions/checklist.action';
import {LOAD_ELIGIBILITY_AGREEMENT_FORM} from '../actions/eligibility-agreement.action';
import {Store} from '@ngrx/store';
import {
  AppState, getChecklistFieldsEnabledState,
  getChecklistState,
  getEligibilityAgreement, getEprId,
  getEPRState,
  getHeaderState,
  getProjectTrackerState,
  getQuestionnaireState,
  getTestingState
} from '../reducers';
import {CVSAlertService, CVSAlertType} from 'angular-component-library';
import {POPULATE_ALL_CHECKLIST_FIELDS_ENABLED_STATES} from '../actions/checklist-fields-enabled.action';
import {ChangedQuestionnaireRecord} from '../models/ChangedQuestionnaireRecord';
import {id} from '../test-utils/QuestionnaireFieldHyperlinks';

@Injectable()
export class EprEffects {

  debounce = 300;
  eprId: any;
  questionnaireId: any;
  pli: any;

  constructor(
    private actions$: Actions,
    private eprService: EprService,
    private store$: Store<AppState>,
    private router: Router,
    private alertService: CVSAlertService,
  ) {
  }

  startEdit$ = createEffect(() => this.actions$.pipe(
      ofType<StartEditAction>(START_EDIT),
      withLatestFrom(
        this.store$.select(getEPRState)
      ),
      mergeMap(([action, epr]) =>
        this.eprService.updateEprEditor(epr.id, action.payload, true)
          .pipe(
            tap(),
            map(() => new StartEditSuccessAction(action.payload)),
            catchError(response => {
              this.alertService.sendAlert({
                headline: response.error.message,
                alertType: CVSAlertType.Error,
                alertDocumentHolderName: '#epr-alert',
              });
              return of(new StartEditFailureAction());
            })
          ))
    )
  );

  stopEdit$ = createEffect(() => this.actions$.pipe(
      ofType(STOP_EDIT),
      withLatestFrom(
        this.store$.select(getEPRState)
      ),
      mergeMap(([action, epr]) =>
        this.eprService.updateEprEditor(epr.id, null, false)
          .pipe(
            tap(),
            map(() => new NoopAction()),
            catchError(error => {
              return of(new NoopAction());
            })
          ))
    )
  );

  loadEpr$ = createEffect(() => this.actions$.pipe(
      ofType<LoadEprAction>(LOAD_EPR),
      mergeMap(action => this.eprService.getEprById(action.payload, action.view)
        .pipe(
          tap(eprValue => {
            // console.error('=============>', eprValue);
            this.store$.dispatch(new ResetProjectIndicesActions());
          }),
          switchMap(eprValue =>
            this.loadSwitchMap(eprValue, "load")),
          tap(epr => {

            if (!action.download) {
              this.router.navigateByUrl('epr');
            } else {
              if (epr.type === LOAD_ELIGIBILITY_AGREEMENT_FORM) {
                this.eprService.fullyLoaded.next(true)
              }
            }

          }),
          catchError(error => {
            return of(new NoopAction());
          })
        ))
    )
  );

  deleteEpr$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteEprAction>(DELETE_EPR),
    mergeMap(action => this.eprService.deleteEpr(action.payload)
      .pipe(
        map(() => new DeleteEprSuccessAction()),
        tap(() => this.router.navigateByUrl('')),
        catchError(error => {
          return of(new NoopAction());
        })
      )
    )
  ));

  saveEpr$ = createEffect(() => this.actions$.pipe(
      debounceTime(this.debounce),
      ofType(SAVE_EPR),
      withLatestFrom(
        this.store$.select(getHeaderState),
        // this.store$.select(getProjectTrackerState),
        // this.store$.select(getChecklistState),
        // this.store$.select(getChecklistFieldsEnabledState),
        // this.store$.select(getTestingState),
        this.store$.select(getQuestionnaireState),
        this.store$.select(getEligibilityAgreement),
        this.store$.select(getEPRState),
      ),
      mergeMap((
          [_, header, 
           /* projectTracker, checklist, checklistFieldsEnabled, testing, */
             questionnaire, eligibilityAgreement, eprMetadata]) => {
          if (header.backupecDefault.id && !header["obec"]) {
            header.backupec.id = null;
          }

          return this.eprService.rebuildAndSaveEpr(header, questionnaire, eligibilityAgreement, eprMetadata)
            .pipe(
              tap((epr) => {
                /*
                if (testing.testIterationsToDelete.length > 0) {
                  this.eprService.deleteTestIteration(testing.testIterationsToDelete).subscribe(() => {
                    if (projectTracker.projectsToDelete.length > 0) {
                      this.eprService.deleteProjectIteration(projectTracker.projectsToDelete).subscribe();
                    }
                  });
                }

                if (testing.testIterationsToDelete.length === 0 && projectTracker.projectsToDelete.length > 0) {
                  this.eprService.deleteProjectIteration(projectTracker.projectsToDelete).subscribe();
                }
                */
                this.store$.dispatch(new StopEditAction());
                if(this.eprService.ssoeEnabled){
                if(!this.eprService.carrierLevelPayloadList[0].eprId && !this.eprService.carrierLevelPayloadList[0].questionnaireId){
                  this.constructCarrierLevelPayload(epr.response);
                  this.eprService.carrierLevelPayloadList = this.eprService.carrierLevelPayloadList.map(x=>{
                    x.eprId=this.eprId;
                    x.questionnaireId=  this.questionnaireId;
                    x.pli=this.pli;
                    return x;
                  });
                }
                  this.eprService.saveCarrierLevel(this.eprService.carrierLevelPayloadList).subscribe((res) => {
                    if (res) {
                      this.eprService.carrierLevelPayloadList = res.response;
                      this.eprService.triggerGetCarrierPliApi(true);
                    }
                  });
                }
                /*this.alertService.sendAlert({
                  headline: 'Saved successfully',
                  alertType: CVSAlertType.Success,
                  alertDocumentHolderName: '#epr-alert',
                })*/
                ;
              }),
              switchMap(eprValue => this.loadSwitchMap(eprValue, "save")),
             /* tap((epr) => {
                if (epr.type === '[EPR] Load Epr Success ' && this.eprService.currentTab === "Projects") {
                  this.eprService.reloadPrj.next(true)
                } else if (epr.type === '[EPR] Load Epr Success ' && this.eprService.currentTab === "Test Results") {
                  this.eprService.reloadTest.next(true)
                }
              }), */
              catchError(error => {
                this.alertService.sendAlert({
                  headline: error.error.message,
                  alertType: CVSAlertType.Error,
                  alertDocumentHolderName: '#epr-alert',
                });
                return of(new NoopAction());
              })
            )
        }
      )
    )
  );

  loadSwitchMap(eprValue: any, loadType) {
    this.eprService.dashboardRowClicked.next(false);
    let actionData = [
      {
        type: header.LOAD_HEADER_FORM_SUCCESS,
        payload: loadType === "save" ? eprValue.response['headerEntity'] : eprValue['headerEntity']
      },

     /* {
        type: LOAD_PROJECT_TRACKER_SUCCESS,
        payload: loadType === "save" ? eprValue.response['projectTrackerEntities'] : eprValue['projectTrackerEntities']
      },
      {
        type: LOAD_CHECKLIST_FORM_SUCCESS,
        payload: loadType === "save" ? eprValue.response.projectTrackerEntities.map(project => project.checklistEntity) :
          eprValue.projectTrackerEntities.map(project => project.checklistEntity)
      },
      {
        type: LOAD_TESTING_SUCCESS,
        payload: loadType === "save" ? eprValue.response.projectTrackerEntities.map(project => project.testingEntity) :
          eprValue.projectTrackerEntities.map(project => project.testingEntity)
      },  */
      {
        type: LOAD_QUESTIONNAIRE_FORM_SUCCESS,
        payload: loadType === "save" ? eprValue.response['questionnaireEntity'] : eprValue['questionnaireEntity']
      },
      {
        type: LOAD_ELIGIBILITY_AGREEMENT_FORM,
        payload: loadType === "save" ? eprValue.response['eligibilityAgreementEntity'] : eprValue['eligibilityAgreementEntity']
      },
      {
        type: LOAD_EPR_SUCCESS,
        payload: loadType === "save" ? eprValue.response : eprValue
      },
      /*
      {
        type: POPULATE_ALL_CHECKLIST_FIELDS_ENABLED_STATES,
        payload: loadType === "save" ? eprValue.response['projectTrackerEntities'].map(project => project.checklistEntity.checklistFieldsEnabled) :
          eprValue['projectTrackerEntities'].map(project => project.checklistEntity.checklistFieldsEnabled)
      }
      */
    ];
    if (loadType === "load") {
      actionData.push({
        type: PREVIOUS_DATA,
        payload: eprValue
      })
    } else if (loadType === "save") {
      actionData.push({
        type: PREVIOUS_DATA,
        payload: eprValue.response
      })
      this.alertService.sendAlert({
        headline: eprValue.errorMessage != null ? eprValue.errorMessage[0] : 'Saved successfully',
        alertType: eprValue.errorMessage != null ? CVSAlertType.Warning : CVSAlertType.Success,
        alertDocumentHolderName: '#epr-alert',
      });
    }
    return actionData
  }

  saveChangedQuestionnaireRecord$ = createEffect(() => this.actions$.pipe(
      ofType(CHANGED_QUESTIONNARIE_RECORD),
      mergeMap(action => this.eprService.saveChangedQuestionnaireData({
          questionnairedata: action['payload']['questionnairedata'],
          headerdata: action['payload']['headerdata']
        }, action['payload']['eprId'])
          .pipe(
            map(changedQuestionnaireRecord => ({
              type: CHANGED_QUESTIONNARIE_RECORD_SUCCESS,
              payload: changedQuestionnaireRecord as ChangedQuestionnaireRecord
            })),
            catchError(() => of({type: CHANGED_QUESTIONNARIE_RECORD_FAILURE}))
          )
      )
    )
  );


  constructCarrierLevelPayload(epr: any) {
    this.pli= epr.headerEntity.pli;
    this.eprId= epr.id;
    this.questionnaireId= epr.questionnaireEntity.id;
  }
}
