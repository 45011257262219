import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EprService } from 'src/app/services/epr.service';

@Component({
  selector: 'app-add-report-format-field',
  templateUrl: './addReportFormatField.component.html',
  styleUrls: ['./addReportFormatField.component.scss']
})
export class AddReportFormatFieldComponent implements OnInit {
  selectedField: any;
  fields:any;
  cddLabel = 'CDD Other';
  startPosition = 1;
    constructor( @Inject(MAT_DIALOG_DATA) public data:any , public dialogRef: MatDialogRef<AddReportFormatFieldComponent>,
                private eprService: EprService) {

    }
  ngOnInit() {
          this.eprService.getReportFields().subscribe(res1 => {
            if(res1){
              this.fields = res1.filter(field=>{ return !this.data.includes(field)})
            }
           

        });
      }

 
  

  onCancel(event:any) {
    this.dialogRef.close();
    event.stopPropagation();
  }
  closeModal(event:any) {
    this.dialogRef.close();
    event.stopPropagation();
  }
  onSave(event){
    let info: any = {reportField:this.selectedField, reportSpaceUsed:0, reportActive:true}
    if(this.selectedField === 'cdd' || this.selectedField === 'CDD'){
      info = {cdd:true, reportField:this.cddLabel,startPosition:this.startPosition, reportSpaceUsed:0, reportActive:true}
    }
    this.dialogRef.close(info);
    event.stopPropagation();
  }

}
