export const rteEprHistoryColumns = [
  {headerName: 'Client Name', field: 'clientName', sortable: true, filter: true, resizable: true},
  {headerName: 'Carrier', field: 'carrier', sortable: true, filter: true, resizable: true},
  {headerName: 'PLI', field: 'pli', sortable: true, filter: true, resizable: true},
  {headerName: 'Assigned EC', field: 'assignedEc', sortable: true, filter: true, resizable: true},
  {headerName: 'User', field: 'eprAuditUser', sortable: true, filter: true, resizable: true},
  {headerName: 'Time Stamp', field: 'timeStamp', sortable: true, filter: true, resizable: true},
  {headerName: 'Section', field: 'section', sortable: true, filter: true, resizable: true},
  {headerName: 'Sub Selection', field: 'subSelection', sortable: true, filter: true, resizable: true},
  {headerName: 'Field', field: 'eprHistoryField', sortable: true, filter: true, resizable: true},
  {headerName: 'Previous Value', field: 'previousValue', sortable: true, filter: true, resizable: true,
  tooltipField: 'newValue',
  tooltipComponentParams: { color: '#ececec' }},
  {headerName: 'New Value', field: 'newValue', sortable: true, filter: true, resizable: true,
  tooltipField: 'newValue',
  tooltipComponentParams: { color: '#ececec' }},
];
