import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Subscription } from "rxjs";
import { pairwise, startWith } from "rxjs/operators";
import { columnDefs } from "../admin/classification-code/classificationColumnSets";

@Component({
  selector: "app-select-renderer",
  template: `
    <div *ngIf="formGroup" [formGroup]="formGroup">
      <mat-select [ngClass]="formGroup.get(key)?.hasError('required') ? 'invalid':''"  [formControlName]="key" [id]="rowId">
        <mat-option
          *ngFor="let option of options"
          value="{{ option.value }}"
          (click)="onOptionSelect(option.value)"
        >
          {{ option.label }}</mat-option
        >
      </mat-select>
    </div>
  `,
})
export class GridSelectFormRendererComponent 
  implements ICellRendererAngularComp
{
  public params: any;
  options: Array<any> = [];
  constructor() {}

  formGroup: FormGroup;
  key;
  value;
  columnName: string;
  rowId: number;
  previousValue: any = null;
  agInit(params: any) {
    this.params = params;
    this.columnName = params.colDef.headerName;
    this.key = params.colDef.field;
    this.options = params?.options ? params.options : [];
    // params.context.createKey(params.columnApi, params.column);
    this.value = params.data[this.key];
    this.rowId = params.data.rowId;
    //params.node.id; //work as form array index
    this.formGroup = params.context.formGroupArray[this.rowId];
    if (
      this.formGroup &&
      !this.formGroup.get(this.key)?.value &&
      ! this.params.context.prefixGrid

    ) {
      this.formGroup.get(this.key)?.patchValue("None");
    }
   
    if(this.key === "contactEwfAccessType"){
      this.previousValue = this.formGroup.get("contactEwfAccessType").getRawValue();
      this.updateLabel(this.previousValue); //for first time when data loads up
    }
  
  }
  onOptionSelect(value){
    if (this.params.context.prefixGrid){
      return
    }
    let prev = this.previousValue;
          let current = value;
          if(current!==prev){
            this.updateLabel(current);
            this.resetLabel(prev);
          }

          if (current === "Remove User") {
            this.formGroup.get("contactEwfAccessStatus").patchValue("I");
            this.params.context.componentParent.setGridData();
          } else {
            this.formGroup.get("contactEwfAccessStatus").patchValue("A");
          }
          this.openUserAccess({ prev, current }, this.rowId);
          this.previousValue = current;
  }

  resetLabel(value) {
    let index = this.options.findIndex((op) => op.value === value);
    if (value === "Linked") {
      this.options[index] = { label: "Link", value: "Linked" };
    } else if (value === "Remove User") {
      this.options[index] = { label: "Remove User", value: "Remove User" };
    }
  }
  updateLabel(value){
    let index = this.options.findIndex((op) => op.value === value);
    if (value === "Linked") {
      this.options[index] = { label: "Linked", value: "Linked" };
    } else if (value === "Remove User") {
      this.options[index] = { label: "Removed User", value: "Remove User" };
    }
  }

  openUserAccess(selected, rowId) {
    if (selected.current === "Linked") {
      let rowData = this.formGroup.getRawValue();
      let selectComponent = this;
      this.params.context.componentParent.openEPRUserModel(
        rowData,
        selected,
        rowId,
        selectComponent
      );
    }

  }
  refresh(params: any): boolean {
    return false;
  }
  destroy() {

  }
}
