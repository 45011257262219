
import { Directive, HostListener, ElementRef, Input } from '@angular/core';
@Directive({
  selector: '[appAlphaNumeric]'
})
export class AlphaNumericDirective {

  regexStr = '^[a-zA-Z0-9_-]*$';
  @Input() isAlphaNumeric: boolean;

  constructor(private el: ElementRef) { }


  @HostListener('keydown', ['$event']) onKeyDown(event) {
    if (event.keyCode === 32) {
      return true;
     } else if (event.keyCode === 127) {
      if (event.target.value.trim().length === 0) {
        event.target.value = '';
      }
    } else {
     return new RegExp(this.regexStr).test(event.key);
 }
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    alert('c');
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {
 alert(this.el.nativeElement.value);
 this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z ]/g, '').replace(/\s/g, '');
 event.preventDefault();

    }, 1);
  }
}
