export function calculateStatus(phase: string, dates: StatusDates): string {
  if (dates === undefined) {
  return;
  }
  if (phase === 'Not Started' || phase === '') {
    return StatusColor.GREY;
  } else if (phase === 'On Hold' || phase === 'Cancelled') {
    return StatusColor.BLACK;
  } else if (phase === 'Completed') {
    if (dates.baselineDueDate !== undefined && dates.baselineCompleteDate !== undefined &&
       dateExists(dates.baselineDueDate) && !dateExists(dates.baselineCompleteDate)) {
      return StatusColor.BLUE;
    } else if (dates.discoveryDueDate !== undefined && dates.discoveryCompleteDate !== undefined &&
      dateExists(dates.discoveryDueDate) && !dateExists(dates.discoveryCompleteDate)) {
      return StatusColor.BLUE;
    } else if (dates.idCardsDueDate !== undefined && dates.idCardsCompleteDate !== undefined &&
      dateExists(dates.idCardsDueDate) && !dateExists(dates.idCardsCompleteDate)) {
      return StatusColor.BLUE;
    } else if (dates.setupDueDate !== undefined && dates.setupCompleteDate !== undefined &&
      dateExists(dates.setupDueDate) && !dateExists(dates.setupCompleteDate)) {
      return StatusColor.BLUE;
    } else if (dates.testingDueDate !== undefined && dates.testingCompleteDate !== undefined &&
      dateExists(dates.testingDueDate) && !dateExists(dates.testingCompleteDate)) {
      return StatusColor.BLUE;
    } else {
      return StatusColor.BLACK;
    }
  } else {
    return calculateActiveStatus(dates);
  }
}

export function calculateActiveStatus(dates: StatusDates) {

    const sectionStatus = [
    calculateChecklistSectionStatus(dates.baselineDueDate, dates.baselineCompleteDate),
    calculateChecklistSectionStatus(dates.discoveryDueDate, dates.discoveryCompleteDate),
    calculateChecklistSectionStatus(dates.idCardsDueDate, dates.idCardsCompleteDate),
    calculateChecklistSectionStatus(dates.setupDueDate, dates.setupCompleteDate),
    calculateChecklistSectionStatus(dates.testingDueDate, dates.testingCompleteDate)
  ];

    if (sectionStatus.includes(StatusColor.RED)) {
    return StatusColor.RED;
  } else if (sectionStatus.includes(StatusColor.YELLOW)) {
    return StatusColor.YELLOW;
  } else {
    return StatusColor.GREEN;
  }

}

function dateExists(date: string): boolean {
  // empty dates can be an empty string or null
  return date !== '' && date !== null;
}

function calculateChecklistSectionStatus(dueDate: string, completeDate: string): StatusColor {
  if (dateExists(dueDate) && !dateExists(completeDate)) {
    const today = new Date();
    if (today > new Date(dueDate)) {
      return StatusColor.RED;
    } else if (today > getDateMinus5Days(dueDate)) {
      return StatusColor.YELLOW;
    }
  }
  return StatusColor.GREEN;
}

function getDateMinus5Days(date: string): Date {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - 5);
  return newDate;
}

export interface StatusDates {
  discoveryDueDate: string;
  discoveryCompleteDate: string;
  setupDueDate: string;
  setupCompleteDate: string;
  testingDueDate: string;
  testingCompleteDate: string;
  baselineDueDate: string;
  baselineCompleteDate: string;
  idCardsDueDate: string;
  idCardsCompleteDate: string;
}

export enum StatusColor {
  GREEN = 'Green',
  YELLOW = 'Yellow',
  RED = 'Red',
  BLUE = 'Blue',
  GREY = 'Grey',
  BLACK = 'Black'
}
