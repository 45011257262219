
<div class="row" style="min-width:85rem">
    <h2 mat-dialog-title style="font-weight: bold;">Import Mapping Document</h2>
    
    <ng-container >
    <mat-dialog-content   class="">
        <form [formGroup]="form">
            <div class="search-add-container">
                <span>
                  <mat-form-field appearance="standard">
                    <input matInput placeholder="Search" id = "search"[formControl]="form.controls.search" >
                    <mat-icon svgIcon="search--s"></mat-icon>
                  </mat-form-field>
                </span>
             
            </div>
        </form>
        <ag-grid-angular
        class="ag-theme-alpine"
        #gridImport
        editType="fullRow"
        rowSelection="single"
        [suppressClickEdit]="true"
        (rowClicked)='onTableRowClicked($event)'
        >
        <!-- (cellClicked)="onCellClicked($event)" -->
        <!-- [suppressClickEdit]="true" -->
        <!-- (rowClicked)='onTableRowClicked($event)'
            (dragStopped)="onDragStopped()"
            (sortChanged)="onSortChange()"
            (filterChanged)="onFilterChange( $event)" -->
        </ag-grid-angular>
        
    </mat-dialog-content>
    <mat-dialog-actions class="row" style="justify-content: flex-end;">
        <button class="mat-raised-button col-4"style="padding: 8px 20px;" (click)="close()">Cancel</button>
        <button class="mat-raised-button mat-primary col-4"style="padding: 8px 20px;" (click)="save()">Import</button>
    </mat-dialog-actions>
    
    </ng-container>
    </div>