import { MessageSupressionService } from './../../services/message-supression.service';
import { ThresholdTranslationsService } from './../../services/threshold-translations.service';
import { ThresholdService } from './../../services/threshold.service';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { AppState, getDataListOptions } from '../../reducers';
import {
  LoadMasterDataSetAction,
  SaveMasterDataSetAction,
  UpdateMasterDataSetAction
} from '../../actions/data-management.action';
import { DropdownOption } from '../../models/DropdownOption';
import { Subscription, BehaviorSubject } from 'rxjs';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AgGridAngular } from 'ag-grid-angular';
import { holidayColumns } from './data-management-columns';
import { DeleteBtnCellRendererComponent } from '../../common/DeleteBtnCellRenderer';
import { FiletrackerService } from '../../services/filetracker.service';
import { AdminAlertTypeService } from 'src/app/services/admin-alert-type.service';
import { MappingApiService } from 'src/app/services/mapping-api.service';
import { DataManagementService } from 'src/app/services/data-management.service';
import { MatDialog, MatDialogRef , MatDialogModule, MatDialogConfig} from '@angular/material/dialog';
import { AddEditItemPopupComponent } from './add-edit-item-popup/add-edit-item-popup.component';
import { AddEditReportingItemPopupComponent } from "./add-edit-reporting-item-popup/add-edit-reporting-item-popup.component";
import {saveAs} from "file-saver";

class reportMessageModel {
  data:any;
  type: string;
}

@Component({
  selector: 'app-data-management',
  templateUrl: './data-management.component.html',
  styleUrls: ['./data-management.component.scss']
})
export class DataManagementComponent implements OnDestroy {
  @ViewChild('addOptionSpan') addOptionField: ElementRef;
  @ViewChild('holidayGrid') holidayGrid: AgGridAngular;
  masterDataList = [
    { value: 'idType', displayName: 'ID Types' },
    { value: 'pcAutogenProgram', displayName: 'PC Autogen Program' },
    { value: 'restrictedDataClients', displayName: 'Restricted Data Clients' },
    { value: 'fileFormat', displayName: 'File Format' },
    { value: 'vendor', displayName: 'Vendors' },
    { value: 'tbaDate', displayName: 'Term By Absence Date' },
    { value: 'fileRouting', displayName: 'File Routing' },
    { value: 'holidays', displayName: 'Holidays' },
    { value: 'translations', displayName: 'Translations' },
    { value: 'threshold', displayName: 'Threshold Translation' },
    { value: 'msgSupression', displayName: 'Message Suppression' },
    { value: 'mapType', displayName: 'Map Type' },
    { value: 'rejectActions', displayName: 'Reactions' },
    { value: 'adminAlertType', displayName: 'Admin Alert Types' },
    { value: 'parameterType', displayName: 'Parameter Type'},
    { value: 'fileType', displayName: 'File Type'},
    { value: 'parameterLevel', displayName: 'Parameter Level'},
    { value: 'isa12', displayName: 'ISA12'},
    { value: 'gs08', displayName: 'GS08'},
    { value: 'st03', displayName: 'ST03'},
    { value: 'projectType', displayName: 'Project Type'},
    { value:'mappingInstructions', displayName: 'Mapping Instructions'},
    { value: 'reportingMessageTypes', displayName: 'Reporting Message Types'},
    { value: 'validations', displayName: 'Validations'},
    { value: 'fieldActions', displayName: 'Field Actions' },
    { value: 'reportingMessageCodes', displayName: 'Reporting Message Codes' },



  ] as DropdownOption[];
  //, ,
  dropDownMenuList = [{ type: 'validations', title: "Add New Validation" },
  { type: 'fieldActions', title:"Add New Field Action"},
  { type: 'rejectActions', title: "Add New Reject Action" },
  { type: 'reportingMessageTypes', title: "Add New Reporting Message Types" },
  { type: 'reportingMessageCodes', title: "Add New Reporting Message Codes" },]
  dataListOptions: string[];
  holidaysData = [];
  dataListSubscription: Subscription;
  selectedDataListOptions: number[] = [];
  currentlyDisplayingOptions = false;
  currentDropdownSelection: string;
  hlGridHeight = 350;
  addOptionDialog = false;
  optionsToAdd = '';
  contextObj: any;
  isSaveDisabled = false;
  frameworkComponents: any;
  showDelete = true;
  maprequirementinstructions:string = "maprequirementinstructions";
  mappingComments ;any;
  commentsReady: boolean = false;
  mappinInstructionsId: any;
  dialogConfig:any = new MatDialogConfig();
  reportTypes: any = [];
  messageCodeFlag: boolean;
  messageCodeRes: any[];
  exportClicked = false;
  exportRowData: any=[];


  constructor(
    private store: Store<AppState>,
    private alertService: CVSAlertService,
    private filetrackerService: FiletrackerService,
    private cdRef: ChangeDetectorRef,
    private thresholdService: ThresholdTranslationsService,
    private msgSupressionService: MessageSupressionService,
    private adminAlertTypeService: AdminAlertTypeService,
    public mappingapi: MappingApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddEditItemPopupComponent>,
    public dataMangementApi:DataManagementService ) {
    this.loadData();
    this.contextObj = {
      componentParent: this
    };
    this.frameworkComponents = {
      deleteAction: DeleteBtnCellRendererComponent
    };
    dataMangementApi.addRowdata.subscribe(res =>{
      this.exportRowData= res;
    })
  }
  loadData() {
    this.dataListSubscription = this.store.select(getDataListOptions).subscribe(dataListOptions => {
      if(dataListOptions){
        this.dataListOptions = [];
        this.holidaysData = [];
        if (this.currentDropdownSelection === 'holidays') {
          const holidayKey=dataListOptions.some(item => item.hasOwnProperty('holidayType'));
          if(holidayKey){
            this.sortDataList(dataListOptions);
          }
        } else {
          if (dataListOptions) {
            if(dataListOptions.listType !== "maprequirementinstructions"){
              const dataListOptionsWithEscapeChars = dataListOptions.slice();
              dataListOptionsWithEscapeChars.forEach(option => {
                try {
                  this.dataListOptions.push(option.replace(/\\,/g, ','));
                } catch (e) {

                }
              });
            }

          }
        }
      }
    });


  }
  sortDataList(dataListOptions) {
    this.holidaysData = [];
    const categoryList = dataListOptions.reduce((r, a) => {
      r[a.holidayType] = [...r[a.holidayType] || [], a];
      return r;
    }, {});
    const expiredList: any = [];
    Object.keys(categoryList).map(key => {
      if (categoryList[key].length >= 1) {
        const expType = categoryList[key].reduce((prevVal, currentVal) => {
          return (new Date(prevVal.holidayDate).getTime() > new Date(currentVal.holidayDate).getTime()) ? prevVal : currentVal;
        });
        expiredList.push(expType);
      }
    });
    const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
    expiredList.map(el => {
      dataListOptions.map(hType => {
        const hDate = new Date(new Date(hType.holidayDate).getUTCFullYear(),
          new Date(hType.holidayDate).getUTCMonth(), new Date(hType.holidayDate).getUTCDate(), 0, 0, 0);
        if (new Date(hDate).getTime() < new Date(currentDate).getTime()) {
          hType.isExpired = true;
        } else {
          hType.isExpired = false;
        }
        /* to show only latest date of the same date type*/
        // if (el.holidayType.trim().toLowerCase() ===  hType.holidayType.trim().toLowerCase()) {
        //   if (el.companyHolidaySeq ===  hType.companyHolidaySeq) {
        //     hType.isExpired = false;
        //   } else {
        //     if (new Date(el.holidayDate).getTime() === new Date(hType.holidayDate).getTime()) {
        //       hType.isExpired = false;
        //     } else {
        //       hType.isExpired = true;
        //     }
        //   }
        // }
      });
    });
    this.holidaysData = dataListOptions;
    this.hlGridHeight = (this.holidaysData.length * 42) + 52;
    if (this.holidayGrid) {
      this.holidayGrid.api.setColumnDefs(holidayColumns);
      // this.holidayGrid.api.sizeColumnsToFit();
      this.holidayGrid.api.redrawRows();
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    const indexInSelected = this.selectedDataListOptions.indexOf(event.previousIndex);
    if (indexInSelected > -1) {
      this.selectedDataListOptions.splice(indexInSelected, 1, event.currentIndex);
    }

    moveItemInArray(this.dataListOptions, event.previousIndex, event.currentIndex);
    this.updateMasterDataList();
    this.alertService.clearAlert('#master-data-alert');
  }

  onSelectionChange($event: MatSelectChange) {
    this.currentlyDisplayingOptions = true;
    let noStoreActionList = ['validations','fieldActions','reportingMessageTypes', 'rejectActions','reportingMessageCodes']
    if(!noStoreActionList.includes(this.currentDropdownSelection)){
      this.store.dispatch(new LoadMasterDataSetAction($event.value));
    }

    this.alertService.clearAlert('#master-data-alert');
    this.selectedDataListOptions = [];
    this.loadData();
    if (this.currentDropdownSelection === 'threshold') {
      this.isSaveDisabled = true;
    } else if (this.currentDropdownSelection === 'msgSupression') {
      this.isSaveDisabled = true;
    } else if (this.currentDropdownSelection === 'mapType') {
      this.isSaveDisabled = true;
    }else if (this.currentDropdownSelection === 'rejectActions') {
      this.isSaveDisabled = true;
    }  else if (this.currentDropdownSelection === 'adminAlertType') {
      this.isSaveDisabled = true;
    }
    else if (this.currentDropdownSelection === 'isa12') {
      this.isSaveDisabled = true;
    }else if (this.currentDropdownSelection === 'gs08') {
      this.isSaveDisabled = true;
    }else if (this.currentDropdownSelection === 'st03') {
      this.isSaveDisabled = true;
    }else if (this.currentDropdownSelection === 'mappingInstructions') {
      this.commentsReady = false
      this.mappingapi.getDataManagementMapInstrTemplate(this.maprequirementinstructions).subscribe(res=>{
        this.mappinInstructionsId = res.id
        this.mappingComments = res.options
        this.commentsReady = true;
      })
      this.isSaveDisabled = true;
    } else if (this.currentDropdownSelection === 'validations') {
      this.isSaveDisabled = true;
      this.msgSupressionService.addNewValidationRow$.next(null);
    } else if (this.currentDropdownSelection === 'fieldActions') {
      this.isSaveDisabled = true;
      this.msgSupressionService.addNewFieldActionsRow$.next(null);
    } else if (this.currentDropdownSelection === 'reportingMessageTypes') {
      this.isSaveDisabled = true;
      this.msgSupressionService.addNewReportingMessageTypesRow$.next(null);
    } else if (this.currentDropdownSelection === 'reportingMessageCodes') {
      this.getReportMessageCodesTypes();
      this.isSaveDisabled = true;
      this.msgSupressionService.addNewReportMessageCodesRow$.next(null);
    } else {
      this.isSaveDisabled = false;
    }
  }

  getReportMessageCodesTypes(){
    this.msgSupressionService.getReportingMessageTypesRows().subscribe(res => {
      if(res && res.length && res.length> 0) {
        this.reportTypes = res;
        this.dataMangementApi.reportTypes.next(res);
      }
    });
  }
  onGridReady(params) {
    this.holidayGrid = params;
    this.hlGridHeight = this.holidaysData.length * 51;
    this.holidayGrid.api.setColumnDefs(holidayColumns);
    // this.holidayGrid.api.sizeColumnsToFit();
  }
  public deleteTableRecord(node, colId) {
    if (node.data.isNew) {
      this.holidayGrid.api.removeItems([node], false);
      this.hlGridHeight = (this.holidayGrid.api.getModel()?.getRowCount() * 42) + 52;
    } else {
      this.filetrackerService.deleteHoliday(node.data.holidayType).subscribe(resp => {
        if (resp > 0) {
          // this.filetrackerContact.api.applyTransaction({remove: node.data});
          this.holidayGrid.api.removeItems([node], false);
          this.hlGridHeight = (this.holidayGrid.api.getModel()?.getRowCount() * 42) + 52;
        }
      });
    }

  }
  saveHolidayList() {
    const updatedRec = [];
    this.holidayGrid.api.forEachNode(node => {
      if (node.data.isUpdated) {
        delete node.data.isUpdated;
        delete node.data.isNew;
        updatedRec.push(node.data);
      }
    });
    this.filetrackerService.saveHolidays(updatedRec).subscribe(resp => {
      this.alertService.sendAlert({
        headline: 'Save successful!',
        alertType: CVSAlertType.Success,
        removedAfterMilliseconds: 5000,
        alertDocumentHolderName: '#master-data-alert',
      });
      this.store.dispatch(new LoadMasterDataSetAction('holidays'));
    });
  }
  saveThresholdList() {
    this.thresholdService.saveNewRecords$.next(true);
  }
  saveMsgSupressList() {
    this.msgSupressionService.saveNewMsgSupRecords$.next(true);
  }
  saveMapTypeList() {
    this.msgSupressionService.saveNewMapTypeRecords$.next(true);
  }
  saveRejectActionList(){
    this.msgSupressionService.saveNewRejectActionrecords$.next(true);
  }
  saveValidationList() {
    this.msgSupressionService.saveNewValidationRecords$.next(true);
  }

  saveFieldActionsList() {
    this.msgSupressionService.saveNewFieldActionsRow$.next(true);
  }
  saveReportingMessageTypesList() {
    this.msgSupressionService.saveNewReportingMessageTypesRow$.next(true);
  }
  saveReportingMessageCodesActionsList() {
    this.msgSupressionService.saveNewReportingMessageCodes$.next(true);
  }
  saveAdminAlertList() {
    this.adminAlertTypeService.saveNewAdminRecords$.next(true);
  }
  saveVersionList() {
    this.msgSupressionService.saveVersionRecords$.next(true);
  }
    saveMasterDataList() {
    if (this.currentDropdownSelection === 'holidays') {
      this.saveHolidayList();
    } else if (this.currentDropdownSelection === 'threshold') {
      this.isSaveDisabled = true;
      this.saveThresholdList();
    } else if (this.currentDropdownSelection === 'msgSupression') {
      this.saveMsgSupressList();
      this.isSaveDisabled = true;
    } else if (this.currentDropdownSelection === 'mapType') {
      this.saveMapTypeList();
      this.isSaveDisabled = true;
    }  else if (this.currentDropdownSelection === 'rejectActions') {
      this.saveRejectActionList();
      this.isSaveDisabled = true;
    } else if (this.currentDropdownSelection === 'adminAlertType') {
      this.isSaveDisabled = true;
      this.saveAdminAlertList();
    } else if (this.currentDropdownSelection === 'isa12') {
      this.saveVersionList();
      this.isSaveDisabled = true;
    } else if (this.currentDropdownSelection === 'gs08') {
      this.saveVersionList();
      this.isSaveDisabled = true;
    } else if (this.currentDropdownSelection === 'st03') {
      this.saveVersionList();
      this.isSaveDisabled = true;
    } else if (this.currentDropdownSelection === 'validations') {
      this.saveValidationList();
      this.isSaveDisabled = true;
    } else if (this.currentDropdownSelection === 'fieldActions') {
      this.saveFieldActionsList();
      this.isSaveDisabled = true;
    } else if (this.currentDropdownSelection === 'reportingMessageTypes') {
      this.saveReportingMessageTypesList();
      this.isSaveDisabled = true;
    } else if (this.currentDropdownSelection === 'mappingInstructions') {
    } else if (this.currentDropdownSelection === 'reportingMessageCodes') {
      this.saveReportingMessageCodesActionsList();
      this.isSaveDisabled = true;
    }else if (this.currentDropdownSelection === 'mappingInstructions') {
      function remove_linebreaks_ss(str) {
        let newstr = "";
        for (let i = 0; i < str.length; i++)
            if (!(str[i] == "\n" || str[i] == "\r"))
                newstr += str[i];
        return newstr
    }
      const data:any = {
        "id" : this.mappinInstructionsId,
        "listType":"maprequirementinstructions",
        "options": [remove_linebreaks_ss(this.mappingComments.join('@').toString())]
      }
      this.dataMangementApi.saveMasterDataSet(data).subscribe(res=>{
       if(res.id){
        this.alertService.sendAlert({
          headline: 'Your changes have been saved',
          alertType: CVSAlertType.Success,
          fadeClass: true,
          alertDocumentHolderName: '#master-data-alert'
        });
       }
      })
      this.isSaveDisabled = true;
    }
    else {
      if (this.addOptionDialog && this.optionsToAdd !== '') {

        const newOptions = this.optionsToAdd.split(/\r?\n/);
        if (newOptions.length > 1) {
          this.dataListOptions = this.dataListOptions.concat(newOptions);
          if (this.currentDropdownSelection === 'vendor') {
            this.dataListOptions.sort((one, two) => (one?.toUpperCase() > two?.toUpperCase() ? 1 : -1));
          }
        } else {
          this.dataListOptions.splice(0, 0, this.optionsToAdd);
          if (this.currentDropdownSelection === 'vendor') {
            this.dataListOptions.sort((one, two) => (one?.toUpperCase() > two?.toUpperCase() ? 1 : -1));
          }
        }

        this.addOptionDialog = false;
        this.optionsToAdd = '';
      }

      for (let i = 0; i < this.dataListOptions.length; i++) {
        this.dataListOptions[i] = this.dataListOptions[i].replace(/,/g, '\\,');
      }
      this.updateMasterDataList();
      this.store.dispatch(new SaveMasterDataSetAction());
    }
  }

  updateMasterDataList() {
    this.store.dispatch(new UpdateMasterDataSetAction(this.dataListOptions));
  }

  editComments(){
    this.isSaveDisabled = false
  }

  ngOnDestroy(): void {
    this.dataListSubscription.unsubscribe();
  }
  holidayRecUpdated(cell) {
    cell.data.isUpdated = true;
  }
  updateSelectedList(dataListIndex: number) {
    this.showDelete = true;
    const indexToDelete = this.selectedDataListOptions.indexOf(dataListIndex);
    if (indexToDelete > -1) { // index is -1 if nothing to delete
      this.selectedDataListOptions.splice(indexToDelete, 1);
    } else {
      this.selectedDataListOptions.push(dataListIndex);
    }
    if (this.dataListOptions.length === this.selectedDataListOptions.length) {
      this.showDelete = false;
    }
    this.alertService.clearAlert('#master-data-alert');
  }

  deleteOptions(): void {
    this.dataListOptions = this.dataListOptions.filter((value, index) => {
      return this.selectedDataListOptions.indexOf(index) === -1;
    }
    );
    this.saveMasterDataList();
    this.selectedDataListOptions = [];
  }

  addButton(): void {
    if (this.currentDropdownSelection === 'holidays') {
      const newRec = [{
        holidayType: '',
        holidayDate: '',
        hscTrnCd: 1,
        hscUserId: 'Z253897',
        hscTs: '2020-03-18T11:32:52.213+0000',
        hsuTrnCd: 1,
        hsuUserid: 'Z253897',
        hsuTs: '2020-03-18T11:32:52.213+0000',
        isNew: true
      }];
      this.holidayGrid.api.updateRowData({
        addIndex: 0,
        add: newRec
      });
      this.holidayGrid.api.setFocusedCell(0, 'holidayType');
      this.hlGridHeight = (this.holidayGrid.api.getModel().getRowCount() * 42) + 52;
    } else if (this.currentDropdownSelection === 'threshold') {

      const newRow = [{
        id: null,
        input: '',
        output: '',
        type: '',
        message: '',
        isNew: true
      }];
      this.thresholdService.addNewThresholdRow(newRow);
    } else if (this.currentDropdownSelection === 'msgSupression') {

      const newMsgSupressRow = [{
        id: null,
        message: '',
        isNew: true,
        type: 'msgSuppression'
      }];
      this.msgSupressionService.addNewRow(newMsgSupressRow);
    } else if (this.currentDropdownSelection === 'mapType') {

      const newMapTypeRow = [{
        id: null,
        message: '',
        isNew: true,
        type: 'mapType'
      }];
      this.msgSupressionService.addNewMapTypeRow(newMapTypeRow);
    } else if(this.showInlineItems()) {
      this.showNewItemDialouge(this.currentDropdownSelection);
    }
     else if (this.currentDropdownSelection === 'adminAlertType') {
      const newRow = [{
        id: null,
        alertTypes: '',
        internal: '',
        visitor: '',
        external: '',
        isNew: true
      }];
      this.adminAlertTypeService.addNewAlertTypeRow(newRow);
    }  else if (this.currentDropdownSelection === 'isa12') {

      const newVersionRow = {
        id: null,
        message: '',
        isNew: true,
        type: 'isa12'
      };
      this.msgSupressionService.addVersionRow(newVersionRow);
    } else if (this.currentDropdownSelection === 'gs08') {

      const newVersionRow = {
        id: null,
        message: '',
        isNew: true,
        type: 'gs08'
      };
      this.msgSupressionService.addVersionRow(newVersionRow);
    } else if (this.currentDropdownSelection === 'st03') {

      const newVersionRow = {
        id: null,
        message: '',
        isNew: true,
        type: 'st03'
      };
      this.msgSupressionService.addVersionRow(newVersionRow);
    }else {
      this.addOptionDialog = true;
      this.cdRef.detectChanges();
      this.addOptionField.nativeElement.focus();
      this.alertService.clearAlert('#master-data-alert');
    }

  }
  showInlineItems() {
    const menuList = this.dropDownMenuList.map((item) => item.type)
    return (this.currentDropdownSelection && menuList.includes(this.currentDropdownSelection))
  }
  showNewItemDialouge(currentAction) {
    this.dialogConfig.data = {};
    const title = this.dropDownMenuList.map(({ type, title }) => { if(type ==currentAction) { return title; } });
    this.dialogConfig.data.actionType = { type: currentAction, mode: "Add", title: title }
    this.dialogConfig.height= '550px';
    this.dialogConfig.width=  '400px';
    const component:any = currentAction==="reportingMessageCodes" ? AddEditReportingItemPopupComponent : AddEditItemPopupComponent;
    const dialogRef = this.dialog.open(component, this.dialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        if(currentAction=="rejectActions"){
          this.msgSupressionService.addNewRejectActionRow(result);
        }
        else if(currentAction=="validations"){
          this.msgSupressionService.addNewValidationRow(result);
        }
        else if(currentAction=="fieldActions"){
          this.msgSupressionService.addNewFieldActionsRow(result);
        } else if(currentAction=="reportingMessageTypes"){
          this.msgSupressionService.addNewReportingMessageTypesRow(result);
        }  else if(currentAction=="reportingMessageCodes"){
          this.msgSupressionService.addNewReportMessageCodesRow(result);
        }
        this.saveMasterDataList();
      }
    });
  }
  cancelAddValidation(): void {

  }
  saveValidation(): void{

  }
  savedInfo() {
    this.isSaveDisabled = false;
  }

   exportView(selection){
     let res = this.filterColumns(this.exportRowData, selection);
     this.downloadCSV(res, selection)
  }
    downloadCSV(res:any=[], fileName?: string){
      const csvData = this.convertArrayToCSV(res);
      const blob= new Blob([csvData],{type: 'text/csv'});
      const url= window.URL.createObjectURL(blob);
      const a= document.createElement('a');
      a.href= url;
      a.download= fileName + '.csv';
      a.click();
      window.URL.revokeObjectURL(url);
    }

   convertArrayToCSV(res: any[]) {
    const csvRows=[];
    const headers= Object.keys(res[0]);
    csvRows.push(headers.join(','));
    for(const row of res){
      const values= headers.map(header => `"${row[header]}"`);
      csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
   }

   filterColumns(res, selection) {
     let filter = [];
     if (selection === 'rejectActions') {
       res.forEach(row => {
         let obj = {"Reactions": row.message, "Description": row.messageDescription};
         filter.push(obj);
       });
     } else if (selection === 'reportingMessageCodes') {
      res.forEach(row => {
        let obj = {"Code": row.message, "Type": row.reportMessageCodesTypeValue, "Message": row.messageDescription};
        filter.push(obj);
      });
     } else if (selection === 'fieldActions') {
      res.forEach(row => {
        let obj = {"Field Actions": row.message, "Description": row.messageDescription};
        filter.push(obj);
      });
     } else if (selection === 'validations') {
      res.forEach(row => {
        let obj = {"Validations": row.message, "Description": row.messageDescription};
        filter.push(obj);
      });
     } else if (selection === 'reportingMessageTypes') {
      res.forEach(row => {
        let obj = {"Type": row.message, "Description": row.messageDescription};
        filter.push(obj);
      });
     }
     return filter;
   }



}

