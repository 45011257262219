import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {spedmFiletrackerFormConfig} from './spedmOnboardingConfig';
import {InputType} from '../models/FormFieldConfig';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import * as fromRoot from '../reducers';
import {getIsRegisteredUser, getRole, getSpedm} from '../reducers';
import {Store} from '@ngrx/store';
import {CVSAlertService, CVSAlertType, DateRangeValidationErrors} from 'angular-component-library';
import {Actions} from '@ngrx/effects';
import {AlertType} from '../models/AlertType';
import { MatDialog } from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {EditAction} from '../actions/spedm.action';
import {SpedmService} from '../services/spedm.service';
import {FormType} from '../models/FormType';
import {SpedmContactDialogComponent} from './spedm-contact-dialog/spedm-contact-dialog.component';
import {Router} from '@angular/router';
import {message} from 'ag-grid-community/dist/lib/utils/general';
import {Permission} from '../models/Authorization';
import {UsersService} from "../services/users.service";

@Component({
  selector: 'app-spedm-onboarding',
  templateUrl: './spedm-onboarding.component.html',
  styleUrls: ['./spedm-onboarding.component.scss']
})
export class SpedmOnboardingComponent implements OnInit {

  constructor(private store: Store<fromRoot.AppState>, private alertService: CVSAlertService,
              private actions$: Actions, private spedmService: SpedmService,
              private usersService: UsersService,
              private router: Router,
              public dialog: MatDialog) { }

  formType = FormType;
  inputType = InputType;
  spedmFiletrackerFormConfig = spedmFiletrackerFormConfig;
  hyperlinks = [];
  form: UntypedFormGroup = new UntypedFormGroup({});
  errors = {};
  isEdited = false;
  isErrorMessage = false;
  isRegisteredUser$ = this.store.select(getIsRegisteredUser) as Observable<boolean>;
  isAdmin = false;
  viewOptions = ['Eligibility', 'Spedm'];
  selectedFileTrackerOption: string = this.viewOptions[1];
  showDaily7Days = false;
  showDailyMtoF = false;
  showWeekly = false;
  isWeeklySelected = false;
  isMonthlySelected = false;
  showMonthly = false;
  showContact = false;
  latefileShowAddConatct = false;
  missedfileShowAddConatct = false;
  isLateFileYes = false;
  ismissedFileYes = false;
  isLateFileViewConatct = false; ismissedFileViewConatct = false;
  usersList: any;
  @ViewChild('hiddenText1') hiddenText1;
  @ViewChild('hiddenText2') hiddenText2;
  @ViewChild('hiddenText3') hiddenText3;
  @ViewChild('hiddenText4') hiddenText4;

  minWidth = 200;
  width1: number = this.minWidth;
  width2: number = this.minWidth;
  width3: number = this.minWidth;
  width4: number = this.minWidth;

  ngOnInit() {
    this.spedmFiletrackerFormConfig = [...spedmFiletrackerFormConfig];
    this.setupForm();
    this.listenChanges();
    if (!this.spedmService.isNew) {
      this.store.select(getSpedm).subscribe((res: any) => {
        if (res ) {
          const spedmObject: any = {
            cltSetupID: res.cltSetupID,
            clientReceiver: res.clientReceiver,
            clientName: res.clientName,
            drugName: res.drugName,
            drmName:res.drmName,
            fileType: res.fileType,
            activeorInactiveStatus: res.activeorInactiveStatus,
            filenameMask: res.filenameMask,
            note: res.note,
            reportFrequency: res.spedmAlertConfigModel ? res.spedmAlertConfigModel.reportFrequency : '',
            reportFrequencyVal:  res.spedmAlertConfigModel ? res.spedmAlertConfigModel.reportFrequencyVal:'',
            contractedDeliveryTime: res.spedmAlertConfigModel ? res.spedmAlertConfigModel.contractedDeliveryTime : '',
            lateFileWarningTime: res.spedmAlertConfigModel ? res.spedmAlertConfigModel.lateFileWarningTime : '',
            alertFrequency: res.spedmAlertConfigModel ? res.spedmAlertConfigModel.alertFrequency : '',
            lateFileWarningAlert: res.spedmAlertConfigModel ? res.spedmAlertConfigModel.lateFileWarningAlert : '',
            missedFileSLAAlert: res.spedmAlertConfigModel ? res.spedmAlertConfigModel.missedFileSLAAlert : '',
          };

          if (spedmObject.clientName) {
            this.resize(2);
          }
          if (spedmObject.clientReceiver) {
            this.resize(1);
          }
          if (spedmObject.drugName) {
            this.resize(3);
          }
          if (spedmObject.filenameMask) {
            this.resize(4);
          }

          this.form.patchValue(spedmObject);
          if (spedmObject.lateFileWarningAlert) {
            this.isLateFileYes = true;
            this.isLateFileViewConatct = true;
            this.latefileShowAddConatct = false;
          } else {
            this.isLateFileYes = false;
            this.isLateFileViewConatct = true;
            this.latefileShowAddConatct = true;
          }
          if (spedmObject.missedFileSLAAlert) {
            this.ismissedFileYes = true;
            this.ismissedFileViewConatct = true;
            this.missedfileShowAddConatct = false;
          } else {
            this.ismissedFileYes = false;
            this.ismissedFileViewConatct = false;
            this.missedfileShowAddConatct = true;
          }

          if (spedmObject.reportFrequency === 'Weekly') {
            this.showWeekly = true;
            this.showMonthly = false;
            this.showDailyMtoF = false;
            this.showDaily7Days = false;
          } else if (spedmObject.reportFrequency === 'Monthly') {
            this.showWeekly = false;
            this.showMonthly = true;
            this.showDailyMtoF = false;
            this.showDaily7Days = false;
          } else if (spedmObject.reportFrequency === 'Daily') {
            this.showDaily7Days = true;
            this.showWeekly = false;
            this.showMonthly = false;
            this.showDailyMtoF = false;
          }  else if (spedmObject.reportFrequency === 'Daily M-F') {
            this.showDailyMtoF = true;
            this.showWeekly = false;
            this.showMonthly = false;
            this.showDaily7Days = false;
          }  else {
            this.showWeekly = false;
            this.showMonthly = false;
            this.showDailyMtoF = false;
            this.showDaily7Days = false;
          }
        }
      });

    } else {
      this.isEdited = true;
      this.formEnable();
      this.spedmService.getNewSpedmClientSetupId().subscribe(res => {
        this.form.get('cltSetupID').setValue(res.id);
      });
    }
    this.listenChanges();
    // this.store.select(getRole).subscribe(role => {
    this.usersService.userRole.subscribe(role =>{
      if ([Permission.ADMIN, Permission.MANAGER,
           Permission.EC, Permission.PROCESSOR,
           Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
           Permission.INTFEED,  Permission.FILETRACKER, Permission.SPEDM].includes(role)) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    });
    this.usersService.getUsersByRole([Permission.SPEDM, Permission.SPEDM_ADMIN]).subscribe(res => {
      this.usersList = res;
    });
  }
    resize(e) {
      if (e === 1) {
        // this.minWidth = 200;
        // this.width1 = this.minWidth;
        setTimeout(() => this.width1 = Math.max(this.minWidth, this.hiddenText1.nativeElement.offsetWidth));
      } else if (e === 2) {
        // this.minWidth = 200;
        // this.width2 = this.minWidth;
        setTimeout(() => this.width2 = Math.max(this.minWidth, this.hiddenText2.nativeElement.offsetWidth));
      } else if (e === 3) {
        // this.minWidth = 200;
        // this.width3 = this.minWidth;
        setTimeout(() => this.width3 = Math.max(this.minWidth, this.hiddenText3.nativeElement.offsetWidth));
      } else {
        // this.minWidth = 200;
        // this.width4 = this.minWidth;
        setTimeout(() => this.width4 = Math.max(this.minWidth, this.hiddenText4.nativeElement.offsetWidth));
      }
    }

  getHyperlink(controlName: string, hyperlinks) {
    let hyperLinkValue = null;
    if (Array.isArray(hyperlinks) && hyperlinks[0] && hyperlinks[0][controlName]) {
      hyperLinkValue =  hyperlinks[0][controlName];
    }
    return hyperLinkValue;

  }
  setupForm() {
    // Base form
    this.spedmFiletrackerFormConfig.map(section => {
      section.map((formField: any) => {
        if (formField.notes) {
          this.form.addControl(formField.notes, new UntypedFormControl(''));
        }
        if (formField.inputType !== InputType.HEADER) {
          if (formField['required']) {
            this.form.addControl(formField.controlName, new UntypedFormControl('', formField['controller']));
          } else {
            this.form.addControl(formField.controlName, new UntypedFormControl(''));
          }

        }
        if (formField.listing === 'repFrequency') {
          this.form.addControl(formField.controlValName, new UntypedFormControl(''));
        }
      });
    });

    this.formDisabled();
  }
  filetrackerOption() {
    if (this.selectedFileTrackerOption === 'Eligibility') {
      this.router.navigateByUrl('filetracker');
    }
  }
  startEdit() {
    this.isEdited = true;
    this.formEnable();
  }
  formDisabled() {
    this.spedmFiletrackerFormConfig.map(section => {
      section.map(formField => {
        if (formField.inputType !== InputType.HEADER && this.form.get(formField.controlName)) {
          this.form.get(formField.controlName).disable();
          if (formField.controlName === 'reportFrequency'){
            this.form.get(formField.controlValName).disable();
          }
        }
      });
    });
  }
  formEnable() {
    this.spedmFiletrackerFormConfig.map(section => {
      section.map(formField => {
        if (formField.inputType !== InputType.HEADER && this.form.get(formField.controlName)) {
          this.form.get(formField.controlName).enable();
          if (formField.controlName === 'reportFrequency'){
            this.form.get(formField.controlValName).enable();
          }
        }
      });
    });
  }
  cancelSpedm() {
    this.isEdited = false;
    this.router.navigateByUrl('dashboard/spedm');

  }

  saveSpedm() {
    const formValue = this.form.value;
    // if (formValue.alertFrequency && formValue.alertFrequency.includes('minutes')) {
    //   actualAlertFrequency = formValue.alertFrequency.replace('minutes', '\ MIN');
    // }
    // if (formValue.alertFrequency && ( formValue.alertFrequency.includes('hour'))) {
    //   actualAlertFrequency = formValue.alertFrequency.replace('hour', '\ HR');
    // }
    // if (formValue.alertFrequency && ( formValue.alertFrequency.includes('hours'))) {
    //   actualAlertFrequency = formValue.alertFrequency.replace('hours', '\ HR');
    // }
    const spedmData: any = {
      cltSetupID : formValue.cltSetupID,
      clientReceiver: formValue.clientReceiver,
      clientName: formValue.clientName,
      drugName: formValue.drugName,
      drmName:formValue.drmName,
      fileType: formValue.fileType,
      filenameMask: formValue.filenameMask,
      activeorInactiveStatus: formValue.activeorInactiveStatus,
      note: formValue.note,
      spedmAlertConfigModel: {
        reportFrequency: formValue.reportFrequency,
        reportFrequencyVal: formValue.reportFrequencyVal,
        contractedDeliveryTime: formValue.contractedDeliveryTime,
        lateFileWarningTime: formValue.lateFileWarningTime,
        alertFrequency: formValue.alertFrequency,
        lateFileWarningAlert: formValue.lateFileWarningAlert,
        missedFileSLAAlert: formValue.missedFileSLAAlert,
      }
    };
    if ( this.spedmService.isNew ) {
      // this.store.dispatch(new SaveFiletrackerAction(this.form.getRawValue()));
      // this.store.dispatch(new SaveFiletrackerAction(this.form.getRawValue(), 'save'));
      this.spedmService.saveSpedm(spedmData, 'save').subscribe(response => {
        this.isEdited = false;
        this.spedmService.isNew = false;
        this.showMessage('Saved Successfully', 'Success');
        this.formDisabled();
      }, error => {
        this.showMessage(error.error.error, 'error');
      });
    } else {
      // this.store.dispatch(new UpdateFiletrackerAction(this.form.getRawValue()));
      spedmData.cltSetupID = formValue.cltSetupID;
      this.spedmService.saveSpedm(spedmData, 'update').subscribe(response => {
        this.isEdited = false;
        this.showMessage('Updated Successfully', 'Success');
        this.formDisabled();
      }, error => {
        this.showMessage(error.error.error, 'error');
      });
    }
    this.isEdited = false;
  }
  addContacts(alertfield) {
    const defaultmessage = 'ErrorMessage:';
    this.store.dispatch(new EditAction(this.isEdited));
    this.dialog.open(SpedmContactDialogComponent, {
      data: {
        setupId: this.form.controls['cltSetupID'].value,
        alertField: alertfield,
        type: AlertType.STATUS,
        isEdited: this.isEdited
      }
    });
  }
  showoptions(event, value, field) {

    field.value = event;
    if (value === 'reportFrequency') {

      if (event === 'Weekly') {
        this.showWeekly = true;
        this.showMonthly = false;
        this.showDailyMtoF = false;
        this.showDaily7Days = false;
      } else if (event === 'Monthly') {
        this.showWeekly = false;
        this.showMonthly = true;
        this.showDailyMtoF = false;
        this.showDaily7Days = false;
      } else if (event === 'Daily') {
        this.showDaily7Days = true;
        this.showWeekly = false;
        this.showMonthly = false;
        this.showDailyMtoF = false;
      }  else if (event === 'Daily M-F') {
        this.showDailyMtoF = true;
        this.showWeekly = false;
        this.showMonthly = false;
        this.showDaily7Days = false;
      }  else {
        this.showWeekly = false;
        this.showMonthly = false;
        this.showDailyMtoF = false;
        this.showDaily7Days = false;
      }
    }
    if (value === 'lateFileWarningAlert' && this.isLateFileYes === false) {
      event === 'Y' ? this.latefileShowAddConatct = true : this.latefileShowAddConatct = false;
    } else if (value === 'lateFileWarningAlert' && this.isLateFileYes === true) {
      event === 'Y' ? this.isLateFileViewConatct = true : this.isLateFileViewConatct = false;
    }
    if (value === 'missedFileSLAAlert' && this.ismissedFileYes === false) {
      event === 'Y' ? this.missedfileShowAddConatct = true : this.missedfileShowAddConatct = false;
    } else if (value === 'missedFileSLAAlert' && this.ismissedFileYes === true) {
      event === 'Y' ? this.ismissedFileViewConatct = true : this.ismissedFileViewConatct = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  showMessage(message, type) {
    // message = message.substring(message.indexOf('\"') + 1, message.length - 1);
    this.alertService.sendAlert({
      // headline: message ? message : 'Save successful !',
      headline: message,
      alertType: type === 'error' ? CVSAlertType.Error : CVSAlertType.Success,
      removedAfterMilliseconds: 3000,
      alertDocumentHolderName: '#file-layout',
    });
  }



   listenChanges() {
    this.form.get('lateFileWarningTime').valueChanges.subscribe(val => {
      if (val && this.spedmService.isNew) {
        this.form.get('lateFileWarningAlert').setValue('Y');
      }
    });
    this.form.get('reportFrequency').valueChanges.subscribe(val => {
       if (val === 'Daily M-F') {
         this.form.get('reportFrequencyVal').setValue('DailyM-F');
       } else if (val === 'Daily') {
         this.form.get('reportFrequencyVal').setValue('Daily');
       } else if (val === 'Weekly'){
        this.isWeeklySelected = true;
        this.isErrorMessage = true
       } else if(val === 'Monthly'){
        this.isMonthlySelected = true;
        this.isErrorMessage = true;
       }
     });

     this.form.get('reportFrequencyVal').valueChanges.subscribe(val =>{
      if(val){
        this.isWeeklySelected = false;
        this.isMonthlySelected = false;
        this.isErrorMessage = false;
      }
     })
    this.form.get('contractedDeliveryTime').valueChanges.subscribe(val => {
      this.form.get('alertFrequency').valueChanges.subscribe(val1 => {
        if (val && val1 && this.spedmService.isNew) {
          this.form.get('missedFileSLAAlert').setValue('Y');
        }
      });
     });
    this.form.get('alertFrequency').valueChanges.subscribe(val => {
       this.form.get('contractedDeliveryTime').valueChanges.subscribe(val1 => {
         if (val && val1 && this.spedmService.isNew) {
           this.form.get('missedFileSLAAlert').setValue('Y');
         }
       });
     });
    this.form.get('contractedDeliveryTime').valueChanges.subscribe(val => {
      const lateFileWarningTime = this.form.get('lateFileWarningTime').value;
      let lateFileWarningHour = '';
      let contractedDeliveryHour = '';
      if (lateFileWarningTime) {
        lateFileWarningHour = lateFileWarningTime.split(':')[0];
        contractedDeliveryHour = val.split(':')[0];
        if (contractedDeliveryHour < lateFileWarningHour && val) {
          this.errors['contractedDeliveryTime'] = {message: 'contractedDeliveryTime should be greaterthan lateFileWarningTime '};
          this.errors['lateFileWarningTime'] = {message: ''};
          this.isErrorMessage = true;
        } else if (contractedDeliveryHour === lateFileWarningHour) {
          const lateFileWarningMinute = lateFileWarningTime.split(':')[1];
          const contractedDeliveryMinute = val.split(':')[1];

          if (contractedDeliveryMinute < lateFileWarningMinute && val) {
            this.errors['contractedDeliveryTime'] = {message: 'contractedDeliveryTime should be greaterthan lateFileWarningTime '};
            this.errors['lateFileWarningTime'] = {message: ''};
            this.isErrorMessage = true;
          } else {
            this.errors['contractedDeliveryTime'] = {message: ''};
            this.errors['lateFileWarningTime'] = {message: ''};
            this.isErrorMessage = false;
          }

        } else {
          this.errors['contractedDeliveryTime'] = {message: ''};
          this.errors['lateFileWarningTime'] = {message: ''};
          this.isErrorMessage = false;
        }
      }
     });
    this.form.get('lateFileWarningTime').valueChanges.subscribe(val => {
       const contractedDeliveryTime = this.form.get('contractedDeliveryTime').value;
       let lateFileWarningHour = '';
       let contractedDeliveryHour = '';
       if (contractedDeliveryTime) {
        contractedDeliveryHour = contractedDeliveryTime.split(':')[0];
        lateFileWarningHour = val.split(':')[0];
        if (contractedDeliveryHour < lateFileWarningHour && val) {
          this.errors['lateFileWarningTime'] = {message: 'lateFileWarningTime should be lesserthan contractedDeliveryTime '};
          this.errors['contractedDeliveryTime'] = {message: ''};
          this.isErrorMessage = true;
        } else if (contractedDeliveryHour === lateFileWarningHour) {
          const lateFileWarningMinute = val.split(':')[1];
          const contractedDeliveryMinute = contractedDeliveryTime.split(':')[1];

          if (contractedDeliveryMinute < lateFileWarningMinute && val) {
            this.errors['lateFileWarningTime'] = {message: 'lateFileWarningTime should be lesserthan contractedDeliveryTime '};
            this.errors['contractedDeliveryTime'] = {message: ''};
            this.isErrorMessage = true;
          } else {
            this.errors['contractedDeliveryTime'] = {message: ''};
            this.errors['lateFileWarningTime'] = {message: ''};
            this.isErrorMessage = false;
          }

        } else {
          this.errors['contractedDeliveryTime'] = {message: ''};
          this.errors['lateFileWarningTime'] = {message: ''};
          this.isErrorMessage = false;
        }
      }
     });
     this.form.get('contractedDeliveryTime').valueChanges.subscribe(contractval => {
      this.form.get('lateFileWarningTime').valueChanges.subscribe(lateval => {
         if(!contractval && !lateval){
          this.isErrorMessage = false
         }
      })
    });

   }


}
