import { Component, ViewEncapsulation } from '@angular/core';
import { ITooltipAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './status-popover.component.html',
  styles: [],
})
export class StatusPopoverComponent implements ITooltipAngularComp {
  value: any;

  constructor() {
  }
  agInit(params): void {
    if (params && params.value) {
      const val = params.value.split(',');
      val.forEach(element => {
        this.value = this.value + '<div>' + element.softText.trim() + '</div>';
      });
    }
  }
}
