import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MappingApiService } from 'src/app/services/mapping-api.service';
import { MappingFormService } from '../../mapping-form.service';
import * as ColumnSets from '../../mappingColumnSets';
@Component({
  selector: 'app-mapping-import-guide',
  templateUrl: './mapping-import-guide.component.html',
  styleUrls: ['./mapping-import-guide.component.scss']
})
export class MappingImportGuideComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('gridImport') grid: AgGridAngular;
  rowData: any = [];
  selectedImport: any;
  subs: Subscription[] = [];
  form: UntypedFormGroup;
  completeData: any;
  providedInfo: any;
  constructor(public dialogRef: MatDialogRef<MappingImportGuideComponent>,
              @Inject(MAT_DIALOG_DATA) data,
              private api: MappingApiService,
              private mapServ: MappingFormService
    // private flService: FileLayoutService
  ) {
    // this.description = data.description;
     this.providedInfo = data;
  }
  ngOnInit() {
  this.getImportDoc();
  this.addSearch();
  }
  ngAfterViewInit(): void {
    this.grid.api.setColumnDefs(ColumnSets.importGuide);
    this.grid.api.sizeColumnsToFit();
    this.grid.gridOptions = { stopEditingWhenGridLosesFocus: true };
    
  }
  setTableData() {
    const rowData = this.rowData;
    if (this.grid) {
      this.grid.api.setRowData(rowData);
      setTimeout(() => {
          const grid = this.grid.api.getFilterInstance('pliCode');
          grid.setModel({
            type: 'contains',
            filter: this.providedInfo.currentPli
          });
          this.grid.api.onFilterChanged();
        }, 200);
    }
  }
  save() {
    this.mapServ.importDocument(this.selectedImport);
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close(null);
  }

  getImportDoc() {
   this.api.getImportDocs(this.providedInfo.currentFileFormat).pipe(
  //  switchMap((resp)=>{
  //    return resp}),
   catchError(err => of({error: {...err}}))).subscribe((res: any) => {
    if (!res.error) {
      this.rowData = res;
      this.completeData = res;
      this.setTableData();
    }
   });
 }
 onTableRowClicked(event) {
  this.selectedImport = event.data;
 }

 addSearch() {
  this.form =  this.mapServ.generateImportSearch();
  this.subs.push(
    this.form.get('search').valueChanges.pipe(
       debounceTime(400),
       distinctUntilChanged(),
      switchMap(resp => this.filterData(resp)) ).subscribe()
  );
 }
  filterData(resp): Observable<any> {
    if (!resp) {
      this.rowData = this.completeData;
      this.setTableData();
    }
    const filteredArray = [];
    this.completeData.forEach(data => {
      const keys  = Object.keys(data);
      
      for (const key of keys) {
       if (data[key]  && (data[key]?.toLowerCase().includes(resp?.toLowerCase()))) {
        filteredArray.push(data);
        return;
      }
      }
      });
    this.rowData = filteredArray;
    this.setTableData();
    return of(null);
  }

 ngOnDestroy(): void {
 this.subs.forEach(sub => sub.unsubscribe());
 }


}

