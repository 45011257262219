import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {
  CHECK_IF_CROSS_FORM_POPULATIONS_NEEDED,
  CheckIfCrossFormPopulationsNeededAction,
  UPDATE_QUESTIONNAIRE_FORM,
} from '../actions/questionnaire.action';
import {AppState} from '../reducers';
import {Store} from '@ngrx/store';
import {NoopAction} from '../actions/noop.action';
import {UpdateEligibilityAgreementFormAction} from '../actions/eligibility-agreement.action';

/* eslint-disable max-len */
@Injectable()
export class QuestionnaireEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>
  ) {  }

  populateOtherForms$ = createEffect(() => this.actions$.pipe(
    ofType(CHECK_IF_CROSS_FORM_POPULATIONS_NEEDED),
    withLatestFrom(this.store$.select(state => state.questionnaire)),
    mergeMap(([action, questionnaire]) => {
      return of({
        new: (action as CheckIfCrossFormPopulationsNeededAction).payload,
        old: questionnaire
      })
        .pipe(
          tap((questionnaires) => {
            questionnaires.new = this.reattachLoadReportIds(questionnaires)
            // Check for relevant updates
            this.populateSurvivingSpousesInEligAgr(questionnaires.new, questionnaires.old);
            this.populateTrmsByAbsThrInEligAgr(questionnaires.new, questionnaires.old);
            this.populateMemberSuspenseUsed(questionnaires.new, questionnaires.old);
            this.populateWhoWillPrintCard(questionnaires.new, questionnaires.old);
            this.populateFamId(questionnaires.new, questionnaires.old);
            this.populateAltIdCode(questionnaires.new, questionnaires.old);
            this.populatePersonCodes(questionnaires.new, questionnaires.old);
            this.populateThirdID(questionnaires.new, questionnaires.old);
            this.populateCEPRCOBInEligAgr(questionnaires.new, questionnaires.old);
            this.populateCEPRTrmDates4AllMbrsInEligAgr(questionnaires.new, questionnaires.old);
            this.populateHistoryMthdInEligAgr(questionnaires.new, questionnaires.old);
            this.populateImpliedTermsInEligAgr(questionnaires.new, questionnaires.old);
            this.populateImpliedTermsTwoInEligAgr(questionnaires.new, questionnaires.old);
            this.populateFileNamingCompareInEligAgr(questionnaires.new, questionnaires.old);
            this.populateCEPRPCOBNCOBNoteInEligAgr(questionnaires.new, questionnaires.old);
            this.populateCERPGrpSuspInEligAgr(questionnaires.new, questionnaires.old);
            this.populateCERPBatchOvrInEligAgr(questionnaires.new, questionnaires.old);
            this.populateCEPRCHOnlyInEligAgr(questionnaires.new, questionnaires.old);
            this.populateCEPRAutoCAGInEligAgr(questionnaires.new, questionnaires.old);
            this.populateCEPRStandaloneDepsInEligAgr(questionnaires.new, questionnaires.old);
            this.populateCEPRSplitFamInEligAgr(questionnaires.new, questionnaires.old);
            this.populateCEPRTMR2InEligAgr(questionnaires.new, questionnaires.old);
            this.populateCEPRTMR3InEligAgr(questionnaires.new, questionnaires.old);
            this.populateCEPRTMR4InEligAgr(questionnaires.new, questionnaires.old);
            this.populateCEPRTMRIgnFutureTrmInEligAgr(questionnaires.new, questionnaires.old);
            this.populateCEPRTMRInclFtrEffInEligAgr(questionnaires.new, questionnaires.old);
            this.populateCEPRDepImpTrmsInEligAgr(questionnaires.new, questionnaires.old);
          }),
          map(questionnaires => {
            return ({type: UPDATE_QUESTIONNAIRE_FORM, payload: questionnaires.new});
          }),
          catchError(() => {
            return of(new NoopAction());
          })
        );
    })
    )
  );

  private populateTrmsByAbsThrInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const oldTermByAbsenceThshldCount = oldQuestionnaire.questionnaireTermThreshold;
    const newTermByAbsenceThshldCount = newQuestionnaire.questionnaireTermThreshold;

    const oldTermByAbsenceThshldPercent = oldQuestionnaire.termByAbsenceThshldPercent;
    const newTermByAbsenceThshldPercent = newQuestionnaire.termByAbsenceThshldPercent;

    const oldTermByAbsence = oldQuestionnaire.tmr;
    const newTermByAbsence = newQuestionnaire.tmr;

    const responseOne = 'Term by Absence is turned on for reporting purposes ONLY.  No terms will actually be applied; Review the load report for details. Any terms that need to be applied must be sent on the file with a termination date.  Work with your EC on specifics.';
    const responseTwo = 'CVS Health will automatically terminate members missing from the eligibility file (subject to thresholds - see \'Thresholds\' section) with the following date...';
    const responseThree = 'CVS Health will NOT use Term by absence.  Client/Vendor is responsible for terminating member eligibility.';
    if (oldTermByAbsence !== newTermByAbsence || oldTermByAbsenceThshldCount !== newTermByAbsenceThshldCount || oldTermByAbsenceThshldPercent !== newTermByAbsenceThshldPercent) {
      if (newTermByAbsence === 'Yes') {
        if (Number(newTermByAbsenceThshldCount) > 0 || ( newTermByAbsenceThshldPercent && Number(newTermByAbsenceThshldPercent.replace('%', '')) > 0)) {
          this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRTMR: responseTwo}));
        } else {
          this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRTMR: responseOne}));
        }
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRTMR: responseThree}));
      }
    }
  }

  private populateMemberSuspenseUsed(newQuestionnaire, oldQuestionnaire): void {
    const newMemberSuspense = newQuestionnaire.mbrSuspUsed;
    const oldMemberSuspense = oldQuestionnaire.mbrSuspUsed;

    const yesDailyValue = 'Members records that contain a Group/Plan that does not exist will be suspended and reported on the load report.  Once Group/Plan is added, Members will load automatically.  Members who are not added within the retention days defined will need to be re-sent on an eligibility file.';
    const yesFileValue = 'Members records that contain a Group/Plan that does not exist will be suspended and reported on the load report.  Once Group/Plan is added, Members will load upon receipt and processing of the following eligibility file.  Members who are not added within the retention days defined will need to be re-sent on an eligibility file.';
    const noValue = 'Member Suspense is not used.';

    if (newMemberSuspense !== oldMemberSuspense) {
      if (newMemberSuspense === 'Yes - Daily') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRSuspense: yesDailyValue}));
      } else if (newMemberSuspense === 'Yes - File') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRSuspense: yesFileValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRSuspense: noValue}));
      }
    }
  }

  private populateWhoWillPrintCard(newQuestionnaire, oldQuestionnaire): void {
    const newToPrintOnCard = newQuestionnaire.field178;
    const oldToPrintOnCard = oldQuestionnaire.field178;

    const cardHolder = 'Only the cardholder will be printed on the card.';
    const entireFamily = 'The entire family will be printed on the card.';
    const eachMember = 'Each member of the family will receive their own card.';

    if (newToPrintOnCard !== oldToPrintOnCard) {
      if (newToPrintOnCard === '1 - CH') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRFamonCards: cardHolder}));
      } else if (newToPrintOnCard === '1-4 All') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRFamonCards: entireFamily}));
      } else if (newToPrintOnCard === '1-4 Ind') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRFamonCards: eachMember}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRFamonCards: 'Not Applicable'}));
      }
    }
  }

  private populateSurvivingSpousesInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newSurvSpouses = newQuestionnaire.qsurvSpsMethod;
    const oldSurvSpouses = oldQuestionnaire.qsurvSpsMethod;

    const elevatedValue = 'Spouse (or designated dependent if there is no spouse) will be assigned a NEW Member ID and promoted to Cardholder, with remaining dependents to follow.';
    const naValue = 'Surviving Spouse logic is not applicable due to the nature of this population.';
    const otherValue = 'Contact your Eligibility Consultant to discuss how surviving spouse situations are to be handled.';
    const notElevatedValue = 'Cardholder will be terminated and surviving spouse will retain their existing ID and relationship code.';
    const cobraValue = 'Cardholder and Spouse termed under existing Active Group and Surviving Spouse and Dependents are only eligible under Cobra and elevated to their own ID';

    if (newSurvSpouses !== oldSurvSpouses) {
      if (newSurvSpouses === 'Elevated') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRSrvvngSps: elevatedValue}));
      } else if (newSurvSpouses === 'N/A') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRSrvvngSps: naValue}));
      } else if (newSurvSpouses === 'Other') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRSrvvngSps: otherValue}));
      } else if (newSurvSpouses === 'Not Elevated') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRSrvvngSps: notElevatedValue}));
      } else if (newSurvSpouses === 'Cobra') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRSrvvngSps: cobraValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRSrvvngSps: otherValue}));
      }
    }
  }

  private populateFamId(newQuestionnaire, oldQuestionnaire): void {
    const newfamIdMatch = newQuestionnaire.famIDMatchCoreMbrIDQ;
    const oldfamIdMatch = oldQuestionnaire.famIDMatchCoreMbrIDQ;

    const yesValue = 'Core of the Member ID and is the same across all members of the family';
    const noValue = 'Must be the same value across the entire family.';

    if (newfamIdMatch !== oldfamIdMatch) {
      if (newfamIdMatch === 'Yes') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRFamID: yesValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRFamID: noValue}));
      }
    }
  }

  private populateAltIdCode(newQuestionnaire, oldQuestionnaire): void {
    const newAltIdCodes = newQuestionnaire.xwalkUsed;
    const oldAltIdCodes = oldQuestionnaire.xwalkUsed;
    const newAltIdType = newQuestionnaire.xwalkIDTypeWPC;
    const oldAltIdType = oldQuestionnaire.xwalkIDTypeWPC;
    const newAutoGen = newQuestionnaire.xwalkAutoGen;
    const oldAutoGen = oldQuestionnaire.xwalkAutoGen;

    const altIdYesAutogenYesValue = 'Alternate ID (Xwalk) is generated by CVS Health';

    if ((newAltIdCodes !== oldAltIdCodes || newAutoGen !== oldAutoGen) || (newAltIdType !== oldAltIdType)
    ) {
      if (newAltIdCodes === 'Yes') {
        if (newAutoGen === 'Yes') {
          this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRFAltIdCode: altIdYesAutogenYesValue}));
        }else{
          if(newAltIdType !== null){
          this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRFAltIdCode: newAltIdType.substring(3)}));
        }
      }
    }
  }
  }


  private populatePersonCodes(newQuestionnaire, oldQuestionnaire): void {
    const newPersonCodes = newQuestionnaire.personCodes;
    const oldPersonCodes = oldQuestionnaire.personCodes;

    const newAutoGen = newQuestionnaire.pcautoGen;
    const oldAutoGen = oldQuestionnaire.pcautoGen;

    const personNoValue = 'Unique per family member and must be appended to Mbr and Xwalk ID\'s.';
    const personYesAutogenNoValue = 'Unique per family member and must be supplied by the client/vendor and appended to Mbr ID. Person Code or Suffix cannot be used again for another member of the family or for any other member. This will create Privacy Issues and create legal exposure.';
    const personYesAutogenYesValue = 'Generated by CVS Health and automatically appended to Member ID and Alternate ID (Xwalk) if used.';

    if (newPersonCodes !== oldPersonCodes || newAutoGen !== oldAutoGen) {
      if (newPersonCodes === 'Yes') {
        if (newAutoGen === 'Yes') {
          this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRFPersonCode: personYesAutogenYesValue}));
        } else {
          this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRFPersonCode: personYesAutogenNoValue}));
        }
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRFPersonCode: personNoValue}));
      }
    }
  }

  private populateThirdID(newQuestionnaire, oldQuestionnaire): void {
    const newThirdID = newQuestionnaire.q3rdIdFieldUsed;
    const oldThirdID = oldQuestionnaire.q3rdIdFieldUsed;

    const yesValue = 'Supplied by client/vendor as needed';

    if (newThirdID !== oldThirdID ) {
      if (newThirdID === 'Yes') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPR3rdID: yesValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPR3rdID: 'N/A'}));
      }
    }
  }

  private populateCEPRCOBInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newCobUsed = newQuestionnaire.cobused;
    const oldCobUsed = oldQuestionnaire.cobused;

    const newCobcUsed = newQuestionnaire.cobcused;
    const oldCobcUsed = oldQuestionnaire.cobcused;

    const newStCobUsed = newQuestionnaire.stcob;
    const oldStCobUsed = oldQuestionnaire.stcob;

    const bothUsed = 'Coordination of Benefits will be managed by both stand alone COBC file, as well as Single Transaction COB (STCOB) which link primary and secondary members based on STCOB fields on the eligibility file.';
    const cOBCUsed = 'Coordination of Benefits will be managed by a separate COBC file along with Alternate Insurance fields as needed.';
    const sTCOBUsed = 'Single Transaction Coordination of Benefits (STCOB) will be used to link primary and secondary coverage. See your eligibility file layout documentation for more details on the fields used and necessary file submission behavior.';
    const bothNotUsed = 'Coordination of Benefits will be managed via the Alternate Insurance fields.';

    if (newCobUsed !== oldCobUsed
        || newCobcUsed !== oldCobcUsed
        || newStCobUsed !== oldStCobUsed) {
      if (newCobUsed === 'No' || newCobUsed === '') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRCOB: 'Not Applicable'}));
      } else {
        if (newCobcUsed !== 'Yes' && newStCobUsed !== 'Yes') {
          this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRCOB: bothNotUsed}));
        } else if (newCobcUsed === 'Yes' && newStCobUsed === 'Yes') {
          this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRCOB: bothUsed}));
        } else if (newCobcUsed === 'Yes' && newStCobUsed !== 'Yes') {
          this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRCOB: cOBCUsed}));
        } else if (newCobcUsed !== 'Yes' && newStCobUsed === 'Yes') {
          this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRCOB: sTCOBUsed}));
        }
      }
    }
  }

  private populateHistoryMthdInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newHistMthd = newQuestionnaire.qhistMethod;
    const oldHistMthd = oldQuestionnaire.qhistMethod;

    const fmlValue = 'Follow Me Logic\' will be used by claims processing to facilitate the usage of Prior ' +
      'Authorizations, Claims, and Accums across groups (CAGs) even when Member ID is not the same.';
    const planControlledValue = 'Claims, Accums, PA\'s will be retrieved based on Member ID at the level ' +
      'defined in the benefit plan.';
    const type4Value = 'Type 4 records will be used to physically move claims, accums, and/or prior authorizations ' +
      'from one CAGM to another.';
    const atnValue = 'A member\'s Claims, Accums, and PA\'s will be retrieved across various groups using the' +
      ' Member ID and based on the \'Accum Tracking Number\' (ATN) defined for each Group. Contact your Account' +
      ' Manager for more info on ATN.';

    if (newHistMthd !== oldHistMthd ) {
      if (newHistMthd === 'FML') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRHistMthd: fmlValue}));
      } else if (newHistMthd === 'Plan Controlled') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRHistMthd: planControlledValue}));
      } else if (newHistMthd === 'Type 4') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRHistMthd: type4Value}));
      } else if (newHistMthd === 'ATN') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRHistMthd: atnValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRHistMthd: ''}));
      }
    }
  }

  private populateCEPRTrmDates4AllMbrsInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newTermDatesUsed = newQuestionnaire.participantTermDates;
    const oldTermDatesUsed = oldQuestionnaire.participantTermDates;

    const value = 'Client/Vendor will send true term dates for all members who are ending their coverage with the client.';

    if (newTermDatesUsed !== oldTermDatesUsed) {
      if (newTermDatesUsed === 'Yes') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRTrmDates4AllMbrs: value}));
      }
    }
  }

  private populateImpliedTermsInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newImpliedTerms = newQuestionnaire.impliedTerms;
    const oldImpliedTerms = oldQuestionnaire.impliedTerms;

    const newImpliedTermLevel = newQuestionnaire.impTermLevel;
    const oldImpliedTermLevel = oldQuestionnaire.impTermLevel;

    const impliedTermsNotYesValue = 'Client/Vendor is responsible for terming members from an old CAG (Carrier/Account/Group) when they are added under a new CAG. For continuous coverage, the term date on the \'old\' coverage should be one day prior to the effective date of the new coverage.  The \'term\' record should be sent first sequentially on the file, with the \'add\' record coming later.';
    const impliedTermLevelPresentValue = 'CVS Health Implied Terms process will term the member in the existing CAG one day prior to the effective date of the newly added member at the level defined.';

    if (newImpliedTerms === 'Yes'){
      this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRImpTrms: impliedTermLevelPresentValue}));
    } else if(newImpliedTerms !== 'Yes'){
      this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRImpTrms: impliedTermsNotYesValue}));
    }

    if (newImpliedTermLevel !== oldImpliedTermLevel) {
      if (newImpliedTermLevel !== null && newImpliedTermLevel !== '') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRImpTrms: impliedTermLevelPresentValue}));
      }
    }
  }

  private populateImpliedTermsTwoInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newMoveHistQues = newQuestionnaire.moveHistQues;
    const oldMoveHistQues = oldQuestionnaire.moveHistQues;

    const newMoveAccumsQues = newQuestionnaire.moveAccumsQues;
    const oldMoveAccumsQues = oldQuestionnaire.moveAccumsQues;

    const newMovePriorAuthsQues = newQuestionnaire.movePriorAuthsQues;
    const oldMovePriorAuthsQues = oldQuestionnaire.movePriorAuthsQues;

    if (newMoveHistQues !== oldMoveHistQues || newMoveAccumsQues !== oldMoveAccumsQues || newMovePriorAuthsQues !== oldMovePriorAuthsQues) {
      const histQuesYes = newMoveHistQues === 'Yes';
      const accumQuesYes = newMoveAccumsQues === 'Yes';
      const priorAuthYes = newMovePriorAuthsQues === 'Yes';

      let ceprImpTrm2Response;

      if (histQuesYes && accumQuesYes && priorAuthYes) {
        ceprImpTrm2Response = 'Claims, Adjustments, and PA\'s will be moved from existing CAGM to new CAGM for members termed by Implied Terms.';
      } else if (histQuesYes && accumQuesYes && !priorAuthYes) {
        ceprImpTrm2Response = 'Claims and Adjustments will be moved from existing CAGM to new CAGM for members termed by Implied Terms.';
      } else if (histQuesYes && !accumQuesYes && priorAuthYes) {
        ceprImpTrm2Response = 'Claims and PA\'s will be moved from existing CAGM to new CAGM for members termed by Implied Terms.';
      } else if (histQuesYes && !accumQuesYes && !priorAuthYes) {
        ceprImpTrm2Response = 'Claims will be moved from existing CAGM to new CAGM for members termed by Implied Terms.';
      } else if (!histQuesYes && accumQuesYes && priorAuthYes) {
        ceprImpTrm2Response = 'Adjustments and PA\'s will be moved from existing CAGM to new CAGM for members termed by Implied Terms.';
      } else if (!histQuesYes && accumQuesYes && !priorAuthYes) {
        ceprImpTrm2Response = 'Adjustments will be moved from existing CAGM to new CAGM for members termed by Implied Terms.';
      } else if (!histQuesYes && !accumQuesYes && priorAuthYes) {
        ceprImpTrm2Response = 'PA\'s will be moved from existing CAGM to new CAGM for members termed by Implied Terms.';
      } else {
        ceprImpTrm2Response = '';
      }
      this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRImpTrm2: ceprImpTrm2Response}));
    }
  }

  private populateFileNamingCompareInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newCompareProcess = newQuestionnaire.compareProcess;
    const oldCompareProcess = oldQuestionnaire.compareProcess;

    const compareValue = 'A Full Compare File Will Be Required';
    const reconValue = 'A Recon File Will Be Provided';
    const tmrValue = 'Term by Absence Will Be Used';

    if (newCompareProcess !== oldCompareProcess) {
      if (newCompareProcess === 'Compare') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({fileNamingCompare: compareValue}));
      } else if (newCompareProcess === 'Recon') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({fileNamingCompare: reconValue}));
      } else if (newCompareProcess === 'TMR') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({fileNamingCompare: tmrValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({fileNamingCompare: ''}));
      }
    }
  }

  private populateCEPRPCOBNCOBNoteInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newPCOBNCOBUsed = newQuestionnaire.pcobncobused;
    const oldPCOBNCOBUsed = oldQuestionnaire.pcobncobused;

    const yesValue = 'A separate COB file will be provided.';

    if (newPCOBNCOBUsed !== oldPCOBNCOBUsed) {
      if (newPCOBNCOBUsed === 'Yes') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRPCOBNCOBNote:  yesValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRPCOBNCOBNote:  ''}));
      }
    }
  }

  private populateCERPGrpSuspInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newGrpSuspUsed = newQuestionnaire.grpSuspUsed;
    const oldGrpSuspUsed = oldQuestionnaire.grpSuspUsed;

    const yesValue = 'Groups received on the file that contain an invalid Plan will be suspended and written to the load report. Once related plan has been added to the system, the group will automatically load upon receipt and processing of the next Group eligibility file. Groups not added within the retention days defined will need to be re-sent on an eligibility file.';
    const bothValue = 'Groups received on the file that contain an invalid Plan will be suspended and written to the load report. Once related plan has been added to the system, the group will automatically load the next day, or upon receipt and processing of the next Group eligibility file. Groups not added within the retention days defined will need to be re-sent on an eligibility file.';

    if (newGrpSuspUsed !== oldGrpSuspUsed) {
      if (newGrpSuspUsed === 'Yes') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRGrpSusp:  yesValue}));
      } else if (newGrpSuspUsed === 'Both') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRGrpSusp:  bothValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRGrpSusp:  ''}));
      }
    }
  }

  private populateCERPBatchOvrInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newBatchOvrProtect = newQuestionnaire.batchOvrProtect;
    const oldBatchOvrProtect = oldQuestionnaire.batchOvrProtect;

    const yesValue = 'Members designated by batch override protection will never be touched by a file. They can only be adjusted manually.';

    if (newBatchOvrProtect !== oldBatchOvrProtect) {
      if (newBatchOvrProtect === 'Yes') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cERPBatchOvr:  yesValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cERPBatchOvr:  ''}));
      }
    }
  }

  private populateCEPRCHOnlyInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newCrdhldrsOnlyQ = newQuestionnaire.crdhldrsOnlyQ;
    const oldCrdhldrsOnlyQ = oldQuestionnaire.crdhldrsOnlyQ;

    const yesValue = 'Population is ONLY Cardholders and Relationship (1) and Person Codes should be assigned accordingly.';

    if (newCrdhldrsOnlyQ !== oldCrdhldrsOnlyQ) {
      if (newCrdhldrsOnlyQ === 'Yes') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRCHOnly:  yesValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRCHOnly:  ''}));
      }
    }
  }

  private populateCEPRAutoCAGInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newGrpOTForAutoCAG = newQuestionnaire.grpOTForAutoCAG;
    const oldGrpOTForAutoCAG = oldQuestionnaire.grpOTForAutoCAG;

    const yesValue = 'In the event that the Account and/or Group does not exist for an incoming Member, CVS Health\'s \'Auto-CAG/Plan\' logic will dynamically create the Account/Group and assign the Plan based on rules table defined within benefit setup, so long as the incoming record is supported by those rules.';

    if (newGrpOTForAutoCAG !== oldGrpOTForAutoCAG) {
      if (newGrpOTForAutoCAG === 'Yes') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRAutoCAG:  yesValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRAutoCAG:  ''}));
      }
    }
  }

  private populateCEPRStandaloneDepsInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newStandAloneDeps = newQuestionnaire.standAloneDeps;
    const oldStandAloneDeps = oldQuestionnaire.standAloneDeps;

    const yesValue = 'Standalone Dependents will be sent on the file. Contact your Eligibility Consultant for more information.';

    if (newStandAloneDeps !== oldStandAloneDeps) {
      if (newStandAloneDeps === 'Yes') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRStandaloneDeps:  yesValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRStandaloneDeps:  ''}));
      }
    }
  }

  private populateCEPRSplitFamInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newMemDepDifGrps = newQuestionnaire.memDepDifGrp;
    const oldMemDepDifGrps = oldQuestionnaire.memDepDifGrp;

    const yesValue = 'Split Families (some family members in one CAG, others family members in another CAG) are possible. Contact your Eligibility Consultant for more information.';

    if (newMemDepDifGrps !== oldMemDepDifGrps) {
      if (newMemDepDifGrps === 'Yes') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRSplitFam:  yesValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRSplitFam:  ''}));
      }
    }
  }

  private populateCEPRTMR2InEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newTmrrangeListType = newQuestionnaire.tmrrangeListType;
    const oldTmrrangeListType = oldQuestionnaire.tmrrangeListType;

    const populatedResponse = 'An Inclusion/Exclusion list is used to determine the population that Term by Absence will be applied to. Contact your Eligibility Consultant for more detail.';

    if (newTmrrangeListType !== oldTmrrangeListType) {
      if (newTmrrangeListType === ''
        || newTmrrangeListType === 'N/A'
        || newTmrrangeListType.includes('Dynamic')) {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRTMR2:  ''}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRTMR2: populatedResponse}));
      }
    }
  }

  private populateCEPRTMR3InEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newManTMRProtect = newQuestionnaire.manTMRProtect;
    const oldManTMRProtect = oldQuestionnaire.manTMRProtect;

    const yesValue = 'Members who are MANUALLY added will not be subject to Term by Absence UNTIL they have been received on an eligibility file.';

    if (newManTMRProtect !== oldManTMRProtect) {
      if (newManTMRProtect === 'Yes') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRTMR3: yesValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRTMR3: ''}));
      }
    }
  }

  private populateCEPRTMR4InEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newTmrrangeListType = newQuestionnaire.tmrrangeListType;
    const oldTmrrangeListType = oldQuestionnaire.tmrrangeListType;

    const yesValue = 'Dynamic Term by Absence is used and will only term members when at least one instance of the carrier/account/group exists on the file. If CAGS are dropped completely, the term by absence process does not terminate records. If an entire carrier, account, and/or group population is terming please work with your EC and/or AM to determine best approach.';

    if (newTmrrangeListType !== oldTmrrangeListType) {
      if (newTmrrangeListType.includes('Dynamic')) {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRTMR4: yesValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRTMR4: ''}));
      }
    }
  }

  private populateCEPRTMRIgnFutureTrmInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newTmrignFtrTrm = newQuestionnaire.tmrignFtrTrm;
    const oldTmrignFtrTrm = oldQuestionnaire.tmrignFtrTrm;

    const yesValue = 'Members who have a future term date that is less than open ended (12/31/39) will not be subject to Term by Absence. The existing term date will remain and must be termed by other means if so desired';

    if (newTmrignFtrTrm !== oldTmrignFtrTrm) {
      if (newTmrignFtrTrm === 'Yes') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRTMRIgnFutureTrm: yesValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRTMRIgnFutureTrm: ''}));
      }
    }
  }

  private populateCEPRTMRInclFtrEffInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newTmrinclFtrEff = newQuestionnaire.tmrinclFtrEff;
    const oldTmrinclFtrEff = oldQuestionnaire.tmrinclFtrEff;

    const yesValue = 'Members with future effective dates are subject to Term by Absence and will be inactivated if they are not received on the file, and meet other Term by Absence criteria.';

    if (newTmrinclFtrEff !== oldTmrinclFtrEff) {
      if (newTmrinclFtrEff === 'Yes') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRTMRInclFtrEff: yesValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRTMRInclFtrEff: ''}));
      }
    }
  }

  private populateCEPRDepImpTrmsInEligAgr(newQuestionnaire, oldQuestionnaire): void {
    const newDepImpTermQues = newQuestionnaire.depImpTermQues;
    const oldDepImpTermQues = oldQuestionnaire.depImpTermQues;

    const yesValue = 'Dependents will automatically be termed when the Cardholder is loaded with a term date.';

    if (newDepImpTermQues !== oldDepImpTermQues) {
      if (newDepImpTermQues === 'Yes') {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRDepImpTrms: yesValue}));
      } else {
        this.store$.dispatch(new UpdateEligibilityAgreementFormAction({cEPRDepImpTrms: ''}));
      }
    }
  }

  private reattachLoadReportIds(questionnaires):any{
    if(!questionnaires.old?.questionariesLoadReportEntity){
      return questionnaires.new
    }
    questionnaires.new.signOffStatus = questionnaires.old.signOffStatus
    questionnaires.new.questionariesLoadReportEntity.id = questionnaires.old.questionariesLoadReportEntity.id;
    
    questionnaires.new.questionariesLoadReportEntity.mbrDetailMessages.forEach(msg => {
      let oldMsg = questionnaires.old.questionariesLoadReportEntity.mbrDetailMessages.find(oldMsg => ((oldMsg.message === msg.message) && (oldMsg.reportMessageType === msg.reportMessageType)))
      if(oldMsg){
        msg.id = oldMsg.id
      } 
      
    });
    
    
    questionnaires.new.questionariesLoadReportEntity.reportFormat.forEach(format => {
      let oldFormat = questionnaires.old.questionariesLoadReportEntity.reportFormat.find(oldFormat => oldFormat.reportField === format.reportField)
      if(oldFormat){
        format.id = oldFormat.id
      } 
      
    });
    
    return questionnaires.new;
  }}
