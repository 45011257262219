<header>
  <div class="container">
    <div class="row">
      <div class="col-4">
        <h1>Reporting & Metrics</h1>
      </div>
    </div>
  </div>
</header>
<div>
  <nav mat-tab-nav-bar >
    <a  mat-tab-link  *ngFor="let link of navLinks" [active]="selectedTab=== link.action" (click)="display(link.action)">
      {{link.label}}
    </a>
  </nav>
  <div *ngIf="selectedTab === 'metrics'">
   <app-rte-metrics></app-rte-metrics>
  </div>
  <div *ngIf="selectedTab === 'suspense'">
    <app-rte-suspense></app-rte-suspense>
  </div>
  <div *ngIf="selectedTab === 'history'">
    <app-rte-epr-history></app-rte-epr-history>
  </div>
</div>
