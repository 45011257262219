import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {getIsRegisteredUser, getRole} from '../reducers';
import {Router} from '@angular/router';
import {AgGridAngular} from 'ag-grid-angular';
import {Observable, of} from 'rxjs';
import {Permission} from '../models/Authorization';
import {saveAs} from 'file-saver';
import * as ColumnSets from './spedmDashboardColumns';
import {LoadAllSpedmAction, LoadSpedmAction} from '../actions/spedm.action';
import {Spedm} from '../models/Spedm';
import {SpedmService} from '../services/spedm.service';

import {CookieService} from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import {EligibilityDialogComponent} from '../eligibility-dialog/eligibility-dialog.component';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {UsersService} from "../services/users.service";
import { GridOptions } from 'ag-grid-community';
import { catchError, switchMap } from 'rxjs/operators';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';


@Component({
  selector: 'app-spedm-dashboard',
  templateUrl: './spedm-dashboard.component.html' ,
  styleUrls: ['./spedm-dashboard.component.scss']
})
export class SpedmDashboardComponent implements OnInit, AfterViewInit {
  @ViewChild('grid1', { static: false }) grid1: AgGridAngular;
  @ViewChild('grid2') grid2: AgGridAngular;


  isRegisteredUser$ = this.store.select(getIsRegisteredUser) as Observable<boolean>;
  role$ = this.store.select(getRole) as Observable<Permission>;
  isAdmin = false;
  spedm$ = this.store.select(fromRoot.getAllSpedm) as Observable<Spedm[]>;
  currentUserId: number;
  currentDateGrid2: Date;
  currentDateGrid1: Date;
  islatest = true;
  gridOptions = {
    rowSelection: 'multiple',
   } as GridOptions;
  allowOnboardingDelete: boolean;
  spedmAdmin: boolean = true;
  spedmDashboardColumns = ColumnSets.spedmDashboardColumns;
  constructor(private store: Store<fromRoot.AppState>, private router: Router,
              private spedmService: SpedmService,
              private usersService: UsersService,
              public dialog: MatDialog,
              private cookieService: CookieService,
              private alertService: CVSAlertService) {
  }

  dashboardOptions = ['Spedm', 'Eligibility','Benefit'];

  selectedDashboardOption: string = this.dashboardOptions[0];

  dashboardRole = true;
  ngOnInit() {
    this.fetchData();
    // this.store.select(getRole).subscribe(role => {
    this.usersService.userRole.subscribe(role =>{
      if (role === Permission.SPEDM) {
        this.dashboardOptions = ['Spedm'];
        this.dashboardRole = false;
        this.isAdmin = true;
      }else if([ Permission.FILETRACKER].includes(role)){
        this.dashboardOptions = ['Spedm','Eligibility','Benefit'];
        this.dashboardRole = true;
        this.isAdmin = true
      }
      if ([Permission.ADMIN, Permission.MANAGER,
      Permission.EC, Permission.PROCESSOR,
      Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
      Permission.INTFEED].includes(role)) {
        this.dashboardRole = true;
        this.isAdmin = true
      }
     let permissionCodes = this.usersService.permissionCodes
      if(permissionCodes?.includes("ELG_SPEDM_ADMIN")){
        this.spedmAdmin = true;
        this.dashboardRole = false;
        this.isAdmin = true;
      }else{
        this.spedmDashboardColumns = this.spedmDashboardColumns.filter(col=>col.field!=="select")
      }
    });
  }

  ngAfterViewInit(): void {
    this.getNewTableData('tableOne', 'tableOne');
  }
  fetchData() {
    this.store.dispatch(new LoadAllSpedmAction());
  }

  createNewSpedm() {
     this.router.navigateByUrl('dashboard/onboardingspedm');
     this.spedmService.isNew = true;
     // this.store.dispatch(new ClearSpedmAction());
     // this.store.dispatch(new LoadSpedmAction(0));
  }

  refreshFiles(tableId: string) {
    if (tableId === 'tableTwo') {
      this.currentDateGrid2 = new Date();
    }
    if (tableId === 'tableOne') {
      this.currentDateGrid1 = new Date();
      this.store.dispatch(new LoadAllSpedmAction());
    }
  }

  onRowClicked($event: any) {
    this.spedmService.isNew = false;
    this.store.dispatch(new LoadSpedmAction($event.data.cltSetupID));
    // this.router.navigateByUrl('dashboard/onboardingspedm');
  }
  onRowClicked_Table2($event: any) {


    $event.data.screenName = false;

    const dialogRef = this.dialog.open(EligibilityDialogComponent, {
      width: '1400px',
      height: '750px',
      disableClose: true,
      panelClass: 'my-dialog',
      data: {
        selected: 'Eligibility',
        body: $event.data,
      }
    });

  }
  onDragStopped(tableId: string) {
  }

  onSortChange(tableId: string) {
  }

  onFilterChange(tableId: string) {

  }


  filterTables(query: any) {
    this.grid1.api.setQuickFilter(query);
    this.grid2.api.setQuickFilter(query);
  }

  resetFilters(tableId: string) {
    this.cookieService.set('ewf-table-one-column-state', JSON.stringify(this.spedmDashboardColumns.map(column => column.field)));
    this.cookieService.set('ewf-table-one-sort-model', JSON.stringify([{colId: '1', sort: 'asc'}, {colId: '2', sort: 'asc'}]));
    this.setColumnType();
  }

  private setColumnType() {
    this.grid1.api.setColumnDefs(this.spedmDashboardColumns);
  }
  private setColumnTypeGrid2() {
    this.grid2.api.setColumnDefs(ColumnSets.filetrackerMCColumns);
  }
  private getNewTableData(dataSetToFetch: string, tableId: string) {
    let sortOrder;
    this.setColumnType();
    this.setColumnTypeGrid2();

    sortOrder = [];
    this.setSortOrder(tableId, sortOrder);
    this.spedm$.subscribe((spedmData) => {
      // spedmData = [{
      //   ActiveorInactive: 'A', clientreceiver: 'cvs', clientname: 'alexa', drugname: 'plx',
      //   filetype: 'c1', filenamemask: 'kl', reportfrequency: 'dcw', contracteddeliverytime: 'dfv',
      //   latefilewarningtime: 'erds', frequencyofalerts: 'erfrw', latefilewarningalert: 'wefwf', missedfilealert: 'wfwe',
      //   note: 'sample notes'
      // }];
      const tableData = [];
      spedmData.forEach((res: any) => {
        const spedmObject: any = {
          select: false,
          cltSetupID: res.cltSetupID,
          clientReceiver: res.clientReceiver,
          clientName: res.clientName,
          drugName: res.drugName,
          drmName: res.drmName,
          fileType: res.fileType,
          activeorInactiveStatus: res.activeorInactiveStatus,
          filenameMask: res.filenameMask,
          note: res.note,
          reportFrequency: res.spedmAlertConfigModel ? res.spedmAlertConfigModel.reportFrequency : '',
          reportFrequencyVal: res.spedmAlertConfigModel ? res.spedmAlertConfigModel.reportFrequencyVal : '',
          contractedDeliveryTime: res.spedmAlertConfigModel ? res.spedmAlertConfigModel.contractedDeliveryTime : '',
          lateFileWarningTime: res.spedmAlertConfigModel ? res.spedmAlertConfigModel.lateFileWarningTime : '',
          alertFrequency: res.spedmAlertConfigModel ? res.spedmAlertConfigModel.alertFrequency : '',
          lateFileWarningAlert: res.spedmAlertConfigModel ? res.spedmAlertConfigModel.lateFileWarningAlert : '',
          missedFileSLAAlert: res.spedmAlertConfigModel ? res.spedmAlertConfigModel.missedFileSLAAlert : '',
        };
        tableData.push(spedmObject);
      });
      this.grid1.api.setRowData(tableData);

    });
    if (this.islatest === true) {
      const idno = 0;
     //  this.spedmService.getFileTrackerSpeedm(idno).subscribe(rowData => {
     //   this.grid2.api.setRowData(rowData);
     // });
      this.grid2.api.setRowData([]);
   } else {
     const idno = -1;
     // this.spedmService.getFileTrackerSpeedm(idno).subscribe(rowData => {
     //   this.grid2.api.setRowData(rowData);
     // });
      this.grid2.api.setRowData([]);
   }

  }


  private setRowData(tableId: string, rowData) {
    this.grid1.api.setRowData(rowData);
  }

  private setSortOrder(tableId: string, sortOrder) {
    this.grid1.api.setSortModel(sortOrder);
  }


  exportView(tableId) {
    const date = new Date();
    const fileName = 'Filetracker_' + date.getTime() + '.csv';
    if (tableId === 'tableOne') {
      const fileData = new Blob([this.grid1.api.getDataAsCsv()], {type: 'text/csv'});
      saveAs(fileData, fileName);
    } else {
      const fileData = new Blob([this.grid2.api.getDataAsCsv()], {type: 'text/csv'});
      saveAs(fileData, fileName);

    }
  }
  dashboardOption() {
    if (this.selectedDashboardOption === 'Eligibility') {
      this.router.navigateByUrl('dashboard/filetracker');
    } else if (this.selectedDashboardOption === 'Spedm') {
      this.router.navigateByUrl('dashboard/spedm');
    } else if (this.selectedDashboardOption === 'Benefit') {
      this.router.navigateByUrl('dashboard/Benefit');
  }
}

  onChange($event: MatSlideToggleChange) {
    this.islatest = $event.checked;
    this.getNewTableData('tableOne', 'tableOne');
}

oncellCLicked(event){
  const fieldType = event.colDef.field;
  let rowIndex = event.rowIndex;
  if (fieldType == 'select') {
    let gridApi = this.grid1.api
    let rowNode = gridApi.getRowNode(rowIndex);
    event.data.select = !event.data.select
    this.grid1.api.forEachNode(node=>{
      if(node.data.select){
        node.setSelected(true);

      }else{
        node.setSelected(false);
      }
    })
    if(this.grid1.api.getSelectedNodes().length){
      this.allowOnboardingDelete = true;
    }else{
      this.allowOnboardingDelete = false;
    }
    this.grid1.api.refreshCells();
  }else{
    this.onRowClicked(event);
  }
}

deleteclientonboarding(proceed){
  if(!proceed){
    this.grid1.api.forEachNode(node=>{
      if(node.data.select){
        node.data.select = false;
        node.setSelected(false);
      }
    });
    this.allowOnboardingDelete = false;
    this.grid1.api.refreshCells();
  return;
  }
let clientIds:Array<string> = [];
this.grid1.api.getSelectedRows()?.forEach(data=>{
  clientIds.push(data.cltSetupID)
})
if(clientIds.length){
  this.spedmService.deleteclientonboarding(clientIds).pipe(switchMap((resp:any)=>{
    if(resp.status === "SUCCESS" ){
      this.fetchData();
      this.getNewTableData('tableOne', 'tableOne');
      this.showMessage("Delete Successful",'success');
      this.allowOnboardingDelete = false;
    }
    return of(resp);
  }),catchError(error=>{
    this.showMessage(error.errorMessage,'error');
    return of(error);
  })).subscribe();
}

}

showMessage(message, type) {
  this.alertService.sendAlert({
    // headline: message ? message : 'Save successful !',
    headline: message,
    alertType: type === 'error' ? CVSAlertType.Error : CVSAlertType.Success,
    removedAfterMilliseconds: 3000,
    alertDocumentHolderName: '#speedm-dashboard',
  });
}

}

