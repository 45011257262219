import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-label-link',
  templateUrl: './label-link.component.html',
  styleUrls: ['./label-link.component.scss']
})
export class LabelLinkComponent implements OnInit {

  @Input() hyperlink: string;
  @Input() labelText: string;
  @Input() description: string;
  trimmedLabelText;
  whitespaces;
  constructor() { 
   
  }
  ngOnInit(): void {
    this.trimmedLabelText = this.labelText.trim()
    let index = this.labelText.indexOf(this.trimmedLabelText[0]);
    this.whitespaces = this.labelText.substring(0,index);
  }


}
