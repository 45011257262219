import { EligibilityDialogComponent } from './../eligibility-dialog/eligibility-dialog.component';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {getIsRegisteredUser, getRole, getUserId} from '../reducers';
import {Router} from '@angular/router';
import {UsersService} from '../services/users.service';
import {CookieService} from 'ngx-cookie-service';
import {AgGridAngular} from 'ag-grid-angular';
import {Observable, Subscription} from 'rxjs';
import * as ColumnSets from './filetrackerDashboardColumnSets';
import {filetrackerColumns} from './filetrackerDashboardColumnSets';
import {Filetracker} from '../models/Filetracker';
import {FiletrackerService} from '../services/filetracker.service';
import {ClearFiletrakcerAction, LoadAllFiletrackerAction, LoadFiletrackerAction} from '../actions/filetracker.action';
import {saveAs} from 'file-saver';
import {Permission} from '../models/Authorization';
import {MatDialog} from '@angular/material/dialog';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';

@Component({
  selector: 'app-filetracker',
  templateUrl: './filetracker-dashboard.component.html',
  styleUrls: ['./filetracker-dashboard.component.scss']
})
export class FiletrackerDashboardComponent implements OnInit, AfterViewInit , OnDestroy {
  @ViewChild('grid1') grid1: AgGridAngular;
  @ViewChild('grid2') grid2: AgGridAngular;
  userId: any;
  fileTrackerRowData: any;
  constructor(private store: Store<fromRoot.AppState>, private router: Router,
              private filetrackerService: FiletrackerService,
              public dialog: MatDialog,
              private usersService: UsersService, private cookieService: CookieService) {
  }
  isRegisteredUser$ = this.store.select(getIsRegisteredUser) as Observable<boolean>;
  role$ = this.store.select(getRole) as Observable<Permission>;
  isAdmin = false;
  getUserIdSubscription: Subscription;
  currentDateGrid2: Date;
  currentDateGrid1: Date;
  private gridApi;


  filetracker$ = this.store.select(fromRoot.getAllFiletracker) as Observable<Filetracker[]>;

  currentUserId: number;
  trackerOptions: any = ['Eligibility', 'Spedm'];
  selectedFileTrackerOption: any = this.trackerOptions[0];
  dashboardOptions = ['Eligibility','Spedm','Benefit'];
  selectedDashboardOption: string = this.dashboardOptions[0];
  dashboardRole = true;
  islatest = true;

  role: any;
  filetrackerObject:any

  ngOnInit() {
    this.getUserIdSubscription = this.store.select(getUserId).subscribe(userId => this.currentUserId = userId);
    // this.store.dispatch(new LoadAllFiletrackerAction());
    // this.store.select(getRole).subscribe(role => {
    this.usersService.userRole.subscribe(role =>{
      this.role = role;
      if (role === Permission.FILETRACKER) {
        this.dashboardOptions = ['Eligibility','Spedm','Benefit'];
        this.dashboardRole = false;
      }
      if ([Permission.ADMIN, Permission.MANAGER,
        Permission.EC, Permission.PROCESSOR,
        Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
        Permission.INTFEED,  Permission.FILETRACKER, Permission.SPEDM].includes(role)) {
        this.isAdmin = true;
        this.dashboardRole = true;
      } else {
        this.isAdmin = false;
        this.dashboardRole = true;
      }
    });

    // this.usersService.getAuthorizedUser().subscribe(resp => {
    //   this.userId = resp.id;
    // });
    this.usersService.userId.subscribe((id) => {
      this.userId = id
    })
  }

  ngAfterViewInit(): void {
    this.getNewTableData('tableOne', 'tableOne');
  }

  createNewFiletracker() {
    this.filetrackerService.isNew = true;
    // this.store.dispatch(new ClearFiletrakcerAction());
    // this.store.dispatch(new LoadFiletrackerAction(0));
    this.router.navigateByUrl('filetracker')
    this.filetrackerService.isNew = true;
  }
  onRowClicked_Table2($event: any) {

    $event.data.screenName = true;


    const dialogRef = this.dialog.open(EligibilityDialogComponent, {
      width: '1400px',
      height: '750px',
      panelClass: 'my-dialog',
      disableClose: true ,
      data: {
        selected: 'Eligibility',
        body: $event.data,
      }
    });

  }
  onRowClicked($event: any) {

    this.filetrackerService.isNew = false;
    this.filetrackerService.cltSetupId = $event.data.cltSetupID
    this.filetrackerService.fileTrackerRowData = $event.data
    this.store.dispatch(new LoadFiletrackerAction($event.data.cltSetupID));
  }

  onDragStopped(tableId: string) {
  }

  onSortChange(tableId: string) {
  }

  onFilterChange(tableId: string) {

  }


  filterTables(query: any) {
    this.grid1.api.setQuickFilter(query);
    this.grid2.api.setQuickFilter(query);
  }

  resetFilters(tableId: string) {
    this.cookieService.set('ewf-table-one-column-state', JSON.stringify(filetrackerColumns.map(column => column.field)));
    this.cookieService.set('ewf-table-one-sort-model', JSON.stringify([{colId: '1', sort: 'asc'}, {colId: '2', sort: 'asc'}]));
    this.setColumnType();
    this.setColumnTypeGrid2();

  }
  refreshMyProcessingFiles(tableId: string) {
    if (tableId === 'tableTwo') {
      this.currentDateGrid2 = new Date();
    }
    if (tableId === 'tableOne') {
      this.currentDateGrid1 = new Date();
    }
  }

  private setColumnType() {
    this.grid1.api.setColumnDefs(ColumnSets.filetrackerColumns);
  }
  private setColumnTypeGrid2() {
    this.grid2.api.setColumnDefs(ColumnSets.filetrackerMCColumns);
  }
  onFirstDataRendered = params => {
    const yourFilterComponent = params.api.getFilterInstance('completed');
    yourFilterComponent.setModel({
      filterType: 'text',
      type: 'contains',
      filter: 'No'
    });
    params.api.onFilterChanged();
  }

  private getNewTableData(dataSetToFetch: string, tableId: string) {
    let sortOrder;
    this.setColumnType();
    this.setColumnTypeGrid2();
    sortOrder = [];
    this.setSortOrder(tableId, sortOrder);

    // this.filetrackerService.getAll().subscribe(rowData => this.setRowData(tableId, rowData));

    // this.filetracker$.subscribe((filetrackerData) => {
    //   this.grid1.api.setRowData(filetrackerData);
    // });

    if (this.islatest === true) {
      const idno = 0;
     //  this.filetrackerService.getFileTrackerEligibility(idno).subscribe(rowData => {
     //   this.grid2.api.setRowData(rowData);
     // });
      this.grid2.api.setRowData([]);
   } else {
     const idno = -1;
     // this.filetrackerService.getFileTrackerEligibility(idno).subscribe(rowData => {
     //   this.grid2.api.setRowData(rowData);
     // });
      this.grid2.api.setRowData([]);
   }
    // this.filetrackerService.getFileTrackerEligibility(idno).subscribe(rowData => {
    //   this.grid2.api.setRowData(rowData);
    // });

    this.filetrackerService.getAll(this.userId,this.role,false).subscribe(rowData => this.setRowData(tableId, rowData));
  }
  onChange($event: MatSlideToggleChange) {
    this.islatest = $event.checked;
    this.getNewTableData('tableOne', 'tableOne');
}

  private setRowData(tableId: string, rowData) {
    this.grid1.api.setRowData(rowData);
  }

  private setSortOrder(tableId: string, sortOrder) {
    this.grid1.api.setSortModel(sortOrder);
  }

  ngOnDestroy(): void {
    this.getUserIdSubscription.unsubscribe();
  }

  exportView(tableId) {
    const date = new Date();
    const fileName = 'Filetracker_' + date.getTime() + '.csv';
    if (tableId === 'tableOne') {
      const fileData = new Blob([this.grid1.api.getDataAsCsv()], {type: 'text/csv'});
      saveAs(fileData, fileName);
    } else {
      const fileData = new Blob([this.grid2.api.getDataAsCsv()], {type: 'text/csv'});
      saveAs(fileData, fileName);

    }


  }
  fetchTableData(event) {
    if (event.value === 'Spedm') {
      this.router.navigateByUrl('spedm');
    }
  }

  dashboardOption() {
    if (this.selectedDashboardOption === 'Eligibility') {
      this.router.navigateByUrl('dashboard/filetracker');
    } else if (this.selectedDashboardOption === 'Spedm') {
      this.router.navigateByUrl('dashboard/spedm');
    } else if (this.selectedDashboardOption === 'Benefit') {
      this.router.navigateByUrl('dashboard/Benefit');
  }
}
}
