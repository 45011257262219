import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import * as spedm from '../actions/spedm.action';
import {Router} from '@angular/router';
import * as paramMap from '../actions/paramMap.action';
import {Spedm} from '../models/Spedm';
import {NoopAction} from '../actions/noop.action';
import {Contact} from '../models/Contact';
import {DeleteEprFeedAction} from '../actions/epr-feed.action';
import {DELETE_CONTACT, DeleteContactSuccessAction} from '../actions/spedm.action';
import {ParamMapService} from '../services/param-map.service';
import {ParamMap} from '../models/ParamMapping';

@Injectable()
export class ParamMapEffects {
  loadAllParamMap$ = createEffect(() => this.actions$.pipe(
    ofType(paramMap.LOAD_ALL_PARAM_MAP),
    mergeMap(() => this.paramMappingService.getAll()
      .pipe(
        map(paramMapValue => ({ type: paramMap.LOAD_ALL_PARAM_MAP_SUCCESS, payload: paramMapValue as ParamMap[] })),
        catchError(() => of({type: paramMap.LOAD_ALL_PARAM_MAP_FAILURE}))
      ))
    )
  );

  loadParamMap$ = createEffect(() => this.actions$.pipe(
    ofType(paramMap.LOAD_PARAM_MAP),
    mergeMap((action) => this.paramMappingService.getParamMapById(action['payload'])
      .pipe(
        map(paramMapValue => ({ type: paramMap.LOAD_PARAM_MAP_SUCCESS, payload: paramMapValue as Spedm })),
        tap(epr => {
          this.router.navigateByUrl('');
        }),
        catchError(error => {
          return of(new NoopAction());
        })
      ))
    )
  );
  saveSpedm$ = createEffect(() => this.actions$.pipe(
    ofType(paramMap.SAVE_PARAM_MAP),
    mergeMap(action => this.paramMappingService.saveParamMap(action['payload'])
      .pipe(
        map(paramMapValue => ({ type: paramMap.SAVE_PARAM_MAP_SUCCESS, payload: paramMapValue as ParamMap })),
        catchError(() => of({type: paramMap.SAVE_PARAM_MAP_FAILURE}))
      ))
    )
  );
  constructor(
    private actions$: Actions,
    private paramMappingService: ParamMapService,
    private router: Router,
  ) { }
}
