<div class="modal-backdrop">
  <div class="modal">
    <div class="modal-header">
      <h5 class="modal-title">Add Field </h5>
      <button type="button" class="close-btn" aria-label="Close" (click)=closeModal($event)>X</button>
    </div>
    <div class="modal-body">
    
        <div>
          <mat-form-field>
           <mat-label for="field">
            <span *ngIf="selectedField !== 'CDD' && selectedField !== 'cdd' ">Field</span>
            <span *ngIf="selectedField === 'CDD' || selectedField === 'cdd' ">Custom Data</span>
          </mat-label>
            <mat-select [(ngModel)]="selectedField" id="field">
              <mat-option *ngFor="let field of fields" [value]="field">
                {{field}}      
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="selectedField === 'CDD' || selectedField === 'cdd' " style="display: flex; flex-direction: column;">
          <mat-form-field>
            <mat-label for="cddLabel">Label</mat-label>
            <input [(ngModel)]="cddLabel" id="cddLabel"  matInput>
          </mat-form-field>
          <div>
            <mat-form-field class="number">
            
              <input [(ngModel)]="startPosition" type="number" id="quantity" name="quantity" min="1" max="256" matInput>
            </mat-form-field>
            <span>Start Position</span>
          </div>
          
        </div>
        <mat-divider></mat-divider>
      </div>
      <div class="modal-footer">
        <button type="button" class= "btn-cancel cvs-btn-white"(click)="onCancel($event)">Cancel</button>
        <button type="button"  class="cvs-btn-primary" (click)="onSave($event)">Save</button>
      </div>
    </div>
</div>
