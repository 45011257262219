<!-- Add Record, Delete Record & Save Record Features to be added -->
<cvs-alert-placeholder id="translations-alert"></cvs-alert-placeholder>

<div id="save-and-delete-and-add">
  <button id="add-btn"
          class="cvs-btn-text-only"
          (click)="addNewTableRow()"
          [disabled]="rowsTobeDeleted.length > 0 || payloadRequest.length > 0"
  ><mat-icon svgIcon="plus--s"></mat-icon>Add Translation
  </button>

  <button
    class="cvs-btn-primary"
    id="dataSaveBtn" [disabled]="!changedRow"
    (click)="saveTranslations()">Save</button>

  <div class="selected-options" *ngIf="rowsTobeDeleted.length > 0">
    <button id="delete-translation-btn"
            class="cvs-btn-primary"
            (click)="deleteTableRow()"
    ><mat-icon svgIcon="trash--s"></mat-icon>{{this.rowsTobeDeleted.length}} Delete</button>
  </div>
</div>

<!-- Custom Tabular view -->
<div cdkDropList class="table" (cdkDropListDropped)="drop($event)">
  <div class="table-row">
    <div class="table-cell-min" style="border: 0px !important; width: 50px !important;"></div>
    <ng-container *ngFor="let rowHeaders of translationsDataColumns">
      <div class="{{rowHeaders.class}} theader" title="{{rowHeaders.field}}">{{rowHeaders.field}}</div>
    </ng-container>
  </div>

  <div class="translations-data-table-row" *ngFor="let row of dataRows; let i = index" cdkDrag>

    <input type = "hidden" id="translation-id-{{i}}" [value]="dataRows[i].translationId ? dataRows[i].translationId : 0">
    <div class="table-cell-min">
      <div style="width: 50px !important; padding-left: 10%; display: flex; padding-top: 5px;">
          <div>
            <mat-icon id="new-translations-option-checkbox" svgIcon="drag-drop-dot--s"></mat-icon>
          </div>
          <div>
            <mat-checkbox id="data-row-{{i}}" [value]="i" (change)="selectedRows($event)"></mat-checkbox>
          </div>
      </div>
    </div>

    <div class="table-cell-min" style="padding-top: 5px;">{{sequenceColumnIDs[i]}}</div>
    <div class="table-cell">
                <textarea id="options-to-add-input-{{i}}"
                          matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          [ngModel]="dataRows[i].input && dataRows[i].input.length > 0 ? dataRows[i].input : ' '"
                          (ngModelChange)="updateChangeEvent($event, dataRows[i], i)"
                >
              </textarea>
    </div>
    <div class="table-cell">
                <textarea id="options-to-add-output-{{i}}"
                          matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          [ngModel]="dataRows[i].output && dataRows[i].output.length > 0 ? dataRows[i].output : ' '"
                          (ngModelChange)="updateChangeEvent($event, dataRows[i], i)"
                >
                </textarea>
    </div>

    <div class="table-cell">
      <textarea id="options-to-add-soft-text-{{i}}"
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                [ngModel]="dataRows[i].softText && dataRows[i].softText.length > 0 ? dataRows[i].softText : ' '"
                (ngModelChange)="updateChangeEvent($event, dataRows[i], i)"
      >
                </textarea>
    </div>
    <div class="table-cell-min"  style="padding-top: 10px;">
      <mat-checkbox id="isAdvance-{{i}}"
                    [checked]="dataRows[i].isAdvanced === 'Y' ? true : false"
                    (change)="updateChangeEvent($event, dataRows[i], i)"
      ></mat-checkbox>
    </div>
    <div class="table-cell-min" style="padding-top: 10px;">
      <mat-checkbox id="isResetToPreload-{{i}}"
                    [checked]="dataRows[i].isResetToPreload === 'Y' ? true : false"
                    (change)="updateChangeEvent($event, dataRows[i], i)"
      ></mat-checkbox>
    </div>
    <div class="table-cell-min" style="padding-top: 10px;">
      <mat-checkbox id="isCancelled-{{i}}"
                    [checked]="dataRows[i].isCancelled === 'Y' ? true : false"
                    (change)="updateChangeEvent($event, dataRows[i], i)"
      ></mat-checkbox>
    </div>
    <div class="table-cell-min" style="padding-top: 10px;">
      <mat-checkbox id="isDetail-{{i}}"
                    [checked]="dataRows[i].isDetailed === 'Y' ? true : false"
                    (change)="updateChangeEvent($event, dataRows[i], i)"
      ></mat-checkbox>
    </div>
    <div class="table-cell-min" style="padding-top: 10px;">
      <mat-checkbox id="isSummary-{{i}}"
                    [checked]="dataRows[i].isSummary === 'Y' ? true : false"
                    (change)="updateChangeEvent($event, dataRows[i], i)"
      ></mat-checkbox>
    </div>
    <div class="table-cell-min" style=" padding-top: 10px;">
      <mat-select  id="translation-status-{{i}}"
                   [value]="dataRows[i].status"
                   (selectionChange)="updateChangeEvent($event, dataRows[i], i)"
                   placeholder="Select status">
        <mat-option *ngFor="let status of statusValues" [value]="status">{{status}}</mat-option>
      </mat-select>
    </div>
    <div class="table-cell-min" style="border-right: 1px solid grey; padding-top: 10px;">
      <mat-select  id="translation-source-{{i}}"
                   [value]="dataRows[i].source"
                   (selectionChange)="updateChangeEvent($event, dataRows[i], i)"
                   placeholder="Select Source">
        <mat-option *ngFor="let source of sourceValues" [value]="source">{{source}}</mat-option>
      </mat-select>
    </div>
</div>
</div>
