import {
  Actions,
  ADD_TEST_ITERATION,
  CHANGE_SELECTED_TEST_ITERATION,
  DELETE_TEST_ITERATION,
  LOAD_TESTING_SUCCESS,
  UPDATE_TESTING
} from '../actions/testing.action';
import {CustomTestCriteria} from '../models/CustomTestCriteria';
import {convertDateForDatePicker} from '../helpers/DateTimeHelpers';
import {ADD_PROJECT_TRACKER, CLEAR_EPR_DATA, COPY_EPR, DELETE_PROJECT_TRACKER,
  RESET_PROJECT_INDICES, SELECT_PROJECT} from '../actions/epr.action';
import {addItemToArrayInArray, removeItemInArray, replaceItemsInArrayOfArrays} from '../helpers/NgRxHelpers';
import {removeItemsInArrayOfArrays} from './epr.reducer';

export class TestingStateType {
  // Test results header
  testIterationDate: string;
  testFileType: string;
  testIterationFileName: string;
  testFileVolume: string;
  tester: number;
  iterationTstCaseSFDC: string;
  testIterationStatus: string;
  tstCltAprvl: string;
  testIterationMethodology: string[];

  // Standard testing criteria
  tCHeaderTrailerStatus: string;
  tCHeaderTrailerSvrty: string;
  tCHeaderTrailerNotes: string;
  tCEndofRecordStatus: string;
  tCEndofRecordSvrty: string;
  tCEndofRecordNotes: string;
  tCCAGStatus: string;
  tCCAGSvrty: string;
  tCCAGNotes: string;
  tCIDPCStatus: string;
  tCIDPCSvrty: string;
  tCIDPCNotes: string;

  tCFamTypeStatus: string;
  tCFamTypeSvrty: string;
  tCFamTypeNotes: string;
  tCDatesStatus: string;
  tCDatesSvrty: string;
  tCDatesNotes: string;
  tCNumericFieldStatus: string;
  tCNumericFieldSvrty: string;
  tCNumericFieldNotes: string;
  tCSpecialCharLwrCaseStatus: string;
  tCSpecialCharLwrCaseSvrty: string;
  tCSpecialCharLwrCaseNotes: string;
  tCAddrStatus: string;
  tCAddrSvrty: string;
  tCAddrNotes: string;
  tCDemographicStatus: string;
  tCDemographicSvrty: string;
  tCDemographicNotes: string;
  tCLanguageStatus: string;
  tCLanguageSvrty: string;
  tCLanguageNotes: string;
  tCOtherEligStatus: string;
  tCOtherEligSvrty: string;
  tCOtherEligNotes: string;
  tCType4Status: string;
  tCType4Svrty: string;
  tCType4Notes: string;
  tCType5Status: string;
  tCType5Svrty: string;
  tCType5Notes: string;
  tCCOBStatus: string;
  tCCOBSvrty: string;
  tCCOBNotes: string;
  tCExchangeStatus: string;
  tCExchangeSvrty: string;
  tCExchangeNotes: string;

  tCIntegAccumsStatus: string;
  tCIntegAccumsSvrty: string;
  tCIntegAccumsNotes: string;

  tCMedBRDSStatus: string;
  tCMedBRDSSvrty: string;
  tCMedBRDSNotes: string;
  tCMedDStatus: string;
  tCMedDSvrty: string;
  tCMedDNotes: string;
  tCMbrLvlPlanStatus: string;
  tCMbrLvlPlanSvrty: string;
  tCMbrLvlPlanNotes: string;

  customTestCriteria: CustomTestCriteria[];
}

export interface State {
  selectedProjectIndex: number;
  selectedTestIteration: number;
  projectTests: TestingStateType[][];
  testIterationsToDelete: number[];
}

export const initialState: State = {
  selectedProjectIndex: 0,
  selectedTestIteration: 0,
  projectTests: [
    // array of arrays because each project has multiple iterations
    [{
      // Test results header fields
      testIterationDate: '',
      testFileType: '',
      testIterationFileName: '',
      testFileVolume: '',
      tester: null,
      iterationTstCaseSFDC: '',
      testIterationStatus: '',
      tstCltAprvl: '',
      testIterationMethodology: [],

      // Standard testing criteria
      tCHeaderTrailerStatus: '',
      tCHeaderTrailerSvrty: '',
      tCHeaderTrailerNotes: '',
      tCEndofRecordStatus: '',
      tCEndofRecordSvrty: '',
      tCEndofRecordNotes: '',
      tCCAGStatus: '',
      tCCAGSvrty: '',
      tCCAGNotes: '',
      tCIDPCStatus: '',
      tCIDPCSvrty: '',
      tCIDPCNotes: '',
      tCFamTypeStatus: '',
      tCFamTypeSvrty: '',
      tCFamTypeNotes: '',
      tCDatesStatus: '',
      tCDatesSvrty: '',
      tCDatesNotes: '',
      tCNumericFieldStatus: '',
      tCNumericFieldSvrty: '',
      tCNumericFieldNotes: '',
      tCSpecialCharLwrCaseStatus: '',
      tCSpecialCharLwrCaseSvrty: '',
      tCSpecialCharLwrCaseNotes: '',
      tCAddrStatus: '',
      tCAddrSvrty: '',
      tCAddrNotes: '',
      tCDemographicStatus: '',
      tCDemographicSvrty: '',
      tCDemographicNotes: '',
      tCLanguageStatus: '',
      tCLanguageSvrty: '',
      tCLanguageNotes: '',
      tCOtherEligStatus: '',
      tCOtherEligSvrty: '',
      tCOtherEligNotes: '',
      tCType4Status: '',
      tCType4Svrty: '',
      tCType4Notes: '',
      tCType5Status: '',
      tCType5Svrty: '',
      tCType5Notes: '',
      tCCOBStatus: '',
      tCCOBSvrty: '',
      tCCOBNotes: '',
      tCExchangeStatus: '',
      tCExchangeSvrty: '',
      tCExchangeNotes: '',

      tCIntegAccumsStatus: '',
      tCIntegAccumsSvrty: '',
      tCIntegAccumsNotes: '',

      tCMedBRDSStatus: '',
      tCMedBRDSSvrty: '',
      tCMedBRDSNotes: '',
      tCMedDStatus: '',
      tCMedDSvrty: '',
      tCMedDNotes: '',
      tCMbrLvlPlanStatus: '',
      tCMbrLvlPlanSvrty: '',
      tCMbrLvlPlanNotes: '',

      customTestCriteria: []
    }]
  ],
  testIterationsToDelete: []
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {

    case UPDATE_TESTING:
      return {...state,
        projectTests: replaceItemsInArrayOfArrays(
          state.projectTests,
          state.selectedProjectIndex,
          state.selectedTestIteration,
          action.payload
        )
      };

    case RESET_PROJECT_INDICES:
      return {
        ...state,
        selectedProjectIndex: 0,
        selectedTestIteration: 0,
      };

    case LOAD_TESTING_SUCCESS:
      return {...state,
        testIterationsToDelete: [],
        projectTests: action.payload.map((projectTestIterations, index) => {
          let j = 0;
          return projectTestIterations.map(iteration => {
            return {
              ...iteration,
              testIterationDate: convertDateForDatePicker(action.payload[index][j++].testIterationDate)
            };
          });
        })
      };

    case CHANGE_SELECTED_TEST_ITERATION:
      return {...state, selectedTestIteration: action.payload};

    case ADD_PROJECT_TRACKER:
      return {
        ...state,
        selectedProjectIndex: 0,
        selectedTestIteration: 0,
        projectTests: [[{
          ...initialState.projectTests[0][0]
        }], ...state.projectTests]
      };

    case DELETE_PROJECT_TRACKER:
      return {
        ...state,
        selectedProjectIndex: 0,
        selectedTestIteration: 0,
        projectTests: removeItemInArray(state.projectTests, action.iteration)
      };

    case SELECT_PROJECT:
      return {
        ...state,
        selectedProjectIndex: action.payload,
        selectedTestIteration: 0
      };

    case ADD_TEST_ITERATION:
      return {
        ...state,
        selectedTestIteration: 0,
        projectTests: addItemToArrayInArray(state.projectTests, state.selectedProjectIndex, {
          ...initialState.projectTests[0][0]
        })
      };

    case DELETE_TEST_ITERATION:
      if (action.id) {
        return {
          ...state,
          selectedTestIteration: 0,
          testIterationsToDelete: [...state.testIterationsToDelete, action.id],
          projectTests: removeItemsInArrayOfArrays(state.projectTests, state.selectedProjectIndex, action.iteration)
        };
      } else {
        return {
          ...state,
          selectedTestIteration: 0,
          projectTests: removeItemsInArrayOfArrays(state.projectTests, state.selectedProjectIndex, action.iteration)
        };
      }

    case CLEAR_EPR_DATA:
      return initialState;

    case COPY_EPR:
      return initialState;

    default:
      return state;
  }
}

export const getSelectedProjectIndex = state => state.selectedProjectIndex;
export const getSelectedTestIteration = state => state.selectedTestIteration;
export const getTestIterationCount = state => state.projectTests[state.selectedProjectIndex]
&& state.projectTests[state.selectedProjectIndex].length;
export const getCurrentProjectTestIterations = state => state.projectTests[state.selectedProjectIndex];

export const getCustomTestCriteria = state => {
  return state.projectTests[state.selectedProjectIndex][state.selectedTestIteration].customTestCriteria;
};
