import {Injectable} from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ProjectType} from '../models/ProjectTypeEnum';
import {
  baselineTasksConfig,
  discoveryTasksConfig, goLiveConfig, idCardsConfig,
  integratedFeedsTasksConfig,
  setupTasksConfig,
  testTasksConfig
} from '../epr/checklist/ChecklistTasksConfig';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChecklistSettingsService {
  checklistSections = [
    discoveryTasksConfig, setupTasksConfig,  testTasksConfig, integratedFeedsTasksConfig,
    baselineTasksConfig, idCardsConfig, goLiveConfig
  ];

  constructor(private http: CVSHttpClient) {}

  getChecklistMasterMetadata(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/settings/checklistMasterMetadata`);
  }


  saveChecklistMasterMetadata(rowData: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/settings/checklistMasterMetadata/save`,
      rowData);
  }

  getChecklistFieldsEnabledByProjectType(projectType: ProjectType): Observable<any> {
    return this.http.post(`${environment.baseUrl}/settings/checklistMasterMetadata/default`, projectType);
  }


}
