import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ParamMapService} from '../../../services/param-map.service';
import { Subscription} from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, getDataListOptions } from '../../../reducers';
import * as fromRoot from '../../../reducers';
import {DataManagementService} from '../../../services/data-management.service';

@Component({
  selector: 'app-add-new-parameter',
  templateUrl: './add-new-parameter.component.html',
  styleUrls: ['./add-new-parameter.component.scss']
})
export class AddNewParameterComponent implements OnInit {
  form: UntypedFormGroup;
  dataListSubscription: Subscription;
  paramTypeList: any;
  fileTypeList: any = [];
  parameter: string;
  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddNewParameterComponent>,
    private paramMapService: ParamMapService,
    private dataManagementService: DataManagementService, private store: Store<fromRoot.AppState>) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      paramType: new UntypedFormControl('', [Validators.required]),
      parameter: new UntypedFormControl('', [Validators.required]),
      fileType: new UntypedFormControl('', [Validators.required])
    });
    this.loadData();
  }
  close() {
    this.dialogRef.close(null);
}
loadData() {
  this.dataListSubscription = this.dataManagementService.getMasterDataList().subscribe(masterDataList => {
    Array.from(masterDataList).forEach((masterData: any) => {
      if (masterData.listType === 'parameterType') {
        masterData.options.forEach((masterdataOptions: any) => {
          masterdataOptions.replace(/\\,/g, ',');
        });
        this.paramTypeList = masterData.options;

      }
      if (masterData.listType === 'fileType') {
        masterData.options.forEach((masterdataOptions: any) => {
          masterdataOptions.replace(/\\,/g, ',');
        });
        this.fileTypeList = masterData.options;
      }
    });

    // if (this.paramTypeList) {
    //   const dataListOptionsWithEscapeChars = this.paramTypeList.slice();
    //   dataListOptionsWithEscapeChars.forEach(option => {
    //     try {
    //       this.paramTypeList.push(option.replace(/\\,/g, ','));
    //     } catch (e) {

    //     }
    //   });
    // }
  });
}



save(event) {
  const formValue = this.form.value;
  const paramMapData: any = {
    parameterType: formValue.paramType,
    parameter: formValue.parameter,
    fileType: formValue.fileType,
    description: formValue.description
  };
  this.paramMapService.saveParamMap(paramMapData).subscribe(resp => {
    this.dialogRef.close(resp);
  });
}
}
