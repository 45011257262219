import { BackupEcCellRendererComponent } from '../common/BackupEcCellRenderer';
export const filetrackerColumns = [
  // {headerName: 'cltSetupID', field: 'cltSetupID', sortable: true, filter: true, resizable: true},
  {headerName: 'Active/Inactive', field: 'ActiveorInactive',
    sortable: true, filter: 'agTextColumnFilter', resizable: true,
    cellStyle: params => {
      if (params.value === 'I') {
        return { color: '#808080' };
      } else {
        return { color: '#000000' };
      }
    }
  },
  {headerName: 'PLI Code', field: 'pliCode', sortable: true, filter: true, resizable: true},
  {headerName: 'Client ID', field: 'clientId', sortable: true, filter: true, resizable: true},
  {headerName: 'Submitter ID', field: 'submitterId', sortable: true, filter: true, resizable: true},
  {headerName: 'Client Name', field: 'clientName', sortable: true, filter: true, resizable: true},
  {headerName: 'File Route', field: 'fileRoute', sortable: true, filter: true, resizable: true},
  {headerName: 'File Type', field: 'fileType', sortable: true, filter: true, resizable: true},
  {headerName: 'File Name Pattern', field: 'fileTypeNamePattern', sortable: true, filter: true, resizable: true},
  {headerName: 'PG Contract', field: 'pgContract', sortable: true, filter: true, resizable: true},
  {headerName: 'PG Turn Around Time LVL 1', field: 'pgTurnAroundTimeLvl1', sortable: true, filter: true, resizable: true},
  {headerName: 'PG Percentage LVL 1', field: 'pgPercentageLv1', sortable: true, filter: true, resizable: true},
  {headerName: 'PG Turn Around Time LVL 2', field: 'pgTurnAroundTimeLvl2', sortable: true, filter: true, resizable: true},
  {headerName: 'PG Percentage LVL 2', field: 'pgPercentageLv2', sortable: true, filter: true, resizable: true},
  {
    headerName: 'PG Load Reporting Turn Around Time', field: 'pgLoadReportingTurnAroundTime',
    sortable: true, filter: true, resizable: true
  },
  {headerName: 'PG Period', field: 'pgPeriod', sortable: true, filter: true, resizable: true},
  {headerName: 'PG File Critical Client In Effect', field: 'pgFileCriticalClientInEffect', sortable: true, filter: true, resizable: true},
  {headerName: 'PG Load Reporting In Effect', field: 'pgLoadReportingInEffect', sortable: true, filter: true, resizable: true},
  {headerName: 'PG Days Allocation', field: 'pgDaysAllocation', sortable: true, filter: true, resizable: true},
  {headerName: 'Call Alert Receiver Group', field: 'callAlertReceiverGroup', sortable: true, filter: true, resizable: true},
  {headerName: 'Successful Receipt Alert', field: 'successfulReceiptAlert', sortable: true, filter: true, resizable: true},
  {
    headerName: 'Not Processed Alert',
    cellStyle: row => {
      if (row.data.notProcCallIn) {
        return {color: 'red'};
      }
    },
    field: 'notProcessedAlert', sortable: true, filter: true, resizable: true
  },
  {
    headerName: 'Not Processed 1st Alert %',
    cellStyle: row => {
      if (row.data.notProcCallIn) {
        return {color: 'red' };
      }
    },
    field: 'notProcessedfirstAlert', sortable: true, filter: true, resizable: true
  },
  {
    headerName: 'PG Load Reporting Alert',
    cellStyle: row => {
      if (row.data.missedPgLoadReportCallIn) {
        return {color: 'red'};
      }
    },
    field: 'pgLoadReportingAlert', sortable: true, filter: true, resizable: true
  },
  {
    headerName: 'PG Load Reporting 1st Alert %',
    cellStyle: row => {
      if (row.data.missedPgLoadReportCallIn) {
        return {color: 'red'};
      }
    },
    field: 'pgLoadReportingfirstAlert', sortable: true, filter: true, resizable: true
  },
  {headerName: 'Missing Files Alert', field: 'missingFilesAlert', sortable: true, filter: true, resizable: true},
  {
    headerName: 'Processing Error Alert',
    cellStyle: row => {
      if (row.data.procErrorCallIn) {
        return {color: 'red'};
      }
    },
    field: 'processingErrorAlert', sortable: true, filter: true, resizable: true
  },
  {headerName: 'Multiple Expected Files Alert', field: 'multipleExpectedFilesAlert', sortable: true, filter: true, resizable: true},
  {headerName: 'System', field: 'system', sortable: true, filter: true, resizable: true},
  {
    headerName: 'Note', field: 'note', sortable: true, filter: true, resizable: true,
    tooltipField: 'note',
    tooltipComponentParams: {color: '#ececec'}
  }
];

export function createFiletrackerColumns(masterViewColumns: string[]) {
  const customMasterEprColumns = [];

  masterViewColumns.forEach(columnId => {
    customMasterEprColumns.push(filetrackerColumns.filter(column => column.field === columnId)[0]);
  });

  return customMasterEprColumns;
}

export const filetrackerMCColumns = [
  // {headerName: 'File Tracker Details', field: 'pliCode', sortable: true, filter: true, resizable: true},
  {headerName: 'Completed', field: 'completed', sortable: true, filter: true, resizable: true},
  {headerName: 'Client ID', field: 'clientId', sortable: true, filter: true, resizable: true},
  {
    headerName: 'Client Name', field: 'clientName', sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {headerName: 'Submitted ID', field: 'submittedId', sortable: true, filter: true, resizable: true},
  {headerName: 'PLI Name', field: 'pliName', sortable: true, filter: true, resizable: true},
  {headerName: 'Dest Client ID', field: 'destClientId', sortable: true, filter: true, resizable: true},
  {headerName: 'Start Time', field: 'startTime', sortable: true, filter: true, resizable: true},
  {headerName: 'RX Claim End Time ', field: 'fileEndTime', sortable: true, filter: true, resizable: true},
  {headerName: 'End Time', field: 'endTime', sortable: true, filter: true, resizable: true},
  {headerName: 'File Type', field: 'fileType', sortable: true, filter: true, resizable: true},
  {headerName: 'File From Name', field: 'fileFromName', sortable: true, filter: true, resizable: true},
  {headerName: 'Rx Claim File Name', field: 'fileName', sortable: true, filter: true, resizable: true},
  {headerName: 'File Outbound', field: 'fileOutbound', sortable: true, filter: true, resizable: true},
  {headerName: 'Audit Number', field: 'auditnumber', sortable: true, filter: true, resizable: true},
  {headerName: 'Error Type', field: 'errorType', sortable: true, filter: true, resizable: true},
  {headerName: 'EC Name', field: 'ecname', sortable: true, filter: true, resizable: true},
];
