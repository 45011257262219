import {NgModule} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
// All Angular Material Modules to be used in your project should be added through this file

@NgModule({
    imports: [MatButtonModule,
      MatIconModule,
      MatInputModule,
      MatSelectModule,
      MatFormFieldModule,
      MatTabsModule,
      MatRadioModule,
      MatCheckboxModule,
      MatExpansionModule,
      MatDialogModule,
      FormsModule,
      ReactiveFormsModule,
      MatProgressBarModule,
      MatListModule,
      MatButtonToggleModule,
      MatMenuModule,
      MatAutocompleteModule,
      MatSlideToggleModule
    ],
    exports: [MatButtonModule,
      MatIconModule,
      MatInputModule,
      MatSelectModule,
      MatFormFieldModule,
      MatTabsModule,
      MatRadioModule,
      MatCheckboxModule,
      MatExpansionModule,
      MatDialogModule,
      FormsModule,
      ReactiveFormsModule,
      MatProgressBarModule,
      MatListModule,
      MatButtonToggleModule,
      MatMenuModule,
      MatAutocompleteModule,
      MatSlideToggleModule
    ],
})
export class MyMaterialModule { }
