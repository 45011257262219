<form
  [formGroup]="form"
  [appConnectFormValues]="formType.QUESTIONNAIRE">

  <div style="justify-content: space-between" class="Expand-All">
    <div style="display: flex; align-items: center"  (click)="togglePanels()">
      <mat-icon svgIcon="expand--xs"  class="ExpandBtn" *ngIf="!panelOpenState">Expand All</mat-icon>
      <mat-icon svgIcon="collapse--xs"  class="CollapseBtn"  *ngIf="panelOpenState">Collapse All</mat-icon>
      <span class="expand-text">{{!panelOpenState?'Expand All':'Collapse All'}}</span>
    </div>
    <div style="display:flex; gap:26px;">
      <div *ngIf="showProfileToggle || ssoeEnabled" style="display: flex; align-items: center" >
        <cvs-toggle-switch [value]="showProfileToggle" (onToggle)="showProfile()"></cvs-toggle-switch>
        <span style="padding-left: 8px">Show Profiles</span>
      </div>
      <div style="display: flex; align-items: center">
        <cvs-toggle-switch [value]="showNotesToggle" (onToggle)="showNotes()"></cvs-toggle-switch>
        <span style="padding-left: 8px">Show Notes</span>
      </div>
      <div style="display: flex; align-items: center">
        <cvs-toggle-switch [value]="showExternalOnlyToggle" (onToggle)="showExternalOnly()"></cvs-toggle-switch>
        <span style="padding-left: 8px">Show External Only</span>
      </div>
    </div>
  </div>
  <div style="display:flex; justify-content: end; margin-top: 20px; margin-bottom:24px;" *ngIf="showProfileToggle">
    <button  #tooltip="matTooltip" matTooltipClass="tooltip-bg-color"
             matTooltipHideDelay="500" matTooltip="Send All Pending changes to the TEST environment" id="edit" class="cvs-btn-primary" (click)="pushAllToSsoe('test')" [disabled]="!isEditProfile">Push All ({{getDiffrentProfileCount('test')}}) to Test</button>
    <button #tooltip="matTooltip" matTooltipClass="tooltip-bg-color"
            matTooltipHideDelay="500" matTooltip="Send All Pending changes to the PROD environment" id="edit-btn" class="cvs-btn-primary"(click)="pushAllToSsoe('prod')" [disabled]="!isEditProfile">Push All ({{getDiffrentProfileCount('prod')}}) to Prod</button>
  </div>

  <mat-accordion multi="true" #MatAccordion="matAccordion">
    <mat-expansion-panel [togglePosition]="'before'" *ngFor="let item of finalPanels; let pIdx = index" [expanded]="panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 > {{ item[0][0].label }} ({{getCount(item)}}) </h3>

        </mat-panel-title>
      </mat-expansion-panel-header>
      <div style="display:flex;padding-bottom: 10px">
        <div style="width: 450px; border-bottom: 2px solid #E9E9E9">
          <span style="font-weight: 800" class="headerName">Content</span>
        </div>
        <div *ngIf="showProfileSection(item[0])" style="width: 330px; margin:0px 40px;border-bottom: 2px solid #E9E9E9">
          <span style="font-weight: 800" class="headerName">Profiles
            <span style="font-weight: 600">(Test/Prod)</span>
          </span>
        </div>
        <div *ngIf="showNotesToggle" [ngClass]="showProfileToggle ? 'notes-bar' : 'profile-bar'">
          <span style="font-weight: 800" class="headerName">Notes</span>
        </div>
      </div>
      <div [ngClass]="ssoeEnabled && showProfileToggle ? 'grid' : 'gridNoProfiles'">
        <ng-container class="panel-body" *ngFor="let section of item; let i = index">
          <div class="inputs">
            <ng-container *ngFor="let field of section; let j= index">
              <div *ngIf="showExternalOnlyToggle? field.usage === 'External' : true" class="inputAndNotes" [ngClass]="j === 0? 'noHeight':''">
                <div [ngSwitch]="field.inputType" class="input-field" [ngClass]="addFieldInputClasses(field)">
                <div *ngSwitchCase="inputType.SUB_HEADER" class="sub-header">
                 <b>{{field.label}}</b>
<!--                  <button *ngIf="field.notes" (click)="addNote(i, field.notes, pIdx)"-->
<!--                          [id]="field.notes"-->
<!--                          class="cvs-icon-only-btn"-->
<!--                          [disabled]="isANoteFieldFocused || (readOnly$ | async)">-->
<!--                    <mat-icon>add_circle</mat-icon>-->
<!--                  </button>-->
                </div>

                <div *ngSwitchCase="inputType.TEXT" class="text input" [ngClass]="field.heading ? 'heading' : ''">
                  <app-label-link [description]="field.description" [hyperlink]="field.hyperlink"
                    [labelText]="field.label"
                  ></app-label-link>
                  <div class="control-container" >
                  <input
                    type="text"

                    matInput
                    formControlName="{{field.controlName}}"
                  >
                  <ng-container *ngIf="field?.carrierLevel && ssoeEnabled">

                    <div>
                      <div *ngIf="field?.carrierCount" class="carrier-count" (click)="openPliGrid(field)">{{field.carrierCount}}</div>
                      <mat-icon  *ngIf="!field?.carrierCount && (readOnly$ | async) === false"  class="pli-icon" (click)="openPliGrid(field)">add_circle</mat-icon>
                    </div>
                  </ng-container>
                  </div>
                </div>

                <div *ngSwitchCase="inputType.NUMBER" class="number" [ngClass]="field.heading ? 'heading' : ''">
                  <div class="text input">
                    <app-label-link [description]="field.description" [hyperlink]="field.hyperlink"
                      [labelText]="field.label"
                    ></app-label-link>
                    <div class="control-container" >
                    <input *ngIf="!field?.acceptNumberOnly" type="text" matInput formControlName="{{field.controlName}}">
                    <input *ngIf="field?.acceptNumberOnly" class="onlyNumber" type="text" onlyNumber matInput formControlName="{{field.controlName}}" >
                    <ng-container *ngIf="field?.carrierLevel && ssoeEnabled">

                      <div>
                        <div *ngIf="field?.carrierCount" class="carrier-count" (click)="openPliGrid(field)">{{field.carrierCount}}</div>
                        <mat-icon  *ngIf="!field?.carrierCount && (readOnly$ | async) === false"  class="pli-icon" (click)="openPliGrid(field)">add_circle</mat-icon>
                      </div>
                    </ng-container>
                    </div>
                  </div>
                </div>

                <div *ngSwitchCase="inputType.DROPDOWN" class="dropdown input" [ngClass]="field.heading ? 'heading' : ''">
                  <app-label-link [description]="field.description" [hyperlink]="field.hyperlink"
                    [labelText]="field.label"
                  ></app-label-link>
<!--                  <button *ngIf="field.notes" (click)="addNote(i, field.notes, pIdx)" [id]="field.notes" class="cvs-icon-only-btn {{field.controlName}}-notes"-->
<!--                          [disabled]="isANoteFieldFocused || (readOnly$ | async)">-->
<!--                    <mat-icon>add_circle</mat-icon>-->
<!--                  </button>-->
<!--                  <button *ngIf="isFirstElement(field.label)" (click)="addNote(i, field.notes, pIdx)"-->
<!--                          [id]="field.notes"-->
<!--                          class="cvs-icon-only-btn"-->
<!--                          [disabled]="isANoteFieldFocused || (readOnly$ | async)">-->
<!--                    <mat-icon>add_circle</mat-icon>-->
<!--                  </button>-->
                 <div class="control-container" >
                  <select formControlName="{{field.controlName}}">
                    <option *ngFor="let option of field.options" [value]="option">{{option}}</option>
                  </select>
                  <ng-container *ngIf="field?.carrierLevel && ssoeEnabled">

                    <div>
                      <div *ngIf="field?.carrierCount" class="carrier-count" (click)="openPliGrid(field,{options:field.options})">{{field.carrierCount}}</div>
                      <mat-icon  *ngIf="!field?.carrierCount && (readOnly$ | async) === false"  class="pli-icon" (click)="openPliGrid(field,{options:field.options})">add_circle</mat-icon>
                    </div>
                  </ng-container>
                  </div>
                </div>

                <div *ngSwitchCase="inputType.MDL_DROPDOWN" class="dropdown input"
                     [ngClass]="field.heading ? 'heading' : ''">
                  <app-label-link [description]="field.description" [hyperlink]="field.hyperlink"
                    [labelText]="field.label"
                  ></app-label-link>
                  <div class="control-container" >
                  <select formControlName="{{field.controlName}}">
<!--                    <option *ngFor="let option of (field.mdlName | getMdl | async)?.options"-->
<!--                            [value]="option">{{option}}</option>-->
                    <option *ngFor="let option of getMdlDataOptions(field.mdlName)"
                            [value]="option">{{option}}</option>
                  </select>
                  <ng-container *ngIf="field?.carrierLevel && ssoeEnabled">

                    <div>
                      <div *ngIf="field?.carrierCount" class="carrier-count" (click)="openPliGrid(field,{options:getMdlDataOptions(field.mdlName)})">{{field.carrierCount}}</div>
                      <mat-icon  *ngIf="!field?.carrierCount && (readOnly$ | async) === false"  class="pli-icon" (click)="openPliGrid(field,{options:getMdlDataOptions(field.mdlName)})">add_circle</mat-icon>
                    </div>
                  </ng-container>
                 </div>
                </div>

                <div *ngSwitchCase="inputType.FREEFORM_MULTISELECT" class="auto-complete multi-select input"
                     [ngClass]="field.heading ? 'heading' : ''">
                  <app-label-link [description]="field.description" [hyperlink]="field.hyperlink"
                    [labelText]="field.label"
                  ></app-label-link>
<!--                  <button *ngIf="field.notes" (click)="addNote(i, field.notes, pIdx)" [id]="field.notes" class="cvs-icon-only-btn"-->
<!--                          [disabled]="isANoteFieldFocused || (readOnly$ | async)">-->
<!--                    <mat-icon>add_circle</mat-icon>-->
<!--                  </button>-->

      <div class="control-container" >
                  <app-freeform-multiselect [formControlName]="field.controlName"
                                            [defaultOptions]="field.options"></app-freeform-multiselect>
                                            <ng-container *ngIf="field?.carrierLevel && ssoeEnabled">

                                              <div>
                                                <div *ngIf="field?.carrierCount" class="carrier-count" (click)="openPliGrid(field,{options:field.options})">{{field.carrierCount}}</div>
                                                <mat-icon  *ngIf="!field?.carrierCount && (readOnly$ | async) === false"  class="pli-icon" (click)="openPliGrid(field,{options:field.options})">add_circle</mat-icon>
                                              </div>
                                            </ng-container>
                                           </div>
                </div>

                <div *ngSwitchCase="inputType.MULTI_SELECT" class="multi-select input"
                     [ngClass]="field.heading ? 'heading' : ''">
                  <app-label-link [description]="field.description" [hyperlink]="field.hyperlink"
                     [labelText]="field.label"
                  ></app-label-link>
<!--                  <button *ngIf="field.notes" (click)="addNote(i, field.notes, pIdx)" [id]="field.notes" class="cvs-icon-only-btn"-->
<!--                          [disabled]="isANoteFieldFocused || (readOnly$ | async)">-->
<!--                    <mat-icon>add_circle</mat-icon>-->
<!--                  </button>-->
                <div class="control-container" >
                  <mat-select multiple formControlName="{{field.controlName}}">
                    <mat-option *ngFor="let option of field.options" [value]="option">{{option}}</mat-option>
                  </mat-select>
                  <ng-container *ngIf="field?.carrierLevel && ssoeEnabled ">

                    <div>
                      <div *ngIf="field?.carrierCount" class="carrier-count" (click)="openPliGrid(field,{options:field.options})">{{field.carrierCount}}</div>
                      <mat-icon  *ngIf="!field?.carrierCount && (readOnly$ | async) === false"  class="pli-icon" (click)="openPliGrid(field,{options:field.options})">add_circle</mat-icon>
                    </div>
                  </ng-container>
                  </div>
                </div>

                <div *ngSwitchCase="inputType.HORIZONTAL_FIELD_SET"
                     class="horizontal-field-set"
                     [ngClass]="field.heading ? 'heading' : ''">
                  <app-label-link [description]="field.description" [hyperlink]="field.hyperlink"
                    [labelText]="field.label"
                  ></app-label-link>
                  <div class="input-fields">
                    <div class="control-container">
                    <div *ngFor="let subForm of field.subFormGroup">
                      <ng-container [ngSwitch]="subForm.inputType">
                        <div *ngSwitchCase="inputType.TEXT" class="text input">

                          <input
                            type="text"
                            [style.width]="(subForm.controlName==='memIDLength'||subForm.controlName==='memIDMaxLength'
                             || subForm.controlName==='xwalkIDLength'||subForm.controlName==='xwalkIDMaxLength')?'50px':'125px'"
                            matInput
                            formControlName="{{subForm.controlName}}"
                          >

                        </div>

                        <div *ngSwitchCase="inputType.NUMBER" class="text input">

                          <input type="text" onlyNumber
                            [style.width]="(subForm.controlName==='memIDLength'||subForm.controlName==='memIDMaxLength'
                             || subForm.controlName==='xwalkIDLength'||subForm.controlName==='xwalkIDMaxLength'
                             || subForm.controlName==='famIDMinLength'||subForm.controlName==='famIDMaxLength')?'50px':'125px'"
                            matInput
                            formControlName="{{subForm.controlName}}"
                          >

                        </div>


                        <div *ngSwitchCase="inputType.DROPDOWN" class="dropdown input">

                          <select formControlName="{{subForm.controlName}}">
                            <option *ngFor="let option of subForm.options" [value]="option">{{option}}</option>
                          </select>

                        </div>

                      </ng-container>
                    </div>
                    <ng-container *ngIf="field?.carrierLevel && ssoeEnabled">

                      <div>
                        <div *ngIf="field?.carrierCount" class="carrier-count" (click)="openPliGrid(field)">{{field.carrierCount}}</div>
                        <mat-icon  *ngIf="!field?.carrierCount && (readOnly$ | async) === false"  class="pli-icon" (click)="openPliGrid(field)">add_circle</mat-icon>
                      </div>
                    </ng-container>
                    </div>
                  </div>
                </div>

                <div *ngSwitchCase="inputType.DATE" class="date input">
                  <app-label-link [description]="field.description" [hyperlink]="field.hyperlink"
                   [labelText]="field.label"
                  ></app-label-link>
                  <div class="date-input">
                    <div class="control-container">
                    <input [matDatepicker]="datepicker" formControlName="{{field.controlName}}">
                    <mat-datepicker-toggle [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker></mat-datepicker>
                    <ng-container *ngIf="field?.carrierLevel && ssoeEnabled">

                      <div>
                        <div *ngIf="field?.carrierCount" class="carrier-count" (click)="openPliGrid(field)">{{field.carrierCount}}</div>
                        <mat-icon  *ngIf="!field?.carrierCount && (readOnly$ | async) === false" class="pli-icon" (click)="openPliGrid(field)">add_circle</mat-icon>
                      </div>
                    </ng-container>
                    </div>
                  </div>
                </div>
              </div>
                <div *ngIf="showProfileSection(item[0])" class = "profile">
                  <div *ngIf="field.sendToSsoe === 'Yes'" [ngClass]="field.envTestValDiff ? isEditProfile ? 'profile-red-box':'profile-dark-box': 'profile-box'" class="profile-box">
                    <mat-icon *ngIf="field.envTestValDiff" style= "width:16px;height:16px;margin-top: 2px;"
                              [ngStyle]="field.envTestValDiff ? isEditProfile ? {'border-color': 'red',color: 'red'}:{'border-color': 'grey',color: 'grey'}: {'border-color': 'lightgrey',color: 'lightgrey'} "
                              svgIcon="file-send--s" (click)="sendDatassoe(field,'test')">
                      Acearrow Icon
                    </mat-icon>
                    <div [title]="field.envTestVal" class="profileLabel">{{ field?.envTestVal }}</div>
                  </div>
                  <div style="margin-left: 109px" *ngIf=" field.showSsoeTestCount && field.sendToSsoe === 'Yes' && field.envTestDiffCount > 0" class="profile_diff"
                  (click)="openPliGrid(field,{env:'test'})"
                  >{{field.envTestDiffCount}}</div>

                  <div *ngIf="field.sendToSsoe === 'Yes'" [ngClass]="field.envProdValDiff ? isEditProfile ? 'profile-red-box':'profile-dark-box': 'profile-box'" class="profile-box">
                    <mat-icon *ngIf="field.envProdValDiff" style= "width:16px;height:16px;margin-top: 2px;"
                              [ngStyle]="field.envProdValDiff ? isEditProfile ? {'border-color': 'red',color: 'red'}:{'border-color': 'grey',color: 'grey'}: {'border-color': 'lightgrey',color: 'lightgrey'} "
                              svgIcon="file-send--s" (click)="sendDatassoe(field,'prod')">
                      Acearrow Icon
                    </mat-icon>
                    <div [title]="field.envProdVal"class="profileLabel">{{ field?.envProdVal }}</div>
                  </div>
                  <div style="margin-left: 280px" *ngIf="field.showSsoeProdCount && field.sendToSsoe === 'Yes' && field.envProdDiffCount > 0" class="profile_diff"
                  (click)="openPliGrid(field,{env:'prod'})"
                  >{{field.envProdDiffCount}}</div>

<!--                  <div class="profile-box">{{getProfileValue(field,'test').value}}</div>-->
                </div>
                <div *ngIf="field.notes" class="notes" [ngStyle]="{'grid-column-start':showProfileSection(item[0]) ? '3' :'2','grid-column-end':showProfileSection(item[0]) ? '3' :'2'}"> 
                    <div  *ngIf="showNotesToggle" [class]="field.notes + 'box'" class="fieldBox">
                      <quill-editor
                      [id]=field.notes
                      [formControlName]="field.notes"
                      [modules] = "{toolbar: false}"
                      placeholder=""
                      (onFocus)="onNotesFocus()"
                      (onBlur)="onNotesBlur(i, field.notes)"
                      bounds="self"
                    >
                      </quill-editor>
                    </div>
                  </div>
              </div>
            </ng-container>
         </div>
<!--          <div class="notes" *ngIf="section[0] && section[0].notes"-->
<!--         [ngClass]="section[0].inputType === inputType.HEADER ? 'note-under-divider' : ''">-->
<!--      <quill-editor-->
<!--        [formControlName]="section[0].notes"-->
<!--              placeholder=""-->
<!--        (onFocus)="onNotesFocus()"-->
<!--        (onBlur)="onNotesBlur(i, section[0].notes)"-->
<!--        bounds="self"-->
<!--      >-->
<!--      </quill-editor>-->
<!--    </div>-->

        </ng-container>
      </div>
    </mat-expansion-panel>

    <!-- <mat-expansion-panel [togglePosition]="'before'">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>Contact Information</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table id="contact-info">
        <th>Role</th>
        <th>Name</th>
        <th>Email</th>
        <th>Phone Number</th>

        <tr>
          <td>
            Client/Vendor File Contact
          </td>
          <td>
            <mat-form-field appearance="none">
              <input
                formControlName="fileSubmitterName"
                type="text"
                matInput
              >
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="none">
              <input
                formControlName="fileSubmitterEmail"
                type="text"
                matInput
              >
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="none">
              <input
                formControlName="fileSubmitterPhone"
                type="text"
                matInput
              >
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            Primary Load Report Contact
          </td>
          <td>
            <mat-form-field appearance="none">
              <input
                formControlName="loadReportName"
                type="text"
                matInput
              >
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="none">
              <input
                formControlName="loadReportEmail"
                type="text"
                matInput
              >
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="none">
              <input
                formControlName="loadReportPhone"
                type="text"
                matInput
              >
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            Other Contact
          </td>
          <td>
            <mat-form-field appearance="none">
              <input
                formControlName="otherName"
                type="text"
                matInput
              >
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="none">
              <input
                formControlName="otherEmail"
                type="text"
                matInput
              >
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="none">
              <input
                formControlName="otherPhone"
                type="text"
                matInput
              >
            </mat-form-field>
          </td>
        </tr>
      </table>
    </mat-expansion-panel> -->
    <mat-expansion-panel [togglePosition]="'before'" [expanded]="panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>File Prefixes</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ag-grid-angular
        class="ag-theme-alpine pds-ag-grid"
        id="prefixGrid"
        #prefixesGrid
        [suppressMenuHide]= "true"
        [context]="getContext('prefix')"
        (gridReady)="onGridReady($event, 'prefix')">
      </ag-grid-angular>
       <button class="cvs-btn-text-only" id="add-prefix-btn" (click)="addPrefixRow()"
              [disabled]="readOnly$ | async">
        <mat-icon svgIcon="plus--s"></mat-icon>
        Add Row
      </button>
    </mat-expansion-panel>

    <!-- Load Reporting Section -->
    <mat-expansion-panel [togglePosition]="'before'" [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3>Load Report Parameters</h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="rpt-panel">
          <cvs-alert-placeholder id="loadReport-alert"></cvs-alert-placeholder>
          <section *ngIf="ssoeEnabled" class="header-button" style="justify-content: flex-end;display: flex;
        "> 
          <section class="pushToTestProd">
            <button id="pushTest-btn"
                      (click)="sendLoadReportSSOE('test')" 
                      [disabled]="!isDifferentTest || !isEditProfile"
                      class="cvs-btn-primary">
                Push to Test
              </button>
              <ng-container *ngIf="!loadReportLastUpdatedTest">
                <p>Last Sent: N/A</p>
              </ng-container>
              <ng-container *ngIf="loadReportLastUpdatedTest">
                <p>
                  Last Sent:
                </p>
                <p class="timeLastSent">{{loadReportLastUpdatedTest | date:'short'}}</p>
              </ng-container>
         </section>

            <section *ngIf="ssoeEnabled" class="pushToTestProd">
              <button id="pushProd-btn"
                  (click)="sendLoadReportSSOE('prod')" 
                  [disabled]="!isDifferent || !isEditProfile" 
                  class="cvs-btn-primary"
                    >
              Push to Prod
            </button>
            <ng-container *ngIf="!loadReportLastUpdated">
              <p>Last Sent: N/A</p>
            </ng-container>
            <ng-container *ngIf="loadReportLastUpdated">
              <p>
                Last Sent:
              </p>
              <p class="timeLastSent">{{loadReportLastUpdated | date:'short'}}</p>
            </ng-container>
            </section>
            
          </section>
          <div class="rptNotes">
            <ng-container formGroupName="questionariesLoadReportEntity">
              <quill-editor
                formControlName="notes"
                placeholder=""
                bounds="self"
              >
              </quill-editor>
            </ng-container>

        </div>
        <ng-container formGroupName="questionariesLoadReportEntity">
        <mat-tab-group #tabGroup>
          <ng-container>

            <mat-tab  label="Member" id="member">

              <section class="rptMember" >
                <h3>Output Parameters</h3>
                <div id="outputParams">
                  <div class="row">
                    <div id="sftpToggle">
                       <cvs-toggle-switch [disabled]="(readOnly$ | async)" [value]="sftpToggle"  (onToggle)="toggle('sftp')"></cvs-toggle-switch>
                        <span>SFTP</span>

                    </div>
                    <mat-form-field>
                      <mat-label for="sftpFormat">Format</mat-label>
                      <mat-select formControlName="mbrLoadSftpFormat" id="sftpFormat" >
                        <mat-option value="fixedWidth">
                          Fixed Width
                        </mat-option>
                        <mat-option value="delimited">
                          Delimited
                        </mat-option>
                        <mat-option value="hybrid">
                          Hybrid
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field style="width: 23em">
                      <mat-label>Destination Name</mat-label>
                      <input matInput formControlName="mbrLoadSftpDestName">
                      <mat-hint align="start">[PLI].td[filetype]mmddyyyy</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <div id="responseToggle">
                       <cvs-toggle-switch [disabled]="(readOnly$ | async)" [value]="responseToggle"   (onToggle)="toggle('response')"></cvs-toggle-switch>
                        <span>Response</span>

                    </div>
                    <mat-form-field>
                      <mat-label for="responseFormat">Format</mat-label>
                      <mat-select formControlName="mbrLoadResponseFormat" id="responseFormat">
                        <mat-option value="fixedWidth">
                          Fixed Width
                        </mat-option>
                        <mat-option value="delimited">
                          Delimited
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field style="width: 23em">
                      <mat-label>Destination Name</mat-label>
                      <input formControlName="mbrLoadResponseDestName" matInput>
                      <mat-hint align="start">[PLI].td[filetype]mmddyyyy</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
                <div>
                  <mat-form-field id="delimiter">
                    <mat-label for="delimiter">Delimiter</mat-label>
                    <mat-select formControlName="mbrLoadDelimiter">
                      <mat-option value="pipe">
                        | (Pipe)
                      </mat-option>
                      <mat-option value="semi-colon">
                        ; (Semi-colon)
                      </mat-option>
                      <mat-option value="comma">
                        , (Comma)
                      </mat-option>
                      <mat-option value="colon">
                        : (Colon)
                      </mat-option>
                      <mat-option value="colon">
                        " (Quote)
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </section>
              <div class="sectionBar"></div>
              <section class="rptMember" id="breakSort">
               <div id="breakHeader">
                <h3>Break/Sort Order</h3>
                <div id="onBreakToggle" >
                  <cvs-toggle-switch [value]="breakToggle" [disabled]="(readOnly$ | async)" (onToggle)="toggle('onBreak')"></cvs-toggle-switch>
                  <span>Include Break</span>
                </div>

               </div>
               <div id="rptDrag">
                <div id="orderNumbers">
                  <h3>First</h3>
                  <h3>Second</h3>
                  <h3>Third</h3>
                  <h3>Fourth</h3>
                </div>
                <ng-container>
                  <div  cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                  <div *ngFor="let pill of sortOrder" class="example-box" [id]='pill' cdkDrag>
                    <span>{{pill}}</span>
                    <mat-icon class="sortIcon" svgIcon="sort--s"></mat-icon>
                  </div>
                </div>
                </ng-container>

               </div>

              <div>
                <p for="idType" style="line-height: 24px; margin-bottom: 2px;">ID Type</p>
                <mat-radio-group formControlName="mbrIdType" id="idType" (change)="idTypeChange($event)">
                  <mat-radio-button value="member" >Member</mat-radio-button>
                  <mat-radio-button value="alternate" >Alternate</mat-radio-button>
                </mat-radio-group>
              </div>
              </section>
              <div class="sectionBar"></div>
              <section class="rptMember" id="detailMessages">
                <div id="detailHeader">
                  <h3>Detail Messages</h3>
                  <button class="cvs-btn-text-only" [disabled]="(readOnly$ | async)" (click)="openDetailMessageModal()">
                    <mat-icon svgIcon="pencil--s"></mat-icon>
                    <span>Edit Selection</span>
                  </button>
                </div>

                <ng-container formArrayName="mbrDetailMessages">
                    <mat-table class="detailTable" mat-table [dataSource]="loadReportGroup.controls['mbrDetailMessages'].value" >
                        <!-- Type Column -->
                        <ng-container matColumnDef="type">
                          <th mat-header-cell *matHeaderCellDef> Type </th>
                          <td mat-cell *matCellDef="let element"> {{element.message}} </td>
                        </ng-container>

                        <!-- MBR or Both Column -->
                        <ng-container matColumnDef="mbrBoth">
                          <th mat-header-cell *matHeaderCellDef> MBR or Both </th>
                          <td mat-cell *matCellDef="let element"> {{element.reportMessageType}} </td>
                        </ng-container>

                        <!-- Description Column -->
                        <ng-container matColumnDef="description">
                          <th mat-header-cell *matHeaderCellDef> Description </th>
                          <td mat-cell *matCellDef="let element"> {{element.messageDescription}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="detailMessageColumns"></tr>
                        <tr mat-row *matRowDef="let row; let odd = odd; columns: detailMessageColumns;" [ngClass]="{gray:odd}"></tr>
                        <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="4">No detail messages currently</td>
                        </tr>
                      </mat-table>
                </ng-container>
                <div class="sectionBar"></div>
                <div id="maskedData">
                  <div id="maskedDataHeader">
                    <p for="idType">Masked Data</p>
                    <button class="cvs-btn-text-only" [disabled]="(!checkBoxChecked)" (click)="clearAllMaskedData()">
                      <mat-icon svgIcon="close--xs"></mat-icon>
                      <span>Clear Selection</span>
                    </button>
                  </div>
                  <div id="checkboxGrid">
                    <mat-checkbox #checkbox1 formControlName="mbrMaskedMemberId"  (change)=isChecked([checkbox1.checked,checkbox2.checked,checkbox3.checked,checkbox4.checked]) id="checkbox1">Member ID</mat-checkbox>
                    <mat-checkbox #checkbox2 formControlName="mbrMaskedAlternateId" (change)=isChecked([checkbox1.checked,checkbox2.checked,checkbox3.checked,checkbox4.checked]) id="checkbox2">Alternate ID</mat-checkbox>
                    <mat-checkbox #checkbox3 formControlName="mbrMaskedFname" (change)=isChecked([checkbox1.checked,checkbox2.checked,checkbox3.checked,checkbox4.checked]) id="checkbox3">First Name</mat-checkbox>
                    <mat-checkbox #checkbox4 formControlName="mbrMaskedDOB" (change)=isChecked([checkbox1.checked,checkbox2.checked,checkbox3.checked,checkbox4.checked]) id="checkbox4">Date of Birth</mat-checkbox>
                  </div>
                </div>

              </section>
              <div class="sectionBar"></div>

              <section class="rptMember" id="reportFields">
                <h3 style="margin-bottom: 35px;">Report Format</h3>
                <div class="reportGridAndButton">

                  <ag-grid-angular
                  class="ag-theme-alpine pds-ag-grid"
                  id="reportFormatGrid"
                  #reportFormatGrid
                  [columnDefs]="reportFormatColumnDefs"
                  [context]="getContext('report')"
                  [domLayout]="'autoHeight'"
                  [rowDragManaged]="true"
                  [suppressMenuHide]="true"
                  (onRowDragEnd)= "updateSort($event)"
                  >
                </ag-grid-angular>
                <button class="cvs-btn-text-only" id="resetFields"
                  [disabled]="readOnly$ | async" (click)="resetDefault()">
                  <mat-icon svgIcon="redo--s" ></mat-icon>
                  Reset to Default
                </button>
              </div>
                <button class="cvs-btn-text-only" id="add-field"
                  [disabled]="readOnly$ | async" (click)="openAddReportFormatFieldModal()">
                  <mat-icon [ngClass]="!readOnly? 'redIcon': ''" svgIcon="plus--s" ></mat-icon>
                  Add Field
                </button>

                <div>
                  <h3> Report Length: {{reportSpaceUsed}}/<span id="reportFormatMaxSpace" [ngClass]="overMaxSpace? 'overMax': ''">200</span></h3>
                </div>



              </section>
             </mat-tab>
           </ng-container>
           <ng-container *ngIf="(groupFilesSent$ | async) === 'Yes'">

            <mat-tab  label="Group" id="Group">
             </mat-tab>
           </ng-container>

        </mat-tab-group>

      </ng-container>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel [togglePosition]="'before'" [expanded]="panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>Contact Information</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <cvs-alert-placeholder id="contacts-alert"></cvs-alert-placeholder>
      <section *ngIf="ssoeEnabled" class="header-button contactsSSOE" style="justify-content: flex-end;display: flex;
        ">
              <section class="pushToTestProd">
                <button id="contactPushTest-btn"
                          (click)="sendContactsSSOE('test')" 
                          [disabled]="!isDifferentContactsTest || !isEditProfile"
                          class="cvs-btn-primary">
                    Push to Test
                  </button>
                  <ng-container *ngIf="!contactsLastUpdatedTest">
                    <p>Last Sent: N/A</p>
                  </ng-container>
                  <ng-container *ngIf="contactsLastUpdatedTest">
                    <p>
                      Last Sent:
                    </p>
                    <p class="timeLastSent">{{contactsLastUpdatedTest | date:'short'}}</p>
                  </ng-container>
                  
             </section>
    
                <section class="pushToTestProd">
                  <button id="contactPushProd-btn" 
                      [disabled]="!isDifferentContacts || !isEditProfile" 
                      class="cvs-btn-primary"
                      (click)="sendContactsSSOE('prod')"
                        >
                  Push to Prod
                </button>
                <ng-container *ngIf="!contactsLastUpdated">
                  <p>Last Sent: N/A</p>
                </ng-container>
                <ng-container *ngIf="contactsLastUpdated">
                  <p>
                    Last Sent:
                  </p>
                  <p class="timeLastSent">{{contactsLastUpdated | date:'short'}}</p>
                </ng-container>
                </section>
              </section>
      <!-- <table id="addt-contact-info">
        <th>Role</th>
        <th>Name</th>
        <th>Email</th>
        <th>Phone Number</th>        

        <ng-container formArrayName="contactInfo">
          <tr *ngFor="let _ of additionalContactsFormArray.controls; let i = index" [formGroupName]="i">
            <td>
              <mat-form-field appearance="none" class="test-criteria-input">
                <input
                  formControlName="contactRole"
                  type="text"
                  matInput
                >
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="none" class="test-criteria-input">
                <input
                  formControlName="contactName"
                  type="text"
                  matInput
                >
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="none" class="test-criteria-input">
                <input
                  formControlName="contactEmail"
                  type="text"
                  matInput
                >
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="none" class="test-criteria-input">
                <input
                  formControlName="contactPhone"
                  type="text"
                  matInput
                >
              </mat-form-field>
            </td>
            <button *ngIf="(readOnly$ | async) === false" class="cvs-icon-only-btn" (click)="deleteAdditionalContact(i)">
              <mat-icon svgIcon="trash--s"></mat-icon>
            </button>
          </tr>
        </ng-container>
      </table> -->
      <ag-grid-angular
      class="ag-theme-alpine pds-ag-grid"
      #contactGrid
      [context]="getContext()"
     (gridReady)="onGridReady($event)"
     style="width: 1230px;"
    >
    </ag-grid-angular>
      <button class="cvs-btn-text-only" id="add-addt-contact-btn" (click)="addAdditionalContact()"
              [disabled]="readOnly$ | async">
        <mat-icon svgIcon="plus--s"></mat-icon>
        Add Additional Contact
      </button>
    </mat-expansion-panel>

    <mat-expansion-panel [togglePosition]="'before'"  [expanded]="panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>Previous EC</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table id="previous-ea-groups">
        <th>Previous EC</th>
        <th>EC Thru</th>
        <ng-container formArrayName="previousEaGroup">
          <tr *ngFor="let _ of previousEaGroupFormArray.controls; let i = index" [formGroupName]="i">
            <td>
              <mat-form-field appearance="none" class="previous-ec-input">
                <mat-select formControlName="previousEa">
                  <mat-option *ngFor="let user of allUsers$ | async"
                              [value]="user.id">{{user.firstName}} {{user.lastName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="table-date-input" appearance="none">
                <input matInput [matDatepicker]="eaDatePicker" formControlName="previousEADateSince">
                <mat-datepicker-toggle matSuffix [for]="eaDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #eaDatePicker></mat-datepicker>
              </mat-form-field>
            </td>
            <button *ngIf="(readOnly$ | async) === false" class="cvs-icon-only-btn" (click)="deletePreviousEaGroup(i)">
              <mat-icon svgIcon="trash--s"></mat-icon>
            </button>
          </tr>
        </ng-container>
      </table>
      <button class="cvs-btn-text-only" id="add-previous-eaGroup-btn" (click)="addPreviousEaGroup()"
              [disabled]="readOnly$ | async">
        <mat-icon svgIcon="plus--s"></mat-icon>
        Add Previous EC
      </button>
    </mat-expansion-panel>

    <mat-expansion-panel [togglePosition]="'before'" [expanded]="panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>Audit History</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table id="ec-audit-history">
        <th>EC Audited</th>
        <th>Auditor</th>
        <th>Date</th>
        <ng-container formArrayName="auditSection">
          <tr *ngFor="let _ of ecAuditHistoryFormArray.controls; let i = index" [formGroupName]="i">
            <td>
              <mat-form-field appearance="none">
                <mat-select formControlName="ecaudited">
                  <mat-option *ngFor="let user of allUsers$ | async"
                              [value]="user.id">{{user.firstName}} {{user.lastName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="none">
                <mat-select formControlName="auditor">
                  <mat-option *ngFor="let user of allUsers$ | async"
                              [value]="user.id">{{user.firstName}} {{user.lastName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="table-date-input" appearance="none">
                <input matInput [matDatepicker]="auditDatePicker" formControlName="auditDate">
                <mat-datepicker-toggle matSuffix [for]="auditDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #auditDatePicker></mat-datepicker>
              </mat-form-field>
            </td>
            <button *ngIf="(readOnly$ | async) === false" class="cvs-icon-only-btn" (click)="deleteAuditHistoryEntry(i)">
              <mat-icon svgIcon="trash--s"></mat-icon>
            </button>
          </tr>
        </ng-container>
      </table>
      <button class="cvs-btn-text-only" id="add-audit-entry-btn" (click)="addAuditEntry()"
              [disabled]="readOnly$ | async">
        <mat-icon svgIcon="plus--s"></mat-icon>
        Add Auditor
      </button>
    </mat-expansion-panel>
  </mat-accordion>

</form>
