import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {AlertType} from '../../models/AlertType';
import {AgGridAngular} from 'ag-grid-angular';
import {FiletrackerColumns} from './filetracker-contact-columnSets';
import * as fromRoot from '../../reducers';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {Contact} from '../../models/Contact';
import {LoadContactAction} from '../../actions/filetracker.action';
import {FiletrackerService} from '../../services/filetracker.service';
import {DeleteBtnCellRendererComponent} from '../../common/DeleteBtnCellRenderer';
import {CellEditingStartedEvent} from "ag-grid-community";
import {CVSAlertService, CVSAlertType} from "angular-component-library";

export interface ConfirmationDialogData {
  setupId: any;
  alertField: any;
  type: AlertType;
  isEdited: boolean;
}

@Component({
  selector: 'app-filetracker-contact-dialog',
  templateUrl: './filetracker-contact-dialog.component.html',
  styleUrls: ['./filetracker-contact-dialog.component.scss']
})
export class FiletrackerContactDialogComponent implements AfterViewInit {

  tooltipShowDelay: any;
  rowSelection: any;
  @ViewChild('filetrackerContact') filetrackerContact: AgGridAngular;
  isLoading = false;
  contextObj: any;
  contacts$ = this.store.select(fromRoot.getContact) as Observable<Contact[]>;
  frameworkComponents: any;
  savedisabled: boolean = true;
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,private alertService: CVSAlertService,
              public dialogRef: MatDialogRef<ConfirmationDialogData>,
              private store: Store<fromRoot.AppState>,
              private filetrackerService: FiletrackerService
  ) {
    this.contextObj = {
      componentParent: this
    };
    this.frameworkComponents = {
      eligibilitydeleteAction: DeleteBtnCellRendererComponent
    };
  }
  contactData: any;

  ngAfterViewInit() {

    this.store.dispatch(new LoadContactAction({id: this.data.setupId, alertType: this.data.alertField}));
    this.isLoading = true;
    this.filetrackerContact.gridOptions.defaultColDef = {
      flex: 0,
      minWidth: 130,
      editable: true,
      resizable: false
    };


    // @ts-ignore
    this.filetrackerContact.api.setColumnDefs(FiletrackerColumns);
    this.filetrackerContact.gridOptions.tooltipShowDelay = 0;
   this.getcontact()
   }
  public deleteFiterTracker(node) {
    this.filetrackerService.deleteContact(node.data.emailId).subscribe(resp => {
      if (resp > 0) {
        // this.filetrackerContact.api.applyTransaction({remove: node.data});
        this.filetrackerContact.api.removeItems([node], false);
      }
    });
  }

  getcontact(){
    this.filetrackerService.getContact(this.data.setupId,  this.data.alertField).subscribe((contactData) => {
      this.contactData = contactData;
      this.filetrackerContact.api.setRowData(this.contactData);
    });
  }
  closeHandler() {
    this.contactData = [];
    this.dialogRef.close();
  }

  onRowClicked($event: any) {
  }

  onDragStopped() {

  }

  onSortChange() {

  }

  onFilterChange() {

  }

  onCellEditingStarted(event: CellEditingStartedEvent) {
  if(event.data['statusCd'] == 'Active' || 'Inactive'){
    this.savedisabled = false;
  }
  }

  save() {
    this.filetrackerContact.api.stopEditing()
  //  this.store.dispatch(new UpdateContact({contactData: this.contactData, field: this.data.alertField}));
    this.filetrackerService.editContact(this.contactData, this.data.alertField)
      .subscribe(resp => {
        this.getcontact();
        this.savedisabled = true;
      });
  }
  cancel() {
    this.contactData = [];
    this.dialogRef.close();
  }
  add() {
    const contact = new Contact();
    contact.contactId = 'save';
    contact.setupId = this.data.setupId;
    contact.emailId = '';
    contact.alertTypeCd = '';
    this.contactData.push(contact);
    this.filetrackerContact.api.setRowData(this.contactData);
  }

  onCellClicked($event: any) {
    if($event.colDef.headerName === 'Action'){
      this.deleteFiterTracker($event.node)
    }else{
      return
    }
  }
}
