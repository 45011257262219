import {AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {FormType} from '../models/FormType';
import {LoadUsersAction} from '../actions/user-management.action';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import {EprService} from '../services/epr.service';
import {UsersService} from "../services/users.service";
import {Permission} from "../models/Authorization";
import {getEprId, getProjectTestingEditState} from "../reducers";

@Component({
  selector: 'app-epr',
  templateUrl: './epr.component.html',
  styleUrls: ['./epr.component.scss']
})
export class EprComponent implements OnInit,AfterViewInit, OnDestroy {

  @ViewChild("testResultsRef") testResultsRef;
  @ViewChild("tabGroup") tabs: MatTabGroup;
  FormType = FormType;
  readonly: boolean;
  readOnlySubscription: Subscription;
  isEprForm = true;
  profileData: any = [];
  loadProfileData = false;
  showProcessingFilesTab= true;
  showMappingTab = true;
  showHistoryTab= false;

  showAllTabsInEpr = true;
  eprId:any;

  readOnly$ = this.store.select(fromRoot.getReadOnly) as Observable<boolean>;
  role: any;
  selectedTab = 'Questionnaire';
  isProjectTestingEditable: boolean;
  handleTabClick;
  tabNames= ['Questionnaire', 'Projects', "Test Results", 'Eligibility Agreement','File Tracker','Processing Files', 'Mapping','History']
  constructor(private store: Store<fromRoot.AppState>,
              private eprService: EprService,private usersService: UsersService) {
    this.store.dispatch(new LoadUsersAction());
    this.readOnlySubscription = this.readOnly$.subscribe(result => {
      this.readonly = result;
    });
    this.usersService.userRole.subscribe(role=>{
      if(role){
        this.role = role;
      }
      if(role && (role === Permission.SPEDM)){
        this.showProcessingFilesTab= false;
        this.showMappingTab = false;
        this.showAllTabsInEpr= false;
      }
    })
      this.showMappingTab = this.usersService.permissionCodes.includes('ELG_VIEW_MAPPING_TAB')
  }
  tabIndex = 0;

  scrollToTop() {
    document.getElementById('head').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.readonly) {
      $event.returnValue = true;
    }
  }

  ngOnInit() {
    this.store.select(getEprId).subscribe(id => {
      this.eprId = id;
      if(this.eprId){
        this.showHistoryTab = true;
      }
    })
    this.store.select(getProjectTestingEditState).subscribe(edit=>{
    this.isProjectTestingEditable = edit;
    })
  }
  ngAfterViewInit(): void {
    this.monitorTabClick();
}
  ngOnDestroy(): void {
    this.readOnlySubscription.unsubscribe();
  }
  
  
  monitorTabClick(){
    //Catches Material tab click before event is dispatched
    this.handleTabClick = this.tabs._handleClick;
      
    // Adds logic check to click
    this.tabs._handleClick = (tab, header, index) => { 
     
    if((this.selectedTab === "Projects" || this.selectedTab === "Test Results") && this.isProjectTestingEditable){
      //check if project or testing are in edit mode
      this.eprService.triggerProjectTrestingConfirmation(true);
      return;
    }
   // Disptches tab change event
   this.handleTabClick.apply(this.tabs, [tab, header, index]);
   
  };
  }
  tabChanged(event: MatTabChangeEvent) {
    
      this.tabIndex = event.index;
      this.selectedTab = event.tab.textLabel;
      this.eprService.currentTab = this.selectedTab
   
  }


  showAllTabsAtEpr(label: any){
    if(Permission.SPEDM === this.role){
      return false;
    }
    if((this.role === Permission.VISITOR || this.role === Permission.FILETRACKER) && label.includes('Processing Files')){
      return false;
    } else{
      return true;
    }
  }

  saveTestResults() {
    if(this.testResultsRef && this.testResultsRef.payloadTestingEntityList && this.testResultsRef.payloadTestingEntityList.length){
      const payload= this.testResultsRef.payloadTestingEntityList.map(t=> {
        if(typeof t.id === 'string'){
          t.id = null;
        }
        return t;
      });

      this.eprService.saveTestResults(payload).subscribe(res =>{
        this.testResultsRef.payloadTestingEntityList=[];
      }, error => {
        this.testResultsRef.payloadTestingEntityList=[];
      });
    }
  }
}
