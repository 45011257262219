import {Action} from '@ngrx/store';
import {AddTestIterationAction, DeleteCustomTestCriteriaAction, DeleteTestIterationAction} from './testing.action';
import {DeleteAdditionalContactAction, DeleteAuditHistoryAction, DeletePreviousEcAction} from './questionnaire.action';
import {DeleteChangeLogAction} from './eligibility-agreement.action';

export const LOAD_EPR = '[EPR] Load Epr';
export const LOAD_EPR_SUCCESS = '[EPR] Load Epr Success ';
export const LOAD_EPR_FAILURE = '[EPR] Load Epr Failure';
export const SAVE_EPR = '[EPR] Saving Epr...';
export const SAVE_EPR_SUCCESS = '[EPR] Epr saved successfully';
export const DELETE_EPR = '[EPR] Deleting Epr...';
export const DELETE_EPR_SUCCESS = '[EPR] Epr deleted successfully';

export const CLEAR_EPR_DATA = '[EPR] Clearing all EPR Data';

export const START_EDIT = '[EPR] Start Edit';
export const START_EDIT_SUCCESS = '[EPR] Start Edit Success';
export const START_EDIT_FAILURE = '[EPR] Start Edit Failure';
export const STOP_EDIT = '[EPR] Stop Edit';

export const ADD_PROJECT_TRACKER = '[PROJECT_TRACKER] Add Project Tracker Form';
export const DELETE_PROJECT_TRACKER = '[PROJECT_TRACKER] Delete Project Tracker Form';
export const DELETE_PROJECT_TRACKER_SUCCESS = '[PROJECT_TRACKER] Delete Project Tracker Form Success';
export const SELECT_PROJECT = '[EPR] Selecting a project...';

export const RESET_PROJECT_INDICES = '[EPR] Resetting project indices...';

export const SET_FIRST_LOAD = '[EPR] Set First Load';

export const COPY_EPR = '[EPR] Copy EPR';
export const PREVIOUS_DATA = '[EPR] Previous Data EPR';
export const CHANGED_QUESTIONNARIE_RECORD = '[EPR] Previous Questionnaire Record';
export const CHANGED_QUESTIONNARIE_RECORD_SUCCESS = '[EPR] Previous Questionnaire Record Success';
export const CHANGED_QUESTIONNARIE_RECORD_FAILURE = '[EPR] Previous Questionnaire Record Failure';
export const PROJECT_TESTING_EDIT = '[EPR] Project Or Testing Edit Mode'
export class SelectProjectAction implements Action {
  readonly type = SELECT_PROJECT;

  constructor(public payload: any) {  }
}

export class AddProjectTrackerFormAction implements Action {
  readonly type = ADD_PROJECT_TRACKER;

  constructor(public payload: any) {  }
}

export class DeleteProjectTrackerFormAction implements Action {
  readonly type = DELETE_PROJECT_TRACKER;

  constructor(public id: number, public iteration: number) { }
}

export class DeleteProjectTrackerSuccessAction implements Action {
  readonly type = DELETE_PROJECT_TRACKER_SUCCESS;
}

export class SetFirstLoadAction implements Action {
  readonly type = SET_FIRST_LOAD;

  constructor(public payload: any) {  }
}

export class StartEditAction implements Action {
  readonly type = START_EDIT;

  constructor(public payload: number) {  }
}

export class StartEditSuccessAction implements Action {
  readonly type = START_EDIT_SUCCESS;

  constructor(public payload: number) {  }
}

export class StartEditFailureAction implements Action {
  readonly type = START_EDIT_FAILURE;
}

export class ClearEprAction implements Action {
  readonly type = CLEAR_EPR_DATA;
}

export class StopEditAction implements Action {
  readonly type = STOP_EDIT;
}

export class LoadEprAction implements Action {
  readonly type = LOAD_EPR;

  constructor(public payload: number, public view: string, public download= false) {
  }
}

export class LoadEprSuccessAction implements Action {
  readonly type = LOAD_EPR_SUCCESS;

  constructor(public payload: any) {
  }
}

export class SaveEprAction implements Action {
  readonly type = SAVE_EPR;
}

export class SaveEprSuccessAction implements Action {
  readonly type = SAVE_EPR_SUCCESS;
}

export class DeleteEprAction implements Action {
  readonly type = DELETE_EPR;

  constructor(public payload: number) {
  }
}

export class DeleteEprSuccessAction implements Action {
  readonly type = DELETE_EPR_SUCCESS;
}

export class LoadEprFailureAction implements Action {
  readonly type = LOAD_EPR_FAILURE;
}

export class ResetProjectIndicesActions implements Action {
  readonly type = RESET_PROJECT_INDICES;
}

export class CopyEprAction implements Action {
  readonly type = COPY_EPR;
}
export class PreviousQuestionnaireDataAction implements Action {
  readonly type = PREVIOUS_DATA;
  constructor(public payload: any) {  }
}

export class SaveChangedQuestionnaireRecordAction implements Action {
  readonly type = CHANGED_QUESTIONNARIE_RECORD;
  constructor(public payload: any) {}
}

export class SavePreviousQuestionnaireRecordSuccessAction implements Action {
  readonly type = CHANGED_QUESTIONNARIE_RECORD_SUCCESS;
  constructor(public payload: any) {  }
}

export class SavePreviousQuestionnaireRecordFailureAction implements Action {
  readonly type = CHANGED_QUESTIONNARIE_RECORD_FAILURE;
  constructor(public payload: any) {  }
}
export class ProjectOrTestingEdit implements Action {
  readonly type = PROJECT_TESTING_EDIT;
  constructor(public payload : boolean){}
}

export type Actions
  = StartEditAction
  | StartEditSuccessAction
  | StartEditFailureAction
  | StopEditAction
  | LoadEprAction
  | LoadEprSuccessAction
  | LoadEprFailureAction
  | SaveEprAction
  | SaveEprSuccessAction
  | DeleteEprAction
  | DeleteEprSuccessAction
  | ClearEprAction
  | AddProjectTrackerFormAction
  | DeleteProjectTrackerFormAction
  | SelectProjectAction
  | SetFirstLoadAction
  | AddTestIterationAction
  | DeleteAdditionalContactAction
  | DeletePreviousEcAction
  | DeleteCustomTestCriteriaAction
  | DeleteChangeLogAction
  | DeleteAuditHistoryAction
  | ResetProjectIndicesActions
  | DeleteTestIterationAction
  | PreviousQuestionnaireDataAction
  | CopyEprAction
  | DeleteTestIterationAction
  | DeleteProjectTrackerSuccessAction
  | SaveChangedQuestionnaireRecordAction
  | SavePreviousQuestionnaireRecordSuccessAction
  | SavePreviousQuestionnaireRecordFailureAction
  | ProjectOrTestingEdit;
