import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CVSHttpClient, CVSAlertService, CVSAlertType } from 'angular-component-library';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogicCodeApiService {

  baseURL = environment.baseCustomMappingUrl;
  constructor(private http: CVSHttpClient,
              private privateHTTP: HttpClient,
              private alertService: CVSAlertService) { }



  getLogicCodeApi() {
    return this.http.get( `${this.baseURL}/logicCodes/getLogicCodes`);
  }
  addLogicCodeApi(details) {
    return this.http.post(`${this.baseURL}/logicCodes/addLogicCodes`, details)
    .pipe(
      catchError((err) => {
      this.showMessage(err.error.message);
      return of(null);
    }));
  }

  updateLogicCodeApi(update, orignal) {
    const url = `${this.baseURL}/logicCodes/editLogicCodes/${orignal.logicCode}`;
    return  this.http.put( url, update)
    .pipe(catchError((err) => {
      this.showMessage(err.error.message);
      return of(null);
    })
  );
  }

  getRxclaimFieldsApi() {
    return this.http.get( `${this.baseURL}/logicCodes/getRxClaimFields`);
  }

  getMapTypesApi() {
    return this.http.get( `${this.baseURL}/logicCodes/getMapTypes`);
  }
  deleteLogicCodeApi(body) {
    return  this.http.post(`${this.baseURL}/logicCodes/deleteLogicCode`, body)
    .pipe(catchError((err) => {
      this.showMessage(err.error.message);
      return of(null);
    })
  );
  }
  showMessage(body) {

    this.alertService.sendAlert({
      headline: body,
      alertType: CVSAlertType.Error,
      removedAfterMilliseconds: 3000,
      alertDocumentHolderName: '#logic-code',
    });

  }

}
