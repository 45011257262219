import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogicCodeService {
  
  updateLogicCode$: Subject<any> = new Subject();
  constructor(private fb: UntypedFormBuilder) { }

  updateLogicField() {
    this.updateLogicCode$.next(true);
  }
  
  createLogicCodeGroup(logicCodeData): UntypedFormGroup {

  

    function addLogicGroup(data) {
     
      const fileGroup = new UntypedFormGroup({
        check: new UntypedFormControl(false , [] ),
        logicCode: new UntypedFormControl({value: data.logicCode ,  disabled: true} , []),
        mapType: new UntypedFormControl({value: data.mapType ,  disabled: true} , []),
        rxClaimField: new UntypedFormControl({value : data.rxClaimField ,  disabled: true } , []),
        fieldTag: new UntypedFormControl({value : data.fieldTag ,  disabled: true } , []),
        mappingInstructions: new UntypedFormControl({value : data.mappingInstructions , disabled: true } , []),
        mappingDescription: new UntypedFormControl({value : data.mappingDescription ,  disabled: true } , []),
        logicCodeStatus: new UntypedFormControl({value : data.logicCodeStatus , disabled: true }, []),
       
      });
  
      return fileGroup;
    }
  
    const groupArray  = this.fb.group({logicArray: new UntypedFormArray([])}); 
  
    logicCodeData.forEach(data => {
      if (Object.keys(data).length) {
        (groupArray.get('logicArray') as UntypedFormArray).push(addLogicGroup(data)); 
        // get a Form array named record and add multiple record controls.
        
      }
    });
    
    return groupArray;
     }
  generateImportSearch() {
    const form = this.fb.group({
      search: new UntypedFormControl()
    });
    return form;
  }
  generateFilterForm(){
    const form = this.fb.group({
      logicCodeFilter: new UntypedFormControl(),
      mapTypeFilter: new UntypedFormControl(),
      rxClaimFieldFilter: new UntypedFormControl(),
      mapInstructionsFilter: new UntypedFormControl(),
      mapDescriptionFilter: new UntypedFormControl(),
      statusFilter: new UntypedFormControl(),
      fieldTagFilter: new UntypedFormControl(),
    });
    return form;
  }
}
