
import { state } from "@angular/animations";
import { SET_LOGGED_IN_USERPBM, Actions, SET_PERMISSIONS_OBJECT} from "../actions/userPbm.action";
import { Permission } from "../models/Authorization";
import { PermissionsObject } from "../models/PermissionsObject";
import { BasicUserInfo, SuperClient, UserPermission, User_PBM } from "../models/User_PBM";


export interface State {
    user: any;
    permissionsObject: PermissionsObject
  }

  export const initialState: State = {
    permissionsObject: null,
    user: null
  };

  export function reducer(state = initialState, action: Actions): State {
    if(action.type === SET_PERMISSIONS_OBJECT){
      return {...state, permissionsObject: action.permissionObject};
    }
    else if (action.type === SET_LOGGED_IN_USERPBM) {
    //   return {...state, role: filteredRole, rdcs: action.user.rdcs, id: action.user.id};
    return {...state, user: action.user};

    } else {
      return state;
    }
  }


  export const getPermissionsAdminObject= (state: State) => state.permissionsObject.createAdminObject()
  export const getPermissionsDashboardObject= (state: State) => state.permissionsObject.createDashboardObject()
  export const getPermissionsEprObject= (state:State) => state.permissionsObject.createEprOpbject()
  export const getPermissionsProcessingFilesObject= (state:State) => state.permissionsObject.createProcessingFilesObject()
  export const getPermissionsFiletrackerObject = (state:State)=> state.permissionsObject.createFiletrackerObject()
  export const getUserPBM = (state: State) => state.user
  export const getUserId = (state: State) => state.user.employeeId;
  export const getIsRegisteredUser = (state: State): boolean => state.user.adminUser;
  export const getPermissions = (state: State) => state.user.permissions
  export const getUserNamePBM = (state:State)=>{return state.user.basicUserInfo.lastName + ', ' + state.user.basicUserInfo.firstName}
