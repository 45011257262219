<div class="popup">
  <mat-dialog-content class="dialog-header">
    <div class="icon-and-title">
      <h2>{{data.type}}</h2>
    </div>
    <button id="closeAction" class="cvs-icon-only-btn" mat-dialog-close (click)="closeHandler()" >
      <mat-icon svgIcon="close-btn--s"></mat-icon>
    </button>
  </mat-dialog-content>
  <mat-dialog-content id="bodyTextContent">
    <ag-grid-angular
      class="ag-theme-alpine epr-feed-gui-grid" style="height: 300px !important;"
      #filetrackerContact
      [context]="contextObj"
      [frameworkComponents]="frameworkComponents"
      (rowClicked)='onRowClicked($event)'
      (dragStopped)="onDragStopped()"
      (sortChanged)="onSortChange()"
      (filterChanged)="onFilterChange()"
      [rowSelection]="rowSelection"
      [tooltipShowDelay]="tooltipShowDelay"
      (cellEditingStarted)="onCellEditingStarted($event)"
      (cellClicked)='onCellClicked($event)'
    ></ag-grid-angular>

  </mat-dialog-content>
  <mat-dialog-content  class="dialog-footer">
    <button id="addContact" class="cvs-btn-primary" (click)="add()" [disabled]="!data.isEdited">
      <mat-icon svgIcon="folder--s"></mat-icon>
     Add
    </button>
    <button id="saveContact" class="cvs-btn-primary" (click)="save()" [disabled]="savedisabled">
      <mat-icon svgIcon="folder--s"></mat-icon>
     Save
    </button>
    <button id="cancelContact" class="cvs-btn-primary" (click)="cancel()">
      <mat-icon svgIcon="folder--s"></mat-icon>
      Cancel
    </button>
  </mat-dialog-content>
</div>
