import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { AgGridAngular } from 'ag-grid-angular';
import * as ColumnSets from './mappingColumnSets';
import { Store } from '@ngrx/store';
import { FormType } from 'src/app/models/FormType';
import { MappingFormService } from './mapping-form.service';
import { MappingConfirmationComponent } from './mapping-confirmation/mapping-confirmation.component';
import { MappingGuideComponent } from './mapping-guide/mapping-guide.component';
import { MappingApiService } from 'src/app/services/mapping-api.service';
import { AppState, getEprId, getHeaderState, getRole, getUserId } from 'src/app/reducers';
import { BehaviorSubject, forkJoin, Subject, Subscription } from 'rxjs';
import { MappingRecordComponent } from './mapping-record/mapping-record.component';
import { MappingRequirementsComponent } from './mapping-requirements/mapping-requirements.component';
import { Router, Routes } from '@angular/router';
import { InformationDialogComponent } from './common-components/information-dialog/information-dialog.component';
import { MAPPING_STATUS, TYPE_OF_999_TRANSACTION, TYPE_OF_999_TRANSACTION_MAP_OPTION, HD05_SENT_DEPENDENTS } from './mapping-documents.enum';
import { Permission } from "../../models/Authorization";
import { UsersService } from "../../services/users.service";
import { EprService } from 'src/app/services/epr.service';
import {CopayService} from "../../services/copay.service";

@Component({
  selector: 'app-mapping-documents',
  templateUrl: './mapping-documents.component.html',
  styleUrls: ['./mapping-documents.component.scss']
})
export class MappingDocumentsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('grid') grid: AgGridAngular;
  @ViewChild('firstAccordion', { static: true }) firstAccordion: MatAccordion;
  rowSelected = false;
  mappingDocumentData: any = null;
  selectedDocument: any;
  isEditable: any;
  dialogConfig = new MatDialogConfig();

  @ViewChild(MappingGuideComponent) guide: MappingGuideComponent;
  @ViewChild(MappingRecordComponent) mappingRecordComponent: MappingRecordComponent;
  @ViewChild(MappingRequirementsComponent) mappingRequirement: MappingRequirementsComponent;
  toggleForm: any;
  eprId: number;
  pli: string;
  clientCode: string;
  addNewCheck: boolean;
  guidePanel = true;
  warning = {
    type: 'success',
    header: null,
    message: null
  };
  mappingGuideMisssingField = 0;
  mappingRequirementMisssingField = 0;
  emptyRecordTypeLogicCodeCount = 0;
  emptyRecordTypeLogicCodeCountCopy = 0;
  showRecTypeErrors = false;
  disableEdit = false;
  subscription: Subscription[] = [];
  carrierNumber: string;
  clientName: string;
  errorMessageArray: Array<any> = [];
  newDocId: any;
  disableRequirement: any = false;
  showSpinner: Subject<boolean> = new Subject();
  role: any;
  isVisitor : boolean = false;
  isNew:boolean = false;
  maprequirementinstructions:string = "maprequirementinstructions";
  maprequirementinstructionsData:string;
  questionnaireData = new BehaviorSubject(null);
  q3rdIdFieldType:String;
 
  constructor(public api: MappingApiService,
              public mapService: MappingFormService,
              private usersService: UsersService,
              private dialog: MatDialog,
              private store: Store<AppState>,
              private router: Router,
              public eprService: EprService,
              public copayService: CopayService
  ) {


    this.store.select(getEprId).subscribe(id => {
      if (id === undefined || null) {
        this.router.navigateByUrl(''); // navigate to home if no epr found in case of session timeout.
      }
      this.eprId = id;
    });

    this.store.select(getHeaderState).subscribe(headerDetail => {
      // this.pli = 'US';
      // this.clientCode = 'US';
      this.pli = headerDetail.pli;
      this.clientCode = headerDetail.clientCode1;
      this.carrierNumber = headerDetail.carrierNumber;
      this.clientName = headerDetail.clientName;

    });
  }

  ngOnInit() {
    this.usersService.userRole.subscribe(res=>{
      this.role= res;
      if([Permission.VISITOR].includes(this.role) || [Permission.FILETRACKER].includes(this.role)){
        this.isVisitor = true;
      }
    })
    this.api?.getNewRequirementApi(this.eprId).subscribe(resp => {
      if (resp && resp.status === 'SUCCESS') {
      this.questionnaireData=resp;
      this.q3rdIdFieldType=resp?.response?.q3rdIdFieldType;
      } else {
      }
    });
    this.loadUI();
    this.isNew = this.eprService.isNewCopy;
  }

  ngAfterViewInit() {
    this.grid.api.setColumnDefs(ColumnSets.mapping);
    this.grid.suppressClickEdit = true;
  }

  loadUI(loadSpecificDoc?) {
    if (this.subscription.length) {
      this.subscription.forEach(subs => subs.unsubscribe());
    }

    this.api.getMappingDocument({ pli: this.pli, clientCode: this.clientCode, epr: this.eprId }).subscribe(resp => {
      this.mappingDocumentData = resp;
      this.setTableData(resp);
      if (loadSpecificDoc) {
        this.grid.api.forEachNode((node) => {
          if (node.data.documentID === loadSpecificDoc) {
            node.setSelected(true);
            this.onTableRowClicked(node);
          }
        });
      }
    });

    this.toggleForm = this.mapService.generateToggleForm();
    this.subscription.push(
      this.toggleForm.valueChanges.subscribe(action => {
        this.mapService.showValidations(action.enableValidations);
        if (!action.enableValidations) {
          this.showRecTypeErrors = false;
          this.mappingGuideMisssingField = 0;
          this.mappingRequirementMisssingField = 0;
        } else {
          setTimeout(() => {
            const mappingGuideValues = this.guide.form.getRawValue();
            if (mappingGuideValues.mappingStatus && this.guide.is834Format
              && this.toggleForm.get('enableValidations').value && this.isEditable
              // !(mappingGuideValues.mappingStatus === MAPPING_STATUS.REQUESTED
              // || mappingGuideValues.mappingStatus === MAPPING_STATUS.MAPPING)
            ) {
              this.emptyRecordTypeLogicCodeCount = this.emptyRecordTypeLogicCodeCountCopy;
              this.mappingRecordComponent.validationEnable = true;
              this.showRecTypeErrors = true;
            } else {
              this.showRecTypeErrors = false;
              this.emptyRecordTypeLogicCodeCount = 0;
            }
            this.showGuideMissingField();
          }, 500);
        }

      })
    );
    this.subscription.push(
      this.mapService.mappinGuideValueChange$.subscribe(value => {
        const selectedRec = value.recTypes;
        if (((Array.isArray(selectedRec) && selectedRec.length === 0) || !selectedRec)
          && this.toggleForm.value.enableValidations) {
          this.showGuideMissingField();
        }
      })
    );
    this.subscription.push(
      this.mapService.disableMappingEdit$.subscribe(disable => {
        if (disable && !this.isVisitor) {
          this.disableEdit = true;
        }
      })
    );
    this.subscription.push(
      this.mapService.addRequirementValidations$.subscribe(enable => {
        if (!enable) {
          this.mappingRequirementMisssingField = 0;
          setTimeout(() => {
            this.showGuideMissingField()}, 300);

        }
      })
    );
    // this.firstAccordion.openAll();

    this.api.getDataManagementMapInstrTemplate(this.maprequirementinstructions).subscribe(resp => {
      this.maprequirementinstructionsData = resp;
    });
  }

  public updateEmptyRecordTypeLogicCodeCount(val) {
    this.emptyRecordTypeLogicCodeCountCopy = val;
    this.emptyRecordTypeLogicCodeCount = val;
  }

  private setTableData(data) {
    const rowData = data;
    if (this.grid) {
      this.grid.api.setRowData(rowData);
    }

  }

  hasPermission(action:string){
    let mappingPermissions = {ADD: 'ELG_REQUEST_NEW_MAP',
  EDIT:'ELG_EDIT_MAP'}
    return  this.usersService.permissionCodes?.includes(mappingPermissions[action])
  }
  onTableRowClicked(event) {
    if (this.role && (this.role === Permission.FILETRACKER || this.role === Permission.VISITOR || this.role ===
      Permission.SPEDM)) {
      this.disableEdit = true;
    }else{
      this.disableEdit = false;
    }
    this.rowSelected = true;
    this.selectedDocument = event.data;
    this.selectedDocument.eprId = this.eprId;
    this.isEditable = false;
    // this.disableEdit = false;
    this.addNewCheck = false;
    this.updateToggleForm(false);
    this.mapService.mappingDocSelected(this.selectedDocument);
    this.mapService.editableMode(this.isEditable);
  }

  addNewDocument() {
    this.api.addNewMapping().subscribe(resp => {
      this.addNewCheck = true;
      this.isEditable = true;
      this.updateToggleForm(false);
      // if (this.mappingDocumentData.length) {
      //   this.selectedDocument = this.mappingDocumentData[0];
      //   this.selectedDocument.documentID = resp.documentID;
      // } else {
      // }
      this.selectedDocument = { documentID: resp.documentID }; // update new doc id.
      // add epr id  pli cli cleint code in this. As this is hard coded cases.
      this.selectedDocument.eprId = this.eprId;
      this.selectedDocument.pliCode = this.pli;
      this.selectedDocument.clientCode = this.clientCode;
      this.mapService.loadNewMapping(this.selectedDocument);
    });
  }

  setEditable() {
    if (this.isEditable) {
      this.isEditable = false;
    } else {
      this.isEditable = true;
      this.updateToggleForm(false);
    }

    this.mapService.editableMode(this.isEditable);
  }

  setDialogConfig(id) {
    switch (id) {
      case 0:
        this.dialogConfig.data = {
          id: 1,
          title: 'Confirmation',
          type: 'cancel_edit'

        };
        break;
    }
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
  }

  cancelEdit() {
    this.setDialogConfig(0);
    const dialogData = this.dialog.open(MappingConfirmationComponent, this.dialogConfig);
    dialogData.afterClosed().subscribe(input => {
      if (input != null) {
        // i.e exit the edit mode now and reset the screens.
        // in mapping guide section using observable emit.
        this.isEditable = false;
        this.mapService.editableMode(this.isEditable);
        this.updateToggleForm(false);
        if (this.addNewCheck) {
          this.addNewCheck = false;
          this.rowSelected = false; // hide the accords.
          document.getElementById('mappingDocumentTable').scrollIntoView();
        } else {
          this.mapService.mappingDocSelected(this.selectedDocument);
        }
      } else {
        return;
      }
    });
  }

  updateToggleForm(value) {
    this.toggleForm.get('enableValidations').patchValue(value);
    if (!value) {
      this.mappingGuideMisssingField = 0; // to hide the mapping guide count when toggle is disabled.
      this.showRecTypeErrors = false;
      this.mappingRequirementMisssingField = 0;
    }
  }

  save() { // final save .
    this.updateToggleForm(true);

    let mappingGuideValues = this.guide.form.getRawValue();
    function byPassStatus(mappingStatus){
      if(mappingStatus === MAPPING_STATUS.REQUESTED ||
        mappingStatus === MAPPING_STATUS.MAPPING ||
        mappingStatus === MAPPING_STATUS.CANCELLED){
          return true;
        }else{
          return false;
        }
    }
    if (byPassStatus(mappingGuideValues.mappingStatus)) {
      this.showRecTypeErrors = false;
      this.emptyRecordTypeLogicCodeCount = 0;
      this.mappingRecordComponent.validationEnable = false;
    } else {
      this.emptyRecordTypeLogicCodeCount = this.emptyRecordTypeLogicCodeCountCopy;
    }

    if (!byPassStatus(mappingGuideValues.mappingStatus) && (
      this.guide.form.invalid ||
      this.mappingRequirement.form.invalid
      || this.emptyRecordTypeLogicCodeCount > 0)) {
      this.mappingGuideMisssingField = 0;
      setTimeout(() => {
        const invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
        if (invalidFields && invalidFields.length) {
          invalidFields[1].focus();
        } // focuses on first invalid field on screen.
        // this.showGuideMissingField();
      }, 200); // set time out being used for observable to work properly.
    } else {
      const valuesRequirement = this.mappingRequirement.form.getRawValue();
      const additionalData = this.guide.additionalData;
      mappingGuideValues.documentID = this.selectedDocument.documentID;
      mappingGuideValues.pliCode = this.selectedDocument.pliCode;
      mappingGuideValues.clientCode = this.selectedDocument.clientCode;
      mappingGuideValues.eprId = this.selectedDocument.eprId;
      mappingGuideValues.carrierNumber = this.carrierNumber;
      mappingGuideValues.clientName = this.clientName;
      mappingGuideValues = { ...mappingGuideValues, ...additionalData };
      mappingGuideValues.createdTs = this.selectedDocument.createdTs;

      valuesRequirement.documentID = this.selectedDocument.documentID;
      valuesRequirement.pliCode = this.selectedDocument.pliCode;
      valuesRequirement.clientCode = this.selectedDocument.clientCode;
      valuesRequirement.eprId = this.selectedDocument.eprId;

      const valuesFileRequirement = [];
      this.mappingRequirement.grid.api.forEachNode(node => {

        if(node.data?.bgnValue || node.data?.filePliCode || node.data?.fileType || node.data?.outputFile){
          valuesFileRequirement.push(node.data);
        }

      });
      valuesRequirement.mappingFileSentRecords = valuesFileRequirement;
      valuesRequirement.mappingReqInstructions = remove_linebreaks_ss(this.mappingRequirement.mappingComments.join('@').toString())
      this.mappingRequirement.pdfComments = valuesRequirement.mappingReqInstructions
      function remove_linebreaks_ss(str) {
        let newstr = "";
        for (let i = 0; i < str.length; i++)
            if (!(str[i] == "\n" || str[i] == "\r"))
                newstr += str[i];
        return newstr
    }
      // if seq id will bethere it will be there.
      this.mapService.showValidations(true);
      this.mappingRecordComponent.validationEnable = false;
      const apis = [];
      this.showSpinner.next(true);
      if (this.addNewCheck) {
        this.newDocId = this.selectedDocument.documentID;
        this.api.saveMappingGuideApi(mappingGuideValues).subscribe(resp => {
          if (resp.status === 'SUCCESS') {
            apis.push(this.api.saveMappingRequirementApi(valuesRequirement));
            apis.push(this.mappingRecordComponent.saveRecData({
              mappingStatus: mappingGuideValues.mappingStatus,
              mappingFormat: mappingGuideValues.mappingFormat
            }));

            forkJoin(apis).subscribe((forkResp: any) => {
              const [saveMappingReqResponse, saveRecTypeResponse] = forkResp;
              if (saveMappingReqResponse.status === 'SUCCESS' && saveRecTypeResponse.status === 'SUCCESS') {
                this.showWarning({ display: true, case: { type: 'success', message: 'Mapping Document Added Successfully.' } });
                this.showSpinner.next(false);
                this.mappingRecordComponent.unSavedChanges = false;
                this.mappingRecordComponent.validationEnable = false;
                this.mappingRecordComponent.editMode = false;
                this.isEditable = false;
                this.addNewCheck = false;
                this.mapService.editableMode(this.isEditable);
                this.updateToggleForm(false);
                this.mappingFinalStatus();
                if (mappingGuideValues.mappingStatus === MAPPING_STATUS.SUBMITTED &&
                   saveMappingReqResponse.status === 'SUCCESS' &&
                   (mappingGuideValues.mapType !== null && mappingGuideValues.mapType !== "Custom") )
                 {
                  const saveToJSONObj = this.buildSaveToJSONObj(
                    {
                      ...mappingGuideValues, ...valuesRequirement,
                      rectypeDataCache: this.mappingRecordComponent.rectypeDataCache,
                      logicCodeActiveStatus: saveRecTypeResponse.response.logicCodeActiveStatus,
                      classificationCode: saveRecTypeResponse.response.classificationCode
                    }
                  );
                  this.api.saveToJSON(saveToJSONObj).subscribe(resSub => {
                    this.loadUI(this.newDocId);
                    // reload mapping doc table as envelope api updates mapping status
                  }, err => {
                    this.loadUI(this.newDocId);
                  });
                } else {
                  this.loadUI(this.newDocId);   // reload mapping doc table
                }
              } else {
                // handel error case
                if (saveRecTypeResponse.errorMessage) {
                  this.errorMessageArray.push('Mapping Record Section :');
                  this.errorMessageArray.push(...saveRecTypeResponse.errorMessage);
                }
                if (saveMappingReqResponse.errorMessage) {
                  this.errorMessageArray.push('Mapping Requirement Section :');
                  this.errorMessageArray.push(saveMappingReqResponse.errorMessage);
                }
                this.showSpinner.next(false);
                this.showWarning({ display: true, case: { type: 'error' } });
                // }
                this.addNewCheck = false;
                this.loadUI(this.newDocId); // reload mapping doc table
              }
            });
          } else {
            // handel error case
            this.showSpinner.next(false);
            this.errorMessageArray.push(resp.errorMessage);
            this.showWarning({ display: true, case: { type: 'error' } });
          }
        });
        // case for add new doc save.
      } else {
        // case for save or edit existing document.

        this.api.editMappingGuideApi(mappingGuideValues).subscribe(resp => {
          if (resp.status === 'SUCCESS') {
            apis.push(this.api.editMappingRequirementApi(valuesRequirement));

            apis.push(this.mappingRecordComponent.saveRecData({
              mappingStatus: mappingGuideValues.mappingStatus,
              mappingFormat: mappingGuideValues.mappingFormat
            }));
            forkJoin(apis).subscribe((forkResp: any) => {
              const [saveMappingReqResponse, saveRecTypeResponse] = forkResp;
              if (saveMappingReqResponse.status === 'SUCCESS' && saveRecTypeResponse.status === 'SUCCESS') {
                this.showSpinner.next(false);
                this.mappingRecordComponent.unSavedChanges = false;
                this.mappingRecordComponent.validationEnable = false;
                this.mappingRecordComponent.editMode = false;
                this.isEditable = false;
                this.showWarning({ display: true, case: { type: 'success', message: 'Mapping Document Edited Successfully.' } });
                this.mapService.editableMode(this.isEditable);
                this.updateToggleForm(false);
                this.mappingFinalStatus();
                if (mappingGuideValues.mappingStatus === MAPPING_STATUS.SUBMITTED &&
                  saveMappingReqResponse.status === 'SUCCESS' &&
                  (mappingGuideValues.mapType !== null && mappingGuideValues.mapType !== "Custom") )
                {
                  const saveToJSONObj = this.buildSaveToJSONObj(
                    {
                      ...mappingGuideValues, ...valuesRequirement,
                      rectypeDataCache: this.mappingRecordComponent.rectypeDataCache,
                      logicCodeActiveStatus: saveRecTypeResponse.response.logicCodeActiveStatus,
                      classificationCode: saveRecTypeResponse.response.classificationCode
                    }
                  );
                  this.api.saveToJSON(saveToJSONObj).subscribe((resSub) => {
                    this.loadUI(this.selectedDocument.documentID);
                    // as this api updates mapping status.
                  }, err => {
                    this.loadUI(this.selectedDocument.documentID);
                  });
                } else {
                  this.loadUI();
                }
              } else {
                // handel error //
                if (saveRecTypeResponse.errorMessage) {
                  this.errorMessageArray.push('Mapping Record Section :');
                  this.errorMessageArray.push(...saveRecTypeResponse.errorMessage);
                }
                if (saveMappingReqResponse.errorMessage) {
                  this.errorMessageArray.push('Mapping Requirement Section :');
                  this.errorMessageArray.push(saveMappingReqResponse.errorMessage);
                }
                this.showSpinner.next(false);
                this.showWarning({ display: true, case: { type: 'error' } });
                // }
              }
            });
          } else {
            // handel error case
            this.showSpinner.next(false);
            this.errorMessageArray.push(resp.errorMessage);
            this.showWarning({ display: true, case: { type: 'error' } });
          }
        });
      }
    }
  }

  mappingFinalStatus() {
    const formValues = this.guide.form.getRawValue();
    if (formValues.mappingStatus === MAPPING_STATUS.HISTORIC ||
      formValues.mappingStatus === MAPPING_STATUS.CANCELLED) {
      this.mapService.disableMappingEdit(true); // disabled edit if status is canceled or historic already saved.
    }
  }

  showGuideMissingField() {
    if (this.guide && this.guide.form) {
      const form = this.guide.form;
      this.mappingGuideMisssingField = 0;
      Object.keys(this.guide.form.controls).forEach(key => {
        if (form.get(key).hasError('required')) {
          this.mappingGuideMisssingField += 1;
        }
      });
    }
    this.mappingRequirementMisssingField = 0;
    if (this.mappingRequirement && this.mappingRequirement.form && this.mappingRequirement.form.invalid) {

      const formRequirement = this.mappingRequirement.form;
      Object.keys(formRequirement.controls).forEach(key => {
        if (formRequirement.get(key).hasError('required')) {
          this.mappingRequirementMisssingField += 1;
        }
      });
    }
  }

  showWarning(show) {
    if (document.getElementById('warning')) {
      const element = document.getElementById('warning');
      if (show.display) {
        if (show.case.type === 'error') {
          this.warning.type = 'error';
          this.warning.message = this.errorMessageArray.join('\r\n');
        } else {
          this.warning.type = 'success';
          this.warning.message = show.case.message;
        }

        element.style.display = 'block';
        element.focus();
        element.scrollIntoView();
        setTimeout(() => {
          element.style.display = 'none';
          this.errorMessageArray = [];
        }, 4000);
      } else {
        element.style.display = 'none';
        this.errorMessageArray = [];
      }

    }

  }

  addWarning(warnings: Array<any>) {
    this.errorMessageArray.push(...warnings);
    this.showWarning({ display: true, case: { type: 'error' } });
  }

  showSaveErrorModal() {
    this.dialog.open(InformationDialogComponent, {
      height: '200px',
      width: '400px',
      data: {
        body: 'Something went wrong. please try again.',
        buttonText: 'Close'
      }
    });
  }

  buildSaveToJSONObj(valueObj) {
    const updatedObj = new Object();
    const fieldType = {};
    const allRecords = this.mappingRecordComponent.rectypeDataCache;
    const recTypes = Object.keys(allRecords);

    if (recTypes) {
      recTypes.forEach(recName => {
        updatedObj[recName] = new Object(); allRecords[recName].forEach(rowRecord => {
          if (recName) {
            if(rowRecord.sendToSI === 'Yes' && rowRecord.logicCode){
              updatedObj[recName][rowRecord.fieldNumber] = rowRecord.logicCode ? rowRecord.logicCode : null;
            }
            if (rowRecord.comments) {
              updatedObj[recName][rowRecord.fieldNumber + '-comments'] = rowRecord.comments;
            }
            fieldType[rowRecord.fieldNumber] = rowRecord.fieldType;
          }
        });
      });
    }
    const numberOfFilesSent = [];
    let filteredFiles = [];
    if (valueObj.mappingFileSentRecords.length) {
      filteredFiles = valueObj.mappingFileSentRecords.filter(data => data.bgnValue || data.filePliCode || data.fileType || data.outputFile);
      if (filteredFiles.length) {
        filteredFiles.forEach(fileInfo => {
          numberOfFilesSent.push(
            (fileInfo.bgnValue ? fileInfo.bgnValue : '') + ',' +
            (fileInfo.filePliCode ? fileInfo.filePliCode : '') + ',' +
            (fileInfo.outputFile ? fileInfo.outputFile : '') + ',' +
            (fileInfo.fileType ? fileInfo.fileType : '')
          );
        });
      }
    }

    let versionsFields:any  = {};
    versionsFields.iSAVersionNumber = this.mappingRequirement.options.isa12.find(data=>data.id == valueObj.isa12)?.message || null;
    versionsFields.gSVersionNumber = this.mappingRequirement.options.gs08.find(data=>data.id == valueObj.gs08)?.message || null;
    versionsFields.sTVersionNumber = this.mappingRequirement.options.valueSentInST03.find(data=>data.id == valueObj.valueSentInST03)?.message || null;
    const saveToJSONobj = {
      classification: updatedObj,
      multiplePli: valueObj.multiplePli,
      documentId: valueObj.documentID,
      clientCode: valueObj.clientCode,
      pliCode: valueObj.pliCode,
      mappingStatus: valueObj.mappingStatus,
      requestType: 'INSERT',
      iSASenderID: valueObj.isa06,
      iSAReceiverID: valueObj.isa08,
      interchangeSenderIDQualifier: valueObj.isa05,
      interchangeReceiverIDQualifier: valueObj.isa07,
      gSSenderID: valueObj.gs02,
      gSReceiverID: valueObj.gs03,
      sTSenderID: valueObj.gs02,
      sTReceiverID: valueObj.gs03,
      partnerName: valueObj.p5N102,
      thirdIdType: (this.q3rdIdFieldType),
      response999TransactionRequired: valueObj.responseTransaction999,
      typeof999Transaction: this.mapTypeOf999Transaction(valueObj.typeOf999Transaction),
      notExtendedAddressRequiredFlag: this.extendedAddressRequiredFlag(valueObj.extendedAddressRequired,'notExtendedAddressRequiredFlag'),
      alwaysExtendedAddressRequiredFlag: this.extendedAddressRequiredFlag(valueObj.extendedAddressRequired,'alwaysExtendedAddressRequiredFlag'),
      notSentMemberIDCopyDownFlag: this.memberIdSentForDepFlag(valueObj.memberIdForDependents,'notSentMemberIDCopyDownFlag'),
      alwaysMemberIDCopyDownFlag: this.memberIdSentForDepFlag(valueObj.memberIdForDependents,'alwaysMemberIDCopyDownFlag'),
      notSentAddressCopyDownFlag: this.addressSentForDepFlag(valueObj.addressForDependents,'NotSentAddresscopydownflag'),
      alwaysAddressCopyDownFlag: this.addressSentForDepFlag(valueObj.addressForDependents,'AlwaysAddressCopyDownFlag'),
      notXwalkIDDownFlag: this.alternateIDusedValue(valueObj.alternateIdForDependents,'notXwalkIDDownFlag'),
      alwaysXwalkIDDownFlag: this.alternateIDusedValue(valueObj.alternateIdForDependents,'alwaysXwalkIDDownFlag'),
      type5AddressCopyDownFlag: valueObj.type5RecordsSent,
      eprID: valueObj.eprId,
     // sTVersionNumber: '',
      defaultEffectiveDates: valueObj.effectiveDateOverrideInstructions,
      defaultTerminationDates: valueObj.terminationDateOverrideNeededInstructions,
      familyTypeCopyDownFlag: this.mapFamilyTypeCopyDownFlag(valueObj),
      carrier: this.carrierNumber,
      userStory: valueObj.userStory,
      mapType: valueObj.mapType,
      rxcFieldType: fieldType,
      projectName: valueObj.project,
      logicCodeActiveStatus: valueObj.logicCodeActiveStatus,
      golivedate: valueObj.goLiveDate,
      clientName: valueObj.clientName,
      logicCodeClassification: valueObj.classificationCode,
      numberOfFilesSent,
      ...versionsFields
    };
    return saveToJSONobj;
  }

  mapTypeOf999Transaction(typeOf999Transaction) {
    return typeOf999Transaction === TYPE_OF_999_TRANSACTION.ACKNOWLEDGEMENT ? TYPE_OF_999_TRANSACTION_MAP_OPTION.A
      : typeOf999Transaction === TYPE_OF_999_TRANSACTION.ERROR ? TYPE_OF_999_TRANSACTION_MAP_OPTION.B : '';
  }

  extendedAddressRequiredFlag(ID,Type){
    if(Type === 'notExtendedAddressRequiredFlag') {
      if (ID === 'Yes, but Copy-Down needed (Conditional)') {
        return 'Yes'
      } else {
        return ''
      }
    }else if (Type === 'alwaysExtendedAddressRequiredFlag'){
      if (ID === 'Yes, but Copy-Down needed (Always)' || ID === 'No, but Copy-Down needed (Always)') {
        return 'Yes'
      } else {
        return ''
      }
    }
  }

  memberIdSentForDepFlag(ID,Type){
    if(Type === 'notSentMemberIDCopyDownFlag') {
      if (ID === 'Yes, but Copy-Down needed (Conditional)') {
        return 'Yes'
      } else {
        return ''
      }
    }else if (Type === 'alwaysMemberIDCopyDownFlag'){
      if (ID === 'Yes, but Copy-Down needed (Always)' || ID === 'No, but Copy-Down needed (Always)') {
        return 'Yes'
      } else {
        return ''
      }
    }
  }

  alternateIDusedValue(ID,Type){
    if(Type === 'notXwalkIDDownFlag') {
      if (ID === 'Yes, but Copy-Down needed (Conditional)') {
        return 'Yes'
      } else {
        return ''
      }
    }else if (Type === 'alwaysXwalkIDDownFlag'){
      if (ID === 'Yes, but Copy-Down needed (Always)' || ID === 'No, but Copy-Down needed (Always)') {
        return 'Yes'
      } else {
        return ''
      }
    }
  }

  // Added for US412213 - TA439565
  addressSentForDepFlag(ID,Type){
    if(Type === 'NotSentAddresscopydownflag') {
      if (ID === 'Yes, but Copy-Down needed (Conditional)') {
        return 'Yes'
      } else {
        return ''
      }
    }else if (Type === 'AlwaysAddressCopyDownFlag'){
      if (ID === 'Yes, but Copy-Down needed (Always)' || ID === 'No, but Copy-Down needed (Always)') {
        return 'Yes'
      } else {
        return ''
      }
    }
  }

  public get emailBody(): string {
    const subject = `${this.clientName ? this.clientName?.trim() : ''}${this.pli ? ' | ' + this.pli?.trim() : ''}${this.carrierNumber ? ' | ' + this.carrierNumber?.trim() : ''} File Layout/Mapping`;
    return `mailto:?subject=${subject}&cc=EligAdminApprovals`;
  }

  mapFamilyTypeCopyDownFlag(valueObj) {
    return valueObj.valueHD05SentDependents === HD05_SENT_DEPENDENTS.NOCOPYDOWNNEEDED ? 'YES' : 'NO';
  }

  disableRequirementAccord(disable) {
    this.disableRequirement = disable;
  }

  ngOnDestroy() {
    if (this.subscription.length) {
      this.subscription.forEach(subs => subs.unsubscribe());
    }
  }
}
