import {Actions, LOAD_HEADER_FORM_SUCCESS, UPDATE_HEADER_FORM} from '../actions/header.action';
import {convertDateForDatePicker} from '../helpers/DateTimeHelpers';
import {CLEAR_EPR_DATA, COPY_EPR} from '../actions/epr.action';

export interface State {
  clientName: string;
  carrierNumber: string;
  pli: string;
  eligibilityConsultant: number;
  goLiveDate: Date;
  clientCode1: string;
  qlClientCode1: string;
  rdc: string;
  headerId:number;
  backupec: {
    id: number;
  }
  backupecDefault: {
    id: number;
  }
}

export const initialState: State = {
  clientName: '',
  carrierNumber: '',
  pli: '',
  eligibilityConsultant: null,
  goLiveDate: null,
  clientCode1: '',
  qlClientCode1: '',
  headerId:null,
  rdc: '',
  backupec: {
    id: null
  },
  backupecDefault: {
    id: null
  }
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {

    case UPDATE_HEADER_FORM:
      return action.payload;

    case LOAD_HEADER_FORM_SUCCESS:
      return {...action.payload, id: undefined, goLiveDate: convertDateForDatePicker(action.payload.goLiveDate)};

    case CLEAR_EPR_DATA:
      return initialState;

    case COPY_EPR:
      return {
        ...state,
        clientName: state.clientName + ' COPY'
      };

    default:
      return state;
  }
}

export const getClientName = (state: State) => state.clientName;

export const getCarriers = (state: State) => state.carrierNumber;
export const getPli = (state: State) => state.pli;
export const getEC = (state: State) => state.eligibilityConsultant;
export const getBackupec = (state: State) => state.backupec;
export const getBackupecDefault = (state: State) => state.backupecDefault
