import { MapTypeComponent } from './../admin/data-management/map-type/map-type.component';
import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import { ThresholdsDataGridComponent } from '../admin/data-management/thresholds-data-grid/thresholds-data-grid.component';
import { MsgSupressDataGridComponent } from '../admin/data-management/msg-supress-data-grid/msg-supress-data-grid.component';
@Injectable()
export class AdminDeactivateGuard implements CanDeactivate<ThresholdsDataGridComponent | MapTypeComponent | MsgSupressDataGridComponent  > {
 constructor() {}
 canDeactivate(component: ThresholdsDataGridComponent| MapTypeComponent | MsgSupressDataGridComponent ): boolean {
  if (component) {
    const readonly = localStorage.getItem('deactivate-threshold');

    if (component && readonly === '1') {
      if (confirm('Changes have NOT been saved! Click \'OK\' to exit WITHOUT saving.')) {
     return true;
     } else {
      return false;
      }
      }
    return true;
      }

  }

}
