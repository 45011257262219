import * as moment from "moment";

const testArray = ['Ready'];
const mappingStatus = ['Requested', 'Mapping', 'Submitted', 'New Coding', 'Auto Coding', 'QA',
  'UAT', 'Ready to Deploy', 'Completed', 'Cancelled', 'Historic'];

export const mapping = [
  {
    field: 'project', headerName: 'Project', sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    filterParams: { newRowsAction: 'keep' }, editable: false
  },
  {
    field: 'mappingStatus', headerName: 'Mapping Status',
    cellRenderer: params => {
      let baselineDate = null
      let progclr = '';
      let backgroundClr = ''
      let progressIndex = 0;
      let progressBarPercent = 0;
      const currentValue = params.value;
      if (currentValue) {
        mappingStatus.forEach((item, index) => {
          if (currentValue?.toLowerCase().includes(item?.toLowerCase())) {
            progressIndex = index * 10;
          }
        });
        if (progressIndex) {
          progressBarPercent = (progressIndex / 110) * 100;
        }
        if (params.data.baselineDate) {
          baselineDate = new Date(params.data.baselineDate)
          const currentDate = new Date();
          const diffInTime = baselineDate.getTime() - currentDate.getTime();
          const diffInDays = diffInTime / (1000 * 3600 * 24);

          if (params.data.mappingStatus === '11 - Historic' || params.data.mappingStatus === '9 - Completed') {
            progclr = 'black';
            backgroundClr = '#00000040'
          }
          else if (params.data.mappingStatus === '10 - Cancelled') {
            progclr = 'black';
            backgroundClr = '#00000040';
            progressBarPercent = 0;
          }
          else if (diffInDays > 7) {
            progclr = '#61A515';
            backgroundClr = '#61A51540'
          }
          else if ((diffInDays >= 1 && diffInDays < 7) || (diffInDays > 0 && diffInDays < 1)) {
            progclr = '#FFD216';
            backgroundClr = '#FFD21640'
          }
          else if (diffInDays == 0 || diffInDays < 0) {
            progclr = '#D0021B';
            backgroundClr = '#CC000040'
          }
        } else {
          if (params.data.mappingStatus === '11 - Historic' || params.data.mappingStatus === '9 - Completed') {
            progclr = 'black';
            backgroundClr = '#00000040'
          }
          else if (params.data.mappingStatus === '10 - Cancelled') {
            progclr = 'black';
            backgroundClr = '#00000040';
            progressBarPercent = 0;
          } else {
            progclr = '#61A515';
            backgroundClr = '#61A51540'
          }
        }

        const progressBar = '<div>' + currentValue + '</div>' +
          `<div style="border-radius: 6px; width: 100%; margin-top: -6%; background-color:${backgroundClr};">` +
          '<div style="background-color: ' + progclr + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';

        return progressBar;
      } else {
        return params.value;
      }
    },
    sortable: true, filter: true, lockVisible: true,
    lockPosition: true, resizable: true,
    filterParams: { newRowsAction: 'keep' }, editable: false
  },
  {
    field: 'baselineDate',
    cellRenderer: (data) => {
      return data.value ? (moment(data.value).utc().format("MM-DD-YYYY")) : '';
    },
    headerName: 'Baseline Date', sortable: true,
    filter: true, lockVisible: true, lockPosition: true,
    resizable: true, filterParams: { newRowsAction: 'keep' }, editable: false
  },
  {
    field: 'testingDate',
    cellRenderer: (data) => {
      return data.value ? (moment(data.value).utc().format("MM-DD-YYYY")) : '';
    }, headerName: 'Testing Date', sortable: true, filter: true,
    lockVisible: true, lockPosition: true, resizable: true,
    filterParams: { newRowsAction: 'keep' }, editable: false
  },
  {
    field: 'goLiveDate',
    cellRenderer: (data) => {
      return data.value ? (moment(data.value).utc().format("MM-DD-YYYY")) : '';
    }, headerName: 'Go-Live Date', sortable: true, filter: true, lockVisible: true,
    lockPosition: true, resizable: true, filterParams: { newRowsAction: 'keep' }, editable: false
  },
  {
    field: 'mappingVendor', headerName: 'Vendor', sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    filterParams: { newRowsAction: 'keep' }, editable: false
  },
  {
    field: 'mappingType', headerName: 'Map Type', sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    filterParams: { newRowsAction: 'keep' }, editable: false
  },
  {
    field: 'userStory', headerName: 'User Story', sortable: true, filter: true,
    lockVisible: true, lockPosition: true, resizable: true,
    filterParams: { newRowsAction: 'keep' }, editable: false
  },
  {
    field: 'mappingBA', headerName: 'Mapping BA', sortable: true, filter: true,
    lockVisible: true, lockPosition: true, resizable: true,
    filterParams: { newRowsAction: 'keep' }, editable: false
  },
  {
    field: 'mappingCoder', headerName: 'Coder', sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    filterParams: { newRowsAction: 'keep' }, editable: false
  },
  {
    field: 'mappingQA', headerName: 'QA', sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    filterParams: { newRowsAction: 'keep' }, editable: false
  },
  {
    field: 'mappingTester', headerName: 'Tester', sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    filterParams: { newRowsAction: 'keep' }, editable: false
  },
];

export const requirements = [
  {
    field: '', headerName: '',
    valueGetter: 'node.rowIndex + 1'
    , sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    filterParams: { newRowsAction: 'keep' }, editable: false,
    width: 10

  },
  {
    field: 'bgnValue',
    cellRenderer: (data) => {
      return data.value;
    },
    headerName: 'BGN08 value', sortable: true, filter: true, lockVisible: true,
    lockPosition: true, resizable: true, filterParams: { newRowsAction: 'keep' }, editable: true,
    // width: 150
  },
  {
    field: 'filePliCode',
    cellRenderer: (data) => {
      return data.value;
    }, headerName: 'PLI code', sortable: true, filter: true, lockVisible: true,
    lockPosition: true, resizable: true, filterParams: { newRowsAction: 'keep' }, editable: true,
    // width: 150
  }
  ,
  {
    field: 'outputFile',
    cellRenderer: (data) => {
      return data.value;
    }, headerName: 'Output File', sortable: true, filter: true, lockVisible: true,
    lockPosition: true, resizable: true, filterParams: { newRowsAction: 'keep' }, editable: true,
    //  width: 150
  },
  {
    field: 'fileType',
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ['Update', 'Full', 'OE', 'Null'],
    },
    cellRenderer: (data) => {
      return data.value;
    }, headerName: 'File Type', sortable: true, filter: true, lockVisible: true,
    lockPosition: true, resizable: true, filterParams: { newRowsAction: 'keep' }, editable: true,
    //  width: 150
  },
  {
    cellRenderer: 'deleteMappingFileSentAction',
    headerName: 'Thrash Icon',
    editable: false,
    width: 150
  },
];

export const importGuide = [
  {
    field: 'clientName',
    cellRenderer: (data) => {
      return data.value;
    },
    headerName: 'Client Name', sortable: true, filter: true, lockVisible: true,
    lockPosition: true, resizable: true, filterParams: { newRowsAction: 'keep' }, editable: true,
    // width: 150
  },
  {
    field: 'carrierNumber',
    cellRenderer: (data) => {
      return data.value;
    }, headerName: 'Carrier', sortable: true, filter: true, lockVisible: true,
    lockPosition: true, resizable: true, filterParams: { newRowsAction: 'keep' }, editable: true,
    // width: 150
  }
  ,
  {
    field: 'pliCode',
    cellRenderer: (data) => {
      return data.value;
    }, headerName: 'PLI', sortable: true, filter: true, lockVisible: true,
    lockPosition: true, resizable: true, filterParams: { newRowsAction: 'keep' }, editable: true,
    //  width: 150
  },
  {
    field: 'goLiveDate',
    cellRenderer: (data) => {
      return data.value;
    }, headerName: 'Go-Live Date', sortable: true, filter: true, lockVisible: true,
    lockPosition: true, resizable: true, filterParams: { newRowsAction: 'keep' }, editable: true,
    //  width: 150
  },
  {
    field: 'mappingStatus',
    cellRenderer: (data) => {
      return data.value;
    }, headerName: 'Mapping Status', sortable: true, filter: true, lockVisible: true,
    lockPosition: true, resizable: true, filterParams: { newRowsAction: 'keep' }, editable: true,
    //  width: 150
  },
  {
    field: 'userStory',
    cellRenderer: (data) => {
      return data.value;
    }, headerName: 'User Story', sortable: true, filter: true, lockVisible: true,
    lockPosition: true, resizable: true, filterParams: { newRowsAction: 'keep' }, editable: true,
    //  width: 150
  }

];

export let tooltip = {
  address: 'Alight TBA - Check Loop 2100A, NM101 = “74” first' + '\n' +
    'then Loop 2100A, NM101 = “IL”' + '\n' +
    'All others -  Check Loop 2100A, NM101 = “34” first' + '\n' +
    'then Loop 2100A, NM101 = “IL”',
  type5: 'Must be sent at Member level, copy-down does not apply',
  multipleLoop: 'If YES, CVS Health must be aware in order to code to custom' + '\n' +
    'requirements. Additional items for discussion - Effective Date,' + '\n' +
    'Term Date, Family Type, possible C/A/G changes, and proper' + '\n' +
    'order of data. This will have to be noted below.',
  terminationDateCoding: 'If Alight TBA, must follow standard coding for this Vendor' + '\n' +
    'If Alight CBA, must ask if DTP*356 should be check first as part of the' + '\n' +
    'Termination logic',
  medB: 'In order for MED-B to be coded within EDI834,' +
    ' the MBI must be sent on incoming file.  If not sent, then MED-B cannot be coded.' +
    '\n' +
    'Code of “W” must also be coded into MED-D Participation Code' + '\n' +
    'MED-D Participation Event Date must contain the MED-B From Date' + '\n' +
    'MED-D Participation Event Thru Date must contain Member Term Date' + '\n' +
    'If MED-B From Date not sent, default to Member Eff Date',
  rds: 'In order for RDS to be coded within EDI834, the MBI must be sent on incoming file.' +
    ' If not sent, then RDS cannot be coded.'
    + '\n' +
    'Code of “W” must be code into RDS (MED-D) Participation Code' + '\n' +
    'Spaces must be contained in the MED-B Coverage Type Code' + '\n' +
    'Must load the RDS (MED-D) MBI into the MED-B MBI field as well' + '\n' +
    'Must load the RDS (MED-D) Event Date into the MED-B From Date' + '\n' +
    'If RDS Event Date not sent, default to Member Eff Date' + '\n' +
    'Note – If RDS Thru Date not sent, default to OPEN-ENDED Date'

};
