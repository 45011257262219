import {FormFieldConfig, InputType} from '../models/FormFieldConfig';
import {Validators} from '@angular/forms';

export const filetrackerFormConfig = [
  [
    {
      label: 'Setup ID',
      controlName: 'cltSetupID'
    },
    {
      label: 'PLI Code',
      controlName: 'pliCode',
      inputType: InputType.TEXT,
      controller: [Validators.required],
      errorMessage: 'PLI code is required',
      title: 'Preload Identifier code as defined within RxClaim for this file feed. REQUIRED FIELD',
      required: true
    },
    {
      label: 'Primary EC',
      inputType: InputType.HEADER,
      controlName:"primaryUser"
    },
    {
      label: 'Backup EC',
      inputType: InputType.HEADER,
      controlName:"secondaryUser"
    },

    {
      label: 'Client ID',
      controlName: 'clientId',
      inputType: InputType.TEXT,
      controller: [Validators.required],
      errorMessage: 'Client ID is required',
      title: 'Login ID used by client/vendor to access source application (Secure Transport/Sterling Integrator - e.g., ANT591, 4013).' +
        ' REQUIRED FIELD',
      required: true
    },
    {
      label: 'Submitter ID',
      controlName: 'submitterId',
      inputType: InputType.TEXT,
      controller: [Validators.required],
      errorMessage: 'Submitter ID is required',
      title: 'ID used to identify client/vendor or source of data (e.g., USMS). For Secure Transport, Submitter ID may equal Client ID.' +
        ' REQUIRED FIELD',
      required: true,
    },
    {
      label: 'Client Name',
      controlName: 'clientName',
      inputType: InputType.TEXT,
      controller: [Validators.required],
      title: 'Name of Client associated to the data file as defined by Performance Guarantee Contract ID or from Sales Force.' +
        ' REQUIRED FIELD',
      errorMessage: 'clientName  is required',
      required: true
    },
    {
      label: 'File Route',
      controlName: 'fileRoute',
      inputType: InputType.DROPDOWN,
      controller: [Validators.required],
      dropdownTitle: 'The route that the data file is required to follow to reach it\'s end point for processing. REQUIRED FIELD',
      errorMessage: 'FileRoute  is required',
      options: [
        {value: 'STRX', name: 'Client=>ST=>RxClaim'},
        {value: 'STSISTRX', name: 'Client=>ST=>SI=>ST=>RxClaim'},
        {value: 'SIRX', name: 'Client=>SI=>RxClaim'},
        {value: 'SISTRX', name: 'Client=>SI=>ST=>RxClaim'},
        {value: 'RX', name: 'Client=>RxClaim'}
      ],
      required: true
    },
    {
      label: 'File Type',
      controlName: 'fileType',
      inputType: InputType.DROPDOWN,
      editable: true,
      dropdownTitle: 'Type of file being routed for processing (e.g., G - Group, M - Member).',
      options: [ {value: '', name: ''}, {value: 'A', name: 'A - Adjustment'},
        {value: 'B', name: 'B - BCBS RI Med Notes Prior Authorization'},
        {value: 'C', name: 'C - COBC'},
        {value: 'D', name: 'D - Currently Not Used'},
        {value: 'E', name: 'E - ERS Alternate Insurance Update'},
        {value: 'F', name: 'F - Full Adjustment'},
        {value: 'G', name: 'G - Group'},
        {value: 'H', name: 'H - Currently Not Used'},
        {value: 'I', name: 'I - Plan to Plan Inbound'},
        {value: 'J', name: 'J - AID Additional ID File'},
        {value: 'K', name: 'K - PA Preload'},
        {value: 'L', name: 'L - Amerigroup Claims History'},
        {value: 'M', name: 'M - Member'},
        {value: 'N', name: 'N - Currently Not Used'},
        {value: 'O', name: 'O - Currently Not Used'},
        {value: 'P', name: 'P - Physician'},
        {value: 'Q', name: 'Q - HRA Adjustment'},
        {value: 'R', name: 'R - PDE Response File'},
        {value: 'S', name: 'S - PROVIDER'},
        {value: 'T', name: 'T - Retro Term File'},
        {value: 'U', name: 'U - Health Net to CareMark Balance'},
        {value: 'V', name: 'V - Standard PA'},
        {value: 'W', name: 'W - Currently Not Used'},
        {value: 'X', name: 'X - Client History Load from QL'},
        {value: 'Y', name: 'Y - Diagnosis Aux File'},
        {value: 'Z', name: 'Z - Lab Results Aux File'},
        {value: '0', name: '0 - FEP'},
        {value: '1', name: '1 - Prior Auth File'},
        {value: '2', name: '2 - Migration Table'},
        {value: '3', name: '3 - Commercial COB Aux File'},
        {value: '4', name: '4 - Health Actions Aux File'},
        {value: '5', name: '5 - UID Input File from AETNA'},
        {value: '6', name: '6 - AT&T MED D input file'},
        {value: '7', name: '7- STCOB'},
        {value: '8', name: '8- GE Subsidy Load'},
        {value: '9', name: '9- SOF file for incoming Sub/Dep files'}]
    },
    {
      label: 'File Name Pattern',
      controlName: 'fileTypeNamePattern',
      title: 'Unique pattern found within the file name to help distinguish between multiple files sent with same File Type/Client ID',
      inputType: InputType.TEXT
    },
    {
      label: 'PG Contract',
      controlName: 'pgContract',
      inputType: InputType.TEXT,
      title: 'Contract ID defined by CVS Health Performance Guarantee Department for tracking purposes internally.',
    },
    {
      label: 'PG Turn Around Time LVL1',
      controlName: 'pgTurnAroundTimeLvl1',
      inputType: InputType.DROPDOWN,
      controller: [Validators.required],
      errorMessage: 'PG Turn-Around Time LVL1 is required',
      options: [{value: '', name: ''},
        {value: 'PT2H', name: '2 hours'},
        {value: 'PT4H', name: '4 hours'},
        {value: 'PT6H', name: '6 hours'},
        {value: 'PT8H', name: '8 hours'},
        {value: 'PT12H', name: '12 hours'},
        {value: 'PT18H', name: '18 hours'},
        {value: 'PT24H', name: '24 hours'},
        {value: 'PT36H', name: '36 hours'},
        {value: 'PT48H', name: '48 hours'},
        {value: 'PT60H', name: '60 hours'},
        {value: 'PT72H', name: '72 hours'},
        {value: 'PT90H', name: '90 hours'},
        {value: 'PT120H', name: '120 hours'}],
      dropdownTitle: 'Primary time period (e.g.,24 hours,48 hours) agreed upon by client and CVS Health to receive, transfer' +
        ' and load each file into the system. REQUIRED FIELD',
      required: true
    },


    {
      label: 'PG Percentage LVL1',
      controlName: 'pgPercentageLv1',
      inputType: InputType.DROPDOWN,
      controller: [Validators.required],
      errorMessage: 'pgPercentageLv1  is required',
      options: [{value: '', name: ''},
        {value: '90.00', name: '90'},
        {value: '95.00', name: '95'},
        {value: '98.00', name: '98'},
        {value: '98.50', name: '98.5'},
        {value: '99.00', name: '99'},
        {value: '99.50', name: '99.5'},
        {value: '99.90', name: '99.9'},
        {value: '100.00', name: '100'}],
      dropdownTitle: 'Primary percentage of file loads that must meet the LVL1 Turn-Around Time (e.g. 95%). REQUIRED FIELD',
      required: true
    },

    {
      label: 'PG Turn Around Time LVL2',
      controlName: 'pgTurnAroundTimeLvl2',
      inputType: InputType.DROPDOWN,
      options: [{value: '', name: ''},
        {value: 'PT2H', name: '2 hours'},
        {value: 'PT4H', name: '4 hours'},
        {value: 'PT6H', name: '6 hours'},
        {value: 'PT8H', name: '8 hours'},
        {value: 'PT12H', name: '12 hours'},
        {value: 'PT18H', name: '18 hours'},
        {value: 'PT24H', name: '24 hours'},
        {value: 'PT36H', name: '36 hours'},
        {value: 'PT48H', name: '48 hours'},
        {value: 'PT60H', name: '60 hours'},
        {value: 'PT72H', name: '72 hours'},
        {value: 'PT90H', name: '90 hours'},
        {value: 'PT120H', name: '120 hours'}],
      dropdownTitle: 'Secondary time period (e.g.,24 hours,48 hours) agreed upon by client and CVS Health to receive, transfer' +
        ' and load each file into the system. If supplied, value must be equal to or greater than LVL1 Turn-Around Time value.',
    },
    {
      label: 'PG Percentage LVL2',
      controlName: 'pgPercentageLv2',
      inputType: InputType.DROPDOWN,
      options: [{value: '', name: ''},
        {value: '90.00', name: '90'},
        {value: '95.00', name: '95'},
        {value: '98.00', name: '98'},
        {value: '98.50', name: '98.5'},
        {value: '99.00', name: '99'},
        {value: '99.50', name: '99.5'},
        {value: '99.90', name: '99.9'},
        {value: '100.00', name: '100'}],
      dropdownTitle: 'Secondary percentage of file loads that must meet the LVL2 Turn-Around Time (e.g. 100%).' +
        ' Required Field if LVL2 Turn-Around Time populated. If supplied, value must be equal to or greater than LVL1 Percentage value.',
    },
    {
      label: 'PG Load Reporting Turn Around Time',
      controlName: 'pgLoadReportingTurnAroundTime',
      inputType: InputType.DROPDOWN,
      options: [{value: '', name: ''},
        {value: 'PT2H', name: '2 hours'},
        {value: 'PT4H', name: '4 hours'},
        {value: 'PT6H', name: '6 hours'},
        {value: 'PT8H', name: '8 hours'},
        {value: 'PT12H', name: '12 hours'},
        {value: 'PT18H', name: '18 hours'},
        {value: 'PT24H', name: '24 hours'},
        {value: 'PT36H', name: '36 hours'},
        {value: 'PT48H', name: '48 hours'},
        {value: 'PT60H', name: '60 hours'},
        {value: 'PT72H', name: '72 hours'},
        {value: 'PT90H', name: '90 hours'},
        {value: 'PT120H', name: '120 hours'}],
      dropdownTitle: 'Primary time period (e.g. 12 hours, 24 hours) agreed upon by client and CVS Health to receive the load reports' +
        ' electronically following the completion of the most recent file load.',
    },
    {
      label: 'PG Period',
      controlName: 'pgPeriod',
      inputType: InputType.DROPDOWN,
      controller: [Validators.required],
      errorMessage: 'pgPeriod is required',
      options: [{value: '', name: ''},
        {value: '1M', name: '1 Months'}, {value: '3M', name: '3 Months'}
        , {value: '6M', name: '6 Months'}, {value: '12M', name: '1 Year'}],
      dropdownTitle: 'Time Period for which the Performance Guarantee will be calculated against (e.g., 3 Months). REQUIRED FIELD',
      required: true
    },
    {
      label: 'PG File Critical Client In Effect',
      controlName: 'pgFileCriticalClientInEffect',
      inputType: InputType.DROPDOWN,
      controller: [Validators.required],
      errorMessage: 'pgFileCriticalClientInEffect is required',
      options: [{value: 'C', name: 'C'}, {value: 'N', name: 'N'}, {value: 'Y', name: 'Y'}],
      dropdownTitle: 'Indicator that a file load Performance Guarantee is in effect for this client file feed and/or is considered' +
        ' a "Critical Client" and tracked through XMatters for alert notification. Confirm with the Eligibility Consultant' +
        ' if a PG is to be tracked for this file feed in the Performance Guarantee tab of File Tracker. REQUIRED FIELD',
      required: true
    },
    {
      label: 'PG Load Reporting In Effect',
      controlName: 'pgLoadReportingInEffect',
      inputType: InputType.DROPDOWN,
      controller: [Validators.required],
      errorMessage: 'pgLoadReportingInEffect is required',
      options: [{value: 'N', name: 'N'}, {value: 'Y', name: 'Y'}],
      dropdownTitle: 'Indicator that a Load Report Performance Guarantee is in effect for this client file feed. Confirm with the ' +
        'Eligibility' +
        ' Consultant if a PG is to be tracked for this Load Report in the Performance Guarantee tab of File Tracker. REQUIRED FIELD',
      required: true
    },
    {
      label: 'PG Days Allocation',
      controlName: 'pgDaysAllocation',
      inputType: InputType.DROPDOWN,
      controller: [Validators.required],
      errorMessage: 'pgDaysAllocation is required',
      options: [{value: '', name: ''}, {value: 'CD', name: 'Calendar Days'}, {value: 'BD', name: 'B - Business Days'}],
      dropdownTitle: 'Performance Guarantee is calculated based on Business days (M - F excluding weekends / corporate holidays),' +
        ' or calendar days (M - SU including weekends / any holidays). REQUIRED FIELD',
      required: true
    },
    {
      label: 'Successful Receipt Alert',
      alertType: 'Successful Receipt alert',
      controlName: 'successfulReceiptAlert',
      inputType: InputType.DROPDOWN,
      options: [{value: 'N', name: 'N'}, {value: 'E', name: 'E'}, {value: 'M', name: 'M'}, {value: 'B', name: 'B'}],
      dropdownTitle: 'Notify Client / Vendor via (X) or (E) email when file is received at CVS Health for processing. Eligibility' +
        ' Consultant' +
        ' can also request to have notification sent to them via (E) email, (M) text message, or (B) both. ',
      notes: 'successfulReceiptAlert',
      checkBox: false
    },
    {
      label: 'Call Alert Receiver Group',
      controlName: 'callAlertReceiverGroup',
      inputType: InputType.DROPDOWN,
      dropdownTitle: 'Call Alert Receiver Group',
      options: [{value: '', name: ''}, {value: 'Anthem', name: 'Anthem'}]

    },

    {
      label: 'Not Processed Alert',
      alertType: 'Not Processed alert',
      controlName: 'notProcessedAlert',
      inputType: InputType.DROPDOWN,
      options: [{value: 'N', name: 'N'}, {value: 'E', name: 'E'}, {value: 'M', name: 'M'}, {value: 'B', name: 'B'}],
      notes: 'notProcCallIn',
      title: 'Subscribe for Not Processed Call Alert',
      dropdownTitle: 'Notify Eligibility Consultant that a successful file has been received by CVS, but the file has yet to complete' +
        ' processing in RxClaim. Notification can be sent via (E) email, (M) text message, or (B) both.',
      checkBox: true
    },
    {
      label: 'Not Processed 1st Alert %',
      controlName: 'notProcessedfirstAlert',
      inputType: InputType.DROPDOWN,
      dropdownTitle: 'Percentage of Level 1 TAT that will elapse before 1st Alert is triggered on successful files received but not yet' +
        ' processed in RxClaim.',
      options: [{value: '', name: ''},
        {value: '10', name: '10'},
        {value: '20', name: '20'},
        {value: '30', name: '30'},
        {value: '40', name: '40'},
        {value: '50', name: '50'},
        {value: '60', name: '60'},
        {value: '70', name: '70'},
        {value: '80', name: '80'},
        {value: '90', name: '90'},
        {value: '95', name: '95'}],
    },
    {
      label: 'PG Load Reporting Alert',
      alertType: 'PG Load Reporting alert',
      controlName: 'pgLoadReportingAlert',
      inputType: InputType.DROPDOWN,
      options: [{value: 'N', name: 'N'}, {value: 'E', name: 'E'}, {value: 'M', name: 'M'}, {value: 'B', name: 'B'}],
      notes: 'missedPgLoadReportCallIn',
      title: 'Subscribe for Missed PG Load Report Call Alert',
      dropdownTitle: 'Notify Eligibility Consultant that the electronic load reports generated from the most recent file load,' +
        ' have not yet been distributed to its final Secure Transport location. Notification can be sent via (E) email,' +
        ' (M) text message, or (B) both.',
      checkBox: true
    },
    {
      label: 'PG Load Reporting 1st Alert %',
      controlName: 'pgLoadReportingfirstAlert',
      inputType: InputType.DROPDOWN,
      dropdownTitle: 'Percentage of PG Load Report TAT that will elapse before 1st alert is triggered on load reports not successfully' +
        ' distributed to Secure Transport location.',
      options: [{value: '', name: ''},
        {value: '10', name: '10'},
        {value: '20', name: '20'},
        {value: '30', name: '30'},
        {value: '40', name: '40'},
        {value: '50', name: '50'},
        {value: '60', name: '60'},
        {value: '70', name: '70'},
        {value: '80', name: '80'},
        {value: '90', name: '90'},
        {value: '95', name: '95'}],
    },
    {
      label: 'Missing Files Alert',
      alertType: 'Missing Files alert',
      controlName: 'missingFilesAlert',
      inputType: InputType.DROPDOWN,
      dropdownTitle: 'Notify Eligibility Consultant that a client file has yet to be received on the date and time specified on this' +
        ' client\'s ' +
        'file schedule. Notification can be sent via (E) email, (M) text message, or (B) both.',
      options: [{value: 'N', name: 'N'}, {value: 'E', name: 'E'}, {value: 'M', name: 'M'}, {value: 'B', name: 'B'}],
      notes: 'missedFilesCallIn',
    },
    {
      label: 'Processing Error Alert',
      alertType: 'Processing Error alert',
      controlName: 'processingErrorAlert',
      inputType: InputType.DROPDOWN,
      options: [{value: 'N', name: 'N'}, {value: 'E', name: 'E'}, {value: 'M', name: 'M'}, {value: 'B', name: 'B'}],
      notes: 'procErrorCallIn',
      title: 'Subscribe for Processing Error Call Alert',
      dropdownTitle: 'Notify Eligibility Consultant that while processing a file in ST, SI or in RxClaim, an error was encountered.' +
        ' Notification can be sent via (E) email, (M) text message, or (B) both.',
      checkBox: true
    },

    {
      label: 'Multiple Expected Files Alert',
      alertType: 'Multiple expected files alert',
      controlName: 'multipleExpectedFilesAlert',
      inputType: InputType.DROPDOWN,
      dropdownTitle: 'Notify Eligibility Consultant at the conclusion of the defined schedule period (per RxClaim Integrity Profile),' +
        ' whether expected number of file loads have (or have not) been received and processed successfully in RxClaim. Notification' +
        ' can be sent via (E) email, (M) text message, or (B) both.',
      options: [{value: 'N', name: 'N'}, {value: 'E', name: 'E'}, {value: 'M', name: 'M'}, {value: 'B', name: 'B'}],
      notes: 'multipleExpectedFilesCallIn',
    },
    {
      label: 'System',
      controlName: 'system',
      errorMessage: 'System is required',
      inputType: InputType.DROPDOWN,
      controller: [Validators.required],
      dropdownTitle: 'Starting Application from which the file will route from once received from data provider (e.g., ST, SI).' +
        ' REQUIRED FIELD',
      options: [{value: '', name: ''},
        {value: 'SI', name: 'Sterling Integrator'}, {value: 'ST', name: 'Secure Transport'}, {value: 'RX', name: 'Rxclaim'}],
      required: true
    },
    {
      label: 'Active / InActive',
      controlName: 'ActiveorInactive',
      inputType: InputType.DROPDOWN,
      dropdownTitle: 'Marker to indicate if Client entry is active(A) or inactive(I).Data passed through File Tracker will continue' +
        ' to display regardless of this setting.',
      options: [ {value: 'Y', name: 'A'}, {value: 'N', name: 'I'}],
    },
    {
      label: 'Note',
      controlName: 'note',
      title: 'Free form data entry field to capture notes on client entry.',
      inputType: InputType.TEXT_AREA
    },
  ]
] as FormFieldConfig[][];

