import { Injectable } from '@angular/core';
import { CVSHttpClient } from 'angular-component-library';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RteEprHistoryService {

  constructor(private http: CVSHttpClient) { }

  getEprHistory() {
    return this.http.get(`${environment.baseUrl}/eprHistory`);
  }

  getEprHistoryByDates(payload:any) {
    return this.http.post(`${environment.baseUrl}/eprHistory/bydates`, payload);
  }
}

