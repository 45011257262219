<cvs-alert-placeholder id="fieldActions-alert">
</cvs-alert-placeholder>

<div class="outer-grid">
<h2>Actions</h2>
<ag-grid-angular class="ag-theme-alpine" [rowDragManaged]="true"
[rowDragEntireRow]="true"
[rowDragMultiRow]="true"
[rowSelection]="true"
(rowDragEnd)="onRowDragEnd($event)" domLayout='autoHeight' (sortChanged)="sortChange($event)" id="fieldActionsGrid" (keyup)="emitSave()" (cellValueChanged)="UpdatedRow($event)"
                 (filterChanged)="onFilterChange( $event)" [suppressMenuHide]="true"
                 [context]="contextObj" [frameworkComponents]="frameworkComponents" [stopEditingWhenCellsLoseFocus]="true" [stopEditingWhenGridLosesFocus]="true"
                 (gridReady)="onGridReady($event)" [rowData]="dataRows">

</ag-grid-angular>
</div>
