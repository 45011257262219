import {
  Actions,
  ADD_PROJECT_TRACKER,
  CLEAR_EPR_DATA,
  COPY_EPR,
  DELETE_EPR,
  DELETE_PROJECT_TRACKER,
  LOAD_EPR_SUCCESS,
  PREVIOUS_DATA, CHANGED_QUESTIONNARIE_RECORD_SUCCESS,
  RESET_PROJECT_INDICES,
  SELECT_PROJECT,
  SET_FIRST_LOAD,
  START_EDIT_SUCCESS,
  STOP_EDIT,
  PROJECT_TESTING_EDIT
} from '../actions/epr.action';
import {ADD_TEST_ITERATION, DELETE_CUSTOM_TEST_CRITERIA, DELETE_TEST_ITERATION} from '../actions/testing.action';
import {addItemToArrayInArray, removeItemInArray} from '../helpers/NgRxHelpers';
import {DELETE_ADDITIONAL_CONTACT, DELETE_AUDIT_HISTORY, DELETE_PREFIX_ROW, DELETE_PREVIOUS_EC} from '../actions/questionnaire.action';
import {DELETE_CHANGE_LOG} from '../actions/eligibility-agreement.action';

export interface State {
  id: number;
  editorId: number;
  readOnly: boolean;
  firstLoad: boolean;
  selectedProjectIndex: number;
  headerId: number;
  questionnaireMetadata: any;
  eligibilityAgreementMetadata: any;
  /*projectTrackerEntities:{};
  projectTrackerIds: number[];
  checklistIds: number[];
  testingMetadata: any[][]; */
  previousData: any;
  changedQuestionnaireRecord: any;
  questionnaireEntity: {};
  headerEntity: {};
  projectTestingEdit:boolean;
}

export const initialState: State = {
  id: null,
  editorId: null,
  readOnly: true,
  firstLoad: true,
  selectedProjectIndex: 0,
  headerId: null,
  questionnaireEntity: {},
  headerEntity: {},
  questionnaireMetadata: {
    id: null,
    contactInfoIds: [],
    previousEaGroupIds: [],
    auditSectionIds: [],
  },
  eligibilityAgreementMetadata: {
    id: null,
    changeLogIds: []
  },
 /* projectTrackerIds: [],
  projectTrackerEntities:{},
  checklistIds: [],
  testingMetadata: [
    [
      {
        id: null,
        customCriteriaIds: []
      }
    ]
  ], */
  previousData : {},
  changedQuestionnaireRecord : {},
  projectTestingEdit : false
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case START_EDIT_SUCCESS:
      return {...state,
        readOnly: false,
        editorId: action.payload
      };

    case STOP_EDIT:
      return {...state,
        readOnly: true,
        editorId: null
      };
    case PREVIOUS_DATA:
      return {...state,
        previousData: action.payload
      };
    case CHANGED_QUESTIONNARIE_RECORD_SUCCESS:
      return {...state,
        changedQuestionnaireRecord: action.payload
      };
    case SET_FIRST_LOAD:
      return {...state,
        firstLoad: action.payload
      };

    case ADD_PROJECT_TRACKER:
      return {...state,
       /* projectTrackerIds: [null, ...state.projectTrackerIds],
        checklistIds: [null, ...state.checklistIds],
        testingMetadata: [[{
          id: null,
          customCriteriaIds: []
        }], ...state.testingMetadata],
        selectedProjectIndex: 0
        */
      };

    case DELETE_PROJECT_TRACKER:
      return {...state,
       /* selectedProjectIndex: 0,
        projectTrackerIds: removeItemInArray(state.projectTrackerIds, action.iteration),
        checklistIds: removeItemInArray(state.checklistIds, action.iteration),
        testingMetadata: removeItemInArray(state.testingMetadata, action.iteration),
        */
      };
    
    case ADD_TEST_ITERATION:
      return {...state,
        /*
        testingMetadata: addItemToArrayInArray(state.testingMetadata, state.selectedProjectIndex, {
          id: null,
          customCriteriaIds: []
        })
          */
      };

    case DELETE_TEST_ITERATION:
      return {...state, 
        /*testingMetadata: removeItemsInArrayOfArrays(state.testingMetadata, state.selectedProjectIndex, action.iteration)
        */
        };

    case SELECT_PROJECT:
      return {...state,
        selectedProjectIndex: action.payload
      };

    case LOAD_EPR_SUCCESS:
      /*const projectTrackerIds = [];
      const checklistIds = [];
      const testingMetadata = [[]];

      action.payload.projectTrackerEntities.forEach((project, index) => {
        projectTrackerIds.push(project.id);
        checklistIds.push(project.checklistEntity.id);

        testingMetadata[index] = [];

        project.testingEntity.forEach(testIteration => {

          testingMetadata[index].push({
            id: testIteration.id,
            customCriteriaIds: testIteration.customTestCriteria.map(customCriteria => customCriteria.id)
          });
        });
      }); */

      return {...state,
        id: action.payload.id,
        readOnly: true,
        editorId: action.payload.editorId,
        selectedProjectIndex: state.selectedProjectIndex,
        headerId: action.payload.headerEntity?.id,
        headerEntity: action.payload.headerEntity,
        questionnaireMetadata: {

          id: action.payload.questionnaireEntity?.id,
          contactInfoIds: action.payload.questionnaireEntity?.contactInfo.map(contact => contact.id),
          previousEaGroupIds: action.payload.questionnaireEntity?.previousEaGroup.map(prevEa => prevEa.id),
          auditSectionIds: action.payload.questionnaireEntity?.auditSection.map(audit => audit.id)
        },
        questionnaireEntity: action.payload.questionnaireEntity,
        eligibilityAgreementMetadata: {
          id: action.payload.eligibilityAgreementEntity?.id,
          changeLogIds: action.payload.eligibilityAgreementEntity?.changeLog?.map(change => change.id)
        },
        projectTestingEdit: false
        /*projectTrackerIds,
        projectTrackerEntities: action.payload.projectTrackerEntities,
        checklistIds,
        testingMetadata*/
      };

    case CLEAR_EPR_DATA:
      return initialState;

    case RESET_PROJECT_INDICES:
      return {
        ...state,
        selectedProjectIndex: 0
      };

    case DELETE_ADDITIONAL_CONTACT:
      return {
        ...state,
        questionnaireMetadata: {
          ...state.questionnaireMetadata,
          contactInfoIds: removeItemInArray(state.questionnaireMetadata.contactInfoIds, action.payload)
        }
      };
      
      case DELETE_PREVIOUS_EC:
      return {
        ...state,
        questionnaireMetadata: {
          ...state.questionnaireMetadata,
          previousEaGroupIds: removeItemInArray(state.questionnaireMetadata.previousEaGroupIds, action.payload)
        }
      };

    case DELETE_CHANGE_LOG:
      return {
        ...state,
        eligibilityAgreementMetadata: {
          ...state.eligibilityAgreementMetadata,
          changeLogIds: removeItemInArray(state.eligibilityAgreementMetadata.changeLogIds, action.payload)
        }
      };

    case DELETE_CUSTOM_TEST_CRITERIA:
      return {
        ...state,
      /*  testingMetadata: [
          ...state.testingMetadata.slice(0, state.selectedProjectIndex),
          [
            ...state.testingMetadata[state.selectedProjectIndex].slice(0, action.payload.testIteration),
            {
              ...state.testingMetadata[state.selectedProjectIndex][action.payload.testIteration],
              customCriteriaIds: removeItemInArray(state.testingMetadata[state.selectedProjectIndex][action.payload.testIteration]
                .customCriteriaIds, action.payload.index)
            },
            ...state.testingMetadata[state.selectedProjectIndex].slice(action.payload.testIteration + 1),

          ],
          ...state.testingMetadata.slice(state.selectedProjectIndex + 1),
        ] */
      };

    case DELETE_AUDIT_HISTORY:
      return {
        ...state,
        questionnaireMetadata: {
          ...state.questionnaireMetadata,
          auditSectionIds: removeItemInArray(state.questionnaireMetadata.auditSectionIds, action.payload)
        }
      };

    case COPY_EPR:
      return {
        ...initialState,
      };

    case DELETE_EPR:
      return {
        ...state,
        readOnly: true
      };
    case PROJECT_TESTING_EDIT :
      return {
        ...state,
        projectTestingEdit : action.payload
      }
    default:
      return state;
  }
}

export function removeItemsInArrayOfArrays(originalArrayOfArrays, outerIndex, innerIndex): any {
  return [
    ...originalArrayOfArrays.slice(0, outerIndex),
    removeItemInArray(originalArrayOfArrays[outerIndex], innerIndex),
    ...originalArrayOfArrays.slice(outerIndex + 1)
  ];
}

export const getReadOnly = (state: State) => state.readOnly;
export const getEditorId = (state: State) => state.editorId;
export const getFirstLoad = (state: State) => state.firstLoad;
export const getSelectedProjectIndex = (state: State) => state.selectedProjectIndex;
export const getCurrentEditorId = (state: State) => state.editorId;
export const getId = (state: State) => state.id;
export const getPreviousData = (state: State) => state.previousData;
export const getQuestionareID = (state:State)=>state.questionnaireMetadata?.id;
export const getchangedQuestionnaireRecord = (state: State) => state.changedQuestionnaireRecord;
export const getProjectTestingEditState = (state:State)=> state.projectTestingEdit;