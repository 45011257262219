import {MasterDataList} from '../models/MasterDataList';
import {
  Actions,
  LOAD_MASTER_DATA_SET_SUCCESS,
  SAVE_MASTER_DATA_SET_SUCCESS,
  UPDATE_MASTER_DATA_SET
} from '../actions/data-management.action';


export interface State {
  dataList: any;
}

export const initialState: State = {
  dataList: {
    id: null,
    listType: null,
    options: []
  } as any
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case LOAD_MASTER_DATA_SET_SUCCESS:
      return {
        dataList: action.payload
      };

    case UPDATE_MASTER_DATA_SET:
      return {...state,
        dataList: {...state.dataList,
          options: action.payload
        }
      };

    case SAVE_MASTER_DATA_SET_SUCCESS:
      return {
        dataList: action.payload
      };

    default:
      return state;
  }
}

export const getDataListOptions = (state: State) => ((state.dataList && state.dataList.options) ? state.dataList.options : state.dataList);
