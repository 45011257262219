import {Action} from '@ngrx/store';
import {User} from '../models/User';
import {Authorization} from '../models/Authorization';

export const LOAD_USERS = '[User Management] Load Users';
export const LOAD_USERS_SUCCESS = '[User Management] Users Loaded Success';
export const LOAD_USERS_FAILURE = '[User Management] Users Loaded Failure';
export const SAVE_USER = '[User Management] Save User';
export const SAVE_USER_SUCCESS = '[User Management] Save User Success';
export const EDIT_USER = '[User Management] Edit User';
export const EDIT_USER_SUCCESS = '[User Management] Edit User Success';
export const LOAD_ROLES = '[User Management] Load Roles';
export const LOAD_ROLES_SUCCESS = '[User Management] Load Roles Success';
export const FILTER_USERS_BY_ROLE = '[User Management] Filter Users By Role';
export const LOAD_USER_SETTING = '[User Management] Load Users Setting';
export const LOAD_USERS_SETTING_SUCCESS = '[User Management] Users Setting Loaded Success';
export const LOAD_USERS_SETTING_FAILURE = '[User Management] Users Setting Loaded Failure';

export class LoadUsersAction implements Action {
  readonly type = LOAD_USERS;
}


export class LoadUsersSuccessAction implements Action {
  readonly type = LOAD_USERS_SUCCESS;
  
  constructor(public payload: User[]) { }
}

export class LoadUsersFailureAction implements Action {
  readonly type = LOAD_USERS_FAILURE;
}
export class LoadUsersSettingAction implements Action {
  readonly type = LOAD_USER_SETTING;
  constructor(public payload: string) { }
}
export class LoadUsersSettingSuccessAction implements Action {
  readonly type = LOAD_USERS_SETTING_SUCCESS;
  
  constructor(public payload: User[]) { }
}

export class LoadUsersSettingFailureAction implements Action {
  readonly type = LOAD_USERS_SETTING_FAILURE;
}


export class SaveUserAction implements Action {
  readonly type = SAVE_USER;

  constructor(public payload: User) { }
}

export class SaveUserSuccessAction implements Action {
  readonly type = SAVE_USER_SUCCESS;

  constructor(public payload: User) { }
}

export class EditUserAction implements Action {
  readonly type = EDIT_USER;

  constructor(public payload: User) { }
}

export class EditUserSuccessAction implements Action {
  readonly type = EDIT_USER_SUCCESS;

  constructor(public payload: User) { }
}

export class LoadRolesAction implements Action {
  readonly type = LOAD_ROLES;
}

export class LoadRolesSuccessAction implements Action {
  readonly type = LOAD_ROLES_SUCCESS;

  constructor(public payload: Authorization[]) { }
}

export class FilterUsersByRoleAction implements Action {
  readonly type = FILTER_USERS_BY_ROLE;

  constructor(public payload: string[]) {}
}

export type Actions
  = LoadUsersAction
  | LoadUsersSettingAction
  | LoadUsersSuccessAction
  | LoadUsersFailureAction
  | LoadUsersSettingSuccessAction
  | LoadUsersSettingFailureAction
  | SaveUserAction
  | SaveUserSuccessAction
  | EditUserAction
  | EditUserSuccessAction
  | LoadRolesAction
  | LoadRolesSuccessAction
  | FilterUsersByRoleAction;
