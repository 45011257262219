import {Component} from '@angular/core';
import {ICellEditorAngularComp, ICellRendererAngularComp} from 'ag-grid-angular';


@Component({
  selector: 'app-date-cell',
  template: `
    <mat-form-field>
      <input 
        matInput 
        [matDatepicker]="picker" 
        [value]="selectedDate" 
        (dateChange)="onDateChanged($event)" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  `,
})
export class PliCarrierDateCellRendererComponent implements ICellEditorAngularComp {
  private params: any;
   selectedDate = null;

  agInit(params: any): void {
    this.params = params;
    this.selectedDate = params?.value;
  }

  getValue = () => {
    let dateString = null;
    if (this.selectedDate) {
     // dateString = format(this.selectedDate, 'dd/MM/yyyy');
    }
    return this.selectedDate;
  };

  afterGuiAttached = () => {
    // if (!this.params.value) {
    //   return;
    // }
    // const [_, day, month, year] = this.params.value.match(
    //   /^(\d{2})\/(\d{2})\/(\d{4})$/
    // );
    // let selectedDate = new Date(year, month - 1, day);

    // this.selectedDate = selectedDate;
  };

  onDateChanged = (event) => {
    let date = event.value;
   
   date = new Date(date).setUTCHours(0,0,0,0);
   this.selectedDate = new Date(date).toISOString();
  };
}