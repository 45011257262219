export const thresholdColumns = [
  {
    headerName: '',
    field: 'download',
    editable: false,
    lockPosition: true,
    resizable: true,
    sortable: false,
    filter: false,
    type: 'leftAligned',
    width: 30,
    cellRenderer: 'downloadAction',
    cellStyle: {'margin-top': '-5px',    'margin-left': '-15px'}

  },
  {
    headerName: 'Carrier',
    field: 'carrier',
    sortable: true,
    filter: true,
     width: 100,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'Status',
    field: 'status',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    tooltipField: 'softText',
    cellRenderer: params => {
      const currentValue = params.data.body.progressBarDetails;
      let rowSequence = 0;
      let trLength = 0;
      let translationRecords = [];
      let colour = '';
      let statusBorder = '';
      let statusexist = false;
      let status = '';
      translationRecords = params.data.body.translationsRecords;
      if (params.data.body.translationsRecords) {
        translationRecords = translationRecords.sort((a, b) => a.translationId - b.translationId);
        trLength = translationRecords.length;

      }

      if (params.data.status != null && params.data.status !== '' && params.data.body.translationsRecords) {
        rowSequence = translationRecords.findIndex(el => params.data.status === el.input.trim());
        if (rowSequence >= 0) {
          statusexist = true;
        }
        rowSequence = rowSequence + 1;
        const currentTranslation = translationRecords[rowSequence - 1];

        if (currentTranslation && currentTranslation.status === 'Pending') {
          colour = '#AD8E03';
        } else if (currentTranslation && currentTranslation.status === 'Processing') {
          colour = '#006400';
        } else if (currentTranslation && currentTranslation.status === 'Error') {
          colour = '#CC0000';
        }else if (currentTranslation && currentTranslation.status === 'Completed') {
          colour = '#000000';
        }
/*        if (currentValue) {
          currentValue.rowSequence = rowSequence;
          currentValue.totalTranslations = trLength;
          currentValue.outputText = translationRecords[rowSequence - 1] ? translationRecords[rowSequence - 1].output.trim()
            : params.data.status;
          currentValue.softText = translationRecords[rowSequence - 1] ? translationRecords[rowSequence - 1].softText.trim()
            : params.data.status;
          currentValue.statusBorderColour = statusBorder;
          currentValue.statusColour = colour;
        }*/
      }

      if (currentValue) {
        if (params.data) {
          if (params.data.status === '' || params.data.status === null) {
            params.data.status = currentValue.outputText;
            status = params.data.body.tRecord.input;
          } else {
            params.data.status =  translationRecords[rowSequence - 1] ? translationRecords[rowSequence - 1].output.trim()
              : params.data.status;
          }
        }

      } else if (params.data && params.data.status === '') {
        params.data.status = params.data.translationStatus;
      }

      let progressBar = '';
      let progressBarPercent = 0;
      if (statusexist && currentValue && currentValue.outputText) {
        progressBarPercent = (rowSequence / trLength) * 100;
        progressBar =
          '<div style=" border-radius: 6px; width: 100%; margin-top: -1%">' +
          '<div style="background-color: ' + colour + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';
      } else if (status === null || status === '') {
        progressBarPercent = (rowSequence / trLength) * 100;
        progressBar =
          '<div style="border-radius: 6px; width: 100%; margin-top: -1%">' +
          '<div style="background-color: ' + colour + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';
      } else {
        rowSequence = translationRecords.findIndex(el => status === el.input.trim());
        progressBarPercent = (rowSequence / trLength) * 100;
        progressBar =
          '<div style="border-radius: 6px; width: 100%; margin-top: -1%">' +
          '<div style="background-color: ' + currentValue.statusColour + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';
      }
      return params.data.status + progressBar;
    },
    flex: 1,

  },
  {
    headerName: 'Number of Records',
     width: 150,
    field: 'records',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'Threshold Type',
    // width: 130,
    field: 'thresholdType',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'Defined Threshold',
    // width: 140,
    field: 'definedThreshold',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'Calculated Threshold',
    // width: 150,
    field: 'calculatedThreshold',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'Exceeded',
    // width: 100,
    field: 'exceeded',
    sortable: true,
    sort: 'desc',
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    // cellRenderer: params => {
    //   const currVal = params.data;
    //   let returnValue = '';
    //   if (Number(currVal.calculatedThreshold) >= Number(currVal.definedThreshold)) {
    //     returnValue = 'Yes';
    //   } else if (Number(currVal.definedThreshold) >= Number(currVal.exceeded)) {
    //     returnValue = 'Yes';
    //   }
    //   return returnValue;
    // }
    flex: 1,
  }
];

export const auditLogColumns = [
  {
    headerName: 'Date',
    field: 'auditDate',
    sortable: true,
    sort: 'desc',
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    width:140,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Time',
    field: 'auditTime',
    sortable: true,
    sort: 'desc',
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    width:140,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'User',
    field: 'auditUser',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    width:140,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Message Text',
    field: 'auditMessage',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,
    tooltipField: 'auditMessage'

  }
];

export const balanceSheetCols = [
  {
    headerName: '',
    field: 'carrier',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPinned:true,
    pinned:'left',
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'PPL Total Record Read',
    field: 'pplTotalRecordsRead',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'PPL Total Records Rejected',
    field: 'pplTotalRecordsRejected',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'PPL Total Records Written',
    field: 'pplTotalRecordsWritten',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'PL Total Records Read',
    field: 'plTotalRecordsRead',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'PL Total Rejected',
    field: 'plTotalRecordsRejected',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'PL Total Term Count',
    field: 'plTotalTermCount',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'PL Total Role History',
    field: 'plTotalRoleHistory',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'PL Total To Stage',
    field: 'plTotalToStage',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'Stage Record Input',
    field: 'stageRecordInput',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'Stage Record Reject',
    field: 'stageRecordReject',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'Load Record Input',
    field: 'loadRecordInput',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  },
  {
    headerName: 'Load Record Reject',
    field: 'loadRecordReject',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    flex: 1,

  }
];

export const eligibilityCols = [
  {headerName: 'File Current Status',
  field: 'catlgnm',
  sortable: true,
  filter: true,
  lockVisible: true,
  lockPosition: true,
  resizable: true,
  filterParams: {newRowsAction: 'keep'}
},
  {
    headerName: 'Client Id',
    field: 'clientId',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },

  {
    headerName: 'PLI Code',
    field: 'pliCode',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'File Type',
    field: 'fileType',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'File From',
    field: 'fileFrom',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Process',
    field: 'process',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'File From Name',
    field: 'fileFromName',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'File To',
    field: 'fileTo',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'File To Name',
    field: 'fileToName',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Load Member ID',
    field: 'loadMemberId',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Start Time',
    field: 'startTime',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Termination Time',
    field: 'terminationTime',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Successful Alert',
    field: 'successfulAlert',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'File Status',
    field: 'fileStatus',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Error Alert Required',
    field: 'errorAlertRequired',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Miss File Alert',
    field: 'missFileAlert',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Late File Alert',
    field: 'lateFileAlert',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'File Outbound',
    field: 'fileOutbound',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Error Details',
    field: 'errorDetails',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Error Type',
    field: 'errorType',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Error Code',
    field: 'errorCode',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Error Message',
    field: 'errorMessage',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Carriers',
    field: 'carriers',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    cellEditorParams: {
      maxLength: 1000,
      rows: 10,
      cols: 50
    }
  },
  {headerName: 'Step ID',
  field: 'stepId',
  sortable: true,
  filter: true,
  lockVisible: true,
  lockPosition: true,
  resizable: true,
  filterParams: {newRowsAction: 'keep'}
}

];

export const SpeedmCols = [
  {
    headerName: 'Client Receiver',
    field: 'clientId',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Client Name',
    field: 'clientName',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Drug Name',
    field: 'pliCode',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'File Type',
    field: 'fileType',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'File From',
    field: 'fileFrom',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  // {
  //   headerName: 'Process',
  //   field: 'process',
  //   sortable: true,
  //   filter: true,
  //   lockVisible: true,
  //   lockPosition: true,
  //   resizable: true,
  //   filterParams: {newRowsAction: 'keep'},
  // },
  {
    headerName: 'File From Name',
    field: 'fileFromName',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'File To',
    field: 'fileTo',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'File To Name',
    field: 'fileToName',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  // {
  //   headerName: 'Load Member ID',
  //   field: 'loadMemberId',
  //   sortable: true,
  //   filter: true,
  //   lockVisible: true,
  //   lockPosition: true,
  //   resizable: true,
  //   filterParams: {newRowsAction: 'keep'},
  // },
  {
    headerName: 'Start Time',
    field: 'startTime',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Termination Time',
    field: 'terminationTime',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },

  {
    headerName: 'File Status',
    field: 'fileStatus',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Successful Alert',
    field: '',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Error Alert Required',
    field: '',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  // {
  //   headerName: 'File Outbound',
  //   field: 'fileOutbound',
  //   sortable: true,
  //   filter: true,
  //   lockVisible: true,
  //   lockPosition: true,
  //   resizable: true,
  //   filterParams: {newRowsAction: 'keep'},
  // },
  {
    headerName: 'Error Details',
    field: 'errorDetails',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Error Type',
    field: 'errorType',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Error Code',
    field: 'errorCode',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  {
    headerName: 'Error Message',
    field: 'errorMessage',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep'},
  },
  // {
  //   headerName: 'Carriers',
  //   field: 'carriers',
  //   sortable: true,
  //   filter: true,
  //   lockVisible: true,
  //   lockPosition: true,
  //   resizable: true,
  //   filterParams: {newRowsAction: 'keep'},
  //   cellEditor: 'agLargeTextCellEditor',
  //   cellEditorPopup: true,
  //   cellEditorParams: {
  //     maxLength: 1000,
  //     rows: 10,
  //     cols: 50
  //   }
  // },
  {headerName: 'Step ID',
  field: 'stepId',
  sortable: true,
  filter: true,
  lockVisible: true,
  lockPosition: true,
  resizable: true,
  filterParams: {newRowsAction: 'keep'}
},
  {headerName: 'Cataloge Name',
  field: 'catlgnm',
  sortable: true,
  filter: true,
  lockVisible: true,
  lockPosition: true,
  resizable: true,
  filterParams: {newRowsAction: 'keep'}
}
];


export function generatePrograsessBar(currentValue, params) {

 }
