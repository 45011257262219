import {Injectable} from '@angular/core';
import {User} from '../models/User';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {CVSHttpClient} from 'angular-component-library';
import {HttpClient} from '@angular/common/http';
import { catchError,switchMap,tap } from 'rxjs/operators';
import { Permission } from '../models/Authorization';
import * as fromRoot from '../reducers';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  userId = new BehaviorSubject(null);
  userRole = new BehaviorSubject(null);
  userFullName =  new BehaviorSubject(null);
  user =  new BehaviorSubject(null);
  userData:any;
  userSettingData:any = null;
  myPbmRoles= {'ELG_APP_ADMIN': Permission.ADMIN,
  'ELG_LEADERSHIP': Permission.LEADERSHIP,
  'ELG_EC': Permission.EC,
  'ELG_ELIGIBILITY_RELATIONSHIP_MANAGER': Permission.ERM,
  'ELG_MANAGER': Permission.MANAGER,
  'ELG_PROCESSOR': Permission.PROCESSOR,
  'ELG_TESTER_AUDITOR': Permission.TESTER_AUDITOR,
  'ELG_INTEGRATED_FEEDS': Permission.INTFEED,
  'ELG_SI_MAPPER': Permission.SI_MAPPER,
  'ELG_SPEDM_ADMIN': Permission.SPEDM_ADMIN,
  'ELG_VISITOR': Permission.VISITOR,
  'ELG_CLIENT_USER':Permission.CLIENT_USER,
  'ELG_CONSULTANT_USER':Permission.CONSULTANT_USER,
  'ELG_OTHER_FILE_USER':Permission.OTHER_FILE_USER,
  'ELG_FILE_TRACKER_LEGACY_ROLE':Permission.FILETRACKER,
  'ELG_SPEDM_LEGACY':Permission.SPEDM,
  'ELG_BENEFIT_FT_LEGACY':Permission.FT_BENEFIT
  }
  permissionCodes:Array<String> =[]

  constructor(private http: CVSHttpClient , private httpgen: HttpClient,private store: Store<fromRoot.AppState>) {
    this.store.select(fromRoot.getUserSettingData).subscribe(resp=>{
      if(resp?.length){
        this.userSettingData = resp;
      }
     });
     this.store.select(fromRoot.getUsers).subscribe(resp=>{
      if(resp?.length){
        this.userData = resp;
      }
     });
   }

  getRecentlyViewedEprs(userId: number): Observable<any> {
    return this.http.get(`${environment.baseUrl}/user/recentlyViewedEprs/${userId}`);
  }

  getUserEntity(employeeId: any) {
    return this.http.get(`${environment.baseUrl}/getByEmp?empid=${employeeId}`);
  }

  getAll(): Observable<any> {
    if(this.userData){
      return of(this.userData);

    }

    return this.http.get(`${environment.baseUrl}/users`).pipe(switchMap(resp=>of(resp)),
      tap(data => this.userData = data))
  }
  getAllUserSettingInfo(ldapId): Observable<any> {
    // to reload user from API call as updating user from admin MFE will impact user settings
    /*if(this.userSettingData){
      return of(this.userSettingData);

    }*/


    return this.http.get(`${environment.baseUrl}/usersettings/users/${ldapId}`).pipe(
      tap(data => this.userSettingData = data))
  }

  saveUser(user: User): Observable<any> {
    this.userData = null
    return this.http.post(`${environment.baseUrl}/users`, user);
  }

  editUser(user: User): Observable<any> {
    this.userData = null
    return this.http.put(`${environment.baseUrl}/users/update`, user);
  }

  getUsersByRole(roles: any): Observable<any> {
    let myPbmRoleArray = []
     roles.forEach(role => {
      for(let [key, value] of Object.entries(this.myPbmRoles)){
        if(value === role){
          myPbmRoleArray.push(key)
        }
      }
    });
    roles = myPbmRoleArray
    return this.http.get(`${environment.baseUrl}/users/byRole?roles=` + roles.toString().split(','));
  }

  getUserByFirstAndLastName(firstName: string, lastName: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}/get/user/name?firstName=` + firstName + '&lastName=' + lastName);
  }


  getPlisByUserId(userId: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/projects/plis?userId=${userId}`);
    // return of(['8738', '3435']);
  }
  getEprUsers(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/getEprUsers`);
  }


  getUserInfoByUserId(userId: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/user/pli/${userId}`);
  }

  getPlisByECAndERMAndManager(userId: any,backup: boolean): Observable<any> {
    return this.http.get(`${environment.baseUrl}/dashboard/plisincludebackup/${userId}/${backup}`);
  }

  getAuthorizedUser(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/authorizedUser`);
  }

  getAllRecentlyViewedEprs(userId: number): Observable<any> {
    return this.http.get(`${environment.baseUrl}/getAllRecentlyViewedEprs?userId=${userId}`);
  }

  saveRecentlyProcessingFilesViewedEprs(payload: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/saveProcessingFilesRecentlyViewedEprs`, payload);
  }

  getPreferences(userId:number):Observable<any>{
    return this.http.get(`${environment.baseUrl}/dashboard/getuserpreferences?userId=${userId}`);
  }

  savePreference(payload:any):Observable<any>{
    return this.http.post(`${environment.baseUrl}/dashboard/saveuserpreferences`, payload);
  }
}
