import { Component, OnInit } from '@angular/core';
import {
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';


@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})

export class EditComponent implements ICellEditorAngularComp {
  public params: any;
  public value: any;
  public prf: any;
  public selectedValue = '';

  values = ['Yes', 'No'];

  @ViewChild('input', {
    read: ViewContainerRef
}) public input;

  constructor() {
  }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
  }

  getValue(): any {
    return this.value;
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

}
