import {Permission} from './Authorization';


export enum InputType {
    TEXT,
    TEXT_AREA,
    HEADER,
    DROPDOWN,
    MULTI_SELECT,
    DATE,
    HORIZONTAL_FIELD_SET,
    MDL_DROPDOWN,
    USERS_DROPDOWN,
    NUMBER,
    CHIP,
    FREEFORM_MULTISELECT,
    SUB_HEADER,
    TIME,
    sinkInput1,
    sinkInput2,
    sinkInput3,
    sinkInput4,
    sinkInput5,
    sinkInput6,
    DRM_DROPDOWN = 20
}

export interface FormFieldConfig {
  label: string;
  controlName?: string;
  inputType: InputType;
  permissions?: Permission[];
  options?: string[]; // first index is default option
  heading?: boolean;
  mdlName?: any;
  subFormGroup?: FormFieldConfig[];
  errorMessage?: string;
  colorCode?: string;
  hint?: string;
  notes?: string;
  title?: any;
  dropdownTitle?: any;
}

export interface Header{
  label: string;
  inputType: InputType;
  notes?: string;
  heading?: boolean;
}

export interface RootFormFieldConfig {
  header: Header;
  content: FormFieldConfig[][];
}

