import {Action} from '@ngrx/store';
import {WebEprFeed} from '../models/WebEprFeed';
import {Authorization} from '../models/Authorization';

export const LOAD_WEBEPRFEED = '[WebEprFeed] Load WebEprFeed';
export const LOAD_WEBEPRFEED_SUCCESS = '[WebEprFeed] WebEprFeed Loaded Success';
export const LOAD_WEBEPRFEED_FAILURE = '[WebEprFeed] WebEprFeed Loaded Failure';
export const SAVE_WEBEPRFEED = '[WebEprFeed] Save WebEprFeed';
export const SAVE_WEBEPRFEED_SUCCESS = '[WebEprFeed] Save WebEprFeed Success';
export const SAVE_WEBEPRFEED_FAILURE = '[WebEprFeed] Save WebEprFeed Failure';
export const EDIT_WEBEPRFEED = '[WebEprFeed] Edit WebEprFeed';
export const EDIT_WEBEPRFEED_SUCCESS = '[WebEprFeed] Edit WebEprFeed Success';
export const EDIT_WEBEPRFEED_FAILURE = '[WebEprFeed] Edit WebEprFeed Failure';

export class LoadWebEprFeedAction implements Action {
  readonly type = LOAD_WEBEPRFEED;
}

export class LoadWebEprFeedSuccessAction implements Action {
  readonly type = LOAD_WEBEPRFEED_SUCCESS;

  constructor(public payload: WebEprFeed[]) { }
}

export class LoadWebEprFeedFailureAction implements Action {
  readonly type = LOAD_WEBEPRFEED_FAILURE;
}

export class SaveWebEprFeedAction implements Action {
  readonly type = SAVE_WEBEPRFEED;

  constructor(public payload: WebEprFeed[]) { }
}

export class SaveWebeprfeeduccessAction implements Action {
  readonly type = SAVE_WEBEPRFEED_SUCCESS;

  constructor(public payload: WebEprFeed[]) { }
}
export class SaveWebeprfeedfailureAction implements Action {
  readonly type = SAVE_WEBEPRFEED_FAILURE;

  constructor(public payload: WebEprFeed) { }
}
export class EditWebEprFeedAction implements Action {
  readonly type = EDIT_WEBEPRFEED;

  constructor(public payload: WebEprFeed) { }
}

export class EditWebEprFeeduccessAction implements Action {
  readonly type = EDIT_WEBEPRFEED_SUCCESS;

  constructor(public payload: WebEprFeed) { }
}

export class EditWebEprFeedfailureAction implements Action {
  readonly type = EDIT_WEBEPRFEED_FAILURE;

  constructor(public payload: WebEprFeed) { }
}


export type Actions
  = LoadWebEprFeedAction
  | LoadWebEprFeedSuccessAction
  | LoadWebEprFeedFailureAction
  | SaveWebEprFeedAction
  | SaveWebeprfeeduccessAction
  | SaveWebeprfeedfailureAction
  | EditWebEprFeedAction
  | EditWebEprFeeduccessAction
  | EditWebEprFeedfailureAction;
