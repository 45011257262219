import {Component, Input, OnDestroy, OnInit, ViewChild, NgZone, Output, EventEmitter} from '@angular/core';
import * as fromRoot from '../../reducers';
import {
  AppState,
  getchangedQuestionnaireRecord,
  getCurrentEditorName,
  getEditorId,
  getEprId,
  getEPRState,
  getIsRegisteredUser,
  getProjectTestingEditState,
  getProjectTrackerDisplayNames,
  getRdcs,
  getReadOnly,
  getRole,
  getSelectedProjectIndex,
  getSelectedTestIteration,
  getUserId
} from '../../reducers';
import {Store} from '@ngrx/store';
import {
  CopyEprAction,
  DeleteEprAction,
  SaveChangedQuestionnaireRecordAction,
  SaveEprAction,
  StartEditAction,
  StopEditAction
} from '../../actions/epr.action';
import {Permission} from '../../models/Authorization';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {FormType} from '../../models/FormType';
import {AbstractControl, FormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {take, tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {
  ConfirmationDialogData
} from '../questionnaire/confirmation-dialog-content/confirmation-dialog-content.component';
import {AlertType} from '../../models/AlertType';
import {ConfirmationDialogComponent} from '../confirmation-dialogue/confirmation-dialog.component';
import {CVSAlertService, CVSAlertType} from 'angular-component-library';
import {Router} from '@angular/router';
import {EprService} from '../../services/epr.service';
import {EprFeedService} from '../../services/eprfeed.service';
import {EprFeed} from '../../models/EprFeed';
import {UsersService} from "../../services/users.service";
import {
  baselineTasksConfig,
  discoveryTasksConfig,
  goLiveConfig,
  idCardsConfig,
  integratedFeedsTasksConfig,
  setupTasksConfig,
  testTasksConfig
} from "../checklist/ChecklistTasksConfig";
import * as qconfig from "../questionnaire/QuestionnaireConfig";
import {InputType} from "../../models/FormFieldConfig";
import * as moment from 'moment';
import {OutOfOfficeIconRendererComponent} from "../../common/OutOfOfficeIconRenderer";
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { EPRObject } from 'src/app/models/PermissionsObject';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild("autosize") autosize:CdkTextareaAutosize;
  @Output() saveTestResults= new EventEmitter();

  @Input() checklist;
  @ViewChild('projectTrackerForm') projectTrackerForm;
  checklistSections = [
    {
      sectionConfig: discoveryTasksConfig,
      header: 'Discovery',
      abbreviation: 'newDisc',
      noteControlName: 'discoveryNotes',
      showToggles: false
    },
    {
      sectionConfig: setupTasksConfig,
      header: 'Setup',
      abbreviation: 'newSetup',
      noteControlName: 'setupPhaseNotes',
      showToggles: false
    },
    {
      sectionConfig: testTasksConfig,
      header: 'Testing',
      abbreviation: 'newTst',
      noteControlName: 'testNote',
      showToggles: false,
      hasExpectedDate: true
    },
    {
      sectionConfig: integratedFeedsTasksConfig,
      header: 'Integrated Feeds',
      abbreviation: 'newIntFds',
      noteControlName: 'integFeedMigNotes',
      showToggles: false,
      hasNoDates: true
    },
    {
      sectionConfig: baselineTasksConfig,
      header: 'Baseline',
      abbreviation: 'newBL',
      noteControlName: 'baselineNote',
      showToggles: false,
      hasExpectedDate: true
    },
    {
      sectionConfig: idCardsConfig,
      header: 'ID CARDS',
      abbreviation: 'newIDCrds',
      noteControlName: 'iDCardTaskNotes',
      showToggles: false
    },
    {
      sectionConfig: goLiveConfig,
      header: 'GO-LIVE',
      abbreviation: 'goLive',
      noteControlName: 'goLiveNote',
      showToggles: false,
      hasGoLiveDate: true
    }
  ];


  formType = FormType.HEADER;
  Permission = Permission;
  subSection: String;
  saveSubscription: Subscription;
  isRegisteredUser$= this.usersService.userRole.subscribe(res => this.isRegisteredUser$ =res)
  restrictedAccess$ = this.store.select(getRdcs) as Observable<string[]>;
  readOnly$ = this.store.select(getReadOnly) as Observable<boolean>;
  userId$ = this.store.select(getUserId) as Observable<number>;
  eprId$ = this.store.select(getEprId) as Observable<number>;
  editorId$ = this.store.select(getEditorId) as Observable<number>;
  currentEditorName$ = this.store.select(getCurrentEditorName) as Observable<string>;
  backupEc$ = this.store.select(fromRoot.getBackupEc) as Observable<any>;
  getBackupecDefault$ = this.store.select(fromRoot.getBackupDefaultEc) as Observable<any>;
  headerForm: UntypedFormGroup;
  userId: number;

  editorId: any;
  eprId: number;
  oooFromDate;
  oooToDate;
  oooFromTime: any;
  oooToTime: any;
  oooFromDateBec: string;
  oooToDateBec: string;
  oooFromTimeBec: any;
  oooToTimeBec: any;

  formDriverSubscribe: Subscription;
  getUserIdSubscription: Subscription;
  getEprIdSubscription: Subscription;
  isAdmin = false;
  holddatereadonly= true;

  consildatedFlag = false;
  // @Output() pliChangedValue = new EventEmitter();
  // tslint:disable-next-line:max-line-length
  username: string;
  entireresp: any;
  private questSubSection: any;
  usersList: any = [];
  private intialEprId: any;

  diffCount = false;
  addEprcount = true;
  assingedEc: string;
  eprHistoryObj: string;
  saveHistoryObj: string;

  role: any;
  isOnhold: boolean = true;

  @ViewChild("tooltip") tooltip: OutOfOfficeIconRendererComponent;
  adminBackupUser: any;
  isUserInactive: any;
  isUserInactiveBeC: any;

  editAdminAccess = true;
  showEditButton = true;
  lockButton = false;
  isPliValid = true;
  usersPliList: any[];
  isEprLoaded: boolean;
  currentEprPli: any;

  permissions = this.usersService.permissionCodes
  eprObject:any
  isEditQuestionnaire: boolean;
  isEditProfiles: boolean;
  restritedcAccessArray:any;
  isProjectTestingEditable: boolean;
  subs:Subscription[] = [];

  constructor(private store: Store<fromRoot.AppState>, private fb: FormBuilder, private dialog: MatDialog, private alertService: CVSAlertService,
    private router: Router, private store$: Store<AppState>, private eprService: EprService,
    private eprFeedService: EprFeedService,
    private usersService: UsersService) {
  }

  ngOnInit() {
    this.eprObject = this.createEprPermissionObject()
    let backupecId;
    let getBackupecDefaultId;
    this.subs.push(
    this.editorId$.subscribe(editorId => {
      this.editorId = editorId;
    }),
    this.backupEc$.subscribe(res => {
      backupecId = res?.id;
    }),
    this.getBackupecDefault$.subscribe(res => {
      getBackupecDefaultId = res?.id;
    }),
    this.usersService.userRole.subscribe(role => {
      if (role) {
        this.role = role;
      }
    }),
    this.store.select(getProjectTestingEditState).subscribe(edit=>{
      this.isProjectTestingEditable = edit;
    }),
    this.eprService.getMyUser().subscribe(res=>{
      this.restritedcAccessArray = res.rdcs
    })
      )
    this.setupForm();
    this.formDriverSubscribe = combineLatest(this.readOnly$, this.restrictedAccess$,).pipe(
      tap(([readOnly, restrictedAccess]) => {
        this.holddatereadonly = readOnly;
        if (this.headerForm) {
          this.eprService.pliValue.next(this.headerForm.controls['pli'].value);
        }

        this.eprService.consolidateFlag.subscribe(value => {
          this.consildatedFlag = value === 'Yes';
          if(this.consildatedFlag){
            if(!this.headerForm?.controls['holdDate']?.value){
              this.headerForm.controls['holdDate'].patchValue( new Date('01/01/1995'));
            }
            this.headerForm?.controls['holdDate']?.valueChanges.subscribe(val => {
              this.checkHoldDate();
            });
            this.checkHoldDate();
          }
        });
        if (readOnly) {
          this.headerForm.disable();
          if (backupecId && (backupecId !== getBackupecDefaultId)) {
            this.headerForm.patchValue({
              obec: true,
            })
          }
        } else if (!readOnly && (this.role === Permission.FILETRACKER || this.role === Permission.VISITOR)) {
          this.headerForm.disable();

        }
        else {
          this.headerForm.enable();
          if (backupecId && (backupecId !== getBackupecDefaultId)) {
            this.headerForm.patchValue({
              obec: true,
            })
            this.headerForm.get('backupec').get('id').enable();
          } else {
            this.headerForm.patchValue({
              obec: false,
            })
            this.headerForm.get('backupec').get('id').disable();
          }
          if (this.restritedcAccessArray && this.restritedcAccessArray.length === 0) {
            this.headerForm.controls['rdc'].disable({ emitEvent: true });
          }
          this.eprService.consolidateFlag.subscribe(value => {
            this.consildatedFlag = value === 'Yes';
            if (this.headerForm && this.headerForm.enabled && this.consildatedFlag) {
              this.headerForm.controls['pli'].disable({ emitEvent: false });
              this.alertService.sendAlert({
                headline: 'SSOE Migration Enabled',
                alertType: CVSAlertType.Success,
                alertDocumentHolderName: '#consolidate-profile',
              });

              setTimeout(()=>{
                this.alertService.clearAlert('#consolidate-profile');


              },2000)
            } else if (this.headerForm && this.headerForm.enabled && !this.consildatedFlag) {
              this.headerForm.controls['pli'].enable({ emitEvent: false });

            }
          });

        }
      })).subscribe();



    // this.getUserIdSubscription = this.store.select(getUserId).subscribe(id => {
    //   this.userId = id;
    // });
    this.usersService.userId.subscribe(userid => {
      this.userId = userid;
    })
    this.usersService.userFullName.subscribe(name => {
      this.username = name;
      this.eprService.username = name;
    })

    this.getEprIdSubscription = this.store.select(getEprId).subscribe(id => {
      this.eprId = id;
    });
    // this.store.select(getRole).subscribe(role => {
    this.usersService.userRole.subscribe(role => {

        if ([Permission.ADMIN, Permission.MANAGER,
          Permission.EC, Permission.PROCESSOR,
          Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
          Permission.INTFEED, Permission.SI_MAPPER].includes(role) && this.eprObject.buttons.EDIT_EPR) {
            this.editAdminAccess = true;
          } else {
            this.editAdminAccess = false;
          }
      

    });
    this.usersService.getUsersByRole([Permission.EC, Permission.ADMIN, Permission.ERM, Permission.MANAGER,Permission.LEADERSHIP,Permission.SI_MAPPER])
      .subscribe(res => {
      this.usersList = res;
      this.eprService.usersList = res;
      if (!this.headerForm.get('backupec').get('id').value) {
        this.headerForm.get('backupec').get('id').setValue(this.headerForm.get('backupecDefault').get('id').value)
      }
    })

    this.eprService.initialEprData.subscribe(resp => {
      if (resp && this.addEprcount) {
        this.addEprcount = false;
        this.saveHistory(resp, 'Add')
        this.eprService.initialEprData.unsubscribe;
      }
    })

    if(this.editorId){
      if(this.userId !== this.editorId){
        this.lockButton = true;
        if(this.editAdminAccess){
          this.showEditButton = true;
        }else{
          this.showEditButton = false;
        }

        if(this.eprObject.pbmRole ){
          if(this.editAdminAccess){
                    this.showEditButton = true;
                  }
        }
      } else{
        this.showEditButton= true;
      }
    }

    this.eprService.usersPliList.subscribe(res =>{
      if(res){
        this.usersPliList= res;
      }
    })
    this.resize()

  }

  onEcChange(val) {
    if (val) {
      const selectedEC = this.usersList.find(user => user.id === val);
      if (selectedEC) {
        this.adminBackupUser = selectedEC.backUpUser;
        this.headerForm.get('backupec').patchValue({
          id: selectedEC.backUpUser?.id
        })
        this.headerForm.get('backupecDefault').patchValue({
          id: selectedEC.backUpUser?.id
        })
      }
    }
  }

  toggleBEC(e) {
    const control = this.headerForm.get('backupec').get('id');
    const ecControl = this.headerForm.get('eligibilityConsultant')
    if (e.checked) {
      control.enable()
    } else {
      control.disable()
      this.headerForm.get('backupec').get('id').setValue(null)
      this.onEcChange(ecControl.value);
    }
  }


  ngOnDestroy(): void {
    // this.getUserIdSubscription.unsubscribe();
    this.formDriverSubscribe.unsubscribe();
    this.getEprIdSubscription.unsubscribe();
    this.saveSubscription?.unsubscribe();
    this.subs.forEach(sub=>sub.unsubscribe());
  }

  startEdit = () => {
    if(this.lockButton && (this.isAdmin||this.editAdminAccess)){
      this.lockButton=false;
    }

      this.store.dispatch(new StartEditAction(this.userId));
  }

  get oooExists() {
    let result = false;
    const selectedUserID = this.headerForm.get('backupec').get('id').value;
    if (selectedUserID) {
      this.usersList.forEach(user => {
        if (user.id === selectedUserID) {
          if (user.active) {
            if (user.outofofficeFromDate && user.outofofficeToDate && !this.hasOOOExpired(user.outofofficeToDate, user.outofofficeToTime, user.outofofficeFromDate, user.outofofficeFromTime)) {
              this.oooFromDateBec = moment(user.outofofficeFromDate).format("MMMM Do YYYY");
              this.oooToDateBec = moment(user.outofofficeToDate).format("MMMM Do YYYY");
              this.oooFromTimeBec = user.outofofficeFromTime;
              this.oooToTimeBec = user.outofofficeToTime;
              this.isUserInactiveBeC = user.active;
              result = true;
            }
          }

          if (user.active === false) {
            this.isUserInactiveBeC = false;
            result = true;
          }
        }
      })
    }
    return result;
  }

  get oooExistsEC() {
    let result = false;
    const selectedUserID = this.headerForm.get('eligibilityConsultant').value
    if (selectedUserID) {
      this.usersList.forEach(user => {
        if (user.id === selectedUserID) {
          if (user.active) {
            if (user.outofofficeFromDate && user.outofofficeToDate && !this.hasOOOExpired(user.outofofficeToDate, user.outofofficeToTime, user.outofofficeFromDate, user.outofofficeFromTime)) {
              this.oooFromDate = moment(user.outofofficeFromDate).format("MMMM Do YYYY");
              this.oooToDate = moment(user.outofofficeToDate).format("MMMM Do YYYY");
              this.oooFromTime = user.outofofficeFromTime;
              this.oooToTime = user.outofofficeToTime;
              this.isUserInactive = user.active;
              result = true;
            }
          }

          if (user.active === false) {
            this.isUserInactive = false;
            result = true;
          }

        }
      })
    }
    return result;
  }

  hasOOOExpired(endDate, endTime, startDate, startTime) {
    let endDateTime = new Date(endDate + ' ' + endTime)
    let startDateTime = new Date(startDate + ' ' + startTime)
    let currentDateTime = moment();
    const isBetween = currentDateTime.isBetween(startDateTime, endDateTime)

    return !isBetween
  }

  removeTimeStamp(tempHistoryArray) {
    let val = this.oooExists;
    tempHistoryArray.map(t => {
      delete t.timeStamp;
      return t;
    });
  }
  replaceTimestamp(tempHistoryArray) {
    tempHistoryArray.map(obj => {
      if (obj.newValue) {
        obj.newValue= this.transform(obj.newValue, 450)
      }
      if (obj.previousValue) {
        obj.previousValue= this.transform(obj.previousValue, 450)
      }
      if (obj?.previousValue && obj.previousValue?.includes && obj.previousValue?.includes('T00:00:00')) {
        obj.previousValue = obj.previousValue.slice(0, 10);
      }
      if (obj?.newValue && obj.newValue?.includes && obj.newValue?.includes('T00:00:00')) {
        obj.newValue = obj.newValue.slice(0, 10);
      }

      return obj;
    })
  }
  transform(value: string, maxLength: number){
    if(value && value.length <= maxLength){
      return value;
    } else{
      if(typeof value === 'string'){
        const retValue = value.substring(0,maxLength);
        return retValue
      }else{
        return null
      }

    }
  }
  saveHistory(eprData, epraction) {
    this.addEprcount = false;
    let historyDate = new Date();
    let commonFieldsHistoryobj: any = {};
    this.assingedEc = this.eprService.getAssignedEc(eprData.headerEntity.eligibilityConsultant);
    const eprid = eprData.id;

    if (epraction === 'Add' || epraction === 'Delete') {

      commonFieldsHistoryobj = this.eprService.getCommonFiledsHistoryObj(eprData.id, eprData.headerEntity, historyDate, epraction);
      this.saveEprHistory(commonFieldsHistoryobj)
    }

    if (epraction === 'Update') {

      let selectedProjectIndex: any;
      let currentTestIterIndex: any;
      let projectTrackerOptions: any;
      // let previousProject='';
      // let currentProject = '';
      let previousTestResults: any = '';
      let currentTestResults = '';

      let selectedProjectIndex$ = this.store.select(getSelectedProjectIndex) as Observable<number>;
      let projectTrackerOptions$ = this.store.select(getProjectTrackerDisplayNames) as Observable<string[]>;
      projectTrackerOptions$.subscribe(res => {
        projectTrackerOptions = res;
      })
      selectedProjectIndex$.subscribe(index => {
        selectedProjectIndex = index;
      })
      let currentTestIterIndex$ = this.store.select(getSelectedTestIteration);
      currentTestIterIndex$.subscribe(index => {
        currentTestIterIndex = index;
      });

      let project = projectTrackerOptions ? projectTrackerOptions[selectedProjectIndex] : '';
      let currentHeaderData: any = eprData.headerEntity;
      let previousHeaderData: any = eprData.previousData.headerEntity;
      const prevQuestionnaire = eprData.previousData.questionnaireEntity;
      const currentQuestionnaire = eprData.questionnaireEntity;

      // if(eprData.previousData && eprData.previousData.projectTrackerEntities && eprData.previousData.projectTrackerEntities.length > 0){
      //   // previousProject =eprData.previousData.projectTrackerEntities[selectedProjectIndex]
      //   // currentProject = eprData.projectTrackerEntities[selectedProjectIndex]
      //   previousTestResults = eprData.previousData.projectTrackerEntities[selectedProjectIndex].testingEntity[currentTestIterIndex]
      //   currentTestResults = eprData.projectTrackerEntities[selectedProjectIndex].testingEntity[currentTestIterIndex]
      // }

      let eprHistoryArray: any = [];
      if (currentHeaderData) {
        commonFieldsHistoryobj = this.eprService.getCommonFiledsHistoryObj(eprid, currentHeaderData, historyDate, epraction);
      }

      for (const k in previousHeaderData) {
        if (k === 'backupecDefault' || k === 'backupec') {
          continue;
        }
        if (currentHeaderData[k] !== previousHeaderData[k]) {
          if (k === 'eligibilityConsultant') {
            currentHeaderData[k] = this.eprService.getAssignedEc(currentHeaderData[k]);
            previousHeaderData[k] = this.eprService.getAssignedEc(previousHeaderData[k]);
          }
          if (k === "backupec" || k === "backupecDefault") {
            currentHeaderData[k] = currentHeaderData[k]?.firstName && currentHeaderData[k]?.lastName ? `${currentHeaderData[k]?.firstName} ${currentHeaderData[k]?.lastName}` : '';
            previousHeaderData[k] = previousHeaderData[k]?.firstName && previousHeaderData[k]?.lastName ? `${previousHeaderData[k]?.firstName} ${previousHeaderData[k]?.lastName}` : '';
          }

          let changedFieldsHistoryobj: any = {
            "section": "Header",
            "subSelection": "-",
            "eprHistoryField": k,
            "previousValue": previousHeaderData[k],
            "newValue": currentHeaderData[k],
          }
          let eprHistoryObj = { ...commonFieldsHistoryobj, ...changedFieldsHistoryobj }
          eprHistoryArray.push(eprHistoryObj);
        }
      }

      /* we are handling project tracker details from backend please keep this code for future reference*/
      // const preProjectkeys = Object.keys(previousProject);
      // preProjectkeys.forEach(k => {
      //   if ((typeof (previousProject[k]) === 'object' && k!== 'checklistEntity'  && k!==  'testingEntity') ||
      //        typeof (previousProject[k]) === 'string' || typeof (previousProject[k]) === 'number') {
      //     if (currentProject[k] !== previousProject[k] &&
      //       !(previousProject[k] === null && currentProject[k] === '')) {
      //       let changedFieldsHistoryobj: any = {
      //         "section": "Project",
      //         "subSelection": project,
      //         "eprHistoryField":'Tracker - ' + k,
      //         "previousValue": previousProject[k],
      //         "newValue": currentProject[k],
      //       }
      //       let eprHistoryObj= {...commonFieldsHistoryobj, ...changedFieldsHistoryobj}
      //       eprHistoryArray.push(eprHistoryObj);
      //     }
      //   }
      //   if (typeof (previousProject[k]) === 'object' && k === 'checklistEntity') {
      //     const preProjectChecklistkeys = Object.keys(previousProject[k]);
      //     preProjectChecklistkeys.forEach(j => {
      //       if ((typeof (previousProject['checklistEntity'][j]) === 'object' && j !== 'checklistFieldsEnabled') ||
      //         typeof (previousProject['checklistEntity'][j]) === 'string' || typeof (previousProject['checklistEntity'][j]) === 'number') {
      //         if (currentProject['checklistEntity'][j] !== previousProject['checklistEntity'][j] &&
      //           !(previousProject['checklistEntity'][j]  === null && currentProject['checklistEntity'][j] === '')) {
      //           this.getChecklistSection(j);
      //           let changedFieldsHistoryobj: any = {
      //             "section": "Project",
      //             "subSelection": "Checklist",
      //             "eprHistoryField": this.subSection + ' - ' + j,
      //             "previousValue": previousProject['checklistEntity'][j],
      //             "newValue": currentProject['checklistEntity'][j],
      //           }
      //           let eprHistoryObj= {...commonFieldsHistoryobj, ...changedFieldsHistoryobj}
      //           eprHistoryArray.push(eprHistoryObj);
      //         }
      //       }
      //     });
      //   }
      // });

      if (prevQuestionnaire) {
        for (const k in prevQuestionnaire) {
          if (((typeof (prevQuestionnaire[k]) === 'object') ||
            typeof (prevQuestionnaire[k]) === 'string' || typeof (prevQuestionnaire[k]) === 'number')) {
            if (typeof prevQuestionnaire[k] === 'object' && prevQuestionnaire[k] !== null) {
              if (currentQuestionnaire[k]?.length !== undefined && (currentQuestionnaire[k]?.length > 0 && typeof currentQuestionnaire[k][0] === 'string')) {
                if (currentQuestionnaire[k].join() !== prevQuestionnaire[k].join() &&
                  !(prevQuestionnaire[k].join() === null && currentQuestionnaire[k].join() === '')) {
                  this.getQuestionnaireSection(k);
                  let changedFieldsHistoryobj: any = {
                    "subSelection": "-",
                    "eprHistoryField": this.questSubSection,
                    "section": "Questionnaire",
                    "previousValue": prevQuestionnaire[k].join(),
                    "newValue": currentQuestionnaire[k].join(),
                  }
                  let eprHistoryObj = { ...commonFieldsHistoryobj, ...changedFieldsHistoryobj }
                  eprHistoryArray.push(eprHistoryObj);
                }
              } else {
                if (prevQuestionnaire[k]?.length !== undefined && (prevQuestionnaire[k].length !== currentQuestionnaire[k].length ||
                  prevQuestionnaire[k]?.map(t => JSON.stringify(t)).join() !== currentQuestionnaire[k].map(t => JSON.stringify(t)).join())) {
                  this.getQuestionnaireSection(k);
                  let changedFieldsHistoryobj: any = {
                    "subSelection": "-",
                    "eprHistoryField": this.questSubSection,
                    "section": "Questionnaire",
                    "previousValue": prevQuestionnaire[k].map(t => JSON.stringify(t)).join(),
                    "newValue": currentQuestionnaire[k].map(t => JSON.stringify(t)).join(),
                  }
                  let eprHistoryObj = { ...commonFieldsHistoryobj, ...changedFieldsHistoryobj }
                  eprHistoryArray.push(eprHistoryObj);
                }
              }
            } else {
              if (currentQuestionnaire[k] !== prevQuestionnaire[k] && !(prevQuestionnaire[k] === null && currentQuestionnaire[k] === '')) {
                // let newValue = k.includes('Notes') && currentQuestionnaire[k] ? JSON.parse(currentQuestionnaire[k])?.ops[0]?.insert : currentQuestionnaire[k];
                let newValue
                if(k.includes('Notes') ) {
                  if(currentQuestionnaire[k]?.ops){
                    newValue = JSON.parse(currentQuestionnaire[k])?.ops[0]?.insert
                  }else{
                    newValue = currentQuestionnaire[k]
                  }


                }else{
                  newValue = currentQuestionnaire[k]
                }
                // let previousValue = k.includes('Notes') && prevQuestionnaire[k] ? JSON.parse(prevQuestionnaire[k])?.ops[0]?.insert : prevQuestionnaire[k];
                let previousValue
                if(k.includes('Notes') ) {
                  if(prevQuestionnaire[k]?.ops){
                    previousValue = JSON.parse(prevQuestionnaire[k])?.ops[0]?.insert
                  }else{
                    previousValue = prevQuestionnaire[k]
                  }


                }else{
                  previousValue = prevQuestionnaire[k]
                }
                //  let previousValue = k.includes('Notes') && prevQuestionnaire[k] ? JSON.parse(prevQuestionnaire[k])?.ops[0]?.insert : prevQuestionnaire[k];
                this.getQuestionnaireSection(k);
                newValue = this.transform(newValue,newValue?.length);
                let changedFieldsHistoryobj: any = {
                  "section": "Questionnaire",
                  "subSelection": "-",
                  "eprHistoryField": this.questSubSection,
                  "previousValue": previousValue,
                  "newValue": newValue,
                }
                let eprHistoryObj = { ...commonFieldsHistoryobj, ...changedFieldsHistoryobj }
                eprHistoryArray.push(eprHistoryObj);
              }
            }
          }
        }

      }
      // if(prevQuestionnaire){
      //   const preQuestionnairekeys = Object.keys(prevQuestionnaire);
      //   preQuestionnairekeys.forEach(k => {
      //     if (typeof (prevQuestionnaire[k]) === 'string' || typeof (prevQuestionnaire[k]) === 'number') {
      //       if (currentQuestionnaire[k] !== prevQuestionnaire[k]) {
      //         this.getQuestionnaireSection(k);
      //         let changedFieldsHistoryobj: any = {
      //           "section": "Questionnaire",
      //           "subSelection": "-",
      //           "eprHistoryField": this.questSubSection + ' - ' + k,
      //           "previousValue": prevQuestionnaire[k],
      //           "newValue": currentQuestionnaire[k],
      //         }
      //         let eprHistoryObj= {...commonFieldsHistoryobj, ...changedFieldsHistoryobj}
      //         eprHistoryArray.push(eprHistoryObj);
      //       }
      //     }
      //     if (typeof (prevQuestionnaire[k]) === 'object') {
      //       if (JSON.stringify(currentQuestionnaire[k]) !== JSON.stringify(prevQuestionnaire[k])) {
      //         let changedFieldsHistoryobj: any = {
      //           "section": "Questionnaire",
      //           "subSelection": "-",
      //           "eprHistoryField": k,
      //           "previousValue": prevQuestionnaire[k],
      //           "newValue": currentQuestionnaire[k],
      //         }
      //         let eprHistoryObj= {...commonFieldsHistoryobj, ...changedFieldsHistoryobj}
      //         eprHistoryArray.push(eprHistoryObj);
      //       }
      //     }
      //   });
      // }

      if (eprHistoryArray.length) {
        let tempHistoryArray = eprHistoryArray;
        // this.removeTimeStamp(tempHistoryArray);
        if (tempHistoryArray) {
          this.replaceTimestamp(tempHistoryArray)
          if (this.saveHistoryObj !== JSON.stringify(tempHistoryArray)) {
            this.saveHistoryObj = JSON.stringify(tempHistoryArray);
            // const eprCommonHistoryObj={
            //   eprHistoryRecordList: eprHistoryArray,
            //   eprCommonModel: this.eprService.finalEprCommonModel
            // }
            this.eprService.saveEprHistory(eprHistoryArray).subscribe((res) => {
            });
          }
          this.saveSubscription.unsubscribe();
        }

      }
    }

  }
  saveEprHistory(commonFieldsHistoryobj) {
    this.addEprcount = false;
    let eprHistoryArray = [];
    eprHistoryArray.push(commonFieldsHistoryobj);
    if (eprHistoryArray.length) {
      let tempHistoryArray = eprHistoryArray;
      // this.removeTimeStamp(tempHistoryArray);
      if (tempHistoryArray) {
        this.replaceTimestamp(tempHistoryArray)
        if (this.saveHistoryObj !== JSON.stringify(eprHistoryArray)) {
          this.saveHistoryObj = JSON.stringify(eprHistoryArray);
          // const eprCommonHistoryObj={
          //   eprHistoryRecordList: eprHistoryArray,
          //   eprCommonModel: this.eprService.finalEprCommonModel
          // }
          this.eprService.saveEprHistory(eprHistoryArray).subscribe((res) => {
            if (res) {
              this.eprService.initialEprData.unsubscribe;
              this.saveSubscription?.unsubscribe();
            }
          });
        }
      }
    }

  }


  getTestResultsfield(field: any) {
    if (!field.includes('tC')) {
      return field;
    } else {
      let result: any;
      if (field.includes('Status')) {
        result = field.slice(2, field.indexOf('Status')) + ' : Status';
      } else if (field.includes('Svrty')) {
        result = field.slice(2, field.indexOf('Svrty')) + ' : Severity';
      } else if (field.includes('Notes')) {
        result = field.slice(2, field.indexOf('Notes')) + ' : Notes';
      }
      return result;
    }
  }
  getQuestionnaireSection(field: any) {
    this.questSubSection = '';
    qconfig.questionnaireFormConfig.forEach(section => {
      section.forEach(formControl => {
        if (formControl.controlName === field || formControl.notes === field) {
          section.forEach((header: any) => {
            if (header.inputType === InputType.HEADER) {
              this.questSubSection = header.label + '-' + formControl.label;
            } else if (header.inputType === InputType.SUB_HEADER || header.subHeader) {
              if(qconfig.headerConfig && formControl.label && qconfig?.headerConfig[header.label]) {
                this.questSubSection = qconfig.headerConfig[header.label] + '-' + formControl.label;
              }
             }
          });
        }
      })
    });

    if (this.questSubSection == '') {
      qconfig.questionnaireHardCodedFormConfig.forEach(formControl => {
        if (formControl.controlName === field) {
          this.questSubSection = formControl.label;

        }
      });
    }
  }
  getChecklistSection(field: any) {
    this.checklistSections.forEach(section => {
      if (field.includes(section.abbreviation)) {
        this.subSection = section.header;
      } else {
        section.sectionConfig.forEach(formControl => {
          if (formControl.formControlName === field) {
            this.subSection = section.header;
          }
        });
      }
    });
  }
  isSaveEnable() {
    return (this.headerForm && this.headerForm?.controls['pli']?.invalid )|| !this.eprService.enableSaveButton;
  }
  saveEpr = () => {
    // this.saveTestResults.emit();
    let prevQuestionnaire: any;
    let prevHeader: any;
    if (this.headerForm.valid) {
      this.store.dispatch(new SaveEprAction());
      this.saveSubscription = this.store.select(getEPRState).subscribe(resp => {
        if (resp && resp.id && resp.questionnaireEntity !== undefined) {
          let questionnaireResult = {};
          let headerResult: any = {};
          if (this.eprHistoryObj !== JSON.stringify(resp)) {
            this.eprHistoryObj = JSON.stringify(resp);
            this.saveHistory(resp, 'Update');
          }
          const prevQuestionnaire = resp.previousData.questionnaireEntity;
          const prevHeader = resp.previousData.headerEntity;
          const currentQuestionnaire = resp.questionnaireEntity;
          const currentHeader = resp.headerEntity;
          if (prevQuestionnaire) {
            const preQuestionnairekeys = Object.keys(prevQuestionnaire);
            preQuestionnairekeys.forEach(k => {
              if (typeof (prevQuestionnaire[k]) === 'string' || typeof (prevQuestionnaire[k]) === 'number') {
                if (currentQuestionnaire[k] !== prevQuestionnaire[k]) {
                  questionnaireResult[k] = currentQuestionnaire[k];
                }
              }
              if (typeof (prevQuestionnaire[k]) === 'object') {
                if (JSON.stringify(currentQuestionnaire[k]) !== JSON.stringify(prevQuestionnaire[k])) {
                  questionnaireResult[k] = currentQuestionnaire[k];
                }
              }
            });
          }
          for (const k in prevHeader) {
            if (currentHeader[k] !== prevHeader[k]) {
              headerResult[k] = currentHeader[k];
            }

          }
          if (!(Object.keys(questionnaireResult).length === 0) || !(Object.keys(headerResult).length === 0)) {
            // eslint-disable-next-line max-len
            this.diffCount = true;
            const saveChangesQue = this.store$.select(getchangedQuestionnaireRecord).subscribe(response => {
              if (response.questionnairedata) {
                questionnaireResult = Object.assign(response.questionnairedata, questionnaireResult);
              }
              if (response.headerdata) {
                headerResult = Object.assign(response.headerdata, headerResult);
              }
              const ec = this.headerForm.get('eligibilityConsultant').value;
              headerResult.eligibilityConsultant = ec;
              this.store.dispatch(new SaveChangedQuestionnaireRecordAction(
                { questionnairedata: questionnaireResult, headerdata: headerResult, eprId: this.eprId }));
              this.entireresp = response;
              this.saveSubscription.unsubscribe();
            });
            saveChangesQue.unsubscribe();

          }
          /* this condition is to save the individual eprfeed on Profile Updates Screen */
          if (this.eprId && !this.diffCount) {
            this.diffCount = true;
            const eprfeed: EprFeed = this.eprFeedService.eprfeed;
            if (eprfeed) {
              eprfeed.eprId = this.eprId;
              setTimeout(() => {
                this.callServicetosave(eprfeed);
              }, 3000);

            }
          }
        }
      });
    } else {
      this.headerForm.markAllAsTouched();
    }
    this.isEditQuestionnaire = false;
  }
  callServicetosave(eprfeed) {
    // this.eprFeedService.saveEprFeed(eprfeed).subscribe(resp => {
    //
    // });
  }
  openDeleteEprModal() {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        body: 'Are you sure you want to DELETE this entire EPR?',
        type: AlertType.CONFIRMATION,
        buttonText: 'Confirm Delete',
        onConfirm: () => this.onDeletionConfirmation()
      } as ConfirmationDialogData
    });
  }

  onDeletionConfirmation = () => {
    this.saveSubscription = this.store$.select(getEPRState).subscribe(resp => {
      if (resp && resp.id === this.eprId && resp.questionnaireEntity !== undefined) {
        this.saveHistory(resp, 'Delete');
      }
    });
    this.saveSubscription?.unsubscribe();
    this.store.dispatch(new DeleteEprAction(this.eprId));
    this.dialog.closeAll();
  }

  private setupForm() {
    this.headerForm = this.fb.group({
      clientName: ['', Validators.required],
      carrierNumber: ['', [this.validation]],
      pli: ['', Validators.required],
      eligibilityConsultant: ['', Validators.required],
      goLiveDate: '',
      clientCode1: '',
      qlClientCode1: '',
      rdc: '',
      obec: false,
      backupec: this.fb.group({
        id: [{ value: '', disabled: true }]
      }),
      backupecDefault: this.fb.group({
        id: [{ value: '', disabled: true }]
      }),
      holdDate: ''
    });

      this.headerForm.controls['pli'].valueChanges.subscribe(val => {
        if(val){
          if(!this.isEprLoaded){
            this.isEprLoaded = true;
            this.currentEprPli = val;
          }
          this.eprService.pliValue.next(val);
          const regxmatch = new RegExp(/^[A-Za-z][\w]{2,3}$/i).test(val);
          const plivalid= ! this.usersPliList.map(t => t?.toLowerCase()).includes(val?.toLowerCase()) || val=== this.currentEprPli;
          // const plioccurance = this.usersPliList.filter(item => item === val).length;
          this.isPliValid = regxmatch && plivalid;
           }
    });
  }
  validation(control: AbstractControl) {
    let valid = null;
    const value = control.value;
    let carrierArray = new Array();
    if (value) {
      if (/^[^`~!@#$%\^&*()_+={}|[\]\\:';"<>?./]*$/.test(control.value)) {
        if (value.indexOf(',') !== -1) {
          carrierArray = value.split(',');
          let i = 0;
          for (const carrier of carrierArray) {
            const space = carrier.split(' ').length - 1;
            if (!(carrier.replace(/\s/g, '').length >= 4 && carrier.replace(/\s/g, '').length <= 9)) {
              valid = { required: true };
            }
            if (space > 1) {
              valid = { required: true };
            }
            if (i === 0 && space >= 1) {
              valid = { required: true };
            }
            if (i > 0) {
              const index = carrier.indexOf(' ');
              if (!(index === 0) || space > 1) {
                valid = { required: true };
              }
            }
            i++;
          }
        } else {
          const space = value.split(' ').length - 1;
          if (!(value.replace(/\s/g, '').length >= 4 && value.replace(/\s/g, '').length <= 9)) {
            valid = { required: true };
          }
          if (space >= 1) {
            valid = { required: true };
          }
        }
      } else {
        valid = { required: true };
      }
      return valid;
    }
  }

  removeRestricedAccessClient() {
    this.headerForm.controls['rdc'].patchValue('', { emitEvent: false });
  }

  copyEpr() {
    this.store.dispatch(new CopyEprAction());

    this.router.navigateByUrl('404', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl('epr').then(() => {
        this.alertService.sendAlert({
          headline: 'EPR Copied',
          alertType: CVSAlertType.Success,
          removedAfterMilliseconds: 10000,
          alertDocumentHolderName: '#header-alert',
        });
        this.startEdit();
      });
    });
    this.eprService.isNewCopy = true;
  }

  allowedTopCopy(permission: Permission): boolean {
    
      return this.eprObject.buttons.COPY_EPR
  }

  checkHoldDate() {
    let holdDate = this.headerForm.get('holdDate').value;
    if (holdDate) {
      holdDate = new Date(holdDate);
      const todayDate = new Date();
      if (holdDate.getTime() === todayDate.getTime() || holdDate.getTime() < todayDate.getTime()) {
        this.isOnhold = true;
      } else {
        this.isOnhold = false;
      }
    } else {
      this.isOnhold = false;
    }

  }

  resize(){
    const tx = document.querySelector("textarea");


  tx.style.height = 'auto';
  tx.style.height = (tx.scrollHeight) + "px;overflow-y:hidden;";



  }
  createEprPermissionObject(){
    let eprObject: EPRObject;
    eprObject = new EPRObject()
    
      for(const [k,v] of Object.entries(eprObject.EprButtonsPermissions)){
        if(this.permissions?.includes(v)){
            eprObject.buttons[k] = true

        }

      }
  
    return eprObject
  }
  editQuestionnaire() {
    this.isEditQuestionnaire=true;
    this.startEdit();
  }

  editProfiles() {
    this.isEditProfiles = true;
    this.eprService.isEditProfileFlag.next(this.isEditProfiles);
  }

  exitProfileEdit() {
    this.isEditProfiles = false;
    this.eprService.isEditProfileFlag.next(this.isEditProfiles);
  }

  discardChanges() {
    this.isEditQuestionnaire = false;
    this.store$.dispatch(new StopEditAction());
    this.eprService.resetQuestionnaire.next(true);


  }

}
