import {User} from '../models/User';
import {
  Actions,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  FILTER_USERS_BY_ROLE,
  LOAD_ROLES_SUCCESS,
  LOAD_USERS_SETTING_SUCCESS,
  LOAD_USERS_SUCCESS,
  LOAD_USER_SETTING,
  SAVE_USER_SUCCESS,
} from '../actions/user-management.action';
import {Authorization, Permission} from '../models/Authorization';

export interface State {
  allUsers: User[];
  displayedUsers: User[];
  roles: Authorization[];
  selectedUserId: number;
  userSetting: User[];
}

export const initialState: State = {
  allUsers: [],
  displayedUsers: [],
  roles: [],
  selectedUserId: undefined,
  userSetting: []
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case LOAD_USERS_SUCCESS:
      return {...state, allUsers: action.payload as User[], displayedUsers: action.payload as User[],
      userSetting: action.payload as User[]
      };

    case LOAD_USERS_SETTING_SUCCESS :
    return {  ...state, userSetting: action.payload as User[]};

    case SAVE_USER_SUCCESS :
      const newUsers = state.displayedUsers.concat(action.payload);
      newUsers.sort((a, b) => {
        if (a.firstName < b.firstName) {
          return -1;
        }
        if (a.firstName > b.firstName) {
          return 1;
        }
        return 0;
      });
      return {...state, allUsers: newUsers, displayedUsers: newUsers};

    case EDIT_USER:
      return {...state, selectedUserId: action.payload.id};

    case EDIT_USER_SUCCESS:
      let index;
      state.allUsers.forEach((user,i)=>{ if(user.id === action.payload.id) if(user.id === action.payload.id) index = i})
        //index = state.allUsers.findIndex(allUser =>{allUser === user})})

      let displayIndex;
      state.displayedUsers.forEach((user,i) =>{ if(user.id === action.payload.id) displayIndex = i})
        //displayIndex = state.displayedUsers.findIndex(allUser =>{allUser === user})})
      let userSettingIndex;
      state.userSetting.forEach((user, i) => {
        if (user.id === action.payload.id) userSettingIndex = i;
      });
          //displayIndex = state.displayedUsers.findIndex(allUser =>{allUser === user})})
     if(index!== undefined){
          return {
        ...state,
        allUsers: [
          ...state.allUsers.slice(0, index),
          Object.assign({}, state.allUsers[index], action.payload),
          ...state.allUsers.slice(index + 1),
        ],
        displayedUsers: [
          ...state.displayedUsers.slice(0, displayIndex),
          Object.assign({}, state.displayedUsers[displayIndex], action.payload),
          ...state.displayedUsers.slice(displayIndex + 1),
        ],
        userSetting: [
          ...state.userSetting.slice(0, userSettingIndex),
          Object.assign(
            {},
            state.userSetting[userSettingIndex],
            action.payload
          ),
          ...state.userSetting.slice(userSettingIndex + 1),
        ],
      };
    }else{
      return {
        ...state,
        userSetting: [
          ...state.userSetting.slice(0, userSettingIndex),
          Object.assign(
            {},
            state.userSetting[userSettingIndex],
            action.payload
          ),
          ...state.userSetting.slice(userSettingIndex + 1),
        ],
      };
    }

    case LOAD_ROLES_SUCCESS:
      return {...state, roles: action.payload as Authorization[]};

    case FILTER_USERS_BY_ROLE:
      const filteredUsers = state.allUsers.filter((user) => {
        return user.authorization && (user.authorization.permissionRole === action.payload.toString());
      });
      return {...state, displayedUsers: filteredUsers as User[]};

    default:
      return state;
  }
}

export const getUsers = (state: State) => state?.allUsers;
// export const getActiveUsers = (state: State) => {
//   return state.allUsers.filter(user => user.active);
// };
// export const getActiveUsersByRole = (state: State, permissions: Permission[]) => {
//   return state.allUsers.filter(user => {
//     return user.active && permissions.includes(user.authorization.permission);
//   });
// };
export const getDisplayedUsers = (state: State) => state?.displayedUsers;
export const getRoles = (state: State) => state.roles;
export const getUserSettingList = (state: State) => state?.userSetting;

export const getActiveUsers = (state: State) => {
  if(state.allUsers.length){
    return state.allUsers.filter(user => user.active);
  }else{
    return state.userSetting.filter(user => user.active);
  }
};
export const getActiveUsersByRole = (state: State, permissions: string[]) => {
 if(state.allUsers.length){
   return state.allUsers.filter(user => {
     return user.active && permissions.includes(user?.authorization?.permissionRole);
   });
 }else{
  return state.userSetting.filter(user => {
    return user.active && permissions.includes(user?.authorization?.permissionRole);
  });
 }
};
