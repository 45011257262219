import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ICellEditorAngularComp, ICellRendererAngularComp} from 'ag-grid-angular';
import {of} from 'rxjs';
import {ICellRendererParams} from "ag-grid-community";


@Component({
  selector: 'app-dropdown-renderer',
  templateUrl: './dropdown-renderer.component.html',
  styleUrls: ['./dropdown-renderer.component.scss']
})
export class DropdownRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public value: number;
  selectedOption: any= 'no';
  dropdownValues: any = [];

  agInit(params: any): void {
    this.params = params;
    this.dropdownValues= params.values;
    this.dropdownValues.forEach(item =>{
      if(item.default){
        this.selectedOption= item.value;
      }
    })
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  ischeckBoxChecked(item: any, fieldActionsDefault: string) {
    return undefined;
  }

  ischecked(value) {
    return this.selectedOption === value;
  }

  selectValue($event:any) {
    this.selectedOption = $event;

  }
}
