<cvs-alert-placeholder id="master-data-alert"></cvs-alert-placeholder>
<div class="parmcontainer">
    <button class="mat-raised-button col-4 add-button"  (click)="addNewParameter()">
        <mat-icon class="mat-sym" svgIcon="plus--s"></mat-icon>
        Add New Parameter
    </button>
    <div id="dashboard-table-one" class="dashboard-table">
        <ag-grid-angular class="ag-theme-alpine" [columnDefs]="columnDefs" [rowData]="rowData" (rowClicked)='onRowClicked($event)' >
        </ag-grid-angular>
      </div>
</div>
