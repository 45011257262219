export class Authorization {
  id: string;
  permission: Permission;
  permissionRole: string;
}

export enum Permission {
  ADMIN = 'ADMIN',
  LEADERSHIP = 'LEADERSHIP',
  EC = 'EC',
  ERM = 'ERM',
  MANAGER = 'MANAGER',
  PROCESSOR = 'PROCESSOR',
  TESTER_AUDITOR = 'TESTER_AUDITOR',
  INTFEED = 'INTFEED',
  VISITOR = 'VISITOR',
  FILETRACKER= 'FILETRACKER',
  SPEDM =  'SPEDM',
  SI_MAPPER = 'SI_MAPPER',
  FT_BENEFIT = 'FT_BENEFIT',
  SPEDM_ADMIN = 'SPEDM_ADMIN',
  CLIENT_USER = 'CLIENT_USER',
  CONSULTANT_USER ="CONSULTANT_USER",
  OTHER_FILE_USER ="OTHER_FILE_USER"
}
