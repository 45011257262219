import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DataManagementService} from "../../../services/data-management.service";

@Component({
  selector: 'app-add-edit-reporting-item-popup',
  templateUrl: './add-edit-reporting-item-popup.component.html',
  styleUrls: ['./add-edit-reporting-item-popup.component.scss']
})
export class AddEditReportingItemPopupComponent implements OnInit {
  public code: string;
  public type: string;
  public description: string;
  public dataValues: any = {}
  public isEditMode: boolean = false;
  public isDelete: boolean;
  public title: string = "";
  public reportingMessageCodesTypes:any = [];
  public reportMessageCodes:any = [];
  characterCount: Number = 0;
  addEditItem: UntypedFormGroup;
  codeError:boolean;
  constructor(private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) data , public dialogRef: MatDialogRef<AddEditReportingItemPopupComponent>,private dataManagementService:DataManagementService) {
    this.dataValues= data;
    this.isEditMode = (this.dataValues?.actionType?.mode)?.includes("Edit");
    this.isDelete = (this.dataValues?.actionType?.mode)?.includes("Delete");
    let title = (this.dataValues?.actionType?.type=="validations") ? ' Validation': ((this.dataValues?.actionType?.type=='fieldActions') ? ' Field Action':((this.dataValues?.actionType?.type=='reportingMessageCodes') ? ' Reporting Message Codes': ' Reject Action'));
    this.title = this.dataValues?.actionType?.mode + title;
    this.code = this.isEditMode ? this.dataValues.code :'';
    this.description = this.isEditMode ? this.dataValues.description :'';
    this.createForm();

  }

  ngOnInit(): void {
    this.dataManagementService.reportTypes.subscribe(data=>{
      this.reportingMessageCodesTypes = data;
      this.type = this.isEditMode ? this.reportingMessageCodesTypes.find(t=> t.id ===this.dataValues.type) :'';
      this.addEditItem?.controls['reportType'].setValue(this.type);
    })
    this.dataManagementService.reportMessageCodes.subscribe(data=>{
      this.reportMessageCodes = data;
    })
  }
  createForm() {
    this.addEditItem = this.fb.group({
      code: [{value:this.code,disabled:this.isEditMode}, Validators.required],
      reportType: [this.type, Validators.required],
      description: [this.description]
    })
  }
  save(): void{
    if(this.addEditItem.valid) {
      this.code =  this.addEditItem.get('code')?.value;
      this.type= this.addEditItem.get('reportType')?.value.id;
      this.description = this.addEditItem.get('description')?.value;
      const newValidationRow = [{
        id:  this.isEditMode ? this.dataValues.id: null,
        message: this.code,
        type: this.dataValues?.actionType?.type,
        messageDescription: this.description,
        reportType: this.type,
        isNew: this.isEditMode ? false : true,
      }];
      this.dialogRef.close(newValidationRow);
    }
  }
  deleteRecord(){
    this.dialogRef.close(this.dataValues.actionType);
  }
  checkCode(event){
    this.reportMessageCodes = this.reportMessageCodes.map(v => v?.toLowerCase());
    this.codeError = this.reportMessageCodes.includes(event.target.value?.toLowerCase());
  }

  findcharacterCount(event){
    this.characterCount  = 0;
    if(event?.target?.value && event?.target?.value !="") {
      this.characterCount  = event?.target?.value?.length;
    }
  }
}
