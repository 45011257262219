import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-freeform-multiselect',
  templateUrl: './freeform-multiselect.component.html',
  styleUrls: ['./freeform-multiselect.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FreeformMultiselectComponent),
      multi: true
    }
  ]
})
export class FreeformMultiselectComponent implements ControlValueAccessor {

  @Input() controlName: string;
  @Input() defaultOptions: string[];
  customOptions: string[];
  values: string[];
  disabled: boolean;

  onChange: any = () => {};
  onTouched: any = () => {};

  getAllOptions(): void {
    this.customOptions = [...this.defaultOptions];
    let value;
    for (value of this.values) {
      if (!this.customOptions.includes(value)) {
        this.customOptions.push(value);
      }
    }
  }

  selectOption() {
    this.onChange(this.values);
    this.onTouched();
  }

  selectCustomOption(value: string) {
    this.customOptions.push(value);

    // value must be removed and added again after it is added as an option
    // so that it will be seen as valid
    this.values.pop();
    this.values.push(value);
    this.selectOption();
  }

  writeValue(obj: any): void {
    this.values = obj;
    this.getAllOptions();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
