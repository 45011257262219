import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../reducers';
import {catchError, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {
  CHECK_IF_CROSS_FORM_POPULATIONS_NEEDED_CHECKLIST,
  CheckIfCrossFormPopulationsNeededActionChecklist,
  UPDATE_CHECKLIST_FORM
} from '../actions/checklist.action';
import {NoopAction} from '../actions/noop.action';
import {AlertType} from '../models/AlertType';
import {
  ConfirmationDialogContentComponent,
  ConfirmationDialogData
} from '../epr/questionnaire/confirmation-dialog-content/confirmation-dialog-content.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class ChecklistEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    public dialog: MatDialog,
  ) {  }

  populateOtherForms$ = createEffect(() => this.actions$.pipe(
    ofType(CHECK_IF_CROSS_FORM_POPULATIONS_NEEDED_CHECKLIST),
    withLatestFrom(
      this.store$.select(state => state.checklist.checklists[state.checklist.selectedProjectIndex]),
      this.store$.select(state => state.checklist.selectedProjectIndex),
      this.store$.select(state => state.questionnaire)
    ),
    mergeMap(([action, checklist, index, questionnaire]) => {
      return of({
        new: (action as CheckIfCrossFormPopulationsNeededActionChecklist).payload,
        old: checklist
      })
        .pipe(
          tap((checklists) => {
            // Check for relevant updates
            this.popupIfSetupCmpltDteChanges(checklists.new, checklists.old);
            this.popupIfPreProfCpltDteChanges(checklists.new, checklists.old, questionnaire);
            this.popupIfStageProfCpltDteChanges(checklists.new, checklists.old, questionnaire);
          }),
          map(checklists => {
            return ({type: UPDATE_CHECKLIST_FORM, payload: {formData: checklists.new, selectedProjectIndex: index}});
          }),
          catchError(() => {
            return of(new NoopAction());
          })
        );
    })
    )
  );

  private popupIfSetupCmpltDteChanges(newChecklist, oldChecklist) {
    const newSetupCmpltDate = newChecklist.newSetupCmpltDate;
    const oldSetupCmpltDate = oldChecklist.newSetupCmpltDate;

    if (oldSetupCmpltDate !== newSetupCmpltDate) {
      // eslint-disable-next-line max-len
      this.openDialog('QC is required PRIOR to testing. Be sure to set project PHASE to \'Ready for QC\'.', AlertType.ADDITIONAL_INFORMATION);
    }
  }

  private popupIfPreProfCpltDteChanges(newChecklist, oldChecklist, questionnaire) {
    const newPrePreCpltDate = newChecklist.prePreCpltDate;
    const oldPrePreCpltDate = oldChecklist.prePreCpltDate;


    if (oldPrePreCpltDate !== newPrePreCpltDate) {
        if (questionnaire.careFcltyOnTheFly === 'Yes' || questionnaire.careQualifierOnTheFly === 'Yes') {
          // eslint-disable-next-line max-len
          this.openDialog('Client has requested that either Groups, Care Facility, or Care Qualifier be created on the fly. Be sure to setup Preload profile accordingly. See questionnaire for more detail.', AlertType.ADDITIONAL_INFORMATION);
        }
      }
  }

  private popupIfStageProfCpltDteChanges(newChecklist, oldChecklist, questionnaire) {
    const newStageActDate = newChecklist.stageActDate;
    const oldStageActDate = oldChecklist.stageActDate;

    if (oldStageActDate !== newStageActDate) {
      if (questionnaire.cltPrdctUsed === 'Yes'
        || questionnaire.cltRiderUsed === 'Yes'
        || questionnaire.careNtwrkUsed === 'Yes'
        || questionnaire.careFcltyUsed === 'Yes'
        || questionnaire.careQualifierUsed === 'Yes'
      ) {
        // eslint-disable-next-line max-len
        this.openDialog('Client Product/Rider and, or Care Facility/Network/Qualifier used for this client... Be sure to setup stage profile accordingly. See Questionnaire for more detail.', AlertType.ADDITIONAL_INFORMATION);
      }
    }
  }

  private openDialog(bodyText: string, alertType: AlertType) {
    this.dialog.open(ConfirmationDialogContentComponent, {
      data: {
        body: bodyText,
        type: alertType
      } as ConfirmationDialogData
    });
  }
}
