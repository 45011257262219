import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Authorization} from '../models/Authorization';
import {CVSHttpClient} from 'angular-component-library';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _authorization: Authorization;

  get authorization() {
    return this._authorization;
  }

  set authorization(authorization: Authorization) {
    this._authorization = authorization;
  }

  constructor(private http: CVSHttpClient) {
  }

  getAll(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/authorizations`);
  }
}
